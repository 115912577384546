import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import download from 'downloadjs'

import pdfIcon from '../../../assets/file-pdf.svg'
import useToken from '../../../utils/useToken'
import { ENDPOINT_REPORT } from '../../../const/index'
import '../../../styles/ClientAllDataShow.css'

const ButtonPdfReport = ({ uuid }) => {
  const [isLoading, setIsLoading] = useState(false)
  const getToken = useToken()
  const { t } = useTranslation()

  const generarDetalleVerifPDF = async (uuid) => {
    setIsLoading(true)
    try {
      const token = await getToken({
        audience: 'veridocid',
      })

      const response = await fetch(`${ENDPOINT_REPORT}/${uuid}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if (response.ok) {
        setIsLoading(false)
        const data = await response.blob()
        download(data, `${uuid}.pdf`, 'application/pdf')
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  return (
    <Button
      onClick={() => generarDetalleVerifPDF(uuid)}
      floated="right"
      loading={isLoading}
      style={{
        background: 'var(--primary-color)',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      className="btns_actions_with_background"
    >
      <img
        src={pdfIcon}
        alt="detalleIcon"
        style={{ width: '14px', height: '14px', marginRight: '3px' }}
      />{' '}
      <span className="details_btnPDF">{t('pdfReport')}</span>
    </Button>
  )
}

export default ButtonPdfReport
