import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'

export const MyGrid = styled(Grid)((props) => ({
  color: 'var(--secondary-color)',
  width: '100%',
  height: '30px',
  display: 'flex',
  alignItems: 'center',
  marginTop: props.marginTop ? `${props.marginTop}px` : '0',
  marginBottom: props.marginBottom ? `${props.marginBottom}px` : '0',
}))

export const OnlyMe = styled(Grid)({
  height: 'auto',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  margin: 'auto',
  flexDirection: 'column',
  padding: '0.5rem 0',
})

export const OnlyMeBox = styled(Grid)((props) => ({
  width: props.width ? props.width : '0px',
  height: props.height ? props.height : '0px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  zIndex: 80,
  cursor: 'pointer',
}))

export const OnlyMeBoxImage = styled('img')((props) => ({
  width: props.width ? props.width : '0px',
  height: props.height ? props.height : '0px',
  position: 'absolute',
  zIndex: 90,
}))

export const OnlyMeBoxContainer = styled(Grid)((props) => ({
  width: props.width ? props.width : '0px',
  height: props.height ? props.height : '0px',
  fontSize: props.fsize ? props.fsize : '9px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  overflow: 'hidden',
  backgroundImage: props.bimage ? props.bimage : '',
  backgroundSize: props.bsize ? props.bsize : '',
  backgroundRepeat: 'no-repeat',
  objectFit: 'contain',
}))

export const DDZoneB = styled(Grid)({
  height: 'auto',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  margin: '1rem auto auto auto',
  flexDirection: 'column',
  padding: '0.5rem 0',
})

export const Rubric = styled(Grid)({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  color: 'var(--secondary-color)',
})
