import React from 'react'
import './FlagSelect.css'

const FlagSelect = ({ country, countryCode }) => (
  <div className="FlagSelect">
    <i className={country + ' flag'} />
    <p> {countryCode}</p>
  </div>
)

export default FlagSelect
