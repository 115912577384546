import React from 'react'

export const StatusContract = ({ data, t }) => (
  <div className="content_status_sign content_data_panel">
    <h4 className="title_panel_custom">{t('contract_data__status')}</h4>
    <div className="description_panel_custom">
      {data?.participants && data?.participants.length !== 0 ? (
        data?.participants.map((signatory, i) => (
          <p key={i}>
            {signatory.status === 'assigned' ? 'Sin firmar' : 'Firmado' || '-'}
          </p>
        ))
      ) : (
        <p>Termina de configurar tu contrato</p>
      )}
    </div>
  </div>
)
