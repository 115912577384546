import { Loader, Dimmer, Pagination } from 'semantic-ui-react'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Tag, Radio } from 'antd'
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Box,
} from '@mui/material'
import { API_DASHBOARD_URL } from '../../../../const/index.js'
import { makeStyles } from '@material-ui/core/styles'
import useToken from '../../../../utils/useToken'
import moment from 'moment'
import useSWR from 'swr'
import { VscArrowDown, VscArrowUp } from 'react-icons/vsc'

const useStyles = makeStyles((theme) => ({
  thead: {
    background: 'var(--primary-color)',
    '& th:first-child': {
      borderTopLeftRadius: '5px',
    },
    '& th:last-child': {
      borderTopRightRadius: '5px',
    },
  },

  menuItem: {
    display: 'flex',
    height: '30px',
  },
  radioButtons: {
    background: '#000!important',
    'ant-radio-checked ant-radio-inner': {
      borderColor: 'red !important ',
    },

    'ant-radio-checked ant-radio-inner:after': {
      background: 'red',
    },

    'ant-radio:hover ant-radio-inner': {
      borderColor: 'red',
    },
  },
}))

function EnhancedTableHead({
  t,
  selected,
  selectCreatedAtA,
  selectCreatedAtD,
}) {
  return (
    <TableHead>
      <TableRow>
        <TableCell
          className="head_table_custom"
          onClick={
            selected.createdAtD
              ? selectCreatedAtA
              : selected.createdAtA
              ? selectCreatedAtD
              : selectCreatedAtD
          }
        >
          {t('date')}{' '}
          {selected.createdAtD ? (
            <VscArrowDown size={13} strokeWidth={0.8} cursor="pointer" />
          ) : selected.createdAtA ? (
            <VscArrowUp size={13} strokeWidth={0.8} cursor="pointer" />
          ) : null}
        </TableCell>
        <TableCell className="head_table_custom">{t('documentID')}</TableCell>
        <TableCell className="head_table_custom">
          {t('documentHeaderTable')}
        </TableCell>
        <TableCell className="head_table_custom">{t('name')}</TableCell>
        <TableCell className="head_table_custom">{t('signerEmail')}</TableCell>
        <TableCell className="head_table_custom">{t('status')}</TableCell>
        <TableCell className="head_table_custom">
          {t('typeOfSignature')}
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

const AprovalTableComponent = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const [activePage, setActivePage] = useState(1)
  const [register, setRegister] = useState(null)
  const [delay, setDelay] = useState(4000)
  const [limit, setLimit] = useState(10)
  const [selected, setSelected] = useState({
    createdAtD: true,
    createdAtA: false,
  })
  const [status, setStatus] = useState('pending_approval')
  const getToken = useToken()
  const classes = useStyles()
  const changeOption = async (event) => {
    setActivePage(1)
    setStatus(event)
  }

  // Get all contracts
  const showApprovalData = async (url) => {
    const token = await getToken({
      audience: 'veridocid-dashboard',
    })

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })

    if (response.ok) {
      const responseJson = await response.json()
      return responseJson
    } else {
      return null
    }
  }

  const { data } = useSWR(
    `${API_DASHBOARD_URL}/sign/mesa?status=${status}&limit=${limit}&page=${activePage}&sortBy=${
      selected.createdAtD ? 1 : selected.createdAtA ? 2 : 1
    }`,
    showApprovalData,
    {
      refreshInterval: delay,
    }
  )

  useSWR(
    register?.hasNextPage
      ? `${API_DASHBOARD_URL}/sign/mesa?status=${status}&limit=${limit}&page=${
          activePage + 1
        }&sortBy=${selected.createdAtD ? 1 : selected.createdAtA ? 2 : 1}`
      : null,
    showApprovalData
  )

  useEffect(() => {
    if (data) {
      const wait =
        Math.ceil(data.totalDocs / 10) < 5000
          ? 5000
          : Math.ceil(data.totalDocs / 8)
      setDelay(wait)
      setRegister(data)
    }
  }, [limit, data])

  const handlePaginationChange = (e, { activePage }) =>
    setActivePage(activePage)

  // Sort
  const selectCreatedAtD = () =>
    setSelected({
      createdAtD: true,
      createdAtA: false,
    })

  const selectCreatedAtA = () =>
    setSelected({
      createdAtD: false,
      createdAtA: true,
    })

  const handleGoTo = (row) =>
    history.push(
      `/contratos/plantillas/mesa/${row.documentID}/${row.signDocument.uuid}/${row.signDocument.id}`
    )

  return (
    <>
      <Dimmer.Dimmable dimmed={!data}>
        <Dimmer active={!data} inverted>
          <Loader>Cargando...</Loader>
        </Dimmer>

        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h1
            style={{
              display: 'inline',
              color: 'rgb(68, 68, 68)',
              fontSize: 'clamp(1.78em, 1.82em, 1.8em)',
            }}
          >
            {t('reviewTable')}
          </h1>
          <div>
            <Radio.Group
              defaultValue="pending_approval"
              size="middle"
              buttonStyle="solid"
              classes={{ root: classes.radioButtons }}
            >
              <Radio.Button
                value="pending_approval"
                onChange={(e) => changeOption(e.target.value)}
              >
                {t('pendingStatus')}
              </Radio.Button>
              <Radio.Button
                value="approved"
                onChange={(e) => changeOption(e.target.value)}
              >
                {t('approvedStatus')}
              </Radio.Button>
              <Radio.Button
                value="rejected"
                onChange={(e) => changeOption(e.target.value)}
              >
                {t('rejectedStatus')}
              </Radio.Button>
            </Radio.Group>
          </div>
        </div>

        <Box sx={{ width: '100%', paddingBottom: '4rem' }}>
          <Paper sx={{ width: '100%', mb: 2, mt: 4, boxShadow: 'none' }}>
            <TableContainer>
              <Table
                style={{
                  background: '#fff',
                  color: '#303232',
                }}
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
              >
                <EnhancedTableHead
                  t={t}
                  selected={selected}
                  selectCreatedAtA={selectCreatedAtA}
                  selectCreatedAtD={selectCreatedAtD}
                />
                <TableBody>
                  {register &&
                    register?.result.map((row, index) => {
                      return (
                        <TableRow
                          key={index}
                          style={{ cursor: 'pointer' }}
                          hover
                        >
                          <TableCell
                            style={{ fontSize: '14px' }}
                            align="center"
                            onClick={() => handleGoTo(row)}
                          >
                            {row?.createdAt
                              ? moment(row.createdAt).format(
                                  'DD/MM/yyyy hh:mm:ss'
                                )
                              : '-'}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: '14px' }}
                            align="center"
                            onClick={() => handleGoTo(row)}
                          >
                            {row?.documentID ?? '-'}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: '14px' }}
                            align="center"
                            onClick={() => handleGoTo(row)}
                          >
                            {row?.documentName ?? '-'}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: '14px' }}
                            align="center"
                            onClick={() => handleGoTo(row)}
                          >
                            {row?.creatorName ?? '-'}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: '14px' }}
                            align="center"
                            onClick={() => handleGoTo(row)}
                          >
                            {row?.creatorEmail ?? '-'}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: '14px' }}
                            align="center"
                            onClick={() => handleGoTo(row)}
                          >
                            {row?.status === 'approved' ? (
                              <Tag color="green">Aprobado</Tag>
                            ) : row?.status === 'pending_approval' ? (
                              <Tag color="purple">Aprobación pendiente</Tag>
                            ) : (
                              <Tag color="red">Rechazado</Tag>
                            )}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: '14px' }}
                            align="center"
                            onClick={() => handleGoTo(row)}
                          >
                            {row?.signType === 'ELECTRONIC_SIGNATURE'
                              ? `${t('electronicSignature')}`
                              : 'Otra'}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="content_pagination_table_custom">
              {/* Show data */}
              <div className="content_feature_to_pagunation">
                <p>{t('show')}</p>
                <select
                  name="table_contract"
                  id="table_contract"
                  className="select_limit_to_pagination"
                  onChange={(e) => setLimit(e.target.value)}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                <p>
                  {' '}
                  {t('of')} {register?.totalRecords}
                </p>
              </div>
              {/* Pagination */}
              <Pagination
                secondary
                firstItem={null}
                lastItem={null}
                activePage={activePage}
                onPageChange={handlePaginationChange}
                totalPages={register?.totalPages ?? 0}
              />
            </div>
          </Paper>
        </Box>
      </Dimmer.Dimmable>
    </>
  )
}

export default AprovalTableComponent
