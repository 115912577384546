import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  Container: {
    width: '100%',
    //height: '1000px',
    border: '0.5px solid #c8c8c8',
  },
  Tools: {
    height: '50px',
    backgroundColor: 'var(--secondary-color)',
    color: '#eee',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  Tool: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  MoreTools: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  Contract: {
    height: '900px',
    backgroundColor: '#f2f2f2',
    cursor: 'pointer',
    boxShadow:
      '0 1px 2px -2px rgba(218, 218, 218, 0.16), 0 4px 7px 1px #4b4b4b18 !important',
    border: '2px solid #e6e6e6',
    transition: 'transition: 0.3s color ease, 0.3s background-color ease',
    '&:hover, &:active, &:focus': {
      backgroundColor: '#f186aa2c',
      borderColor: 'var(--secondary-color)',
      boxShadow: 'none !important',
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  uploadPDF: {
    fontSize: '25px',
    color: 'var(--secondary-color)',
    cursor: 'pointer',
  },
  instructions: { fontSize: '20px', color: '#777777', cursor: 'pointer' },
  showPDF: {
    width: '100%',
    height: '900px',
    padding: '50px',
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    backgroundColor: '#E6E6E6',
    flexDirection: 'row',
    position: 'relative',
    zIndex: '98',
  },
  Right: {
    width: '100%',
    height: '950px',
    backgroundColor: '#F6F8FA',
    position: 'relative',
    overflowY: 'auto',
  },
  Right_Title: {
    width: '100%',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '20px',
    fontSize: '18px',
  },
  Final: {
    width: '100%',
    // height: '80px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1.3rem 1.5rem',
    flexWrap: 'wrap',
  },
  save: {
    color: 'var(--secondary-color)',
    border: '1px solid var(--secondary-color)',
  },
  ddzone: {
    position: 'absolute',
    zIndex: '95',
    overflow: 'hidden',
  },
  taskB: {
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '100',
    cursor: 'pointer',
    padding: '0px 10px',
  },
  mainDiv: {
    position: 'absolute',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '90',
    cursor: 'pointer',
  },
  emailBox: {
    top: 0,
    left: 0,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: '10px',
    overflow: 'hidden',
    alignItems: 'center',
  },
  rightDiv: {
    display: 'inline-flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  rightDiv_Close: {
    backgroundColor: 'tomato',
    borderRadius: '100%',
    color: 'white',
    fontSize: 'inherit',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  divLi: {
    // width: '100%',
    // height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // cursor: 'pointer',
    backgroundColor: 'white',
    margin: '10px 0',
    border: '1px solid #C4C4C4',
    padding: '0.9rem 1rem',
    fontSize: 'clamp(0.75rem, 0.8rem, 0.85rem)',
    '&:hover, &:active, &:focus': {
      border: '1px solid var(--secondary-color)',
    },
  },
  delLi: {
    width: '15%',
    // height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentInfoAddSign: {
    width: '85%',
    display: 'flex',
    flexDirection: 'column',
  },
  spanNameSigner: {
    color: '#646464',
  },
  spanEmailSigner: {
    color: '#646464',
  },
  btnDeleteSigner: {
    backgroundColor: 'transparent',
    borderRadius: '2px',
    color: '#ff6363',
    padding: '0rem 0.5rem 0.2rem',
    fontSize: '1.2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    border: '0.5px solid #ff6363',
    transition: 'all ease 1.5ms',
    '&:hover, &:active, &:focus': {
      backgroundColor: '#ff6363',
      color: '#fff',
      outline: 'none',
    },
  },
  signMeData: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 'clamp(0.8em, 0.85em, 0.9em)',
    margin: '1rem 0 0 0',
    backgroundColor: '#fff',
    border: '1px solid #C4C4C4',
    padding: '1rem 1rem',
  },
  dataMe: {
    color: '#808080',
    '&>p': {
      margin: '0',
      padding: '0',
    },
  },
  actionMe: {
    color: 'var(--primary-color)',
    padding: '0.3rem 0.6rem',
    cursor: 'pointer',
    border: '1px solid var(--primary-color)',
    borderRadius: '3px',
    '&:hover, &:active, &:focus': {
      backgroundColor: 'var(--primary-color)',
      color: '#fff',
      outline: 'none',
    },
  },
  ddzoneTest: {
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    margin: '1rem auto 1.2rem auto',
    flexDirection: 'column',
    padding: '0.5rem 0',
  },
  ddzoneB: {
    // width: '300px',
    height: 'auto',
    // border: '1px solid black',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    margin: '1rem auto auto auto',
    flexDirection: 'column',
    padding: '0.5rem 0',
  },
  title_signatories: {
    margin: '1rem 0 1rem 0',
    color: 'var(--secondary-color)',
    fontSize: 'clamp(0.95em, 1em, 1.4em)',
  },
  save_configurations: {
    outline: 'none !important',
    fontSize: '0.95rem',
    '&:hover, &:active, &:focus': {
      outline: 'none !important',
      borderColor: 'var(--secondary-color)',
      color: 'var(--secondary-color)',
    },
  },
  save_configurations_solid: {
    borderColor: 'var(--secondary-color)',
    color: 'var(--secondary-color)',
    outline: 'none !important',
    margin: '5px 0',
    '&:hover, &:active, &:focus': {
      outline: 'none !important',
      borderColor: 'var(--secondary-color)',
      backgroundColor: 'var(--secondary-color)',
      color: 'white',
    },
  },
  Collapse_custom: {
    '& .ant-collapse-item': {
      backgroundColor: 'rgb(238, 238, 238)',
    },
  },
  // Form
  config_form__two__column: {
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  content_save_config: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: '1rem',
  },
  // Confirm data
  content_sign__document: {
    '& h4': {
      color: '#373737',
    },
  },
  description__document: {
    color: '#a5a5a5',
    fontSize: 'clamp(0.8rem, 0.88rem, 0.98rem)',
  },
  form__document: {
    '& label': {
      color: '#373737',
    },
    '& input, & textarea': {
      outline: 'none',
      '&:hover, &:focus, &:active': {
        borderColor: 'var(--secondary-color)',
        boxShadow: '0 0 0 2px rgba(255, 193, 24, 0.2)',
      },
    },
  },
  content_data__document: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill,minmax(50%,1fr))',
    margin: '1rem 0 0 0',
    '& h5': {
      color: 'var(--secondary-color)',
    },
  },
  list__document: {
    color: '#8e8e8e',
    padding: '10px 40px 0 0',
    fontSize: 'clamp(0.75rem,0.81rem,0.91rem)',
    '& li': {
      padding: '10px 0',
    },
  },
}))
