import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'
import { Button } from 'antd'

export const Right = styled(Grid)({
  width: '100%',
  height: '950px',
  backgroundColor: '#F6F8FA',
  position: 'relative',
  overflowY: 'auto',
  userSelect: 'none',
})

export const RightTitle = styled(Grid)({
  width: '100%',
  height: '50px',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '20px',
  fontSize: '18px',
})

export const Container = styled(Grid)({
  height: '50px',
  marginTop: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const MyButton = styled(Button)({
  border: '1px solid #28295a',
  color: '#28295a',
  outline: 'none !important',
  margin: '1.5rem 0.8rem 1rem 0rem',
  '&:hover, &:active, &:focus': {
    outline: 'none !important',
    borderColor: '#28295a',
    backgroundColor: '#28295a',
    color: 'white',
  },
})
