import React from 'react'

import './PlatformLabelWithInfo.css'
import notFound from '../../../assets/broken-1.png'

const PlatformLabelWithInfo = ({
  icon,
  upper,
  text,
  backgroundColor,
  color,
  photo,
  name,
  bio,
  age,
  id,
  handle,
  gender,
  country,
  city,
  lastSeen,
}) => {
  const date = lastSeen ? new Date(lastSeen * 1000) : null
  const socialName = upper + text.slice(1)
  const profilePicture = photo?.includes('http')
    ? photo
    : !photo
    ? notFound
    : `data:image/png;base64,${photo}`

  return (
    <div className="PlatformLabelWithInfo">
      <div
        className="PlatformLabelWithInfo-label"
        style={{
          backgroundColor,
        }}
      >
        {icon}
        <h3 style={{ color, width: '80%' }}>{socialName}</h3>
      </div>
      <div className="PlatformLabelWithInfo-info">
        <div className="PlatformLabelWithInfo-profile">
          <img
            className="PlatformLabelWithInfo-image"
            src={profilePicture}
            alt="profilePicture"
          />
          <div className="PlatformLabelWithInfo-contact-info">
            {name ? (
              <>
                <h3>
                  <strong>{name}</strong>
                </h3>
                <p>{city ? `${city}, ${country}` : country}</p>
              </>
            ) : lastSeen ? (
              <>
                <h4>
                  <strong>Last seen</strong>
                </h4>
                <p>{date?.toDateString()}</p>
              </>
            ) : null}
          </div>
        </div>
        <div className="PlatformLabelWithInfo-more-info">
          <table style={{ width: '100%' }}>
            <tbody>
              {bio ? (
                <tr>
                  <td style={{ width: '30%' }}>
                    <strong>Bio:</strong>
                  </td>
                  <td style={{ width: '70%' }}>{bio}</td>
                </tr>
              ) : null}
              {id ? (
                <tr>
                  <td style={{ width: '30%' }}>
                    <strong>{socialName} ID:</strong>
                  </td>
                  <td style={{ width: '70%' }}>{id}</td>
                </tr>
              ) : null}
              {handle ? (
                <tr>
                  <td style={{ width: '30%' }}>
                    <strong>Handle:</strong>
                  </td>
                  <td style={{ width: '70%' }}>{handle}</td>
                </tr>
              ) : null}
              {gender ? (
                <tr>
                  <td style={{ width: '30%' }}>
                    <strong>Gender:</strong>
                  </td>
                  <td style={{ width: '70%' }}>
                    {gender === 1 ? 'Male' : 'Female'}
                  </td>
                </tr>
              ) : null}
              {age ? (
                <tr>
                  <td style={{ width: '30%' }}>
                    <strong>Age:</strong>
                  </td>
                  <td style={{ width: '70%' }}>{age}</td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default PlatformLabelWithInfo
