import React, { useState } from 'react'
import { Tooltip } from 'antd'
import {
  MyGrid,
  OnlyMe,
  OnlyMeBox,
  OnlyMeBoxImage,
  OnlyMeBoxContainer,
  DDZoneB,
  Rubric,
} from './SignsStyle'
import { VscInfo } from 'react-icons/vsc'

export default function Signs(props) {
  const {
    totalPages,
    pageSize,
    user,
    zoom,
    sign,
    signers,
    signPositions,
    setSignPositions,
    boxSign,
    dd_dragstart,
    whoSign,
  } = props

  const [rubric, setRubric] = useState(false)

  const handleRubric = (e) => {
    setRubric(e.target.checked)

    if (e.target.checked) {
      let internalSigners = []
      let internalPageSizeX = parseInt(pageSize[0].replace('px', '')) - 136
      let internalPageSizeY = parseInt(pageSize[1].replace('px', '')) - 73

      if (whoSign !== 'others')
        internalSigners.push({
          email: user.email,
          name: user.name,
          uuid: '',
          biometric: '',
        })
      if (signers.length > 0) internalSigners.push(...signers)

      let newA = []
      let x = 20 * zoom
      let y = 10 * zoom

      for (let page = 0; page < totalPages; page++) {
        x = 20 * zoom
        y = 10 * zoom

        for (let signer of internalSigners) {
          newA.push({
            email: signer.email,
            name: signer.name !== '' ? signer.name : signer.email,
            page: page + 1,
            positionX: x,
            positionY: y,
            zoom,
            rubric: true,
            isEndorse: signer.isEndorse || false
          })

          y = y + 78 * zoom

          if (y > internalPageSizeY) {
            x = internalPageSizeX * zoom
            y = 10 * zoom
          }
        }
      }
      newA.push(...signPositions)
      setSignPositions(newA)
    } else {
      let newA = []
      for (let signPosition of signPositions)
        if (!signPosition.rubric) newA.push(signPosition)
      setSignPositions(newA)
    }
  }

  return (
    <>
      {whoSign !== 'others' ? (
        <OnlyMe onDragStart={(e) => dd_dragstart(e)}>
          <OnlyMeBox
            draggable={true}
            id={user.email}
            width={`${136 * zoom}px`}
            height={`${75 * zoom}px`}
          >
            <OnlyMeBoxImage
              src={boxSign}
              alt="caja"
              draggable={false}
              width={`${136 * zoom}px`}
              height={`${75 * zoom}px`}
            />
            <OnlyMeBoxContainer
              width={`${
                sign.signature === ''
                  ? 113 * zoom
                  : sign.option !== 3
                  ? 80 * zoom
                  : 113 * zoom
              }px`}
              height={`${35 * zoom}px`}
              fsize={`${9 * zoom}px`}
              bimage={`url(${sign.signature})`}
              bsize={`${sign.option !== 3 ? 80 * zoom : 113 * zoom}px ${
                35 * zoom
              }px`}
            >
              Yo
            </OnlyMeBoxContainer>
          </OnlyMeBox>
        </OnlyMe>
      ) : null}
      {whoSign !== 'others' || signers.length > 0 ? (
        <Rubric className="signers_Li">
          <div style={{ marginRight: '14px' }}>
            <Tooltip title="Agrega firmas en todas las hojas">
              <VscInfo
                color="rgb(140, 140, 140)"
                size={16}
                style={{
                  cursor: 'pointer',
                  marginRight: '15px',
                }}
              />
            </Tooltip>
            <span>{rubric ? 'Quitar Rubrica' : 'Rubricar'}</span>
          </div>
          <input type="checkbox" checked={rubric} onChange={handleRubric} />
        </Rubric>
      ) : null}
      {whoSign !== 'me' ? (
        <>
          <MyGrid marginBottom={5}>Firmante(s)</MyGrid>
          <span className="description_config_client">
            Ubique en donde quiere visualizar la firma de sus firmantes.
          </span>
        </>
      ) : null}
      {whoSign === 'me' && signers.length === 1 ? (
        <>
          <MyGrid marginBottom={5}>Representante Legal</MyGrid>
          <span className="description_config_client">
            Ubique en donde quiere visualizar la firma del representante legal.
          </span>
        </>
      ) : null}

      <DDZoneB id="ddzoneB" onDragStart={(e) => dd_dragstart(e)}></DDZoneB>
    </>
  )
}
