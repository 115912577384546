import React from 'react'
import {
  Container,
  Logo,
  InternalContainer,
  Time,
  Clean,
} from './Step1Option2.jsx'
import BoxSign from '../../../../../../assets/caja firma.svg'
import { VscTrash } from 'react-icons/vsc'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTranslation } from 'react-i18next'

export default function Step1Option2(props) {
  const { signatureData, setSignatureData, today } = props
  const { t } = useTranslation()
  const matches = useMediaQuery('(max-width:600px)')

  const clickPDF = () => document.getElementById('fileIMG').click()

  const stopDefault = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const dropPDF = (e) => {
    stopDefault(e)
    const fileInput = document.getElementById('fileIMG')
    fileInput.files = e.dataTransfer.files
    changeInput()
  }

  const changeInput = () => {
    const fileInput = document.getElementById('fileIMG')

    if (fileInput.files[0]) {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(fileInput.files[0])
      fileReader.onload = (x) => setSignatureData(x.target.result)
    } else setSignatureData(null)
  }

  const handleClear = () => setSignatureData(null)

  return (
    <Container style={{ height: matches ? '245px' : '292px' }}>
      <Logo src={BoxSign} alt="boxSign" draggable={false} />
      {signatureData ? (
        <InternalContainer
          style={{ height: matches ? '110px' : '165px' }}
          logo={signatureData}
        >
          <Clean onClick={handleClear}>
            <VscTrash size={20} color={'tomato'} strokeWidth={0.3} />
          </Clean>
        </InternalContainer>
      ) : (
        <InternalContainer
          style={{ height: matches ? '110px' : '165px' }}
          logo={signatureData}
          onClick={clickPDF}
          onDrop={(e) => dropPDF(e)}
          onDragOver={(e) => stopDefault(e)}
          onDragEnter={(e) => stopDefault(e)}
          onDragLeave={(e) => stopDefault(e)}
        >
          {`${t('clickOrDrag')}`}
          <input
            style={{ display: 'none' }}
            accept="image/png"
            type="file"
            name="fileIMG"
            id="fileIMG"
            onChange={changeInput}
          />
        </InternalContainer>
      )}
      <Time>{today}</Time>
    </Container>
  )
}
