import React, { Fragment } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import ShowVerifications from '../components/PanelExperts/ShowVerifications'
import 'semantic-ui-css/semantic.css'

const PanelExperts = () => {
  const { type } = useParams()
  const { t } = useTranslation()
  const isSecurityScanner = type === 'scanner'

  return (
    <Fragment>
      <h1
        style={{
          color: 'rgb(68, 68, 68)',
          fontSize: 'clamp(1.5em, 1.7em, 1.9em)',
        }}
      >
        {t('panelExperts')} - {t(`${type}`)}
      </h1>
      <ShowVerifications type={type} isSecurityScanner={isSecurityScanner}/>
    </Fragment>
  )
}

export default PanelExperts
