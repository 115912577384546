import React, { Fragment, useState } from 'react'
import { Button, Input, Form, Transition, Label } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

import '../ConsultationServices.css'
import CardEmailWithInfo from './CardEmailWithInfo'
import useToken from '../../../utils/useToken'
import { API_URL } from '../../../const/index'
import { useAuth0 } from '../../../react-auth0-spa'

const EMAIL = ({
  id,
  uuid,
  email,
  emailStatus,
  dataEmailFound,
  setIsEmailVerified,
  handleChangeInputEmail,
  onVerifyEmail,
}) => {
  const [dataEmail, setDataEmail] = useState(null)
  const [verificando, setVerificando] = useState(false)

  //Control de errores
  const [inputError, setInputError] = useState(false)
  const [inputMsgErr, setInputMsgErr] = useState('')

  const { t } = useTranslation()
  const { user } = useAuth0()
  const getToken = useToken()

  const callToEmailApi = async () => {
    setVerificando(true)
    try {
      const token = await getToken({
        audience: 'veridocid',
      })

      const response = await fetch(`${API_URL}/social/checkEmail`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
          uuid,
          email,
          name: user.name,
          emailUser: user.email,
        }),
      })

      if (response.ok) {
        const responseJSON = await response.json()

        if (responseJSON?.success === true) {
          onVerifyEmail(responseJSON)
          setDataEmail(responseJSON)
          setIsEmailVerified(true)
        } else {
          setIsEmailVerified(false)
          //Error
          setInputError(true)
          setInputMsgErr(responseJSON?.error?.message)
          setTimeout(() => {
            setInputError(false)
          }, 3000)
        }
      } else {
        setInputError(true)
        setInputMsgErr(`${t('invalidEmailFormat')}`)
        setTimeout(() => {
          setInputError(false)
        }, 3000)
        //console.log(`La respuesta de la API Email no se encontro`)
      }

      setVerificando(false)
    } catch (error) {
      console.log(`La llamada a la API de Email fallo`)
      setVerificando(false)
    }
  }

  const handleClick = () => {
    if (email !== '') {
      callToEmailApi()
      setInputError(false)
    } else {
      setInputError(true)
      setInputMsgErr(`${t('messageErrorEmptyInputEmail')}`)
      setTimeout(() => {
        setInputError(false)
      }, 3000)
    }
  }

  return (
    <Fragment>
      <div className="ui container">
        {emailStatus ? (
          <CardEmailWithInfo
            dataEmail={dataEmailFound?.data || dataEmail?.data}
          />
        ) : (
          <>
            <Form>
              <Form.Field>
                <p>{t('email')}</p>
                <Input
                  fluid
                  placeholder="example@example.com"
                  value={email}
                  onChange={handleChangeInputEmail}
                />

                <Transition
                  animation="drop"
                  duration={500}
                  visible={inputError}
                >
                  <Label
                    pointing
                    color="red"
                    as="span"
                    style={{ lineHeight: '15px' }}
                  >
                    {`${inputMsgErr}`}
                  </Label>
                </Transition>
              </Form.Field>
            </Form>
            <br />
            <div className="ButtonsCard">
              <Button
                onClick={handleClick}
                disabled={verificando}
                loading={verificando}
              >
                {t('send')}
              </Button>
            </div>
          </>
        )}
      </div>
    </Fragment>
  )
}

export default EMAIL
