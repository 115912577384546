import React, { useState } from 'react'

import './DocumentData.css'
import { labels } from '../../../../fixtures/panelExperts.fixture'
import EditDocumentData from './EditDocumentData/EditDocumentData'

const DocumentData = (props) => {
  const [isEdit, setIsEdit] = useState(false)

  const rows = props.response?.map((resp, index) => (
    <tr key={index}>
      <td className="RowsDocumentData">
        <b>{resp?.name}</b>
      </td>
      <td className="RowsDocumentData">{resp?.value}</td>
    </tr>
  ))

  const onClickEditDataHandler = (resp) => {
    setIsEdit(resp)
  }

  const content = isEdit ? (
    <EditDocumentData
      clicked={() => onClickEditDataHandler(!isEdit)}
      data={props.response}
    />
  ) : (
    <div className="DocumentData">
      <table className="TableDocumentData">
        <thead>
          <tr>
            <th className="ThDocumentData">
              <b>{labels.field}</b>
            </th>
            <th className="ThDocumentData">
              <b>{labels.value}</b>
            </th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
      <button
        className="EditButton"
        onClick={() => onClickEditDataHandler(!isEdit)}
      >
        {labels.editDocumentData}
      </button>
    </div>
  )

  return <div>{content}</div>
}

export default DocumentData
