import React, { useState } from 'react'
import RecordsFiltersContext from './RecordsFilters.context'
const RecordsFilters = ({ children }) => {
  const setId = (id) => setState((prevState) => ({ ...prevState, id }))
  const setIsSearching = (isSearching) =>
    setState((prevState) => ({ ...prevState, isSearching }))

  const search = () => {
    setIsSearching(true)
  }
  const [state, setState] = useState({
    id: '',
    setId,
    isSearching: false,
    setIsSearching,
    search,
  })
  return (
    <RecordsFiltersContext.Provider value={state}>
      {children}
    </RecordsFiltersContext.Provider>
  )
}

export default RecordsFilters
