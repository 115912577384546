import React, { useState } from 'react'
import { Grid } from '@mui/material'
import { Button, message as signMessage } from 'antd'
import {
  ObserversGrid,
  ObserversGridLeft,
  ObserversGridRight,
  ObserversCard,
} from './ObserversStyle.jsx'
import { VscAdd, VscCircleSlash } from 'react-icons/vsc'
import './ObserversStyle.css'
import { useTranslation } from 'react-i18next'

// Text mask
import MaskedInput from 'react-text-mask'
import emailMask from 'text-mask-addons/dist/emailMask'

export default function Observers(props) {
  const { observers, setObservers } = props
  const { t } = useTranslation()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [showCard, setShowCard] = useState(false)

  const handleShowCard = () => setShowCard(!showCard)

  const handleName = (e) => setName(e.target.value)

  const handleEmail = (e) => setEmail(e.target.value)

  const handleAddObserver = () => {
    if (name === '' || email === '') {
      signMessage.warning(`${t('completeDataForm')}`)
    } else {
      setObservers([
        ...observers,
        {
          name,
          email,
        },
      ])
      // Reset form
      setName('')
      setEmail('')
    }
  }

  const handleCancelObserver = () => {
    setName('')
    setEmail('')
  }

  return (
    <>
      <ObserversGrid>
        <ObserversGridLeft>
          <p style={{ color: 'var(--secondary-color)', margin: '0' }}>
            Observadores
          </p>
          <p className="description_config_client">
            Agregue uno o varios observadores para que sigan el proceso de firma
          </p>
        </ObserversGridLeft>
        <ObserversGridRight>
          <Button
            onClick={handleShowCard}
            size="small"
            style={{
              backgroundColor: '#e9e9e9',
              color: 'rgba(0,0,0,.85)',
              border: 'none',
              outline: 'none',
            }}
          >
            Agregar
          </Button>
        </ObserversGridRight>
      </ObserversGrid>
      {showCard ? (
        <ObserversCard item xs={12}>
          <div className="content-input_add">
            <label>Nombre</label>
            <input
              type="text"
              name="name_Signatory"
              value={name}
              onChange={handleName}
              placeholder="Nombre del observador"
            />
          </div>
          <div className="content-input_add">
            <label>Correo</label>
            <MaskedInput
              name="email_observer"
              keepCharPositions={false}
              mask={emailMask}
              guide={true}
              placeholder="Correo del observador"
              onChange={handleEmail}
              value={email}
            />
          </div>
          <Grid container className="actions_add">
            <button
              type="button"
              className="new_add"
              onClick={handleAddObserver}
            >
              <VscAdd color="white" size={20} strokeWidth={1} />
            </button>
            <button
              type="button"
              className="cancel_add"
              onClick={handleCancelObserver}
            >
              <VscCircleSlash color="white" size={20} strokeWidth={1} />
            </button>
          </Grid>
        </ObserversCard>
      ) : null}
      <div
        id="orderObserver"
        style={{
          width: '100%',
          padding: '0',
          marginTop: '20px',
        }}
      ></div>
    </>
  )
}
