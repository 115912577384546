import { InboxOutlined } from '@ant-design/icons'
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Form } from 'semantic-ui-react'
import CardOcrAddressWithInfo from './CardOcrAddressWithInfo'

import { API_URL } from '../../../const/index'
import { useAuth0 } from '../../../react-auth0-spa'
import { checkOcrAddress } from '../../../utils/checkOcrAddress'
import { getDataOcrAddress } from '../../../utils/getDataCustomize'
import useToken from '../../../utils/useToken'
import Upload from '../../Upload'
import '../ConsultationServices.css'

const CardOcrAddress = ({
  id,
  ocrAddressStatus,
  dataOcrAddressFound,
  setIsOcrAddressVerified,
  onVerifyOcrAddress,
  uuid,
}) => {
  const getToken = useToken()
  const { user, isAuthenticated } = useAuth0()
  const { t } = useTranslation()
  const [dataOcrAddress, setDataOcrAddress] = useState(null)
  const [verificando, setVerificando] = useState(false)
  const [isImageSelected, setIsImageSelected] = useState(false)
  const [image, setImage] = useState(null)
  const [clientID, setClientID] = useState({})

  useEffect(() => {
    if (isAuthenticated) {
      setClientID(user?.['https://vdid.sumamexico.com/app_metadata'] ?? {})
    }
  }, [isAuthenticated, user])

  const callToOcrAddress = async () => {
    setVerificando(true)
    try {
      const token = await getToken({
        audience: 'veridocid',
      })
      const tokenDashboard = await getToken({
        audience: 'veridocid-dashboard',
      })

      const response = await fetch(`${API_URL}/ocr/analyze`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
          image,
          uuid,
          name: user.name,
          email: user.email,
        }),
      })

      if (response.ok) {
        const ocrId = await response.text()
        const responseJSON = await checkOcrAddress({ ocrId, token })
        const ocrResponse = await getDataOcrAddress({
          token: tokenDashboard,
          uuid,
        })

        onVerifyOcrAddress({
          data: responseJSON,
          filePath: ocrResponse?.ocrAddress?.filePath,
        })
        setDataOcrAddress({
          data: responseJSON,
          filePath: ocrResponse?.ocrAddress?.filePath,
        })
        setIsOcrAddressVerified(true)
      } else {
        const errorJSON = await response.json()

        onVerifyOcrAddress(errorJSON)
        setDataOcrAddress(errorJSON)
        setIsOcrAddressVerified(true)
      }

      setVerificando(false)
    } catch (error) {
      console.log(`La llamada a la API de ocr analyze fallo`)
      setVerificando(false)
    }
  }

  const onUpload = async ({ file }) => {
    let base64 = null
    setIsImageSelected((prevIsImageSelected) => !prevIsImageSelected)
    const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    }

    if (file) {
      base64 = await getBase64(file)
      base64 = base64.split('base64,')[1]
      setImage(base64)
    }
  }

  const handleClick = () => {
    callToOcrAddress()
  }

  React.useEffect(() => {
    if (!ocrAddressStatus) {
      setIsImageSelected(false)
    }
  }, [ocrAddressStatus])

  return (
    <Fragment>
      <div className="ui container">
        {ocrAddressStatus ? (
          <CardOcrAddressWithInfo
            dataOcrAddress={dataOcrAddressFound || dataOcrAddress}
            setIsOcrAddressVerified={setIsOcrAddressVerified}
            setIsImageSelected={setIsImageSelected}
            receiptFound={
              dataOcrAddressFound?.filePath || dataOcrAddress?.filePath
            }
            clientID={clientID}
          />
        ) : (
          <>
            <Form>
              <Form.Field>
                <Upload id="reverso" onUpload={onUpload}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                </Upload>
              </Form.Field>
            </Form>
            <br />
            <div className="ButtonsCard">
              <Button
                onClick={handleClick}
                disabled={!isImageSelected || verificando}
                loading={verificando}
              >
                {t('send')}
              </Button>
            </div>
          </>
        )}
      </div>
    </Fragment>
  )
}

export default CardOcrAddress
