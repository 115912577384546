import React from 'react'
import { VscEdit, VscFileMedia, VscSymbolKey, VscSaveAs } from 'react-icons/vsc'
import { Box, BoxIcon, BoxName } from './Step0.jsx'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTranslation } from 'react-i18next'

export default function Step0(props) {
  const { option, setOption, handleComplete } = props
  const { t } = useTranslation()
  const matches = useMediaQuery('(max-width:600px)')

  const opc1 = () => {
    setOption({
      option1: true,
      option2: false,
      option3: false,
      option4: false,
    })
    handleComplete()
  }

  const opc2 = () => {
    setOption({
      option1: false,
      option2: true,
      option3: false,
      option4: false,
    })
    handleComplete()
  }

  const opc3 = () => {
    setOption({
      option1: false,
      option2: false,
      option3: true,
      option4: false,
    })
    handleComplete()
  }

  const opc4 = () => {
    setOption({
      option1: false,
      option2: false,
      option3: false,
      option4: true,
    })
    handleComplete()
  }

  return (
    <>
      <Box
        style={{ maxWidth: matches ? '100%' : '40%' }}
        container
        onClick={opc1}
        selected={option.option1}
      >
        <BoxName item>{`${t('handwritten')}`}</BoxName>
        <BoxIcon item>
          <VscEdit size={22} strokeWidth={0.1} />
        </BoxIcon>
      </Box>
      <Box
        style={{ maxWidth: matches ? '100%' : '40%' }}
        container
        onClick={opc2}
        selected={option.option2}
      >
        {/* <BoxName item xs={10}> */}
        <BoxName item>{`${t('image')}`}</BoxName>
        {/* <BoxIcon item xs={2}> */}
        <BoxIcon item>
          <VscFileMedia size={22} strokeWidth={0.1} />
        </BoxIcon>
      </Box>
      <Box
        style={{ maxWidth: matches ? '100%' : '40%' }}
        container
        onClick={opc3}
        selected={option.option3}
      >
        <BoxName item>{`${t('name')}`}</BoxName>
        <BoxIcon item>
          <VscSymbolKey size={25} strokeWidth={0.1} />
        </BoxIcon>
      </Box>
      <Box
        style={{ maxWidth: matches ? '100%' : '40%' }}
        container
        onClick={opc4}
        selected={option.option4}
      >
        <BoxName item>{`${t('signatureSaved')}`}</BoxName>
        <BoxIcon item>
          <VscSaveAs size={25} strokeWidth={0.1} />
        </BoxIcon>
      </Box>
    </>
  )
}
