import React from 'react'

import './PlatformLabel.css'

const PlatformLabel = ({ icon, upper, name, backgroundColor, color }) => (
  <div
    className="PlatformLabel"
    style={{
      backgroundColor,
    }}
  >
    {icon}
    <h3 style={{ color, width: '60%' }}>
      {upper}
      {name.slice(1)}
    </h3>
  </div>
)

export default PlatformLabel
