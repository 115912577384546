import React, { Fragment, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'

// Components
import { Grid, Button } from 'semantic-ui-react'
import { InboxOutlined } from '@ant-design/icons'
import { message } from 'antd'
import Upload from '../components/Upload'
import ModalVerification from '../components/UI/ModalVerification/ModalVerification'

// Utils
import { checkVerificationStatus } from '../utils/checkVerificationStatus'
import useToken from '../utils/useToken'
import { API_URL } from '../const'
import { useAuth0 } from '../react-auth0-spa'
import {
  initialErrorModal,
  initalSendedModal,
  initialWarningModal,
} from '../fixtures/panelExperts.fixture'

// Styles
import '../index.css'

const FormToSendVerification = () => {
  const { t } = useTranslation()
  const { user } = useAuth0()
  const history = useHistory()

  const [anverso, setAnverso] = useState(null)
  const [reverso, setReverso] = useState(null)
  const [facial, setFacial] = useState(null)
  const [identifier, setIdentifier] = useState('')
  const [verificando, setVerificando] = useState(false)
  const [isSended, setIsSended] = useState(initalSendedModal)
  const [isError, setIsError] = useState(initialErrorModal)
  const [isWarning, setIsWarning] = useState(initialWarningModal)
  const [isWaiting, setIsWaiting] = useState(false)

  const getToken = useToken()

  /** */
  const verificar = async () => {
    if (!identifier) {
      message.error('Es necesario escribir un identificador a tu registro')
      return
    }

    if (!anverso) {
      message.error('La imagen de anverso es requerida')
      return
    }

    setVerificando(true)
    setIsWaiting(true)

    try {
      const token = await getToken({
        audience: 'veridocid',
      })
      let body

      body =
        anverso && reverso && facial
          ? JSON.stringify({
              id: identifier.trim(),
              frontImage: anverso,
              backImage: reverso,
              faceImage: facial,
              name: user.name,
              email: user.email,
              user_id: user.sub
            })
          : anverso && reverso
          ? JSON.stringify({
              id: identifier.trim(),
              frontImage: anverso,
              backImage: reverso,
              name: user.name,
              email: user.email,
              user_id: user.sub
            })
          : anverso && facial
          ? JSON.stringify({
              id: identifier.trim(),
              frontImage: anverso,
              faceImage: facial,
              name: user.name,
              email: user.email,
              user_id: user.sub
            })
          : JSON.stringify({
              id: identifier.trim(),
              frontImage: anverso,
              name: user.name,
              email: user.email,
              user_id: user.sub
            })

      const result = await fetch(`${API_URL}/id/v3/verify`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body,
      })

      if (result.ok) {
        const uuid = await result.text()
        const status = await checkVerificationStatus({
          uuid,
          token,
          isScanner: false,
        })
        if (status === 'Checked') {
          history.push(`/resultados/${uuid}`)
          return
        } else if (
          [
            'ManualIdentification',
            'ManualChecking',
            'ExpertManualIdentification',
            'ExpertManualChecking',
          ].includes(status)
        ) {
          setIsWaiting(false)
          setIsSended({ isSended: true, open: true })
        } else {
          setIsWaiting(false)
          setIsWarning({ isWarning: true, open: true })
          return
        }
      } else {
        const error = await result.text()

        if (error === 'ID already exists.') {
          message.error('El id ingresado ya existe.')
          setIsWaiting(false)
        } else {
          const errorJson = await result.json()

          console.error(errorJson)

          setIsWaiting(false)
          setIsError({ isError: true, open: true })
        }
      }

      setVerificando(false)
    } catch (error) {
      setIsWaiting(false)
      setIsError({ isError: true, open: true })
      setVerificando(false)
    }
  }

  /** */
  const onUpload = async ({ id, file }) => {
    // console.log(file)
    const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    }

    let base64 = null

    if (file) {
      base64 = await getBase64(file)
      base64 = base64.split('base64,')[1]
    }

    //console.log(id)
    // console.log(base64)
    switch (id) {
      case 'anverso':
        setAnverso(base64)
        break
      case 'reverso':
        setReverso(base64)
        break
      case 'facial':
        setFacial(base64)
        break
      default:
        break
    }
  }

  /** */
  const generateUUID = () => {
    const identifier = uuidv4()
    setIdentifier(identifier)
  }

  /** */
  return (
    <Fragment>
      <div style={{ margin: '0 0 4em 0' }}>
        <h1
          className="ui header"
          style={{
            color: 'rgb(68,68,68)',
            fontSize: 'clamp(1.7em, 1.79em, 1.8em)',
            margin: '0 0 8px 0',
          }}
        >
          {t('uploadFilesToVerify')}
        </h1>
        <p
          style={{
            fontSize: 'clamp(0.9em, 1.05em, 1.1em)',
            color: 'rgb(45, 47, 109)',
            marginBottom: '1.2em',
          }}
        >
          {t('createId')}
        </p>
        <div className="ui input" style={{ width: '400px' }}>
          <input
            style={{ borderRadius: '4px 0 0 4px' }}
            type="text"
            value={identifier}
            onChange={(e) => {
              const id = e?.target?.value
              setIdentifier(id?.replace(/\s/g, ''))
            }}
            placeholder={t('customerId')}
            maxLength={36}
            minLength={1}
          />{' '}
          <button
            style={{
              background: 'var(--primary-color)',
              color: '#ffffff',
              borderRadius: '0 4px 4px 0',
              fontWeight: '200',
            }}
            className="ui button"
            onClick={generateUUID}
          >
            {t('auto')}
          </button>
        </div>
      </div>
      <Grid columns="equal" stackable textAlign="center">
        <Grid.Row style={{ padding: '2.4em 0' }}>
          <Grid.Column width={4} textAlign={'center'}>
            <h3
              className="ui header"
              style={{
                textAlign: 'center',
                marginBottom: '1.3em',
                color: 'gray',
              }}
            >
              {t('frontImage')}
            </h3>
            {/* Upload images */}
            <Upload id="anverso" onUpload={onUpload}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
            </Upload>
          </Grid.Column>
          <Grid.Column width={4} textAlign={'center'}>
            <h3
              className="ui header"
              style={{
                textAlign: 'center',
                marginBottom: '2.6em',
                color: 'gray',
              }}
            >
              {t('backImage')}
            </h3>
            {/* Upload images */}
            <Upload id="reverso" onUpload={onUpload}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
            </Upload>
          </Grid.Column>
          <Grid.Column width={4} textAlign={'center'}>
            <h3
              className="ui header"
              style={{
                textAlign: 'center',
                marginBottom: '2.5em',
                color: 'gray',
              }}
            >
              {t('faceImage')}
            </h3>
            {/* Upload images */}
            <Upload id="facial" onUpload={onUpload}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
            </Upload>
          </Grid.Column>
        </Grid.Row>
        {/* <Grid.Row>
          <Grid.Column width={1} />
          <Grid.Column width={4} textAlign={'center'}>
            <Upload id="anverso" onUpload={onUpload}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
            </Upload>
          </Grid.Column>
          <Grid.Column width={1} />
          <Grid.Column width={4} textAlign={'center'}>
            <Upload id="reverso" onUpload={onUpload}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
            </Upload>
          </Grid.Column>
          <Grid.Column width={1} />
          <Grid.Column width={4} textAlign={'center'}>
            <Upload id="facial" onUpload={onUpload}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
            </Upload>
          </Grid.Column>
        </Grid.Row> */}
        <Grid.Row style={{ marginBottom: '2em', marginTop: '1.6em' }}>
          <Grid.Column width={4} />
          <Grid.Column textAlign="center" width={8}>
            <p style={{ fontSize: 'clamp(0.1em, 1.2em, 1.4em)' }}>
              <strong style={{ color: '#f72240' }}>{t('note')}:</strong>{' '}
              {t('notePassport')}
            </p>
          </Grid.Column>
          <Grid.Column width={4}></Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3}>
            <Button
              disabled={verificando}
              loading={verificando}
              fluid
              style={{ background: 'var(--primary-color)', color: '#fff' }}
              onClick={verificar}
            >
              {t('verifyDocument')}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <br />
      <br />
      <ModalVerification
        isOpenModal={
          isSended?.open ? isSended : isError?.open ? isError : isWarning
        }
        isLoading={isWaiting}
      />
    </Fragment>
  )
}

export default FormToSendVerification
