export const labels = {
  title: 'Verificación de Documento de Identidad Nivel',
  messageTableOfExperts:
    'La verificación esta siendo revisada por la mesa de expertos.',
  securityFeaturesTitle: 'Patrones Visuales de Seguridad',
  messageVerifying: 'Verificando',
  imageQualityTitleFront: 'Calidad de la Imagen - Anverso',
  imageQualityTitleBack: 'Calidad de la Imagen - Reverso',
  imageQualityTitleDatasheet: 'Calidad de la Imagen - Hoja de Datos',
}

export const status = {
  checking: 'ManualChecking',
  identification: 'ManualIdentification',
  checked: 'Checked',
}
