import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'

// Components
import BodyTemplateSelection from '../components/PanelExperts/TemplateSelection/BodyTemplateSelection'

// Styles
import 'semantic-ui-css/semantic.css'
import { labels } from '../fixtures/panelExperts.fixture'
import '../styles/TemplateSelection.css'
import leftArrow from '../assets/icons/arrow-left.svg'

const TemplateSelection = () => {
  const history = useHistory()

  const onBackHandler = () => {
    history.goBack()
  }

  return (
    <Fragment>
      <div className="TitleTemplate">
        <h1
          className="ui header"
          style={{
            display: 'inline',
            color: 'rgb(68, 68, 68)',
            fontSize: 'clamp(1.7em, 1.75em, 1.8em)',
            marginBottom: '0',
          }}
        >
          {labels.titleVerificationResults}
        </h1>
        <button className="ButtonTemplate" onClick={onBackHandler}>
          <img src={leftArrow} alt="left" className="LeftArrow" />
          {labels.buttonBack}
        </button>
      </div>
      <BodyTemplateSelection />
      <br />
    </Fragment>
  )
}

export default TemplateSelection
