import { Grid } from '@mui/material'
import { Switch } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import './TermsAndConditions.css'

export default function TermsAndConditions(props) {
  const {
    withTermsAndConditions,
    setWithTermsAndConditions,
    listTermsAndConditions,
    setListTermsAndConditions,
  } = props

  const { t } = useTranslation()

  const activeTerms = (event) => {
    setWithTermsAndConditions(event)
  }

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value, checked } = e.target
    const list = [...listTermsAndConditions]
    list[index][name] = name === 'text' ? value : checked
    setListTermsAndConditions([...list])
  }

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...listTermsAndConditions]
    list.splice(index, 1)
    setListTermsAndConditions(list)
  }

  // handle click event of the Add button
  const handleAddClick = () => {
    setListTermsAndConditions([
      ...listTermsAndConditions,
      { checked: false, text: '' },
    ])
  }

  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div style={{ width: '100%' }}>
          <span
            style={{
              fontSize: 'clamp(.9em,1em,1.1em)',
              color: 'var(--secondary-color)',
            }}
          >
            Terminos y Condiciones
          </span>
          <p
            className="description_config_client"
            style={{ fontSize: 'clamp(.5rem,.85rem,.9rem)' }}
          >
            Agregue validacion obligatoria de leer los terminos y condiciones
            antes de firmar
          </p>
        </div>
      </div>

      <Grid
        item
        xs={12}
        style={{
          padding: '1rem 0 0',
          display: 'flex',
          alignItems: 'center',
        }}
        className="config_form__content_input"
      >
        <div>
          <Switch
            defaultChecked
            style={{
              width: '14px',
              marginTop: '-0.4em',
              marginRight: '10px',
            }}
            checked={withTermsAndConditions}
            onChange={activeTerms}
          />
          <label>{withTermsAndConditions ? 'Activo' : 'Inactivo'}</label>
        </div>
      </Grid>
      {withTermsAndConditions ? (
        <>
          {listTermsAndConditions.map((x, i) => {
            return (
              <div key={i}>
                <div className="box">
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      marginBottom: '10px',
                      marginTop: '15px',
                      alignItems: 'center',
                    }}
                    className="signers_Li"
                  >
                    <input
                      type="checkbox"
                      checked={x.checked}
                      name="checked"
                      onChange={(e) => handleInputChange(e, i)}
                      style={{ marginRight: '10px' }}
                      placeholder="Requerido"
                    />
                    <p>¿Es Requerido?</p>
                  </div>

                  <div>
                    <textarea
                      className="textarea_custom"
                      name="text"
                      placeholder="Ingrese el texto sobre los términos y condiciones"
                      value={x.text}
                      onChange={(e) => handleInputChange(e, i)}
                      style={{
                        width: '100%',
                        border: 'none',
                        padding: '0.6rem 0.8rem',
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    marginTop: '15px',
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}
                >
                  <button
                    type="button"
                    onClick={handleAddClick}
                    className="btns_actions_with_background_yellow"
                  >
                    {t('add')}
                  </button>
                  <button
                    type="button"
                    className="removeButtonTerms"
                    onClick={() => handleRemoveClick(i)}
                  >
                    {t('delete')}
                  </button>
                </div>
              </div>
            )
          })}
        </>
      ) : null}
    </>
  )
}
