import React from 'react'
import { Grid } from '@mui/material'
import { Tooltip, DatePicker as DatePickerCustom, Input, Select } from 'antd'
import { CountryDropdown } from 'react-country-region-selector'
import { Button } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { VscListFilter } from 'react-icons/vsc'

const { Option } = Select

export const FilterTable = ({
  t,
  startDate,
  changeStartDate,
  endDate,
  changeEndDate,
  name,
  changeName,
  documentID,
  changeDocumentID,
  documentName,
  changeDocumentName,
  country,
  changeCountry,
  status,
  changeStatus,
  onSearch,
  onClear,
  onExport,
  loadingExport,
}) => {
  const history = useHistory()
  const [showFilters, setShowFilters] = React.useState(false)
  const toContract = () => history.push('contratos/nuevo-contrato')

  return (
    <>
      <div
        style={{
          margin: '0 0 1rem',
          padding: '0.8rem 0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Button
          className="btns_actions_with_background_yellow"
          onClick={toContract}
        >
          {t('newDocument')}
        </Button>
        <div>
          <Tooltip title="Filtrar" placement="top">
            <Button
              className="btn_actions_custom_filter"
              onClick={() => setShowFilters(!showFilters)}
            >
              <VscListFilter size={20} strokeWidth={0.3} />
            </Button>
          </Tooltip>
        </div>
      </div>
      {showFilters ? (
        <div
          style={{
            backgroundColor: '#F8F9FA',
            padding: '2rem 2rem 10px',
            marginBottom: '2rem',
          }}
        >
          {/* Form */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
              <span
                style={{ fontSize: '0.89rem', color: 'var(--black-color)' }}
              >
                {t('documentID')}
              </span>
              <Input
                placeholder="Ingrese el id del documento"
                onChange={changeDocumentID}
                value={documentID}
                style={{ padding: '0.5rem 0.6rem', marginTop: '0.6rem' }}
                className="custom_input_form_search"
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
              <span
                style={{ fontSize: '0.89rem', color: 'var(--black-color)' }}
              >
                {t('documentName')}
              </span>
              <Input
                placeholder="Ingrese el nombre del documento"
                onChange={changeDocumentName}
                value={documentName}
                style={{ padding: '0.5rem 0.6rem', marginTop: '0.6rem' }}
                className="custom_input_form_search"
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
              <span
                style={{ fontSize: '0.89rem', color: 'var(--black-color)' }}
              >
                {t('signerName')}
              </span>
              <Input
                placeholder="Ingrese el nombre del firmante"
                onChange={changeName}
                value={name}
                style={{ padding: '0.5rem 0.6rem', marginTop: '0.6rem' }}
                className="custom_input_form_search"
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
              <span
                style={{ fontSize: '0.89rem', color: 'var(--black-color)' }}
              >
                {t('status')}
              </span>
              <Select
                value={status}
                onChange={changeStatus}
                style={{ width: '100%', marginTop: '0.6rem' }}
                className="custom_input_form_search_doc"
              >
                <Option value="">{t('selectStatus')}</Option>
                <Option value="signed">{t('signedStatus')}</Option>
                <Option value="valid">{t('pendingStatus')}</Option>
              </Select>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
              <span
                style={{ fontSize: '0.89rem', color: 'var(--black-color)' }}
              >
                {t('country')}
              </span>
              <CountryDropdown
                style={{
                  border: '1px solid #d9d9d9',
                  color: '#6a6a6a',
                  width: '100%',
                  padding: '0.5rem 0.6rem',
                  marginTop: '0.6rem',
                  background: '#fff',
                }}
                defaultOptionLabel={`${t('selectCountry')}`}
                value={country}
                priorityOptions={['MX', 'US', 'CA']}
                onChange={changeCountry}
                classes="custom_input_form_search"
              />
            </Grid>

            <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
              <span
                style={{ fontSize: '0.89rem', color: 'var(--black-color)' }}
              >
                {t('startDate')}
              </span>
              <DatePickerCustom
                style={{
                  width: '100%',
                  padding: '0.5rem 0.6rem',
                  marginTop: '0.6rem',
                }}
                onChange={(date, dateString) => changeStartDate(dateString)}
                value={startDate}
                allowClear={false}
                format="YYYY/MM/DD"
                placeholder="Fecha de inicio"
                className="custom_input_form_search"
              />
            </Grid>

            <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
              <span
                style={{ fontSize: '0.89rem', color: 'var(--black-color)' }}
              >
                {t('endDate')}
              </span>
              <DatePickerCustom
                style={{
                  width: '100%',
                  padding: '0.5rem 0.6rem',
                  marginTop: '0.6rem',
                }}
                onChange={(date, dateString) => changeEndDate(dateString)}
                value={endDate}
                allowClear={false}
                format="YYYY/MM/DD"
                placeholder="Fecha de fin"
                className="custom_input_form_search"
              />
            </Grid>
          </Grid>
          {/* Actions */}
          <Grid
            style={{
              margin: '20px 0px 10px 0px',
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row-reverse',
              alignItems: 'center',
              gap: '5px',
            }}
          >
            <div>
              <Tooltip
                title={'Exporta los resultados en un archivo excel'}
                placement="bottom"
              >
                <Button
                  onClick={onExport}
                  variant="outlined"
                  className="btn_export_data"
                  loading={loadingExport}
                  size="tiny"
                >
                  {t('download')}
                </Button>
              </Tooltip>
            </div>
            <div>
              <Tooltip
                title={
                  'Una vez introducidos los valores, dale click para buscar'
                }
                placement="bottom"
              >
                <Button
                  onClick={onSearch}
                  className="btn_search_data"
                  size="tiny"
                >
                  {t('search')}
                </Button>
              </Tooltip>
            </div>
            <div>
              <Tooltip
                title={'Limpiar los filtros de búsqueda'}
                placement="bottom"
              >
                <Button
                  onClick={onClear}
                  className="btn_clear_data"
                  size="tiny"
                >
                  {t('clear')}
                </Button>
              </Tooltip>
            </div>
          </Grid>
        </div>
      ) : null}
    </>
  )
}
