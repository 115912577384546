import React, { Fragment, useEffect } from 'react'
import { find } from 'lodash'
import { Table } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import {
  responseVersion1,
  responseVersion2,
} from '../../fixtures/dataResponse.fixture'

import iconAwesomeCheck from '../../assets/icons/awesome-check.svg'
import iconAwesomeFail from '../../assets/icons/awesome-fail.svg'

import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import MobileStepper from '@mui/material/MobileStepper'
import Button from '@mui/material/Button'
import { VscChevronRight, VscChevronLeft } from 'react-icons/vsc'

const DocumentIDInfo = ({ data }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [activeStep, setActiveStep] = React.useState(0)
  const [steps, setSteps] = React.useState([])
  const maxSteps = 3
  const globalResultDescription = data?.data?.globalResultDescription
  const expertComments = data?.data?.expertComments
  const color = data?.data?.globalResult === 'Ok' ? 'green' : 'red'
  let types

  useEffect(() => {
    setSteps([
      {
        title: 'Datos del documento',
        description: (
          <Table color={color}>
            <Table.Body>
              {globalResultDescription || expertComments ? (
                <Table.Row>
                  <Table.Cell width={5}>
                    <strong>{t('outcomeVerification')}:</strong>
                  </Table.Cell>
                  <Table.Cell>
                    {expertComments ??
                      globalResultDescription ??
                      'No hay datos para mostrar'}
                  </Table.Cell>
                  <Table.Cell width={5}>
                    <strong>{t('document_type_code')}:</strong>
                  </Table.Cell>
                  <Table.Cell>
                    {
                      find(data?.data?.documentData, {
                        type: 'Document Class Code',
                      })?.value
                    }
                  </Table.Cell>
                </Table.Row>
              ) : null}
              {data?.data?.identifier ? (
                <Table.Row>
                  <Table.Cell width={5}>
                    <strong>{t('uniqueIdentifierNumber')}:</strong>
                  </Table.Cell>
                  <Table.Cell>
                    {data?.data?.identifier ?? 'No hay datos para mostrar'}
                  </Table.Cell>
                  <Table.Cell width={5}>
                    <strong>{t('number_of_duplicates')}:</strong>
                  </Table.Cell>
                  <Table.Cell>
                    {
                      find(data?.data?.documentData, {
                        type: 'Number of Duplicates',
                      })?.value
                    }
                  </Table.Cell>
                </Table.Row>
              ) : null}
              {data?.data?.startDateUtc ? <Table.Row></Table.Row> : null}
              <Table.Row>
                <Table.Cell width={5}>
                  <strong>{t('name')}:</strong>
                </Table.Cell>
                <Table.Cell>{fullName || ""}</Table.Cell>
                <Table.Cell width={5}>
                  <strong>{t('dateVerification')}:</strong>
                </Table.Cell>
                <Table.Cell>
                  {(data?.data?.startDateUtc &&
                    data?.data?.startDateUtc.substring(0, 10)) ||
                    'No hay datos para mostrar'}
                </Table.Cell>
              </Table.Row>
              {find(data?.data?.documentData, {
                type: types.documentNumber,
              })?.value && isPassport ? (
                <Table.Row>
                  <Table.Cell width={5}>
                    <strong>{t('documentNumber')}:</strong>
                  </Table.Cell>
                  <Table.Cell>
                    {
                      find(data?.data?.documentData, {
                        type: types.documentNumber,
                      })?.value
                    }
                  </Table.Cell>
                </Table.Row>
              ) : null}
              {find(data?.data?.documentData, {
                type: types.personalNumber,
              })?.value ? (
                <Table.Row>
                  <Table.Cell width={5}>
                    <strong>
                      {isPassport ? t('personalNumber') : t('curp')}:
                    </strong>
                  </Table.Cell>
                  <Table.Cell>
                    {
                      find(data?.data?.documentData, {
                        type: types.personalNumber,
                      })?.value
                    }
                  </Table.Cell>
                  <Table.Cell width={5}>
                    <strong>{t('electorKey')}:</strong>
                  </Table.Cell>
                  <Table.Cell>
                    {
                      find(data?.data?.documentData, {
                        type: types.voterKey,
                      })?.value
                    }
                  </Table.Cell>
                </Table.Row>
              ) : null}
              {find(data?.data?.documentData, { type: types.voterKey })
                ?.value && !isPassport ? (
                <Table.Row></Table.Row>
              ) : null}
              {find(data?.data?.documentData, {
                type: types.documentNumber,
              })?.value && !isPassport ? (
                <Table.Row>
                  <Table.Cell width={5}>
                    <strong>{t('cicNumber')}:</strong>
                  </Table.Cell>
                  <Table.Cell>
                    {
                      find(data?.data?.documentData, {
                        type: types.documentNumber,
                      })?.value
                    }
                  </Table.Cell>

                  <Table.Cell width={5}>
                    <strong>{t('ocrNumber')}:</strong>
                  </Table.Cell>
                  <Table.Cell>
                    {
                      find(data?.data?.documentData, {
                        type: types.ocrNumber,
                      })?.value
                    }
                  </Table.Cell>
                </Table.Row>
              ) : null}

              {find(data?.data?.documentData, { type: types.dateOfBirth })
                ?.value ? (
                <Table.Row>
                  <Table.Cell width={5}>
                    <strong>{t('dateOfBirth')}:</strong>
                  </Table.Cell>
                  <Table.Cell>
                    {
                      find(data?.data?.documentData, {
                        type: types.dateOfBirth,
                      })?.value
                    }
                  </Table.Cell>
                  <Table.Cell width={5}>
                    <strong>{t('registry_date')}:</strong>
                  </Table.Cell>
                  <Table.Cell>
                    {
                      find(data?.data?.documentData, {
                        type: 'Date of Registration',
                      })?.value
                    }
                  </Table.Cell>
                </Table.Row>
              ) : null}

              {find(data?.data?.documentData, {
                type: 'Date of Issue',
              })?.value ? (
                <Table.Row>
                  <Table.Cell width={5}>
                    <strong>{t('emision_date')}:</strong>
                  </Table.Cell>
                  <Table.Cell>
                    {`
                    ${
                      find(data?.data?.documentData, {
                        type: 'Date of Issue',
                      })?.value
                    }
                     - 
                    ${
                      find(data?.data?.documentData, {
                        type: 'Years Since Issue',
                      })?.value
                    } años 

                    `}
                  </Table.Cell>
                  <Table.Cell width={5}>
                    <strong>{t('dateOfExpiry')}:</strong>
                  </Table.Cell>
                  <Table.Cell>
                    {
                      find(data?.data?.documentData, {
                        type: types.dateOfExpiry,
                      })?.value
                    }
                  </Table.Cell>
                </Table.Row>
              ) : null}

              {find(data?.data?.documentData, {
                type: 'Age at Issue',
              })?.value ? (
                <Table.Row>
                  <Table.Cell width={5}>
                    <strong>{t('age_at_issue')}:</strong>
                  </Table.Cell>
                  <Table.Cell>
                    {
                      find(data?.data?.documentData, {
                        type: 'Age at Issue',
                      })?.value
                    }
                  </Table.Cell>

                  <Table.Cell width={5}>
                    <strong>{t('issueNumber')}:</strong>
                  </Table.Cell>
                  <Table.Cell>
                    {
                      find(data?.data?.documentData, {
                        type: types.numberOfDuplicates,
                      })?.value
                    }
                  </Table.Cell>
                </Table.Row>
              ) : null}

              {find(data?.data?.documentData, { type: 'Sex' })?.value ? (
                <Table.Row>
                  <Table.Cell width={5}>
                    <strong>{t('sex')}:</strong>
                  </Table.Cell>
                  <Table.Cell>
                    {find(data?.data?.documentData, { type: 'Sex' })?.value}
                  </Table.Cell>
                  <Table.Cell width={5}>
                    <strong>{t('nationality')}:</strong>
                  </Table.Cell>
                  <Table.Cell>
                    {find(data?.data?.documentData, {
                      type: 'Nationality',
                    })?.value ??
                      'Error al extraer el dato, revise manualmente.'}
                  </Table.Cell>
                </Table.Row>
              ) : null}

              {find(data?.data?.documentData, { type: 'Issuing State Name' })
                ?.value ? (
                <Table.Row>
                  <Table.Cell width={5}>
                    <strong>{t('country_of_issue')}:</strong>
                  </Table.Cell>
                  <Table.Cell>
                    {
                      find(data?.data?.documentData, {
                        type: 'Issuing State Name',
                      })?.value
                    }
                  </Table.Cell>
                  <Table.Cell width={5}>
                    <strong>{t('section')}:</strong>
                  </Table.Cell>
                  <Table.Cell>
                    {
                      find(data?.data?.documentData, {
                        type: 'Section',
                      })?.value
                    }
                  </Table.Cell>
                </Table.Row>
              ) : null}

              {find(data?.data?.documentData, { type: 'Address' })?.value ? (
                <Table.Row>
                  <Table.Cell width={5}>
                    <strong>{t('address')}:</strong>
                  </Table.Cell>
                  <Table.Cell>
                    {find(data?.data?.documentData, {
                      type: 'Address',
                    })?.value.replaceAll('^', ', ')}
                  </Table.Cell>
                  <Table.Cell width={5}>
                    <strong>{t('address_location')}:</strong>
                  </Table.Cell>
                  <Table.Cell>
                    {
                      find(data?.data?.documentData, {
                        type: 'Address Location',
                      })?.value
                    }
                  </Table.Cell>
                </Table.Row>
              ) : null}

              {find(data?.data?.documentData, {
                type: 'Address State',
              })?.value ? (
                <Table.Row>
                  <Table.Cell width={5}>
                    <strong>{t('address_state')}:</strong>
                  </Table.Cell>
                  <Table.Cell>
                    {
                      find(data?.data?.documentData, {
                        type: 'Address State',
                      })?.value
                    }
                  </Table.Cell>
                  <Table.Cell width={5}>
                    <strong>{t('address_municipality')}:</strong>
                  </Table.Cell>
                  <Table.Cell>
                    {
                      find(data?.data?.documentData, {
                        type: 'Address Municipality',
                      })?.value
                    }
                  </Table.Cell>
                </Table.Row>
              ) : null}

              {/* AQUI NUEVOS DATOS */}
            </Table.Body>
          </Table>
        ),
      },
      {
        title: 'Formato de datos',
        description: (
          <Table color={color}>
            {data?.data?.documentVerifications
              .filter((item) => item.category === 'DataFormat')
              .map((item, index) => (
                <Table.Body key={index}>
                  <Table.Row>
                    <Table.Cell style={{ verticalAlign: 'inherit' }}>
                      <strong>{t(`${item.name}`)}</strong>
                    </Table.Cell>
                    <Table.Cell>
                      {item.inputFields.map((i, index) => (
                        <p key={index}>{i.value}</p>
                      ))}
                    </Table.Cell>
                    <Table.Cell
                      textAlign="center"
                      style={{ verticalAlign: 'inherit' }}
                    >
                      <img
                        src={
                          item.result === 'Ok'
                            ? iconAwesomeCheck
                            : iconAwesomeFail
                        }
                        alt="result icon"
                        style={{ width: '1.5rem' }}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              ))}
          </Table>
        ),
      },
      {
        title: 'Integridad de los datos',
        description: (
          <Table color={color}>
            {data?.data?.documentVerifications
              .filter((item) => item.category === 'DataIntegrity')
              .map((item, index) => (
                <Table.Body key={index}>
                  <Table.Row>
                    <Table.Cell style={{ verticalAlign: 'inherit' }}>
                      <strong>{t(`${item.name}`)}</strong>
                    </Table.Cell>

                    <Table.Cell>
                      {item.inputFields.map((i, index) => (
                        <p key={index}>
                          {i.type} : {i.value}
                        </p>
                      ))}
                    </Table.Cell>
                    <Table.Cell
                      textAlign="center"
                      style={{ verticalAlign: 'inherit' }}
                    >
                      <img
                        src={
                          item.result === 'Ok'
                            ? iconAwesomeCheck
                            : iconAwesomeFail
                        }
                        alt="result icon"
                        style={{ width: '1.5rem' }}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              ))}
          </Table>
        ),
      },
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleNext = (page) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = (page) => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  if (Number(data?.dataVersion) >= 2) {
    types = responseVersion2
  } else {
    types = responseVersion1
  }

  const isPassport = data?.data?.documentType === types.passport

  const fullNameMrz = find(data?.data?.documentData, {
    type: types.fullName,
  })?.value

  let name = find(data?.data?.documentData, {
    type: types.name,
  })?.value?.replace(/[\^]/g, ' ')

  let fatherSurname = find(data?.data?.documentData, {
    type: types.fatherSurname,
  })?.value

  let motherSurname = find(data?.data?.documentData, {
    type: types.motherSurname,
  })?.value

  let fullName =
    find(data?.data?.documentData, {
      type: types.fullName,
      source: types.visual,
    })?.value?.replace(/[\^]/g, ' ') || null

  if (fatherSurname && motherSurname && name && !fullName) {
    fullName = `${fatherSurname} ${motherSurname} ${name}`
  } else if (!fullName && !fullNameMrz) {
    const documentHolderFullName = find(data?.data?.documentData, {
      type: 'DocumentHolderFullName',
    })?.value

    const surname = find(data?.data?.documentData, {
      type: 'Surname',
    })?.value
    const name = find(data?.data?.documentData, { type: types.name })?.value

    const fallbackName = name && surname ? `${surname} ${name}` : 'Sin Datos'

    fullName = documentHolderFullName || fallbackName
  } else if (!fullName) {
    fullName = fullNameMrz
  }

  const chargeData = () => {
    return (
      <Box sx={{ flexGrow: 1 }}>
        {/* Stepper */}
        <MobileStepper
          variant="text"
          style={{ marginTop: '28px' }}
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={() => handleNext(activeStep)}
              disabled={activeStep === maxSteps - 1}
            >
              Siguiente
              {theme.direction === 'rtl' ? (
                <VscChevronLeft size={16} strokeWidth={1} />
              ) : (
                <VscChevronRight size={16} strokeWidth={1} />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={() => handleBack(activeStep)}
              disabled={activeStep === 0}
            >
              {theme.direction === 'rtl' ? (
                <VscChevronRight size={16} strokeWidth={1} />
              ) : (
                <VscChevronLeft size={16} strokeWidth={1} />
              )}
              Anterior
            </Button>
          }
        />
        {/* Information */}
        <Box sx={{ width: '100%' }}>
          <h4
            style={{
              width: '100%',
              borderRadius: '3px 3px 0 0',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              background: 'var(--primary-color)',
              color: 'white',
              fontWeight: 500,
              paddingTop: '10px',
              paddingBottom: '10px',
              paddingLeft: '20px',
              marginBottom: '-14px',
              marginTop: '3px',
            }}
          >
            {steps[activeStep]?.title}
          </h4>
          {steps[activeStep]?.description}
        </Box>
      </Box>
    )
  }

  return (
    <Fragment>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(10.5rem, 1fr))',
          gridAutoFlow: 'dense',
        }}
      >
        <span style={{ fontSize: 'clamp(1em, 1.3em, 1.5em)' }}>
          {t('totalChecks')}: <strong>{data?.data?.totalChecks}</strong>
        </span>
        <span style={{ fontSize: 'clamp(1em, 1.3em, 1.5em)' }}>
          {t('successChecks')}: <strong>{data?.data?.successChecks}</strong>
        </span>
        <span style={{ fontSize: 'clamp(1em, 1.3em, 1.5em)' }}>
          {t('warningChecks')}: <strong>{data?.data?.warningChecks}</strong>
        </span>
        <span style={{ fontSize: 'clamp(1em, 1.3em, 1.5em)' }}>
          {t('failedChecks')}: <strong>{data?.data?.failedChecks}</strong>
        </span>
      </div>
      <Fragment>{data ? chargeData() : null}</Fragment>
    </Fragment>
  )
}

export default DocumentIDInfo
