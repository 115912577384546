import { API_DASHBOARD_URL, API_URL_V3 } from '../const'

export const getData = async (token, uuid) => {
  try {
    const response = await fetch(`${API_DASHBOARD_URL}/records/${uuid}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })

    if (response.ok) {
      const json = await response.json()
      return json
    } else {
      const error = await response.json()
      console.error(error)
    }
  } catch (error) {
    console.error(error)
  }
}

export const getParticipantsOfDocument = async (token, documentID) => {
  try {
    const response = await fetch(
      `${API_DASHBOARD_URL}/sign/verification/${documentID}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )

    if (response.ok) {
      const json = await response.json()
      return json
    } else {
      const error = await response.json()
      return error
    }
  } catch (error) {
    return null
  }
}

export const getDataOcrAddress = async ({ token, uuid }) => {
  try {
    const response = await fetch(
      `${API_DASHBOARD_URL}/records/${uuid}/getResultsOcr`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )

    if (response.ok) {
      const json = await response.json()
      return json
    } else {
      const error = await response.json()
      console.error(error)
    }
  } catch (error) {
    console.error(error)
  }
}

export const getDataSign = async (token, uuid) => {
  try {
    const response = await fetch(`${API_DASHBOARD_URL}/sign/${uuid}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })

    if (response.ok) {
      const json = await response.json()
      return json
    }
  } catch (error) {
    console.error(error)
  }
}

export const getDataContractsWithVerification = async (token, uuid) => {
  try {
    const response = await fetch(
      `${API_DASHBOARD_URL}/sign/documents-with-verification/${uuid}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )

    if (response.ok) {
      const json = await response.json()
      return json
    }
  } catch (error) {
    console.error(error)
  }
}

export const getDocumentBase64Sign = async (token, documentId, uuid) => {
  try {
    const response = await fetch(
      `${API_DASHBOARD_URL}/sign/document/${documentId}/${uuid}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )

    if (response.ok) {
      const base64 = await response.text()
      return base64
    } else {
      const error = await response.json()
      console.error(error)
    }
  } catch (error) {
    console.error(error)
  }
}

export const getCountParticipantsAndSignatories = async (token, documentId) => {
  try {
    const response = await fetch(
      `${API_URL_V3}/count-sign-data?documentID=${documentId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )

    if (response.ok) {
      const count = await response.json()
      return count
    }
  } catch (error) {
    return null
  }
}

export const getDocumentDataSign = async (token, documentId, uuid) => {
  try {
    const response = await fetch(
      `${API_DASHBOARD_URL}/sign/data-document/${documentId}/${uuid}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )

    if (response.ok) {
      const json = await response.json()
      return json
    } else {
      const error = await response.json()
      console.error(error)
    }
  } catch (error) {
    console.error(error)
  }
}

// Inicio - Estas apis nos ayudan a obtener los datos de los participantes con biometria y ademas las imagenes (solo el proceso de firma)
export const getDataSignWithBiometricResult = async (token, uuid) => {
  try {
    const response = await fetch(
      `${API_DASHBOARD_URL}/sign/data-mesa/${uuid}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )

    if (response.ok) {
      const json = await response.json()
      return json
    } else {
      const error = await response.json()
      console.error(error)
    }
  } catch (error) {
    console.error(error)
  }
}

export const getBiometricImageSignProcess = async (token, filePath) => {
  try {
    const response = await fetch(
      `${API_DASHBOARD_URL}/files/sign?filePath=${filePath}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    if (response.ok) {
      const json = await response.text()
      return json
    }
  } catch (error) {
    console.error(error)
  }
}

export const getPortraitImageSignProcess = async (token, uuid) => {
  try {
    const response = await fetch(
      `${API_DASHBOARD_URL}/files/sign/portrait/${uuid}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    if (response.ok) {
      const json = await response.text()
      return json
    }
  } catch (error) {
    console.error(error)
  }
}
// Fin - Estas apis nos ayudan a obtener los datos de los participantes con biometria y ademas las imagenes (solo el proceso de firma)

export const getDocumentBase64SignNom = async (token, documentId, uuid) => {
  try {
    const response = await fetch(
      `${API_DASHBOARD_URL}/sign/document/${documentId}/${uuid}/nom`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )

    if (response.ok) {
      const base64 = await response.text()
      return base64
    } else {
      const error = await response.json()
      console.error(error)
    }
  } catch (error) {
    console.error(error)
  }
}

// Find verifications by uuid (signers)
export const getVerificationSigner = async (token, uuidSigner) => {
  try {
    const response = await fetch(
      `${API_DASHBOARD_URL}/records/filter-clients`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uuid: uuidSigner,
        }),
      }
    )

    if (response.ok) {
      const json = await response.json()
      return json
    } else {
      const error = await response.json()
      return error
    }
  } catch (error) {
    return error
  }
}
// Find verifications by uuid (signers)
export const disabledContract = async (token, data) => {
  try {
    const response = await fetch(`${API_URL_V3}/update-document-status`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })

    if (response.ok) {
      const json = await response.json()
      return json
    } else {
      const error = await response.json()
      return error
    }
  } catch (error) {
    return error
  }
}

export const signEmailForwarding = async (token, data) => {
  try {
    const url = `${API_URL_V3}/email-forwarding`
    // const url = `${API_URL_V3}/sign-resend-emails/invite`
    const response = await fetch(`${url}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })

    if (response.ok) {
      const json = await response.json()
      return json
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}
