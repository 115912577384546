import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'

export const LegalRepresentantGrid = styled(Grid)({
  width: '100%',
  margin: '0.9rem 0 20px 0',
})

export const LegalRepresentantGridLeft = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'flex-start',
  flexDirection: 'column',
})

export const LegalRepresentantCard = styled(Grid)({
  width: '100%',
  minHeight: '250px',
  backgroundColor: '#fff',
  padding: '20px 18px',
  display: 'flex',
  flexDirection: 'column',
})

export const LegalRepresentantCardInput = styled('input')({
  border: '1px solid #d9d9d9',
  color: 'grey',
  paddingLeft: '10px',
  marginBottom: '15px',
  outline: 'none',
  padding: '0.4rem 0.5rem',
  '&:hover, &:active, &:focus': {
    border: '1px solid var(--secondary-color)',
  },
})
