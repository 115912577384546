import React, { useState } from 'react'
import { Popup } from 'semantic-ui-react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import useTimeout from '../hooks/useTimeout'
import { VscCopy } from 'react-icons/vsc'

const CopyIdPopup = ({ text, copy }) => {
  const [copied, setCopied] = useState(false)

  useTimeout(
    copied
      ? () => {
          setCopied(false)
        }
      : null,
    200
  )
  const onCopy = () => {
    setCopied(true)
  }
  return (
    <Popup trigger={<div>{text}</div>} flowing hoverable position="top center">
      <CopyToClipboard text={copy} onCopy={onCopy}>
        <div>
          <span>{copy}</span>
          <VscCopy
            className="ui copy icon"
            style={{ marginLeft: '10px' }}
            color={`${copied ? 'blue' : 'black'}`}
          />
        </div>
      </CopyToClipboard>
    </Popup>
  )
}

export default CopyIdPopup
