import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import boxSign from '../assets/caja firma.svg'
import ViewPDF from '../components/Signature/PDFSignatureNC/ViewPDF/ViewPDF.jsx'
import CreateTemplate from '../components/Signature/Templates/CreateTemplate/CreateTemplate.jsx'
import {
  BtnReturn,
  Container,
  Details,
  InternalContainer,
} from '../components/Signature/Templates/CreateTemplate/NewTemplate.jsx'
import { useAuth0 } from '../react-auth0-spa.js'

let signPositionsDOM = []
let offsetClickDown

export default function NewTemplate() {
  const { user, isAuthenticated } = useAuth0()
  //ViewPDF
  const [file, setFile] = useState(false)
  const [fileBase64, setFileBase64] = useState('false')
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [pageSize, setPageSize] = useState([612, 792])
  //Legal Representant
  const [legalRepresentant, setLegalRepresentant] = useState({
    name: '',
    email: '',
  })
  //ConfigurationsPDF
  const [currentDrop, setCurrentDrop] = useState([])
  const [currentSelect, setCurrentSelect] = useState('')
  const sign = {
    signature: '',
    currentDate: '',
    option: 1,
  }
  const [signers, setSigners] = useState([])
  const [signPositions, setSignPositions] = useState([])
  const [eventDD, setEventDD] = useState(false)

  // Checked endorse
  const [endorseTemplate, setEndorseTemplate] = useState(null)
  const [signerEndorseTemplate, setSignerEndorseTemplate] = useState('')

  // ClientID
  const [clientID, setClientID] = useState({})

  // Settings Resize
  const [newWidth, setNewWidth] = useState(136)
  const [newHeigth, setNewHeigth] = useState(73)

  const [imgBoxState, setImageBoxState] = useState(null)
  const [onBorder, setOnBorder] = useState(true)
  const [signBase64, setSignBase64] = useState('')

  const { t } = useTranslation()

  useEffect(() => {
    if (isAuthenticated) {
      setClientID(user?.['https://vdid.sumamexico.com/app_metadata'] ?? {})
    }
  }, [isAuthenticated, user])

  const handleRenderSuccess = (pgd) => {
    let canvas = document.getElementById('pdf_renderer')
    canvas = canvas.firstChild
    canvas = canvas.firstChild
    canvas = canvas.firstChild
    setPageSize([canvas.style.width, canvas.style.height])

    handleComproveSigns()
  }

  const dd_dragstart = (e) => e.dataTransfer.setData('text/plain', e.target.id)

  const dd_dragover = (e) => e.preventDefault()

  const dd_drop = (e) => {
    e.preventDefault()

    const getContainerElement = e.target.parentElement

    let eX = 0
    let eY = 0

    const element = document.getElementById(e.dataTransfer.getData('text'))
    const newElement = document.getElementById(e.dataTransfer.getData('text'))

    if (!getContainerElement.id && !getContainerElement.className) {
      let [, x, y] = getContainerElement?.parentElement?.id
        .split('-')
        .splice(-3)

      const { positionX, positionY } = signPositionsDOM.find(
        (sign) => sign.positionX === Number(x) && sign.positionY === Number(y)
      )
      eX = positionX + e.offsetX
      eY = positionY + e.offsetY
    } else {
      eX = e.offsetX
      eY = e.offsetY
    }

    if (offsetClickDown) {
      eY = eY - (offsetClickDown.y - offsetClickDown.height)
      eX = eX - (offsetClickDown.x - offsetClickDown.width)
    }

    //Actualiza el arreglo con los datos actuales del elemento dropeado
    setCurrentDrop([{ newElement, element, eX, eY }])
  }

  const dd_mousedown = (e) => {
    let getContainerElement = e.target.parentElement
    if (getContainerElement.id === 'emailBox') {
      getContainerElement = getContainerElement.parentElement
    }

    let width = getContainerElement.offsetWidth / 2
    width += 10
    const height = getContainerElement.offsetHeight / 2

    offsetClickDown = { width, height, x: e.offsetX, y: e.offsetY }
  }
  const dd_mouseup = () => {
    offsetClickDown = null
  }

  const handleComproveSigns = () => {
    if (imgBoxState == null) {
      setImageBoxState(boxSign)
    }
    // Zona A (PDF)
    const dzone = document.getElementById('ddzone')

    if (dzone) {
      //Agrega una sola vez los eventos drop y dropover a la Zona A
      if (!eventDD) {
        dzone.addEventListener('dragover', dd_dragover)
        dzone.addEventListener('drop', dd_drop)
        dzone.addEventListener('mousedown', dd_mousedown)
        dzone.addEventListener('mouseup', dd_mouseup)
        dzone.addEventListener('dragstart', dd_dragstart)
        setEventDD(true)
      }

      //Elimina los hijos ya existentes, Evita duplicados
      const tope = dzone.childElementCount
      for (let i = 0; i < tope; i++) {
        dzone.removeChild(dzone.firstChild)
      }

      let internalSignPositions = [...signPositions]

      //Comprueba cada uno de los firmantes
      for (let [i, signer] of internalSignPositions.entries()) {
        //Si el firmante tiene una firma activa en esta hoja, creara su caja de firma
        if (signer.page === page) {
          let mainDiv = document.createElement('div')
          let leftDiv = document.createElement('div')
          let rightDiv = document.createElement('div')
          let rightDiv_Close = document.createElement('div')
          let rightCloseText = document.createTextNode('×')
          let rightDiv_AddSign = document.createElement('div')
          let rightAddSign = document.createTextNode('+')
          let imgBox = document.createElement('img')
          let internalDiv = document.createElement('div')
          let internalDivEmail = document.createTextNode(signer.email)

          mainDiv.id = `sign${i}`

          mainDiv.draggable = true
          mainDiv.classList = 'mainDiv'
          mainDiv.style.fontSize = `${9 * zoom}px`
          mainDiv.style.width = `${newWidth * zoom}px`
          mainDiv.style.height = `${newHeigth * zoom}px`

          leftDiv.style.width = `${newWidth * zoom}px`
          leftDiv.style.height = `${newHeigth * zoom}px`
          leftDiv.style.display = `flex`
          leftDiv.style.justifyContent = `center`
          leftDiv.style.alignItems = `center`

          !onBorder ? (imgBox.style.opacity = 0) : (imgBox.src = imgBoxState)
          imgBox.draggable = false
          imgBox.style.width = `${newWidth * zoom}px`
          imgBox.style.height = `${newHeigth * zoom}px`
          imgBox.style.position = 'relative'
          leftDiv.appendChild(imgBox)
          internalDiv.classList = 'internalDiv'
          internalDiv.style.width = `${newWidth * zoom}px`
          internalDiv.style.height = `${newHeigth * zoom}px`
          internalDiv.appendChild(internalDivEmail)
          leftDiv.appendChild(internalDiv)

          rightDiv.classList = 'rightDiv'
          rightDiv.style.width = `${20 * zoom}px`
          rightDiv.style.height = `${newHeigth * zoom}px`

          rightDiv_Close.classList = 'rightDiv_Close'
          rightDiv_Close.style.width = `${20 * zoom}px`
          rightDiv_Close.style.height = `${20 * zoom}px`
          rightDiv_Close.style.fontSize = `${20 * zoom}px`
          rightDiv_Close.style.paddingLeft = `${0.5 * zoom}px`
          rightDiv_Close.style.paddingBottom = `${4 * zoom}px`
          rightDiv_Close.style.marginBottom = `${5 * zoom}px`
          rightDiv_Close.setAttribute(
            'id',
            `${signer?.email}%PXY%${signer?.page}-${signer?.positionX}-${signer?.positionY}`
          )

          rightDiv_AddSign.classList = 'rightDiv_AddSign'
          rightDiv_AddSign.style.width = `${20 * zoom}px`
          rightDiv_AddSign.style.height = `${20 * zoom}px`
          rightDiv_AddSign.style.fontSize = `${20 * zoom}px`
          rightDiv_AddSign.style.paddingLeft = `${0.5 * zoom}px`
          rightDiv_AddSign.style.paddingBottom = `${4 * zoom}px`
          rightDiv_AddSign.style.marginBottom = `${5 * zoom}px`
          rightDiv_AddSign.setAttribute(
            'id',
            `${signer?.email}%PXY%${signer?.page}-${signer?.positionX}-${signer?.positionY}`
          )

          rightDiv_Close.onclick = function (e) {
            let newA = []
            for (let signer of signPositions) {
              if (
                `${signer?.email}%PXY%${signer?.page}-${signer?.positionX}-${signer?.positionY}` !==
                  e.target.id.trim() ||
                signer.page !== page
              )
                newA = [...newA, signer]
            }
            setSignPositions(newA)
            document.getElementById(`${e.target.id.trim()}`).remove()
          }

          rightDiv_AddSign.onclick = function (e) {
            let currentPosition
            for (let signer of signPositions) {
              if (
                `${signer?.email}%PXY%${signer?.page}-${signer?.positionX}-${signer?.positionY}` ===
                e.target.id.trim()
              ) {
                currentPosition = signer
                /* eslint no-unused-vars: "off" */
                break
              }
            }

            let newB = signPositions
            for (let count = 1; count <= totalPages; count++) {
              if (count !== page)
                newB.push({
                  email: signer.email,
                  name: signer.name,
                  page: count,
                  positionX: signer.positionX,
                  positionY: signer.positionY,
                  zoom: signer.zoom,
                  isEndorse: signer.isEndorse,
                })
            }

            setSignPositions(newB)
          }

          rightDiv_Close.appendChild(rightCloseText)
          rightDiv_AddSign.appendChild(rightAddSign)

          rightDiv.appendChild(rightDiv_Close)
          rightDiv.appendChild(rightDiv_AddSign)

          mainDiv.appendChild(leftDiv)
          mainDiv.appendChild(rightDiv)

          mainDiv.setAttribute(
            'id',
            `${signer?.email}%PXY%${signer?.page}-${signer?.positionX}-${signer?.positionY}`
          )
          mainDiv.ondragstart = function (e) {
            setCurrentSelect(e.target.id)
          }
          //convierte las coordenadas a relacion 1/1 y las ajusta al zoom actual
          mainDiv.style.top = (signer?.positionY / signer?.zoom) * zoom + 'px'
          mainDiv.style.marginLeft =
            (signer?.positionX / signer?.zoom) * zoom + 'px'
          dzone.appendChild(mainDiv)
        }
      }

      //Zona B (Zona derecha)
      const bzone = document.getElementById('ddzoneB')

      if (bzone) {
        //Elimina los hijos ya existentes, Evita duplicados
        const topeB = bzone.childElementCount
        for (let i = 0; i < topeB; i++) {
          bzone.removeChild(bzone.firstChild)
        }

        //Por cada firmante en el arreglo de la zona B se creara su caja
        for (let signer of signers) {
          let newDivB = document.createElement('div')
          newDivB.classList = 'taskB'
          newDivB.style.width = `${136 * zoom}px`
          newDivB.style.height = `${75 * zoom}px`
          newDivB.style.fontSize = `${9 * zoom}px`
          newDivB.setAttribute('draggable', true)

          let newImg = document.createElement('img')
          newImg.src = boxSign
          newImg.height = 75 * zoom
          newImg.width = 136 * zoom
          newImg.style.position = 'absolute'
          newImg.style.zIndex = 0
          // newImg.style.backgroundColor = 'blue'
          newImg.setAttribute('draggable', false)
          newDivB.appendChild(newImg)

          let internalDiv = document.createElement('div')
          internalDiv.style.height = `${newHeigth * zoom}px`
          internalDiv.style.width = `${newWidth * zoom}px`
          internalDiv.style.overflow = 'hidden'
          internalDiv.style.display = 'flex'
          internalDiv.style.flexDirection = 'column'
          internalDiv.style.flexWrap = 'wrap'
          internalDiv.style.justifyContent = 'center'
          internalDiv.style.alignItems = 'center'
          internalDiv.style.zIndex = 98 * zoom

          let contentEmail = document.createTextNode(`${signer.email}`)
          internalDiv.appendChild(contentEmail)
          newDivB.appendChild(internalDiv)

          newDivB.setAttribute('id', `${signer.email}`)

          bzone.appendChild(newDivB)
        }
      }
    }
  }

  useEffect(() => {
    handleComproveSigns()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signPositions, signers, imgBoxState, newHeigth, newWidth, onBorder])

  useEffect(() => {
    const handleCurrentUpdate = (currentDrop) => {
      //Valida que haya un elemento valido arrastrable
      if (currentDrop.length !== 0 && currentDrop[0].element) {
        //ajust centra la caja para un mejor control del drop
        const adjustX = 77 * zoom
        const adjustY = 36.5 * zoom
        let codsX = currentDrop[0].eX - adjustX
        let codsY = currentDrop[0].eY - adjustY
        let actualPositions = signPositions

        if (currentSelect !== '') {
          let newA = []
          for (let signPosition of signPositions) {
            if (
              `${signPosition.email}%PXY%${signPosition.page}-${signPosition.positionX}-${signPosition.positionY}` !==
              currentSelect
            )
              newA = [...newA, signPosition]
          }
          actualPositions = newA
          setCurrentSelect('')
        }

        let newA = [],
          currentUserData = {}

        let email = currentDrop[0].element.id.trim()
        let nEmail = email.split('%PXY%', 1)

        currentUserData = {
          email: nEmail[0],
          name:
            legalRepresentant.email === nEmail[0]
              ? legalRepresentant.name
              : user.email !== nEmail[0]
              ? nEmail[0]
              : user.name,
          positionX: codsX,
          positionY: codsY,
          page,
          zoom,
          isEndorse: false,
        }

        newA = [...actualPositions, currentUserData]

        setSignPositions(newA)
        signPositionsDOM = newA
      }
    }

    handleCurrentUpdate(currentDrop)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDrop])

  // History
  const history = useHistory()

  const returnTemplates = () => {
    history.push(`/contratos/plantillas`)
  }

  return (
    <Container container>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '2rem',
        }}
      >
        <Details> {t('newTemplate')}</Details>
        <BtnReturn onClick={() => returnTemplates()} type="button">
          {t('return_home')}
        </BtnReturn>
      </div>
      <InternalContainer container>
        <ViewPDF
          setFile={setFile}
          fileBase64={fileBase64}
          setFileBase64={setFileBase64}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          setTotalPages={setTotalPages}
          zoom={zoom}
          setZoom={setZoom}
          pageSize={pageSize}
          handleRenderSuccess={handleRenderSuccess}
          dd_dragstart={dd_dragstart}
        />
        {fileBase64 !== 'false' ? (
          <CreateTemplate
            totalPages={totalPages}
            pageSize={pageSize}
            file={file}
            fileBase64={fileBase64}
            sign={sign}
            zoom={zoom}
            signers={signers}
            setSigners={setSigners}
            signPositions={signPositions}
            setSignPositions={setSignPositions}
            legalRepresentant={legalRepresentant}
            setLegalRepresentant={setLegalRepresentant}
            user={user}
            dd_dragstart={dd_dragstart}
            boxSign={boxSign}
            onBorder={onBorder}
            setOnBorder={setOnBorder}
            setImageBoxState={setImageBoxState}
            setNewWidth={setNewWidth}
            newWidth={newWidth}
            setNewHeigth={setNewHeigth}
            newHeigth={newHeigth}
            setSignBase64={setSignBase64}
            signBase64={signBase64}
            // Checked endorse (only kavak)
            endorseTemplate={endorseTemplate}
            setEndorseTemplate={setEndorseTemplate}
            signerEndorseTemplate={signerEndorseTemplate}
            setSignerEndorseTemplate={setSignerEndorseTemplate}
            clientId={clientID?.clientId}
          />
        ) : null}
      </InternalContainer>
    </Container>
  )
}
