import React, { useState } from 'react'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import './FormatData.css'
import { labels, categories } from '../../../../fixtures/panelExperts.fixture'
import iconAwesomeCheck from '../../../../assets/icons/awesome-check.svg'
import iconAwesomeFail from '../../../../assets/icons/awesome-fail.svg'
import iconAwesomeExclamation from '../../../../assets/icons/awesome-exclamation.svg'

// Utils
import { API_DASHBOARD_URL } from '../../../../const'
import useToken from '../../../../utils/useToken'

const FormatData = ({ uuid, type, ...props }) => {
  const getToken = useToken()
  const { confirm } = Modal
  const [isClicked, setIsClicked] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const [output, setOutput] = useState(null)
  const [isOverwriting, setIsOverwriting] = useState(false)
  const dataFormat = props.response
  const numbers = []
  var valueClass = ''

  for (let i = 1; i < 45; i++) {
    numbers.push(
      <td className="RowsNumbers" key={i}>
        {i}
      </td>
    )
  }

  const mrzRows = dataFormat
    ?.find((elemnt) => elemnt?.name === 'MRZ: Cadena de texto MRZ')
    ?.inputFields?.map((resp, index) => (
      <div key={index}>
        <table className="TableNumbers">
          <tbody>
            <tr>{numbers}</tr>
          </tbody>
        </table>
        <input type="text" defaultValue={resp?.value} />
      </div>
    ))

  const onCancelHandler = () => {
    setIsEdit(false)
    setIsClicked(null)
  }

  // Handle edit data
  const onEditHandler = (key) => {
    setIsClicked(key)
    setIsEdit(true)
  }

  /* ----------------------------
    Input data (overwrite)
  ----------------------------*/
  const onChangeInputHandler = (e) => {
    const newOutput = e.target.value
    setOutput(Number(newOutput))
    if (newOutput.match(/[0-9]/g)) {
      setIsOverwriting(true)
    } else {
      setIsOverwriting(false)
    }
  }

  /* ----------------------------
    Show modal to confirm overwrite data
  ----------------------------*/
  const showModalConfirm = (value) => {
    confirm({
      title: '¿Estás seguro?',
      icon: <ExclamationCircleOutlined />,
      content: 'Esta acción es irreversible.',
      cancelText: 'No',
      okText: 'Si',
      onOk() {
        onChangeFormatDataValue(value)
        // console.log(value)
      },
      onCancel() {},
    })
  }

  /* ----------------------------
    Show modal info about overwrite data
  ----------------------------*/
  const showModalInfo = (status) => {
    if (status) {
      Modal.success({
        content: `Se ha modificado el valor correctamente.`,
        onOk() {
          window.location.reload()
        },
      })
    } else {
      Modal.error({
        title: '¡Oh, no! Algo salió mal',
        content: 'Por favor, vuelve a intentarlo.',
      })
    }
  }

  /* ----------------------------
    EP to change Data
  ----------------------------*/
  const onChangeFormatDataValue = async (key) => {
    try {
      const token = await getToken({
        audience: 'veridocid-dashboard',
      })
      dataFormat[key].output = output
      const response = await fetch(
        `${API_DASHBOARD_URL}/experts/records/updateDocumentVerification`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            uuid,
            output,
            category: categories.formatData,
            key,
            type,
          }),
        }
      )
      if (response.ok) {
        showModalInfo(true)
        onCancelHandler()
      } else {
        const error = await response.json()
        showModalInfo(false)
        console.error(error)
      }
    } catch (error) {
      showModalInfo(false)
      console.error(error)
    }
    setIsOverwriting(false)
  }

  const rows = dataFormat?.map((resp, index) => {
    const inputFields = resp?.inputFields?.map((input) => (
      <b key={Math.random()}>{input.value}</b>
    ))
    valueClass =
      resp?.result === 'Ok'
        ? 'Correct'
        : resp?.result === 'Overriden'
        ? 'Override'
        : 'Incorrect'

    const value =
      isEdit && isClicked === index ? (
        <input
          defaultValue={resp?.output}
          style={{ maxWidth: '5.5rem' }}
          maxLength="9"
          onChange={(event) => onChangeInputHandler(event)}
        />
      ) : (
        <span className="FormatOutput" onClick={() => onEditHandler(index)}>
          {resp?.output}
        </span>
      )
    const rowOutput =
      resp?.result === 'Ok' || resp?.result === 'Overriden' ? (
        <td className="ClassTD">{resp?.output}</td>
      ) : (
        <td className="ClassTD">
          {value}
          {isClicked === index ? (
            <div className="EditButtons">
              <button className="CancelButton" onClick={onCancelHandler}>
                {labels.cancel}
              </button>
              <button
                className="OverwriteButton"
                disabled={!isOverwriting}
                onClick={() => showModalConfirm(isClicked)}
              >
                {labels.overwrite}
              </button>
            </div>
          ) : null}
        </td>
      )

    return (
      <tr key={index}>
        <td className="ClassTD">
          <b>{resp?.name}</b>
        </td>
        <td className={'ClassTD ' + valueClass}>
          <div className="InputFields">{inputFields}</div>
        </td>
        {rowOutput}
        <td className="ClassTD">
          <img
            src={
              resp?.result === 'Ok'
                ? iconAwesomeCheck
                : resp?.result === 'Overriden'
                ? iconAwesomeExclamation
                : iconAwesomeFail
            }
            alt="Result"
            style={{ width: '1.5rem' }}
          />
        </td>
      </tr>
    )
  })

  return (
    <div className="FormatData">
      <p className="FormatDataTitle">
        <b>{labels.editMRZ}</b>
      </p>
      <div className="EditMRZ">
        <div className="LeftEditMRZ">{mrzRows}</div>
        <div className="RightEditMRZ">
          <img src={props.backImage} alt="Reverso" />
        </div>
      </div>
      <div>
        <button className="ButtonVerifyMRZ" disabled>
          {labels.verifyMRZ}
        </button>
      </div>
      <div className="TableMRZ">
        <table className="Table" key={4}>
          <thead>
            <tr>
              <th className="ClassTH" style={{ width: '25%' }}>
                <b>{labels.name}</b>
              </th>
              <th className="ClassTH" style={{ width: '40%' }}>
                <b>{labels.mrz} </b>
              </th>
              <th className="ClassTH" style={{ width: '20%' }}>
                <b>{labels.output}</b>
              </th>
              <th className="ClassTH" style={{ width: '15%' }}>
                <b>{labels.result}</b>
              </th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    </div>
  )
}

export default FormatData
