import React from 'react'
import Icon_404 from '../assets/error_404.svg'
import '../styles/NotFoundStyles.css'
import { useTranslation } from 'react-i18next'

const NotFound = () => {
  const { t } = useTranslation()
  return (
    <div className="content_not_found">
      <img src={Icon_404} alt="Not found" width="400px" height="auto" />
      <p className="title_not_found">Not found</p>
      <span className="description_not_found">
        {t('description_not_found')}
      </span>
      <a href="/" className="return_home">
        {t('return_home')}
      </a>
    </div>
  )
}

export default NotFound
