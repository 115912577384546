import { Grid } from '@mui/material'
import { Plugins, Sortable } from '@shopify/draggable'
import { Button, message as signMessage, Switch, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { VscAdd, VscCircleSlash, VscInfo } from 'react-icons/vsc'
import './SignersStyle.css'
import {
  EndorseSignatureCardInput,
  SignersCard,
  SignersGrid,
  SignersGridLeft,
  SignersGridRight,
} from './SignersStyle.jsx'

// Text mask
import MaskedInput from 'react-text-mask'
import emailMask from 'text-mask-addons/dist/emailMask'

export default function Signers(props) {
  const { signers, setSigners, sortStatus, setSortStatus } = props
  const [sortSigners, setSortSigners] = useState(null)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [verification, setVerification] = useState(false)
  const [endorse, setEndorse] = useState(false)
  const [biometric, setBiometric] = useState(false)
  const [showCard, setShowCard] = useState(false)
  const [endorseText, setEndorseText] = useState(
    'Endoso en propiedad de la presente factura del auto a favor de UVI TECH, S.A.P.I de C.V'
  )
  const { t } = useTranslation()

  const handleShowCard = () => setShowCard(!showCard)

  const handleName = (e) => setName(e.target.value)

  const handleEmail = (e) => setEmail(e.target.value)

  const onChangeCheckCreateVerification = (e) =>
    setVerification(e?.target?.checked)

  const onChangeCheckCreateEndorse = (e) => setEndorse(e?.target?.checked)

  // Validate Email
  const regexValidateEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  const handleAddSigner = () => {
    if (sortStatus) {
      if (sortSigners) {
        setSortStatus(false)
        sortSigners.destroy()
        setSortSigners(null)
      }
    }
    // Validate email
    const validateEmail = regexValidateEmail.test(String(email).toLowerCase())

    if (name === '' && email === '') {
      signMessage.warning(`${t('completeDataForm')}`)
    } else if (!validateEmail) {
      signMessage.warning(`${t('invalidEmailFormat')}`)
    } else {
      let addSigner = true

      for (let signer of signers) {
        if (signer.email === email) {
          signMessage.warning(`${t('differentEmails')}`)
          addSigner = false
          break
        }
      }

      if (addSigner) {
        setSigners([
          ...signers,
          {
            uuid: '',
            name,
            email,
            biometric,
            sdk: verification,
            isEndorse: endorse,
            endorseText,
            signerNumber: signers.length,
          },
        ])

        // Reset form
        setName('')
        setEmail('')
        setBiometric(false)
        setEndorse(false)
      }
    }
  }

  const handleCancelSigner = () => {
    setName('')
    setEmail('')
    setBiometric(false)
  }

  useEffect(() => {
    if (sortStatus) {
      if (sortSigners) {
        sortSigners.destroy()
        setSortSigners(null)
        setSortStatus(false)
        handleSortable(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signers])

  const handleSortable = (e) => {
    const czone = document.getElementById('orderSigners')
    if (!sortSigners) {
      const sortable = new Sortable(czone, {
        draggable: 'li',
        mirror: {
          constrainDimensions: true,
        },
        plugins: [Plugins.ResizeMirror],
      })

      sortable.on('sortable:sorted', (evt) => {
        try {
          const { newIndex, oldIndex } = evt?.data,
            source = String(evt?.data?.dragEvent?.data?.source?.id).replace(
              '_li',
              ''
            ),
            over = String(evt?.data?.dragEvent?.data?.over?.id).replace(
              '_li',
              ''
            )
          for (const signer of signers) {
            if (over === signer.email) signer.signerNumber = oldIndex
            if (source === signer.email) signer.signerNumber = newIndex
          }
          setSigners(signers)
        } catch (error) {
          console.log(error)
        }
      })
      setSortSigners(sortable)
      setSortStatus(e)
    } else {
      setSortStatus(e)
      sortSigners.destroy()
      setSortSigners(null)
    }
  }

  return (
    <>
      <span className="description_config_client">
        Configure sus opciones que mas se ajusten a sus necesidades
      </span>
      <SignersGrid>
        <SignersGridLeft>
          <p style={{ color: 'var(--secondary-color)', margin: '0' }}>
            Firmantes
          </p>
          <p className="description_config_client">
            Agregue nuevos firmantes para añadir a su documento
          </p>
        </SignersGridLeft>
        <SignersGridRight>
          <Button
            onClick={handleShowCard}
            size="small"
            style={{
              backgroundColor: '#e9e9e9',
              color: 'rgba(0,0,0,.85)',
              border: 'none',
              outline: 'none',
            }}
          >
            Agregar
          </Button>
        </SignersGridRight>
      </SignersGrid>
      {showCard ? (
        <SignersCard item xs={12}>
          <div className="content-input_add">
            <label>Nombre</label>
            <input
              type="text"
              name="name_Signatory"
              value={name}
              onChange={handleName}
              placeholder="Nombre del firmante"
            />
          </div>

          <div className="content-input_add">
            <label>Correo</label>
            <MaskedInput
              name="email_observer"
              keepCharPositions={false}
              mask={emailMask}
              guide={true}
              placeholder="Correo del firmante"
              value={email}
              onChange={handleEmail}
            />
          </div>

          {/* Verification */}
          <div
            className="info_select_verification"
            style={{ marginTop: '15px' }}
          >
            <Tooltip title="Si requieres que la persona haga una verificación antes de firmar, puedes habilitar esta opción">
              <VscInfo />
            </Tooltip>
            <span>Verificación</span>
            <div
              className="content_custom_checked"
              style={{ marginLeft: '10px' }}
            >
              <input
                type="checkbox"
                name="check_verification"
                onChange={onChangeCheckCreateVerification}
                checked={verification}
              />
            </div>
          </div>

          {/* Endorse */}
          <div
            className="info_select_verification"
            style={{ marginTop: '15px' }}
          >
            <Tooltip title="Endosar Firma">
              <VscInfo />
            </Tooltip>
            <span>Endosar</span>
            <div
              className="content_custom_checked"
              style={{ marginLeft: '10px' }}
            >
              <input
                type="checkbox"
                name="check_verification"
                onChange={onChangeCheckCreateEndorse}
                checked={endorse}
              />
            </div>
          </div>

          <div>
            {endorse ? (
              <div style={{ marginTop: '15px' }}>
                <span style={{ color: 'var(--gray-color)' }}>
                  Agregue texto de endoso
                </span>
                <EndorseSignatureCardInput
                  onChange={(e) => {
                    setEndorseText(e.target.value)
                  }}
                  value={endorseText}
                ></EndorseSignatureCardInput>
              </div>
            ) : null}
          </div>

          {/* Actions */}
          <Grid container className="actions_add">
            <button type="button" className="new_add" onClick={handleAddSigner}>
              <VscAdd color="white" size={20} strokeWidth={1} />
            </button>
            <button
              type="button"
              className="cancel_add"
              onClick={handleCancelSigner}
            >
              <VscCircleSlash color="white" size={20} strokeWidth={1} />
            </button>
          </Grid>
        </SignersCard>
      ) : null}
      {signers.length > 1 ? (
        <Grid
          item
          xs={12}
          style={{
            height: '50px',
            display: 'flex',
            alignItems: 'center',
          }}
          className="config_form__content_input"
        >
          <Switch
            defaultChecked
            style={{
              width: '14px',
              marginTop: '-0.4em',
              marginRight: '10px',
            }}
            checked={sortStatus}
            onChange={handleSortable}
          />
          <label>{sortStatus ? 'Ordenable' : 'No ordenable'}</label>
        </Grid>
      ) : null}
      <ul
        id="orderSigners"
        style={{
          width: '100%',
          padding: '0',
          marginTop: '20px',
        }}
      ></ul>
    </>
  )
}
