import React, { useState } from 'react'
import { find, isArray } from 'lodash'
import { useTranslation } from 'react-i18next'

// Components and styles
import { Button, Message } from 'semantic-ui-react'
import {
  responseVersion1,
  responseVersion2,
} from '../../../fixtures/dataResponse.fixture'
import useToken from '../../../utils/useToken'
import { API_URL } from '../../../const/index'
import HistoryCreditWihtInfo from './HistoryCreditWithInfo'
import ButtonPdfCreditReport from '../../../components/UI/ButtonPdfCreditReport/ButtonPdfCreditReport'
import '../CreditStyles.css'

const HistoryCredit = ({
  data,
  statusDataCredit,
  dataBDCredit,
  setStatusCreditHistoryVerifiedBD,
  ambitDev,
  ambitProd,
  dataNipUser,
  creditConfig,
}) => {
  const { t } = useTranslation()
  const getToken = useToken()
  const [loadingCredit, setLoadingCredit] = useState(false)
  const [statusMessageError, setStatusMessageError] = useState(false)
  const [errorApi, setErrorApi] = useState({ message: '', visible: false })
  // User data
  const userData = dataNipUser?.userData

  // Data history credit
  const [historyCreditData, setHistoryCreditData] = useState({
    folioConsulta: '',
    claveOtorgante: '',
    consultas: [],
    creditos: [],
    domicilios: [],
    empleos: [],
    scores: [],
  })

  let documentDataClient = data?.data?.documentData
  let types

  if (Number(data?.dataVersion) >= 2) {
    types = responseVersion2
  } else {
    types = responseVersion1
  }

  //--------- FullName
  const fullNameMrz = find(documentDataClient, {
    type: types.fullName,
  })?.value

  //--------- Nombre
  let name = find(documentDataClient, {
    type: types.name,
  })?.value?.replace(/[\^]/g, ' ')

  //--------- Apellido paterno
  let fatherSurname = find(documentDataClient, {
    type: types.fatherSurname,
  })?.value

  //--------- Apellido materno
  let motherSurname = find(documentDataClient, {
    type: types.motherSurname,
  })?.value

  let fullName =
    find(data?.data?.documentData, {
      type: types.fullName,
      source: types.visual,
    })?.value?.replace(/[\^]/g, ' ') || null

  if (fatherSurname && motherSurname && name && !fullName) {
    fullName = `${fatherSurname} ${motherSurname} ${name}`
  } else if (!fullName && !fullNameMrz) {
    const documentHolderFullName = find(data?.data?.documentData, {
      type: 'DocumentHolderFullName',
    })?.value

    const surname = find(data?.data?.documentData, {
      type: 'Surname',
    })?.value
    const name = find(data?.data?.documentData, { type: types.name })?.value

    const fallbackName = name && surname ? `${surname} ${name}` : 'Sin Datos'

    fullName = documentHolderFullName || fallbackName
  } else if (!fullName) {
    fullName = fullNameMrz
  }

  //--------- Primer nombre
  let givenNames = find(documentDataClient, {
    type: 'Given Names',
  })?.value

  //--------- Fecha de nacimiento
  let dateOfBirth = find(documentDataClient, { type: types.dateOfBirth })
    ?.value?.split('/')
    ?.reverse()
    ?.join('-')

  //--------- Curp
  let curp = find(documentDataClient, { type: types.personalNumber })?.value

  //--------- Nacionalidad
  let nationalityClient = ''
  let nationality = find(documentDataClient, { type: 'Nationality' })?.value

  //   Catálogo
  switch (nationality) {
    case 'Mexico' || 'México':
      nationalityClient = 'MX'
      break
    case 'Andorra':
      nationalityClient = 'AD'
      break
    case 'Afganistán' || 'Afganistan':
      nationalityClient = 'AF'
      break
    case 'Antigua y Barbado':
      nationalityClient = 'AG'
      break
    case 'Anguila':
      nationalityClient = 'AI'
      break
    case 'Albania':
      nationalityClient = 'AN'
      break
    case 'Angola':
      nationalityClient = 'AO'
      break
    case 'Ascención':
      nationalityClient = 'AS'
      break
    case 'Argentina':
      nationalityClient = 'AT'
      break
    case 'Australia':
      nationalityClient = 'AU'
      break
    case 'Arabía' || 'Arabia':
      nationalityClient = 'AW'
      break
    case 'Barbados':
      nationalityClient = 'BB'
      break
    case 'Bangladesh':
      nationalityClient = 'BD'
      break
    case 'Bélgica' || 'Belgica':
      nationalityClient = 'BE'
      break
    case 'Burkina':
      nationalityClient = 'BF'
      break
    case 'Bulgaria':
      nationalityClient = 'BG'
      break
    case 'Bahrein':
      nationalityClient = 'BH'
      break
    case 'Burundi':
      nationalityClient = 'BI'
      break
    case 'Benin':
      nationalityClient = 'BJ'
      break
    case 'Birmania':
      nationalityClient = 'BK'
      break
    case 'Bután' || 'Butan':
      nationalityClient = 'BM'
      break
    case 'Colombia':
      nationalityClient = 'CB'
      break
    case 'Córcega' || 'Corcega':
      nationalityClient = 'CC'
      break
    case 'Chad':
      nationalityClient = 'CD'
      break
    case 'Rep. Central Africana' ||
      'República Central Africana' ||
      'Republica Central Africana':
      nationalityClient = 'CF'
      break
    case 'Congo':
      nationalityClient = 'CG'
      break
    case 'Liechtenstein':
      nationalityClient = 'CH'
      break
    case 'Islas Caimán' || 'Islas Caiman':
      nationalityClient = 'CI'
      break
    case 'Comoros':
      nationalityClient = 'CJ'
      break
    case 'Chile':
      nationalityClient = 'CL'
      break
    case 'Camerún' || 'Camerun':
      nationalityClient = 'CM'
      break

    default:
      nationalityClient = 'MX'
      break
  }

  const handleCloseMessageErrorApi = () => {
    setErrorApi({ message: '', visible: false })
    setStatusMessageError(false)
  }

  //------ Historial de credito - API
  const historyCredit = async () => {
    setLoadingCredit(true)
    setStatusMessageError(false)
    try {
      const token = await getToken({
        audience: 'veridocid',
      })

      let jsonClient = {},
        urlClient

      // Sandbox
      if (ambitDev) {
        urlClient = `${API_URL}/credit/rccficoscore`
        jsonClient = {
          id: data?.id,
          uuid: data?.uuid,
          apellidoPaterno: 'SESENTAYDOS',
          apellidoMaterno: 'PRUEBA',
          primerNombre: 'JUAN',
          fechaNacimiento: '1965-08-09',
          RFC: 'SEPJ650809JG1',
          nacionalidad: 'MX',
          domicilio: {
            direccion: 'PASADISO ENCONTRADO 58',
            coloniaPoblacion: 'MONTEVIDEO',
            delegacionMunicipio: 'GUSTAVO A MADERO',
            ciudad: 'CIUDAD DE MÉXICO',
            estado: 'CDMX',
            CP: '07730',
          },
        }
      } else {
        //Prod
        urlClient = `${API_URL}/credit/v2/rccficoscore`

        if (userData !== null) {
          jsonClient = {
            id: data?.id,
            uuid: data?.uuid,
            apellidoPaterno: userData?.apellidoPaterno
              ? userData?.apellidoPaterno
              : fatherSurname,
            apellidoMaterno: userData?.apellidoMaterno
              ? userData?.apellidoMaterno
              : motherSurname,
            primerNombre: userData?.primerNombre
              ? userData?.primerNombre
              : name || givenNames,
            fechaNacimiento: userData?.fechaNacimiento
              ? userData?.fechaNacimiento
              : dateOfBirth,
            RFC: userData?.RFC,
            CURP: curp,
            nacionalidad: nationalityClient,
            domicilio: {
              direccion: userData?.direccion,
              coloniaPoblacion: userData?.colonia,
              delegacionMunicipio: userData?.delegacion,
              ciudad: userData?.ciudad,
              estado: userData?.estado,
              CP: userData?.cp,
            },
          }
        }
      }

      if (ambitDev && ambitProd) {
        setLoadingCredit(false)
        setStatusMessageError(true)
        setErrorApi({
          message: `${t('conflictCredentials')}`,
          visible: true,
        })
      } else {
        const response = await fetch(`${urlClient}`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(jsonClient),
        })

        const responseJSON = await response.json()
        if (response.ok) {
          if (responseJSON?.error) {
            setLoadingCredit(false)
            setErrorApi({
              message: `${responseJSON.error}`,
              visible: true,
            })
            setStatusCreditHistoryVerifiedBD(false)
            setStatusMessageError(true)
            return
          }

          if (responseJSON?.errores) {
            setLoadingCredit(false)
            setErrorApi({
              message: `${responseJSON?.errores[0]?.mensaje}`,
              visible: true,
            })
            setStatusCreditHistoryVerifiedBD(false)
            setStatusMessageError(true)
            return
          }

          setStatusCreditHistoryVerifiedBD(true)
          setHistoryCreditData({
            folioConsulta: responseJSON?.folioConsulta,
            claveOtorgante: responseJSON?.claveOtorgante,
            consultas: responseJSON?.consultas,
            creditos: responseJSON?.creditos,
            domicilios: responseJSON?.domicilios,
            empleos: responseJSON?.empleos,
            scores: responseJSON?.scores,
          })
        } else {
          setStatusCreditHistoryVerifiedBD(false)
          setStatusMessageError(true)

          if (responseJSON?.error) {
            setLoadingCredit(false)
            if (isArray(responseJSON?.error?.message)) {
              setErrorApi({
                message: `${responseJSON?.error?.message[0]}`,
                visible: true,
              })
            } else {
              setErrorApi({
                message: `${responseJSON?.error?.message}`,
                visible: true,
              })
            }
            return
          }

          if (responseJSON.error.errores) {
            responseJSON.error.errores.map((errors) => {
              return setErrorApi({
                message: `${errors.mensaje}`,
                visible: true,
              })
            })
          }
        }
        setLoadingCredit(false)
      }
    } catch (error) {
      setStatusMessageError(true)
    }
  }

  return (
    <div className="content_show_data_credit">
      <div className="data_user">
        <p>{fullName}</p>
        {!statusDataCredit ? (
          <Button
            loading={loadingCredit}
            className="btns_actions_primary_not_background"
            onClick={() => historyCredit()}
          >
            Ver Historial
          </Button>
        ) : (
          <ButtonPdfCreditReport
            folioConsulta={
              dataBDCredit
                ? dataBDCredit?.folioConsulta
                : historyCreditData.folioConsulta
            }
          >
            Reporte PDF
          </ButtonPdfCreditReport>
        )}
      </div>
      {statusDataCredit ? (
        <HistoryCreditWihtInfo data={dataBDCredit || historyCreditData} />
      ) : (
        <div className="config_data_user">
          {creditConfig !== null && creditConfig ? (
            <p>
              {fullName} ha terminado el proceso de verificación de su nip de
              forma satisfactoria. Ahora se puede llevar a cabo la consulta del
              historial de crédito
            </p>
          ) : (
            <p>
              {fullName}{' '}
              {!dataNipUser?.isVerified
                ? `ha terminado de enviar los datos de forma
                  satisfactoria, pero falta verificar el nip para consultar
                  el historial de crédito`
                : `ha terminado el proceso de verificación de su nip de
                forma satisfactoria. Ahora se puede llevar a cabo la consulta del historial de
                crédito`}
            </p>
          )}
        </div>
      )}

      <div className="content_error_credit" style={{ marginTop: '1em' }}>
        {statusMessageError ? (
          <Message
            error
            onDismiss={handleCloseMessageErrorApi}
            header={'Historial de crédito'}
            content={errorApi.message}
          />
        ) : null}
      </div>
    </div>
  )
}

export default HistoryCredit
