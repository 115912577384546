import { Image } from 'antd'
import React, { useState } from 'react'

import './CarouselExperts.css'
import { labels } from './CarouselExperts.fixture'

const CarouselExperts = (props) => {
  const [firstSlide, setFirstSlide] = useState('slideEnable')
  const [secondSlide, setSecondSlide] = useState('slideDisable')
  const [thirdSlide, setThirdSlide] = useState('slideDisable')
  const [fourthSlide, setFourthSlide] = useState('slideDisable')
  const [fifthSlide, setFifthSlide] = useState('slideDisable')
  const [sixthSlide, setSixthSlide] = useState('slideDisable')
  const [dotOne, setDotOne] = useState(['dot', 'active'])
  const [dotTwo, setDotTwo] = useState(['dot'])
  const [dotThree, setDotThree] = useState(['dot'])
  const [dotFour, setDotFour] = useState(['dot'])
  const [dotFive, setDotFive] = useState(['dot'])
  const [dotSix, setDotSix] = useState(['dot'])
  const [title, setTitle] = useState(labels.front)

  // Thumbnail image controls
  const currentSlide = (n) => {
    showSlides(n)
  }

  const showSlides = (n) => {
    switch (n) {
      case 1:
        setFirstSlide('slideEnable')
        setSecondSlide('slideDisable')
        setThirdSlide('slideDisable')
        setFourthSlide('slideDisable')
        setFifthSlide('slideDisable')
        setSixthSlide('slideDisable')
        setDotOne(['dot', 'active'])
        setDotTwo(['dot'])
        setDotThree(['dot'])
        setDotFour(['dot'])
        setDotFive(['dot'])
        setDotSix(['dot'])
        setTitle(labels.front)
        break
      case 2:
        setFirstSlide('slideDisable')
        setSecondSlide('slideEnable')
        setThirdSlide('slideDisable')
        setFourthSlide('slideDisable')
        setFifthSlide('slideDisable')
        setSixthSlide('slideDisable')
        setDotOne(['dot'])
        setDotTwo(['dot', 'active'])
        setDotThree(['dot'])
        setDotFour(['dot'])
        setDotFive(['dot'])
        setDotSix(['dot'])
        setTitle(labels.back)
        break
      case 3:
        setFirstSlide('slideDisable')
        setSecondSlide('slideDisable')
        setThirdSlide('slideEnable')
        setFourthSlide('slideDisable')
        setFifthSlide('slideDisable')
        setSixthSlide('slideDisable')
        setDotOne(['dot'])
        setDotTwo(['dot'])
        setDotThree(['dot', 'active'])
        setDotFour(['dot'])
        setDotFive(['dot'])
        setDotSix(['dot'])
        setTitle(labels.frontUltra)
        break
      case 4:
        setFirstSlide('slideDisable')
        setSecondSlide('slideDisable')
        setThirdSlide('slideDisable')
        setFourthSlide('slideEnable')
        setFifthSlide('slideDisable')
        setSixthSlide('slideDisable')
        setDotOne(['dot'])
        setDotTwo(['dot'])
        setDotThree(['dot'])
        setDotFour(['dot', 'active'])
        setDotFive(['dot'])
        setDotSix(['dot'])
        setTitle(labels.backUltra)
        break
      case 5:
        setFirstSlide('slideDisable')
        setSecondSlide('slideDisable')
        setThirdSlide('slideDisable')
        setFourthSlide('slideDisable')
        setFifthSlide('slideEnable')
        setSixthSlide('slideDisable')
        setDotOne(['dot'])
        setDotTwo(['dot'])
        setDotThree(['dot'])
        setDotFour(['dot'])
        setDotFive(['dot', 'active'])
        setDotSix(['dot'])
        setTitle(labels.frontInfra)
        break
      case 6:
        setFirstSlide('slideDisable')
        setSecondSlide('slideDisable')
        setThirdSlide('slideDisable')
        setFourthSlide('slideDisable')
        setFifthSlide('slideDisable')
        setSixthSlide('slideEnable')
        setDotOne(['dot'])
        setDotTwo(['dot'])
        setDotThree(['dot'])
        setDotFour(['dot'])
        setDotFive(['dot'])
        setDotSix(['dot', 'active'])
        setTitle(labels.backInfra)
        break
      default:
        setFirstSlide('slideEnable')
        setSecondSlide('slideDisable')
        setDotTwo(['dot'])
        setDotOne(['dot', 'active'])
        setTitle(labels.front)
        break
    }
  }

  const contentFront = !props.imageBack ? (
    <Image
      src={props.imageFront}
      alt={props.name}
      className="FrontImageCarouselExperts"
    />
  ) : (
    <Image
      src={props.imageFront}
      alt={props.name}
      className="ImagesCarouselExperts"
    />
  )

  const dots = props.type === "scanner" ? (
    <div className="dots">
      <button className={dotOne.join(' ')} onClick={() => currentSlide(1)} />
      <button className={dotTwo.join(' ')} onClick={() => currentSlide(2)} />
      <button className={dotThree.join(' ')} onClick={() => currentSlide(3)} />
      <button className={dotFour.join(' ')} onClick={() => currentSlide(4)} />
      <button className={dotFive.join(' ')} onClick={() => currentSlide(5)} />
      <button className={dotSix.join(' ')} onClick={() => currentSlide(6)} />
    </div>
  ) : !props.imageBack ? (
    <span />
  ) : (
    <div className="dots">
      <button className={dotOne.join(' ')} onClick={() => currentSlide(1)} />
      <button className={dotTwo.join(' ')} onClick={() => currentSlide(2)} />
    </div>
  )

  return (
    <div className="CarouselExperts">
      <div className="TitleCarouselExperts">
        <b>{props.title}</b>
      </div>
      <div className="CarouselExpertsImages">
        <div className="slideshow-container">
          <h4 style={{ textAlign: 'center' }}>
            {props?.isDefault ? labels.selectTemplate : title}
          </h4>
          <div className={firstSlide}>{contentFront}</div>
          <div className={secondSlide}>
            <Image
              src={props?.imageBack}
              alt={props?.name}
              className="ImagesCarouselExperts"
            />
          </div>
          <div className={thirdSlide}>
            <Image
              src={props?.imageFrontUltraviolet}
              alt={props?.name}
              className="ImagesCarouselExperts"
            />
          </div>
          <div className={fourthSlide}>
            <Image
              src={props?.imageBackUltraviolet}
              alt={props?.name}
              className="ImagesCarouselExperts"
            />
          </div>
          <div className={fifthSlide}>
            <Image
              src={props?.imageFrontInfrared}
              alt={props?.name}
              className="ImagesCarouselExperts"
            />
          </div>
          <div className={sixthSlide}>
            <Image
              src={props?.imageBackimageFrontInfrared}
              alt={props?.name}
              className="ImagesCarouselExperts"
            />
          </div>
        </div>
        {dots}
      </div>
      <div className="Info">
        <b className="InfoTitle">{props.titleInfo}</b>
        <div className="InfoBody">
          {props.icon ? (
            <Image
              src={props.icon}
              alt="icon"
              style={{ width: '2.5rem', marginRight: '1rem' }}
            />
          ) : null}
          <div style={{ fontSize: '1.1rem' }}>{props.info}</div>
        </div>
      </div>
    </div>
  )
}

export default CarouselExperts
