import React from 'react'
import {
  Container,
  MessageTitle,
  MessageInput,
  MessageTextArea,
} from './MessageStyle.jsx'

export default function Message(props) {
  const { title, setTitle, message, setMessage } = props

  const handleTitle = (e) => setTitle(e.target.value)

  const handleMessage = (e) => setMessage(e.target.value)

  return (
    <Container>
      <MessageTitle>Personalización del mensaje</MessageTitle>
      <label>Título</label>
      <MessageInput type="text" value={title} onChange={handleTitle} />
      <label>Mensaje</label>
      <MessageTextArea value={message} onChange={handleMessage} />
    </Container>
  )
}
