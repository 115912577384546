import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'

export const Container = styled(Grid)({
  width: '100%',
  border: '0.5px solid #c8c8c8',
})

export const Tools = styled(Grid)({
  height: '50px',
  backgroundColor: 'var(--secondary-color)',
  color: '#eee',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: '500',
})

export const Tool = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
})

export const MoreTools = styled(Grid)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
})

export const Contract = styled(Grid)({
  height: '900px',
  backgroundColor: '#f2f2f2',
  cursor: 'pointer',
  boxShadow:
    '0 1px 2px -2px rgba(218, 218, 218, 0.16), 0 4px 7px 1px #4b4b4b18 !important',
  border: '2px solid #e6e6e6',
  transition: 'transition: 0.3s color ease, 0.3s background-color ease',
  '&:hover, &:active, &:focus': {
    backgroundColor: '#f186aa2c',
    borderColor: 'var(--secondary-color)',
    boxShadow: 'none !important',
  },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
})

export const Input = styled('input')({
  display: 'none',
})

export const UploadPDF = styled('label')({
  fontSize: '25px',
  color: 'var(--secondary-color)',
  cursor: 'pointer',
})

export const Instructions = styled('label')({
  fontSize: '20px',
  color: '#777777',
  cursor: 'pointer',
})

export const ShowPDF = styled(Grid)({
  width: '100%',
  height: '900px',
  padding: '50px',
  overflow: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  backgroundColor: '#E6E6E6',
  flexDirection: 'row',
  position: 'relative',
  zIndex: '98',
})

export const DDZone = styled(Grid)((props) => ({
  width: props.width ? props.width : 612,
  height: props.height ? props.height : 792,
  position: 'absolute',
  zIndex: '95',
  overflow: 'hidden',
}))
