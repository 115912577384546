import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'

export const SignersGrid = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 'clamp(.5rem,.85rem,.9rem)',
  margin: '1.9rem 0 1.8rem 0',
})

export const SignersGridLeft = styled('div')({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'flex-start',
  flexDirection: 'column',
})

export const SignersGridRight = styled('div')({
  width: '30%',
  textAlign: 'center',
})

export const SignersCard = styled(Grid)({
  width: '100%',
  minHeight: '250px',
  backgroundColor: '#fff',
  padding: '20px 18px',
  display: 'flex',
  flexDirection: 'column',
})

export const EndorseSignatureCardInput = styled('textarea')({
  border: '1px solid #d9d9d9',
  color: 'grey',
  paddingLeft: '10px',
  paddingTop: '9px',
  marginBottom: '15px',
  outline: 'none',
  height: '100px',
  width: '100%',
  marginTop: '1rem',
  '&:hover, &:active, &:focus': {
    border: '1px solid var(--secondary-color)',
  },
})
