import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'

export const MyGrid = styled(Grid)((props) => ({
  color: 'var(--secondary-color)',
  width: '100%',
  flexWrap: 'initial',
  marginTop: props.marginTop ? `${props.marginTop}px` : '0',
  marginBottom: props.marginBottom ? `${props.marginBottom}px` : '0',
}))

export const GeolocationMessage = styled(Grid)((props) => ({
  fontSize: 'clamp(0.87em,9em,0.97em)',
  fontWeight: 500,
  lineHeight: '18px',
  color: props.fc ? props.fc : '#000',
}))
