import React from 'react'
import { CountryDropdown } from 'react-country-region-selector'
import { useTranslation } from 'react-i18next'
import { Switch, Tooltip, Radio } from 'antd'
import { Grid } from '@mui/material'
import {
  Country,
  Geolocation,
  GeolocationOptions,
  GeolocationOptionsText,
  GeolocationOptionsSwitch,
} from './MainConfigurationsStyle.jsx'
import { VscInfo } from 'react-icons/vsc'
import './MainConfigurationsStyle.css'

export default function MainConfigurations(props) {
  const {
    dataCountry,
    setDataCountry,
    checked,
    setChecked,
    whoSign,
    setWhoSign,
    handleReset,
  } = props
  const { t } = useTranslation()

  const handleSelectCountry = (value) => setDataCountry(value)

  const handleParticipantsGeolocation = (e) => setChecked(e)

  const handleOptionSign = (e) => {
    setWhoSign(e?.target?.value)
    handleReset()
  }

  return (
    <>
      <Grid container>
        <span className="description_config_client">
          Configure sus opciones que mas se ajusten a sus necesidades
        </span>
        <Grid container style={{ margin: '15px 0 5px 0' }}>
          <Country item xs={12} sm={12} md={12} lg={12} xl={6}>
            <label>País</label>
            <CountryDropdown
              defaultOptionLabel={t('selectCountry')}
              value={dataCountry}
              priorityOptions={['MX', 'US', 'CA']}
              onChange={handleSelectCountry}
              className="select_country"
              style={{ outline: 'none', color: '#000' }}
            />
          </Country>
          {whoSign !== 'me' ? (
            <Geolocation item xs={12} sm={12} md={12} lg={12} xl={6}>
              <GeolocationOptions container>
                <GeolocationOptionsText item xs={12}>
                  <Tooltip title="Activa o desactiva la geolocalización de tus firmantes">
                    <VscInfo
                      color="rgb(140, 140, 140)"
                      size={16}
                      style={{ cursor: 'pointer', marginRight: '10px' }}
                    />
                  </Tooltip>
                  Geolocalización para firmante(s)
                </GeolocationOptionsText>
                <GeolocationOptionsSwitch
                  item
                  xs={12}
                  className="config_form__content_input"
                >
                  <Switch
                    defaultChecked
                    style={{
                      width: '14px',
                      marginTop: '0.9em',
                    }}
                    checked={checked}
                    onChange={handleParticipantsGeolocation}
                  />
                </GeolocationOptionsSwitch>
              </GeolocationOptions>
            </Geolocation>
          ) : null}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        color="var(--secondary-color)"
        style={{ color: 'var(--secondary-color)', marginBottom: '10px' }}
      >
        <Tooltip
          key="tooltip_who_sign"
          title="Selecciona quien o quienes participaran en el contrato"
        >
          <VscInfo
            color="rgb(140, 140, 140)"
            size={16}
            style={{ cursor: 'pointer', marginRight: '10px' }}
          />
        </Tooltip>{' '}
        ¿Quién firma?
      </Grid>
      <Radio.Group
        defaultValue={'others'}
        name="who_sign"
        onChange={handleOptionSign}
        value={whoSign}
        //disabled={disabledInputsConfig}
        style={{
          //border: '1px solid black',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          marginBottom: '15px',
          flexDirection: 'row',
        }}
        className="config_form__content_input"
      >
        <Radio value={'me'}>Yo</Radio>
        <Radio value={'me_others'}>Otros y Yo</Radio>
        <Radio value={'others'}>Otros</Radio>
      </Radio.Group>
    </>
  )
}
