import React from 'react'

const getDomainDetails = (domain_details) => {
  const domainDetails = Object.keys(domain_details).map((key, index) => {
    switch (key) {
      case 'registered':
        return (
          <tr key={index}>
            <td style={{ width: '50%' }}>Registered</td>
            <td style={{ width: '50%' }}>
              {domain_details[key] ? 'Yes' : 'No'}
            </td>
          </tr>
        )
      case 'created':
        return (
          <tr key={index}>
            <td style={{ width: '50%' }}>Creation date</td>
            <td style={{ width: '50%' }}>{domain_details[key]}</td>
          </tr>
        )
      case 'updated':
        return (
          <tr key={index}>
            <td style={{ width: '50%' }}>Update date</td>
            <td style={{ width: '50%' }}>{domain_details[key]}</td>
          </tr>
        )
      case 'expires':
        return (
          <tr key={index}>
            <td style={{ width: '50%' }}>Expire date</td>
            <td style={{ width: '50%' }}>{domain_details[key]}</td>
          </tr>
        )
      case 'registrar_name':
        return (
          <tr key={index}>
            <td style={{ width: '50%' }}>Register name</td>
            <td style={{ width: '50%' }}>{domain_details[key]}</td>
          </tr>
        )
      case 'dmarc_enforced':
        return (
          <tr key={index}>
            <td style={{ width: '50%' }}>DMARC enforced</td>
            <td style={{ width: '50%' }}>
              {domain_details[key] ? 'Yes' : 'No'}
            </td>
          </tr>
        )
      case 'spf_strict':
        return (
          <tr key={index}>
            <td style={{ width: '50%' }}>SPF stric</td>
            <td style={{ width: '50%' }}>
              {domain_details[key] ? 'Yes' : 'No'}
            </td>
          </tr>
        )
      case 'valid_mx':
        return (
          <tr key={index}>
            <td style={{ width: '50%' }}>Valid MX</td>
            <td style={{ width: '50%' }}>
              {domain_details[key] ? 'Yes' : 'No'}
            </td>
          </tr>
        )
      case 'suspicious_tld':
        return (
          <tr key={index}>
            <td style={{ width: '50%' }}>Suspicious TLD</td>
            <td style={{ width: '50%' }}>
              {domain_details[key] ? 'Yes' : 'No'}
            </td>
          </tr>
        )
      case 'accept_all':
        return (
          <tr key={index}>
            <td style={{ width: '50%' }}>Accept all</td>
            <td style={{ width: '50%' }}>
              {domain_details[key] ? 'Yes' : 'No'}
            </td>
          </tr>
        )
      default:
        return null
    }
  })

  return domainDetails
}

export default getDomainDetails
