import React from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from 'semantic-ui-react'

const CardControlList = ({ dataControlList }) => {
  const { t } = useTranslation()
  const controlListValues = dataControlList[0]?.inputFields[0]?.value
  const name = dataControlList[0]?.name
  const result = dataControlList[0]?.result
  const color = result === 'Ok' ? 'green' : 'failed'

  const rows = Object.keys(controlListValues).map((key, index) => (
    <Table.Row key={index}>
      <Table.Cell width={6}>
        <strong>{key}</strong>
      </Table.Cell>
      <Table.Cell>{controlListValues[key]}</Table.Cell>
    </Table.Row>
  ))

  const rowOk = (
    <Table.Row>
      <Table.Cell width={6}>
        <strong>{t('status')}: </strong>
      </Table.Cell>
      <Table.Cell>{controlListValues}</Table.Cell>
    </Table.Row>
  )

  const rowFailed = (
    <div>
      <Table.Row>
        <Table.Cell width={6}>
          <strong>{t('status')}: </strong>
        </Table.Cell>
        <Table.Cell>{name}</Table.Cell>
      </Table.Row>
      {rows}
    </div>
  )

  return (
    <>
      <Table color={color}>
        <Table.Body>{result === 'Ok' ? rowOk : rowFailed}</Table.Body>
      </Table>
    </>
  )
}

export default CardControlList
