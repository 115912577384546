import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Upload as AntUpload, Modal } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

const Upload = ({ id, onUpload, ...props }) => {
  const { t } = useTranslation()
  const [previewVisible, setPreviewVisible] = useState(false) //false
  const [previewImage, setPreviewImage] = useState('') //props.fileList ? props.fileList[0].b64 : ''
  const [previewTitle, setPreviewTitle] = useState('') //''
  const [fileList, setFileList] = useState(props.fileList ? props.fileList : []) //extra, default era []

  const handleCancel = () => setPreviewVisible(false)

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    // if (!file.b64) { //exrta
    //   file.preview = await getBase64(file.originFileObj) //extra
    // } //extra

    setPreviewImage(file.url || file.preview)
    setPreviewVisible(true)
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    )
  }

  const handleChange = ({ file, fileList }) => {
    const newList = fileList.length ? [fileList?.[fileList?.length - 1]] : []
    setFileList(newList)
    onUpload({
      id,
      file: newList?.[0]?.originFileObj ?? null,
    })
    return false
  }

  const handleBeforeUpload = (file, fileList) => {
    return false
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">{t('uploadFile')}</div>
    </div>
  )

  return (
    <div>
      <AntUpload
        {...props}
        multiple={false}
        accept=".png,.jpg,.jpeg,.bmp"
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        beforeUpload={handleBeforeUpload}
      >
        {fileList.length >= 1 ? null : uploadButton}
      </AntUpload>
      <Modal
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
        open={previewVisible}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  )
}

export default Upload
