import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'

export const Container = styled(Grid)({
  width: '100%',
  height: '292px',
  position: 'relative',
  zIndex: '10',
  userSelect: 'none',
})

export const Logo = styled('img')({
  width: '100%',
  height: '100%',
  position: 'absolute',
  zIndex: '10',
  userSelect: 'none',
})

export const InternalContainer = styled(Grid)((props) => ({
  width:
    props.matches360 === 'true'
      ? '230px'
      : props.matches420 === 'true'
      ? '235px'
      : props.matches480 === 'true'
      ? '280px'
      : props.matches600 === 'true'
      ? '333px'
      : '390px',
  height:
    props.matches360 === 'true'
      ? '85px'
      : props.matches420 === 'true'
      ? '90px'
      : props.matches480 === 'true'
      ? '110px'
      : props.matches600 === 'true'
      ? '130px'
      : '160px',
  margin: `0 auto`,
  marginTop:
    props.matches360 === 'true'
      ? '85px'
      : props.matches420 === 'true'
      ? '85px'
      : props.matches480 === 'true'
      ? '75px'
      : props.matches600 === 'true'
      ? '65px'
      : '70px',
  position: 'relative',
  zIndex: '90',
  userSelect: 'none',
  backgroundRepeat: 'no-repeat',
  backgroundImage: props?.logo ? `url(${props.logo})` : null,
  backgroundSize: '240px 105px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const InputText = styled('input')((props) => ({
  width: '90%',
  border: 'none',
  borderBottom: '2px solid black',
  outline: 'none',
  fontSize:
    props.matches360 === 'true'
      ? '15px'
      : props.matches420 === 'true'
      ? '15px'
      : props.matches480 === 'true'
      ? '18px'
      : props.matches600 === 'true'
      ? '21px'
      : '25px',
  fontFamily: 'Arial',
  fontWeight: 'bold',
  '&::placeholder': {
    fontWeight: 'normal',
  },
}))

export const Time = styled(Grid)({
  marginLeft: '50px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontWeight: 'bold',
  userSelect: 'none',
  fontSize: 'clamp(15px, 16px, 17px)',
})
