import React from 'react'
import { Tooltip } from 'antd'
import { Button } from 'semantic-ui-react'
import { VscDesktopDownload } from 'react-icons/vsc'

export const DownloadContract = ({
  loadingDownload,
  data,
  downloadContract,
}) => (
  <div className="content_download_contract">
    <Tooltip title="Descargar contrato">
      <Button
        loading={loadingDownload ? true : false}
        disabled={
          data?.status &&
          (data?.status === 'signed' || data?.status === 'approved')
            ? false
            : true
        }
        type="button"
        style={{
          color: '#2d2f6d',
          border: 'none',
          outline: 'none',
        }}
        className="download_contract"
        onClick={downloadContract}
      >
        <VscDesktopDownload size={20} />
      </Button>
    </Tooltip>
  </div>
)
