import React, { useRef } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { VscTrash } from 'react-icons/vsc'
import {
  MainContainer,
  InternalContainer,
  Clean,
  Instructions,
} from './Step1Option1.jsx'
import { useTranslation } from 'react-i18next'

export default function Step1Option1(props) {
  const { signatureData, setSignatureData } = props
  const { t } = useTranslation()
  const signatureRef = useRef({})

  const handleEnd = () =>
    setSignatureData(signatureRef.current.toDataURL('image/png'))

  const handleClear = () => {
    signatureRef.current.clear()
    setSignatureData(null)
  }

  return (
    <MainContainer>
      <InternalContainer>
        <SignatureCanvas
          canvasProps={{
            width: '333px',
            height: '130px',
            position: 'relative',
            zindex: '95',
            userselect: 'none',
          }}
          onEnd={handleEnd}
          ref={signatureRef}
        />
        <Clean onClick={handleClear}>
          <VscTrash size={20} color={'tomato'} strokeWidth={0.3} />
        </Clean>
      </InternalContainer>
      {!signatureData ? <Instructions>{`${t('putSign')}`}</Instructions> : null}
    </MainContainer>
  )
}
