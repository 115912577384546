import React from 'react'
import loading from '../assets/loading.svg'

const Loading = () => (
  <div className="spinner">
    <img
      src={loading}
      alt="Loading"
      style={{ width: '50%', height: '50%', alignSelf: 'center' }}
    />
  </div>
)

export default Loading
