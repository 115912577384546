import React from 'react'
import { Table } from 'semantic-ui-react'

const CardRenapoWithInfo = ({ dataRenapo }) => (
  <>
    <Table color={dataRenapo ? 'green' : 'black'}>
      <Table.Body>
        <Table.Row>
          <Table.Cell width={6}>
            <strong>Estatus de la consulta:</strong>
          </Table.Cell>
          <Table.Cell>{dataRenapo?.estatus}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell width={6}>
            <strong>CURP:</strong>
          </Table.Cell>
          <Table.Cell>{dataRenapo?.curp}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <strong>Nombre(s):</strong>
          </Table.Cell>
          <Table.Cell>{dataRenapo?.nombre}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <strong>Apellido Paterno:</strong>
          </Table.Cell>
          <Table.Cell>{dataRenapo?.apellidoPaterno}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <strong>Apellido Materno:</strong>
          </Table.Cell>
          <Table.Cell>{dataRenapo?.apellidoMaterno}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <strong>Sexo:</strong>
          </Table.Cell>
          <Table.Cell>{dataRenapo?.sexo}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <strong>Fecha de Nacimiento:</strong>
          </Table.Cell>
          <Table.Cell>{dataRenapo?.fechaNacimiento}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <strong>País de Nacimiento:</strong>
          </Table.Cell>
          <Table.Cell>{dataRenapo?.paisNacimiento}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <strong>Estado de Nacimiento:</strong>
          </Table.Cell>
          <Table.Cell>{dataRenapo?.estadoNacimiento}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  </>
)

export default CardRenapoWithInfo
