import React from 'react'
import { Collapse, Table } from 'antd'
import { isEmpty } from 'lodash'
import './../CreditStyles.css'

const BelvoWithDataFiscal = ({ showDataFiscal }) => {
  const { Column } = Table
  const { Panel } = Collapse

  return (
    <>
      <Collapse
        bordered={false}
        style={{
          marginTop: '2em',
          marginBottom: '1em',
        }}
      >
        {showDataFiscal.invoices_inflow ? (
          <Panel
            style={{
              backgroundColor: 'rgb(238, 238, 238)',
              border: '0',
              marginBottom: '20px',
              borderRadius: '2px',
            }}
            header="Facturas recibidas"
            key="1"
          >
            <Table
              dataSource={showDataFiscal?.invoices_inflow}
              style={{ margin: '1.4em 0 0 0' }}
              scroll={{ x: 3000 }}
            >
              {/* Fecha de la consulta de los datos */}
              <Column
                title="Fecha de la consulta de los datos"
                key="created_at"
                dataIndex="created_at"
                render={(created_at) => <>{created_at.split('T')[0] || '-'}</>}
              ></Column>
              {/* Fecha de la factura */}
              <Column
                title="Fecha de la factura"
                key="invoice_date"
                dataIndex="invoice_date"
                render={(invoice_date) => <>{invoice_date || '-'}</>}
              ></Column>
              {/* Estado */}
              <Column
                title="Estado"
                key="status"
                dataIndex="status"
                render={(status) => <>{status || '-'}</>}
              ></Column>
              {/* Tipo de factura */}
              <Column
                title="Tipo de factura"
                key="invoice_type"
                dataIndex="invoice_type"
                render={(invoice_type) => <>{invoice_type || '-'}</>}
              ></Column>
              {/* Detalles de la factura */}
              <Column
                title="Detalles de la factura"
                key="invoice_details"
                dataIndex="invoice_details"
                render={(invoice_details) => (
                  <>
                    {invoice_details.map((data, key) => (
                      <ul key={key}>
                        <li>Descripción: {data?.description}</li>
                        <ul>
                          <li>Importe de impuestos: {data?.tax_amount}</li>
                          <li>Importe total: {data?.total_amount}</li>
                          <li>Importe unitario: {data?.unit_amount}</li>
                          <li>Cantidad: {data?.quantity}</li>
                          <li>
                            Código de unidad:{' '}
                            {data?.unit_code === '10'
                              ? 'Grupos'
                              : data?.unit_code === '11'
                              ? 'Equipos'
                              : data?.unit_code === 'A9'
                              ? 'Tarifa'
                              : data?.unit_code === 'AB'
                              ? 'Paquete a granel'
                              : data?.unit_code === 'ACT'
                              ? 'Actividad'
                              : data?.unit_code === 'AS'
                              ? 'Variedad'
                              : data?.unit_code === 'BB'
                              ? 'Caja base'
                              : data?.unit_code === 'DAY'
                              ? 'Día'
                              : data?.unit_code === 'DPC'
                              ? 'Docenas de piezas'
                              : data?.unit_code === 'E48'
                              ? 'Unidad de servicio'
                              : data?.unit_code === 'E51'
                              ? 'Trabajo'
                              : data?.unit_code === 'E54'
                              ? 'Viaje'
                              : data?.unit_code === 'EA'
                              ? 'Elemento'
                              : data?.unit_code === 'GRM'
                              ? 'Gramo'
                              : data?.unit_code === 'H87'
                              ? 'Parte'
                              : data?.unit_code === 'HUR'
                              ? 'Tiempo'
                              : data?.unit_code === 'KGM'
                              ? 'Kilogramo'
                              : data?.unit_code === 'KT'
                              ? 'Kit'
                              : data?.unit_code === 'LTR'
                              ? 'Litro'
                              : data?.unit_code === 'MGM'
                              ? 'Miligramo'
                              : data?.unit_code === 'MLT'
                              ? 'Mililitro'
                              : data?.unit_code === 'MON'
                              ? 'Mes'
                              : data?.unit_code === 'MKT'
                              ? 'Metro cuadrado'
                              : data?.unit_code === 'MTR'
                              ? 'Metro'
                              : data?.unit_code === 'PR'
                              ? 'Par'
                              : data?.unit_code === 'SET'
                              ? 'Conjunto'
                              : data?.unit_code === 'XBX'
                              ? 'Caja'
                              : data?.unit_code === 'XKI'
                              ? 'Kit (conjunto de piezas)'
                              : data?.unit_code === 'XLT'
                              ? 'Lote'
                              : data?.unit_code === 'XPK'
                              ? 'Paquete'
                              : data?.unit_code === 'xun'
                              ? 'Unidad'
                              : '-'}
                          </li>
                          <li>
                            Descripción del producto: {data?.unit_description}
                          </li>
                        </ul>
                      </ul>
                    ))}
                  </>
                )}
              ></Column>
              {/* Finalidad */}
              <Column
                title="Finalidad"
                key="usage"
                dataIndex="usage"
                render={(usage) => (
                  <>
                    {usage === 'D01'
                      ? 'Gastos médicos, odontológicos y hospitalarios'
                      : usage === 'D02'
                      ? 'Gastos médicos por discapacidad o invalidez'
                      : usage === 'D03'
                      ? 'Gastos funerarios'
                      : usage === 'D04'
                      ? 'Donaciones'
                      : usage === 'D05'
                      ? 'Intereses reales pagados por préstamos hipotecarios'
                      : usage === 'D06'
                      ? 'Aportaciones voluntarias al SAR'
                      : usage === 'D07'
                      ? 'Seguro de gastos médicos'
                      : usage === 'D08'
                      ? 'Gastos de transporte escolar'
                      : usage === 'D09'
                      ? 'Depósitos en cuentas de ahorro, primas basadas en planes de pensiones'
                      : usage === 'D10'
                      ? 'Pagos por servicios educativos'
                      : usage === 'G01'
                      ? 'Adquisición de mercancías'
                      : usage === 'G02'
                      ? 'Reembolso, descuentos o bonificaciones'
                      : usage === 'G03'
                      ? 'Gastos generales'
                      : usage === 'I01'
                      ? 'Edificios'
                      : usage === 'I02'
                      ? 'Mobiliario y material de oficina'
                      : usage === 'I03'
                      ? 'Material de transporte'
                      : usage === 'I04'
                      ? 'Equipos y accesorios informáticos'
                      : usage === 'I05'
                      ? 'Matrices, moldes, troqueles y herramientas'
                      : usage === 'I06'
                      ? 'Comunicaciones telefónicas'
                      : usage === 'I07'
                      ? 'Comunicaciones por satélite'
                      : usage === 'I08'
                      ? 'Otras máquinas y equipos'
                      : usage === 'P01'
                      ? 'A definir'
                      : '-'}
                  </>
                )}
              ></Column>
              {/* Nombre del remitente */}
              <Column
                title="Nombre del remitente"
                key="sender_name"
                dataIndex="sender_name"
                render={(sender_name) => <>{sender_name || '-'}</>}
              ></Column>
              {/* Nombre del destinatario */}
              <Column
                title="Nombre del destinatario"
                key="receiver_name"
                dataIndex="receiver_name"
                render={(receiver_name) => <>{receiver_name || '-'}</>}
              ></Column>
              {/* Código postal del lugar de emisión */}
              <Column
                title="Código postal del lugar de emisión"
                key="place_of_issue"
                dataIndex="place_of_issue"
                render={(place_of_issue) => <>{place_of_issue || '-'}</>}
              ></Column>
              {/* Importe de impuestos */}
              <Column
                title="Importe de impuestos"
                key="tax_amount"
                dataIndex="tax_amount"
                render={(tax_amount) => <>{tax_amount || 0}</>}
              ></Column>
              {/* Importe total */}
              <Column
                title="Importe total"
                key="total_amount"
                dataIndex="total_amount"
                render={(total_amount) => <>{total_amount || 0}</>}
              ></Column>
              {/* Métodos de pago */}
              <Column
                title="Métodos de pago"
                key="payment_method"
                dataIndex="payment_method"
                render={(payment_method) => (
                  <>
                    {payment_method === 'PUE'
                      ? 'Pago único'
                      : payment_method === 'PPD'
                      ? 'Pagos parciales'
                      : payment_method === 'PIP'
                      ? 'Pagos iniciales o fraccionados'
                      : '-'}
                  </>
                )}
              ></Column>
              {/* Tipos de pagos */}
              <Column
                title="Tipos de pagos"
                key="payment_type"
                dataIndex="payment_type"
                render={(payment_type) => (
                  <>
                    {payment_type === '01'
                      ? 'Caja'
                      : payment_type === '02'
                      ? 'Cheque nómina'
                      : payment_type === '03'
                      ? 'Transferencia electrónica'
                      : payment_type === '04'
                      ? 'Tarjeta de crédito'
                      : payment_type === '05'
                      ? 'Monedero electrónico'
                      : payment_type === '06'
                      ? 'Dinero digital'
                      : payment_type === '08'
                      ? 'Vales de comida'
                      : payment_type === '12'
                      ? 'Liquidación'
                      : payment_type === '13'
                      ? 'Pago por subrogación'
                      : payment_type === '14'
                      ? 'Pago por consignación'
                      : payment_type === '15'
                      ? 'Condonación'
                      : payment_type === '17'
                      ? 'Indemnización'
                      : payment_type === '23'
                      ? 'Novación'
                      : payment_type === '24'
                      ? 'Confusión'
                      : payment_type === '25'
                      ? 'Remesa de deudas'
                      : payment_type === '26'
                      ? 'Prescripción o caducidad'
                      : payment_type === '27'
                      ? 'A satisfacción del acreedor'
                      : payment_type === '28'
                      ? 'Tarjeta de débito'
                      : payment_type === '29'
                      ? 'Tarjeta de servicio'
                      : payment_type === '99'
                      ? 'A definir'
                      : '-'}
                  </>
                )}
              ></Column>
              {/* Detalles de los pagos */}
              <Column
                title="Detalles de los pagos"
                key="payments"
                dataIndex="payments"
                render={(payments) => (
                  <>
                    {payments.map((data, key) => (
                      <ul key={key}>
                        <li>Fecha: {data?.date.split('T')[0]}</li>
                        <br />
                        <strong>Beneficiario</strong>
                        <li>RFC: {data?.beneficiary_rfc}</li>
                        <li>
                          Número de cuenta: {data?.beneficiary_account_number}
                        </li>
                        <br />
                        <strong>Quien paga</strong>
                        <li>RFC: {data?.payer_rfc}</li>
                        <li>Número de cuenta: {data?.payer_account_number}</li>
                        <li>Nombre del banco: {data?.payer_bank_name}</li>
                        <br />
                        <strong>Detalles</strong>
                        <li>Monto: {data?.amount}</li>
                        <li>Número de operación: {data?.operation_number}</li>
                        <li>
                          Tipo de pago:{' '}
                          {data?.payment_type === '01'
                            ? 'Caja'
                            : data?.payment_type === '02'
                            ? 'Cheque nómina'
                            : data?.payment_type === '03'
                            ? 'Transferencia electrónica'
                            : data?.payment_type === '04'
                            ? 'Tarjeta de crédito'
                            : data?.payment_type === '05'
                            ? 'Monedero electrónico'
                            : data?.payment_type === '06'
                            ? 'Dinero digital'
                            : data?.payment_type === '08'
                            ? 'Vales de comida'
                            : data?.payment_type === '12'
                            ? 'Liquidación'
                            : data?.payment_type === '13'
                            ? 'Pago por subrogación'
                            : data?.payment_type === '14'
                            ? 'Pago por consignación'
                            : data?.payment_type === '15'
                            ? 'Condonación'
                            : data?.payment_type === '17'
                            ? 'Indemnización'
                            : data?.payment_type === '23'
                            ? 'Novación'
                            : data?.payment_type === '24'
                            ? 'Confusión'
                            : data?.payment_type === '25'
                            ? 'Remesa de deudas'
                            : data?.payment_type === '26'
                            ? 'Prescripción o caducidad'
                            : data?.payment_type === '27'
                            ? 'A satisfacción del acreedor'
                            : data?.payment_type === '28'
                            ? 'Tarjeta de débito'
                            : data?.payment_type === '29'
                            ? 'Tarjeta de servicio'
                            : data?.payment_type === '99'
                            ? 'A definir'
                            : '-'}
                        </li>
                        <li>
                          Moneda:{' '}
                          {data?.currency === 'MXN'
                            ? 'Pesos Mexicanos'
                            : data?.currency === 'COP'
                            ? 'Pesos Colombianos'
                            : data?.currency === 'BRL'
                            ? 'Real Brasileño'
                            : '-'}
                        </li>
                      </ul>
                    ))}
                  </>
                )}
              ></Column>
              {/* Certificado de autorización */}
              <Column
                title="Certificado de autorización"
                dataIndex="certification_authority"
                key="certification_authority"
                render={(certification_authority) => (
                  <>{certification_authority || '-'}</>
                )}
              />
              {/* Moneda */}
              <Column
                title="Moneda"
                dataIndex="currency"
                key="currency"
                render={(currency) => (
                  <>
                    {currency === 'MXN'
                      ? 'Pesos Mexicanos'
                      : currency === 'COP'
                      ? 'Pesos Colombianos'
                      : currency === 'BRL'
                      ? 'Real Brasileño'
                      : '-'}
                  </>
                )}
              />
            </Table>
          </Panel>
        ) : null}
        {showDataFiscal.invoices_outflow ? (
          <Panel
            style={{
              backgroundColor: 'rgb(238, 238, 238)',
              border: '0',
              marginBottom: '20px',
              borderRadius: '2px',
            }}
            header="Facturas enviadas"
            key="2"
          >
            <Table
              dataSource={showDataFiscal?.invoices_outflow}
              style={{ margin: '1.4em 0 0 0' }}
              scroll={{ x: 3000 }}
            >
              {/* Fecha de la consulta de los datos */}
              <Column
                title="Fecha de la consulta de los datos"
                key="created_at"
                dataIndex="created_at"
                render={(created_at) => <>{created_at.split('T')[0] || '-'}</>}
              ></Column>
              {/* Fecha de la factura */}
              <Column
                title="Fecha de la factura"
                key="invoice_date"
                dataIndex="invoice_date"
                render={(invoice_date) => <>{invoice_date || '-'}</>}
              ></Column>
              {/* Estado */}
              <Column
                title="Estado"
                key="status"
                dataIndex="status"
                render={(status) => <>{status || '-'}</>}
              ></Column>
              {/* Tipo de factura */}
              <Column
                title="Tipo de factura"
                key="invoice_type"
                dataIndex="invoice_type"
                render={(invoice_type) => <>{invoice_type || '-'}</>}
              ></Column>
              {/* Detalles de la factura */}
              <Column
                title="Detalles de la factura"
                key="invoice_details"
                dataIndex="invoice_details"
                render={(invoice_details) => (
                  <>
                    {invoice_details.map((data, key) => (
                      <ul key={key}>
                        <li>Descripción: {data?.description}</li>
                        <ul>
                          <li>Importe de impuestos: {data?.tax_amount}</li>
                          <li>Importe total: {data?.total_amount}</li>
                          <li>Importe unitario: {data?.unit_amount}</li>
                          <li>Cantidad: {data?.quantity}</li>
                          <li>
                            Código de unidad:{' '}
                            {data?.unit_code === '10'
                              ? 'Grupos'
                              : data?.unit_code === '11'
                              ? 'Equipos'
                              : data?.unit_code === 'A9'
                              ? 'Tarifa'
                              : data?.unit_code === 'AB'
                              ? 'Paquete a granel'
                              : data?.unit_code === 'ACT'
                              ? 'Actividad'
                              : data?.unit_code === 'AS'
                              ? 'Variedad'
                              : data?.unit_code === 'BB'
                              ? 'Caja base'
                              : data?.unit_code === 'DAY'
                              ? 'Día'
                              : data?.unit_code === 'DPC'
                              ? 'Docenas de piezas'
                              : data?.unit_code === 'E48'
                              ? 'Unidad de servicio'
                              : data?.unit_code === 'E51'
                              ? 'Trabajo'
                              : data?.unit_code === 'E54'
                              ? 'Viaje'
                              : data?.unit_code === 'EA'
                              ? 'Elemento'
                              : data?.unit_code === 'GRM'
                              ? 'Gramo'
                              : data?.unit_code === 'H87'
                              ? 'Parte'
                              : data?.unit_code === 'HUR'
                              ? 'Tiempo'
                              : data?.unit_code === 'KGM'
                              ? 'Kilogramo'
                              : data?.unit_code === 'KT'
                              ? 'Kit'
                              : data?.unit_code === 'LTR'
                              ? 'Litro'
                              : data?.unit_code === 'MGM'
                              ? 'Miligramo'
                              : data?.unit_code === 'MLT'
                              ? 'Mililitro'
                              : data?.unit_code === 'MON'
                              ? 'Mes'
                              : data?.unit_code === 'MKT'
                              ? 'Metro cuadrado'
                              : data?.unit_code === 'MTR'
                              ? 'Metro'
                              : data?.unit_code === 'PR'
                              ? 'Par'
                              : data?.unit_code === 'SET'
                              ? 'Conjunto'
                              : data?.unit_code === 'XBX'
                              ? 'Caja'
                              : data?.unit_code === 'XKI'
                              ? 'Kit (conjunto de piezas)'
                              : data?.unit_code === 'XLT'
                              ? 'Lote'
                              : data?.unit_code === 'XPK'
                              ? 'Paquete'
                              : data?.unit_code === 'xun'
                              ? 'Unidad'
                              : '-'}
                          </li>
                          <li>
                            Descripción del producto: {data?.unit_description}
                          </li>
                        </ul>
                      </ul>
                    ))}
                  </>
                )}
              ></Column>
              {/* Finalidad */}
              <Column
                title="Finalidad"
                key="usage"
                dataIndex="usage"
                render={(usage) => (
                  <>
                    {usage === 'D01'
                      ? 'Gastos médicos, odontológicos y hospitalarios'
                      : usage === 'D02'
                      ? 'Gastos médicos por discapacidad o invalidez'
                      : usage === 'D03'
                      ? 'Gastos funerarios'
                      : usage === 'D04'
                      ? 'Donaciones'
                      : usage === 'D05'
                      ? 'Intereses reales pagados por préstamos hipotecarios'
                      : usage === 'D06'
                      ? 'Aportaciones voluntarias al SAR'
                      : usage === 'D07'
                      ? 'Seguro de gastos médicos'
                      : usage === 'D08'
                      ? 'Gastos de transporte escolar'
                      : usage === 'D09'
                      ? 'Depósitos en cuentas de ahorro, primas basadas en planes de pensiones'
                      : usage === 'D10'
                      ? 'Pagos por servicios educativos'
                      : usage === 'G01'
                      ? 'Adquisición de mercancías'
                      : usage === 'G02'
                      ? 'Reembolso, descuentos o bonificaciones'
                      : usage === 'G03'
                      ? 'Gastos generales'
                      : usage === 'I01'
                      ? 'Edificios'
                      : usage === 'I02'
                      ? 'Mobiliario y material de oficina'
                      : usage === 'I03'
                      ? 'Material de transporte'
                      : usage === 'I04'
                      ? 'Equipos y accesorios informáticos'
                      : usage === 'I05'
                      ? 'Matrices, moldes, troqueles y herramientas'
                      : usage === 'I06'
                      ? 'Comunicaciones telefónicas'
                      : usage === 'I07'
                      ? 'Comunicaciones por satélite'
                      : usage === 'I08'
                      ? 'Otras máquinas y equipos'
                      : usage === 'P01'
                      ? 'A definir'
                      : '-'}
                  </>
                )}
              ></Column>
              {/* Nombre del remitente */}
              <Column
                title="Nombre del remitente"
                key="sender_name"
                dataIndex="sender_name"
                render={(sender_name) => <>{sender_name || '-'}</>}
              ></Column>
              {/* Nombre del destinatario */}
              <Column
                title="Nombre del destinatario"
                key="receiver_name"
                dataIndex="receiver_name"
                render={(receiver_name) => <>{receiver_name || '-'}</>}
              ></Column>
              {/* Código postal del lugar de emisión */}
              <Column
                title="Código postal del lugar de emisión"
                key="place_of_issue"
                dataIndex="place_of_issue"
                render={(place_of_issue) => <>{place_of_issue || '-'}</>}
              ></Column>
              {/* Importe de impuestos */}
              <Column
                title="Importe de impuestos"
                key="tax_amount"
                dataIndex="tax_amount"
                render={(tax_amount) => <>{tax_amount || 0}</>}
              ></Column>
              {/* Importe total */}
              <Column
                title="Importe total"
                key="total_amount"
                dataIndex="total_amount"
                render={(total_amount) => <>{total_amount || 0}</>}
              ></Column>
              {/* Métodos de pago */}
              <Column
                title="Métodos de pago"
                key="payment_method"
                dataIndex="payment_method"
                render={(payment_method) => (
                  <>
                    {payment_method === 'PUE'
                      ? 'Pago único'
                      : payment_method === 'PPD'
                      ? 'Pagos parciales'
                      : payment_method === 'PIP'
                      ? 'Pagos iniciales o fraccionados'
                      : '-'}
                  </>
                )}
              ></Column>
              {/* Tipos de pagos */}
              <Column
                title="Tipos de pagos"
                key="payment_type"
                dataIndex="payment_type"
                render={(payment_type) => (
                  <>
                    {payment_type === '01'
                      ? 'Caja'
                      : payment_type === '02'
                      ? 'Cheque nómina'
                      : payment_type === '03'
                      ? 'Transferencia electrónica'
                      : payment_type === '04'
                      ? 'Tarjeta de crédito'
                      : payment_type === '05'
                      ? 'Monedero electrónico'
                      : payment_type === '06'
                      ? 'Dinero digital'
                      : payment_type === '08'
                      ? 'Vales de comida'
                      : payment_type === '12'
                      ? 'Liquidación'
                      : payment_type === '13'
                      ? 'Pago por subrogación'
                      : payment_type === '14'
                      ? 'Pago por consignación'
                      : payment_type === '15'
                      ? 'Condonación'
                      : payment_type === '17'
                      ? 'Indemnización'
                      : payment_type === '23'
                      ? 'Novación'
                      : payment_type === '24'
                      ? 'Confusión'
                      : payment_type === '25'
                      ? 'Remesa de deudas'
                      : payment_type === '26'
                      ? 'Prescripción o caducidad'
                      : payment_type === '27'
                      ? 'A satisfacción del acreedor'
                      : payment_type === '28'
                      ? 'Tarjeta de débito'
                      : payment_type === '29'
                      ? 'Tarjeta de servicio'
                      : payment_type === '99'
                      ? 'A definir'
                      : '-'}
                  </>
                )}
              ></Column>
              {/* Detalles de los pagos */}
              <Column
                title="Detalles de los pagos"
                key="payments"
                dataIndex="payments"
                render={(payments) => (
                  <>
                    {payments.map((data, key) => (
                      <ul key={key}>
                        {!isEmpty(data) ? (
                          <>
                            <li>Fecha: {data?.date.split('T')[0]}</li>
                            <br />
                            <strong>Beneficiario</strong>
                            <li>RFC: {data?.beneficiary_rfc}</li>
                            <li>
                              Número de cuenta:{' '}
                              {data?.beneficiary_account_number}
                            </li>
                            <br />
                            <strong>Quien paga</strong>
                            <li>RFC: {data?.payer_rfc}</li>
                            <li>
                              Número de cuenta: {data?.payer_account_number}
                            </li>
                            <li>Nombre del banco: {data?.payer_bank_name}</li>
                            <br />
                            <strong>Detalles</strong>
                            <li>Monto: {data?.amount}</li>
                            <li>
                              Número de operación: {data?.operation_number}
                            </li>
                            <li>
                              Tipo de pago:{' '}
                              {data?.payment_type === '01'
                                ? 'Caja'
                                : data?.payment_type === '02'
                                ? 'Cheque nómina'
                                : data?.payment_type === '03'
                                ? 'Transferencia electrónica'
                                : data?.payment_type === '04'
                                ? 'Tarjeta de crédito'
                                : data?.payment_type === '05'
                                ? 'Monedero electrónico'
                                : data?.payment_type === '06'
                                ? 'Dinero digital'
                                : data?.payment_type === '08'
                                ? 'Vales de comida'
                                : data?.payment_type === '12'
                                ? 'Liquidación'
                                : data?.payment_type === '13'
                                ? 'Pago por subrogación'
                                : data?.payment_type === '14'
                                ? 'Pago por consignación'
                                : data?.payment_type === '15'
                                ? 'Condonación'
                                : data?.payment_type === '17'
                                ? 'Indemnización'
                                : data?.payment_type === '23'
                                ? 'Novación'
                                : data?.payment_type === '24'
                                ? 'Confusión'
                                : data?.payment_type === '25'
                                ? 'Remesa de deudas'
                                : data?.payment_type === '26'
                                ? 'Prescripción o caducidad'
                                : data?.payment_type === '27'
                                ? 'A satisfacción del acreedor'
                                : data?.payment_type === '28'
                                ? 'Tarjeta de débito'
                                : data?.payment_type === '29'
                                ? 'Tarjeta de servicio'
                                : data?.payment_type === '99'
                                ? 'A definir'
                                : '-'}
                            </li>
                            <li>
                              Moneda:{' '}
                              {data?.currency === 'MXN'
                                ? 'Pesos Mexicanos'
                                : data?.currency === 'COP'
                                ? 'Pesos Colombianos'
                                : data?.currency === 'BRL'
                                ? 'Real Brasileño'
                                : '-'}
                            </li>
                          </>
                        ) : (
                          'No hay datos para mostrar'
                        )}
                      </ul>
                    ))}
                  </>
                )}
              ></Column>
              {/* Certificado de autorización */}
              <Column
                title="Certificado de autorización"
                dataIndex="certification_authority"
                key="certification_authority"
                render={(certification_authority) => (
                  <>{certification_authority || '-'}</>
                )}
              />
              {/* Moneda */}
              <Column
                title="Moneda"
                dataIndex="currency"
                key="currency"
                render={(currency) => (
                  <>
                    {currency === 'MXN'
                      ? 'Pesos Mexicanos'
                      : currency === 'COP'
                      ? 'Pesos Colombianos'
                      : currency === 'BRL'
                      ? 'Real Brasileño'
                      : '-'}
                  </>
                )}
              />
            </Table>
          </Panel>
        ) : null}
      </Collapse>
    </>
  )
}

export default BelvoWithDataFiscal
