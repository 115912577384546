import React from 'react'
import { useTranslation } from 'react-i18next'

const CardRFCWithInfo = ({ dataRfc }) => {
  const { t } = useTranslation()
  const fontStyle = {
    fontWeight: '500',
  }

  const gridLayout = {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    margin: '0.5em 0',
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div style={{ width: '100%' }}>
        <h3>
          <strong>{t('infoRfc')}</strong>
        </h3>

        <div style={gridLayout}>
          <span style={fontStyle}>{t('typeOfPerson')}</span>
          {dataRfc ? (
            <p>{dataRfc.tipoPersona === 'F' ? 'Física' : 'Moral'}</p>
          ) : (
            <p>Sin datos</p>
          )}
        </div>
        <div style={gridLayout}>
          <span style={fontStyle}>{t('menssage')}</span>
          {dataRfc ? <p>{dataRfc.mensaje}</p> : <p>Sin datos</p>}
        </div>
      </div>
    </div>
  )
}

export default CardRFCWithInfo
