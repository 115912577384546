import React from 'react'
import { Divider } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

import ResultLabel from '../../UI/ResultLabel/ResultLabel'
import {
  getRegisteredPlatforms,
  getUnregisteredPlatforms,
  getRegisteredPlatformsWithInfo,
} from '../../../utils/getPlatforms'
import { getAppliedRules } from '../../../utils/getAppliedRules'

const CardPhoneWithInfo = ({ dataPhone }) => {
  //console.log(dataPhone)
  const { t } = useTranslation()
  const account_details = dataPhone?.account_details
  const country = dataPhone?.country
  const applied_rules = dataPhone?.applied_rules
  const label =
    dataPhone?.score >= 21
      ? { backgroundColor: '#FDACAC', color: '#B04242', text: t('decline') }
      : dataPhone?.score >= 11
      ? { backgroundColor: '#FFEFD4', color: '#DDAD5E', text: t('check') }
      : { backgroundColor: '#60BDB2', color: '#FFFFFF', text: 'Ok' }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div style={{ width: '100%' }}>
        <h3>
          <strong>{t('infoPhone')}</strong>
        </h3>
        <p>{dataPhone?.number}</p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            height: '2rem',
          }}
        >
          <h2 style={{ textAlign: 'center' }}>{dataPhone.score}/100</h2>
          <ResultLabel
            text={label?.text}
            backgroundColor={label?.backgroundColor}
            color={label?.color}
          />
        </div>
      </div>
      <Divider clearing />
      <div style={{ width: '100%' }}>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td style={{ width: '50%' }}>{t('country')}</td>
              <td style={{ width: '50%' }}>
                {country} <i className={country?.toLowerCase() + ' flag'} />
              </td>
            </tr>
            <tr>
              <td style={{ width: '50%' }}>{t('valid')}</td>
              <td style={{ width: '50%' }}>
                {dataPhone?.valid ? t('yes') : 'No'}
              </td>
            </tr>
            <tr>
              <td style={{ width: '50%' }}>{t('carrier')}</td>
              <td style={{ width: '50%' }}>{dataPhone?.carrier}</td>
            </tr>
            <tr>
              <td style={{ width: '50%' }}>{t('type')}</td>
              <td style={{ width: '50%' }}>{dataPhone?.type}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Divider clearing />
      <div style={{ width: '100%', height: 'auto' }}>
        <h3>
          <strong>{t('registeredPlatforms')}</strong>
        </h3>
        <div>{getRegisteredPlatformsWithInfo(account_details)}</div>
        <div
          style={{
            display: 'grid',
            gridAutoFlow: 'dense',
            gridTemplateColumns: 'repeat(auto-fill, minmax(14rem, 1fr))',
          }}
        >
          {getRegisteredPlatforms(account_details)}
        </div>
      </div>
      <Divider clearing />
      <div style={{ width: '100%', height: 'auto' }}>
        <h3>
          <strong>{t('unregisteredPlatforms')}</strong>
        </h3>
        <div
          style={{
            display: 'grid',
            gridAutoFlow: 'dense',
            gridTemplateColumns: 'repeat(auto-fill, minmax(14rem, 1fr))',
          }}
        >
          {getUnregisteredPlatforms(account_details)}
        </div>
      </div>
      <Divider clearing />
      <div style={{ width: '100%', height: 'auto' }}>
        <h3>
          <strong>{t('applied_rules')}</strong>
        </h3>
        {getAppliedRules(applied_rules)}
      </div>
    </div>
  )
}

export default CardPhoneWithInfo
