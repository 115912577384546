import { useAuth0 } from '../react-auth0-spa'

const useToken = () => {
  const { getTokenSilently, getTokenWithPopup } = useAuth0()
  const getToken = async ({ audience, scope }) => {
    try {
      return await getTokenSilently({ audience, scope })
    } catch (error) {
      try {
        return await getTokenWithPopup({ audience, scope })
      } catch (error) {
        return null
      }
    }
  }

  return getToken
}

export default useToken
