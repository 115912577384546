
import React, { useState } from 'react'
import SignProcessContext from './SignProcess.context'

/**
 * @typedef {Object} DocumentsWithError
 * @property {string} documentId
 * @property {string[]} reason
 */

/**
 * @typedef {Object} DocumentInProgress
 * @property {string} documentID
 * @property {string} documentName
 * @property {string} emailMessage
 * @property {string} nomMessage
 * @property {string} signMessage
 * @property {boolean} okEmail
 * @property {boolean} okSign
 * @property {boolean} okNom
 * @property {boolean} overallStatus
 */

const SignProcessProvider = ({ children }) => {
  const [isActiveModal, setIsActiveModal] = useState(false);

  const [documentIDs, setDocumentIDs] = useState([]);

  const [documentsWithError, setDocumentsWithError] = useState([]);

  const openModal = () => setIsActiveModal(true);
  const closeModal = () => {
    setIsActiveModal(false);
    setDocumentsWithError([]);
  }

  const acceptProgress = async () => {
    setIsActiveModal(false);
    setDocumentsWithError([]);
  }

  /**
   * @description If there is an error in multiple-sign-docs process, the error is stored in the state
   * @param {DocumentsWithError[]} wrongDocument
   */
  const setWrongDocuments = (wrongDocument) => {
    setDocumentsWithError(wrongDocument);
  }

  /**
   *
   * @param {string[]} documents
   */
  const setDocumentsToSign = (documents) => {
    setDocumentIDs(documents);
  }

  return (
    <SignProcessContext.Provider value={{
      isActiveModal,
      openModal,
      closeModal,
      documentsWithError,
      setWrongDocuments,
      setDocumentsToSign,
      acceptProgress,
      documentIDs
    }}>
      {children}
    </SignProcessContext.Provider>
  )
}

export default SignProcessProvider
