import React, { useState } from 'react'
import { Image, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import './FaceFraudList.css'
import { labels, categories } from '../../../../fixtures/panelExperts.fixture'
import iconAwesomeCheck from '../../../../assets/icons/awesome-check.svg'
import iconAwesomeFail from '../../../../assets/icons/awesome-fail.svg'
import { API_DASHBOARD_URL } from '../../../../const'
import useToken from '../../../../utils/useToken'
import CarouselFraudFace from '../../../UI/CarouselFraudFace/CarouselFraudFace'

const FaceFraudList = ({ uuid, type, livePortrait, attachments, ...props }) => {
  const getToken = useToken()
  const { confirm } = Modal
  const [isClicked, setIsClicked] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const [output, setOutput] = useState(null)
  const [isOverwriting, setIsOverwriting] = useState(false)
  const dataFaceFraudList = props.response

  const onCancelHandler = () => {
    setIsEdit(false)
    setIsClicked(null)
  }

  const onEditHandler = (key) => {
    setIsClicked(key)
    setIsEdit(true)
  }

  const onChangeInputHandler = (e) => {
    const newOutput = e.target.value
    setOutput(Number(newOutput))
    if (newOutput.match(/[0-9]/g) && Number(newOutput) <= 100) {
      setIsOverwriting(true)
    } else {
      setIsOverwriting(false)
    }
  }

  const showModalConfirm = (value) => {
    confirm({
      title: '¿Estás seguro?',
      icon: <ExclamationCircleOutlined />,
      content: 'Esta acción es irreversible.',
      cancelText: 'No',
      okText: 'Si',
      onOk() {
        onChangeFaceFraudListValue(value)
      },
      onCancel() {},
    })
  }

  const showModalInfo = (status) => {
    if (status) {
      Modal.success({
        content: `Se ha modificado el valor correctamente.`,
        onOk() {
          window.location.reload()
        },
      })
    } else {
      Modal.error({
        title: '¡Oh, no! Algo salió mal',
        content: 'Por favor, vuelve a intentarlo.',
      })
    }
  }

  const onChangeFaceFraudListValue = async (key) => {
    try {
      const token = await getToken({
        audience: 'veridocid-dashboard',
      })
      dataFaceFraudList[key].output = output
      const response = await fetch(
        `${API_DASHBOARD_URL}/experts/records/updateDocumentVerification`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            uuid,
            output,
            category: categories.fraudFaceList,
            key,
            type,
          }),
        }
      )
      if (response.ok) {
        showModalInfo(true)
        onCancelHandler()
      } else {
        const error = await response.json()
        console.error(error)
        showModalInfo(false)
      }
    } catch (error) {
      showModalInfo(false)
      console.error(error)
    }
    setIsOverwriting(false)
  }

  const rows = dataFaceFraudList?.map((resp, index) => {
    const value =
      isEdit && isClicked === index ? (
        <input
          defaultValue={resp?.output}
          style={{ width: '3rem' }}
          minLength="1"
          maxLength="6"
          onChange={(event) => onChangeInputHandler(event)}
        />
      ) : (
        <span
          className="FaceFraudListOutput"
          onClick={() => onEditHandler(index)}
        >
          {resp?.output}
        </span>
      )

    const rowOutput = resp?.result ? (
      <td className="FaceFraudListRows">{resp?.output}</td>
    ) : (
      <td className="FaceFraudListRows">
        {value}
        {isClicked === index ? (
          <div className="EditButtons">
            <button className="CancelButton" onClick={onCancelHandler}>
              {labels.cancel}
            </button>
            <button
              className="OverwriteButton"
              disabled={!isOverwriting}
              onClick={() => showModalConfirm(isClicked)}
            >
              {labels.overwrite}
            </button>
          </div>
        ) : null}
      </td>
    )

    const showModalImages = () => {
      Modal.info({
        content: <CarouselFraudFace attachments={attachments} />,
        title: 'Imagenes encontradas de la lista de fraude',
      })
    }

    return (
      <tr key={index}>
        <td className="FaceFraudListRows">{resp?.name}</td>
        <td className="FaceFraudListRows">
          {livePortrait && livePortrait !== null ? (
            <Image
              style={{
                maxWidth: '8rem',
                maxHeight: '10rem',
                margin: '0 1rem',
              }}
              src={`${livePortrait?.data}`}
              alt="portrait"
            />
          ) : (
            <p>Sin imagen</p>
          )}
          <button
            className="ButtonImages"
            onClick={showModalImages}
            disabled={!attachments}
          >
            Ver imagenes
          </button>
        </td>
        {rowOutput}
        <td className="FaceFraudListRows">
          <img
            src={resp?.result ? iconAwesomeCheck : iconAwesomeFail}
            alt="Result"
            style={{ width: '0.9rem' }}
          />
        </td>
      </tr>
    )
  })

  return (
    <div className="FaceFraudList">
      <table className="TableFaceFraudList">
        <thead>
          <tr>
            <th className="ThFaceFraudList" style={{ width: '25%' }}>
              <b>{labels.name || '-'}</b>
            </th>
            <th className="ThFaceFraudList" style={{ width: '40%' }}>
              <b>{labels.input || '-'}</b>
            </th>
            <th className="ThFaceFraudList" style={{ width: '20%' }}>
              <b>{labels.output || '-'}</b>
            </th>
            <th className="ThFaceFraudList" style={{ width: '15%' }}>
              <b>{labels.result || '-'}</b>
            </th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  )
}

export default FaceFraudList
