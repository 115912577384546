import React, { Fragment } from 'react'
import { Table } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

// Asstests, Shared
import { filterBySource, filterCheckResult } from '../../shared/filters'
import iconAwesomeCheck from '../../assets/icons/awesome-check.svg'
import iconAwesomeFail from '../../assets/icons/awesome-fail.svg'

const DocumentImageQuality = ({ data, src, title }) => {
  const { t } = useTranslation()
  const imageQuality = filterBySource(data?.documentImageQuality, src)
  const isFailed = filterCheckResult(imageQuality, 'ERROR')

  const rows = imageQuality?.map((res) => (
    <Table.Row key={Math.random()}>
      <Table.Cell textAlign={'left'}>{res?.name}</Table.Cell>
      <Table.Cell textAlign={'center'}>
        <img
          src={res?.result === 'OK' ? iconAwesomeCheck : iconAwesomeFail}
          alt="Result"
          style={{ width: '1.5rem' }}
        />
      </Table.Cell>
    </Table.Row>
  ))

  return imageQuality?.length >= 1 ? (
    <Fragment>
      <h3 className="ui header" style={{ marginTop: '1rem' }}>
        {title}
      </h3>
      <Table color={isFailed ? 'red' : 'green'}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign={'left'}>
              <strong>{t('name')}</strong>
            </Table.HeaderCell>
            <Table.HeaderCell textAlign={'center'}>
              <strong>{t('result')}</strong>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{rows}</Table.Body>
      </Table>
    </Fragment>
  ) : null
}

export default DocumentImageQuality
