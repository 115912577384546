import React, { useEffect, useState } from 'react'

// Components and styles
import { LoadingOutlined } from '@ant-design/icons'
import { Grid } from '@material-ui/core'
import { Skeleton, Spin, Switch, Typography } from 'antd'
import '../../styles/ContractStyles.css'
import PDFClient from './PDFClient'

// Utils
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import {
  getCountParticipantsAndSignatories,
  getDocumentBase64Sign,
  getDocumentDataSign,
} from '../../utils/getDataCustomize'
import { getDate, getTime } from '../../utils/getDate'
import useToken from '../../utils/useToken'
const ResultContract = () => {
  const getToken = useToken()
  const history = useHistory()
  const { t } = useTranslation()
  const { Paragraph } = Typography
  const { uuid, id, documentID } = useParams()
  const [foundDocumentBase64, setFoundDocumentBase64] = useState(null)
  const [foundDocumentData, setFoundDocumentData] = useState(null)
  const [counter, setCounter] = useState(null)
  const [loadingData, setLoadingData] = useState(false)
  const [stateCollapseData, setStateCollapseData] = useState(false)
  const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />

  useEffect(() => {
    const getDocumentBase64 = async () => {
      setLoadingData(true)
      if (uuid) {
        try {
          const token = await getToken({
            audience: 'veridocid-dashboard',
          })
          const base64 = await getDocumentBase64Sign(token, documentID, uuid)

          setFoundDocumentBase64(base64)
          setLoadingData(false)
        } catch (error) {
          console.error('error', error)
          setLoadingData(false)
        }
      }
    }

    getDocumentBase64()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, documentID])

  useEffect(() => {
    const getDocumentDataClient = async () => {
      if (uuid) {
        try {
          const token = await getToken({
            audience: 'veridocid-dashboard',
          })
          const tokenVeridocid = await getToken({
            audience: 'veridocid',
          })

          // Signer data
          const data = await getDocumentDataSign(token, documentID, uuid)
          setFoundDocumentData(data)

          // Counter sign data
          const resultCounter = await getCountParticipantsAndSignatories(
            tokenVeridocid,
            documentID
          )
          if (resultCounter !== null) {
            setCounter(resultCounter)
          }
        } catch (error) {
          console.error('error', error)
        }
      }
    }

    getDocumentDataClient()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, documentID])

  useEffect(() => {
    const getCounter = async () => {
      // Counter sign data
      if (documentID) {
        const tokenVeridocid = await getToken({
          audience: 'veridocid',
        })
        const resultCounter = await getCountParticipantsAndSignatories(
          tokenVeridocid,
          documentID
        )
        if (resultCounter !== null) {
          setCounter(resultCounter)
        }
      }
    }

    getCounter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showDataContract = () => {
    setStateCollapseData(!stateCollapseData)
  }

  const returnVerify = () => history.push(`/resultados/${uuid}`)

  const returnContracts = () => history.push(`/contratos`)

  return (
    <Grid container className="main_container_contract_client">
      <div className="content_info_contract">
        {/* Info client */}
        <div className="content_info_contract_part1">
          <div className="client_doc">
            <h1
              className="ui header"
              style={{
                display: 'inline',
                color: 'rgb(68, 68, 68)',
                fontSize: 'clamp(1.7em, 1.75em, 1.8em)',
                marginBottom: '0',
              }}
            >
              {foundDocumentData ? foundDocumentData?.documentName : 'Contrato'}
            </h1>
            <p style={{ marginTop: '1rem', fontWeight: '700' }}>
              ID del cliente:{' '}
              <span
                style={{
                  backgroundColor: '#e5e5e5',
                  color: 'rgb(80, 82, 145)',
                  padding: '0.3rem 0.7rem',
                  borderRadius: '3px',
                }}
              >
                {id}
              </span>
            </p>
          </div>
          <button
            disabled={loadingData}
            type="button"
            onClick={
              !foundDocumentData?.fromVerification
                ? returnContracts
                : returnVerify
            }
            className="return_verification"
          >
            Regresar
          </button>
        </div>

        {/* Collapse */}
        <span style={{ margin: '1.8rem 0 0.5rem 0' }}>
          {stateCollapseData
            ? `Ver datos detallados`
            : `Ocultar datos detallados`}
        </span>
        <div
          className="btnSwitch"
          style={{
            margin: '0 0 0.9rem 0',
          }}
        >
          <div>
            <Switch
              disabled={loadingData ? true : false}
              onClick={() => showDataContract()}
            />
          </div>
        </div>
        {/* Document */}
        <div
          className={`collapse-content ${
            stateCollapseData ? 'collapsed' : 'expanded'
          } content_info_contract_part2`}
          aria-expanded={stateCollapseData}
        >
          <Skeleton loading={loadingData}>
            {/* Configuraciones */}
            <div className="content_data_client_contract">
              {/* Configuraciones */}
              <h3>{t('configurations')}</h3>
              <p style={{ fontWeight: '400', margin: '0' }}>
                <strong>{t('country')}: </strong>
                {foundDocumentData?.country || '-'}
              </p>
              <p style={{ fontWeight: '400', margin: '0' }}>
                <strong>{t('geolocation')}: </strong>
                {foundDocumentData?.geo ? t('yes') : 'no'}
              </p>
              {/* Fecha */}
              <h3 style={{ marginTop: '20px' }}>
                {t('contract_data__date_create')}
              </h3>
              <div
                key={id}
                style={{
                  color: 'gray',
                  marginBottom: '1rem',
                  fontWeight: '400',
                  fontSize: 'clamp(0.8rem, 0.9rem, 0.95rem)',
                }}
              >
                <p style={{ fontWeight: '400', margin: '0' }}>
                  <strong>{t('date')}: </strong>
                  {foundDocumentData?.createdAt
                    ? getDate(foundDocumentData?.createdAt)
                    : '-'}
                </p>
                <p style={{ fontWeight: '400', margin: '0' }}>
                  <span>
                    <strong>{t('hour')}: </strong>
                    {foundDocumentData?.createdAt
                      ? getTime(foundDocumentData?.createdAt)
                      : '-'}
                  </span>
                </p>
              </div>
            </div>
            {/* Firmantes */}
            <div
              className="content_data_client_contract"
              style={{ marginRight: '-50px' }}
            >
              <h3>{t('contract_data__signatories')}</h3>
              {foundDocumentData?.participants &&
              foundDocumentData?.participants.length !== 0 ? (
                foundDocumentData?.participants.map((signatories, index) => (
                  <div
                    key={index}
                    style={{
                      color: 'gray',
                      marginBottom: '1rem',
                      fontWeight: '400',
                      fontSize: 'clamp(0.8rem, 0.9rem, 0.95rem)',
                    }}
                  >
                    <strong>{signatories?.name || 'Firmante'}</strong>
                    {signatories?.url && signatories?.url !== 'undefined' ? (
                      <Paragraph
                        copyable={{
                          text: `${signatories?.url}`,
                          tooltips: ['Copie la url del firmante', '¡Copiado!'],
                        }}
                        style={{ display: 'flex', margin: '0' }}
                      >
                        <p style={{ margin: '0' }}>
                          {signatories?.email || '-'}
                        </p>
                      </Paragraph>
                    ) : (
                      <p style={{ margin: '0' }}>{signatories?.email || '-'}</p>
                    )}

                    <p style={{ margin: '0' }}>
                      <span>
                        {signatories?.status === 'signed'
                          ? `${t('signedStatus')}`
                          : `${t('pendingStatus')}` || '-'}
                      </span>
                      {' | '}
                      <span>
                        {signatories?.status === 'signed' &&
                        signatories?.sign_date
                          ? signatories?.sign_date
                          : '-'}
                      </span>
                    </p>
                  </div>
                ))
              ) : (
                <p>{t('no_signatories')}</p>
              )}
            </div>

            {/* Observadores */}
            <div className="content_data_client_contract">
              <h3>{t('contract_data__observers')}</h3>
              {foundDocumentData?.observers &&
              foundDocumentData?.observers.length !== 0 ? (
                foundDocumentData?.observers.map((observers, id) => (
                  <div
                    key={id}
                    style={{
                      color: 'gray',
                      marginBottom: '1rem',
                      fontWeight: '400',
                      fontSize: 'clamp(0.8rem, 0.9rem, 0.95rem)',
                    }}
                  >
                    <strong>{observers?.name || 'Observador'}</strong>
                    <p style={{ margin: '0' }}>{observers?.email || '-'}</p>
                  </div>
                ))
              ) : (
                <p>{t('no_observers')}</p>
              )}
              {/* Contador */}
              <h3>{t('participants')}</h3>
              <p style={{ margin: '0' }}>
                <strong>Total: </strong>
                <span>{counter?.total || '0'}</span>
              </p>
              <p style={{ margin: '0' }}>
                <strong>Firmas: </strong>
                <span>{counter?.signs || '0'}</span>
              </p>
            </div>

            {/* Representante legal y mesa de revisión */}
            {foundDocumentData?.legalRepresentative &&
            foundDocumentData?.legalRepresentative?.user ? (
              <div className="content_data_client_contract">
                <h3>{t('legalRepresentative')}</h3>
                <div
                  style={{
                    color: 'gray',
                    marginBottom: '1rem',
                    fontWeight: '400',
                    fontSize: 'clamp(0.8rem, 0.9rem, 0.95rem)',
                  }}
                >
                  <strong>
                    {foundDocumentData?.legalRepresentative?.user?.name || '-'}
                  </strong>
                  <p style={{ margin: '0' }}>
                    {foundDocumentData?.legalRepresentative?.user?.email || '-'}
                  </p>
                  <p>
                    {foundDocumentData?.participants.filter(
                      (signer) => signer.status !== 'signed'
                    ).length === 0 &&
                    foundDocumentData?.status === 'signed' &&
                    foundDocumentData?.updatedAt ? (
                      <span>{`${t('signedStatus')} | ${getDate(
                        foundDocumentData?.updatedAt
                      )}`}</span>
                    ) : (
                      <span>{t('pendingStatus')}</span>
                    )}
                  </p>
                </div>
                {foundDocumentData?.dateReviewTable &&
                foundDocumentData?.statusReviewTable &&
                foundDocumentData?.dateReviewTable !== '' &&
                foundDocumentData?.statusReviewTable !== '' ? (
                  <>
                    <h3>{t('reviewTable')}</h3>
                    <div
                      style={{
                        color: 'gray',
                        marginBottom: '1rem',
                        fontWeight: '400',
                        fontSize: 'clamp(0.8rem, 0.9rem, 0.95rem)',
                      }}
                    >
                      <strong>
                        {foundDocumentData?.statusReviewTable === 'approved'
                          ? `${t('approvedStatus')}`
                          : `${t('rejectedStatus')}`}
                      </strong>
                      <p style={{ margin: '0' }}>
                        {getDate(foundDocumentData?.dateReviewTable) || '-'}
                      </p>
                    </div>
                  </>
                ) : null}
              </div>
            ) : null}
          </Skeleton>
        </div>
      </div>

      {/* Contract */}
      <div className="content_contract_client">
        {loadingData ? (
          <div className="content_loading_data_contract">
            <Spin
              indicator={antIcon}
              tip="Cargando..."
              style={{ color: 'var(--secondary-color)' }}
            />
          </div>
        ) : (
          <div className="content_data_contract">
            <PDFClient
              fileBase64={
                foundDocumentBase64
                  ? foundDocumentBase64.includes('data:application/pdf;base64,')
                    ? foundDocumentBase64
                    : 'data:application/pdf;base64,' + foundDocumentBase64
                  : null
              }
              idDoc={foundDocumentData ? foundDocumentData?.documentName : null}
            />
          </div>
        )}
      </div>
    </Grid>
  )
}

export default ResultContract
