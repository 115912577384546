import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  Container: {
    width: '100%',
    // height: '1000px',
    marginBottom: '110px',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
  },
  ContainerLoading: {
    height: 'auto',
    width: '100%',
    marginBottom: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  Details: {
    width: '100%',
    // height: '100px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '40px',
  },
  contracts: {
    color: 'rgb(68, 68, 68)',
    fontSize: 'clamp(1.8em, 1.88em, 1.9em)',
  },
  clientId: {
    color: 'var(--primary-color)',
    fontSize: 'clamp(1em, 1.1em, 1.3em)',
  },
  id_accent: {
    backgroundColor: 'rgb(207, 207, 207)',
    padding: '0.1rem 0.5rem 0.2rem',
    borderRadius: '5px',
  },
  content_return: {
    // padding: '1.3rem 0',
    // marginTop: '1rem',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    margin: '10px 0 20px 0',
    '& button': {
      padding: '0.6rem 0.9rem',
      border: 'none',
      outline: 'none',
      color: '#fff',
      backgroundColor: 'var(--secondary-color)',
      borderRadius: '3px',
      '&:hover, &:active, &:focus': {
        backgroundColor: '#ff9c0b',
        boxShadow: '0 0 0 2px rgba(255, 193, 24, 0.2)',
      },
    },
  },
}))
