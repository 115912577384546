import React, { useState } from 'react'
import { getIP, getIPOption2 } from '../../../../../utils/signContract.js'
import { Switch, Tooltip, message as signMessage } from 'antd'
import {
  MyGrid,
  SignMeData,
  DataMe,
  ActionMe,
  GeolocationMessage,
  OnlyMe,
  OnlyMeBox,
  OnlyMeBoxImage,
  OnlyMeBoxContainer,
  OnlyMeBoxDate,
  DDZoneB,
  Rubric,
} from './SignStyle.jsx'
import { VscCheck, VscInfo } from 'react-icons/vsc'
import './SignStyle.css'
import { useTranslation } from 'react-i18next'

export default function Sign(props) {
  const {
    totalPages,
    pageSize,
    user,
    zoom,
    sign,
    signers,
    signPositions,
    setSignPositions,
    setOpen,
    geolocation,
    setGeolocation,
    ip,
    setIP,
    boxSign,
    dd_dragstart,
    whoSign,
  } = props

  const { t } = useTranslation()
  const [error, setError] = useState('')
  const [wait, setWait] = useState(false)
  const [rubric, setRubric] = useState(false)

  const handleShowFormatSign = () => setOpen(true)

  const handleRubric = (e) => {
    setRubric(e.target.checked)

    if (e.target.checked) {
      let internalSigners = []
      let internalPageSizeX = parseInt(pageSize[0].replace('px', '')) - 154
      let internalPageSizeY = parseInt(pageSize[1].replace('px', '')) - 73

      if (whoSign !== 'others')
        internalSigners.push({
          email: user.email,
          name: user.name,
        })
      if (signers.length > 0) internalSigners.push(...signers)

      let newA = []
      let x = 20 * zoom
      let y = 10 * zoom

      for (let page = 0; page < totalPages; page++) {
        x = 20 * zoom
        y = 10 * zoom

        for (let signer of internalSigners) {
          newA.push({
            user: { email: signer.email, name: signer.name },
            coordinates: { x, y },
            page: page + 1,
            zoom,
            rubric: true,
          })

          y = y + 78 * zoom

          if (y > internalPageSizeY) {
            x = internalPageSizeX * zoom
            y = 10 * zoom
          }
        }
      }
      newA.push(...signPositions)
      setSignPositions(newA)
    } else {
      let newA = []
      for (let signPosition of signPositions)
        if (!signPosition.rubric) newA.push(signPosition)
      setSignPositions(newA)
    }
  }

  const handleChange = (event) => {
    const current = event
    if (current) {
      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }

      const success = async (pos) => {
        setWait(true)
        setError('')
        const resGetIP = await getIP()

        if (resGetIP) {
          setIP(resGetIP.IPv4)
          setGeolocation(`${pos.coords.latitude}, ${pos.coords.longitude}`)
          setError('')
          setWait(false)
        } else {
          const resGetIP2 = await getIPOption2()

          if (resGetIP2) {
            setIP(resGetIP2.ip)
            setGeolocation(`${pos.coords.latitude}, ${pos.coords.longitude}`)
            setError('')
            setWait(false)
          } else signMessage.error(`${t('errorGeo')}`)
        }

        setWait(false)
      }

      const error = () => {
        setError('Activa la geolocalizacion para continuar')
      }

      navigator.geolocation.getCurrentPosition(success, error, options)
    } else {
      setGeolocation('')
      setIP('')
      setError('')
    }
  }

  return (
    <>
      {whoSign !== 'others' ? (
        <>
          <MyGrid marginBottom={10}>Agrega tu firma</MyGrid>
          <span className="description_config_client">
            Seleccione el formato para su firma y ubique en donde la quiere
            visualizar.
          </span>
          <SignMeData>
            <DataMe>
              <p>{user.name}</p>
              <p>{user.email}</p>
            </DataMe>
            <ActionMe onClick={handleShowFormatSign}>Firmar</ActionMe>
          </SignMeData>
        </>
      ) : null}
      <MyGrid marginTop={10} marginBottom={10}>
        <Tooltip title="Activa o desactiva tu geolocalización para mostrar desde dónde se creó el contrato">
          <VscInfo
            color="rgb(140, 140, 140)"
            size={16}
            style={{ cursor: 'pointer', marginRight: '10px' }}
          />
        </Tooltip>
        Tu geolocalización
      </MyGrid>
      <MyGrid marginBottom={5} className="config_form__content_input">
        <Switch
          defaultChecked
          style={{
            width: '14px',
            marginRight: '10px',
          }}
          checked={geolocation}
          onChange={handleChange}
        />
        {ip !== '' && geolocation !== '' ? (
          <VscCheck size={24} color={'#388E3C'} strokeWidth={0.3} />
        ) : null}
      </MyGrid>
      {error !== '' || wait ? (
        <GeolocationMessage
          item
          xs={12}
          fc={error ? '#D32F2F' : 'var(--secondary-color)'}
        >
          {error !== '' ? error : wait ? 'Espere un momento...' : null}
        </GeolocationMessage>
      ) : null}
      {sign.signature && whoSign !== 'others' ? (
        <OnlyMe onDragStart={(e) => dd_dragstart(e)}>
          <OnlyMeBox
            //draggable={sign.signature !== '' ? true : false}
            draggable={true}
            id={user.email}
            width={`${136 * zoom}px`}
            height={`${75 * zoom}px`}
          >
            <OnlyMeBoxImage
              src={boxSign}
              alt="caja"
              draggable={false}
              width={`${136 * zoom}px`}
              height={`${75 * zoom}px`}
            />
            <OnlyMeBoxContainer
              width={`${
                sign.signature === ''
                  ? 113 * zoom
                  : sign.option !== 3
                  ? 80 * zoom
                  : 113 * zoom
              }px`}
              height={`${35 * zoom}px`}
              fsize={`${9 * zoom}px`}
              bimage={`url(${sign.signature})`}
              bsize={`${sign.option !== 3 ? 80 * zoom : 113 * zoom}px ${
                35 * zoom
              }px`}
            >
              {sign.signature === '' ? (
                <>
                  <label
                    style={{
                      margin: `${5 * zoom}px auto`,
                    }}
                  >
                    {user.name}
                  </label>
                  <label>{user.email}</label>
                </>
              ) : (
                <OnlyMeBoxDate
                  fsize={`${9 * zoom}px`}
                  left={`${10 * zoom}px`}
                  bottom={`${6 * zoom}px`}
                >
                  {sign.currentDate}
                </OnlyMeBoxDate>
              )}
            </OnlyMeBoxContainer>
          </OnlyMeBox>
        </OnlyMe>
      ) : null}
      {(sign.signature && whoSign !== 'others') || signers.length > 0 ? (
        <Rubric className="signers_Li">
          <div style={{ marginRight: '10px' }}>
            <Tooltip title="Agrega firmas en todas las hojas">
              <VscInfo
                color="rgb(140, 140, 140)"
                size={16}
                style={{
                  cursor: 'pointer',
                  marginRight: '15px',
                  marginTop: '-2px',
                }}
              />
            </Tooltip>
            <span>Rubricar</span>
          </div>
          <input type="checkbox" checked={rubric} onChange={handleRubric} />
        </Rubric>
      ) : null}
      {whoSign !== 'me' ? (
        <>
          <MyGrid marginBottom={5}>Firmante(s)</MyGrid>
          <span className="description_config_client">
            Ubique en donde quiere visualizar la firma de sus firmantes.
          </span>
          <DDZoneB id="ddzoneB" onDragStart={(e) => dd_dragstart(e)}></DDZoneB>
        </>
      ) : null}
    </>
  )
}
