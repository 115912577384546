import React, { lazy, Suspense } from 'react'
import { Grid, Segment, Header } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import '../styles/IdVerifications.css'
import { findLast } from 'lodash'

// Components
import DocumentID from './DocumentID/DocumentID'
import DocumentIDInfo from './DocumentID/DocumentIDInfo'
import DocumentIDExtra from './DocumentID/DocumentIDExtra'
import FormSecurityScanner from './FormSecurityScanner'
import Spinner from './UI/Spinner/Spinner'

// Fixtures, Shared
import { labels, status } from '../fixtures/idVerifications.fixture'
import { filterCategoryCheck } from '../shared/filters'
const Map = lazy(() => import('./UI/Maps/Map'))

const IdVerifications = ({
  anverso,
  reverso,
  documentType,
  front,
  back,
  frontIR,
  backIR,
  frontUV,
  backUV,
  idVerification,
  securityScannerVerification,
  isSecurityScannerAllowed,
  isSecurityScannerVerified,
  id,
  uuid,
  facial,
  portrait,
  openInModal,
  isChangeStatusAllowed,
}) => {
  const { t } = useTranslation()
  const sourceType = idVerification?.data?.sourceType
  const dataIdVerification = idVerification?.data
  const dataSecurityScanner = securityScannerVerification?.data
  const statusSecurityScanner = securityScannerVerification?.status
  const isSecurityScanner = sourceType === 'Scanner'
  const isSecurityFeatures = filterCategoryCheck(
    securityScannerVerification?.data?.documentVerifications,
    'SecurityFeatures'
  )
  const isIdSecurityFeatures = filterCategoryCheck(
    idVerification?.data?.documentVerifications,
    'SecurityFeatures'
  )
  const dataGeolocationSDK = idVerification?.data?.geolocationSDK

  let latitud = findLast(dataGeolocationSDK)?.lat
  let longitud = findLast(dataGeolocationSDK)?.lng

  let addSerie

  switch (documentType) {
    case 'Mexico - Voting Card (2014)':
      addSerie = `${documentType} | Serie E`
      break
    case 'Mexico - Voting Card (2008) Side B':
      addSerie = `${documentType} | Serie C`
      break
    case 'Mexico - Voting Card (2008)':
      addSerie = `${documentType} | Serie C`
      break

    default:
      addSerie = documentType
      break
  }

  return (
    <>
      <Header as="h2" attached="top" className="IdVerifications-header">
        {isSecurityScanner
          ? t('idVerificationLevel2')
          : t('idVerificationLevel1')}
      </Header>
      <Segment attached>
        <Grid columns="equal" stackable>
          <Grid.Column
            textAlign="center"
            stretched
            width={4}
            className="content_custom_img"
          >
            <DocumentID
              front={anverso}
              back={reverso}
              documentType={addSerie}
              data={idVerification}
              facial={facial}
              portrait={portrait}
              isChangeStatusAllowed={isChangeStatusAllowed}
            />
          </Grid.Column>
          <Grid.Column width={12}>
            <DocumentIDInfo data={idVerification} />
          </Grid.Column>
        </Grid>
        <DocumentIDExtra
          idVerification={dataIdVerification}
          isSecurityFeatures={isIdSecurityFeatures}
        />
      </Segment>
      {/* SDK */}
      {!openInModal && dataGeolocationSDK && dataGeolocationSDK.length !== 0 ? (
        <>
          <Header as="h2" attached="top" className="IdVerifications-header">
            {t('geoVerify')}
          </Header>
          <Segment attached>
            <div
              style={{
                margin: '1rem 0 2rem 1rem',
                width: '90%',
                fontSize: 'clamp(0.86rem, 0.98rem, 1rem)',
              }}
            >
              <p style={{ padding: '0 0 8px 0', margin: '0' }}>
                <strong>{t('lat')}:</strong> <span>{latitud}</span>
              </p>
              <p>
                <strong>{t('lng')}:</strong> <span>{longitud}</span>
              </p>
            </div>
            <Suspense fallback={<p>Loading...</p>}>
              <Map lat={latitud} lng={longitud} />
            </Suspense>
          </Segment>
        </>
      ) : null}

      {/* Security Scanner */}
      {!openInModal && isSecurityScannerAllowed ? (
        isSecurityScannerVerified ? (
          statusSecurityScanner === status.identification ||
          statusSecurityScanner === status.checking ||
          !dataSecurityScanner ? (
            <>
              <Header as="h2" attached="top" className="IdVerifications-header">
                {t('idVerificationLevel2')}
              </Header>
              <Segment attached>
                <p style={{ textAlign: 'center' }}>
                  {statusSecurityScanner === status.identification ||
                  statusSecurityScanner === status.checking
                    ? labels.messageTableOfExperts
                    : labels.messageVerifying}
                </p>
                <Spinner />
              </Segment>
            </>
          ) : (
            <>
              <Header as="h2" attached="top" className="IdVerifications-header">
                {t('idVerificationLevel2')}
              </Header>
              <Segment attached>
                <Grid columns="equal" stackable>
                  <Grid.Column textAlign="center" stretched width={4}>
                    <DocumentID
                      front={front}
                      back={back}
                      frontInfrared={frontIR}
                      backInfrared={backIR}
                      frontUltraviolet={frontUV}
                      backUltraviolet={backUV}
                      documentType={addSerie}
                      isSecurityScanner={true}
                    />
                  </Grid.Column>
                  <Grid.Column width={12}>
                    <DocumentIDInfo data={securityScannerVerification} />
                  </Grid.Column>
                </Grid>
                <DocumentIDExtra
                  idVerification={dataSecurityScanner}
                  isSecurityFeatures={isSecurityFeatures}
                />
              </Segment>
            </>
          )
        ) : (
          <>
            <Header as="h2" attached="top" className="IdVerifications-header">
              {t('idVerificationLevel2')}
            </Header>
            <FormSecurityScanner id={id} uuid={uuid} />
          </>
        )
      ) : null}
    </>
  )
}

export default IdVerifications
