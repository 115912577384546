import React from 'react'
import { useTranslation } from 'react-i18next'
import { Divider } from 'semantic-ui-react'
import { getAppliedRules } from '../../../utils/getAppliedRules'

const CardIpWithInfo = ({ dataIp }) => {
  const { t } = useTranslation()
  const applied_rules = dataIp?.applied_rules
  const label =
    dataIp?.score >= 21
      ? { backgroundColor: '#FDACAC', color: '#B04242', text: t('decline') }
      : dataIp?.score >= 11
      ? { backgroundColor: '#FFEFD4', color: '#DDAD5E', text: t('check') }
      : { backgroundColor: '#60BDB2', color: '#FFFFFF', text: t('ok') }

  const open_ports =
    dataIp?.open_ports?.length <= 0 ? 'None' : dataIp?.open_ports

  const fontStyle = {
    fontWeight: '500',
  }

  const gridLayout = {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    margin: '0.5em 0',
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div style={{ width: '100%' }}>
        <h3>
          <strong>{t('infoIp')}</strong>
        </h3>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            marginTop: '15px',
          }}
        >
          <div>
            <p style={{ margin: '0 0 0.5em 0' }}>{dataIp?.ip}</p>
            <h2 style={{ textAlign: 'left' }}>{dataIp?.score}/100</h2>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
            }}
          >
            <span
              style={{
                backgroundColor: `${label?.backgroundColor}`,
                width: '10px',
                height: '10px',
                display: 'block',
                borderRadius: '100%',
                marginRight: '10px',
              }}
            ></span>
            <p>{label?.text}</p>
          </div>
        </div>
      </div>
      <Divider clearing />
      <div style={{ width: '100%' }}>
        <div style={gridLayout}>
          <span style={fontStyle}>{t('location')}</span>
          <p>{`${dataIp?.city} (${dataIp?.state_prov})`}</p>
        </div>
        <div style={gridLayout}>
          <span style={fontStyle}>{t('country')}</span>
          <p>
            {dataIp?.country}{' '}
            <i
              className={dataIp?.country.toLowerCase() + ' flag'}
              style={{ paddingLeft: '5px' }}
            />
          </p>
        </div>
        <div style={gridLayout}>
          <span style={fontStyle}>{t('ipType')}</span>
          <p>{dataIp?.type || '-'}</p>
        </div>
        <div style={gridLayout}>
          <span style={fontStyle}>{t('ipMobile')}</span>
          <p>{dataIp?.mobile ? t('yes') : 'No' || '-'}</p>
        </div>
        <div style={gridLayout}>
          <span style={fontStyle}>{t('internetServiceProvider')}</span>
          <p>{dataIp?.isp_name || '-'}</p>
        </div>
        <div style={gridLayout}>
          <span style={fontStyle}>{t('openPorts')}</span>
          <p>
            {open_ports === 'None'
              ? open_ports
              : open_ports?.map((port) => port).join(', ')}
          </p>
        </div>
      </div>
      <Divider clearing />
      <div style={{ width: '100%' }}>
        <div style={gridLayout}>
          <span style={fontStyle}>TOR</span>
          <p>{dataIp?.tor ? t('yes') : 'No'}</p>
        </div>
        <div style={gridLayout}>
          <span style={fontStyle}>{t('virtualPrivateNetwork')}</span>
          <p>{dataIp?.vpn ? t('yes') : 'No'}</p>
        </div>
        <div style={gridLayout}>
          <span style={fontStyle}>{t('publicProxy')}</span>
          <p>{dataIp?.public_proxy ? t('yes') : 'No'}</p>
        </div>
        <div style={gridLayout}>
          <span style={fontStyle}>{t('webProxy')}</span>
          <p>{dataIp?.web_proxy ? t('yes') : 'No'}</p>
        </div>
      </div>
      <Divider clearing />
      <div style={{ width: '100%' }}>
        <div style={gridLayout}>
          <span style={fontStyle}>{t('spamBlacklist')}</span>
          <p>{dataIp?.spam_number}</p>
        </div>
        {/* Data center no se ve reflejado en los datos JSON */}
        {/* <div style={gridLayout}>
          <span style={fontStyle}>{t('dataCenter')}</span>
          <p>{'No'}</p>
        </div> */}
      </div>
      <Divider clearing />
      <div style={{ width: '100%', height: 'auto' }}>
        <h3>
          <strong>{t('applied_rules')}</strong>
        </h3>
        {getAppliedRules(applied_rules)}
      </div>
    </div>
  )
}

export default CardIpWithInfo
