import React, { useState } from 'react'
import { Sortable, Plugins } from '@shopify/draggable'
import { Grid } from '@mui/material'
import { Switch, Button as AddSigner } from 'antd'

export default function Signers(props) {
  const { signers, setSigners, sortStatus, setSortStatus } = props
  const [sortSigners, setSortSigners] = useState(null)

  const handleAddSigner = () =>
    setSigners([
      ...signers,
      {
        uuid: '',
        name: '',
        email: `Firmante ${signers.length + 1}`,
        biometric: '',
        isEndorse: false,
        endorseText: '',
        signerNumber: signers.length,
      },
    ])

  const handleSortable = (e) => {
    const czone = document.getElementById('orderSigners')
    if (!sortSigners) {
      const sortable = new Sortable(czone, {
        draggable: 'li',
        mirror: {
          constrainDimensions: true,
        },
        plugins: [Plugins.ResizeMirror],
      })

      sortable.on('sortable:sorted', (evt) => {
        console.log(evt)
        try {
          const { newIndex, oldIndex } = evt?.data,
            source = String(evt?.data?.dragEvent?.data?.source?.id).replace(
              '_li',
              ''
            ),
            over = String(evt?.data?.dragEvent?.data?.over?.id).replace(
              '_li',
              ''
            )
          for (const signer of signers) {
            if (over === signer.email) signer.signerNumber = oldIndex
            if (source === signer.email) signer.signerNumber = newIndex
          }
          setSigners(signers)
        } catch (error) {
          console.log(error)
        }
      })
      setSortSigners(sortable)
      setSortStatus(e)
    } else {
      setSortStatus(e)
      sortSigners.destroy()
      setSortSigners(null)
    }
  }

  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div style={{ width: '70%' }}>
          <span
            style={{
              fontSize: 'clamp(.9em,1em,1.1em)',
              color: 'var(--secondary-color)',
            }}
          >
            Firmantes
          </span>
          <p
            className="description_config_client"
            style={{ fontSize: 'clamp(.5rem,.85rem,.9rem)' }}
          >
            Agregue nuevos firmante(s) a su plantilla
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0.7rem 0',
            width: '30%',
          }}
        >
          <AddSigner
            className="new_add_signer"
            size="small"
            style={{
              backgroundColor: '#e9e9e9',
              color: 'rgba(0,0,0,.85)',
              border: 'none',
              outline: 'none',
            }}
            onClick={handleAddSigner}
          >
            Agregar
          </AddSigner>
        </div>
      </div>
      {signers.length > 1 ? (
        <Grid
          item
          xs={12}
          style={{
            height: '50px',
            display: 'flex',
            alignItems: 'center',
          }}
          className="config_form__content_input"
        >
          <Switch
            defaultChecked
            style={{
              width: '14px',
              marginTop: '-0.4em',
              marginRight: '10px',
            }}
            checked={sortStatus}
            onChange={handleSortable}
            disabled
          />
          <label>{sortStatus ? 'Ordenable' : 'No ordenable'}</label>
        </Grid>
      ) : null}
      <ul
        id="orderSigners"
        style={{
          width: '100%',
          padding: '0',
          marginTop: '20px',
        }}
      ></ul>
    </>
  )
}
