import React from 'react'
import { Snack, SnackMessage } from './AlertStyle.jsx'

export default function Alert(props) {
  const { alert, setAlert } = props

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setAlert({
      openAlert: false,
      message: alert.message,
      severity: alert.severity,
    })
  }

  return (
    <Snack
      open={alert.open}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={handleClose}
    >
      <SnackMessage
        severity={alert.severity}
        bgcolor={
          alert.severity === 'error'
            ? '#D32F2F'
            : alert.severity === 'warning'
            ? 'var(--secondary-color)'
            : alert.severity === 'info'
            ? '#0288D1'
            : alert.severity === 'success'
            ? '#388E3C'
            : 'var(--secondary-color)'
        }
      >
        {alert.message}
      </SnackMessage>
    </Snack>
  )
}
