export const API_URL = 'https://veridocid.azure-api.net/api'
export const API_URL_V3 = 'https://veridocid.azure-api.net/api/v3'
// export const API_URL_V3 = 'http://20.125.9.139:8080/api/v3'
export const API_DASHBOARD_URL = '/api'
export const API_RENAPO_URL = 'https://veridocid.azure-api.net/api/gov/curp'
export const API_INE_URL = 'https://veridocid.azure-api.net/api/gov/ine'
export const ENDPOINT_REPORT =
  'https://veridocid.azure-api.net/api/id/v2/report'
export const NMP_FINANCIERA_CLIENTS =
  'CdS5CULl5bNGY4EyNXLWXCeaAjdnhDbF,TaDlnYwJD3CLV6cnLOmUqobPdkFBqR49,gkOhlOFKrxmcowZawGThfeeIAQwDsxff'
export const NMP_CLIENTS =
  'ZcO0euZ9ekObdfCXa1XW4VvqLze4Mi8R,YVNNc03FL9G8Zt58yJxYgqq0VqMXKRfm,PCjGAWQk80Oz9PCwQKHdjfrbQfVFwIwa,iWu9ttlsyzggGEu0WxMz7aIz1CpbgbJS,KVyVQEl9AuyhGt4xmCg0svgm9IUSmyBm,TaDlnYwJD3CLV6cnLOmUqobPdkFBqR49,CdS5CULl5bNGY4EyNXLWXCeaAjdnhDbF,gkOhlOFKrxmcowZawGThfeeIAQwDsxff'
export const IS_CVR = 'meN9MuSIy0mqZQQ71zirrsPcNXjPPsJx'
