import React, { useState } from 'react'
import { Typography, Badge, Switch } from 'antd'
import '../styles/DocumentationStyles.css'

const Documentation = () => {
  //CopyText
  const { Paragraph } = Typography

  const [optionPostman, setOptionPostman] = useState(true)
  const [optionSwagger, setOptionSwagger] = useState(true)
  const [optionNpm, setOptionNpm] = useState(true)

  //Change options
  const optionsDocumentationPostman = (checked) => {
    setOptionPostman(checked)
  }

  const optionsDocumentationSwagger = (checked) => {
    setOptionSwagger(checked)
  }

  const optionsDocumentationNpm = (checked) => {
    setOptionNpm(checked)
  }

  return (
    <>
      <div className="content_doc_postman">
        <Badge.Ribbon color={'rgb(71, 121, 200)'} count={5} text="V 2.0.8">
          <h2 className="title_documentation">Postman</h2>
        </Badge.Ribbon>
        <p className="description_documentation">
          Con la documentación de Postman para la integración y desarrollo de la
          API de VeriDocID, estamos seguros de que podrá integrar fácilmente
          nuestros servicios. Nuestra documentación VeriDocID está disponible
          aquí:
        </p>
        <Switch
          checkedChildren="Abrir"
          unCheckedChildren="Copiar"
          defaultChecked="true"
          onChange={optionsDocumentationPostman}
        />
        <div className="content_link_documentation">
          {optionPostman ? (
            <a
              href="https://bit.ly/3fcprvt"
              alt="Postman"
              target="_blank"
              rel="noopener noreferrer"
              className="open_new_tab"
            >
              Dale click para ver la documentación
            </a>
          ) : (
            <Paragraph copyable>https://bit.ly/3fcprvt</Paragraph>
          )}
        </div>
      </div>
      {/* Swagger */}
      <div className="content_doc_swagger">
        <Badge.Ribbon color={'rgb(71, 121, 200)'} count={5} text="V 2.0.9">
          <h2 className="title_documentation">Swagger</h2>
        </Badge.Ribbon>
        <p className="description_documentation">
          La documentación de Swagger se puede utilizar para la generación
          automática de código de cliente de la API. Nuestra documentación
          VeriDocID está disponible aquí:
        </p>
        <Switch
          checkedChildren="Abrir"
          unCheckedChildren="Copiar"
          defaultChecked="true"
          onChange={optionsDocumentationSwagger}
        />
        <div className="content_link_documentation">
          {optionSwagger ? (
            <a
              href="https://bit.ly/332Bhpv"
              alt="Swagger"
              target="_blank"
              rel="noopener noreferrer"
              className="open_new_tab"
            >
              Dale click para ver la documentación
            </a>
          ) : (
            <Paragraph copyable>https://bit.ly/332Bhpv</Paragraph>
          )}
        </div>
      </div>
      {/* NPM */}
      <div className="content_doc_npm">
        <Badge.Ribbon color={'rgb(71, 121, 200)'} count={5} text="V 1.1.0">
          <h2 className="title_documentation">vdid-sdk-web (npm)</h2>
        </Badge.Ribbon>
        <p className="description_documentation">
          La documentación de vdid-sdk-web, es un paquete de instalación que se
          requiere para la implementación del SDK Web, en cualquier plataforma
          que lo requiera, y mediante esto poder hacer uso del servicio de
          prueba de vida que le ofrece SUMA México. Nuestra documentación para
          el SDK está disponible aquí:
        </p>
        <Switch
          checkedChildren="Abrir"
          unCheckedChildren="Copiar"
          defaultChecked="true"
          onChange={optionsDocumentationNpm}
        />
        <div className="content_link_documentation">
          {optionNpm ? (
            <a
              href="https://bit.ly/3D0POz6"
              alt="NPM-SDK"
              target="_blank"
              rel="noopener noreferrer"
              className="open_new_tab"
            >
              Dale click para ver la documentación
            </a>
          ) : (
            <Paragraph copyable>https://bit.ly/3D0POz6</Paragraph>
          )}
        </div>
      </div>
    </>
  )
}

export default Documentation
