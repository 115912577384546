import React, { useEffect, useState } from 'react'
import { Image, Modal } from 'antd'
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'
import iconCheckFacial from '../../../assets/icons/check-facial.svg'
import iconFailedFacial from '../../../assets/icons/failed-facial.svg'
import { useAuth0 } from '../../../react-auth0-spa'
import useToken from '../../../utils/useToken'
import './CardBiometricsStyles.css'
import { API_URL } from '../../../const'

const CardBiometrics = ({
  dataBiometrics,
  facial,
  portrait,
  data,
  fraudFaceList,
  isGlobalResultChanged,
  isChangeStatusAllowed,
  uuid,
}) => {
  const { t } = useTranslation()
  const { user, isAuthenticated } = useAuth0()
  const { confirm, success, error } = Modal
  const getToken = useToken()
  const [roles, setRoles] = useState([])
  const globalResult = data?.globalResult === 'Ok'
  const result = dataBiometrics[0]?.result
  const output = dataBiometrics[0]?.output
  const isfraudFace = fraudFaceList[0]?.result
  const isfraudFaceScore = fraudFaceList[0]?.output
  const isVideoLiveness = data?.videoLiveness
  const isLivenessResult = typeof data?.resutlLiveness === 'string'
  const livenessScoreOk = data?.scoreLiveness >= 200
  const videoLivenessOK =
    data?.videoLiveness?.videoResult === 'Verificación exitosa'
  const matchId = fraudFaceList[0]?.inputFields?.filter(
    (field) => field.name === 'Match ID'
  )
  const biometricsResult =
    isLivenessResult && isVideoLiveness
      ? livenessScoreOk && result && videoLivenessOK
      : isLivenessResult
      ? livenessScoreOk && result
      : isVideoLiveness
      ? result && videoLivenessOK
      : result
  const canChangeStatus = biometricsResult === globalResult
  const approved = !globalResult
  const rejected = !approved

  useEffect(() => {
    if (isAuthenticated) {
      setRoles(user?.['https://vdid.sumamexico.com/roles'] ?? [])
    }
  }, [isAuthenticated, user])

  const onValidationNip = async (nip, comments) => {
    const token = await getToken({
      audience: 'veridocid-dashboard',
    })
    const response = await fetch(`${API_URL}/id/v3/verifyDashboardNip`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uuid,
        email: user.email,
        nip,
      }),
    })

    if (response.ok) {
      await onChangeStatusHandler(comments)
    } else {
      error({
        title: '¡Oh, no! Algo salió mal',
        icon: <CloseCircleOutlined />,
        content: 'Por favor, vuelve a intentarlo.',
      })
    }
  }

  const sendEmailNip = async (comments) => {
    if (comments === '') {
      error({
        title: 'Error',
        icon: <CloseCircleOutlined />,
        content: 'Necesitas escribir un comentario para poder continuar.',
        onOk() {
          showModalConfirm()
        },
      })
    } else {
      let nip
      const token = await getToken({
        audience: 'veridocid-dashboard',
      })
      const response = await fetch(`${API_URL}/id/v3/sendEmailDashboardNip`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uuid,
          email: user.email,
        }),
      })

      if (response.ok) {
        confirm({
          title: 'Validación de NIP',
          icon: <ExclamationCircleOutlined />,
          content: (
            <>
              <p style={{ margin: '1rem 0 0' }}>
                Escribe el nip que se te ha enviado por correo.
              </p>
              <input
                type="number"
                placeholder="Escribe el nip"
                onChange={(value) => {
                  nip = value?.target?.value
                }}
                style={{ width: '22rem', height: '4rem' }}
              />
            </>
          ),
          okText: 'Continuar',
          onOk() {
            onValidationNip(Number(nip), comments)
          },
        })
      } else {
        error({
          title: '¡Oh, no! Algo salió mal',
          icon: <CloseCircleOutlined />,
          content: 'Por favor, vuelve a intentarlo.',
        })
      }
    }
  }

  const onChangeStatusHandler = async (comments) => {
    const token = await getToken({
      audience: 'veridocid-dashboard',
    })
    const response = await fetch(`${API_URL}/id/v3/updateVerification`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uuid,
        approved,
        rejected,
        name: user.name,
        email: user.email,
        comments,
      }),
    })

    if (response.ok) {
      success({
        title: 'El resultado ha sido cambiado satisfactoriamente.',
        icon: <CheckCircleOutlined />,
        onOk() {
          window.location.reload()
        },
      })
    } else {
      error({
        title: '¡Oh, no! Algo salió mal',
        icon: <CloseCircleOutlined />,
        content: 'Por favor, vuelve a intentarlo.',
      })
    }
  }

  const showModalConfirm = () => {
    let comments = ''

    confirm({
      title: '¿Estás seguro?, Esta acción es irreversible.',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <p style={{ margin: '1rem 0 0' }}>
            Anote la razón por la que desea cambiar el estado.
          </p>
          <textarea
            placeholder="Escribe un comentario"
            onChange={(value) => {
              comments = value?.target?.value
            }}
            style={{ width: '22rem', height: '7rem' }}
          />
        </>
      ),
      cancelText: 'No',
      okText: 'Si',
      onOk() {
        sendEmailNip(comments)
      },
      onCancel() {},
    })
  }

  return (
    <>
      <Grid container style={{ paddingTop: '1rem' }}>
        <Grid item xs={12} sm={12} lg={6} xl={6}>
          <p style={{ fontWeight: '600' }}>Portrait</p>
          <div className="ctnt_image__portrait">
            <img src={portrait} alt="portrait" />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} lg={6} xl={6} justifyContent="center">
          <p style={{ fontWeight: '600' }}>Selfie</p>
          <div className="ctnt_image__selfie">
            <Image src={facial} alt="facial" />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} lg={6} xl={6}>
          <p
            style={{
              fontWeight: '600',
              fontSize: '11px',
              marginTop: '20px',
              marginBottom: '0px',
            }}
          >
            {t('facialBiometry')}
          </p>
          <div className="ctnt_image_result">
            <img
              src={result ? iconCheckFacial : iconFailedFacial}
              alt="icon"
              style={{ width: '2rem', margin: '0px', marginBottom: '10px' }}
            />

            <p style={{ fontSize: '0.9rem', color: '#595858' }}>
              {output !== null
                ? typeof output === 'string'
                  ? `${output}`
                  : `${output.toFixed(2)} %`
                : 0}
            </p>
          </div>
        </Grid>
        {isLivenessResult ? (
          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <p
              style={{
                fontWeight: '600',
                fontSize: '11px',
                marginTop: '20px',
                marginBottom: '0px',
              }}
            >
              {t('liveness_result')}
            </p>
            <div className="ctnt_image_result">
              <img
                src={livenessScoreOk ? iconCheckFacial : iconFailedFacial}
                alt="icon"
                style={{ width: '2rem', margin: '0px', marginBottom: '10px' }}
              />

              <p style={{ fontSize: '0.9rem', color: '#595858' }}>
                {data?.resutlLiveness === 'Prueba de vida exitosa'
                  ? t('liveness_ds_success')
                  : t('liveness_ds_failed')}
              </p>
            </div>
          </Grid>
        ) : null}
        {fraudFaceList?.length >= 1 ? (
          isfraudFace ? (
            <Grid
              item
              style={{ paddingBottom: isLivenessResult ? '1rem' : 0 }}
              xs={isLivenessResult ? 24 : 12}
              sm={isLivenessResult ? 24 : 12}
              lg={isLivenessResult ? 24 : 6}
              xl={isLivenessResult ? 24 : 6}
            >
              <p
                style={{
                  fontWeight: '600',
                  fontSize: isLivenessResult ? undefined : '11px',
                  marginTop: isLivenessResult ? undefined : '20px',
                  marginBottom: isLivenessResult ? undefined : '20px',
                }}
              >
                {t('fraudFaceList')}
              </p>
              <img
                src={iconFailedFacial}
                alt="icon"
                style={{ width: '2rem', margin: '0px', marginBottom: '10px' }}
              />
              <p style={{ fontSize: '0.9rem', color: '#595858' }}>
                {t('positive')} {isfraudFaceScore.toFixed(2)}
              </p>
              {matchId[0]?.value ? (
                <p style={{ fontSize: '0.9rem', color: '#595858' }}>
                  Match ID: {matchId[0].value}
                </p>
              ) : null}
            </Grid>
          ) : (
            <Grid
              item
              style={{ paddingBottom: isLivenessResult ? '1rem' : 0 }}
              xs={isLivenessResult ? 24 : 12}
              sm={isLivenessResult ? 24 : 12}
              lg={isLivenessResult ? 24 : 6}
              xl={isLivenessResult ? 24 : 6}
            >
              <p
                style={{
                  fontWeight: '600',
                  fontSize: isLivenessResult ? undefined : '11px',
                  marginTop: isLivenessResult ? undefined : '20px',
                  marginBottom: isLivenessResult ? undefined : '20px',
                }}
              >
                {t('fraudFaceList')}
              </p>
              <img
                src={iconCheckFacial}
                alt="icon"
                style={{ width: '2rem', margin: '0px', marginBottom: '10px' }}
              />
              <p style={{ fontSize: '0.9rem', color: '#595858' }}>
                {t('negative')}
              </p>
            </Grid>
          )
        ) : null}
        {canChangeStatus &&
        isChangeStatusAllowed &&
        !isGlobalResultChanged &&
        roles?.includes('Supervisor') ? (
          <Grid
            item
            style={{ paddingBottom: '1rem' }}
            xs={24}
            sm={24}
            lg={24}
            xl={24}
          >
            <button
              className={approved ? 'ButtonApprove' : 'ButtonReject'}
              onClick={showModalConfirm}
            >
              {approved ? t('approve') : t('reject')}
            </button>
          </Grid>
        ) : null}
      </Grid>
    </>
  )
}

export default CardBiometrics
