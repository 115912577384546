import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import { useStyles } from '../styles/NewContract'
import PDFSignatureV2 from '../components/Signature/PDFSignatureV2'
import { useHistory } from 'react-router-dom'
import { useAuth0 } from '../react-auth0-spa'
import { Tooltip, Popover } from 'antd'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { AiOutlineMore } from 'react-icons/ai'

// Table
import DashboardTemplatev2 from '../components/Signature/Templates/DashboardTemplate/DashboardTemplatev2'

export default function NewContract(props) {
  const {
    params: { id, uuid },
  } = props.match
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const [file, setFile] = useState(false)
  const { user, isAuthenticated } = useAuth0()
  const [fileBase64, setFileBase64] = useState('false')
  const [visiblePopover, setVisiblePopover] = useState(false)
  const [option, setOption] = useState(0)

  const returnVerify = () => {
    history.push(`/resultados/${uuid}`)
  }

  return (
    <Grid container className={classes.Container}>
      <Grid className={classes.content_return}>
        <button type="button" onClick={() => returnVerify()}>
          {`${t('return_home')}`}
        </button>
      </Grid>
      <Grid className={classes.Details}>
        <label className={classes.contracts}>{`${t('contracts')}`}</label>
        <label className={classes.clientId}>
          <Tooltip title={t('more_options')}>
            <Popover
              content={
                <>
                  <div
                    className="options_to_tab"
                    onClick={() => {
                      setVisiblePopover(false)
                      setOption(0)
                    }}
                  >
                    <p>{`${t('uploadContract')}`}</p>
                  </div>
                  <div
                    className="options_to_tab"
                    onClick={() => {
                      setVisiblePopover(false)
                      setOption(1)
                    }}
                  >
                    <p>{`${t('templates')}`}</p>
                  </div>
                </>
              }
              title={t('title_popover')}
              trigger="click"
              placement="bottomRight"
              visible={visiblePopover}
              onVisibleChange={(visible) => setVisiblePopover(visible)}
            >
              {/* More options button */}
              <button
                className="more_options_view"
                style={{ marginRight: '10px' }}
              >
                <AiOutlineMore strokeWidth={1.2} size={28} />
              </button>
            </Popover>
          </Tooltip>
          {`${t('clientId')}`} <span className={classes.id_accent}>{id}</span>
        </label>
      </Grid>

      <Box sx={{ width: '100%', typography: 'body1' }}>
        {option === 0 ? (
          <PDFSignatureV2
            user={user}
            file={file}
            setFile={setFile}
            fileBase64={fileBase64}
            setFileBase64={setFileBase64}
            idClient={id}
            uuid={uuid}
            useAuth0={user}
            isAuthenticated={isAuthenticated}
          />
        ) : (
          <DashboardTemplatev2 showOptions={false} uuidVerification={uuid} />
        )}
      </Box>
    </Grid>
  )
}
