import React, { Fragment, useState } from 'react'
import { Button, Form, Label, Transition } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

import '../ConsultationServices.css'
import CardIpWithInfo from './CardIpWithInfo.js'
import useToken from '../../../utils/useToken'
import { API_URL } from '../../../const/index'
import { useAuth0 } from '../../../react-auth0-spa'
import MaskedInput from 'react-text-mask'

const IP = ({
  id,
  uuid,
  ip,
  ipStatus,
  dataIpFound,
  setIsIpVerified,
  handleChangeInputIp,
  onVerifyIp,
  openInModal,
}) => {
  const [dataIp, setDataIp] = useState(null)
  const [verificando, setVerificando] = useState(false)

  //Control de errores
  const [inputError, setInputError] = useState(false)
  const [inputMsgErr, setInputMsgErr] = useState('')

  //Disabled input
  const [disabledInput, setDisabledInput] = useState(false)

  //Configurations
  const { t } = useTranslation()
  const { user } = useAuth0()
  const getToken = useToken()

  const callToSeonApi = async () => {
    setVerificando(true)
    setDisabledInput(true)
    try {
      const token = await getToken({
        audience: 'veridocid',
      })

      const response = await fetch(`${API_URL}/social/checkIp`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
          uuid,
          ip,
          name: user.name,
          email: user.email,
        }),
      })

      if (response.ok) {
        const reponseJSON = await response.json()

        if (reponseJSON.success) {
          onVerifyIp(reponseJSON)
          setDataIp(reponseJSON)
          setIsIpVerified(true)
          //console.log(reponseJSON?.data)
        } else {
          setIsIpVerified(false)
          setInputError(true)
          setInputMsgErr(reponseJSON?.error?.message)
          //console.log(reponseJSON?.error)
        }
      } else {
        console.log(`La respuesta de la API IP no se encontro`)
      }

      setVerificando(false)
      setDisabledInput(false)
    } catch (error) {
      console.log(`La llamada a la API de IP fallo`)
      setVerificando(false)
      setDisabledInput(false)
    }
  }

  // Call API to verify the IP
  const handleClick = () => {
    if (ip !== '') {
      setInputError(false)
      callToSeonApi()
      //console.log(`${ip}`)
    } else {
      setInputError(true)
      setInputMsgErr(`${t('messageErrorEmptyInputIP')}`)
      setTimeout(() => {
        setInputError(false)
      }, 3000)
    }
  }

  //Configuration to validate the IP pattern
  const config = {
    guide: false,
    mask: (value) => Array(value.length).fill(/[\d.]/),
    pipe: (value) => {
      if (value === '.' || value.endsWith('..')) return false

      const parts = value.split('.')

      if (
        parts.length > 4 ||
        parts.some((part) => part === '00' || part < 0 || part > 255)
      ) {
        return false
      }

      return value
    },
  }

  return (
    <Fragment>
      <div className="ui container">
        {ipStatus ? (
          <CardIpWithInfo
            dataIp={
              dataIpFound?.data ||
              dataIpFound?.result ||
              dataIp?.data ||
              dataIp?.result
            }
          />
        ) : (
          <>
            <Form>
              <Form.Field>
                <p>{t('ip')}</p>
                <div className="ui fluid input">
                  <MaskedInput
                    {...config}
                    value={ip}
                    placeholder="IP"
                    onChange={handleChangeInputIp}
                    disabled={disabledInput}
                  />
                </div>

                <Transition
                  animation="drop"
                  duration={500}
                  visible={inputError}
                >
                  <Label pointing color="red" as="span">
                    {`${inputMsgErr}`}
                  </Label>
                </Transition>
              </Form.Field>
            </Form>
            <br />
            {!openInModal ? (
              <div className="ButtonsCard">
                <Button
                  onClick={handleClick}
                  disabled={verificando}
                  loading={verificando}
                >
                  {t('send')}
                </Button>
              </div>
            ) : null}
          </>
        )}
      </div>
    </Fragment>
  )
}

export default IP
