import { Collapse } from 'antd'
import React, { useEffect, useState } from 'react'
import Message from '../../PDFSignatureNC/ConfigurationsPDF/Message/Message'
import Observers from '../../PDFSignatureNC/ConfigurationsPDF/Observers/Observers'
import SignatureSettings from '../../SettingsSign/SettingsSign'
import { Right, RightTitle } from './CreateTemplateStyle'
import LegalRepresentant from './LegalRepresentant/LegalRepresentant'
import MainConfigurationsTemplate from './MainConfigurationsTemplate/MainConfigurationsTemplate'
import Save from './Save/Save'
import Signers from './Signers/Signers'
import Signs from './Signs/Signs'
import TermsAndConditions from './TermsAndConditions/TermsAndConditions'

export default function CreateTemplate(props) {
  const {
    totalPages,
    pageSize,
    file,
    fileBase64,
    sign,
    zoom,
    signers,
    setSigners,
    signPositions,
    setSignPositions,
    legalRepresentant,
    setLegalRepresentant,
    user,
    dd_dragstart,
    boxSign,
    setImageBoxState,
    setNewWidth,
    setNewHeigth,
    newWidth,
    newHeigth,
    onBorder,
    setOnBorder,
    setSignBase64,
    signBase64,
    // Checked endorse (only kavak)
    endorseTemplate,
    setEndorseTemplate,
    signerEndorseTemplate,
    setSignerEndorseTemplate,
    clientId,
  } = props
  const { Panel } = Collapse
  //MainConfigurations
  const [checked, setChecked] = useState(false) //Participant geolocation
  const [whoSign, setWhoSign] = useState('me')
  const [dataCountry, setDataCountry] = useState('Mexico')
  const [templateName, setTemplateName] = useState('')
  const [isMasive, setIsMasive] = useState(false)
  const [isMesa, setIsMesa] = useState(false)
  //Signers
  const [sortStatus, setSortStatus] = useState(false)
  const [currentDelete, setCurrentDelete] = useState([])
  //Observers
  const [observers, setObservers] = useState([])
  const [currentDeleteObserver, setCurrentDeleteObserver] = useState([])
  //Message
  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')

  // Terms and Conditions
  const [withTermsAndConditions, setWithTermsAndConditions] = useState(false)
  const [listTermsAndConditions, setListTermsAndConditions] = useState([])

  const handleReset = () => {
    setSignPositions([])
    setChecked(false)
    setDataCountry('Mexico')
    setSortStatus(false)
    setSigners([])
    setObservers([])
    setTitle('')
    setMessage('')
    setIsMasive(false)
    setLegalRepresentant({
      name: '',
      email: '',
    })
  }

  const handleComproveSigners = () => {
    // Zona A (PDF)
    const orderSigners = document.getElementById('orderSigners')
    if (orderSigners) {
      //Elimina los hijos ya existentes, Evita duplicados
      const tope = orderSigners.childElementCount
      for (let i = 0; i < tope; i++) {
        orderSigners.removeChild(orderSigners.firstChild)
      }
      //Apartir de Signers creara las tarjetas
      for (let signer of signers) {
        let mainLi = document.createElement('li')
        let mainInformationDiv = document.createElement('div')
        let informationDiv = document.createElement('div')
        let informationDivDivEmail = document.createElement('div')
        let informationDivTextEmail = document.createTextNode(`${signer.email}`)
        let informationDivDivName = document.createElement('div')
        let informationDivTextName = document.createTextNode(`${signer.name}`)
        let deleteDiv = document.createElement('div')
        let deleteButton = document.createElement('button')
        let deleteButtonText = document.createTextNode('×')

        // ------  Check endoso (only Kavak)
        let optionsEndorseDiv = document.createElement('div')
        let optionsEndorseDivIdentity = document.createElement('div')
        let checkEndorseIdentity = document.createElement('input')
        let spanEndorseIdentity = document.createElement('span')
        let textEndorseIdentity = document.createTextNode('Endosar Firma')

        optionsEndorseDiv.classList = 'optionsDiv'
        optionsEndorseDiv.style.marginBottom = '0.8rem'
        optionsEndorseDivIdentity.classList = 'optionsDivIdentity'
        optionsEndorseDiv.appendChild(optionsEndorseDivIdentity)

        checkEndorseIdentity.type = 'checkbox'
        checkEndorseIdentity.classList = 'checkIdentity'
        checkEndorseIdentity.checked = signer.isEndorse
        checkEndorseIdentity.id = `${signer.email}_li  `
        checkEndorseIdentity.onchange = (e) => {
          setSignerEndorseTemplate(signer.email)
          setEndorseTemplate(e.target.checked)
        }

        // Validación solo para Kavak
        if (
          signer.uuid !== '' ||
          clientId !== 'F0LDMiWc3mOlQjgtCLWIsA32U9og84UO'
        ) {
          optionsEndorseDiv.style.display = 'none'
        }

        optionsEndorseDivIdentity.appendChild(checkEndorseIdentity)

        spanEndorseIdentity.classList = 'spanOptionsText'
        spanEndorseIdentity.style.marginLeft = '10px'
        spanEndorseIdentity.appendChild(textEndorseIdentity)
        optionsEndorseDivIdentity.appendChild(spanEndorseIdentity)

        // ------ Main Div
        mainLi.classList = 'mainLi'
        mainLi.id = `${signer.email}_li`
        mainLi.style.cursor = sortStatus ? 'pointer' : 'normal'

        informationDiv.classList = 'informationDiv'
        if (signer?.name) {
          informationDivDivName.appendChild(informationDivTextName)
          informationDiv.appendChild(informationDivDivName)
        }
        informationDivDivEmail.appendChild(informationDivTextEmail)
        informationDiv.appendChild(informationDivDivEmail)

        mainInformationDiv.classList = 'mainInformationDiv'
        mainInformationDiv.appendChild(informationDiv)

        deleteDiv.classList = 'deleteDiv'

        deleteButton.appendChild(deleteButtonText)
        deleteButton.classList = 'deleteButton'
        deleteButton.id = `${signer.email}_li `
        deleteButton.onclick = function (e) {
          setCurrentDelete([{ element: e.target }])
        }

        deleteDiv.appendChild(deleteButton)
        mainInformationDiv.appendChild(deleteDiv)
        mainInformationDiv.appendChild(optionsEndorseDiv)

        mainLi.appendChild(mainInformationDiv)
        orderSigners.appendChild(mainLi)
      }
    }
  }

  const handleComproveObserver = () => {
    const orderObserver = document.getElementById('orderObserver')
    if (orderObserver) {
      //Elimina los hijos ya existentes, Evita duplicados
      const childs = orderObserver.childElementCount
      for (let i = 0; i < childs; i++) {
        orderObserver.removeChild(orderObserver.firstChild)
      }
      //Apartir de observers creara las tarjetas
      for (let observer of observers) {
        let newSpan = document.createElement('span')
        let name_observer = document.createTextNode(`${observer.name}`)
        let email_observer = document.createTextNode(`${observer.email}`)
        newSpan.id = `${observer.email}_ctn`
        newSpan.classList = 'divLi'
        newSpan.style.cursor = 'normal'

        orderObserver.appendChild(newSpan)

        // Info signer
        let newSpanName = document.createElement('span')
        newSpanName.classList = 'spanNameSigner'
        newSpanName.appendChild(name_observer)

        let newSpanEmail = document.createElement('span')
        newSpanEmail.classList = 'spanEmailSigner'
        newSpanEmail.appendChild(email_observer)

        let newDivContentInfo = document.createElement('div')
        newDivContentInfo.classList = 'contentInfoAddSign'
        newDivContentInfo.appendChild(newSpanName)
        newDivContentInfo.appendChild(newSpanEmail)

        // Delete signer
        let newDiv = document.createElement('div')
        let newDivText = document.createTextNode('×')
        // newDiv.id = `${signer.email}_li `
        newDiv.classList = 'delLi'

        let deleteObserverBtn = document.createElement('span')
        deleteObserverBtn.appendChild(newDivText)
        deleteObserverBtn.id = `${observer.email}_li `
        deleteObserverBtn.classList = 'deleteButton'
        deleteObserverBtn.onclick = function (e) {
          setCurrentDeleteObserver([{ element: e.target }])
        }

        newDiv.appendChild(deleteObserverBtn)
        newSpan.appendChild(newDivContentInfo)
        newSpan.appendChild(newDiv)
      }
    }
  }

  useEffect(() => {
    handleComproveSigners()
    handleComproveObserver()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signers, sortStatus, observers])

  //Ayuda a eliminar un firmante manteniendo los datos actualizados
  const handleCurrentDelete = (currentDelete) => {
    if (currentDelete.length !== 0) {
      let newA = []
      let newAP = []
      //Para actualizar el arreglo de firmantes
      for (let signer of signers) {
        if (`${signer.email}_li` !== currentDelete[0].element.id.trim())
          newA = [...newA, signer]
      }
      //Para actualizar el arreglo de posiciones
      for (let signPosition of signPositions) {
        if (`${signPosition?.email}_li` !== currentDelete[0].element.id.trim())
          newAP = [...newAP, signPosition]
      }
      setSigners(newA)
      setSignPositions(newAP)
    }
  }

  const handleCurrentDeleteObservers = (currentDeleteObserver) => {
    if (currentDeleteObserver.length !== 0) {
      let newA = []
      //Para actualizar el arreglo de firmantes
      for (let observer of observers) {
        if (
          `${observer.email}_li` !== currentDeleteObserver[0].element.id.trim()
        )
          newA = [...newA, observer]
      }
      setObservers(newA)
    }
  }

  useEffect(() => {
    handleCurrentDelete(currentDelete)
    handleCurrentDeleteObservers(currentDeleteObserver)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDelete, currentDeleteObserver])

  //Si isMasive es falso se encargara de limpiar la informacion relacionada
  const handleMasiveFalse = () => {
    if (signers.length !== 0) {
      let newSigners = []
      for (let signer of signers) {
        if (legalRepresentant.email !== signer.email) newSigners.push(signer)
      }

      let newSignPositions = []
      for (let signPosition of signPositions) {
        if (legalRepresentant.email !== signPosition.email)
          newSignPositions.push(signPosition)
      }

      setSigners(newSigners)
      setSignPositions(newSignPositions)
      setLegalRepresentant({
        name: '',
        email: '',
      })
    }
  }

  useEffect(() => {
    if (!isMasive) handleMasiveFalse()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMasive])

  useEffect(() => {
    const auxSignPositions = [...signPositions]
    const auxSigners = [...signers]
    let auxPositionEndorse = [],
      auxSignerEndorse = []

    for (const signers of auxSigners) {
      if (signerEndorseTemplate === signers.email)
        signers.isEndorse = endorseTemplate
      auxSignerEndorse.push(signers)
    }

    for (const position of auxSignPositions) {
      if (signerEndorseTemplate === position.email)
        position.isEndorse = endorseTemplate
      auxPositionEndorse.push(position)
    }
  }, [endorseTemplate, signerEndorseTemplate, signPositions, signers])

  return (
    <Right item xs={4}>
      <RightTitle>Plantilla</RightTitle>
      <Collapse
        defaultActiveKey={['a', 'b', 'c', 'd', 'e', 'f', 'g']}
        style={{
          '& .antCollapseItem': {
            backgroundColor: 'rgb(238, 238, 238)',
          },
        }}
      >
        <Panel
          header="Principales"
          key="a"
          style={{
            backgroundColor: ' rgba(120,120,120,0.1)',
            color: ' rgba(0,0,0,.87)',
          }}
        >
          <MainConfigurationsTemplate
            templateName={templateName}
            setTemplateName={setTemplateName}
            dataCountry={dataCountry}
            setDataCountry={setDataCountry}
            whoSign={whoSign}
            setWhoSign={setWhoSign}
            checked={checked}
            setChecked={setChecked}
            handleReset={handleReset}
            isMasive={isMasive}
            setIsMasive={setIsMasive}
            isMesa={isMesa}
            setIsMesa={setIsMesa}
          />
        </Panel>
        <Panel
          header="Firmantes y Observadores"
          key="b"
          style={{
            backgroundColor: ' rgba(120,120,120,0.1)',
            color: ' rgba(0,0,0,.87)',
          }}
        >
          {whoSign !== 'me' ? (
            <Signers
              signers={signers}
              setSigners={setSigners}
              sortStatus={sortStatus}
              setSortStatus={setSortStatus}
            />
          ) : null}
          <Observers observers={observers} setObservers={setObservers} />
        </Panel>
        {isMasive ? (
          <Panel
            header="Representante Legal"
            key="c"
            style={{
              backgroundColor: ' rgba(120,120,120,0.1)',
              color: ' rgba(0,0,0,.87)',
            }}
          >
            <LegalRepresentant
              legalRepresentant={legalRepresentant}
              setLegalRepresentant={setLegalRepresentant}
              signers={signers}
              setSigners={setSigners}
              signPositions={signPositions}
              setSignPositions={setSignPositions}
              whoSign={whoSign}
              user={user}
            />
          </Panel>
        ) : null}
        {(whoSign === 'me' && observers.length > 0) || whoSign !== 'me' ? (
          <Panel
            header="Mensaje"
            key="d"
            style={{
              backgroundColor: ' rgba(120,120,120,0.1)',
              color: ' rgba(0,0,0,.87)',
            }}
          >
            <Message
              title={title}
              setTitle={setTitle}
              message={message}
              setMessage={setMessage}
            />
          </Panel>
        ) : null}
        <Panel
          header="Configuracion de firma"
          key={'e'}
          style={{
            backgroundColor: ' rgba(120,120,120,0.1)',
            color: ' rgba(0,0,0,.87)',
          }}
        >
          <SignatureSettings
            setSignBase64={setSignBase64}
            setImageBoxState={setImageBoxState}
            setNewWidth={setNewWidth}
            setNewHeigth={setNewHeigth}
            newWidth={newWidth}
            newHeigth={newHeigth}
            boxSign={boxSign}
            setOnBorder={setOnBorder}
            onBorder={onBorder}
          />
        </Panel>

        {/* Only Monte */}
        {clientId === 'YVNNc03FL9G8Zt58yJxYgqq0VqMXKRfm' ||
        clientId === 'ZcO0euZ9ekObdfCXa1XW4VvqLze4Mi8R' ||
        clientId === 'PCjGAWQk80Oz9PCwQKHdjfrbQfVFwIwa' ||
        clientId === 'iWu9ttlsyzggGEu0WxMz7aIz1CpbgbJS' ||
        clientId === 'TaDlnYwJD3CLV6cnLOmUqobPdkFBqR49' ||
        clientId === 'CdS5CULl5bNGY4EyNXLWXCeaAjdnhDbF' ||
        clientId === 'gkOhlOFKrxmcowZawGThfeeIAQwDsxff' ||
        clientId === 'KVyVQEl9AuyhGt4xmCg0svgm9IUSmyBm' ? (
          <Panel
            header="Terminos y Condiciones"
            key={'f'}
            style={{
              backgroundColor: ' rgba(120,120,120,0.1)',
              color: ' rgba(0,0,0,.87)',
            }}
          >
            <TermsAndConditions
              withTermsAndConditions={withTermsAndConditions}
              setWithTermsAndConditions={setWithTermsAndConditions}
              listTermsAndConditions={listTermsAndConditions}
              setListTermsAndConditions={setListTermsAndConditions}
            />
          </Panel>
        ) : null}

        <Panel
          header="Firmas"
          key="g"
          style={{
            backgroundColor: ' rgba(120,120,120,0.1)',
            color: ' rgba(0,0,0,.87)',
          }}
        >
          <Signs
            totalPages={totalPages}
            pageSize={pageSize}
            user={user}
            zoom={zoom}
            sign={sign}
            signers={signers}
            signPositions={signPositions}
            setSignPositions={setSignPositions}
            boxSign={boxSign}
            dd_dragstart={dd_dragstart}
            whoSign={whoSign}
          />
          <Save
            totalPages={totalPages}
            templateName={templateName}
            checked={checked}
            file={file}
            fileBase64={fileBase64}
            signPositions={signPositions}
            dataCountry={dataCountry}
            observers={observers}
            title={title}
            message={message}
            whoSign={whoSign}
            isMasive={isMasive}
            isMesa={isMesa}
            legalRepresentant={legalRepresentant}
            newWidth={newWidth}
            newHeigth={newHeigth}
            onBorder={onBorder}
            signBase64={signBase64}
            sortStatus={sortStatus}
            withTermsAndConditions={withTermsAndConditions}
            listTermsAndConditions={listTermsAndConditions}
          />
        </Panel>
      </Collapse>
    </Right>
  )
}
