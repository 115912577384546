import React, { useEffect, useState } from 'react'
import {
  Box,
  BoxContainer,
  BoxImage,
  DDZoneB,
  EndorseTextArea,
  //MyGrid,
  GeneralDiv,
} from './SignsStyle'

export default function Signs(props) {
  const {
    zoom,
    sign,
    signers,
    signPositions,
    //setSignPositions,
    boxSign,
    dd_dragstart,
    handleEndorseText,
    handleClickEndorseBox,
  } = props

  const [dataSigner, setDataSigner] = useState({})

  useEffect(() => {
    const foundData = signPositions.find(
      (signer) => signer?.user?.name === signers
    )

    setDataSigner(foundData?.user)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signers])

  return (
    <>
      <GeneralDiv onDragStart={(e) => dd_dragstart(e)}>
        <Box
          draggable={true}
          id={`${dataSigner?.name}-${dataSigner?.email}-${dataSigner?.temporalID}-${dataSigner?.isEndorse}`}
          width={`${136 * zoom}px`}
          height={`${75 * zoom}px`}
          onClick={(e) =>
            handleClickEndorseBox(
              `${dataSigner?.name}-${dataSigner?.email}-${dataSigner?.temporalID}`
            )
          }
        >
          <BoxImage
            src={boxSign}
            alt="caja"
            draggable={false}
            width={`${136 * zoom}px`}
            height={`${75 * zoom}px`}
          />
          <BoxContainer
            width={`${
              sign.signature === ''
                ? 113 * zoom
                : sign.option !== 3
                ? 80 * zoom
                : 113 * zoom
            }px`}
            height={`${35 * zoom}px`}
            fsize={`${9 * zoom}px`}
            bimage={
              dataSigner?.name && !dataSigner?.name.includes('Firmante')
                ? `url(${sign.signature})`
                : null
            }
            bsize={`${sign.option !== 3 ? 80 * zoom : 113 * zoom}px ${
              35 * zoom
            }px`}
            color={
              sign.signature &&
              dataSigner?.name &&
              !dataSigner?.name.includes('Firmante')
                ? '#fff'
                : '#000'
            }
          >
            {dataSigner?.email}
          </BoxContainer>
        </Box>
        <EndorseTextArea
          defaultValue={
            'Endoso en propiedad de la presente factura del auto a favor de UVI TECH, S.A.P.I de C.V'
          }
          style={{
            padding: '0.5rem 0.45rem',
            lineHeight: '19px',
            fontSize: 'clamp(0.86rem, 0.89rem, 0.92rem)',
          }}
          id={`${dataSigner?.temporalID}`}
          onChange={(e) => handleEndorseText(e.target)}
        ></EndorseTextArea>
      </GeneralDiv>

      <DDZoneB id="ddzoneB" onDragStart={(e) => dd_dragstart(e)}></DDZoneB>
    </>
  )
}
