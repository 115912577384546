import React from 'react'
import { MyGrid } from '../Templates/UseTemplate/Geolocation/GeolocationStyle.jsx'
import { Switch, Tooltip } from 'antd'
import { VscInfo } from 'react-icons/vsc'
import './settingsSignStyles.css'

export default function SignatureSettings(props) {
  const {
    setNewWidth,
    newWidth,
    setNewHeigth,
    newHeigth,
    setImageBoxState,
    boxSign,
    setOnBorder,
    onBorder,
    setSignBase64,
  } = props

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = ''
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        baseURL = reader.result
        setSignBase64(baseURL.split('base64,')[1])
        resolve(baseURL)
      }
    })
  }

  const handleFileInputChange = (e) => {
    setImageBoxState(
      e.target.files[0] !== undefined
        ? URL.createObjectURL(e.target.files[0])
        : boxSign
    )
    let file = e.target.files[0]
    getBase64(file)
      .then((result) => {
        file['base64'] = result
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <>
      <p style={{ color: 'var(--gray-color)', padding: '0.2rem 0 1rem' }}>
        Personalice el aspecto visual de la(s) caja(s) de firma, modificando
        estas opciones.
      </p>
      <div className="main_container_setting_sign">
        {/* Reset */}
        <div className="containerReset">
          <MyGrid
            marginBottom={5}
            className="config_form__content_input"
            style={{ alignItems: 'center', margin: '0' }}
          >
            <Tooltip title="Habilita esta opción para poder ver u ocultar el border de la caja de firma">
              <VscInfo
                color="rgb(140, 140, 140)"
                size={17}
                style={{
                  cursor: 'pointer',
                  marginRight: '6px',
                }}
              />
            </Tooltip>
            <Switch
              style={{
                // width: '14px',
                marginRight: '10px',
              }}
              checked={onBorder}
              onChange={(e) => {
                setOnBorder(e)
              }}
              unCheckedChildren="Mostrar"
              checkedChildren="Ocultar"
            />
          </MyGrid>

          <button
            className="resetButton"
            onClick={() => {
              setImageBoxState(boxSign)
              setNewWidth(136)
              setNewHeigth(73)
            }}
          >
            Reset
          </button>
        </div>
        {/* Settings */}
        <div className="content_info_settings_sign">
          <Tooltip title="Modifica el ancho y/o alto de la(s) caja(s) de firma">
            <VscInfo
              color="rgb(140, 140, 140)"
              size={17}
              style={{
                cursor: 'pointer',
                marginRight: '6px',
              }}
            />
          </Tooltip>
          <p style={{ color: 'var(--gray-color)' }}>Dimensión de la firma</p>
        </div>
        <div className="containerResize">
          <div className="divResize">
            <p className="labelSize" style={{ color: 'var(--yellow-color)' }}>
              Ancho
            </p>
            <label className="labelSize">
              <input
                className="inputSize"
                type="number"
                onChange={(e) => {
                  setNewWidth(e.target.value)
                }}
                value={newWidth}
                min={50}
                max={250}
              />
              <span>px</span>
            </label>
          </div>
          <div className="divResize">
            <p className="labelSize" style={{ color: 'var(--yellow-color)' }}>
              Alto
            </p>
            <label className="labelSize">
              <input
                className="inputSize"
                type="number"
                onChange={(e) => {
                  setNewHeigth(e.target.value)
                }}
                value={newHeigth}
                min={50}
                max={250}
              />
              <span>px</span>
            </label>
          </div>
        </div>
      </div>
      <div
        className="main_container_setting_sign"
        style={{ marginBottom: '1.2rem' }}
      >
        <div className="content_info_settings_sign">
          <Tooltip title="Modifica el fondo de la(s) caja(s) de firma">
            <VscInfo
              color="rgb(140, 140, 140)"
              size={17}
              style={{
                cursor: 'pointer',
                marginRight: '6px',
              }}
            />
          </Tooltip>
          <p style={{ color: 'var(--gray-color)' }}>Imagen de la firma</p>
        </div>
        {/* Upload image */}
        <input
          accept="image/jpg,image/jpeg"
          className="inputImage"
          type="file"
          onChange={(e) => {
            handleFileInputChange(e)
          }}
        />
      </div>
    </>
  )
}
