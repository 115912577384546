import React from 'react'

import './DomainLabel.css'

const DomainLabel = ({ text, backgroundColor, color }) => (
  <div
    className="DomainLabel"
    style={{
      backgroundColor,
    }}
  >
    <h3 style={{ color }}>{text}</h3>
  </div>
)

export default DomainLabel
