/**
 * Filter data by category
 * @param {*} arr array to filter
 * @param {*} cat category to be filter
 */
export const filterByCategory = (arr, cat) =>
  arr?.filter((data) => data?.category === cat)

/**
 * Filter to check result of array
 * @param {*} arr array to be checked
 * @param {*} res expected result that indicates its ok
 */
export const filterCheckResult = (arr, res) =>
  arr?.filter((data) => data?.result === res).length >= 1

/**
 * Filter to check if the category contains data
 * @param {*} arr array to check
 * @param {*} cat cat to be checked if exists
 */
export const filterCategoryCheck = (arr, cat) =>
  arr?.filter((res) => res?.category === cat).length >= 1

/**
 * Filter to check result of array in boolean
 */
export const filterCheckResultFalse = (arr) =>
  arr?.filter((res) => !res?.result).length >= 1

/**
 * Filter to check result of array in boolean
 */
export const filterCheckResultTrue = (arr) =>
  arr?.filter((res) => res?.result).length >= 1

/**
 * Filter permissions
 */
export const filterCheckPermissions = (arr, permissions) =>
  arr?.filter((res) => res === permissions).length >= 1

/**
 * Filter to check if an item is deleted
 */
export const filterCheckDeleteList = (arr, value) =>
  arr?.filter((res) => res === value).length >= 1

/**
 *
 */
export const filterUndoList = (arr, value) =>
  arr?.filter((res) => res !== value)

/**
 * filter to check if contains result as overriden and no one as failed
 */
export const filterOverridenResult = (arr) =>
  arr?.filter((res) => res?.result === 'Ok' || res?.result === 'Overriden')
    .length >= 1

/**
 * Filter data by source
 * @param {*} arr array to filter
 * @param {*} src source to be filter
 */
export const filterBySource = (arr, src) =>
  arr?.filter((data) => data?.source === src)

/**
 * Filter to check if the source contains data
 * @param {*} arr array to check
 * @param {*} src source to be checked if exists
 */
export const filterSourceCheck = (arr, src) =>
  arr?.filter((res) => res?.source === src).length >= 1
