import React from 'react'
import { Button, Modal } from 'semantic-ui-react'
import ClientAllDataShow from '../views/ClientAllDataShow'

const ModalVerification = ({
  setOpenEnrollModal,
  openEnrollModal,
  jsonVerification,
  failedBiometrics,
}) => {
  return (
    <>
      <Modal
        closeIcon
        onClose={() => setOpenEnrollModal(false)}
        open={openEnrollModal}
        trigger={<Button>Scrolling Content Modal</Button>}
        size="fullscreen"
        style={{
          maxWidth: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          position: 'static',
          height: 'fit-content',
        }}
      >
        <Modal.Header>Verificación</Modal.Header>
        <Modal.Content>
          <ClientAllDataShow
            openInModal={true}
            jsonVerification={jsonVerification}
            failedBiometricsModal={failedBiometrics}
          />
        </Modal.Content>
      </Modal>
    </>
  )
}
export default ModalVerification
