import { Tooltip } from 'antd'
import React from 'react'
import { VscInfo } from 'react-icons/vsc'
import MainConfigurations from '../../../PDFSignatureNC/ConfigurationsPDF/MainConfigurations/MainConfigurations'
import {
  MainContainer,
  MesaMasiva,
  MesaMasivaItems,
  MesaMasivaItemsLeft,
  TemplateNameInput,
} from './MainConfigurationsTemplateStyle'

export default function MainConfigurationsTemplate(props) {
  const {
    templateName,
    setTemplateName,
    dataCountry,
    setDataCountry,
    whoSign,
    setWhoSign,
    checked,
    setChecked,
    handleReset,
    isMasive,
    setIsMasive,
    isMesa,
    setIsMesa,
  } = props

  const handleTemplateName = (e) => setTemplateName(e.target.value)

  const handleMasive = (e) => {
    setIsMasive(e.target.checked)
    if (!e.target.checked) setIsMesa(false)
  }

  const handleMesa = (e) => setIsMesa(e.target.checked)

  return (
    <MainContainer>
      <label style={{ color: 'var(--secondary-color)', marginBottom: '10px' }}>
        Nombre
      </label>
      <TemplateNameInput
        type="text"
        value={templateName}
        onChange={handleTemplateName}
        placeholder="Nombre de la plantilla"
      />
      <MainConfigurations
        dataCountry={dataCountry}
        setDataCountry={setDataCountry}
        checked={checked}
        setChecked={setChecked}
        whoSign={whoSign}
        setWhoSign={setWhoSign}
        handleReset={handleReset}
      />
      <MesaMasiva container>
        <MesaMasivaItemsLeft>
          <Tooltip title="Activa la compatibilidad con firma masiva">
            <VscInfo
              color="rgb(140, 140, 140)"
              size={16}
              style={{
                cursor: 'pointer',
                marginRight: '15px',
              }}
            />
          </Tooltip>
          <span>Firma masiva</span>
        </MesaMasivaItemsLeft>
        <MesaMasivaItems className="signers_Li">
          <input
            type="checkbox"
            checked={isMasive}
            onChange={handleMasive}
            style={{ marginRight: '10px' }}
          />
        </MesaMasivaItems>
      </MesaMasiva>

      <MesaMasiva container>
        <MesaMasivaItemsLeft>
          <Tooltip title="Activa la mesa de revisión">
            <VscInfo
              color="rgb(140, 140, 140)"
              size={16}
              style={{
                cursor: 'pointer',
                marginRight: '15px',
              }}
            />
          </Tooltip>
          <span>Mesa de revisión</span>
        </MesaMasivaItemsLeft>
        <MesaMasivaItems className="signers_Li">
          <input
            type="checkbox"
            checked={isMesa}
            onChange={handleMesa}
            style={{ marginRight: '10px' }}
          />
        </MesaMasivaItems>
      </MesaMasiva>
    </MainContainer>
  )
}
