import React, { useState, useEffect, Fragment, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Loader, Dimmer } from 'semantic-ui-react'
import { Hidden } from '@material-ui/core'
import { Skeleton } from 'antd'
import useSWR from 'swr'
// Utils
import { API_DASHBOARD_URL } from '../const/index.js'
import useToken from '../utils/useToken'
import RecordsFilters from '../context/RecordsFilters.context'
// Components
import RegistersMovile from './ShowRegisters/RegistersMovile.js'
import RegistersPc from './ShowRegisters/RegistersPc.js'
import Settings from './ShowRegisters/Settings.js'

const ShowRegisters = (props) => {
  const { filters } = props
  const { t } = useTranslation()
  const getToken = useToken()
  const { isSearching, setIsSearching, id } = useContext(RecordsFilters)
  const [activePage, setActivePage] = useState(1)
  const [register, setRegister] = useState(null)
  const [delay, setDelay] = useState(5000)
  const [limit, setLimit] = useState(10)
  const [selected, setSelected] = useState({
    createdAtD: true,
    createdAtA: false,
    idD: false,
    idA: false,
  })

  const callToApiDashboard = async (url) => {
    const token = await getToken({
      audience: 'veridocid-dashboard',
    })

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })

    if (response.ok) {
      const responseJson = await response.json()
      setIsSearching(false)
      return responseJson
    } else {
      const error = await response.json()
      console.error(error)
      setIsSearching(false)
      return null
    }
  }

  const { data } = useSWR(
    `${API_DASHBOARD_URL}/records?limit=${limit}&page=${activePage}&id=${id}&sortBy=${
      selected.createdAtD
        ? 1
        : selected.createdAtA
        ? 2
        : selected.idD
        ? 3
        : selected.idA
        ? 4
        : 1
    }&filters=${JSON.stringify(filters)}`,
    callToApiDashboard,
    {
      refreshInterval: delay,
    }
  )

  useSWR(
    register?.hasNextPage
      ? `${API_DASHBOARD_URL}/records?limit=${limit}&page=${
          activePage + 1
        }&id=${id}&sortBy=${
          selected.createdAtD
            ? 1
            : selected.createdAtA
            ? 2
            : selected.idD
            ? 3
            : selected.idA
            ? 4
            : 1
        }&filters=${JSON.stringify(filters)}`
      : null,
    callToApiDashboard
  )

  useEffect(() => {
    if (data) {
      const wait =
        Math.ceil(data.totalDocs / 10) < 5000
          ? 5000
          : Math.ceil(data.totalDocs / 8)
      setDelay(wait)
      setRegister(data)
    }
  }, [limit, data])

  useEffect(() => {
    setActivePage(1)
  }, [isSearching])

  const handlePaginationChange = (e, { activePage }) =>
    setActivePage(activePage)

  return (
    <Fragment>
      <Dimmer.Dimmable dimmed={!data}>
        <Dimmer active={!data} inverted>
          <Loader>{t('loading')}</Loader>
        </Dimmer>
        <Hidden smDown>
          {register ? (
            <RegistersPc
              register={register.docs}
              selected={selected}
              setSelected={setSelected}
            />
          ) : (
            <>
              <Skeleton.Input active block style={{ height: '600px' }} />
            </>
          )}
        </Hidden>
        <Hidden mdUp>
          {register ? (
            <RegistersMovile
              register={register.docs}
              selected={selected}
              setSelected={setSelected}
            />
          ) : (
            <>
              <Skeleton.Input active block style={{ height: '90vh' }} />
            </>
          )}
        </Hidden>
        <Settings
          activePage={activePage}
          totalPages={register?.totalPages ?? 0}
          data={data}
          handlePaginationChange={handlePaginationChange}
          setLimit={setLimit}
        />
      </Dimmer.Dimmable>
    </Fragment>
  )
}

export default ShowRegisters
