import React from 'react'
import { Segment, Header } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'antd'
import '../styles/AdditionalVerifications.css'
import { VscDebugRestart } from 'react-icons/vsc'
import { findLast } from 'lodash'

// Components
import ListaNominal from './ConsultationServices/CardListaNominal/CardListaNominal'
import CardRenapo from './ConsultationServices/CardRenapo/CardRenapo'
// import AntecedentesPenales from './ConsultationServices/CardAntecedentes/CardAntecedentes'
import AntecedentesPenalesV2 from './ConsultationServices/CardAntecedentesV2/CardAntecedentes'
import CardOcrAddress from './ConsultationServices/CardOcrAddress/CardOcrAddress'
import CardControlList from './ConsultationServices/CardControlList/CardControlList'
import CardControlListCollapse from './ConsultationServices/CardControlList/CardControlListCollapse'
import CardEmail from './ConsultationServices/CardEmail/CardEmail'
import CardPhone from './ConsultationServices/CardPhone/CardPhone'
import CardIp from './ConsultationServices/CardIp/CardIp'
import CardRFC from './ConsultationServices/CardRFC/CardRFC'
//import CardNSS from './ConsultationServices/CardNSS/CardNss'
import CardIMSS from './ConsultationServices/CardIMSS/CardImss'
import CardList69 from './ConsultationServices/CardList69/CardList69'

//Shared
import {
  filterCategoryCheck,
  filterByCategory,
  filterCheckResult,
} from '../shared/filters'
import { Masonry } from '@mui/lab'

const AdditionalVerifications = ({
  idVerification,
  isNotProcess,
  isMexican,
  isHideNominalList,
  isOcrAddressAllowed,
  isTruoraAllowed,
  isEmailAllowed,
  isPhoneAllowed,
  isIpAllowed,
  isRfcAllowed,
  //isNssAllowed,
  isImssAllowed,
  isList69Allowed,
  isCourtAllowed,
  isIneAllowed,
  data,
  curp,
  id,
  uuid,
  voterKey,
  numEmision,
  ocr,
  cic,
  ciudadano,
  email,
  phone,
  ip,
  rfcUser,
  rfcList69,
  givenName,
  surName,
  //curpNSS,
  //emailNSS,
  curpAntecedentesPenales,
  curpIMSS,
  onVerifyCriminalRecord,
  onVerifyOcrAddress,
  onVerifyListaNominal,
  onVerifyRenapo,
  onVerifyEmail,
  onVerifyPhone,
  onVerifyIp,
  onVerifyRfc,
  onVerifyNss,
  onVerifyImss,
  onVerifyList69,
  onVerifyCourtRecord,
  handleChangeInputCic,
  handleChangeInputOcr,
  handleChangeInputVoterKey,
  handleChangeInputNumEmision,
  handleChangeInputCiudadano,
  handleChangeInputCurpAntecedentesPenales,
  handleChangeInputCurp,
  handleChangeInputEmail,
  handleChangeInputPhone,
  handleChangeInputIp,
  handleChangeInputRFC,
  handleChangeInputRFCList69,
  handleChangeInputNameAntecedentesPenales,
  handleChangeInputSurnameAntecedentesPenales,
  //handleChangeInputNssforCurp,
  //handleChangeInputNssforEmail,
  handleChangeInputImssforCurp,
  isRenapoVerified,
  setIsRenapoVerified,
  isIneVerified,
  setIsIneVerified,
  isOcrAddressVerified,
  setIsOcrAddressVerified,
  isTruoraVerified,
  setIsTruoraVerified,
  isEmailVerified,
  setIsEmailVerified,
  isPhoneVerified,
  setIsPhoneVerified,
  isIpVerified,
  setIsIpVerified,
  isRfcVerified,
  setIsRfcVerified,
  isList69Verified,
  setIsList69Verified,
  /* isNssVerified,
  setIsNssVerified, */
  isImssVerified,
  setIsImssVerified,
  isCourtVerified,
  setIsCourtVerified,
  portrait,
  facial,
  //Controlar el ingreso de los datos en el Curp
  classErrorCurpInputRenapo,
  visibleErrorCurpInputRenapo,
  messageErrorCurpInputRenapo,
  //
  classErrorCurpInputAntecedentes,
  visibleErrorCurpInputAntecedentes,
  messageErrorCurpInputAntecedentes,
  //
  /* classErrorCurpInputNss,
  visibleErrorCurpInputNss,
  messageErrorCurpInputNss, */
  //
  classErrorCurpInputImss,
  visibleErrorCurpInputImss,
  messageErrorCurpInputImss,
  //
  classErrorNameInputAntecedentes,
  visibleErrorNameInputAntecedentes,
  messageErrorNameInputAntecedentes,
  classErrorSurnameInputAntecedentes,
  visibleErrorSurnameInputAntecedentes,
  messageErrorSurnameInputAntecedentes,
  //------------- Reset services
  isResetAllowed,
  // ip
  handleResetIp,
  messageIp,
  // email
  handleResetEmail,
  messageEmail,
  // phone
  handleResetPhone,
  messagePhone,
  // Ocr
  handleResetOcrAddress,
  messageOcrAddress,
  // Renapo
  handleResetRenapo,
  messageRenapo,
  isRenapoAllowed,
  // Ine
  handleResetIne,
  messageIne,
  //RFC
  handleResetRfc,
  messageRfc,
  // Imss & Nss
  handleResetHistorialImss,
  messageHistorialImss,
  //List69
  handleResetList69,
  messageList69,
  //Legal Record
  handleResetLegalRecord,
  messageLegalRecord,
  // Hide reload buttons if open in modal
  openInModal,
}) => {
  let isVotingCardC
  const { t } = useTranslation()
  const templateName = data?.idVerification?.data?.templateName
  const isBlackList = filterCategoryCheck(
    data?.idVerification?.data?.documentVerifications,
    'ControlList'
  )
  const controlList = filterByCategory(
    data?.idVerification?.data?.documentVerifications,
    'ControlList'
  )
  const isControlListFailed = filterCheckResult(controlList, 'Warning')

  if (
    templateName === 'Credencial para votar 2013' ||
    templateName === 'Credencial para votar 2012' ||
    templateName === 'Credencial para votar 2011' ||
    templateName === 'Credencial para votar 2010' ||
    templateName === 'Credencial para votar 2009' ||
    templateName === 'Credencial para votar 2008' ||
    templateName === 'Credencial para votar serie C' ||
    templateName === 'Mexico - Voting Card #3' ||
    templateName === 'Mexico - Voting Card (2008)'
  ) {
    isVotingCardC = true
  } else {
    isVotingCardC = false
  }

  return isNotProcess ? null : (
    <Masonry
      columns={{ xs: 1, md: 2 }}
      spacing={{ xs: 2, md: 3 }}
      style={{ marginTop: '1.4rem' }}
    >
      {isIneAllowed ? (
        isHideNominalList || !isMexican ? null : (
          <div>
            <Header
              // as="h3"
              attached="top"
              className="AdditionalVerifications-header"
            >
              <h3>Consulta a Lista Nominal</h3>
              {isResetAllowed && !openInModal ? (
                <Tooltip
                  placement="top"
                  title={messageIne.msg}
                  visible={messageIne.visible}
                  zIndex={9}
                >
                  <button
                    type="button"
                    className="reset_btn"
                    onClick={() => handleResetIne()}
                  >
                    <VscDebugRestart size={19} />
                  </button>
                </Tooltip>
              ) : null}
            </Header>
            <Segment attached>
              <ListaNominal
                data={data}
                id={id}
                cic={cic}
                handleChangeInputCic={handleChangeInputCic}
                ocr={ocr}
                uuid={uuid}
                handleChangeInputOcr={handleChangeInputOcr}
                voterKey={voterKey}
                handleChangeInputVoterKey={handleChangeInputVoterKey}
                numEmision={numEmision}
                handleChangeInputNumEmision={handleChangeInputNumEmision}
                ciudadano={ciudadano}
                handleChangeInputCiudadano={handleChangeInputCiudadano}
                ineStatus={isIneVerified}
                //ineStatus={false}
                setIsIneVerified={setIsIneVerified}
                dataIneFound={
                  isIneVerified
                    ? findLast(data.ineVerification, 'uuid')?.data ||
                      data?.ineVerification[0]
                    : false
                }
                onVerifyListaNominal={onVerifyListaNominal}
                isVotingCardC={isVotingCardC}
              />
            </Segment>
          </div>
        )
      ) : null}
      {isRenapoAllowed ? (
        <div>
          <Header attached="top" className="AdditionalVerifications-header">
            <h3>Consulta a RENAPO</h3>
            {isResetAllowed && !openInModal ? (
              <Tooltip
                placement="top"
                title={messageRenapo.msg}
                visible={messageRenapo.visible}
                zIndex={9}
              >
                <button
                  type="button"
                  className="reset_btn"
                  onClick={() => handleResetRenapo()}
                >
                  <VscDebugRestart size={19} />
                </button>
              </Tooltip>
            ) : null}
          </Header>
          <Segment attached>
            <CardRenapo
              curp={curp}
              id={id}
              uuid={uuid}
              renapoStatus={isRenapoVerified}
              handleChangeInputCurp={handleChangeInputCurp}
              setIsRenapoVerified={setIsRenapoVerified}
              dataRenapoFound={isRenapoVerified ? data.curpVerification : false}
              onVerifyRenapo={onVerifyRenapo}
              //Controlar el ingreso de datos en el input curp
              classErrorCurpInputRenapo={classErrorCurpInputRenapo}
              visibleErrorCurpInputRenapo={visibleErrorCurpInputRenapo}
              messageErrorCurpInputRenapo={messageErrorCurpInputRenapo}
            />
          </Segment>
        </div>
      ) : null}
      {isOcrAddressAllowed ? (
        <div>
          <Header attached="top" className="AdditionalVerifications-header">
            <h3>{t('proofAddress')}</h3>
            {isResetAllowed && !openInModal ? (
              <Tooltip
                placement="top"
                title={messageOcrAddress.msg}
                visible={messageOcrAddress.visible}
                zIndex={9}
              >
                <button
                  type="button"
                  className="reset_btn"
                  onClick={() => handleResetOcrAddress()}
                >
                  <VscDebugRestart size={19} />
                </button>
              </Tooltip>
            ) : null}
          </Header>
          <Segment attached>
            <CardOcrAddress
              id={id}
              uuid={uuid}
              ocrAddressStatus={isOcrAddressVerified}
              setIsOcrAddressVerified={setIsOcrAddressVerified}
              dataOcrAddressFound={
                isOcrAddressVerified ? data.ocrAddress : false
              }
              onVerifyOcrAddress={onVerifyOcrAddress}
            />
          </Segment>
        </div>
      ) : null}
      {isBlackList ? (
        isControlListFailed ? (
          <div>
            <Header attached="top" className="AdditionalVerifications-header">
              <h3>{t('sanctionLists')}</h3>
            </Header>
            <Segment attached>
              <CardControlListCollapse dataControlList={controlList} />
            </Segment>
          </div>
        ) : (
          <div>
            <Header attached="top" className="AdditionalVerifications-header">
              <h3>{t('sanctionLists')}</h3>
            </Header>
            <Segment attached>
              <CardControlList dataControlList={controlList} />
            </Segment>
          </div>
        )
      ) : null}
      {isCourtAllowed ? (
        <div>
          <Header attached="top" className="AdditionalVerifications-header">
            <h3>{t('criminalRecords')}</h3>
            {isResetAllowed && !openInModal ? (
              <Tooltip
                placement="top"
                title={messageLegalRecord.msg}
                visible={messageLegalRecord.visible}
                zIndex={9}
              >
                <button
                  type="button"
                  className="reset_btn"
                  onClick={() => handleResetLegalRecord()}
                >
                  <VscDebugRestart size={19} />
                </button>
              </Tooltip>
            ) : null}
          </Header>
          <Segment attached>
            <AntecedentesPenalesV2
              dataVerification={idVerification}
              givenname={givenName}
              surname={surName}
              id={id}
              uuid={uuid}
              courtStatus={isCourtVerified}
              handleChangeInputName={handleChangeInputNameAntecedentesPenales}
              handleChangeInputSurname={
                handleChangeInputSurnameAntecedentesPenales
              }
              setIsCourtVerified={setIsCourtVerified}
              dataCourtFound={isCourtVerified ? data?.courtRecord : false}
              onVerifyCourtRecord={onVerifyCourtRecord}
              //Controlar el ingreso de datos en el input name y surname
              classErrorNameInputAntecedentes={classErrorNameInputAntecedentes}
              visibleErrorNameInputAntecedentes={
                visibleErrorNameInputAntecedentes
              }
              messageErrorNameInputAntecedentes={
                messageErrorNameInputAntecedentes
              }
              classErrorSurnameInputAntecedentes={
                classErrorSurnameInputAntecedentes
              }
              visibleErrorSurnameInputAntecedentes={
                visibleErrorSurnameInputAntecedentes
              }
              messageErrorSurnameInputAntecedentes={
                messageErrorSurnameInputAntecedentes
              }
            />
          </Segment>
        </div>
      ) : null}
      {isEmailAllowed ? (
        <div>
          <Header attached="top" className="AdditionalVerifications-header">
            <h3>{t('emailTitle')}</h3>
            {isResetAllowed && !openInModal ? (
              <Tooltip
                placement="top"
                title={messageEmail.msg}
                visible={messageEmail.visible}
                zIndex={9}
              >
                <button
                  type="button"
                  className="reset_btn"
                  onClick={() => handleResetEmail()}
                >
                  <VscDebugRestart size={19} />
                </button>
              </Tooltip>
            ) : null}
          </Header>
          <Segment attached>
            <CardEmail
              id={id}
              uuid={uuid}
              email={email}
              emailStatus={isEmailVerified}
              handleChangeInputEmail={handleChangeInputEmail}
              onVerifyEmail={onVerifyEmail}
              setIsEmailVerified={setIsEmailVerified}
              dataEmailFound={isEmailVerified ? data.emailVerification : false}
            />
          </Segment>
        </div>
      ) : null}
      {isPhoneAllowed ? (
        <div>
          <Header attached="top" className="AdditionalVerifications-header">
            <h3>{t('phoneTitle')}</h3>
            {isResetAllowed && !openInModal ? (
              <Tooltip
                placement="top"
                title={messagePhone.msg}
                visible={messagePhone.visible}
                zIndex={9}
              >
                <button
                  type="button"
                  className="reset_btn"
                  onClick={() => handleResetPhone()}
                >
                  <VscDebugRestart size={19} />
                </button>
              </Tooltip>
            ) : null}
          </Header>
          <Segment attached>
            <CardPhone
              id={id}
              uuid={uuid}
              phone={phone}
              phoneStatus={isPhoneVerified}
              handleChangeInputPhone={handleChangeInputPhone}
              onVerifyPhone={onVerifyPhone}
              setIsPhoneVerified={setIsPhoneVerified}
              dataPhoneFound={isPhoneVerified ? data.phoneVerification : false}
            />
          </Segment>
        </div>
      ) : null}
      {isIpAllowed ? (
        <div>
          <Header attached="top" className="AdditionalVerifications-header">
            <h3>{t('ipTitle')}</h3>
            {isResetAllowed && !openInModal ? (
              <Tooltip
                placement="top"
                title={messageIp.msg}
                visible={messageIp.visible}
                zIndex={9}
              >
                <button
                  type="button"
                  className="reset_btn"
                  onClick={() => handleResetIp()}
                >
                  <VscDebugRestart size={19} />
                </button>
              </Tooltip>
            ) : null}
          </Header>
          <Segment attached>
            <CardIp
              id={id}
              uuid={uuid}
              ip={ip}
              ipStatus={isIpVerified} //Aqui se le pone el isIpVerified para que muestre un CardIp si es false y si es true muestra CardIpWithInfo
              handleChangeInputIp={handleChangeInputIp}
              onVerifyIp={onVerifyIp}
              setIsIpVerified={setIsIpVerified}
              dataIpFound={isIpVerified ? data.ipVerification : false}
              openInModal={openInModal}
            />
          </Segment>
        </div>
      ) : null}
      {isRfcAllowed ? (
        <div>
          <Header attached="top" className="AdditionalVerifications-header">
            <h3>{t('rfcTitle')}</h3>
            {isResetAllowed && !openInModal ? (
              <Tooltip
                placement="top"
                title={messageRfc.msg}
                visible={messageRfc.visible}
                zIndex={9}
              >
                <button
                  type="button"
                  className="reset_btn"
                  onClick={() => handleResetRfc()}
                >
                  <VscDebugRestart size={19} />
                </button>
              </Tooltip>
            ) : null}
          </Header>
          <Segment attached>
            <CardRFC
              id={id}
              uuid={uuid}
              rfc={rfcUser}
              rfcStatus={isRfcVerified}
              handleChangeInputRFC={handleChangeInputRFC}
              onVerifyRfc={onVerifyRfc}
              setIsRfcVerified={setIsRfcVerified}
              dataRfcFound={isRfcVerified ? data.rfcValidation : false}
            />
          </Segment>
        </div>
      ) : null}
      {isImssAllowed ? (
        <div>
          <Header attached="top" className="AdditionalVerifications-header">
            <h3>{t('imssTitle')}</h3>
            {isResetAllowed && !openInModal ? (
              <Tooltip
                placement="top"
                title={messageHistorialImss.msg}
                visible={messageHistorialImss.visible}
                zIndex={9}
              >
                <button
                  type="button"
                  className="reset_btn"
                  onClick={() => handleResetHistorialImss()}
                >
                  <VscDebugRestart size={19} />
                </button>
              </Tooltip>
            ) : null}
          </Header>
          <Segment attached>
            <CardIMSS
              id={id}
              uuid={uuid}
              curp={curpIMSS}
              imssStatus={isImssVerified}
              handleChangeInputImssforCurp={handleChangeInputImssforCurp}
              onVerifyImss={onVerifyImss}
              setIsImssVerified={setIsImssVerified}
              dataImssFound={isImssVerified ? data.historialLaboralImss : false}
              dataNssFoundByImss={isImssVerified ? data.nssGet : ''}
              //Controlar el ingreso de datos en el input curp
              classErrorCurpInputImss={classErrorCurpInputImss}
              visibleErrorCurpInputImss={visibleErrorCurpInputImss}
              messageErrorCurpInputImss={messageErrorCurpInputImss}
            />
          </Segment>
        </div>
      ) : null}
      {isList69Allowed ? (
        <div>
          <Header attached="top" className="AdditionalVerifications-header">
            <h3>{t('list69')}</h3>
            {isResetAllowed && !openInModal ? (
              <Tooltip
                placement="top"
                title={messageList69.msg}
                visible={messageList69.visible}
                zIndex={9}
              >
                <button
                  type="button"
                  className="reset_btn"
                  onClick={() => handleResetList69()}
                >
                  <VscDebugRestart size={19} />
                </button>
              </Tooltip>
            ) : null}
          </Header>
          <Segment attached>
            <CardList69
              id={id}
              uuid={uuid}
              rfcList69={rfcList69}
              lis69Status={isList69Verified}
              // lis69Status={true}
              handleChangeInputRFCList69={handleChangeInputRFCList69}
              onVerifyList69={onVerifyList69}
              setIsList69Verified={setIsList69Verified}
              dataList69Found={isList69Verified ? data?.list69BY69 : false}
            />
          </Segment>
        </div>
      ) : null}
    </Masonry>
  )
}

export default AdditionalVerifications
