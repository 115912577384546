import React, { useContext, useState } from 'react'
//import { Input, Button } from 'semantic-ui-react'
import { Input as SearchUser } from 'antd'
import { useTranslation } from 'react-i18next'
import RecordsFilters from '../context/RecordsFilters.context'
import '../styles/RegisterFilters.css'

const RegisterFilters = () => {
  const { isSearching, setId, search } = useContext(RecordsFilters)
  const [value, setValue] = useState('')
  //const [visible, setVisible] = useState(false)

  const { t } = useTranslation()

  const onChange = (e) => {
    //console.log(e.target.value)

    if (
      e.target.value === '' ||
      e.target.value === undefined ||
      e.target.value === null
    ) {
      setId('')
      setValue('')
      // console.log('Debes ingresar un texto para buscar')
    } else {
      setValue(e?.target?.value)
    }
  }
  const onClick = () => {
    // console.log(value)
    if (value === '') {
      console.log('Debes ingresar un valor')
    } else {
      setId(value)
      search()
    }
  }

  /* const clearFilters = () => {
    setId('')
    setValue('')
  } */
  return (
    <div className="RegisterFilters">
      {/* <div className="RegisterFiltersInput">
        <Input
          action={{ icon: 'search', onClick: onClick }}
          placeholder={t('searchId')}
          loading={isSearching}
          onChange={onChange}
          value={value}
        />
      </div>
      <div>
        <Button
          icon="cancel"
          content={t('clean')}
          labelPosition="left"
          onClick={clearFilters}
        />
      </div> */}

      {/* Search input */}
      <SearchUser.Search
        allowClear
        placeholder={`${t('searchPlaceholder')}`}
        size="large"
        loading={isSearching}
        bordered
        enterButton
        onChange={onChange}
        onSearch={(value, onClick)}
        //value={value}
      />
    </div>
  )
}

export default RegisterFilters
