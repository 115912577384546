import React, { Fragment, useState } from 'react'
import { Button, Input, Transition, Label } from 'semantic-ui-react'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'

import '../ConsultationServices.css'
import CardPhoneWithInfo from './CardPhoneWithInfo'
import useToken from '../../../utils/useToken'
import { API_URL } from '../../../const/index'
import { useAuth0 } from '../../../react-auth0-spa'
import {
  countryCodeOptions,
  initialCountryCode,
} from '../../../fixtures/consultationServices'

const PHONE = ({
  id,
  uuid,
  phone,
  phoneStatus,
  dataPhoneFound,
  setIsPhoneVerified,
  handleChangeInputPhone,
  onVerifyPhone,
}) => {
  const [dataPhone, setDataPhone] = useState(null)
  const [countryCode, setCountryCode] = useState(initialCountryCode)
  const [verificando, setVerificando] = useState(false)

  //Control de errores
  const [inputError, setInputError] = useState(false)
  const [inputMsgErr, setInputMsgErr] = useState('')

  const { t } = useTranslation()
  const { user } = useAuth0()
  const getToken = useToken()

  const callToPhoneApi = async () => {
    setVerificando(true)
    try {
      const token = await getToken({
        audience: 'veridocid',
      })

      const phoneComplete = countryCode?.value + phone

      const response = await fetch(`${API_URL}/social/checkPhone`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
          uuid,
          phone: parseInt(phoneComplete),
          name: user.name,
          email: user.email,
        }),
      })

      if (response.ok) {
        const responseJSON = await response.json()
        if (responseJSON?.success === true) {
          onVerifyPhone(responseJSON)
          setDataPhone(responseJSON)
          setIsPhoneVerified(true)
        } else {
          setIsPhoneVerified(false)
          //Error
          setInputError(true)
          setInputMsgErr(responseJSON?.error?.message)
          setTimeout(() => {
            setInputError(false)
          }, 3000)
        }
      } else {
        setInputError(true)
        setInputMsgErr(`${t('invalidPhoneFormat')}`)
        setTimeout(() => {
          setInputError(false)
        }, 3000)
        //console.log(`La respuesta de la API Phone no se encontro`)
      }

      setVerificando(false)
    } catch (error) {
      console.log(`La llamada a la API de Phone fallo`)
      setVerificando(false)
    }
  }

  const handleClick = () => {
    if (phone === '') {
      setInputError(true)
      setInputMsgErr(`${t('messageErrorEmptyInputPhone')}`)
      setTimeout(() => {
        setInputError(false)
      }, 3000)
    } else {
      callToPhoneApi()
    }
  }

  const onCountryCodeChangeHandler = (countryCodeSelected) => {
    setCountryCode(countryCodeSelected)
  }

  return (
    <Fragment>
      <div className="ui container">
        {phoneStatus ? (
          <CardPhoneWithInfo
            dataPhone={dataPhoneFound?.data || dataPhone?.data}
          />
        ) : (
          <>
            <p>{t('phone')}</p>
            <div className="CardPhoneForm">
              <Select
                options={countryCodeOptions}
                className="CardPhoneSelect"
                value={countryCode}
                onChange={(countryCodeSelected) =>
                  onCountryCodeChangeHandler(countryCodeSelected)
                }
              />
              <Input
                placeholder="xxxxxxxx"
                value={phone}
                onChange={handleChangeInputPhone}
                className="CardPhoneInput"
              />
            </div>
            <Transition animation="drop" duration={500} visible={inputError}>
              <Label
                pointing
                color="red"
                as="span"
                style={{
                  lineHeight: '15px',
                  left: 'calc(100% - 270px)',
                  marginBottom: '1rem',
                }}
              >
                {`${inputMsgErr}`}
              </Label>
            </Transition>
            <br />
            <div className="ButtonsCard">
              <Button
                onClick={handleClick}
                disabled={verificando}
                loading={verificando}
              >
                {t('send')}
              </Button>
            </div>
          </>
        )}
      </div>
    </Fragment>
  )
}

export default PHONE
