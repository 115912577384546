import React from 'react'

/**
 * Map options
 * @param {*} arr options to map
 */
export const mapOptions = (arr) =>
  arr?.map((opt) => (
    <option key={opt?.id || opt?.value} value={opt?.value || opt?.name}>
      {opt?.name}
    </option>
  ))
