import React from 'react'
import { getDate, getTime } from '../../../utils/getDate'

export const DateSignContract = ({ data, t }) => (
  <div className="content_date_sign content_data_panel">
    <h4 className="title_panel_custom">{t('contract_data__date_sign')}</h4>
    {data?.participants && data?.participants.length !== 0 ? (
      data?.participants.map((signatory, i) => (
        <p key={i}>
          {signatory.status === 'assigned'
            ? '-'
            : `${getDate(signatory?.updatedAt)} ${getTime(
                signatory?.updatedAt
              )}`}
        </p>
      ))
    ) : (
      <p>Termina de configurar tu contrato</p>
    )}
  </div>
)
