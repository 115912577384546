import React, { Fragment, useState } from 'react'
import { Button, Form, Label, Transition, Message } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

import '../ConsultationServices.css'
import CardImssWithInfo from './CardImssWithInfo.jsx'
import useToken from '../../../utils/useToken'
import { API_URL } from '../../../const/index'
import { useAuth0 } from '../../../react-auth0-spa'
import MaskedInput from 'react-text-mask'

const IMSS = ({
  id,
  uuid,
  curp,
  imssStatus,
  dataImssFound,
  dataNssFoundByImss,
  setIsImssVerified,
  handleChangeInputImssforCurp,
  onVerifyImss,
  //Control de errores cuando se refleja cambios en el ingreso de datos en el input curp
  classErrorCurpInputImss,
  visibleErrorCurpInputImss,
  messageErrorCurpInputImss,
}) => {
  const [dataImss, setDataImss] = useState(null)
  const [obtainNSS, setObtainNSS] = useState(null)
  const [verificando, setVerificando] = useState(false)
  //Control de errores de la API
  const [visibleApiErros, setVisibleApiErrors] = useState(false)
  const [apiErrorMsg, setApiErrorMsg] = useState('')
  const [classErrorInput, setClassErrorInput] = useState('')
  //Disabled input
  const [disabledInput, setDisabledInput] = useState(false)
  const { t } = useTranslation()
  const { user } = useAuth0()
  const getToken = useToken()

  //Call Api NSS
  const callToVerifyIMSS = async () => {
    setVerificando(true)
    setDisabledInput(true)
    try {
      const token = await getToken({
        audience: 'veridocid',
      })

      const response = await fetch(`${API_URL}/gov/nss`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
          curp,
          uuid,
          name: user.name,
          email: user.email,
        }),
      })

      if (response.ok) {
        const reponseJSON = await response.json()

        if (reponseJSON?.estatus === 'OK') {
          //Control de errores
          setClassErrorInput('')
          setVisibleApiErrors(false)
          setObtainNSS(reponseJSON.nss)
          // Call API imss
          verifyImss(reponseJSON.nss)
        } else {
          setIsImssVerified(false)
          setClassErrorInput('error')
          if (reponseJSON?.claveMensaje === '4') {
            setClassErrorInput('error')
            setVisibleApiErrors(true)
            setApiErrorMsg(`${t('endpointMessageErrorCURP')}`)
          } else if (reponseJSON?.claveMensaje === '2') {
            setClassErrorInput('error')
            setVisibleApiErrors(true)
            setApiErrorMsg(reponseJSON?.mensaje)
          } else {
            setClassErrorInput('error')
            setVisibleApiErrors(true)
            setApiErrorMsg(reponseJSON?.mensaje)
          }
          setVerificando(false)
          setDisabledInput(false)
        }
      } else {
        console.log(`La respuesta de la API del IMSS no se encontro`)
      }

      //setVerificando(false)
    } catch (error) {
      setVisibleApiErrors(true)
      setApiErrorMsg(`${t('messageErrorData')}`)
      //setVerificando(false)
    }
  }

  // Api IMSS
  const verifyImss = async (nss) => {
    setVerificando(true)
    setDisabledInput(true)

    try {
      const token = await getToken({
        audience: 'veridocid',
      })

      const responseImss = await fetch(`${API_URL}/gov/imss`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
          curp,
          nss,
          uuid,
          name: user.name,
          email: user.email,
        }),
      })

      if (responseImss.ok) {
        const responseJSON = await responseImss.json()

        if (responseJSON?.estatus === 'OK') {
          onVerifyImss(responseJSON)
          setDataImss(responseJSON)
          setIsImssVerified(true)
        } else {
          setIsImssVerified(false)
          setClassErrorInput('error')
          setVisibleApiErrors(true)
          setApiErrorMsg(responseJSON?.mensaje)
        }
      }
      // Visible - loading button and disabled input
      setVerificando(false)
      setDisabledInput(false)
    } catch (error) {
      setVerificando(false)
      setDisabledInput(false)
      // Errors
      setVisibleApiErrors(true)
      setApiErrorMsg(`${t('messageErrorData')}`)
    }
  }

  // Call API to verify IMSS and NSS
  const handleClick = () => {
    if (curp === '' || curp === undefined) {
      setClassErrorInput('error')
      setVisibleApiErrors(true)
      setApiErrorMsg(`${t('msgInvalidCurp')}`)
    } else if (visibleErrorCurpInputImss) {
      setVisibleApiErrors(false)
    } else {
      //Function
      callToVerifyIMSS()
    }
  }

  const changeStateVisibleMsgErrorApi = () => {
    setVisibleApiErrors(false)
    setClassErrorInput('')
  }

  return (
    <Fragment>
      <div className="ui container">
        {imssStatus ? (
          // <CardIMSSWithInfo dataImss={dataImssFound || dataImss} />
          <CardImssWithInfo
            dataImss={dataImssFound || dataImss}
            nss={dataNssFoundByImss?.nss || obtainNSS}
          />
        ) : (
          <>
            <Form error={visibleApiErros}>
              <Form.Field>
                <p>{t('curp')}</p>
                <div
                  className={`ui fluid input ${
                    classErrorCurpInputImss || classErrorInput
                  }`}
                >
                  <MaskedInput
                    mask={[
                      /[a-zA-Z]/,
                      /[a-zA-Z]/,
                      /[a-zA-Z]/,
                      /[a-zA-Z]/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /[a-zA-Z]/,
                      /[a-zA-Z]/,
                      /[a-zA-Z]/,
                      /[a-zA-Z]/,
                      /[a-zA-Z]/,
                      /[a-zA-Z]/,
                      /[a-zA-Z0-9]/,
                      /[a-zA-Z0-9]/,
                    ]}
                    keepCharPositions={true}
                    guide={false}
                    value={curp}
                    placeholder="CURP"
                    onChange={handleChangeInputImssforCurp}
                    disabled={disabledInput}
                    style={{ textTransform: 'uppercase' }}
                  />
                </div>

                {/* Mensage de error cada vez que ingrese un CURP incorrecto */}
                <Transition
                  //Error control
                  animation="drop"
                  duration={500}
                  visible={visibleErrorCurpInputImss}
                >
                  <Label
                    pointing
                    color="red"
                    as="span"
                    style={{ lineHeight: '15px' }}
                  >
                    {`${messageErrorCurpInputImss}`}
                  </Label>
                </Transition>
              </Form.Field>
              {/*Mensajes de error de la API  */}
              <Message
                error
                onDismiss={changeStateVisibleMsgErrorApi}
                header={'NSS e IMSS'}
                content={apiErrorMsg}
                id="my_msg"
              />{' '}
            </Form>
            <br />
            <div className="ButtonsCard">
              <Button
                onClick={handleClick}
                disabled={verificando}
                loading={verificando}
              >
                {t('send')}
              </Button>
            </div>
          </>
        )}
      </div>
    </Fragment>
  )
}

export default IMSS
