import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { find } from 'lodash'
import {
  responseVersion1,
  responseVersion2,
} from '../../fixtures/dataResponse.fixture'
import { Loader } from 'semantic-ui-react'
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
} from '@mui/material'
import { format } from 'date-fns'
import { VscArrowDown, VscArrowUp, VscCheck, VscClose } from 'react-icons/vsc'

export default function RegistersMovile(props) {
  const { register, selected, setSelected } = props
  const { t } = useTranslation()
  const history = useHistory()

  const renderRegistrosMovile = (registro, index) => {
    let types
    let fullName

    if (Number(registro?.dataVersion) >= 2) types = responseVersion2
    else types = responseVersion1

    if (!registro?.data?.documentData) {
      const failedName =
        registro?.data?.status === 'Checked' ? 'Sin Datos' : 'Verificando'
      fullName = registro?.data?.fullName ?? failedName
    } else {
      const fullNameMrz = find(registro?.data?.documentData, {
        type: types.fullName,
      })?.value

      let name = find(registro?.data?.documentData, {
        type: types.name,
        source: types.visual,
      })?.value?.replace(/[\^]/g, ' ')

      let fatherSurname = find(registro?.data?.documentData, {
        type: types.fatherSurname,
      })?.value

      let motherSurname = find(registro?.data?.documentData, {
        type: types.motherSurname,
      })?.value

      fullName =
        find(registro?.data?.documentData, {
          type: types.fullName,
          source: types.visual,
        })?.value?.replace(/[\^]/g, ' ') || null

      if (!registro?.data) fullName = t('checking')
      else {
        if (fatherSurname && motherSurname && name && !fullName)
          fullName = `${fatherSurname} ${motherSurname} ${name}`
        else if (!fullName && !fullNameMrz) {
          const documentHolderFullName = find(registro?.data?.documentData, {
            type: 'DocumentHolderFullName',
          })?.value
          const surname = find(registro?.data?.documentData, {
            type: 'Surname',
          })?.value
          const name = find(registro?.data?.documentData, {
            type: types.name,
          })?.value
          const fallbackName =
            name && surname
              ? `${surname} ${name}`
              : 'El documento no es legible'

          fullName = documentHolderFullName || fallbackName
        } else if (!fullName) fullName = fullNameMrz
      }
    }

    const handleClick = () => history.push(`/resultados/${registro?.uuid}`)

    let applyStyles = {
      backgroundColor:
        !registro?.data || registro?.status !== 'Checked'
          ? '#fdf8dc'
          : registro?.data?.globalResult === 'Ok'
          ? '#fcfff5'
          : '#fff6f6',
      color:
        !registro?.data || registro?.status !== 'Checked'
          ? '#573a08'
          : registro?.data?.globalResult === 'Ok'
          ? '#2c662d'
          : '#9f3a38',
    }
    return (
      <TableRow key={index} style={{ cursor: 'pointer' }}>
        <TableCell
          className="body_cell_table_custom"
          onClick={!registro.data ? null : handleClick}
          style={applyStyles}
        >
          {registro?.data?.startDateUtc ? (
            <>
              {format(new Date(registro?.data?.startDateUtc), 'dd/MM/yyyy')}{' '}
              {format(new Date(registro?.data?.startDateUtc), 'HH:mm:ss')}
            </>
          ) : (
            t('checking')
          )}
        </TableCell>
        <TableCell
          className="body_cell_table_custom"
          onClick={
            !registro.data || registro?.status !== 'Checked'
              ? null
              : handleClick
          }
          style={applyStyles}
        >
          {fullName}
        </TableCell>
        <TableCell
          className="body_cell_table_custom"
          onClick={
            !registro.data || registro?.status !== 'Checked'
              ? null
              : handleClick
          }
          style={applyStyles}
        >
          {!registro?.data || registro?.status !== 'Checked' ? (
            <Loader
              indeterminate
              active
              inline
              style={{ backgroundColor: 'rgba(0,0,0,0)' }}
              size="small"
            />
          ) : registro?.data?.globalResult === 'Ok' ? (
            <VscCheck size={18} />
          ) : (
            <VscClose size={18} />
          )}
        </TableCell>
      </TableRow>
    )
  }

  const selectCreatedAtD = () =>
    setSelected({
      createdAtD: true,
      createdAtA: false,
      idD: false,
      idA: false,
    })

  const selectCreatedAtA = () =>
    setSelected({
      createdAtD: false,
      createdAtA: true,
      idD: false,
      idA: false,
    })

  return (
    <>
      <TableContainer>
        <Table aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell
                className="head_table_custom"
                onClick={
                  selected.createdAtD
                    ? selectCreatedAtA
                    : selected.createdAtA
                    ? selectCreatedAtD
                    : selectCreatedAtD
                }
              >
                {t('date')}{' '}
                {selected.createdAtD ? (
                  <VscArrowDown size={13} strokeWidth={1} cursor="pointer" />
                ) : selected.createdAtA ? (
                  <VscArrowUp size={13} strokeWidth={1} cursor="pointer" />
                ) : null}
              </TableCell>
              <TableCell className="head_table_custom">{t('name')}</TableCell>
              <TableCell className="head_table_custom">
                {t('idResult')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{register.map(renderRegistrosMovile)}</TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
