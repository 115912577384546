import React from 'react'
import { useTranslation } from 'react-i18next'
import ContractsTablev2 from '../components/ContractsDashboard/ContractsTablev2'

const ContractsAllDataShow = () => {
  const { t } = useTranslation()

  return (
    <>
      <h1
        style={{
          display: 'inline',
          color: 'rgb(68, 68, 68)',
          fontSize: 'clamp(1.78em, 1.82em, 1.8em)',
        }}
      >
        {t('documents')}
      </h1>
      <ContractsTablev2 />
    </>
  )
}

export default ContractsAllDataShow
