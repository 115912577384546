import React, { useState, useEffect } from 'react'
import { Grid, Button } from '@material-ui/core'
import { v4 as uuidv4 } from 'uuid'
// Components and utils
import {
  Collapse,
  Button as ActionConfig,
  Radio,
  Switch,
  message,
  Tooltip,
} from 'antd'
import { useStyles } from '../../styles/PDFSignatureV2'
import {
  VscArrowLeft,
  VscArrowRight,
  VscZoomIn,
  VscZoomOut,
  VscInfo,
} from 'react-icons/vsc'
import useToken from '../../utils/useToken'
import { API_URL_V3 } from '../../const/index'
import { CountryDropdown } from 'react-country-region-selector'
import { useHistory } from 'react-router'
import ModalSign from '../Signature/PDFSignatureNC/Modal/ModalSign'
import { fixedSignatory, getIP, getIPOption2 } from '../../utils/signContract'
import { getVerificationSigner } from '../../utils/getDataCustomize'

//SDK
import { WebVerification } from 'vdid-sdk-web'
import { API_DASHBOARD_URL } from '../../const/index.js'

// Config
import ConfigClienteSign from '../Signature/GeneralConfigurationSign/ConfigClienteSign'
import { Document, Page, pdfjs } from 'react-pdf'
import { Sortable } from '@shopify/draggable'
import boxSign from '../../assets/caja firma.svg'
import _, { isArray, isEmpty } from 'lodash'
import { Rubric } from '../Signature/PDFSignatureNC/ConfigurationsPDF/Sign/SignStyle'
// import { SignersCardInput } from '../Signature/PDFSignatureNC/ConfigurationsPDF/Signers/SignersStyle'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const PDFEditClient = ({ fileBase64, uuid, idClient, useAuth0, file }) => {
  const classes = useStyles()
  const { Panel } = Collapse
  const { replace } = useHistory()
  // Token
  const getToken = useToken()

  const [rubric, setRubric] = useState(false)
  const [page, setPageNumber] = useState(1)
  const [numPages, setNumPages] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [pageSize, setPageSize] = useState([612, 792])
  const [signers, setSigners] = useState([])
  const [observers, setObservers] = useState([])
  const [signPositions, setSignPositions] = useState([])
  const [currentDrop, setCurrentDrop] = useState([])
  const [currentSelect, setCurrentSelect] = useState('')
  const [currentDelete, setCurrentDelete] = useState([])
  const [currentDeleteObserver, setCurrentDeleteObserver] = useState([])
  const [eventDD, setEventDD] = useState(false)
  const [sortSigners, setSortSigners] = useState(null)
  const [sortStatus, setSortStatus] = useState(false)
  const [currentVerification, setCurrentVerification] = useState([]) //Select the biometrics option
  // const [whoSign, setWhoSign] = useState('others')

  // Signatories
  const [dataSearchUUID, setDataSearchUUID] = useState('')
  const [dataNameSignatory, setDataNameSignatory] = useState('')
  const [dataEmailSignatory, setDataEmailSignatory] = useState('')
  const [dataCheckCreateVerification, setDataCheckCreateVerification] =
    useState(false)
  /* const [selectBiometricSignatory, setSelectBiometricSignatory] =
    useState(false) */
  const [stateError, setStateError] = useState({
    error_state: 'none',
    error_content: '',
  })

  const [loadingCreateVerification, setLoadingCreateVerification] =
    useState(false)

  // If to search uuid
  const [nameFound, setNameFound] = useState('')
  const [uuidFound, setUuidFound] = useState('')

  // Observer
  const [dataNameObserver, setDataNameObserver] = useState('')
  const [dataEmailObserver, setDataEmailObserver] = useState('')
  const [stateErrorObserver, setStateErrorObserver] = useState({
    error_state: 'none',
    error_content: '',
  })

  // Data form
  const [loadingFixedSignatory, setLoadingFixedSignatory] = useState(false)
  const [loadingSignatory, setLoadingSignatory] = useState(false)

  // Ip and geolocation (me_others)
  const [geolocation, setGeolocation] = useState('')
  const [stateGeolocation, setStateGeolocation] = useState(false)
  const [ip, setIp] = useState('')

  // Collapse
  const callback = (key) => {
    for (let option of key) {
      if (option === '3') handleComproveSigns()
    }
  }

  // Open modal - format signature
  const [open, setOpen] = useState(false)
  const [signMe, setSignMe] = useState({
    signature: '',
    currentDate: '',
    option: 1,
  })

  const handleShowFormatSign = () => setOpen(true)

  // Load success
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
    setPageNumber(1)
  }

  //METODOS PARA DESPLAZARSE O INTERACTUAR CON EL PDF---------------------------------------
  const changePage = (offset) =>
    setPageNumber((prevPageNumber) => prevPageNumber + offset)

  const previousPage = () => {
    if (page > 1) changePage(-1)
  }

  const nextPage = () => {
    if (page < numPages) changePage(1)
  }

  const zoomOut = () => {
    let newZoom = zoom - 0.1
    newZoom = newZoom.toFixed(1)
    newZoom = parseFloat(newZoom)

    if (newZoom >= 0.1) setZoom(newZoom)
  }

  const zoomIn = () => {
    let newZoom = zoom + 0.1
    newZoom = newZoom.toFixed(1)
    newZoom = parseFloat(newZoom)

    if (newZoom <= 2) setZoom(newZoom)
  }

  //METODOS PARA LAS POSICIONES DE LAS FIRMAS EN EL PDF------------------------------------
  const handleComproveSigns = () => {
    // Zona A (PDF)
    const dzone = document.getElementById('ddzone')
    if (dzone) {
      //Agrega una sola vez los eventos drop y dropover a la Zona A
      if (!eventDD) {
        dzone.addEventListener('dragover', dd_dragover, false)
        dzone.addEventListener('drop', dd_drop, false)
        setEventDD(true)
      }

      //Elimina los hijos ya existentes, Evita duplicados
      const tope = dzone.childElementCount
      for (let i = 0; i < tope; i++) {
        dzone.removeChild(dzone.firstChild)
      }

      //Comprueba cada uno de los firmantes
      for (let signer of signPositions) {
        //Si el firmante tiene una firma activa en esta hoja, creara su caja de firma
        if (signer.page === page) {
          let mainDiv = document.createElement('div')
          let leftDiv = document.createElement('div')
          let rightDiv = document.createElement('div')
          let rightDiv_Close = document.createElement('span')
          let rightText = document.createTextNode('×')
          let rightDiv_AddSign = document.createElement('div')
          let rightAddSign = document.createTextNode('+')
          let imgBox = document.createElement('img')
          let emailBox = document.createElement('div')
          let emailText = document.createTextNode(`${signer?.user?.email}`)
          // let dateBox = document.createElement('p')
          // let dateText = document.createTextNode(`${signer?.date}`)
          // Me and others
          let dateMeBox = document.createElement('p')
          let dateMeText = document.createTextNode(`${signMe?.currentDate}`)

          mainDiv.draggable = true
          mainDiv.classList = `${classes.mainDiv}`
          mainDiv.style.fontSize = `${10 * zoom}px`
          mainDiv.style.width = `${134 * zoom}px`
          mainDiv.style.height = `${73 * zoom}px`

          leftDiv.style.width = `${136 * zoom}px`
          leftDiv.style.height = `${75 * zoom}px`

          imgBox.src = boxSign
          imgBox.draggable = false
          imgBox.style.width = `${136 * zoom}px`
          imgBox.style.height = `${75 * zoom}px`
          imgBox.style.position = 'relative'

          emailBox.classList = `${classes.emailBox}`
          emailBox.style.width = `${119 * zoom}px`
          emailBox.style.height = `${73 * zoom}px`
          emailBox.style.display = 'flex'
          emailBox.style.flexDirection = 'column'
          emailBox.style.alignItems = 'center'
          emailBox.style.justifyContent = 'center'
          emailBox.appendChild(emailText)
          // emailBox.appendChild(dateText)

          leftDiv.appendChild(imgBox)
          leftDiv.appendChild(emailBox)

          rightDiv.classList = `${classes.rightDiv}`
          rightDiv.style.width = `${20 * zoom}px`
          rightDiv.style.height = `${73 * zoom}px`

          // Si yo voy a firmar el documento y ya seleccione un formato de firma se plasma la imagen de la firma
          if (
            signer?.user?.email === useAuth0.email &&
            signMe.signature !== ''
          ) {
            leftDiv.style.backgroundImage = `url(${signMe.signature})`
            leftDiv.style.backgroundPosition = 'center 5px'
            leftDiv.style.backgroundSize = `${
              signMe.option !== 3 ? 80 * zoom : 113 * zoom
            }px ${35 * zoom}px`
            leftDiv.style.backgroundRepeat = 'no-repeat'
            leftDiv.style.objectFit = 'contain'
            leftDiv.style.display = 'flex'
            leftDiv.style.alignItems = 'center'
            leftDiv.style.justifyContent = 'center'
            leftDiv.style.flexDirection = 'column'
            leftDiv.style.position = 'relative'
            leftDiv.style.margin = '0 auto'
            leftDiv.removeChild(emailBox)

            dateMeBox.style.position = 'absolute'
            dateMeBox.style.top = 'calc(100% - 30px)'

            dateMeBox.appendChild(dateMeText)
            leftDiv.appendChild(dateMeBox)
          }

          // Close signature
          rightDiv_Close.classList = `${classes.rightDiv_Close}`
          rightDiv_Close.style.width = `${20 * zoom}px`
          rightDiv_Close.style.height = `${20 * zoom}px`
          rightDiv_Close.style.fontSize = `${20 * zoom}px`
          rightDiv_Close.style.paddingLeft = `${0.5 * zoom}px`
          rightDiv_Close.style.paddingBottom = `${4 * zoom}px`
          rightDiv_Close.style.marginBottom = `${5 * zoom}px`

          rightDiv_Close.setAttribute(
            'id',
            `${signer?.user?.email}^${signer?.page}${signer?.coordinates.x}${signer?.coordinates.y}`
          )

          rightDiv_AddSign.classList = 'rightDiv_AddSign'
          rightDiv_AddSign.style.width = `${20 * zoom}px`
          rightDiv_AddSign.style.height = `${20 * zoom}px`
          rightDiv_AddSign.style.fontSize = `${20 * zoom}px`
          rightDiv_AddSign.style.paddingLeft = `${0.5 * zoom}px`
          rightDiv_AddSign.style.paddingBottom = `${4 * zoom}px`
          rightDiv_AddSign.style.marginBottom = `${5 * zoom}px`
          rightDiv_AddSign.setAttribute(
            'id',
            `${signer?.user?.email}%PXY%${signer?.page}-${signer?.coordinates.x}-${signer?.coordinates.y} `
          )

          rightDiv_Close.onclick = function (e) {
            let newA = []
            for (let signer of signPositions) {
              if (
                `${signer?.user?.email}^${signer?.page}${signer?.coordinates.x}${signer?.coordinates.y}` !==
                  e.target.id.trim() ||
                signer.page !== page
              )
                newA = [...newA, signer]
            }
            setSignPositions(newA)
            document.getElementById(`${e.target.id.trim()}`).remove()
          }
          rightDiv_AddSign.onclick = function (e) {
            let currentPosition
            for (let signer of signPositions) {
              if (
                `${signer?.user?.email}%PXY%${signer?.page}-${signer?.coordinates.x}-${signer?.coordinates.y}` ===
                e.target.id.trim()
              ) {
                currentPosition = signer
                break
              }
            }

            let newB = signPositions
            for (let count = 1; count <= numPages; count++) {
              if (count !== page)
                newB.push({
                  coordinates: currentPosition.coordinates,
                  page: count,
                  user: currentPosition.user,
                  zoom: currentPosition.zoom,
                })
            }

            setSignPositions(newB)
          }
          rightDiv_Close.appendChild(rightText)
          rightDiv_AddSign.appendChild(rightAddSign)

          rightDiv.appendChild(rightDiv_Close)
          rightDiv.appendChild(rightDiv_AddSign)

          mainDiv.appendChild(leftDiv)
          mainDiv.appendChild(rightDiv)

          mainDiv.setAttribute(
            'id',
            `${signer?.user?.email}^${signer?.page}${signer?.coordinates.x}${signer?.coordinates.y}`
          )

          // Drag the sign
          mainDiv.ondragstart = function (e) {
            setCurrentSelect(e.target.id)
          }

          //convierte las coordenadas a relacion 1/1 y las ajusta al zoom actual
          mainDiv.style.top =
            (signer?.coordinates?.y / signer?.zoom) * zoom + 'px'
          mainDiv.style.marginLeft =
            (signer?.coordinates?.x / signer?.zoom) * zoom + 'px'
          dzone.appendChild(mainDiv)
        }
      }

      //Zona B (Zona derecha)
      const bzone = document.getElementById('ddzoneB')

      if (bzone) {
        //Elimina los hijos ya existentes, Evita duplicados
        const topeB = bzone.childElementCount
        for (let i = 0; i < topeB; i++) {
          bzone.removeChild(bzone.firstChild)
        }

        //Por cada firmante en el arreglo de la zona B se creara su caja
        for (let signer of signers) {
          let newDivB = document.createElement('div')
          newDivB.classList = `${classes.taskB}`
          newDivB.style.width = `180px`
          newDivB.style.height = `85px`
          newDivB.style.fontSize = `clamp(0.86em, 0.89em, 0.95em)`
          newDivB.setAttribute('draggable', true)

          let newImg = document.createElement('img')
          newImg.src = boxSign
          newImg.height = 85
          newImg.width = 180
          newImg.style.position = 'absolute'
          newImg.style.zIndex = 0
          // newImg.style.backgroundColor = 'blue'
          newImg.setAttribute('draggable', false)
          newDivB.appendChild(newImg)

          let internalDiv = document.createElement('div')
          internalDiv.style.height = `85px`
          internalDiv.style.width = `180px`
          internalDiv.style.overflow = 'hidden'
          internalDiv.style.display = 'flex'
          internalDiv.style.flexDirection = 'column'
          internalDiv.style.flexWrap = 'wrap'
          internalDiv.style.justifyContent = 'center'
          internalDiv.style.alignItems = 'center'
          internalDiv.style.zIndex = 98
          // internalDiv.style.backgroundColor = 'green'
          let emailSign = document.createElement('span')
          let contentEmail = document.createTextNode(`${signer.email}`)
          emailSign.appendChild(contentEmail)

          let dateSign = document.createElement('span')
          let contentDate = document.createTextNode(`${signer.date}`)
          dateSign.appendChild(contentDate)

          internalDiv.appendChild(emailSign)
          internalDiv.appendChild(dateSign)
          newDivB.appendChild(internalDiv)

          newDivB.setAttribute('id', `${signer.email}`)

          bzone.appendChild(newDivB)
        }
      }

      // Zona SignMe
      const ddzoneTesting = document.getElementById('ddzoneTest')

      if (signMe.signature !== '') {
        //Elimina los hijos ya existentes, Evita duplicados
        const topeTest = ddzoneTesting.childElementCount
        for (let i = 0; i < topeTest; i++) {
          ddzoneTesting.removeChild(ddzoneTesting.firstChild)
        }

        let newDivSignMe = document.createElement('div')
        let imgBox = document.createElement('img')
        let internalDivSignMe = document.createElement('div')
        let dateDivSignMe = document.createElement('p')
        let dateTextSignMe = document.createTextNode(signMe.currentDate)

        newDivSignMe.style.width = `180px`
        newDivSignMe.style.height = `85px`
        newDivSignMe.style.cursor = 'pointer'
        newDivSignMe.style.fontSize = `clamp(0.86em, 0.89em, 0.95em)`
        newDivSignMe.setAttribute('draggable', true)

        imgBox.src = boxSign
        imgBox.draggable = false
        imgBox.style.width = `180px`
        imgBox.style.height = `85px`
        imgBox.style.position = 'absolute'

        internalDivSignMe.style.height = `85px`
        internalDivSignMe.style.width = `${
          signMe.signature === '' ? 113 : signMe.option !== 3 ? 80 : 113
        }px`
        internalDivSignMe.style.backgroundImage = `url(${signMe.signature})`
        internalDivSignMe.style.backgroundPosition = 'center 20px'
        internalDivSignMe.style.backgroundSize = 'contain'
        internalDivSignMe.style.backgroundRepeat = 'no-repeat'
        internalDivSignMe.style.objectFit = 'contain'
        internalDivSignMe.style.display = 'flex'
        internalDivSignMe.style.alignItems = 'center'
        internalDivSignMe.style.justifyContent = 'center'
        internalDivSignMe.style.flexDirection = 'column'
        internalDivSignMe.style.position = 'relative'
        internalDivSignMe.style.margin = '0 auto'

        dateDivSignMe.style.position = 'absolute'
        dateDivSignMe.style.top = 'calc(100% - 30px)'
        dateDivSignMe.appendChild(dateTextSignMe)
        internalDivSignMe.appendChild(dateDivSignMe)

        newDivSignMe.appendChild(imgBox)
        newDivSignMe.appendChild(internalDivSignMe)

        newDivSignMe.setAttribute('id', `${useAuth0.email}`)
        ddzoneTesting.appendChild(newDivSignMe)
      }
    }
  }

  useEffect(() => {
    handleComproveSigns()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signPositions, signers, signMe])

  //Se ejecuta cada que se interactua con el PDF
  const handleRenderSuccess = (pgd) => {
    let canvas = document.getElementById('pdf_renderer')
    canvas = canvas.firstChild
    canvas = canvas.firstChild
    canvas = canvas.firstChild
    setPageSize([canvas.style.width, canvas.style.height])

    handleComproveSigns()
  }

  /*-------------------------------------------- 
    DRAG AND DROP
  ----------------------------------------------*/
  const dd_dragstart = (e) => e.dataTransfer.setData('text/plain', e.target.id)

  const dd_dragover = (e) => e.preventDefault()

  const dd_drop = (e) => {
    e.preventDefault()
    const element = document.getElementById(e.dataTransfer.getData('text'))
    const eX = e.offsetX !== undefined ? e.offsetX : 0
    const eY = e.offsetY !== undefined ? e.offsetY : 0
    //Actualiza el arreglo con los datos actuales del elemento dropeado
    setCurrentDrop([{ element, eX, eY }])
  }

  useEffect(() => {
    //Recibe mediante el useEffect el dato actual a agregar
    const handleCurrentUpdate = (currentDrop) => {
      //Valida que haya un elemento valido arrastrable
      if (currentDrop.length !== 0 && currentDrop[0].element) {
        //ajust centra la caja para un mejor control del drop
        const adjustX = 77 * zoom
        const adjustY = 36.5 * zoom
        let codsX = currentDrop[0].eX - adjustX
        let codsY = currentDrop[0].eY - adjustY
        let actualPositions = signPositions

        if (currentSelect !== '') {
          let newA = []
          for (let signPosition of signPositions) {
            if (
              `${signPosition?.user?.email}^${signPosition?.page}${signPosition?.coordinates.x}${signPosition?.coordinates.y}` !==
              currentSelect
            )
              newA = [...newA, signPosition]
          }
          actualPositions = newA
          setCurrentSelect('')
        }

        let newA = []
        let email = currentDrop[0].element.id.trim()
        let splitEmailClient = email.split('^', 1)

        newA = [
          ...actualPositions,
          {
            user: { email: splitEmailClient[0] },
            coordinates: {
              x: codsX,
              y: codsY,
            },
            page,
            zoom,
          },
        ]

        setSignPositions(newA)
      }
    }

    handleCurrentUpdate(currentDrop)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDrop])

  /*-------------------------------------------- 
    ORDER SIGNATORIES
  ----------------------------------------------*/

  //Activa o desactiva el ordenado de firmantes
  const useOrder = (e) => {
    const czone = document.getElementById('orderSigners')
    if (!sortSigners) {
      const sortable = new Sortable(czone, {
        draggable: 'li',
      })
      setSortSigners(sortable)
      setSortStatus(e)
    } else {
      setSortStatus(e)
      sortSigners.destroy()
      setSortSigners(null)
    }
  }

  /*-------------------------------------------- 
    Create Verification (add new signer)
  ----------------------------------------------*/
  const createVerificationSigner = async ({
    idUser,
    optionSelfie,
    optionVerifyIp,
  }) => {
    setLoadingCreateVerification(true)
    try {
      const token = await getToken({
        audience: 'veridocid-dashboard',
      })

      const tokenID = await getToken({
        audience: 'veridocid',
      })

      const response = await fetch(
        `${API_DASHBOARD_URL}/vdid/createVerification`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: idUser,
            selfie: optionSelfie,
            verifyIp: optionVerifyIp,
            redirect_url: 'https://verificaciones.sumamexico.com/',
            token: `Bearer ${tokenID}`,
          }),
        }
      )

      if (response.ok) {
        const responseJSON = await response.json()

        //------------------------------------ Add new signer
        let addSigner = true

        for (let signer of signers) {
          if (signer.email === dataEmailSignatory) {
            setStateError({
              error_state: 'block',
              error_content:
                'Los correos de los firmantes deben de ser diferentes',
            })

            setTimeout(() => {
              setStateError({ error_state: 'none', error_content: '' })
            }, 2000)
            addSigner = false
            break
          }
        }

        if (addSigner) {
          setSigners([
            ...signers,
            {
              uuid: responseJSON.identifier,
              name: dataNameSignatory,
              email: dataEmailSignatory,
              biometric: false,
              sdk: true,
            },
          ])

          // Reset form
          setDataNameSignatory('')
          setDataEmailSignatory('')
          setDataCheckCreateVerification(false)

          // Comprove signs
          handleComproveSigns()

          //------------------------------------ Start SDK
          // console.log('uuid', responseJSON.identifier)
          const vdid = new WebVerification(responseJSON.apiKey)
          vdid.verifyIdentity(responseJSON.identifier)
          setLoadingCreateVerification(false)
        }

        setLoadingCreateVerification(false)
      }
    } catch (error) {
      setLoadingCreateVerification(false)
      console.log(error)
    }
  }

  const handleSubmitConfigModalCV = () => {
    //Generate id (create verification - cv)
    const idSignerCV = uuidv4()

    // console.log('id', idSignerCV)

    // Create verification and new signer
    createVerificationSigner({
      idUser: idSignerCV,
      optionSelfie: true,
      optionVerifyIp: false,
    })
  }
  /*-------------------------------------------- 
    ADD NEW SIGNATORY
  ----------------------------------------------*/
  // Add the new value
  useEffect(() => {
    if (!isEmpty(nameFound)) {
      setDataNameSignatory(nameFound)
    }
  }, [nameFound])

  const handleAddSigner = () => {
    if (dataNameSignatory === '' || dataEmailSignatory === '') {
      setStateError({
        error_state: 'block',
        error_content: 'Debes ingresar el nombre y/o correo',
      })

      setTimeout(() => {
        setStateError({ error_state: 'none', error_content: '' })
      }, 2000)
    } else if (signers.length > 0) {
      //----------------------------Ya que temporalmente no hay manera de relacionar a otro uuid, se limita a 1 firmante
      setStateError({
        error_state: 'block',
        error_content: 'Solo puedes agregar al firmante de la verificacion',
      })

      // Reset form
      setDataNameSignatory('')
      setDataEmailSignatory('')
      setDataSearchUUID('')
      setDataCheckCreateVerification(false)
      setLoadingCreateVerification(false)

      //Reset values
      setNameFound('')
      setUuidFound('')

      setTimeout(() => {
        setStateError({ error_state: 'none', error_content: '' })
      }, 2000)
      //----------------------------Ya que temporalmente no hay manera de relacionar a otro uuid, se limita a 1 firmante
    } else {
      let addSigner = true

      for (let signer of signers) {
        if (signer.email === dataEmailSignatory) {
          setStateError({
            error_state: 'block',
            error_content:
              'Los correos de los firmantes deben de ser diferentes',
          })

          setTimeout(() => {
            setStateError({ error_state: 'none', error_content: '' })
          }, 2000)
          addSigner = false
          break
        }
      }

      if (addSigner) {
        let addUuidSigner = ''
        if (!isEmpty(uuidFound)) addUuidSigner = uuidFound

        setSigners([
          ...signers,
          {
            uuid: addUuidSigner,
            name: dataNameSignatory,
            email: dataEmailSignatory,
            biometric: false,
            sdk: false,
          },
        ])

        // Reset form
        setDataNameSignatory('')
        setDataEmailSignatory('')
        setDataSearchUUID('')
        setDataCheckCreateVerification(false)
        //Reset values
        setNameFound('')
        setUuidFound('')
        // Comprove signs
        handleComproveSigns()
      }
    }
  }

  /*-------------------------------------------- 
    ADD NEW OBSERVERS
  ----------------------------------------------*/
  const handleAddObserver = () => {
    if (dataNameObserver === '' || dataEmailObserver === '') {
      setStateErrorObserver({
        error_state: 'block',
        error_content: 'Debes ingresar el nombre y/o correo',
      })

      setTimeout(() => {
        setStateErrorObserver({ error_state: 'none', error_content: '' })
      }, 2000)
    } else {
      setObservers([
        ...observers,
        {
          name: dataNameObserver,
          email: dataEmailObserver,
        },
      ])
      // Reset form
      setDataNameObserver('')
      setDataEmailObserver('')
    }
  }

  /*-------------------------------------------- 
    COMPROVE SIGNATORIES
  ----------------------------------------------*/

  const handleComproveSigners = () => {
    // Zona A (PDF)
    const orderSigners = document.getElementById('orderSigners')
    if (orderSigners) {
      //Elimina los hijos ya existentes, Evita duplicados
      const tope = orderSigners.childElementCount
      for (let i = 0; i < tope; i++) {
        orderSigners.removeChild(orderSigners.firstChild)
      }
      //Apartir de Signers creara las tarjetas
      for (let signer of signers) {
        let newLi = document.createElement('li')
        let contentDataSigner = document.createElement('div')
        let newLiText = document.createTextNode(`${signer.email}`)
        let newLiTextName = document.createTextNode(`${signer.name}`)
        newLi.id = `${signer.email}_li`
        newLi.classList = `${classes.divLi}`

        contentDataSigner.classList = 'mainInformationDiv'

        // Change cursor
        sortStatus
          ? (newLi.style.cursor = 'pointer')
          : (newLi.style.cursor = 'normal')

        // Info signer
        let newSpanName = document.createElement('span')
        newSpanName.classList = classes.spanNameSigner
        newSpanName.appendChild(newLiTextName)

        let newSpanEmail = document.createElement('span')
        newSpanEmail.classList = classes.spanEmailSigner
        newSpanEmail.appendChild(newLiText)

        let newDivContentInfo = document.createElement('div')
        newDivContentInfo.classList = classes.contentInfoAddSign
        newDivContentInfo.appendChild(newSpanName)
        newDivContentInfo.appendChild(newSpanEmail)

        // Delete signer
        let newDiv = document.createElement('div')
        let newDivText = document.createTextNode('×')
        // newDiv.id = `${signer.email}_li `
        newDiv.classList = classes.delLi

        let deleteSignerBtn = document.createElement('span')
        deleteSignerBtn.appendChild(newDivText)
        deleteSignerBtn.id = `${signer.email}_li `
        deleteSignerBtn.classList = classes.btnDeleteSigner
        deleteSignerBtn.onclick = function (e) {
          setCurrentDelete([{ element: e.target }])
        }

        // CheckBiometric
        let optionsDiv = document.createElement('div')
        let optionsDivIdentity = document.createElement('div')
        let checkIdentity = document.createElement('input')
        let spanIdentity = document.createElement('span')
        let textIdentity = document.createTextNode('Biometría Facial')

        optionsDiv.classList = 'optionsDiv'
        optionsDivIdentity.classList = 'optionsDivIdentity'

        spanIdentity.classList = 'spanOptionsText'
        spanIdentity.appendChild(textIdentity)
        optionsDivIdentity.appendChild(spanIdentity)

        checkIdentity.type = 'checkbox'
        checkIdentity.classList = 'checkIdentity'
        checkIdentity.checked = signer.biometric
        checkIdentity.id = `${signer.email}_li  `
        checkIdentity.onchange = function (e) {
          setCurrentVerification([{ element: e.target }])
        }
        optionsDivIdentity.appendChild(checkIdentity)
        optionsDiv.appendChild(optionsDivIdentity)

        // Main component
        newDiv.appendChild(deleteSignerBtn)
        contentDataSigner.appendChild(newDivContentInfo)
        contentDataSigner.appendChild(newDiv)

        //If sdk is false add the check to biometric
        if (!signer.sdk) {
          contentDataSigner.appendChild(optionsDiv)
        }

        newLi.appendChild(contentDataSigner)
        orderSigners.appendChild(newLi)
      }
    }
  }

  /*-------------------------------------------- 
    Select the biometric option to verification
  ----------------------------------------------*/
  const handleCurrentSignersVerification = (currentVerification) => {
    if (currentVerification.length !== 0) {
      let newA = []
      //Para actualizar el arreglo de firmantes
      for (let signer of signers) {
        if (`${signer.email}_li` !== currentVerification[0].element.id.trim())
          newA = [...newA, signer]
        else
          newA = [
            ...newA,
            {
              uuid: signer.uuid,
              name: signer.name,
              email: signer.email,
              biometric: !signer.biometric,
              sdk: !signer.sdk,
            },
          ]
      }
      setSigners(newA)
    }
  }

  useEffect(() => {
    handleCurrentSignersVerification(currentVerification)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVerification])

  /*-------------------------------------------- 
    COMPROVE OBSERVERS
  ----------------------------------------------*/

  const handleComproveObserver = () => {
    const orderObserver = document.getElementById('orderObserver')
    if (orderObserver) {
      //Elimina los hijos ya existentes, Evita duplicados
      const childs = orderObserver.childElementCount
      for (let i = 0; i < childs; i++) {
        orderObserver.removeChild(orderObserver.firstChild)
      }
      //Apartir de observers creara las tarjetas
      for (let observer of observers) {
        let newSpan = document.createElement('span')
        let name_observer = document.createTextNode(`${observer.name}`)
        let email_observer = document.createTextNode(`${observer.email}`)
        newSpan.id = `${observer.email}_ctn`
        newSpan.classList = `${classes.divLi}`
        newSpan.style.cursor = 'normal'

        orderObserver.appendChild(newSpan)

        // Info signer
        let newSpanName = document.createElement('span')
        newSpanName.classList = classes.spanNameSigner
        newSpanName.appendChild(name_observer)

        let newSpanEmail = document.createElement('span')
        newSpanEmail.classList = classes.spanEmailSigner
        newSpanEmail.appendChild(email_observer)

        let newDivContentInfo = document.createElement('div')
        newDivContentInfo.classList = classes.contentInfoAddSign
        newDivContentInfo.appendChild(newSpanName)
        newDivContentInfo.appendChild(newSpanEmail)

        // Delete signer
        let newDiv = document.createElement('div')
        let newDivText = document.createTextNode('×')
        // newDiv.id = `${signer.email}_li `
        newDiv.classList = classes.delLi

        let deleteObserverBtn = document.createElement('span')
        deleteObserverBtn.appendChild(newDivText)
        deleteObserverBtn.id = `${observer.email}_li `
        deleteObserverBtn.classList = classes.btnDeleteSigner
        deleteObserverBtn.onclick = function (e) {
          setCurrentDeleteObserver([{ element: e.target }])
        }

        newDiv.appendChild(deleteObserverBtn)
        newSpan.appendChild(newDivContentInfo)
        newSpan.appendChild(newDiv)
      }
    }
  }

  useEffect(() => {
    handleComproveSigners()
    handleComproveObserver()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signers, sortStatus, observers])

  /*-------------------------------------------- 
    DELETE COMPONENTS
  ----------------------------------------------*/

  //Ayuda a eliminar un firmante manteniendo los datos actualizados
  const handleCurrentDelete = (currentDelete) => {
    if (currentDelete.length !== 0) {
      let newA = []
      let newAP = []
      //Para actualizar el arreglo de firmantes
      for (let signer of signers) {
        if (`${signer.email}_li` !== currentDelete[0].element.id.trim())
          newA = [...newA, signer]
      }
      //Para actualizar el arreglo de posiciones
      for (let signPosition of signPositions) {
        if (
          `${signPosition?.user?.email}_li` !==
          currentDelete[0].element.id.trim()
        )
          newAP = [...newAP, signPosition]
      }
      setSigners(newA)
      setSignPositions(newAP)
    }
  }

  const handleCurrentDeleteObservers = (currentDeleteObserver) => {
    if (currentDeleteObserver.length !== 0) {
      let newA = []
      //Para actualizar el arreglo de firmantes
      for (let observer of observers) {
        if (
          `${observer.email}_li` !== currentDeleteObserver[0].element.id.trim()
        )
          newA = [...newA, observer]
      }
      setObservers(newA)
    }
  }

  useEffect(() => {
    handleCurrentDelete(currentDelete)
    handleCurrentDeleteObservers(currentDeleteObserver)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDelete, currentDeleteObserver])

  /*-------------------------------------------- 
    SIGNATORIES
  ----------------------------------------------*/

  // Form signatory
  const handleSearchUUID = (e) => {
    const uuidClient = e?.target?.value
    setDataSearchUUID(uuidClient)
  }
  const handleNameSignatory = (e) => {
    const name = e?.target?.value
    setDataNameSignatory(name)
  }
  const handleEmailSignatory = (e) => {
    const email = e?.target?.value
    setDataEmailSignatory(email)
  }

  const handleChangeCheckCreateVerification = (e) => {
    const check = e?.target?.checked
    setDataCheckCreateVerification(check)
  }

  //Actions
  const handleCancelSignatory = () => {
    setDataNameSignatory('')
    setDataEmailSignatory('')
    setDataSearchUUID('')
    setDataCheckCreateVerification(false)
    setLoadingCreateVerification(false)

    //Reset values
    setNameFound('')
    setUuidFound('')

    setStateError({ error_state: 'none', error_content: '' })
  }

  const handleVerificationData = async () => {
    if (dataSearchUUID) {
      const token = await getToken({
        audience: 'veridocid-dashboard',
      })

      const dataVerification = await getVerificationSigner(
        token,
        dataSearchUUID
      )

      if (isEmpty(dataVerification)) {
        //Reset values
        setNameFound('')
        setUuidFound('')
        setStateError({
          error_state: 'block',
          error_content: `No se han encontrado datos sobre el uuid: ${dataSearchUUID}`,
        })
      } else {
        if (dataVerification?.status === 400) {
          //Reset values
          setNameFound('')
          setUuidFound('')
          setStateError({
            error_state: 'block',
            error_content: `No se puede agregar verificaciones, que no se han procesado correctamente`,
          })
        } else {
          // Result data
          setNameFound(dataVerification?.name)
          setUuidFound(dataVerification?.uuid)
          // Reset data
          setStateError({
            error_state: 'none',
            error_content: ``,
          })
        }
      }
    } else {
      //Reset values
      setNameFound('')
      setUuidFound('')
      setStateError({
        error_state: 'block',
        error_content: `Debe ingresar un uuid para obtener los datos`,
      })
    }
  }

  /*-------------------------------------------- 
    OBSERVERS
  ----------------------------------------------*/

  // Form signatory
  const handleNameObserver = (e) => {
    const name = e?.target?.value
    setDataNameObserver(name)
  }
  const handleEmailObserver = (e) => {
    const email = e?.target?.value
    setDataEmailObserver(email)
  }

  //Actions
  const handleCancelObserver = () => {
    setDataNameObserver('')
    setDataEmailObserver('')
    setStateErrorObserver({ error_state: 'none', error_content: '' })
  }

  // -------------------------------------- Who sign
  /* const handleOptionSign = (e) => {
    setWhoSign(e?.target?.value)
  } */

  // -------------------------------------- Geolocation (me_others)
  const handleGeolocationMeOthers = (e) => {
    setStateGeolocation(e)

    if (!e) {
      message.error('Has desactivado la geolocalización')
    } else {
      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }

      const successGeo = async (pos) => {
        const getIp = await getIP()
        setGeolocation(`${pos.coords.latitude}, ${pos.coords.longitude}`)
        if (getIp) {
          setIp(getIp?.IPv4)
          message.success('Has activado la geolocalización')
        } else {
          const resGetIP2 = await getIPOption2()

          if (resGetIP2) {
            setIp(resGetIP2.ip)
            message.success('Has activado la geolocalización')
          } else
            message.error(
              'Hemos tenido un problema al intentar obtener su geolocalización, intentelo nuevamente'
            )
        }
      }
      const errorGeo = (err) => {
        if (err) {
          setStateGeolocation(false)
          message.error('Debe activar la geolocalización para continuar')
        }
      }

      navigator.geolocation.getCurrentPosition(successGeo, errorGeo, options)
    }
  }

  /*-------------------------------------------- 
    SIGN DOCUMENT - ME
  ----------------------------------------------*/
  const handleSignContractMe = async (participantId, uuidSign) => {
    try {
      const token = await getToken({
        audience: 'veridocid',
      })

      let leftMargin = 22
      let newA = []

      if (signMe.option === 3) {
        leftMargin = 9
      }

      for (let signPosition of signPositions) {
        if (signPosition.user.email === useAuth0.email) {
          newA = [
            ...newA,
            {
              user: {
                email: useAuth0.email,
              },
              coordinates: {
                x: signPosition.coordinates.x + leftMargin,
                y: signPosition.coordinates.y + 19,
              },
              page: signPosition.page,
            },
          ]
        }
      }

      // const url = `${API_URL_V3}/add-sign`
      const url = `${API_URL_V3}/add_sign`
      const response = await fetch(`${url}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: idClient,
          uuid: uuidSign,
          documentID: file?.documentID,
          participantId,
          staticSignPositions: newA,
          imageB64: signMe.signature,
          ubicacion: geolocation,
          ip,
        }),
      })

      const reponseJSON = await response.json()
      if (response.ok) {
        message.success('Has firmado el documento con éxito')
        handleSendEmail()
      } else {
        setLoadingSignatory(false)
        if (reponseJSON?.error) {
          if (isArray(reponseJSON?.error?.message))
            message.error(`${reponseJSON.error?.message[0]}`)
          else message.error(`${reponseJSON.error?.message}`)
        }
      }
      setLoadingSignatory(false)
    } catch (error) {
      setLoadingSignatory(false)
      message.error('Hemos tenido un problema, por favor intentar más tarde')
    }
  }

  /*-------------------------------------------- 
    SIGN DOCUMENT
  ----------------------------------------------*/
  // const [createEmail, setCreateEmail] = useState('')
  const [titleMessage, setTitleMessage] = useState('')
  const [contentMessage, setContentMessage] = useState('')
  const [statusError, setStatusError] = useState({
    error_msg: '',
    error_status: false,
    error_visible: 'none',
  })

  // Form send email
  /* const handleInputCreateEmail = (e) => {
    setCreateEmail(e?.target?.value)
  } */

  const handleChangeTitle = (e) => {
    const title = e?.target?.value
    setTitleMessage(title)
  }

  const handleChangeMessage = (e) => {
    const msg = e?.target?.value
    setContentMessage(msg)
  }

  // Sign
  const handleSendEmail = async () => {
    setLoadingSignatory(true)
    try {
      const token = await getToken({
        audience: 'veridocid',
      })

      // Only get signers data
      let arraySignatories = []

      signers.length > 0 &&
        signers.map((data) =>
          arraySignatories.push({ name: data?.name, email: data?.email })
        )

      const url = `${API_URL_V3}/sign/signatory`
      // const url = `${API_URL_V3}/sign-signatory`
      const response = await fetch(`${url}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: idClient,
          uuid,
          documentID: file?.documentID,
          message: contentMessage,
          title: titleMessage,
          signatory: arraySignatories,
          sharedWith: [...observers],
        }),
      })

      const reponseJSON = await response.json()
      if (response.ok) {
        // Reset values
        // setCreateEmail('')
        setContentMessage('')
        setTitleMessage('')
        // replace('/success-contract') ---> cambiar por la nueva ruta que permite ver el archivo pero sin poder editar configuraciones
        replace(`/resultado-contrato/${file?.documentID}/${uuid}/${idClient}`)

        message.success('Email(s) enviado(s) correctamente')
      } else {
        setLoadingSignatory(false)
        if (reponseJSON?.error) {
          if (isArray(reponseJSON?.error?.message))
            message.error(`${reponseJSON.error?.message[0]}`)
          else message.error(`${reponseJSON.error?.message}`)
        }
      }
      setLoadingSignatory(false)
    } catch (error) {
      setLoadingSignatory(false)
      message.error('Hemos tenido un problema, por favor intentar más tarde')
    }
  }

  /*-------------------------------------------- 
    SAVE SIGNATORIES AND OBSERVERS
  ----------------------------------------------*/
  const saveDataSignatory = async () => {
    if (contentMessage === '' || titleMessage === '') {
      message.error('Ingresa el título y/o el mensaje')

      setStatusError({
        error_msg: 'Ingresa el título y/o el mensaje',
        error_status: true,
        error_visible: 'block',
      })

      setTimeout(() => {
        setStatusError({
          error_msg: 'Ingresa el título y/o el mensaje',
          error_status: false,
          error_visible: 'none',
        })
      }, 2500)
      setLoadingFixedSignatory(false)
    } else {
      // Empty message
      setStatusError({
        error_msg: '',
        error_status: false,
        error_visible: 'none',
      })

      // Comprueba si tiene un documentID para continuar
      if (file?.documentID !== null) {
        // Loading button
        setLoadingFixedSignatory(true)
        // try {
        const token = await getToken({
          audience: 'veridocid',
        })

        //Independientemente del zoom las posiciones de la caja no cambiaran --> En este punto se convierten las coordenadas a escala 1/1 y se remueve el atributo zoom
        let newA = []
        for (let signPosition of signPositions) {
          newA = [
            ...newA,
            {
              coordinates: {
                x: signPosition.coordinates.x / signPosition.zoom,
                y: signPosition.coordinates.y / signPosition.zoom,
              },
              page: signPosition.page,
              user: signPosition.user,
            },
          ]
        }

        var bodyOptions
        if (file?.whoSign === 'me_others' && signMe.signature !== '') {
          bodyOptions = {
            id: idClient,
            uuid,
            documentID: file?.documentID,
            staticSignPositions: newA,
            participants: [
              ...signers,
              {
                name: useAuth0.name,
                email: useAuth0.email,
                biometric: false,
                sdK: false,
              },
            ],
            observers: [...observers],
          }
        } else {
          bodyOptions = {
            id: idClient,
            uuid,
            documentID: file?.documentID,
            staticSignPositions: newA,
            participants: [...signers],
            observers: [...observers],
          }
        }

        const fixedSign = await fixedSignatory(token, bodyOptions)

        if (fixedSign !== null) {
          message.success(
            'Se han añadido con éxito los firmantes y/u observadores'
          )

          if (fixedSign?.participants) {
            let participantId = ''
            for (let participant of fixedSign.participants) {
              if (participant.email === useAuth0.email) {
                participantId = participant.participantId
                break
              }
            }

            // Me&Others
            if (signMe.signature && signMe.signature !== '') {
              await handleSignContractMe(participantId, fixedSign?.uuid)
            } else {
              // Send email
              handleSendEmail()
            }
          }
        } else {
          setLoadingFixedSignatory(false)
          message.error(
            'Hemos tenido un problema al guardar a los firmantes, por favor intentarlo más tarde'
          )
        }
      }
    }
  }

  const handleRubric = (e) => {
    setRubric(e.target.checked)

    if (e.target.checked) {
      let internalSigners = []
      let internalPageSizeX = parseInt(pageSize[0].replace('px', '')) - 136
      let internalPageSizeY = parseInt(pageSize[1].replace('px', '')) - 73

      if (file?.whoSign !== 'others')
        internalSigners.push({
          email: useAuth0.email,
          name: useAuth0.name,
        })
      if (signers.length > 0) internalSigners.push(...signers)

      let newA = []
      let x = 20 * zoom
      let y = 10 * zoom

      for (let page = 0; page < numPages; page++) {
        x = 20 * zoom
        y = 10 * zoom

        for (let signer of internalSigners) {
          newA.push({
            user: { email: signer.email, name: signer.name },
            coordinates: { x, y },
            page: page + 1,
            zoom,
            rubric: true,
          })

          y = y + 78 * zoom

          if (y > internalPageSizeY) {
            x = internalPageSizeX * zoom
            y = 10 * zoom
          }
        }
      }
      newA.push(...signPositions)
      setSignPositions(newA)
    } else {
      let newA = []
      for (let signPosition of signPositions)
        if (!signPosition.rubric) newA.push(signPosition)
      setSignPositions(newA)
    }
  }

  return (
    <Grid container className={classes.Container}>
      <Grid item xs={fileBase64 === 'false' ? 12 : 8}>
        <Grid container className={classes.Tools}>
          <Grid item xs={4}>
            {fileBase64 !== 'false' ? (
              <Grid container>
                <Grid item xs={4} className={classes.Tool}>
                  {page > 1 ? (
                    <Button style={{ outline: 'none' }} onClick={previousPage}>
                      <VscArrowLeft size={24} color={'#eee'} />
                    </Button>
                  ) : null}
                </Grid>
                <Grid item xs={4} className={classes.Tool}>
                  <div>
                    {page || (numPages ? 1 : '--')} / {numPages || '--'}
                  </div>
                </Grid>
                <Grid item xs={4} className={classes.Tool}>
                  {page < numPages ? (
                    <Button style={{ outline: 'none' }} onClick={nextPage}>
                      <VscArrowRight size={24} color={'#eee'} />
                    </Button>
                  ) : null}
                </Grid>
              </Grid>
            ) : null}
          </Grid>
          <Grid item xs={4}>
            {fileBase64 !== 'false' ? (
              <Grid container>
                <Grid item xs={4} className={classes.Tool}>
                  <Button style={{ outline: 'none' }} onClick={zoomOut}>
                    <VscZoomOut size={24} color={'#eee'} />
                  </Button>
                </Grid>
                <Grid item xs={4} className={classes.Tool}>
                  <div>{parseInt(zoom * 100)}%</div>
                </Grid>
                <Grid item xs={4} className={classes.Tool}>
                  {zoom < 2 ? (
                    <Button style={{ outline: 'none' }} onClick={zoomIn}>
                      <VscZoomIn size={24} color={'#eee'} />
                    </Button>
                  ) : null}
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        {/* PDF */}
        <Grid item xs={12} className={classes.showPDF} id="pdf_renderer">
          <Document file={fileBase64} onLoadSuccess={onDocumentLoadSuccess}>
            <Page
              pageNumber={page}
              scale={zoom}
              onRenderSuccess={(e) => handleRenderSuccess(e)}
            />
          </Document>

          <Grid
            item
            className={classes.ddzone}
            id="ddzone"
            onDragStart={(e) => dd_dragstart(e)}
            style={{ width: pageSize[0], height: pageSize[1] }}
          ></Grid>
        </Grid>
      </Grid>
      {fileBase64 !== 'false' ? (
        <Grid item xs={4} className={classes.Right}>
          <Grid className={classes.Right_Title}>Configuraciones</Grid>
          <Collapse
            defaultActiveKey={['2', '3']}
            onChange={callback}
            className={classes.Collapse_custom}
          >
            <Panel header="Principales" key="1">
              <span className="description_config_client">
                Configure sus opciones que mas se ajusten a sus necesidades
              </span>
              <form className={classes.config_form__main}>
                <div className={classes.config_form__two__column}>
                  {/* Country */}
                  <div className="config_form__content_input">
                    <label htmlFor="country" className="lbl_input">
                      País
                    </label>
                    <CountryDropdown
                      disabled
                      value={file ? file?.country : 'Mexico'}
                      className="select_country"
                    />
                  </div>
                  {/* Geolocalization */}
                  <div
                    className="config_form__content_input"
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <span
                      className="geolocalization lbl_input"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Tooltip title="Activa o desactiva la geolocalización de tus firmantes">
                        <VscInfo
                          color="rgb(140, 140, 140)"
                          size={16}
                          style={{ cursor: 'pointer', marginRight: '10px' }}
                        />
                      </Tooltip>{' '}
                      Geolocalización para firmante(s)
                    </span>
                    <Switch
                      disabled
                      style={{ width: '14px', marginTop: '0.9em' }}
                      checked={file ? file?.geo : true}
                    />
                  </div>
                </div>
                {/* Who sign */}
                <div
                  className="config_form__content_input"
                  style={{ marginBottom: '1.6em' }}
                >
                  <label
                    htmlFor="who_sign"
                    className="lbl_input"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Tooltip
                      key="tooltip_who_sign"
                      title="Selecciona quien o quienes participaran en el contrato"
                    >
                      <VscInfo
                        color="rgb(140, 140, 140)"
                        size={16}
                        style={{ cursor: 'pointer', marginRight: '10px' }}
                      />
                    </Tooltip>{' '}
                    ¿Quién firma?
                  </label>
                  <Radio.Group
                    // defaultValue={'me_others'}
                    name="who_sign"
                    // onChange={handleOptionSign}
                    // value={whoSign}
                    value={file?.whoSign ? file?.whoSign : 'others'}
                  >
                    <Radio value={'me_others'} disabled>
                      Yo y otros
                    </Radio>
                    <Radio value={'others'} disabled>
                      Otros
                    </Radio>
                  </Radio.Group>
                </div>
              </form>
            </Panel>
            <Panel header="Firmantes y observadores" key="2">
              <ConfigClienteSign
                // Signatories
                signers={signers}
                changeInputSearch={handleSearchUUID}
                changeInputNameSignatory={handleNameSignatory}
                changeInputEmailSignatory={handleEmailSignatory}
                onChangeCheckCreateVerification={
                  handleChangeCheckCreateVerification
                }
                searchUUID={dataSearchUUID}
                nameSignatory={
                  nameFound ? _.capitalize(nameFound) : dataNameSignatory
                }
                foundNameVerification={nameFound ? true : false}
                emailSignatory={dataEmailSignatory}
                checkCreateVerification={dataCheckCreateVerification}
                addNewSignatory={
                  dataCheckCreateVerification && isEmpty(uuidFound)
                    ? handleSubmitConfigModalCV
                    : handleAddSigner
                }
                cancelNewSignatory={handleCancelSignatory}
                handleVerificationData={handleVerificationData}
                set_visibility_error={stateError.error_state}
                set_msg_error={stateError.error_content}
                // Effects
                stateLoadingAddSigner={loadingCreateVerification}
                // Order signatories
                statusSwitchSort={
                  document.getElementById('orderSigners') &&
                  document.getElementById('orderSigners').childElementCount ===
                    0
                    ? true
                    : false
                }
                handleOrder={useOrder}
                orderComponent={sortStatus}
                stateTextSort={sortStatus ? 'Ordenable' : 'No ordenable'}
                // Observer
                changeInputNameObserver={handleNameObserver}
                changeInputEmailObserver={handleEmailObserver}
                nameObserver={dataNameObserver}
                emailObserver={dataEmailObserver}
                addNewObserver={handleAddObserver}
                cancelNewObserver={handleCancelObserver}
                set_visibility_error__observer={stateErrorObserver.error_state}
                set_msg_error__observer={stateErrorObserver.error_content}
                // Message email
                handleChangeTitle={handleChangeTitle}
                titleMessage={titleMessage}
                handleChangeMessage={handleChangeMessage}
                contentMessage={contentMessage}
                visibleErrorMessageEmail={statusError.error_status}
                classErrorMessageEmail={statusError.error_visible}
                contentErrorMessageEmail={statusError.error_msg}
              />
            </Panel>
            <Panel header="Firma" key="3">
              {/* Yo y otros */}
              {file?.whoSign === 'me_others' ? (
                <>
                  <h5 className={classes.title_signatories}>Agrega tu firma</h5>
                  <span className="description_config_client">
                    Seleccione el formato para su firma y ubique en donde la
                    quiere visualizar.
                  </span>
                  <br />
                  {/* Me sign */}
                  <div className={classes.signMeData}>
                    <div className={classes.dataMe}>
                      <p>{useAuth0.name}</p>
                      <p>{useAuth0.email}</p>
                    </div>
                    <span
                      className={classes.actionMe}
                      onClick={handleShowFormatSign}
                    >
                      Firmar
                    </span>
                  </div>

                  {/* Geolocalization */}
                  <div
                    className="config_form__content_input"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexWrap: 'wrap',
                    }}
                  >
                    <span className="geolocalization lbl_input">
                      Tu geolocalización
                    </span>
                    <Switch
                      // defaultChecked
                      style={{ width: '14px', marginTop: '0.9em' }}
                      checked={stateGeolocation}
                      onChange={handleGeolocationMeOthers}
                      // disabled={disabledInputsConfig}
                    />
                    <span
                      className="description_config_client"
                      style={{ marginTop: '10px' }}
                    >
                      Debes activar la geolocalización para poder continuar con
                      el contrato
                    </span>
                  </div>

                  {/* Drag zone sign */}
                  <div
                    className={classes.ddzoneTest}
                    id="ddzoneTest"
                    onDragStart={(e) => dd_dragstart(e)}
                  ></div>
                </>
              ) : null}

              {/* Others */}
              <h5 className={classes.title_signatories}>Firmante(s)</h5>
              <span className="description_config_client">
                Ubique en donde quiere visualizar la firma de sus firmantes
              </span>
              {/* Rubric */}
              {signers.length !== 0 || signPositions.length !== 0 ? (
                <Rubric className="signers_Li">
                  <div>
                    <Tooltip title="Habilita esta opción para poder agregar las firmas en todas las hojas">
                      <VscInfo
                        color="rgb(140, 140, 140)"
                        size={16}
                        style={{
                          cursor: 'pointer',
                          marginRight: '6px',
                        }}
                      />
                    </Tooltip>
                    <span>Rubricar</span>
                  </div>
                  <input
                    type="checkbox"
                    checked={rubric}
                    onChange={handleRubric}
                    style={{ marginLeft: '12px' }}
                  />
                </Rubric>
              ) : null}
              {/* Drag and drop sign */}
              <div
                className={classes.ddzoneB}
                id="ddzoneB"
                onDragStart={(e) => dd_dragstart(e)}
              ></div>
            </Panel>
          </Collapse>

          {/* Select format sign */}
          {open ? (
            <ModalSign open={open} setOpen={setOpen} setSign={setSignMe} />
          ) : null}

          {/* Actions */}
          <Grid className={classes.Final}>
            {/* Save signatories and observers */}
            <ActionConfig
              type="button"
              className={classes.save_configurations_solid}
              onClick={() => saveDataSignatory()}
              loading={loadingFixedSignatory || loadingSignatory}
              disabled={
                signers.length === 0 || signPositions.length === 0
                  ? true
                  : false
              }
            >
              Enviar
            </ActionConfig>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  )
}

export default PDFEditClient
