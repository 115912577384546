import React from 'react'
import logo from '../assets/VeriDocID_50.png'
import '../styles/Dashboard.css'

const Footer = () => (
  <footer id="principal-footer" className="bg-light p-3 text-center">
    <br />
    <img src={logo} width="100" alt="Logo" />
    <br />
    <br />
    <p>
      Service provided by{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://sumamexico.com"
      >
        SUMA México
      </a>
    </p>
  </footer>
)

export default Footer
