import React from 'react'
import { VscAdd, VscCircleSlash } from 'react-icons/vsc'
import './FormAddStyles.css'

// Text mask
import MaskedInput from 'react-text-mask'
import emailMask from 'text-mask-addons/dist/emailMask'

const FormAdd = ({
  onChangeNameObserver,
  onChangeEmailObserver,
  valueNameObserver,
  valueEmailObserver,
  onAddObserver,
  onCancelObserver,
  // Error
  msg_visibility_observer,
  content_msg_visibility_observer,
  // Collapse
  stateCollapseFormObserver,
}) => {
  return (
    <div
      // className="form_add"
      className={`collapse-content ${
        stateCollapseFormObserver ? 'collapsed' : 'expanded'
      } form_add`}
      aria-expanded={stateCollapseFormObserver}
    >
      <div className="content-input_add">
        <label htmlFor="name_observer">Nombre</label>
        <input
          type="text"
          name="name_observer"
          placeholder='Nombre del observador'
          onChange={onChangeNameObserver}
          value={valueNameObserver}
        />
      </div>
      <div className="content-input_add">
        <label htmlFor="email_observer">Correo</label>
        <MaskedInput
          name="email_observer"
          keepCharPositions={false}
          mask={emailMask}
          guide={true}
          placeholder="Correo del observador"
          onChange={onChangeEmailObserver}
          value={valueEmailObserver}
        />
      </div>
      <div className="actions_add">
        <button type="button" className="new_add" onClick={onAddObserver}>
          <VscAdd color="white" size={20} strokeWidth={1} />
        </button>
        <button type="button" className="cancel_add" onClick={onCancelObserver}>
          <VscCircleSlash color="white" size={20} strokeWidth={1} />
        </button>
      </div>
      {/* Message */}
      <div
        className="message_error"
        style={{ display: `${msg_visibility_observer}` }}
      >
        <h5 className="title_msg_error">Observadores</h5>
        <p className="content_msg_error">{content_msg_visibility_observer}</p>
      </div>
    </div>
  )
}

export default FormAdd
