import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'

export const Right = styled(Grid)({
  width: '100%',
  height: '950px',
  backgroundColor: '#F6F8FA',
  position: 'relative',
  overflowY: 'auto',
  userSelect: 'none',
})

export const RightTitle = styled(Grid)({
  width: '100%',
  height: '50px',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '20px',
  fontSize: '18px',
})
