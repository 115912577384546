import React from 'react'
import { Tooltip } from 'antd'
import { VscEdit, VscEye } from 'react-icons/vsc'

export const ViewContract = ({
  data,
  handleEditContract,
  handleViewContract,
}) => (
  <div className="content_view_contract">
    {data?.participants && data?.participants.length === 0 ? (
      <Tooltip title="Seguir editando">
        <button
          disabled={data?.documentID ? false : true}
          type="button"
          style={{
            color: '#2d2f6d',
            border: 'none',
            outline: 'none',
          }}
          className="btnContractClient"
          onClick={handleEditContract}
        >
          <VscEdit size={20} />
        </button>
      </Tooltip>
    ) : (
      <Tooltip title="Ver contrato">
        <button
          disabled={data?.documentID ? false : true}
          type="button"
          style={{
            color: '#2d2f6d',
            border: 'none',
            outline: 'none',
          }}
          className="btnContractClient"
          onClick={handleViewContract}
        >
          <VscEye size={20} />
        </button>
      </Tooltip>
    )}
  </div>
)
