import React, { useState } from 'react'
import Carousel from 'react-multi-carousel'
import { API_DASHBOARD_URL } from '../../../../const'
import useToken from '../../../../utils/useToken'
import fileType from 'file-type'
import useSWR from 'swr'
import '../BodyTemplateSelection.css'

function AvailableDocumentsCarousel({
  responsive,
  templates,
  fallbackImage,
  onClick,
  style,
  templateClicked,
}) {
  const itemsFront = templates?.filter(
    (template) => !template?.name?.includes('Side B')
  )

  const itemsBack = templates?.filter((template) =>
    template?.name?.includes('Side B')
  )

  const imagesSideB = itemsBack?.map((template) => template)

  const Item = ({ template, fallbackImage, onClick }) => {
    const getToken = useToken()
    const [image, setImage] = useState(template.image)

    const getImage = async (url, imagePath, typeTemplate) => {
      if (!imagePath) {
        return
      }

      const token = await getToken({
        audience: 'veridocid-dashboard',
      })

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ imagePath }),
      })

      if (response.ok) {
        const image = await response.text()
        const data = Buffer.from(image, 'base64')
        const type = await fileType.fromBuffer(data)
        if (typeTemplate === 'front') {
          template.image = `data:image/${type.ext || 'jpg'};base64,${image}`
          setImage(template.image)
        } else {
          template.backImage = `data:image/${type.ext || 'jpg'};base64,${image}`
        }
      }
    }

    useSWR(
      !template?.image
        ? [
            `${API_DASHBOARD_URL}/experts/records/templates/image`,
            template.imagePath,
            'front',
          ]
        : null,
      getImage
    )

    const imageBack = imagesSideB?.filter((res) =>
      res?.name?.includes(template?.name)
    )

    useSWR(
      imageBack[0]?.imagePath
        ? [
            `${API_DASHBOARD_URL}/experts/records/templates/image`,
            imageBack[0]?.imagePath,
            'back',
          ]
        : null,
      getImage
    )

    return (
      <div
        key={template?.templateId}
        style={{
          textAlign: 'center',
          backgroundColor: 'rgb(249, 249, 249)',
          padding: '1.4rem 0 2rem',
        }}
      >
        <p style={{ fontSize: 'clamp(0.8em,0.9em,1em)' }}>{template?.name}</p>
        <img
          src={image || fallbackImage}
          alt={template?.name}
          className="ImageTemplate"
          onClick={onClick}
          style={template?.templateId === templateClicked ? style : null}
        />
      </div>
    )
  }

  const items = itemsFront
    ?.slice(0)
    ?.sort()
    ?.reverse()
    ?.map((template) => (
      <Item
        key={template?.templateId}
        template={template}
        fallbackImage={fallbackImage}
        onClick={() => onClick(template)}
      ></Item>
    ))

  return (
    <Carousel responsive={responsive} arrows={true}>
      {items}
    </Carousel>
  )
}

export default AvailableDocumentsCarousel
