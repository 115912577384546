import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useToken from '../utils/useToken.js'
import { useAuth0 } from '../react-auth0-spa.js'
import {
  getDocumentBase64Sign,
  getDocumentDataSign,
} from '../utils/getDataCustomize'
import { useTranslation } from 'react-i18next'
import { message as signMessage } from 'antd'
import {
  Container,
  Details,
  BtnReturn,
  InternalContainer,
} from '../components/Signature/MassiveSignature/ContractReview/ContractReviewStyle.jsx'
import ViewPDF from '../components/Signature/Templates/UseTemplate/ViewPDF/ViewPDF.jsx'
import Options from '../components/Signature/MassiveSignature/ContractReview/Options.jsx'
import loading from '../assets/loading.svg'
import { useHistory } from 'react-router-dom'
import '../components/Signature/MassiveSignature/ContractReview/ContractReviewStyle.css'

export default function ContractReview() {
  let { documentID, uuid, id } = useParams()
  const { t } = useTranslation()
  const getToken = useToken()
  const { user, isAuthenticated } = useAuth0()
  const [message, setMessage] = useState('')

  // ClientID
  const [clientID, setClientID] = useState({})

  //ViewPDF
  const [participants, setParticipants] = useState(null)
  const [fileBase64, setFileBase64] = useState(null)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [pageSize, setPageSize] = useState([612, 792])
  //Legal Representant
  let legalRepresentant = { name: '', email: '' }
  //ConfigurationsPDF
  const [currentDrop, setCurrentDrop] = useState([])
  const [currentSelect, setCurrentSelect] = useState('')
  const [signPositions, setSignPositions] = useState([])
  const [eventDD, setEventDD] = useState(false)

  useEffect(() => {
    if (isAuthenticated) {
      setClientID(user?.['https://vdid.sumamexico.com/app_metadata'] ?? {})
    }
  }, [isAuthenticated, user])

  const handleRenderSuccess = () => {
    let canvas = document.getElementById('pdf_renderer')
    canvas = canvas.firstChild
    canvas = canvas.firstChild
    canvas = canvas.firstChild
    setPageSize([canvas.style.width, canvas.style.height])

    handleComproveSigns()
  }

  const handleConsultContract = async () => {
    const token = await getToken({
      audience: 'veridocid-dashboard',
    })
    const resGetDocumentBase64Sign = await getDocumentBase64Sign(
      token,
      documentID,
      uuid
    )
    setFileBase64(resGetDocumentBase64Sign)
  }

  const handleParticipantsData = async () => {
    const token = await getToken({
      audience: 'veridocid-dashboard',
    })

    const getParticipantData = await getDocumentDataSign(
      token,
      documentID,
      uuid
    )

    if (
      getParticipantData?.participants &&
      getParticipantData?.participants.length > 0
    ) {
      setParticipants(getParticipantData?.participants)
    }
  }

  useEffect(() => {
    handleConsultContract()
    handleParticipantsData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //
  const dd_dragstart = (e) => e.dataTransfer.setData('text/plain', e.target.id)

  const dd_dragover = (e) => e.preventDefault()

  const dd_drop = (e) => {
    e.preventDefault()
    let element = document.getElementById(e.dataTransfer.getData('text'))
    if (!element) element = document.getElementById(user.email)

    const eX = e.offsetX !== undefined ? e.offsetX : 0
    const eY = e.offsetY !== undefined ? e.offsetY : 0
    //Actualiza el arreglo con los datos actuales del elemento dropeado
    setCurrentDrop([{ element, eX, eY }])
  }

  const handleComproveSigns = () => {
    // Zona A (PDF)
    const dzone = document.getElementById('ddzone')

    if (dzone) {
      //Agrega una sola vez los eventos drop y dropover a la Zona A
      if (!eventDD) {
        dzone.addEventListener('dragover', dd_dragover, false)
        dzone.addEventListener('drop', dd_drop, false)
        setEventDD(true)
      }

      //Elimina los hijos ya existentes, Evita duplicados
      const tope = dzone.childElementCount
      for (let i = 0; i < tope; i++) dzone.removeChild(dzone.firstChild)

      //Comprueba que exista el messageDiv de la derecha
      const messageDivRight = document.getElementById(user.email)

      if (messageDivRight) {
        const divSize = messageDivRight.offsetWidth

        //Comprueba cada uno de los firmantes
        for (let signer of signPositions) {
          //Si el firmante tiene una firma activa en esta hoja, creara su caja de firma
          if (signer.page === page) {
            let mainDiv = document.createElement('div')
            let leftDiv = document.createElement('div')
            let leftDivText = document.createTextNode(message)
            let rightDiv = document.createElement('div')
            let rightDiv_Close = document.createElement('div')
            let rightCloseText = document.createTextNode('×')

            let rightDiv_AddSign = document.createElement('div')
            let rightAddSign = document.createTextNode('+')

            mainDiv.draggable = true
            mainDiv.classList = 'messageMain'
            mainDiv.style.fontSize = `${9 * zoom}px`
            mainDiv.style.width = `${(divSize + 20) * zoom}px`
            mainDiv.style.height = `${20 * zoom}px`

            leftDiv.classList = 'messageLeft'
            leftDiv.style.width = `${divSize * zoom}px`
            leftDiv.style.height = `${20 * zoom}px`
            leftDiv.appendChild(leftDivText)

            rightDiv.classList = 'messageRight'
            rightDiv.style.width = `${20 * zoom}px`
            rightDiv.style.height = `${20 * zoom}px`

            rightDiv_Close.classList = 'messageDiv_Close'
            rightDiv_Close.style.width = `${20 * zoom}px`
            rightDiv_Close.style.height = `${20 * zoom}px`
            rightDiv_Close.style.fontSize = `${20 * zoom}px`
            rightDiv_Close.style.paddingLeft = `${0.5 * zoom}px`
            rightDiv_Close.style.paddingBottom = `${4 * zoom}px`
            rightDiv_Close.style.marginBottom = `${5 * zoom}px`
            rightDiv_Close.setAttribute(
              'id',
              `${signer?.email}%PXY%${signer?.page}-${signer?.positionX}-${signer?.positionY}`
            )

            rightDiv_AddSign.classList = 'rightDiv_AddSign'
            rightDiv_AddSign.style.width = `${20 * zoom}px`
            rightDiv_AddSign.style.height = `${20 * zoom}px`
            rightDiv_AddSign.style.fontSize = `${20 * zoom}px`
            rightDiv_AddSign.style.paddingLeft = `${0.5 * zoom}px`
            rightDiv_AddSign.style.paddingBottom = `${4 * zoom}px`
            rightDiv_AddSign.style.marginBottom = `${5 * zoom}px`
            rightDiv_AddSign.setAttribute(
              'id',
              `${signer?.email}%PXY%${signer?.page}-${signer?.positionX}-${signer?.positionY}`
            )

            rightDiv_Close.onclick = function (e) {
              let newA = []
              signMessage.info(`Se elimino con éxito la firma`)
              for (let signer of signPositions) {
                if (
                  `${signer?.email}%PXY%${signer?.page}-${signer?.positionX}-${signer?.positionY}` !==
                    e.target.id.trim() ||
                  signer.page !== page
                )
                  newA = [...newA, signer]
              }
              setSignPositions(newA)
              document.getElementById(`${e.target.id.trim()}`).remove()
            }

            rightDiv_AddSign.onclick = function (e) {
              let currentPosition
              rightDiv_AddSign.disabled = true
              for (let signer of signPositions) {
                if (
                  `${signer?.email}%PXY%${signer?.page}-${signer?.positionX}-${signer?.positionY}` ===
                  e.target.id.trim()
                ) {
                  currentPosition = signer
                  /* eslint no-unused-vars: "off" */
                  break
                }
              }

              let newB = signPositions
              for (let count = 1; count <= totalPages; count++) {
                if (count !== page && count > page)
                  newB.push({
                    email: signer.email,
                    height: signer.height,
                    name: signer.name,
                    page: count,
                    positionX: signer.positionX,
                    positionY: signer.positionY,
                    width: signer.width,
                    zoom: signer.zoom,
                  })
              }

              setSignPositions(newB)
              signMessage.info(`${t('addMoreSignatures')}`)
              rightDiv_AddSign.disabled = false
            }

            rightDiv_Close.appendChild(rightCloseText)
            rightDiv_AddSign.appendChild(rightAddSign)

            rightDiv.appendChild(rightDiv_Close)
            rightDiv.appendChild(rightDiv_AddSign)

            mainDiv.appendChild(leftDiv)
            mainDiv.appendChild(rightDiv)

            mainDiv.setAttribute(
              'id',
              `${signer?.email}%PXY%${signer?.page}-${signer?.positionX}-${signer?.positionY}`
            )
            mainDiv.ondragstart = function (e) {
              setCurrentSelect(e.target.id)
            }
            //convierte las coordenadas a relacion 1/1 y las ajusta al zoom actual
            mainDiv.style.top = (signer?.positionY / signer?.zoom) * zoom + 'px'
            mainDiv.style.marginLeft =
              (signer?.positionX / signer?.zoom) * zoom + 'px'
            dzone.appendChild(mainDiv)
          }
        }
      }
    }
  }

  useEffect(() => {
    handleComproveSigns()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signPositions, message])

  useEffect(() => {
    const handleCurrentUpdate = (currentDrop) => {
      //Valida que haya un elemento valido arrastrable
      if (currentDrop.length !== 0 && currentDrop[0].element) {
        const divSize = currentDrop[0].element.offsetWidth
        //ajust centra la caja para un mejor control del drop
        const adjustX = (divSize * zoom) / 2
        const adjustY = 10 * zoom
        let codsX = currentDrop[0].eX - adjustX
        let codsY = currentDrop[0].eY - adjustY
        let actualPositions = signPositions

        if (currentSelect !== '') {
          let newA = []
          for (let signPosition of signPositions) {
            if (
              `${signPosition.email}%PXY%${signPosition.page}-${signPosition.positionX}-${signPosition.positionY}` !==
              currentSelect
            )
              newA = [...newA, signPosition]
          }
          actualPositions = newA
          setCurrentSelect('')
        }

        let newA = []
        let email = currentDrop[0].element.id.trim()
        let nEmail = email.split('%PXY%', 1)
        newA = [
          ...actualPositions,
          {
            email: nEmail[0],
            name:
              legalRepresentant.email === nEmail[0]
                ? legalRepresentant.name
                : user.email !== nEmail[0]
                ? nEmail[0]
                : user.name,
            positionX: codsX,
            positionY: codsY,
            width: divSize,
            height: 20,
            page,
            zoom,
            message,
          },
        ]
        setSignPositions(newA)
      }
    }

    handleCurrentUpdate(currentDrop)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDrop])

  // History
  const history = useHistory()
  const returnReview = () => {
    history.push(`/contratos/plantillas/mesa`)
  }

  return (
    <Container container>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '2rem',
        }}
      >
        <Details>Revisión de Contrato</Details>
        <BtnReturn onClick={() => returnReview()} type="button">
          Regresar
        </BtnReturn>
      </div>
      {fileBase64 !== null ? (
        <InternalContainer container>
          <ViewPDF
            optionChange={false}
            fileBase64={fileBase64}
            setFileBase64={setFileBase64}
            page={page}
            setPage={setPage}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            zoom={zoom}
            setZoom={setZoom}
            pageSize={pageSize}
            handleRenderSuccess={handleRenderSuccess}
          />
          <Options
            message={message}
            setMessage={setMessage}
            zoom={zoom}
            user={user}
            documentID={documentID}
            uuid={uuid}
            id={id}
            signPositions={signPositions}
            dd_dragstart={dd_dragstart}
            participants={participants}
            clientId={clientID?.clientId}
          />
        </InternalContainer>
      ) : (
        <InternalContainer container>
          <div
            style={{
              width: '100%',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={loading}
              alt="loading"
              style={{ width: '40%', height: '40%' }}
            />
          </div>
        </InternalContainer>
      )}
    </Container>
  )
}
