import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import SignProcessContext from '../../context/SignProcess.context'

export const DocumentsErrorList = () => {
  const { documentsWithError } = useContext(SignProcessContext)

  return documentsWithError.map((data) => (
    <DocumentErrorItem key={data.documentID} {...data} />
  ))
}
const DocumentErrorItem = ({ documentID, reason }) => (
  <div style={{ margin: '0 0 1.4rem 0' }}>
    <p style={{ margin: '0' }}>{documentID || '-'}</p>
    {reason.map((reas) => (
      <Reason key={`${documentID}-${reas}`} reason={reas} />
    ))}
  </div>
)

const Reason = ({ reason }) => {
  const { t } = useTranslation()
  return (
    <span
      style={{
        fontSize: 'clamp(0.77rem, 0.8rem, 0.88rem)',
        color: 'var(--black-light-color)',
      }}
    >
      {reason === 'The email does not match the legal representative'
        ? `${t('reason1')}`
        : reason === 'The document is not masive'
        ? `${t('reason2')}`
        : reason === 'The document is not approved'
        ? `${t('reason3')}`
        : `${t('reason4')}`}
    </span>
  )
}
