import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'

export const Box = styled(Grid)((props) => ({
  // width: '330px',
  // height: '60px',
  boxShadow:
    '0 1px 2px -2px rgba(218, 218, 218, 0.16), 0 4px 7px 1px #4b4b4b18',
  cursor: 'pointer',
  border: '1px solid #e6e6e6',
  borderRadius: '5px',
  // backgroundColor: props?.selected ? '#f1b3862b' : null,
  // borderColor: props?.selected ? 'var(--secondary-color)' : null,
  outline: 'none',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: '40%',
  margin: '0 10px',
  padding: '1.5rem 0',
  '&:hover, &:active, &:focus': {
    backgroundColor: '#f1b3862b',
    borderColor: 'var(--secondary-color)',
    boxShadow: 'none',
    outline: 'none',
  },
}))

export const BoxIcon = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#172b4d',
  width: '35px',
  height: '35px',
})

export const BoxName = styled(Grid)({
  fontWeight: '600',
  fontSize: '16px',
  margin: '0 0 0.5rem 0',
  color: 'rgb(23, 43, 77)',
})
