import { Button, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useStyles } from '../../styles/PDFSignatureV2'

// Components and utils
import {
  Button as ActionConfig,
  Collapse,
  message,
  Radio,
  Switch,
  Tooltip,
} from 'antd'
import { format } from 'date-fns'
import { CountryDropdown } from 'react-country-region-selector'
import { useTranslation } from 'react-i18next'
import {
  VscArrowLeft,
  VscArrowRight,
  VscFilePdf,
  VscInfo,
  VscZoomIn,
  VscZoomOut,
} from 'react-icons/vsc'
import { useHistory } from 'react-router'
import { API_URL_V3, IS_CVR } from '../../const/index'
import { getVerificationSigner } from '../../utils/getDataCustomize'
import {
  addDocument,
  convertDocx,
  fixedSignatory,
  getIP,
  getIPOption2,
  signatory,
} from '../../utils/signContract'
import useToken from '../../utils/useToken'
import ModalSign from '../Signature/PDFSignatureNC/Modal/ModalSign'

// Configurations
import { Plugins, Sortable } from '@shopify/draggable'
import _, { isArray, isEmpty } from 'lodash'
import { Document, Page, pdfjs } from 'react-pdf'
import { Dimmer, Loader } from 'semantic-ui-react'
import boxSign from '../../assets/caja firma.svg'
import '../../styles/GeneralStylesContracts.css'
import Alert from '../Alert/Alert'
import SignatureSettings from '../Signature/SettingsSign/SettingsSign'
import ConfigClienteSign from './GeneralConfigurationSign/ConfigClienteSign'
import { Rubric } from './PDFSignatureNC/ConfigurationsPDF/Sign/SignStyle'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

let signPositionsDOM = []
let offsetClickDown

export default function PDFSignatureV2(props) {
  const {
    user,
    file,
    setFile,
    fileBase64,
    setFileBase64,
    // --------- DataBD
    idClient,
    uuid,
    useAuth0,
    isAuthenticated,
  } = props
  const classes = useStyles()
  const { Panel } = Collapse
  const { t } = useTranslation()
  const { replace } = useHistory()
  // Token
  const getToken = useToken()

  const [rubric, setRubric] = useState(false)
  const [alertContract, setAlertContract] = useState({})
  const [loading, setLoading] = useState(false)
  const [page, setPageNumber] = useState(1)
  const [numPages, setNumPages] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [pageSize, setPageSize] = useState([612, 792])
  const [signers, setSigners] = useState([])
  const [observers, setObservers] = useState([])
  const [signPositions, setSignPositions] = useState([])
  const [currentDrop, setCurrentDrop] = useState([])
  const [currentSelect, setCurrentSelect] = useState('')
  const [currentDelete, setCurrentDelete] = useState([])
  const [currentVerification, setCurrentVerification] = useState([])
  const [currentDeleteObserver, setCurrentDeleteObserver] = useState([])
  const [eventDD, setEventDD] = useState(false)
  const [sortSigners, setSortSigners] = useState(null)
  const [sortStatus, setSortStatus] = useState(false)
  const [whoSign, setWhoSign] = useState('others')
  const [contractExpiration, setContractExpiration] = useState(30)

  // Validate clientId
  const [clientID, setClientID] = useState({})

  useEffect(() => {
    if (isAuthenticated)
      setClientID(
        user?.['https://vdid.sumamexico.com/app_metadata']?.clientId ?? null
      )
  }, [isAuthenticated, user])

  // Signatories
  const [dataSearchUUID, setDataSearchUUID] = useState('')
  const [dataNameSignatory, setDataNameSignatory] = useState('')
  const [dataEmailSignatory, setDataEmailSignatory] = useState('')
  const [dataCheckCreateVerification, setDataCheckCreateVerification] =
    useState(false)
  const [stateError, setStateError] = useState({
    error_state: 'none',
    error_content: '',
  })

  const [loadingCreateVerification, setLoadingCreateVerification] =
    useState(false)
  // If to search uuid
  const [nameFound, setNameFound] = useState('')
  const [uuidFound, setUuidFound] = useState('')

  // Observer
  const [dataNameObserver, setDataNameObserver] = useState('')
  const [dataEmailObserver, setDataEmailObserver] = useState('')
  const [stateErrorObserver, setStateErrorObserver] = useState({
    error_state: 'none',
    error_content: '',
  })

  // Data form
  const [loadingFixedSignatory, setLoadingFixedSignatory] = useState(false)
  const [loadingSignatory, setLoadingSignatory] = useState(false)
  const [dataGeolocation, setDataGeolocation] = useState(false)
  const [dataCountry, setDataCountry] = useState('Mexico')

  // Ip and geolocation (me_others)
  const [geolocation, setGeolocation] = useState('')
  const [stateGeolocation, setStateGeolocation] = useState(false)
  const [ip, setIp] = useState('')

  // const [specificPosition, setSpecificPosition] = useState(false)
  // Validate Email
  const regexValidateEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  // Collapse
  const callback = (key) => {
    for (let option of key) {
      if (option === '3') handleComproveSigns()
    }
  }

  // Open modal - format signature
  const [open, setOpen] = useState(false)
  const [signMe, setSignMe] = useState({
    signature: '',
    currentDate: '',
    option: 1,
  })

  // Setting Size
  const [newWidth, setNewWidth] = useState(136)
  const [newHeigth, setNewHeigth] = useState(73)

  // Settings Border
  const [imgBoxState, setImageBoxState] = useState(null)
  const [onBorder, setOnBorder] = useState(true)
  const [signBase64, setSignBase64] = useState('')

  const handleShowFormatSign = () => setOpen(true)

  //METODOS PARA AGREGAR O CAMBIAR EL PDF---------------------------------------------------
  const clickPDF = () => document.getElementById('file').click()

  const stopDefault = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDragEnter = (e) => stopDefault(e)

  const handleDragLeave = (e) => stopDefault(e)

  const handleDragOver = (e) => stopDefault(e)

  const dropPDF = (e) => {
    stopDefault(e)
    const fileInput = document.getElementById('file')
    fileInput.files = e.dataTransfer.files
    changeInput()
  }

  const changeInput = async () => {
    const fileInput = document.getElementById('file')

    if (!fileInput.files[0]) {
      return
    }

    const transformMb = fileInput.files[0].size / 1024 / 1024

    if (IS_CVR.includes(clientID)) {
      if (transformMb > 40) {
        let bodyAlert = {
          open: true,
          message: 'El archivo supera el limite de 40mb',
          severity: 'error',
        }
        setAlertContract(bodyAlert)
        return
      }
    } else {
      if (transformMb > 20) {
        let bodyAlert = {
          open: true,
          message: 'El archivo supera el limite de 20mb',
          severity: 'error',
        }
        setAlertContract(bodyAlert)
        return
      }
    }

    if (fileInput.files.length !== 0) setFile(fileInput.files[0])
    else setFile(false)

    if (fileInput.files[0].type === 'application/pdf') {
      const fileReader = new FileReader()
      if (fileInput.files[0]) {
        fileReader.readAsDataURL(fileInput.files[0])
        fileReader.onload = async (x) => {
          setFileBase64(x.target.result)
          setFile(fileInput.files[0])
        }
      } else setFileBase64('false')
      return
    } else {
      setLoading(true)
      const fileReader = new FileReader()
      const token = await getToken({ audience: 'veridocid' })

      if (fileInput.files[0]) {
        fileReader.readAsDataURL(fileInput.files[0])

        fileReader.onload = async (x) => {
          const result = await convertDocx(token, fileInput.files[0])

          if (result === null || result.error !== 0) {
            let bodyAlert = {
              open: true,
              message: 'Ocurrio un error al subir el documento.',
              severity: 'error',
            }
            setAlertContract(bodyAlert)
            setLoading(false)
            return
          }

          setFile(fileInput.files[0])
          setFileBase64('data:application/pdf;base64,' + result.result)
          setLoading(false)
        }
      } else setFileBase64('false')
      return
    }
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
    setPageNumber(1)
  }

  //METODOS PARA DESPLAZARSE O INTERACTUAR CON EL PDF---------------------------------------
  const changePage = (offset) =>
    setPageNumber((prevPageNumber) => prevPageNumber + offset)

  const previousPage = () => {
    if (page > 1) changePage(-1)
  }

  const nextPage = () => {
    if (page < numPages) changePage(1)
  }

  const zoomOut = () => {
    let newZoom = zoom - 0.1
    newZoom = newZoom.toFixed(1)
    newZoom = parseFloat(newZoom)

    if (newZoom >= 0.1) setZoom(newZoom)
  }

  const zoomIn = () => {
    let newZoom = zoom + 0.1
    newZoom = newZoom.toFixed(1)
    newZoom = parseFloat(newZoom)

    if (newZoom <= 2) setZoom(newZoom)
  }

  //METODOS PARA LAS POSICIONES DE LAS FIRMAS EN EL PDF------------------------------------
  const handleComproveSigns = () => {
    // Valida si existe una imagen cargada
    if (imgBoxState == null) {
      setImageBoxState(boxSign)
    }

    // Zona A (PDF)
    const dzone = document.getElementById('ddzone')
    if (dzone) {
      //Agrega una sola vez los eventos drop y dropover a la Zona A
      if (!eventDD) {
        dzone.addEventListener('dragover', dd_dragover, false)
        dzone.addEventListener('drop', dd_drop, false)
        dzone.addEventListener('mousedown', dd_mousedown)
        dzone.addEventListener('mouseup', dd_mouseup)
        setEventDD(true)
      }

      //Elimina los hijos ya existentes, Evita duplicados
      const tope = dzone.childElementCount
      for (let i = 0; i < tope; i++) {
        dzone.removeChild(dzone.firstChild)
      }
      let internalSignPositions = [...signPositions]

      //Comprueba cada uno de los firmantes
      for (let [i, signer] of internalSignPositions.entries()) {
        //Si el firmante tiene una firma activa en esta hoja, creara su caja de firma
        if (signer.page === page) {
          let mainDiv = document.createElement('div')
          let leftDiv = document.createElement('div')
          let rightDiv = document.createElement('div')
          let rightDiv_Close = document.createElement('span')
          let rightText = document.createTextNode('×')
          let rightDiv_AddSign = document.createElement('div')
          let rightAddSign = document.createTextNode('+')
          let imgBox = document.createElement('img')
          let emailBox = document.createElement('div')
          let emailText = document.createTextNode(`${signer?.user?.email}`)
          // let dateBox = document.createElement('p')
          // let dateText = document.createTextNode(`${signer?.date}`)
          // Me and others
          let dateMeBox = document.createElement('p')
          let dateMeText = document.createTextNode(`${signMe?.currentDate}`)

          mainDiv.id = `sign${i}`

          mainDiv.draggable = true
          mainDiv.classList = `${classes.mainDiv}`
          mainDiv.style.fontSize = `${10 * zoom}px`
          mainDiv.style.width = `${newWidth * zoom}px`
          mainDiv.style.height = `${newHeigth * zoom}px`

          leftDiv.style.width = `${newWidth * zoom}px`
          leftDiv.style.height = `${newHeigth * zoom}px`

          !onBorder ? (imgBox.style.opacity = 0) : (imgBox.src = imgBoxState)

          imgBox.draggable = false

          imgBox.style.width = `${newWidth * zoom}px`
          imgBox.style.height = `${newHeigth * zoom}px`
          imgBox.style.position = 'relative'

          emailBox.classList = `${classes.emailBox}`
          emailBox.style.width = `${newWidth * zoom}px`
          emailBox.style.height = `${newHeigth * zoom}px`
          emailBox.style.display = 'flex'
          emailBox.style.flexDirection = 'column'
          emailBox.style.alignItems = 'center'
          emailBox.style.justifyContent = 'center'
          emailBox.style.marginLeft = '-15px'
          emailBox.appendChild(emailText)

          // dateBox.style.top = 'calc(100% -20px)'
          // dateBox.appendChild(dateText)
          // emailBox.appendChild(dateBox)

          leftDiv.appendChild(imgBox)
          leftDiv.appendChild(emailBox)

          rightDiv.classList = `${classes.rightDiv}`
          rightDiv.style.width = `${20 * zoom}px`
          rightDiv.style.height = `${newHeigth * zoom}px`

          // Si yo voy a firmar el documento y ya seleccione un formato de firma se plasma la imagen de la firma
          if (
            signer?.user?.email === useAuth0.email &&
            signMe.signature !== ''
          ) {
            leftDiv.style.backgroundImage = `url(${signMe.signature})`
            leftDiv.style.backgroundPosition = 'center 15px'
            leftDiv.style.backgroundSize = `${
              signMe.option !== 3 ? 80 * zoom : 113 * zoom
            }px ${35 * zoom}px`
            leftDiv.style.backgroundRepeat = 'no-repeat'
            leftDiv.style.objectFit = 'contain'
            leftDiv.style.display = 'flex'
            leftDiv.style.alignItems = 'center'
            leftDiv.style.justifyContent = 'center'
            leftDiv.style.flexDirection = 'column'
            leftDiv.style.position = 'relative'
            leftDiv.style.margin = '0 auto'
            leftDiv.removeChild(emailBox)

            dateMeBox.style.position = 'absolute'
            dateMeBox.style.top = 'calc(100% - 20px)'

            dateMeBox.appendChild(dateMeText)
            leftDiv.appendChild(dateMeBox)
          }

          // Close signature
          rightDiv_Close.classList = `${classes.rightDiv_Close}`
          rightDiv_Close.style.width = `${20 * zoom}px`
          rightDiv_Close.style.height = `${20 * zoom}px`
          rightDiv_Close.style.fontSize = `${20 * zoom}px`
          rightDiv_Close.style.paddingLeft = `${0.5 * zoom}px`
          rightDiv_Close.style.paddingBottom = `${4 * zoom}px`
          rightDiv_Close.style.marginBottom = `${5 * zoom}px`
          rightDiv_Close.setAttribute(
            'id',
            `${signer?.user?.email}%PXY%${signer?.page}-${signer?.coordinates.x}-${signer?.coordinates.y} `
          )
          rightDiv_AddSign.classList = 'rightDiv_AddSign'
          rightDiv_AddSign.style.width = `${20 * zoom}px`
          rightDiv_AddSign.style.height = `${20 * zoom}px`
          rightDiv_AddSign.style.fontSize = `${20 * zoom}px`
          rightDiv_AddSign.style.paddingLeft = `${0.5 * zoom}px`
          rightDiv_AddSign.style.paddingBottom = `${4 * zoom}px`
          rightDiv_AddSign.style.marginBottom = `${5 * zoom}px`
          rightDiv_AddSign.setAttribute(
            'id',
            `${signer?.user?.email}%PXY%${signer?.page}-${signer?.coordinates.x}-${signer?.coordinates.y} `
          )
          rightDiv_Close.onclick = function (e) {
            let newA = []
            for (let signer of signPositions) {
              if (
                `${signer?.user?.email}%PXY%${signer?.page}-${signer?.coordinates.x}-${signer?.coordinates.y}` !==
                  e.target.id.trim() ||
                signer.page !== page
              )
                newA = [...newA, signer]
            }
            setSignPositions(newA)
            document.getElementById(`${e.target.id.trim()}`).remove()
          }

          rightDiv_AddSign.onclick = function (e) {
            let currentPosition
            for (let signer of signPositions) {
              if (
                `${signer?.user?.email}%PXY%${signer?.page}-${signer?.coordinates.x}-${signer?.coordinates.y}` ===
                e.target.id.trim()
              ) {
                currentPosition = signer
                break
              }
            }

            let newB = signPositions
            for (let count = 1; count <= numPages; count++) {
              if (count !== page)
                newB.push({
                  coordinates: currentPosition.coordinates,
                  page: count,
                  user: currentPosition.user,
                  zoom: currentPosition.zoom,
                })
            }
            setSignPositions(newB)
          }

          rightDiv_Close.appendChild(rightText)
          rightDiv_AddSign.appendChild(rightAddSign)

          rightDiv.appendChild(rightDiv_Close)
          rightDiv.appendChild(rightDiv_AddSign)

          mainDiv.appendChild(leftDiv)
          mainDiv.appendChild(rightDiv)

          mainDiv.setAttribute(
            'id',
            `${signer?.user?.email}%PXY%${signer?.page}-${signer?.coordinates.x}-${signer?.coordinates.y}`
          )
          mainDiv.ondragstart = function (e) {
            setCurrentSelect(e.target.id)
          }
          //convierte las coordenadas a relacion 1/1 y las ajusta al zoom actual
          mainDiv.style.top =
            (signer?.coordinates?.y / signer?.zoom) * zoom + 'px'
          mainDiv.style.marginLeft =
            (signer?.coordinates?.x / signer?.zoom) * zoom + 'px'
          dzone.appendChild(mainDiv)
        }
      }

      //Zona B (Zona derecha)
      const bzone = document.getElementById('ddzoneB')

      if (bzone) {
        //Elimina los hijos ya existentes, Evita duplicados
        const topeB = bzone.childElementCount
        for (let i = 0; i < topeB; i++) {
          bzone.removeChild(bzone.firstChild)
        }

        //Por cada firmante en el arreglo de la zona B se creara su caja
        for (let signer of signers) {
          let newDivB = document.createElement('div')
          newDivB.classList = `${classes.taskB}`
          newDivB.style.width = `180px`
          newDivB.style.height = `85px`
          newDivB.style.fontSize = `clamp(0.86em, 0.89em, 0.95em)`
          newDivB.setAttribute('draggable', true)

          let newImg = document.createElement('img')
          newImg.src = boxSign
          newImg.height = newHeigth
          newImg.width = newWidth
          newImg.style.position = 'absolute'
          newImg.style.zIndex = 0
          // newImg.style.backgroundColor = 'blue'
          newImg.setAttribute('draggable', false)
          newDivB.appendChild(newImg)

          let internalDiv = document.createElement('div')
          internalDiv.style.height = `85px`
          internalDiv.style.width = `180px`
          internalDiv.style.overflow = 'hidden'
          internalDiv.style.display = 'flex'
          internalDiv.style.flexDirection = 'column'
          internalDiv.style.flexWrap = 'wrap'
          internalDiv.style.justifyContent = 'center'
          internalDiv.style.alignItems = 'center'
          internalDiv.style.zIndex = 98

          let emailSign = document.createElement('span')
          let contentEmail = document.createTextNode(`${signer.email}`)
          emailSign.appendChild(contentEmail)

          let dateSign = document.createElement('span')
          let contentDate = document.createTextNode(`${signer.date}`)
          dateSign.appendChild(contentDate)

          internalDiv.appendChild(emailSign)
          //internalDiv.appendChild(dateSign)
          newDivB.appendChild(internalDiv)

          newDivB.setAttribute('id', `${signer.email}`)

          bzone.appendChild(newDivB)
        }
      }

      // Zona SignMe
      const ddzoneTesting = document.getElementById('ddzoneTest')

      if (signMe.signature !== '') {
        //Elimina los hijos ya existentes, Evita duplicados
        const topeTest = ddzoneTesting.childElementCount
        for (let i = 0; i < topeTest; i++) {
          ddzoneTesting.removeChild(ddzoneTesting.firstChild)
        }

        let newDivSignMe = document.createElement('div')
        let imgBox = document.createElement('img')
        let internalDivSignMe = document.createElement('div')
        let dateDivSignMe = document.createElement('p')
        let dateTextSignMe = document.createTextNode(signMe.currentDate)

        newDivSignMe.style.width = `180px`
        newDivSignMe.style.height = `85px`
        newDivSignMe.style.cursor = 'pointer'
        newDivSignMe.style.fontSize = `clamp(0.86em, 0.89em, 0.95em)`
        newDivSignMe.setAttribute('draggable', true)

        imgBox.src = boxSign
        imgBox.draggable = false
        imgBox.style.width = `180px`
        imgBox.style.height = `85px`
        imgBox.style.position = 'absolute'

        internalDivSignMe.style.height = `85px`
        internalDivSignMe.style.width = `${
          signMe.signature === '' ? 113 : signMe.option !== 3 ? 80 : 113
        }px`
        internalDivSignMe.style.backgroundImage = `url(${signMe.signature})`
        internalDivSignMe.style.backgroundPosition = 'center 20px'
        internalDivSignMe.style.backgroundSize = `${
          signMe.option !== 3 ? 80 : 113
        }px ${35}px`
        internalDivSignMe.style.backgroundRepeat = 'no-repeat'
        internalDivSignMe.style.objectFit = 'contain'
        internalDivSignMe.style.display = 'flex'
        internalDivSignMe.style.alignItems = 'center'
        internalDivSignMe.style.justifyContent = 'center'
        internalDivSignMe.style.flexDirection = 'column'
        internalDivSignMe.style.position = 'relative'
        internalDivSignMe.style.margin = '0 auto'

        dateDivSignMe.style.position = 'absolute'
        dateDivSignMe.style.top = 'calc(100% - 30px)'
        dateDivSignMe.appendChild(dateTextSignMe)
        internalDivSignMe.appendChild(dateDivSignMe)

        newDivSignMe.appendChild(imgBox)
        newDivSignMe.appendChild(internalDivSignMe)

        newDivSignMe.setAttribute('id', `${useAuth0.email}`)
        ddzoneTesting.appendChild(newDivSignMe)
      }
    }
  }

  useEffect(() => {
    handleComproveSigns()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    signPositions,
    signers,
    signMe,
    imgBoxState,
    newWidth,
    newHeigth,
    onBorder,
  ])

  //Se ejecuta cada que se interactua con el PDF
  const handleRenderSuccess = (pgd) => {
    let canvas = document.getElementById('pdf_renderer')
    canvas = canvas.firstChild
    canvas = canvas.firstChild
    canvas = canvas.firstChild
    setPageSize([canvas.style.width, canvas.style.height])

    handleComproveSigns()
  }

  /*-------------------------------------------- 
    DRAG AND DROP
  ----------------------------------------------*/
  const dd_dragstart = (e) => e.dataTransfer.setData('text/plain', e.target.id)

  const dd_dragover = (e) => e.preventDefault()

  const dd_drop = (e) => {
    e.preventDefault()

    const getContainerElement = e.target.parentElement

    let eX = 0
    let eY = 0

    const element = document.getElementById(e.dataTransfer.getData('text'))
    const newElement = document.getElementById(e.dataTransfer.getData('text'))

    if (!getContainerElement.id && !getContainerElement.className) {
      let [, x, y] = getContainerElement?.parentElement?.id
        .split('-')
        .splice(-3)

      const { coordinates } = signPositionsDOM.find(
        (sign) =>
          sign.coordinates.x === Number(x) && sign.coordinates.y === Number(y)
      )

      eX = coordinates.x + e.offsetX
      eY = coordinates.y + e.offsetY
    } else {
      eX = e.offsetX
      eY = e.offsetY
    }

    if (offsetClickDown) {
      eY = eY - (offsetClickDown.y - offsetClickDown.height)
      eX = eX - (offsetClickDown.x - offsetClickDown.width)
    }

    //Actualiza el arreglo con los datos actuales del elemento dropeado
    setCurrentDrop([{ newElement, element, eX, eY }])
  }

  const dd_mousedown = (e) => {
    let getContainerElement = e.target.parentElement
    if (getContainerElement.id === 'emailBox') {
      getContainerElement = getContainerElement.parentElement
    }

    let width = getContainerElement.offsetWidth / 2
    width += 10
    const height = getContainerElement.offsetHeight / 2

    offsetClickDown = { width, height, x: e.offsetX, y: e.offsetY }
  }

  const dd_mouseup = () => {
    offsetClickDown = null
  }

  useEffect(() => {
    //Recibe mediante el useEffect el dato actual a agregar
    const handleCurrentUpdate = (currentDrop) => {
      //Valida que haya un elemento valido arrastrable
      if (currentDrop.length !== 0 && currentDrop[0].element) {
        //ajust centra la caja para un mejor control del drop
        const adjustX = 77 * zoom
        const adjustY = 36.5 * zoom
        let codsX = currentDrop[0].eX - adjustX
        let codsY = currentDrop[0].eY - adjustY
        let actualPositions = signPositions

        if (currentSelect !== '') {
          let newA = []
          for (let signPosition of signPositions) {
            if (
              `${signPosition.user.email}%PXY%${signPosition.page}-${signPosition.coordinates.x}-${signPosition.coordinates.y}` !==
              currentSelect
            )
              newA = [...newA, signPosition]
          }
          actualPositions = newA
          setCurrentSelect('')
        }
        let newA = []
        let email = currentDrop[0].element.id.trim()
        let nEmail = email.split('%PXY%', 1)
        // let dateCreateSignatory = format(new Date(), 'dd/MM/yyyy')

        newA = [
          ...actualPositions,
          {
            user: {
              email: nEmail[0],
            },
            coordinates: { x: codsX, y: codsY },
            page,
            zoom,
          },
        ]

        setSignPositions(newA)
        signPositionsDOM = newA
      }
    }

    handleCurrentUpdate(currentDrop)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDrop])

  /*-------------------------------------------- 
    ORDER SIGNATORIES
  ----------------------------------------------*/
  useEffect(() => {
    if (sortStatus) {
      if (sortSigners) {
        sortSigners.destroy()
        setSortSigners(null)
        setSortStatus(false)
        handleOrder(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signers])

  //Activa o desactiva el ordenado de firmantes
  const handleOrder = (e) => {
    const czone = document.getElementById('orderSigners')
    if (!sortSigners) {
      const sortable = new Sortable(czone, {
        draggable: 'li',
        mirror: {
          constrainDimensions: true,
        },
        plugins: [Plugins.ResizeMirror],
      })

      sortable.on('sortable:sorted', (evt) => {
        try {
          const { newIndex, oldIndex } = evt?.data,
            source = String(evt?.data?.dragEvent?.data?.source?.id).replace(
              '_li',
              ''
            ),
            over = String(evt?.data?.dragEvent?.data?.over?.id).replace(
              '_li',
              ''
            )
          for (const signer of signers) {
            if (over === signer.email) signer.signerNumber = oldIndex
            if (source === signer.email) signer.signerNumber = newIndex
          }
          setSigners(signers)
        } catch (error) {
          console.log(error)
        }
      })
      setSortSigners(sortable)
      setSortStatus(e)
    } else {
      setSortStatus(e)
      sortSigners.destroy()
      setSortSigners(null)
    }
  }

  /*-------------------------------------------- 
    ADD NEW SIGNATORY
  ----------------------------------------------*/
  // Add the new value
  useEffect(() => {
    if (!isEmpty(nameFound)) {
      setDataNameSignatory(nameFound)
    }
  }, [nameFound])

  const handleAddSigner = () => {
    if (sortStatus) {
      if (sortSigners) {
        setSortStatus(false)
        sortSigners.destroy()
        setSortSigners(null)
      }
    }

    // Validate email
    const validateEmail = regexValidateEmail.test(
      String(dataEmailSignatory).toLowerCase()
    )

    // Validate data
    if (dataNameSignatory === '' || dataEmailSignatory === '') {
      setStateError({
        error_state: 'block',
        error_content: 'Debes ingresar el nombre y/o correo',
      })

      setTimeout(() => {
        setStateError({ error_state: 'none', error_content: '' })
      }, 2000)
    } else if (!validateEmail) {
      setStateError({
        error_state: 'block',
        error_content: 'Debes ingresar un correo válido',
      })

      setTimeout(() => {
        setStateError({ error_state: 'none', error_content: '' })
      }, 2000)
    } else {
      let addSigner = true

      for (let signer of signers) {
        if (signer.email === dataEmailSignatory) {
          setStateError({
            error_state: 'block',
            error_content:
              'Los correos de los firmantes deben de ser diferentes',
          })

          setTimeout(() => {
            setStateError({ error_state: 'none', error_content: '' })
          }, 2000)
          addSigner = false
          break
        }
      }

      if (addSigner) {
        let addUuidSigner = ''
        let dateCreateSignatory = format(new Date(), 'dd/MM/yyyy')
        // let hourCreateSignatory = format(new Date(), 'HH:mm:ss')

        if (!isEmpty(uuidFound)) {
          addUuidSigner = uuidFound
        }

        //Permite que si solo es un firmante, el uuid asignado sea el de la verifcacion
        if (signers.length === 0) addUuidSigner = uuid

        setSigners([
          ...signers,
          {
            uuid: addUuidSigner,
            name: dataNameSignatory,
            email: dataEmailSignatory,
            date: dateCreateSignatory,
            biometric: false,
            sdk: dataCheckCreateVerification,
            signerNumber: signers.length,
          },
        ])

        // Reset form
        setDataNameSignatory('')
        setDataEmailSignatory('')
        setDataSearchUUID('')
        setDataCheckCreateVerification(false)
        //Reset values
        setNameFound('')
        setUuidFound('')
        // Comprove signs
        handleComproveSigns()
      }
    }
  }

  /*-------------------------------------------- 
    ADD NEW OBSERVERS
  ----------------------------------------------*/
  const handleAddObserver = () => {
    if (dataNameObserver === '' || dataEmailObserver === '') {
      setStateErrorObserver({
        error_state: 'block',
        error_content: 'Debes ingresar el nombre y/o correo',
      })

      setTimeout(() => {
        setStateErrorObserver({ error_state: 'none', error_content: '' })
      }, 2000)
    } else {
      setObservers([
        ...observers,
        {
          name: dataNameObserver,
          email: dataEmailObserver,
        },
      ])
      // Reset form
      setDataNameObserver('')
      setDataEmailObserver('')
    }
  }

  /*-------------------------------------------- 
    COMPROVE SIGNATORIES
  ----------------------------------------------*/

  const handleComproveSigners = () => {
    // Zona A (PDF)
    const orderSigners = document.getElementById('orderSigners')
    if (orderSigners) {
      //Elimina los hijos ya existentes, Evita duplicados
      const tope = orderSigners.childElementCount
      for (let i = 0; i < tope; i++) {
        orderSigners.removeChild(orderSigners.firstChild)
      }
      //Apartir de Signers creara las tarjetas
      for (let signer of signers) {
        let mainLi = document.createElement('li')
        let mainInformationDiv = document.createElement('div')
        let informationDiv = document.createElement('div')
        let informationDivDivEmail = document.createElement('div')
        let informationDivTextEmail = document.createTextNode(`${signer.email}`)
        let informationDivDivName = document.createElement('div')
        let informationDivTextName = document.createTextNode(`${signer.name}`)
        let deleteDiv = document.createElement('div')
        let deleteButton = document.createElement('button')
        let deleteButtonText = document.createTextNode('×')
        // Verification
        let optionsDiv = document.createElement('div')
        let optionsDivIdentity = document.createElement('div')
        let checkIdentity = document.createElement('input')
        let spanIdentity = document.createElement('span')
        let textIdentity = document.createTextNode('Biometría Facial')

        mainLi.classList = 'mainLi'
        mainLi.id = `${signer.email}_li`
        mainLi.style.cursor = sortStatus ? 'pointer' : 'normal'

        informationDiv.classList = 'informationDiv'
        informationDivDivName.appendChild(informationDivTextName)
        informationDiv.appendChild(informationDivDivName)
        informationDivDivEmail.appendChild(informationDivTextEmail)
        informationDiv.appendChild(informationDivDivEmail)

        mainInformationDiv.classList = 'mainInformationDiv'
        mainInformationDiv.appendChild(informationDiv)

        deleteDiv.classList = 'deleteDiv'

        deleteButton.appendChild(deleteButtonText)
        deleteButton.classList = 'deleteButton'
        deleteButton.type = 'button'
        deleteButton.id = `${signer.email}_li `
        deleteButton.onclick = function (e) {
          setCurrentDelete([{ element: e.target }])
        }
        deleteDiv.appendChild(deleteButton)
        mainInformationDiv.appendChild(deleteDiv)

        optionsDiv.classList = 'optionsDiv'
        optionsDivIdentity.classList = 'optionsDivIdentity'

        spanIdentity.classList = 'spanOptionsText'
        spanIdentity.appendChild(textIdentity)
        optionsDivIdentity.appendChild(spanIdentity)

        // Identity
        checkIdentity.type = 'checkbox'
        checkIdentity.classList = 'checkIdentity'
        checkIdentity.checked = signer.biometric
        checkIdentity.id = `${signer.email}_li  `
        checkIdentity.onchange = function (e) {
          setCurrentVerification([{ element: e.target }])
        }
        optionsDivIdentity.appendChild(checkIdentity)

        //If sdk is false add the check to biometric
        if (!signer.sdk && signer.uuid) {
          optionsDiv.appendChild(optionsDivIdentity)
        }

        mainInformationDiv.appendChild(optionsDiv)
        mainLi.appendChild(mainInformationDiv)
        orderSigners.appendChild(mainLi)
      }
    }
  }

  const handleCurrentSignersVerification = (currentVerification) => {
    if (currentVerification.length !== 0) {
      let newA = []
      //Para actualizar el arreglo de firmantes
      for (let signer of signers) {
        if (`${signer.email}_li` !== currentVerification[0].element.id.trim())
          newA = [...newA, signer]
        else
          newA = [
            ...newA,
            {
              uuid: signer.uuid,
              name: signer.name,
              email: signer.email,
              biometric: !signer.biometric,
              sdk: signer.sdk,
            },
          ]
      }
      setSigners(newA)
    }
  }

  useEffect(() => {
    handleCurrentSignersVerification(currentVerification)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVerification])

  /*-------------------------------------------- 
    COMPROVE OBSERVERS
  ----------------------------------------------*/

  const handleComproveObserver = () => {
    const orderObserver = document.getElementById('orderObserver')
    if (orderObserver) {
      //Elimina los hijos ya existentes, Evita duplicados
      const childs = orderObserver.childElementCount
      for (let i = 0; i < childs; i++) {
        orderObserver.removeChild(orderObserver.firstChild)
      }
      //Apartir de observers creara las tarjetas
      for (let observer of observers) {
        let newSpan = document.createElement('span')
        let name_observer = document.createTextNode(`${observer.name}`)
        let email_observer = document.createTextNode(`${observer.email}`)
        newSpan.id = `${observer.email}_ctn`
        newSpan.classList = `${classes.divLi}`
        newSpan.style.cursor = 'normal'

        orderObserver.appendChild(newSpan)

        // Info signer
        let newSpanName = document.createElement('span')
        newSpanName.classList = classes.spanNameSigner
        newSpanName.appendChild(name_observer)

        let newSpanEmail = document.createElement('span')
        newSpanEmail.classList = classes.spanEmailSigner
        newSpanEmail.appendChild(email_observer)

        let newDivContentInfo = document.createElement('div')
        newDivContentInfo.classList = classes.contentInfoAddSign
        newDivContentInfo.appendChild(newSpanName)
        newDivContentInfo.appendChild(newSpanEmail)

        // Delete signer
        let newDiv = document.createElement('div')
        let newDivText = document.createTextNode('×')
        // newDiv.id = `${signer.email}_li `
        newDiv.classList = classes.delLi

        let deleteObserverBtn = document.createElement('span')
        deleteObserverBtn.appendChild(newDivText)
        deleteObserverBtn.id = `${observer.email}_li `
        deleteObserverBtn.classList = classes.btnDeleteSigner
        deleteObserverBtn.onclick = function (e) {
          setCurrentDeleteObserver([{ element: e.target }])
        }

        newDiv.appendChild(deleteObserverBtn)
        newSpan.appendChild(newDivContentInfo)
        newSpan.appendChild(newDiv)
      }
    }
  }

  useEffect(() => {
    handleComproveSigners()
    handleComproveObserver()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signers, sortStatus, observers])

  /*-------------------------------------------- 
    DELETE COMPONENTS
  ----------------------------------------------*/

  //Ayuda a eliminar un firmante manteniendo los datos actualizados
  const handleCurrentDelete = (currentDelete) => {
    if (currentDelete.length !== 0) {
      let newA = []
      let newAP = []
      //Para actualizar el arreglo de firmantes
      for (let signer of signers) {
        if (`${signer.email}_li` !== currentDelete[0].element.id.trim())
          newA = [...newA, signer]
      }
      //Para actualizar el arreglo de posiciones
      for (let signPosition of signPositions) {
        if (
          `${signPosition?.user?.email}_li` !==
          currentDelete[0].element.id.trim()
        )
          newAP = [...newAP, signPosition]
      }
      setSigners(newA)
      setSignPositions(newAP)
    }
  }

  const handleCurrentDeleteObservers = (currentDeleteObserver) => {
    if (currentDeleteObserver.length !== 0) {
      let newA = []
      //Para actualizar el arreglo de firmantes
      for (let observer of observers) {
        if (
          `${observer.email}_li` !== currentDeleteObserver[0].element.id.trim()
        )
          newA = [...newA, observer]
      }
      setObservers(newA)
    }
  }

  useEffect(() => {
    handleCurrentDelete(currentDelete)
    handleCurrentDeleteObservers(currentDeleteObserver)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDelete, currentDeleteObserver])

  /*-------------------------------------------- 
    SIGNATORIES
  ----------------------------------------------*/

  // Form signatory
  const handleSearchUUID = (e) => {
    const uuidClient = e?.target?.value
    setDataSearchUUID(uuidClient)
  }
  const handleNameSignatory = (e) => {
    const name = e?.target?.value
    setDataNameSignatory(name)
  }
  const handleEmailSignatory = (e) => {
    const email = e?.target?.value
    setDataEmailSignatory(email)
  }

  const handleChangeCheckCreateVerification = (e) => {
    const check = e?.target?.checked
    setDataCheckCreateVerification(check)
  }

  //Actions
  const handleCancelSignatory = () => {
    setDataNameSignatory('')
    setDataEmailSignatory('')
    setDataSearchUUID('')
    setDataCheckCreateVerification(false)
    setLoadingCreateVerification(false)

    //Reset values
    setNameFound('')
    setUuidFound('')

    setStateError({ error_state: 'none', error_content: '' })
  }

  const handleVerificationData = async () => {
    if (dataSearchUUID) {
      const token = await getToken({
        audience: 'veridocid-dashboard',
      })

      const dataVerification = await getVerificationSigner(
        token,
        dataSearchUUID
      )

      if (isEmpty(dataVerification)) {
        //Reset values
        setNameFound('')
        setUuidFound('')
        setStateError({
          error_state: 'block',
          error_content: `No se han encontrado datos sobre el uuid: ${dataSearchUUID}`,
        })
      } else {
        if (dataVerification?.status === 400) {
          //Reset values
          setNameFound('')
          setUuidFound('')
          setStateError({
            error_state: 'block',
            error_content: `No se puede agregar verificaciones, que no se han procesado correctamente`,
          })
        } else {
          // Result data
          setNameFound(dataVerification?.name)
          setUuidFound(dataVerification?.uuid)
          // Reset data
          setStateError({
            error_state: 'none',
            error_content: ``,
          })
        }
      }
    } else {
      //Reset values
      setNameFound('')
      setUuidFound('')
      setStateError({
        error_state: 'block',
        error_content: `Debe ingresar un uuid para obtener los datos`,
      })
    }
  }

  /*-------------------------------------------- 
    OBSERVERS
  ----------------------------------------------*/

  // Form signatory
  const handleNameObserver = (e) => {
    const name = e?.target?.value
    setDataNameObserver(name)
  }
  const handleEmailObserver = (e) => {
    const email = e?.target?.value
    setDataEmailObserver(email)
  }

  //Actions
  const handleCancelObserver = () => {
    setDataNameObserver('')
    setDataEmailObserver('')
    setStateErrorObserver({ error_state: 'none', error_content: '' })
  }

  // -------------------------------------- Geolocalization
  const handleStateGeolocation = (e) => {
    setDataGeolocation(e)
  }
  // -------------------------------------- Country
  const handleSelectCountry = (value) => {
    setDataCountry(value)
  }

  // -------------------------------------- expirationContract
  const handleSelectExpiration = (e) => {
    setContractExpiration(e?.target?.value)
  }
  // -------------------------------------- Who sign
  const handleOptionSign = (e) => {
    setWhoSign(e?.target?.value)
  }

  // -------------------------------------- Geolocation (me_others)
  const handleGeolocationMeOthers = (e) => {
    setStateGeolocation(e)

    if (!e) {
      message.error(`${t('disabledGeo')}`)
    } else {
      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }

      const successGeo = async (pos) => {
        const getIp = await getIP()
        setGeolocation(`${pos.coords.latitude}, ${pos.coords.longitude}`)
        if (getIp) {
          setIp(getIp?.IPv4)
          message.success(`${t('enabledGeo')}`)
        } else {
          const resGetIP2 = await getIPOption2()

          if (resGetIP2) {
            setIp(resGetIP2.ip)
            message.success(`${t('enabledGeo')}`)
          } else
            message.error(
              'Hemos tenido un problema al intentar obtener su geolocalización, intentelo nuevamente'
            )
        }
      }
      const errorGeo = (err) => {
        if (err) {
          setStateGeolocation(false)
          message.error('Debe activar la geolocalización para continuar')
        }
      }

      navigator.geolocation.getCurrentPosition(successGeo, errorGeo, options)
    }
  }

  /*-------------------------------------------- 
    SIGN DOCUMENT - ME
  ----------------------------------------------*/
  const handleSignContractMe = async (participantId, uuidSign, documentID) => {
    try {
      const token = await getToken({
        audience: 'veridocid',
      })

      let leftMargin = 22
      let newA = []

      if (signMe.option === 3) {
        leftMargin = 9
      }

      let internalSignPositions = []
      //Evita que haya firmas en hojas inexistentes
      for (let signPosition of signPositions)
        if (signPosition.page <= numPages)
          internalSignPositions.push(signPosition)

      for (let signPosition of internalSignPositions) {
        if (signPosition.user.email === useAuth0.email)
          newA.push({
            user: {
              email: useAuth0.email,
            },
            coordinates: {
              x: signPosition.coordinates.x + leftMargin,
              y: signPosition.coordinates.y + 19,
            },
            page: signPosition.page,
          })
      }

      // const url = `${API_URL_V3}/add_sign`
      const url = `${API_URL_V3}/add-sign`
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: idClient,
          uuid: uuidSign,
          documentID,
          participantId,
          staticSignPositions: newA,
          imageB64: signMe.signature,
          ubicacion: geolocation,
          ip,
        }),
      })

      const reponseJSON = await response.json()
      if (response.ok) {
        message.success(`${t('signedDocumentSuccess')}`)
        handleSendEmail(documentID)
      } else {
        setLoadingSignatory(false)
        setLoadingFixedSignatory(false)
        message.error(`${reponseJSON?.error}`)
      }
      setLoadingSignatory(false)
      setLoadingFixedSignatory(false)
    } catch (error) {
      setLoadingSignatory(false)
      setLoadingFixedSignatory(false)
      message.error(`${t('messageErrorData')}`)
    }
  }

  /*-------------------------------------------- 
    SIGN DOCUMENT
  ----------------------------------------------*/
  // const [createEmail, setCreateEmail] = useState('')
  const [titleMessage, setTitleMessage] = useState('')
  const [contentMessage, setContentMessage] = useState('')
  const [statusError, setStatusError] = useState({
    error_msg: '',
    error_status: false,
    error_visible: 'none',
  })

  // Form send email
  /* const handleInputCreateEmail = (e) => {
    setCreateEmail(e?.target?.value)
  } */

  const handleChangeTitle = (e) => {
    const title = e?.target?.value
    setTitleMessage(title)
  }

  const handleChangeMessage = (e) => {
    const msg = e?.target?.value
    setContentMessage(msg)
  }

  // Sign
  const handleSendEmail = async (documentID) => {
    setLoadingSignatory(true)

    // Only get signers data
    let arraySignatories = []

    signers.length > 0 &&
      signers.map((data) =>
        arraySignatories.push({ name: data?.name, email: data?.email })
      )

    const token = await getToken({ audience: 'veridocid' })

    const data = {
      id: idClient,
      uuid,
      documentID,
      message: contentMessage,
      title: titleMessage,
      signatory: arraySignatories,
      sharedWith: [...observers],
    }

    const resSignatory = await signatory(token, data)

    if (resSignatory) {
      if (resSignatory.error) {
        setLoadingSignatory(false)
        setLoadingFixedSignatory(false)
        if (isArray(resSignatory?.error?.message))
          message.error(`${resSignatory.error?.message[0]}`)
        else message.error(`${resSignatory.error?.message}`)
      }

      if (resSignatory.status === 200) {
        setContentMessage('')
        setTitleMessage('')
        message.success(`${t('sendEmails')}`)
        replace(`/resultado-contrato/${documentID}/${uuid}/${idClient}`)
      }
    } else {
      setLoadingSignatory(false)
      setLoadingFixedSignatory(false)
      message.error(`${t('messageErrorData')}`)
    }
  }

  /*-------------------------------------------- 
    SAVE SIGNATORIES AND OBSERVERS
  ----------------------------------------------*/
  const saveDataSignatory = async () => {
    if (contentMessage === '' || titleMessage === '') {
      message.error(`${t('addTitleMessage')}`)

      setStatusError({
        error_msg: `${t('addTitleMessage')}`,
        error_status: true,
        error_visible: 'block',
      })

      setTimeout(() => {
        setStatusError({
          error_msg: `${t('addTitleMessage')}`,
          error_status: false,
          error_visible: 'none',
        })
      }, 2500)
      setLoadingFixedSignatory(false)
    } else {
      // Empty message
      setStatusError({
        error_msg: '',
        error_status: false,
        error_visible: 'none',
      })

      // Loading button
      setLoadingFixedSignatory(true)

      const token = await getToken({
        audience: 'veridocid',
      })

      /*-------------------------- 
        Save configurations
      -------------------------- */
      const addDoc = {
        id: idClient,
        uuid,
        country: dataCountry,
        geo: dataGeolocation,
        language: 'es',
        signType: 'ELECTRONIC_SIGNATURE',
        document: fileBase64.replace('data:application/pdf;base64,', ''),
        documentName: file.name
          .replace('.docx', '.pdf')
          .replace('.doc', '.pdf'),
        create_name: useAuth0.name,
        create_email: useAuth0.email,
        create_ip: ip,
        create_ubication: geolocation,
        who_sign: whoSign,
        expiresIn: contractExpiration,
        signSize: {
          width: Number(newWidth),
          height: Number(newHeigth),
        },
        hasBorder: onBorder,
        logoSign: signBase64,
      }

      const dataSaveDocument = await addDocument(token, addDoc)

      // Si la respuesta del EP es diferemte de null se pasa a guardar los firmantes
      if (dataSaveDocument !== null) {
        message.success(`${t('addDocumentSuccess')}`)
        /*-------------------------- 
          Add signatories
        -------------------------- */
        let newA = []
        let internalSignPositions = []

        //Evita que haya firmas en hojas inexistentes
        for (let signPosition of signPositions)
          if (signPosition.page <= numPages)
            internalSignPositions.push(signPosition)

        //Independientemente del zoom las posiciones de la caja no cambiaran --> En este punto se convierten las coordenadas a escala 1/1 y se remueve el atributo zoom
        for (let signPosition of internalSignPositions)
          newA.push({
            coordinates: {
              x: signPosition.coordinates.x / signPosition.zoom,
              y: signPosition.coordinates.y / signPosition.zoom,
            },
            page: signPosition.page,
            user: signPosition.user,
          })

        // Data de pendiendo de quien firma
        var bodyOptions
        if (whoSign === 'me_others' && signMe.signature !== '') {
          bodyOptions = {
            id: idClient,
            uuid,
            documentID: dataSaveDocument.documentID,
            staticSignPositions: newA,
            participants: [
              ...signers,
              {
                // uuid: null,
                name: useAuth0.name,
                email: useAuth0.email,
                date: signMe.currentDate,
                biometric: false,
                sdk: false,
                signerNumber: 0,
              },
            ],
            observers: [...observers],
            isSorted: sortStatus,
          }
        } else {
          bodyOptions = {
            id: idClient,
            uuid,
            documentID: dataSaveDocument.documentID,
            staticSignPositions: newA,
            participants: [...signers],
            observers: [...observers],
            isSorted: sortStatus,
          }
        }

        // Si existe el documentID se pasa a almacenar a almacenar a los firmantes
        if (dataSaveDocument?.documentID) {
          const fixedSign = await fixedSignatory(token, bodyOptions)

          if (fixedSign !== null) {
            message.success(`${t('fixedSignatorySuccess')}`)

            if (fixedSign?.participants) {
              let participantId = ''
              for (let participant of fixedSign.participants) {
                if (participant.email === useAuth0.email) {
                  participantId = participant.participantId
                  break
                }
              }

              // Me&Others
              if (signMe.signature && signMe.signature !== '') {
                await handleSignContractMe(
                  participantId,
                  fixedSign?.uuid,
                  dataSaveDocument?.documentID
                )
              } else {
                // Send email
                handleSendEmail(dataSaveDocument?.documentID)
              }
            }
          } else {
            setLoadingFixedSignatory(false)
            message.error(`${t('errorAddSignatories')}`)
          }
        }
      } else {
        setLoadingFixedSignatory(false)
        message.error(`${t('messageErrorData')}`)
      }
    }
  }

  // Rubrica
  const handleRubric = (e) => {
    setRubric(e.target.checked)

    if (e.target.checked) {
      let internalSigners = []
      let internalPageSizeX = parseInt(pageSize[0].replace('px', '')) - 136
      let internalPageSizeY = parseInt(pageSize[1].replace('px', '')) - 73

      if (whoSign !== 'others')
        internalSigners.push({
          email: user.email,
          name: user.name,
        })
      if (signers.length > 0) internalSigners.push(...signers)

      let newA = []
      let x = 20 * zoom
      let y = 10 * zoom

      for (let page = 0; page < numPages; page++) {
        x = 20 * zoom
        y = 10 * zoom

        for (let signer of internalSigners) {
          newA.push({
            user: { email: signer.email, name: signer.name },
            coordinates: { x, y },
            page: page + 1,
            zoom,
            rubric: true,
          })

          y = y + 78 * zoom

          if (y > internalPageSizeY) {
            x = internalPageSizeX * zoom
            y = 10 * zoom
          }
        }
      }
      newA.push(...signPositions)
      setSignPositions(newA)
    } else {
      let newA = []
      for (let signPosition of signPositions)
        if (!signPosition.rubric) newA.push(signPosition)
      setSignPositions(newA)
    }
  }

  return (
    <Grid container className={classes.Container}>
      {alertContract.open ? (
        <Alert alert={alertContract} setAlert={setAlertContract} />
      ) : null}
      <Dimmer active={loading} inverted>
        <Loader>Procesando Documento ...</Loader>
      </Dimmer>
      <Grid item xs={fileBase64 === 'false' ? 12 : 8}>
        <Grid container className={classes.Tools}>
          <Grid item xs={4}>
            {fileBase64 !== 'false' ? (
              <Grid container>
                <Grid item xs={4} className={classes.Tool}>
                  {page > 1 ? (
                    <Button style={{ outline: 'none' }} onClick={previousPage}>
                      <VscArrowLeft size={24} color={'#eee'} />
                    </Button>
                  ) : null}
                </Grid>
                <Grid item xs={4} className={classes.Tool}>
                  <div>
                    {page || (numPages ? 1 : '--')} / {numPages || '--'}
                  </div>
                </Grid>
                <Grid item xs={4} className={classes.Tool}>
                  {page < numPages ? (
                    <Button style={{ outline: 'none' }} onClick={nextPage}>
                      <VscArrowRight size={24} color={'#eee'} />
                    </Button>
                  ) : null}
                </Grid>
              </Grid>
            ) : null}
          </Grid>
          <Grid item xs={4}>
            {fileBase64 !== 'false' ? (
              <Grid container>
                <Grid item xs={4} className={classes.Tool}>
                  <Button style={{ outline: 'none' }} onClick={zoomOut}>
                    <VscZoomOut size={24} color={'#eee'} />
                  </Button>
                </Grid>
                <Grid item xs={4} className={classes.Tool}>
                  <div>{parseInt(zoom * 100)}%</div>
                </Grid>
                <Grid item xs={4} className={classes.Tool}>
                  {zoom < 2 ? (
                    <Button style={{ outline: 'none' }} onClick={zoomIn}>
                      <VscZoomIn size={24} color={'#eee'} />
                    </Button>
                  ) : null}
                </Grid>
              </Grid>
            ) : null}
          </Grid>
          <Grid item xs={4} className={classes.MoreTools}>
            {fileBase64 !== 'false' ? (
              <Button style={{ outline: 'none' }} onClick={clickPDF}>
                <VscFilePdf size={24} color={'#eee'} strokeWidth={0.2} />
                <input
                  style={{ display: 'none' }}
                  accept=".pdf,.doc,.docx"
                  type="file"
                  name="file"
                  id="file"
                  multiple
                  onChange={changeInput}
                />
              </Button>
            ) : null}
            {/* <Button style={{ outline: 'none' }}>
              <VscCopy size={24} color={'#eee'} />
            </Button> */}
            {/* <Button style={{ outline: 'none' }}>
              <VscGear size={24} color={'#eee'} strokeWidth={0.3} />
            </Button> */}
          </Grid>
        </Grid>
        {fileBase64 === 'false' ? (
          <Grid
            item
            xs={12}
            className={classes.Contract}
            id="drop-zone"
            onClick={clickPDF}
            onDrop={(e) => dropPDF(e)}
            onDragOver={(e) => handleDragOver(e)}
            onDragEnter={(e) => handleDragEnter(e)}
            onDragLeave={(e) => handleDragLeave(e)}
          >
            <input
              style={{ display: 'none' }}
              accept=".pdf,.doc,.docx"
              type="file"
              name="file"
              id="file"
              multiple
              onChange={changeInput}
            />
            <VscFilePdf size={90} color={'var(--secondary-color)'} />
            <label className={classes.uploadPDF}>{`${t('uploadPdf')}`}</label>
            <label className={classes.instructions}>
              {`${t('clickOrDragPDF')}`}
            </label>
          </Grid>
        ) : (
          <Grid item xs={12} className={classes.showPDF} id="pdf_renderer">
            <Document file={fileBase64} onLoadSuccess={onDocumentLoadSuccess}>
              <Page
                pageNumber={page}
                scale={zoom}
                onRenderSuccess={(e) => handleRenderSuccess(e)}
              />
            </Document>

            <Grid
              item
              className={classes.ddzone}
              id="ddzone"
              onDragStart={(e) => dd_dragstart(e)}
              style={{ width: pageSize[0], height: pageSize[1] }}
            ></Grid>
          </Grid>
        )}
      </Grid>
      {fileBase64 !== 'false' ? (
        <Grid item xs={4} className={classes.Right}>
          <Grid className={classes.Right_Title}>{`${t(
            'configurations'
          )}`}</Grid>
          <Collapse
            // defaultActiveKey={documentID_BD ? ['2'] : ['1']}
            defaultActiveKey={['1', '2', '3']}
            onChange={callback}
            className={classes.Collapse_custom}
          >
            <Panel header="Principales" key="1">
              <span className="description_config_client">
                Configure sus opciones que mas se ajusten a sus necesidades
              </span>
              <form className={classes.config_form__main}>
                <div className={classes.config_form__two__column}>
                  {/* Country */}
                  <div className="config_form__content_input">
                    <label htmlFor="country" className="lbl_input">
                      País
                    </label>
                    <CountryDropdown
                      defaultOptionLabel={t('selectCountry')}
                      value={dataCountry}
                      priorityOptions={['MX', 'US', 'CA']}
                      onChange={handleSelectCountry}
                      className="select_country"
                    />
                  </div>
                  {/* Geolocalization */}
                  <div
                    className="config_form__content_input"
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <span
                      className="geolocalization lbl_input"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Tooltip title="Activa o desactiva la geolocalización de tus firmantes">
                        <VscInfo
                          color="rgb(140, 140, 140)"
                          size={16}
                          style={{ cursor: 'pointer', marginRight: '10px' }}
                        />
                      </Tooltip>{' '}
                      Geolocalización para firmante(s)
                    </span>
                    <Switch
                      defaultChecked
                      style={{ width: '14px', marginTop: '0.9em' }}
                      checked={dataGeolocation}
                      onChange={handleStateGeolocation}
                    />
                  </div>
                </div>
                {/* Who sign */}
                <div
                  className="config_form__content_input"
                  style={{ marginBottom: '1.6em' }}
                >
                  <label
                    htmlFor="who_sign"
                    className="lbl_input"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Tooltip
                      key="tooltip_who_sign"
                      title="Selecciona quien o quienes participaran en el contrato"
                    >
                      <VscInfo
                        color="rgb(140, 140, 140)"
                        size={16}
                        style={{ cursor: 'pointer', marginRight: '10px' }}
                      />
                    </Tooltip>{' '}
                    ¿Quién firma?
                  </label>
                  <Radio.Group
                    defaultValue={'others'}
                    name="who_sign"
                    onChange={handleOptionSign}
                    value={whoSign}
                  >
                    <Radio value={'me_others'}>Yo y otros</Radio>
                    <Radio value={'others'}>Otros</Radio>
                  </Radio.Group>
                </div>
                <div className="config_form__content_input">
                  <label htmlFor="country" className="lbl_input">
                    Caducidad del contrato
                  </label>

                  <select
                    name="selectExpiration"
                    className="select_country"
                    onChange={handleSelectExpiration}
                  >
                    <option value={10}>10 días</option>
                    <option value={20}>20 días</option>
                    <option value={30}>30 días</option>
                  </select>
                </div>

                {/* <div
                  className="config_form__content_input"
                  style={{ marginBottom: '1.6em' }}
                >
                  <label
                    htmlFor="who_sign"
                    className="lbl_input"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Tooltip
                      key="tooltip_who_sign"
                      title="Coloca un recuadro de firma en todas las paginas"
                    >
                      <VscInfo
                        color="rgb(140, 140, 140)"
                        size={16}
                        style={{ cursor: 'pointer', marginRight: '10px' }}
                      />
                    </Tooltip>{' '}
                    Posicion Especifica
                  </label>

                  <Checkbox
                    onChange={(e) => {
                      setSpecificPosition(e?.target?.checked)
                      console.log('e?.target?.value', e?.target?.checked)
                      console.log(specificPosition)
                    }}
                  >
                    {specificPosition == true ? 'Desactivar' : 'Activar'}
                  </Checkbox>
                </div> */}
              </form>
            </Panel>
            <Panel header="Firmantes y observadores" key="2">
              <ConfigClienteSign
                // Signatories
                signers={signers}
                changeInputSearch={handleSearchUUID}
                changeInputNameSignatory={handleNameSignatory}
                changeInputEmailSignatory={handleEmailSignatory}
                onChangeCheckCreateVerification={
                  handleChangeCheckCreateVerification
                }
                searchUUID={dataSearchUUID}
                nameSignatory={
                  nameFound ? _.capitalize(nameFound) : dataNameSignatory
                }
                foundNameVerification={nameFound ? true : false}
                emailSignatory={dataEmailSignatory}
                checkCreateVerification={dataCheckCreateVerification}
                addNewSignatory={handleAddSigner}
                cancelNewSignatory={handleCancelSignatory}
                handleVerificationData={handleVerificationData}
                set_visibility_error={stateError.error_state}
                set_msg_error={stateError.error_content}
                // Effects
                stateLoadingAddSigner={loadingCreateVerification}
                // Order signatories
                statusSwitchSort={
                  document.getElementById('orderSigners') &&
                  document.getElementById('orderSigners').childElementCount ===
                    0
                    ? true
                    : false
                }
                handleOrder={handleOrder}
                sortStatus={sortStatus}
                stateTextSort={sortStatus ? 'Ordenable' : 'No ordenable'}
                // Observer
                changeInputNameObserver={handleNameObserver}
                changeInputEmailObserver={handleEmailObserver}
                nameObserver={dataNameObserver}
                emailObserver={dataEmailObserver}
                addNewObserver={handleAddObserver}
                cancelNewObserver={handleCancelObserver}
                set_visibility_error__observer={stateErrorObserver.error_state}
                set_msg_error__observer={stateErrorObserver.error_content}
                // Message email
                handleChangeTitle={handleChangeTitle}
                titleMessage={titleMessage}
                handleChangeMessage={handleChangeMessage}
                contentMessage={contentMessage}
                visibleErrorMessageEmail={statusError.error_status}
                classErrorMessageEmail={statusError.error_visible}
                contentErrorMessageEmail={statusError.error_msg}
              />
            </Panel>
            <Panel header="Firma" key="3">
              {/* Yo y otros */}
              {whoSign === 'me_others' ? (
                <>
                  <h5 className={classes.title_signatories}>Agrega tu firma</h5>
                  <span className="description_config_client">
                    Seleccione el formato para su firma y ubique en donde la
                    quiere visualizar.
                  </span>
                  <br />
                  {/* Me sign */}
                  <div className={classes.signMeData}>
                    <div className={classes.dataMe}>
                      <p>{useAuth0.name}</p>
                      <p>{useAuth0.email}</p>
                    </div>
                    <span
                      className={classes.actionMe}
                      onClick={handleShowFormatSign}
                    >
                      Firmar
                    </span>
                  </div>

                  {/* Geolocalization */}
                  <div
                    className="config_form__content_input"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexWrap: 'wrap',
                    }}
                  >
                    <span className="geolocalization lbl_input">
                      <Tooltip title="Activa o desactiva tu geolocalización para mostrar desde dónde se creó el contrato">
                        <VscInfo
                          color="rgb(140, 140, 140)"
                          size={16}
                          style={{ cursor: 'pointer', marginRight: '10px' }}
                        />
                      </Tooltip>
                      Tu geolocalización
                    </span>
                    <Switch
                      // defaultChecked
                      style={{ width: '14px', marginTop: '0.9em' }}
                      checked={stateGeolocation}
                      onChange={handleGeolocationMeOthers}
                    />
                  </div>

                  {/* Drag zone sign */}
                  <div
                    className={classes.ddzoneTest}
                    id="ddzoneTest"
                    onDragStart={(e) => dd_dragstart(e)}
                  />
                </>
              ) : null}

              {/* Others */}
              <h5 className={classes.title_signatories}>Firmante(s)</h5>
              <span className="description_config_client">
                Ubique en donde quiere visualizar la firma de sus firmantes
              </span>
              {signers.length !== 0 || signPositions.length !== 0 ? (
                <Rubric className="signers_Li">
                  <div>
                    <Tooltip title="Habilita esta opción para poder agregar las firmas en todas las hojas">
                      <VscInfo
                        color="rgb(140, 140, 140)"
                        size={16}
                        style={{
                          cursor: 'pointer',
                          marginRight: '6px',
                        }}
                      />
                    </Tooltip>
                    <span>Rubricar</span>
                  </div>
                  <input
                    type="checkbox"
                    checked={rubric}
                    onChange={handleRubric}
                    style={{ marginLeft: '12px' }}
                  />
                </Rubric>
              ) : null}
              {/* Drag and drop sign */}
              <div
                className={classes.ddzoneB}
                id="ddzoneB"
                onDragStart={(e) => dd_dragstart(e)}
              ></div>
            </Panel>
            <Panel
              header="Configuracion de firma"
              key="4"
              style={{
                backgroundColor: ' rgba(120,120,120,0.1)',
                color: ' rgba(0,0,0,.87)',
              }}
            >
              <SignatureSettings
                setImageBoxState={setImageBoxState}
                setNewWidth={setNewWidth}
                setNewHeigth={setNewHeigth}
                newWidth={newWidth}
                newHeigth={newHeigth}
                boxSign={boxSign}
                setOnBorder={setOnBorder}
                onBorder={onBorder}
                setSignBase64={setSignBase64}
                signBase64={signBase64}
              />
            </Panel>
          </Collapse>

          {/* Select format sign */}
          {open ? (
            <ModalSign open={open} setOpen={setOpen} setSign={setSignMe} />
          ) : null}
          {/* Actions */}
          <Grid className={classes.Final}>
            {/* Save signatories and observers */}
            <ActionConfig
              type="button"
              className={classes.save_configurations_solid}
              onClick={() => saveDataSignatory()}
              loading={loadingFixedSignatory || loadingSignatory}
              disabled={
                signers.length === 0 || signPositions.length === 0
                  ? true
                  : false
              }
            >
              Enviar
            </ActionConfig>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  )
}
