import React, { useEffect, useState } from 'react'
import { Router, Switch } from 'react-router-dom'
import { Container } from 'reactstrap'
import { useAuth0 } from './react-auth0-spa'

// Utils
import history from './utils/history'
import { getCustomColors } from './utils/profileServices'
import useToken from './utils/useToken'

// Components
import EditContract from './components/ContractsView/EditContract'
import ResultContract from './components/ContractsView/ResultContract'
import Footer from './components/Footer'
import Loading from './components/Loading'
import NavBar from './components/NavBar'
import PrivateRoute from './components/PrivateRoute'
import AprovalTable from './views/AprovalTable'
import ClientAllDataShow from './views/ClientAllDataShow'
import ContractReview from './views/ContractReview'
import Contract from './views/Contracts'
import ContractsAllDataShow from './views/ContractsAllDataShow'
import ContractsToSign from './views/ContractsToSign'
import Documentation from './views/Documentation'
import FormToSendVerification from './views/FormToSendVerification'
import Home from './views/Home'
import NewContract from './views/NewContract'
import NewTemplate from './views/NewTemplate'
import NotFound from './views/NotFound'
import PanelExperts from './views/PanelExperts'
import PreviewContracts from './views/PreviewMasive'
import Profile from './views/Profile'
import SignTemplate from './views/SignTemplate'
import TemplatesAllDataShow from './views/TemplatesAllDataShow'
import TemplateSelection from './views/TemplateSelection'
import VerificationResults from './views/VerificationResults'

// Styles
import 'semantic-ui-css/semantic.css'
import './App.css'

let cssRoot = document.querySelector(':root')

const App = () => {
  const { loading, isAuthenticated } = useAuth0()
  const getToken = useToken()
  const [initProject, setInitProject] = useState(false)

  const getColors = async () => {
    const token = await getToken({
      audience: 'veridocid',
    })
    const customColors = await getCustomColors(token)

    if (customColors !== null && customColors?.result !== null) {
      actualColors(
        customColors?.result?.dashboardColor,
        customColors?.result?.primary_color ?? '#faa82c',
        customColors?.result?.secondary_color ?? '#373A8A'
      )
    } else {
      actualColors('#eff1f5', '#faa82c', '#373A8A')
    }
  }

  const actualColors = (dashboard, primary, secondary) => {
    cssRoot.style.setProperty('--dashboard-color', `${dashboard}`)
    cssRoot.style.setProperty('--primary-color', `${secondary}`)
    cssRoot.style.setProperty('--secondary-color', `${primary}`)
  }

  useEffect(() => {
    if (!initProject && isAuthenticated) {
      setInitProject(true)
      getColors()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  if (loading) {
    return <Loading />
  }

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar />
        <Container className="mt-5 flex-grow-1">
          <Switch>
            <PrivateRoute path="/" exact component={Home} />
            <PrivateRoute
              path="/contratos"
              exact
              component={ContractsAllDataShow}
            />
            <PrivateRoute
              path="/contratos/nuevo-contrato"
              exact
              component={Contract}
            />
            <PrivateRoute
              path="/contratos/plantillas"
              exact
              component={TemplatesAllDataShow}
            />
            <PrivateRoute
              path="/contratos/plantillas/nueva-plantilla"
              exact
              component={NewTemplate}
            />
            <PrivateRoute
              path="/contratos/plantillas/firma-plantilla/:uuidTemplate"
              exact
              component={SignTemplate}
            />
            <PrivateRoute
              path="/contratos/plantillas/mesa/:documentID/:uuid/:id"
              exact
              component={ContractReview}
            />
            <PrivateRoute
              path="/firma-masiva"
              exact
              component={ContractsToSign}
            />
            <PrivateRoute
              path="/firma-masiva/contratos"
              exact
              component={PreviewContracts}
            />
            <PrivateRoute
              path="/contratos/plantillas/mesa"
              exact
              component={AprovalTable}
            />
            <PrivateRoute
              path="/verificar"
              component={FormToSendVerification}
            />
            <PrivateRoute path="/documentation" component={Documentation} />
            <PrivateRoute
              path="/resultados/:uuid"
              component={ClientAllDataShow}
            />
            <PrivateRoute
              path="/nuevo-contrato/:id/:uuid"
              component={NewContract}
            />
            <PrivateRoute
              path="/resultado-contrato/:documentID/:uuid/:id"
              component={ResultContract}
            />
            <PrivateRoute
              path="/editar-contrato/:documentID/:uuid/:id"
              component={EditContract}
            />
            <PrivateRoute path="/profile" component={Profile} />
            <PrivateRoute
              path="/panelexperts/:type"
              role="PanelOfExperts"
              component={PanelExperts}
            />
            <PrivateRoute
              path="/verificaciones/:type/:uuid"
              component={VerificationResults}
            />
            <PrivateRoute
              path="/seleccion/:type/:uuid"
              component={TemplateSelection}
            />
            <PrivateRoute path="" component={NotFound} />
          </Switch>
        </Container>
        <Footer />
      </div>
    </Router>
  )
}

export default App
