import React, { useState } from 'react'
import {
  VscAdd,
  VscCircleSlash,
  VscSearch,
  VscChevronRight,
  VscInfo,
} from 'react-icons/vsc'
import { Tooltip } from 'antd'
import { Button as BtnSigner } from 'semantic-ui-react'
import './FormAddStyles.css'

// Text mask
import MaskedInput from 'react-text-mask'
import emailMask from 'text-mask-addons/dist/emailMask'

const FormAddSignatory = ({
  signers,
  onSearchIdentifier,
  onChangeNameSign,
  onChangeEmailSign,
  onChangeCheckCreateVerification,
  valueSearchIdentifier,
  valueNameSign,
  valueEmailSign,
  checkCreateVerification,
  handleVerificationData,
  onAddSign,
  onCancelSign,
  // Effects
  stateLoadingAddSigner,
  // Validation
  foundNameVerification,
  // Error
  msg_visibility,
  content_msg_visibility,
  // Collapse
  stateCollapse,
}) => {
  // Collapse - complemento para ver y ocultar el buscador
  const [isCollapsedUUID, setIsCollapsedUUID] = useState(false)
  const showSearch = () => {
    setIsCollapsedUUID(!isCollapsedUUID)
  }

  // Component
  return (
    <div
      // className="form_add"
      className={`collapse-content ${
        stateCollapse ? 'collapsed' : 'expanded'
      } form_add`}
      aria-expanded={stateCollapse}
    >
      {/* Find by uuid  - buscador por uuid en el caso que se requiera se habilitara*/}
      {signers.length > 0 ? (
        <div className="content-input_search">
          <div className="content_label_search">
            <label htmlFor="uuid_Signatory">Buscar por UUID</label>
            <Tooltip title="Se puede hacer una búsqueda de verificaciones mediante el uuid y agregarlo como firmante">
              <button
                type="button"
                className={`show_search_uuid ${
                  isCollapsedUUID ? 'rotateOrigin' : 'rotate90'
                }`}
                onClick={() => showSearch()}
              >
                <VscChevronRight />
              </button>
            </Tooltip>
          </div>
          <div
            className={`content_search ${
              isCollapsedUUID ? 'collapsed' : 'expanded'
            }`}
            aria-expanded={isCollapsedUUID}
          >
            <input
              type="text"
              name="uuid_Signatory"
              placeholder='Buscar uuid'
              onChange={onSearchIdentifier}
              value={valueSearchIdentifier}
            />
            <button
              type="button"
              className="btn_action_search"
              onClick={handleVerificationData}
            >
              <VscSearch color="white" size={15} strokeWidth={1} />
            </button>
          </div>
        </div>
      ) : null}
      {/* Name */}
      <div className="content-input_add">
        <label htmlFor="name_Signatory">Nombre</label>
        <input
          type="text"
          name="name_Signatory"
          onChange={onChangeNameSign}
          value={valueNameSign}
          disabled={foundNameVerification}
          placeholder="Nombre del firmante"
        />
      </div>
      {/* Email */}
      <div className="content-input_add">
        <label htmlFor="email_signatory">Correo</label>
        <MaskedInput
          name="email_signatory"
          keepCharPositions={false}
          mask={emailMask}
          guide={true}
          placeholder="Correo del firmante"
          onChange={onChangeEmailSign}
          value={valueEmailSign}
        />
      </div>
      {/* Añadir verificación */}
      {signers.length > 0 ? (
        <div
          className="select_other_options"
          style={{
            backgroundColor: valueSearchIdentifier ? '#dbdbdb' : 'white',
            transition: 'ease all 600ms',
            opacity: valueSearchIdentifier ? '0.5' : '1',
          }}
        >
          <div className="info_select_verification">
            <Tooltip title="Si requieres que la persona haga una verificación antes de firmar, puedes habilitar esta opción">
              <VscInfo />
            </Tooltip>
            <span>Verificación</span>
          </div>
          <div className="content_custom_checked">
            <input
              disabled={valueSearchIdentifier ? true : false}
              type="checkbox"
              name="check_verification"
              // value={checkCreateVerification}
              onChange={onChangeCheckCreateVerification}
              checked={checkCreateVerification}
            />
          </div>
        </div>
      ) : null}
      {/* Actions */}
      <div className="actions_add">
        <BtnSigner
          type="button"
          className="new_add"
          loading={stateLoadingAddSigner}
          onClick={onAddSign}
        >
          <VscAdd
            color={stateLoadingAddSigner ? '#ffa826' : 'white'}
            size={20}
            strokeWidth={1}
          />
        </BtnSigner>
        <button type="button" className="cancel_add" onClick={onCancelSign}>
          <VscCircleSlash color="white" size={20} strokeWidth={1} />
        </button>
      </div>
      {/* Message */}
      <div className="message_error" style={{ display: `${msg_visibility}` }}>
        <h5 className="title_msg_error" style={{ color: '#ff6363' }}>
          Firmantes
        </h5>
        <p className="content_msg_error">{content_msg_visibility}</p>
      </div>
    </div>
  )
}

export default FormAddSignatory
