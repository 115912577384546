import React from 'react'

import './EditDocumentData.css'
import {
  labels,
  menuOptions,
} from '../../../../../fixtures/panelExperts.fixture'
import { mapOptions } from '../../../../../shared/maps'

const EditDocumentData = (props) => {
  const rows = props.data?.map((resp, index) => (
    <tr key={index}>
      <td className="RowsEditTable">{resp?.name}</td>
      <td className="RowsEditTable">
        <input type="text" defaultValue={resp?.value} />
      </td>
      <td className="RowsEditTable">
        <button className="ButtonDelete">{labels.delete}</button>
      </td>
    </tr>
  ))

  const options = mapOptions(menuOptions)

  return (
    <div className="EditDocumentData">
      <table className="EditTable">
        <tbody>{rows}</tbody>
      </table>
      <div className="AddDocumentData">
        <p>{labels.addDocumentData}</p>
        <table className="TableAddDocumentData">
          <tbody>
            <tr>
              <td
                className="RowsAddDocumentData"
                style={{ width: '7rem', textAlign: 'center' }}
              >
                <p>
                  <b>{labels.field}:</b>
                </p>
              </td>
              <td className="RowsAddDocumentData">
                <select className="Select">{options}</select>
              </td>
              <td
                className="RowsAddDocumentData"
                style={{ width: '5rem', textAlign: 'center' }}
              >
                <p>{labels.value}:</p>
              </td>
              <td className="RowsAddDocumentData">
                <input type="text" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <button className="ButtonAdd">{labels.add}</button>
      <div>
        <button className="ButtonSave">{labels.save}</button>
        <button className="ButtonCancel" onClick={props.clicked}>
          {labels.cancel}
        </button>
      </div>
    </div>
  )
}

export default EditDocumentData
