import { find } from 'lodash'

// Images Id Verification
export const getImagesIdPath = (dataVersion, idVerification) => {
  const isAttachments = Number(dataVersion) >= 2

  const _anverso = isAttachments
    ? find(idVerification?.attachments, { name: 'frontImageCropped' })?.path ||
      find(idVerification?.attachments, { name: 'frontImage' })?.path
    : idVerification?.files?.frontImage
  const _reverso = isAttachments
    ? find(idVerification?.attachments, { name: 'backImageCropped' })?.path ||
      find(idVerification?.attachments, { name: 'backImage' })?.path
    : idVerification?.files?.backImage
  const _facial = isAttachments
    ? find(idVerification?.attachments, { name: 'faceImage' })?.path
    : idVerification?.files?.faceImage
  const _portrait = isAttachments
    ? find(idVerification?.attachments, { name: 'documentPortraitImage' })?.path
    : idVerification?.files?.faceImage

  return { _anverso, _reverso, _facial, _portrait }
}

// Images Security Scanner Verification
export const getImagesSecurityScannerPath = (securityScannerVerification) => {
  const _front =
    find(securityScannerVerification?.attachments, {
      name: 'frontImageCropped',
    })?.path ||
    find(securityScannerVerification?.attachments, {
      name: 'frontImage',
    })?.path

  const _frontInfrared =
    find(securityScannerVerification?.attachments, {
      name: 'frontInfraredImageCropped',
    })?.path ||
    find(securityScannerVerification?.attachments, {
      name: 'frontInfraredImage',
    })?.path

  const _frontUltraviolet =
    find(securityScannerVerification?.attachments, {
      name: 'frontUltravioletImageCropped',
    })?.path ||
    find(securityScannerVerification?.attachments, {
      name: 'frontUltravioletImage',
    })?.path

  const _back =
    find(securityScannerVerification?.attachments, {
      name: 'backImageCropped',
    })?.path ||
    find(securityScannerVerification?.attachments, {
      name: 'backImage',
    })?.path

  const _backInfrared =
    find(securityScannerVerification?.attachments, {
      name: 'backInfraredImageCropped',
    })?.path ||
    find(securityScannerVerification?.attachments, {
      name: 'backInfraredImage',
    })?.path

  const _backUltraviolet =
    find(securityScannerVerification?.attachments, {
      name: 'backUltravioletImageCropped',
    })?.path ||
    find(securityScannerVerification?.attachments, {
      name: 'backUltravioletImage',
    })?.path

  return {
    _front,
    _frontInfrared,
    _frontUltraviolet,
    _back,
    _backInfrared,
    _backUltraviolet,
  }
}

export const getImagesOriginalPath = (idVerification) => {
  const _frontOriginal = find(idVerification?.attachments, {
    name: 'frontImage',
  })?.path

  const _backOriginal = find(idVerification?.attachments, {
    name: 'backImage',
  })?.path

  return {
    _frontOriginal,
    _backOriginal,
  }
}
