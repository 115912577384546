import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { find } from 'lodash'
import { Table } from 'semantic-ui-react'
// import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai'
import { VscArrowDown, VscArrowUp, VscCheck, VscClose } from 'react-icons/vsc'
import { format } from 'date-fns'
import CopyIdPopup from '../../components/CopyIdPopup'
import {
  responseVersion1,
  responseVersion2,
} from '../../fixtures/dataResponse.fixture'
import { Loader } from 'semantic-ui-react'

export default function RegistersPc(props) {
  const { register, selected, setSelected } = props
  const { t } = useTranslation()
  const history = useHistory()

  const renderRegistros = (registro, index) => {
    let types
    let fullName

    if (Number(registro?.dataVersion) >= 2) types = responseVersion2
    else types = responseVersion1

    if (!registro?.data?.documentData) {
      const failedName =
        registro?.data?.status === 'Checked' ? 'Sin Datos' : 'Verificando'
      fullName = registro?.data?.fullName ?? failedName
    } else {
      const fullNameMrz = find(registro?.data?.documentData, {
        type: types.fullName,
      })?.value

      let name = find(registro?.data?.documentData, {
        type: types.name,
        source: types.visual,
      })?.value?.replace(/[\^]/g, ' ')

      let fatherSurname = find(registro?.data?.documentData, {
        type: types.fatherSurname,
      })?.value

      let motherSurname = find(registro?.data?.documentData, {
        type: types.motherSurname,
      })?.value

      fullName =
        find(registro?.data?.documentData, {
          type: types.fullName,
          source: types.visual,
        })?.value?.replace(/[\^]/g, ' ') || null

      if (!registro?.data) fullName = t('checking')
      else {
        if (fatherSurname && motherSurname && name && !fullName)
          fullName = `${fatherSurname} ${motherSurname} ${name}`
        else if (!fullName && !fullNameMrz) {
          const documentHolderFullName = find(registro?.data?.documentData, {
            type: 'DocumentHolderFullName',
          })?.value
          const surname = find(registro?.data?.documentData, {
            type: 'Surname',
          })?.value
          const name = find(registro?.data?.documentData, {
            type: types.name,
          })?.value
          const fallbackName =
            name && surname
              ? `${surname} ${name}`
              : registro?.data?.status === 'Checked'
              ? 'El documento no es legible'
              : registro?.data?.status === 'WaitingData'
              ? t('waitingData')
              : registro?.status === 'ManualIdentification'
              ? t('ManualIdentification')
              : registro?.status === 'ManualChecking'
              ? t('ManualChecking')
              : t('checking')

          fullName = documentHolderFullName || fallbackName
        } else if (!fullName) fullName = fullNameMrz
      }
    }

    const isPositive = registro.data && registro?.data?.globalResult === 'Ok'
    const isNegative = registro.data ? !isPositive : false

    const handleClick = () => history.push(`/resultados/${registro?.uuid}`)

    return (
      <Table.Row key={index} style={{ cursor: 'pointer' }}>
        <Table.Cell
          positive={isPositive}
          negative={isNegative}
          warning={!registro.data || registro?.status !== 'Checked'}
          verticalAlign="middle"
          textAlign="center"
          onClick={!registro.data ? null : handleClick}
        >
          {registro?.data?.startDateUtc ? (
            <>
              {format(new Date(registro?.data?.startDateUtc), 'dd/MM/yyyy')}{' '}
              {format(new Date(registro?.data?.startDateUtc), 'HH:mm:ss')}
            </>
          ) : (
            t('checking')
          )}
        </Table.Cell>

        <Table.Cell
          positive={isPositive}
          negative={isNegative}
          warning={!registro.data || registro?.status !== 'Checked'}
          verticalAlign="middle"
          textAlign="center"
          width="two"
        >
          <CopyIdPopup text={registro?.id} copy={registro?.uuid}></CopyIdPopup>
        </Table.Cell>

        <Table.Cell
          positive={isPositive}
          negative={isNegative}
          warning={!registro.data || registro?.status !== 'Checked'}
          verticalAlign="middle"
          textAlign="center"
          style={{ height: '50px' }}
          onClick={
            !registro.data || registro?.status !== 'Checked'
              ? null
              : handleClick
          }
        >
          {fullName}
        </Table.Cell>

        <Table.Cell
          positive={isPositive}
          negative={isNegative}
          warning={!registro.data || registro?.status !== 'Checked'}
          verticalAlign="middle"
          textAlign="center"
          onClick={
            !registro.data || registro?.status !== 'Checked'
              ? null
              : handleClick
          }
        >
          {registro?.status === 'ManualIdentification'
            ? t('ManualIdentification')
            : registro?.status === 'ManualChecking'
            ? t('ManualChecking')
            : registro?.status === 'WaitingData'
            ? t('waitingData')
            : !registro?.data || registro?.status !== 'Checked'
            ? t('checking')
            : registro?.data?.documentType ?? 'Sin datos'}
        </Table.Cell>

        <Table.Cell
          positive={isPositive}
          negative={isNegative}
          warning={!registro.data || registro?.status !== 'Checked'}
          verticalAlign="middle"
          textAlign="center"
          onClick={
            !registro.data || registro?.status !== 'Checked'
              ? null
              : handleClick
          }
        >
          {!registro?.data || registro?.status !== 'Checked' ? (
            <Loader
              indeterminate
              active
              inline
              style={{ backgroundColor: 'rgba(0,0,0,0)' }}
              size="small"
            />
          ) : registro?.data?.globalResult === 'Ok' ? (
            <VscCheck size={18} />
          ) : (
            <VscClose size={18} />
          )}
        </Table.Cell>
      </Table.Row>
    )
  }

  const selectCreatedAtD = () =>
    setSelected({
      createdAtD: true,
      createdAtA: false,
      idD: false,
      idA: false,
    })

  const selectCreatedAtA = () =>
    setSelected({
      createdAtD: false,
      createdAtA: true,
      idD: false,
      idA: false,
    })

  const selectIdD = () =>
    setSelected({
      createdAtD: false,
      createdAtA: false,
      idD: true,
      idA: false,
    })

  const selectIdA = () =>
    setSelected({
      createdAtD: false,
      createdAtA: false,
      idD: false,
      idA: true,
    })

  return (
    <Table
      inverted
      selectable
      fixed
      compact
      style={{ background: 'rgb(240, 240, 240)', color: '#303232' }}
    >
      <Table.Header style={{ backgroundColor: 'var(--primary-color)' }}>
        <Table.Row verticalAlign="middle">
          <Table.HeaderCell
            width={2}
            textAlign="center"
            onClick={
              selected.createdAtD
                ? selectCreatedAtA
                : selected.createdAtA
                ? selectCreatedAtD
                : selectCreatedAtD
            }
          >
            {t('date')}{' '}
            {selected.createdAtD ? (
              <VscArrowDown size={15} strokeWidth={1} cursor="pointer" />
            ) : selected.createdAtA ? (
              <VscArrowUp size={15} strokeWidth={1} cursor="pointer" />
            ) : null}
          </Table.HeaderCell>

          <Table.HeaderCell
            width={3}
            textAlign="center"
            onClick={
              selected.idD ? selectIdA : selected.idA ? selectIdD : selectIdD
            }
          >
            {t('clientId')}{' '}
            {selected.idD ? (
              <VscArrowDown size={15} strokeWidth={1} cursor="pointer" />
            ) : selected.idA ? (
              <VscArrowUp size={15} strokeWidth={1} cursor="pointer" />
            ) : null}
          </Table.HeaderCell>

          <Table.HeaderCell width={5} textAlign="center">
            {t('name')}
          </Table.HeaderCell>

          <Table.HeaderCell width={3} textAlign="center">
            {t('documentType')}
          </Table.HeaderCell>

          <Table.HeaderCell width={2} textAlign="center">
            {t('idResult')}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{register.map(renderRegistros)}</Table.Body>
    </Table>
  )
}
