import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
// Components
import RecordsFiltersProvider from '../context/RecordsFilters.provider'
import RegisterFilters from '../components/RegisterFilters'
import BtnStartVerification from '../components/BtnStartVerification'
import FilterByDate from '../components/AdvancedFilters/FilterByDate'
import ShowRegisters from '../components/ShowRegisters'
// Styles
import 'semantic-ui-css/semantic.css'
import '../styles/Home.css'
import { AiOutlineMore } from 'react-icons/ai'
import { Tooltip } from 'antd'

const Home = () => {
  const { t } = useTranslation()
  const [filters, setFilters] = useState([])

  // Collapse
  const [isCollapseMoreOptions, setIsCollapseMoreOptions] = useState(true)
  const showMoreOptions = () => {
    setIsCollapseMoreOptions(!isCollapseMoreOptions)
  }

  //Busca en el arreglo filters si el filtro existe, regresa valores booleanos
  const alreadyExist = (option) => {
    let xd = filters.length
    if (xd !== 0) {
      let checkFilters = filters
      let toCheck
      for (let dato in checkFilters) {
        toCheck = Object.keys(checkFilters[dato])[0]
        if (option === toCheck) return true
      }
    } else return false
  }
  //Busca en el arreglo filters el filtro a eliminar, regresa un nuevo arreglo
  const deleteFilter = (option) => {
    let oldFilters = filters
    let toDelete
    let newFilters = []
    for (let dato in oldFilters) {
      toDelete = Object.keys(oldFilters[dato])[0]
      if (option !== toDelete) newFilters = [...newFilters, oldFilters[dato]]
    }
    return newFilters
  }
  return (
    <RecordsFiltersProvider>
      <Fragment>
        <h1
          // className="ui header"
          style={{
            display: 'inline',
            color: 'rgb(68, 68, 68)',
            fontSize: 'clamp(1.8em, 1.88em, 1.9em)',
          }}
        >
          {t('records')}
        </h1>
        <div className="HomeSetup">
          <div className="content_options_home">
            <RegisterFilters></RegisterFilters>
            {/* More options button */}
            <Tooltip title="Más opciones de búsqueda">
              <button
                className="more_search_options"
                type="button"
                onClick={() => showMoreOptions()}
              >
                <AiOutlineMore strokeWidth={1.2} size={28} />
              </button>
            </Tooltip>
          </div>
          <BtnStartVerification />
          <FilterByDate
            filters={filters}
            setFilters={setFilters}
            alreadyExist={alreadyExist}
            deleteFilter={deleteFilter}
            setupCollapse={isCollapseMoreOptions}
          />
        </div>
        <ShowRegisters filters={filters} />
      </Fragment>
    </RecordsFiltersProvider>
  )
}

export default Home
