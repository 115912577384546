import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'

export const Container = styled(Grid)({
  width: '100%',
  height: '292px',
  position: 'relative',
  zIndex: '10',
  userSelect: 'none',
})

export const Logo = styled('img')({
  width: '100%',
  height: '100%',
  position: 'absolute',
  zIndex: '10',
  userSelect: 'none',
})

export const InternalContainer = styled(Grid)((props) => ({
  width: '80%',
  height: '165px',
  border: '1px solid #aaa',
  margin: '70px auto 0 auto',
  position: 'relative',
  zIndex: '90',
  userSelect: 'none',
  backgroundImage: props?.logo ? `url(${props.logo})` : null,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: !props?.logo ? 'pointer' : 'normal',
  textAlign: 'center',
  fontSize: 'clamp(16px,17px,18px)',
  padding: '0 1rem',
  color: '#888',
  '&:hover, &:active, &:focus': {
    backgroundColor: !props?.logo ? '#f186aa2c' : null,
    borderColor: !props?.logo ? 'var(--secondary-color)' : null,
    color: 'var(--secondary-color)',
  },
}))

export const Clean = styled(Grid)({
  width: '20px',
  height: '20px',
  position: 'absolute',
  zIndex: '100',
  top: 0,
  right: 0,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'white',
  userSelect: 'none',
  '&:hover, &:active, &:focus': {
    backgroundColor: '#f186aa2c',
    borderColor: 'var(--secondary-color)',
    boxShadow: 'none !important',
  },
})

export const Time = styled(Grid)({
  marginLeft: '50px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontWeight: 'bold',
  userSelect: 'none',
  fontSize: 'clamp(15px, 16px, 17px)',
})
