import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'

export const MyGrid = styled(Grid)((props) => ({
  color: 'var(--secondary-color)',
  width: '100%',
  height: '30px',
  display: 'flex',
  alignItems: 'center',
  marginTop: props.marginTop ? `${props.marginTop}px` : '0',
  marginBottom: props.marginBottom ? `${props.marginBottom}px` : '0',
}))

export const SignMeData = styled(Grid)({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: 'clamp(0.8em, 0.85em, 0.9em)',
  margin: '1rem 0 0 0',
  backgroundColor: '#fff',
  border: '1px solid #C4C4C4',
  padding: '1rem 1rem',
})

export const DataMe = styled(Grid)({
  color: '#808080',
  '&>p': {
    margin: '0',
    padding: '0',
  },
})

export const ActionMe = styled('span')({
  color: 'var(--primary-color)',
  padding: '0.3rem 0.6rem',
  cursor: 'pointer',
  border: '1px solid var(--primary-color)',
  borderRadius: '3px',
  '&:hover, &:active, &:focus': {
    backgroundColor: 'var(--primary-color)',
    color: '#fff',
    outline: 'none',
  },
})

export const GeolocationMessage = styled(Grid)((props) => ({
  height: '35px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  fontSize: 'clamp(0.87em,9em,0.97em)',
  fontWeight: 500,
  lineHeight: '18px',
  color: props.fc ? props.fc : '#000',
}))

export const OnlyMe = styled(Grid)({
  height: 'auto',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  margin: 'auto',
  flexDirection: 'column',
  padding: '0.5rem 0',
})

export const OnlyMeBox = styled(Grid)((props) => ({
  width: props.width ? props.width : '0px',
  height: props.height ? props.height : '0px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  zIndex: 80,
  cursor: 'pointer',
}))

export const OnlyMeBoxImage = styled('img')((props) => ({
  width: props.width ? props.width : '0px',
  height: props.height ? props.height : '0px',
  position: 'absolute',
  zIndex: 90,
}))

export const OnlyMeBoxContainer = styled(Grid)((props) => ({
  width: props.width ? props.width : '0px',
  height: props.height ? props.height : '0px',
  fontSize: props.fsize ? props.fsize : '9px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  flexDirection: 'column',
  overflow: 'hidden',
  backgroundImage: props.bimage ? props.bimage : '',
  backgroundSize: props.bsize ? props.bsize : '',
  backgroundRepeat: 'no-repeat',
  objectFit: 'contain',
}))

export const OnlyMeBoxDate = styled(Grid)((props) => ({
  position: 'absolute',
  fontSize: props.fsize ? props.fsize : '9px',
  left: props.left ? props.left : '0px',
  bottom: props.bottom ? props.bottom : '0px',
}))

export const DDZoneB = styled(Grid)({
  height: 'auto',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  margin: '1rem auto auto auto',
  flexDirection: 'column',
  padding: '0.5rem 0',
})

export const Rubric = styled(Grid)({
  width: '100%',
  padding: '0.8rem 0',
  display: 'flex',
  alignItems: 'center',
  color: '#444343',
})
