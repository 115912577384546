import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'

export const Country = styled(Grid)({
  height: '65px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'space-around',
  flexDirection: 'column',
  color: 'var(--secondary-color)',
  outline: 'none',
  marginBottom: '10px',
})

export const Geolocation = styled(Grid)({
  minHeight: '65px',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'flex-start',
  color: 'var(--secondary-color)',
  outline: 'none',
  marginBottom: '20px',
})

export const GeolocationOptions = styled(Grid)({
  height: '65px',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
})

export const GeolocationOptionsText = styled(Grid)({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
})

export const GeolocationOptionsSwitch = styled(Grid)({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
})
