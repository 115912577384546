import React, { useState } from 'react'

import './CarouselVerification.css'

const CarouselVerification = ({ white, infrared, ultraviolet, name }) => {
  const [firstSlide, setFirstSlide] = useState('slideEnable')
  const [secondSlide, setSecondSlide] = useState('slideDisable')
  const [thirdSlide, setThirdSlide] = useState('slideDisable')
  const [dotOne, setDotOne] = useState(['dot', 'active'])
  const [dotTwo, setDotTwo] = useState(['dot'])
  const [dotThree, setDotThree] = useState(['dot'])

  // Thumbnail image controls
  const currentSlide = (n) => {
    showSlides(n)
  }

  const showSlides = (n) => {
    if (n === 3) {
      setThirdSlide('slideEnable')
      setSecondSlide('slideDisable')
      setFirstSlide('slideDisable')
      setDotOne(['dot'])
      setDotTwo(['dot'])
      setDotThree(['dot', 'active'])
    } else if (n === 2) {
      setSecondSlide('slideEnable')
      setFirstSlide('slideDisable')
      setThirdSlide('slideDisable')
      setDotOne(['dot'])
      setDotTwo(['dot', 'active'])
      setDotThree(['dot'])
    } else {
      setFirstSlide('slideEnable')
      setSecondSlide('slideDisable')
      setThirdSlide('slideDisable')
      setDotThree(['dot'])
      setDotTwo(['dot'])
      setDotOne(['dot', 'active'])
    }
  }

  const contentFront =
    !infrared || !ultraviolet ? (
      <img src={white} alt={name} className="FrontImageCarouselVerification" />
    ) : (
      <img
        src={white}
        alt={name}
        className="ImagesCarouselVerification"
        onClick={() => currentSlide(2)}
      />
    )

  const dots =
    !infrared || !ultraviolet ? (
      <span />
    ) : (
      <div className="dots">
        <button className={dotOne.join(' ')} onClick={() => currentSlide(1)} />
        <button className={dotTwo.join(' ')} onClick={() => currentSlide(2)} />
        <button
          className={dotThree.join(' ')}
          onClick={() => currentSlide(3)}
        />
      </div>
    )

  return (
    <div className="CarouselVerification">
      <div className="CarouselVerificationImages">
        <div className="slideshow-container">
          <div className={firstSlide}>{contentFront}</div>
          <div className={secondSlide}>
            <img
              src={infrared}
              alt={name}
              className="ImagesCarouselVerification"
              onClick={() => currentSlide(3)}
            />
          </div>
          <div className={thirdSlide}>
            <img
              src={ultraviolet}
              alt={name}
              className="ImagesCarouselVerification"
              onClick={() => currentSlide(1)}
            />
          </div>
        </div>
        {dots}
      </div>
    </div>
  )
}

export default CarouselVerification
