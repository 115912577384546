import React, { useState } from 'react'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import './Identification.css'
import { labels, categories } from '../../../../fixtures/panelExperts.fixture'
import iconAwesomeCheck from '../../../../assets/icons/awesome-check.svg'
import iconAwesomeFail from '../../../../assets/icons/awesome-fail.svg'
import iconAwesomeExclamation from '../../../../assets/icons/awesome-exclamation.svg'
import { API_DASHBOARD_URL } from '../../../../const'
import useToken from '../../../../utils/useToken'

const Identification = ({ uuid, type, ...props }) => {
  const getToken = useToken()
  const { confirm } = Modal
  const [isClicked, setIsClicked] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const [output, setOutput] = useState(null)
  const [isOverwriting, setIsOverwriting] = useState(false)
  const dataIdentification = props.response

  const onChangeInputHandler = (e) => {
    const newOutput = e.target.value
    setOutput(Number(newOutput))
    if (newOutput.match(/[0-9]/g)) {
      setIsOverwriting(true)
    } else {
      setIsOverwriting(false)
    }
  }

  const showModalInfo = (status) => {
    if (status) {
      Modal.success({
        content: `Se ha modificado el valor correctamente.`,
        onOk() {
          window.location.reload()
        },
      })
    } else {
      Modal.error({
        title: '¡Oh, no! Algo salió mal',
        content: 'Por favor, vuelve a intentarlo.',
      })
    }
  }

  const onChangeIdentificationValue = async (key) => {
    try {
      const token = await getToken({
        audience: 'veridocid-dashboard',
      })
      dataIdentification[key].output = output
      const response = await fetch(
        `${API_DASHBOARD_URL}/experts/records/updateDocumentVerification`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            uuid,
            output,
            category: categories.identification,
            key,
            type,
          }),
        }
      )
      if (response.ok) {
        showModalInfo(true)
        onCancelHandler()
      } else {
        const error = await response.json()
        showModalInfo(false)
        console.error(error)
      }
    } catch (error) {
      showModalInfo(false)
      console.error(error)
    }
    setIsOverwriting(false)
  }

  const showModalConfirm = (value) => {
    confirm({
      title: '¿Estás seguro?',
      icon: <ExclamationCircleOutlined />,
      content: 'Esta acción es irreversible.',
      cancelText: 'No',
      okText: 'Si',
      onOk() {
        onChangeIdentificationValue(value)
      },
      onCancel() {},
    })
  }

  const onCancelHandler = () => {
    setIsEdit(false)
    setIsClicked(null)
  }

  const onEditHandler = (key) => {
    setIsClicked(key)
    setIsEdit(true)
  }

  const rows = dataIdentification?.map((resp, index) => {
    const inputFields = resp?.inputFields?.map((res) => (
      <p key={Math.random()}>{res?.value}</p>
    ))

    const value =
      isEdit && isClicked === index ? (
        <input
          defaultValue={resp?.output}
          style={{ width: '3rem' }}
          onChange={(event) => onChangeInputHandler(event)}
          maxLength="3"
        />
      ) : (
        <span
          className="IdentificationOutput"
          onClick={() => onEditHandler(index)}
        >
          {resp?.output}
        </span>
      )

    const rowOutput =
      resp?.result === 'Ok' || resp?.result === 'Overriden' ? (
        <td className="IdentificationRows">{resp?.output}</td>
      ) : (
        <td className="IdentificationRows">
          {value}
          {isClicked === index ? (
            <div className="EditButtons">
              <button className="CancelButton" onClick={onCancelHandler}>
                {labels.cancel}
              </button>
              <button
                className="OverwriteButton"
                disabled={!isOverwriting}
                onClick={() => showModalConfirm(isClicked)}
              >
                {labels.overwrite}
              </button>
            </div>
          ) : null}
        </td>
      )

    return (
      <tr key={index}>
        <td className="IdentificationRows">{resp?.name}</td>
        <td className="IdentificationRows">{inputFields}</td>
        {rowOutput}
        <td className="IdentificationRows">
          <img
            src={
              resp?.result === 'Ok'
                ? iconAwesomeCheck
                : resp?.result === 'Overriden'
                ? iconAwesomeExclamation
                : iconAwesomeFail
            }
            alt="Result"
            style={{ width: '1.5rem' }}
          />
        </td>
      </tr>
    )
  })

  return (
    <div className="Identification">
      <table className="TableIdentification">
        <thead>
          <tr>
            <th className="ThIdentification" style={{ width: '25%' }}>
              <b>{labels.name}</b>
            </th>
            <th className="ThIdentification" style={{ width: '40%' }}>
              <b>{labels.input}</b>
            </th>
            <th className="ThIdentification" style={{ width: '20%' }}>
              <b>{labels.output}</b>
            </th>
            <th className="ThIdentification" style={{ width: '15%' }}>
              <b>{labels.result}</b>
            </th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  )
}

export default Identification
