import React, { useState, useEffect } from 'react'
import useSWR from 'swr'
import { useTranslation } from 'react-i18next'
import useToken from '../../../../utils/useToken'
import { FilterTable } from './FilterTable'

// Utils and components
import { Loader, Dimmer, Button } from 'semantic-ui-react'
import { Switch, Skeleton, Tooltip } from 'antd'
import { Hidden } from '@material-ui/core'
import { API_DASHBOARD_URL } from '../../../../const'
import Settings from '../../../UI/SettingsTable/Settings'
import TableDesktop from './TableDesktop'
import TableMobile from './TableMobile'
import { useHistory } from 'react-router-dom'
import { VscListFilter } from 'react-icons/vsc'

// Main componente
const DashboardTemplatev2 = (props) => {
  const { showOptions, uuidVerification } = props
  const history = useHistory()
  const getToken = useToken()
  const { t } = useTranslation()
  const [activePage, setActivePage] = useState(1)
  const [register, setRegister] = useState(null)
  const [delay, setDelay] = useState(4000)
  const [limit, setLimit] = useState(10)
  const [status, setStatus] = useState(true)

  // Search
  const [templateID, setTemplateID] = useState('')
  const [templateName, setTemplateName] = useState('')
  const [templateTitle, setTemplateTitle] = useState('')
  const [filters, setFilters] = useState([])

  // Filter
  const [showFilters, setShowFilters] = useState(false)

  const changeTemplateID = (event) => setTemplateID(event?.target?.value)
  const changeTemplateName = (event) => setTemplateName(event?.target?.value)
  const changeTemplateTitle = (event) => setTemplateTitle(event?.target?.value)

  // Get templates
  const showAllTemplates = async (url) => {
    const token = await getToken({
      audience: 'veridocid-dashboard',
    })

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })

    if (response.ok) {
      const responseJson = await response.json()
      return responseJson
    } else {
      const error = await response.json()
      console.error(error)
      return null
    }
  }

  const { data } = useSWR(
    `${API_DASHBOARD_URL}/sign/templates?limit=${limit}&page=${activePage}&status=${status}&filters=${JSON.stringify(
      filters
    )}`,
    showAllTemplates,
    {
      refreshInterval: delay,
    }
  )

  useSWR(
    register?.hasNextPage
      ? `${API_DASHBOARD_URL}/sign/templates?limit=${limit}&page=${
          activePage + 1
        }&status=${status}&filters=${JSON.stringify(filters)}`
      : null,
    showAllTemplates
  )

  useEffect(() => {
    if (data) {
      const wait =
        Math.ceil(data.totalDocs / 10) < 4000
          ? 4000
          : Math.ceil(data.totalDocs / 8)
      setDelay(wait)
      setRegister(data)
    }
  }, [limit, data])

  const handlePaginationChange = (e, { activePage }) =>
    setActivePage(activePage)

  // Switch Data
  const handleSwitchChange = async (event) => {
    setStatus(event)
    setActivePage(1)
  }

  //   Search
  const onSearch = () => {
    let searchData = [{}]
    searchData.forEach((data) => {
      if (templateID !== '') data.uuid = templateID
      if (templateName !== '') data.name = templateName
      if (templateTitle !== '') data.title = templateTitle
    })
    setFilters(searchData)
    setActivePage(1)
  }
  // Clear
  const onClear = () => {
    setTemplateID('')
    setTemplateName('')
    setTemplateTitle('')
    setFilters([])
    setActivePage(1)
  }

  const createTemplate = () =>
    history.push('/contratos/plantillas/nueva-plantilla')

  return (
    <>
      {/* Options */}
      {showOptions ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: '1.3rem 0 1.9rem',
          }}
        >
          <Button
            className="btns_actions_with_background_yellow"
            onClick={createTemplate}
          >
            {t('newTemplate')}
          </Button>
          <div
            style={{ display: 'flex', alignItems: 'center' }}
            className="tableData"
          >
            <p style={{ margin: 0, fontWeight: '500' }}>
              {status ? `${t('enabled')}` : `${t('disabled')}`}
            </p>
            <Switch
              defaultChecked
              style={{ width: '14px', margin: '0 10px' }}
              onChange={(e) => {
                handleSwitchChange(e)
              }}
            />
            {/* Filter */}
            <div>
              <Tooltip title="Filtrar" placement="top">
                <Button
                  className="btn_actions_custom_filter"
                  onClick={() => setShowFilters(!showFilters)}
                >
                  <VscListFilter size={20} strokeWidth={0.3} />
                </Button>
              </Tooltip>
            </div>
          </div>
        </div>
      ) : null}

      {/* SEARCH*/}
      <FilterTable
        t={t}
        showFilters={showFilters}
        templateID={templateID}
        changeTemplateID={changeTemplateID}
        templateName={templateName}
        changeTemplateName={changeTemplateName}
        templateTitle={templateTitle}
        changeTemplateTitle={changeTemplateTitle}
        onSearch={onSearch}
        onClear={onClear}
      />
      {/* Table */}
      <Dimmer.Dimmable dimmed={!data}>
        <Dimmer active={!data} inverted>
          <Loader>{t('loading')}</Loader>
        </Dimmer>
        {/* Data */}
        <Hidden smDown>
          {register ? (
            <TableDesktop
              register={register?.result}
              showOptions={showOptions}
              setDelayData={setDelay}
              uuidVerification={uuidVerification}
            />
          ) : (
            <>
              <Skeleton.Input active block style={{ height: '600px' }} />
            </>
          )}
        </Hidden>
        <Hidden mdUp>
          {register ? (
            <TableMobile
              register={register?.result}
              showOptions={showOptions}
              uuidVerification={uuidVerification}
            />
          ) : (
            <>
              <Skeleton.Input active block style={{ height: '90vh' }} />
            </>
          )}
        </Hidden>
        <Settings
          activePage={activePage}
          totalPages={register?.totalPages ?? 0}
          data={data}
          handlePaginationChange={handlePaginationChange}
          setLimit={setLimit}
        />
      </Dimmer.Dimmable>
    </>
  )
}

export default DashboardTemplatev2
