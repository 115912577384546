import { Button, Dimmer, Loader, Pagination } from 'semantic-ui-react'
import ModalSign from '../../PDFSignatureNC/Modal/ModalSign.js'
import { API_DASHBOARD_URL } from '../../../../const/index.js'
import { VscArrowDown, VscArrowUp } from 'react-icons/vsc'
import { useAuth0 } from '../../../../react-auth0-spa.js'
import React, { useEffect, useState } from 'react'
import useToken from '../../../../utils/useToken'
import { useTranslation } from 'react-i18next'
import { Switch, Tag } from 'antd'
import moment from 'moment'
import useSWR from 'swr'
import {
  Paper,
  TableRow,
  TableHead,
  FormControlLabel,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Box,
  Grid,
  Checkbox,
} from '@mui/material'

const EnhancedTableHead = ({
  t,
  selectedSort,
  selectCreatedAtA,
  selectCreatedAtD,
  numSelected,
  rowCount,
  onSelectAllClick,
}) => (
  <TableHead>
    <TableRow>
      <TableCell className="head_table_custom">
        <Checkbox
          indeterminate={numSelected > 0 && numSelected < rowCount}
          checked={rowCount > 0 && numSelected === rowCount}
          onChange={onSelectAllClick}
          inputProps={{
            'aria-label': 'select all desserts',
          }}
        />
      </TableCell>
      <TableCell
        className="head_table_custom"
        onClick={
          selectedSort.createdAtD
            ? selectCreatedAtA
            : selectedSort.createdAtA
            ? selectCreatedAtD
            : selectCreatedAtD
        }
      >
        {t('date')}{' '}
        {selectedSort.createdAtD ? (
          <VscArrowDown size={13} strokeWidth={0.8} cursor="pointer" />
        ) : selectedSort.createdAtA ? (
          <VscArrowUp size={13} strokeWidth={0.8} cursor="pointer" />
        ) : null}
      </TableCell>
      <TableCell className="head_table_custom">{t('documentID')}</TableCell>
      <TableCell className="head_table_custom">
        {t('documentHeaderTable')}
      </TableCell>
      <TableCell className="head_table_custom">Nombre del Firmante</TableCell>
      <TableCell className="head_table_custom">Email Firmante</TableCell>
      <TableCell className="head_table_custom">{t('status')}</TableCell>
      <TableCell className="head_table_custom">
        {t('typeOfSignature')}
      </TableCell>
    </TableRow>
  </TableHead>
)

const ContractToSignComponent = () => {
  const { user } = useAuth0()
  const getToken = useToken()
  const { t } = useTranslation()
  const [activePage, setActivePage] = useState(1)
  const [register, setRegister] = useState(null)
  const [delay, setDelay] = useState(4000)
  const [limit, setLimit] = useState(10)
  const [selectedSort, setSelectedSort] = useState({
    createdAtD: true,
    createdAtA: false,
  })
  const [status, setStatus] = useState('approved')
  const [checked, setChecked] = useState(true)
  const [selected, setSelected] = useState([])

  //Datos para firma masiva
  const [currentDocuments, setCurrentDocuments] = useState({
    email: '',
    signature: '',
    documentInfo: [],
  })

  //Modal
  const [open, setOpen] = useState(false)
  const [masive, setMasive] = useState(false)

  const isSelected = (name) => selected.indexOf(name) !== -1

  const handleSwitchChange = async (event) => {
    setChecked(event)
    setActivePage(1)
    event === true ? setStatus('approved') : setStatus('signed')
  }

  // Open modal
  const handleOpen = () => {
    setOpen(true)
    setMasive(true)
  }

  // Get all contracts
  const showApprovalData = async (url) => {
    const token = await getToken({
      audience: 'veridocid-dashboard',
    })

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })

    if (response.ok) {
      const responseJson = await response.json()
      return responseJson
    } else {
      return null
    }
  }

  const { data } = useSWR(
    `${API_DASHBOARD_URL}/sign/masive?status=${status}&limit=${limit}&page=${activePage}&sortBy=${
      selectedSort.createdAtD ? 1 : selectedSort.createdAtA ? 2 : 1
    }`,
    showApprovalData,
    {
      refreshInterval: delay,
    }
  )

  useSWR(
    register?.hasNextPage
      ? `${API_DASHBOARD_URL}/sign/masive?status=${status}&limit=${limit}&page=${
          activePage + 1
        }&sortBy=${
          selectedSort.createdAtD ? 1 : selectedSort.createdAtA ? 2 : 1
        }`
      : null,
    showApprovalData
  )

  useEffect(() => {
    if (data) {
      const wait =
        Math.ceil(data.totalDocs / 10) < 5000
          ? 5000
          : Math.ceil(data.totalDocs / 8)
      setDelay(wait)
      setRegister(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, data])

  const handlePaginationChange = (e, { activePage }) =>
    setActivePage(activePage)

  // Sort
  const selectCreatedAtD = () =>
    setSelectedSort({
      createdAtD: true,
      createdAtA: false,
    })

  const selectCreatedAtA = () =>
    setSelectedSort({
      createdAtD: false,
      createdAtA: true,
    })

  // Select documents
  const handleDocument = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)

    const data = {
      email: String(user?.email),
      signature: '',
      documentInfo: [],
    }

    for (const document of newSelected) data.documentInfo.push(document)
    setCurrentDocuments(data)
  }

  const handleSelectDocuments = (event) => {
    if (event.target.checked) {
      const newSelecteds = register?.result.map((n) => n.documentID)
      const data = {
        email: String(user?.email),
        signature: '',
        documentInfo: [],
      }

      for (const document of newSelecteds) data.documentInfo.push(document)
      setCurrentDocuments(data)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
    setCurrentDocuments({
      email: '',
      signature: '',
      documentInfo: [],
    })
  }

  return (
    <>
      {open ? (
        <ModalSign
          masive={masive}
          open={open}
          setOpen={setOpen}
          currentDocuments={currentDocuments}
        />
      ) : null}
      <h1
        style={{
          display: 'inline',
          color: 'rgb(68, 68, 68)',
          fontSize: 'clamp(1.78em, 1.8em, 1.81em)',
        }}
      >
        {t('contractsToBeSigned')}
      </h1>
      <Dimmer.Dimmable dimmed={!data}>
        <Dimmer active={!data} inverted>
          <Loader>Cargando...</Loader>
        </Dimmer>
        <Grid
          container
          justifyContent="space-between"
          style={{
            marginBottom: '-30px',
          }}
        >
          <Grid
            item
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '1.4rem',
              marginBottom: '1.9rem',
            }}
          >
            {currentDocuments.documentInfo.length > 0 && checked ? (
              <Button
                className="btns_actions_with_background_yellow"
                onClick={handleOpen}
              >
                Firma Masiva
              </Button>
            ) : null}
          </Grid>
          <FormControlLabel
            className="dashboardTemplateSwitch"
            control={
              <Switch
                defaultChecked
                style={{
                  width: '14px',
                  marginLeft: '10px',
                  marginRight: '10px',
                }}
                checked={checked}
                onChange={(e) => {
                  handleSwitchChange(e)
                }}
              />
            }
            label={checked ? 'Pendientes de Firma' : 'Firmados'}
            labelPlacement="start"
          />
        </Grid>
        <Box sx={{ width: '100%', paddingBottom: '4rem' }}>
          <Paper sx={{ width: '100%', mb: 2, mt: 4, boxShadow: 'none' }}>
            <TableContainer>
              <Table
                style={{
                  background: '#fff',
                  color: '#303232',
                }}
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
              >
                <EnhancedTableHead
                  t={t}
                  selectedSort={selectedSort}
                  selectCreatedAtA={selectCreatedAtA}
                  selectCreatedAtD={selectCreatedAtD}
                  numSelected={selected.length}
                  rowCount={register ? register?.result.length : 0}
                  onSelectAllClick={handleSelectDocuments}
                />
                <TableBody>
                  {register &&
                    register?.result.map((row, index) => {
                      const isItemSelected = isSelected(row?.documentID)
                      return (
                        <TableRow
                          key={index}
                          style={{ cursor: 'pointer' }}
                          hover
                          selected={isItemSelected}
                          onClick={(e) => handleDocument(e, row?.documentID)}
                        >
                          <TableCell
                            style={{ fontSize: '14px' }}
                            align="center"
                          >
                            <Checkbox checked={isItemSelected} />
                          </TableCell>
                          <TableCell
                            style={{ fontSize: '14px' }}
                            align="center"
                          >
                            {row?.createdAt
                              ? moment(row.createdAt).format(
                                  'DD/MM/yyyy hh:mm:ss'
                                )
                              : '-'}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: '14px' }}
                            align="center"
                          >
                            {row?.documentID ?? '-'}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: '14px' }}
                            align="center"
                          >
                            <p
                              style={{
                                width: '180px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                wordWrap: 'break-word',
                              }}
                            >
                              {row?.documentName ?? "-"}
                            </p>
                          </TableCell>
                          <TableCell
                            style={{ fontSize: '14px' }}
                            align="center"
                          >
                            {row?.creatorName ?? '-'}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: '14px' }}
                            align="center"
                          >
                            {row?.creatorEmail ?? '-'}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: '14px' }}
                            align="center"
                          >
                            {row?.status === 'approved' ? (
                              <Tag color="lime">Aprobado</Tag>
                            ) : (
                              <Tag color="green">Firmado</Tag>
                            )}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: '14px' }}
                            align="center"
                          >
                            {row?.signType === 'ELECTRONIC_SIGNATURE'
                              ? `${t('electronicSignature')}`
                              : 'Otra'}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="content_pagination_table_custom">
              <div className="content_feature_to_pagunation">
                <p>{t('show')}</p>
                <select
                  name="table_contract"
                  id="table_contract"
                  className="select_limit_to_pagination"
                  onChange={(e) => setLimit(e.target.value)}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                <p>
                  {' '}
                  {t('of')} {register?.totalRecords}
                </p>
              </div>

              <Pagination
                secondary
                firstItem={null}
                lastItem={null}
                activePage={activePage}
                onPageChange={handlePaginationChange}
                totalPages={register?.totalPages ?? 0}
              />
            </div>
          </Paper>
        </Box>
      </Dimmer.Dimmable>
    </>
  )
}

export default ContractToSignComponent
