import React from 'react'
import { useTranslation } from 'react-i18next'

// Components
import {
  Form as FormSendNip,
  // Input as InputEmailNip,
  Transition,
  Label,
  Button,
  Message,
} from 'semantic-ui-react'
import { Modal, Radio } from 'antd'
// Text mask
import MaskedInput from 'react-text-mask'
import emailMask from 'text-mask-addons/dist/emailMask'

const SendMailBelvo = (props) => {
  const { t } = useTranslation()

  const {
    visibleModal,
    handleSendMail,
    handleCancelSendEmail,
    changeDataEmail,
    emailData,
    descriptionSendEmail,
    changeSelectData,
    stateSelectData,
    // Error
    visibleMessageError,
    messageInputError,
    title_send_mail,
    classError,
    stateLoadingSubmit,
    // Api message errors
    changeStateVisibleMsgErrorApi,
    apiErrorMsg,
    headerErrorMsg,
    visibleMsgErrorApi,
  } = props

  return (
    <Modal
      centered={true}
      title={title_send_mail}
      open={visibleModal}
      closable={false}
      // style={{ top: '20%' }}
      footer={[
        <Button
          key="send_nip"
          onClick={handleSendMail}
          id="btn-actions_process-submit"
          style={{ marginRight: '10px' }}
          loading={stateLoadingSubmit}
        >
          {t('submitDataSDK')}
        </Button>,
        <Button
          key="cancel"
          onClick={handleCancelSendEmail}
          id="btn-actions_process-close"
        >
          {t('cancelDataSDK')}
        </Button>,
      ]}
    >
      <FormSendNip error={visibleMsgErrorApi}>
        <FormSendNip.Field>
          <span style={{ color: 'gray' }}>{`${descriptionSendEmail}`}</span>
          <div style={{ margin: '1.5em 0 0 0' }}>
            <p>Selecciona los datos que quisieras obtener de la persona</p>
            <Radio.Group onChange={changeSelectData} value={stateSelectData}>
              <Radio value={'retail'}>Bancaria</Radio>
              <Radio value={'fiscal'}>Fiscal</Radio>
            </Radio.Group>
          </div>
          <div
            className={`ui fluid input ${classError}`}
            style={{ marginTop: '1rem', display: 'flex', flexWrap: 'wrap' }}
          >
            <p
              style={{
                margin: '1rem 0 1rem 0',
                width: '100%',
              }}
            >
              {t('email')}
            </p>
            <MaskedInput
              keepCharPositions={false}
              mask={emailMask}
              guide={true}
              placeholder="ejemplo@email.com"
              value={emailData}
              onChange={changeDataEmail}
            />
          </div>
          {/* Errors */}
          <Transition
            animation="drop"
            duration={500}
            visible={visibleMessageError}
          >
            <Label pointing color="red" as="span">
              {`${messageInputError}`}
            </Label>
          </Transition>
          {/* Errors */}
          {/*Mensajes de error de la API  */}
          <Message
            error
            onDismiss={changeStateVisibleMsgErrorApi}
            header={headerErrorMsg}
            content={apiErrorMsg}
          />
        </FormSendNip.Field>
      </FormSendNip>
    </Modal>
  )
}

export default SendMailBelvo
