import React from 'react'
import { Button, Modal, Header, Form } from 'semantic-ui-react'
import Upload from './Upload'

const ModalEnrollment = ({
  setOpenEnrollModal,
  openEnrollModal,
  onUpload,
  imgToFileList,
  handleChangeNameEnroll,
  handleChangeSurnameEnroll,
  handleSubmitEnroll,
  loadingEnrollSubmit,
}) => {
  return (
    <>
      <Modal
        onClose={() => setOpenEnrollModal(false)}
        open={openEnrollModal}
        style={{ height: 'fit-content', position: 'static', width: '40%' }}
      >
        <Modal.Header>Enrolamiento</Modal.Header>
        {/* //aqui va el upload */}
        <Modal.Content style={{}}>
          <Modal.Description style={{}}>
            <Header style={{}}>Sube una foto</Header>
          </Modal.Description>
          <Form style={{}}>
            <Form.Field required style={{ width: '100%', margin: 'auto' }}>
              <label
                style={{
                  width: 'fit-content',
                  margin: 'auto',
                  marginBottom: '5px',
                }}
              >
                Imagen
              </label>
              <div style={{ width: 'fit-content', margin: 'auto' }}>
                <Upload
                  id="facial_enroll"
                  onUpload={onUpload}
                  fileList={imgToFileList}
                >
                  <p className="ant-upload-drag-icon"></p>
                </Upload>
              </div>
            </Form.Field>
            <Form.Field required style={{ width: '100%', margin: 'auto' }}>
              <label>Nombre</label>
              <input
                placeholder="Nombre"
                onChange={handleChangeNameEnroll}
                required
              />
            </Form.Field>
            <Form.Field
              required
              style={{ width: '100%', margin: 'auto', marginTop: '10px' }}
            >
              <label>Apellido</label>
              <input
                placeholder="Apellido"
                onChange={handleChangeSurnameEnroll}
                required
              />
            </Form.Field>
            <Button
              type="submit"
              onClick={handleSubmitEnroll}
              loading={loadingEnrollSubmit}
              style={{
                width: '100%',
                margin: 'auto',
                display: 'block',
                marginTop: '20px',
              }}
              color="green"
            >
              Enrolar
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    </>
  )
}
export default ModalEnrollment
