import { Image } from 'antd'
import React, { useState } from 'react'

import './CarouselFraudFace.css'

const CarouselFraudFace = ({ attachments }) => {
  const [active, setActive] = useState(0)

  const images = Object.keys(attachments).map((key, index) => {
    const className = active === index ? 'slideEnable' : 'slideDisable'

    return (
      <Image
        className={className}
        src={`data:image/jpg;base64,${attachments[key]}`}
        alt={index}
        key={key}
        onClick={() => setActive(index)}
      />
    )
  })

  const dots = Object.keys(attachments).map((key, index) => {
    const className = active === index ? 'dot active' : 'dot'

    return (
      <button
        className={className}
        key={index}
        onClick={() => setActive(index)}
      />
    )
  })

  return (
    <div className="CarouselFraudFace">
      <div className="CarouselFraudFaceImages">
        <div className="slideshow-container">{images}</div>
        <div className="dots">{dots}</div>
      </div>
    </div>
  )
}

export default CarouselFraudFace
