import React from 'react'
import { MyGrid, DDZoneB } from './showSignStyle'

export default function Sign(props) {
  const { dd_dragstart, whoSign } = props

  return (
    <>
      {whoSign !== 'me' ? (
        <>
          <MyGrid marginBottom={5}>Firmante(s)</MyGrid>
          <span className="description_config_client">
            Ubique en donde quiere visualizar la firma de sus firmantes.
          </span>
          <DDZoneB id="ddzoneB" onDragStart={(e) => dd_dragstart(e)}></DDZoneB>
        </>
      ) : null}
    </>
  )
}
