import React, { useState } from 'react'
import { getIP, getIPOption2 } from '../../../../../utils/signContract.js'
import { Switch, Tooltip, message } from 'antd'
import { MyGrid, GeolocationMessage } from './GeolocationStyle.jsx'
import { VscInfo } from 'react-icons/vsc'
import { useTranslation } from 'react-i18next'

export default function Geolocation(props) {
  const { geolocation, setGeolocation, setIP } = props

  const { t } = useTranslation()
  const [error, setError] = useState('')
  const [wait, setWait] = useState(false)

  const handleChange = (event) => {
    const current = event
    if (current) {
      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }

      const success = async (pos) => {
        setWait(true)
        setError('')
        const resGetIP = await getIP()

        if (resGetIP) {
          setIP(resGetIP.IPv4)
          setGeolocation(`${pos.coords.latitude}, ${pos.coords.longitude}`)
          setError('')
          setWait(false)
        } else {
          const resGetIP2 = await getIPOption2()

          if (resGetIP2) {
            setIP(resGetIP2.ip)
            setGeolocation(`${pos.coords.latitude}, ${pos.coords.longitude}`)
            setError('')
            setWait(false)
          } else message.error(`${t('erroGetGeolocation')}`)
        }

        setWait(false)
      }

      const error = () => {
        setError('Activa la geolocalizacion para continuar')
      }

      navigator.geolocation.getCurrentPosition(success, error, options)
    } else {
      setGeolocation('')
      setIP('')
      setError('')
    }
  }

  return (
    <>
      <MyGrid marginTop={10} marginBottom={10}>
        <Tooltip title="Activa o desactiva tu geolocalización para mostrar desde dónde se creó el contrato">
          <VscInfo
            color="rgb(140, 140, 140)"
            size={16}
            style={{ cursor: 'pointer', marginRight: '10px' }}
          />
        </Tooltip>
        Tu geolocalización
      </MyGrid>
      <MyGrid marginBottom={5} className="config_form__content_input">
        <Switch
          defaultChecked
          style={{
            width: '14px',
            marginRight: '10px',
          }}
          checked={geolocation}
          onChange={handleChange}
        />
        {error === '' || wait ? (
          <GeolocationMessage
            item
            xs={12}
            fc={error ? '#D32F2F' : 'var(--secondary-color)'}
          >
            {error !== '' ? error : wait ? 'Espere un momento...' : null}
          </GeolocationMessage>
        ) : null}
      </MyGrid>
    </>
  )
}
