import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'

export const Container = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
})

export const MessageTitle = styled(Grid)({
  width: '100%',
  height: '40px',
  color: 'var(--secondary-color)',
})

export const MessageInput = styled('input')({
  border: '1px solid #d9d9d9',
  color: 'grey',
  paddingLeft: '10px',
  marginBottom: '15px',
  outline: 'none',
  height: '35px',
  '&:hover, &:active, &:focus': {
    border: '1px solid var(--secondary-color)',
  },
})

export const MessageTextArea = styled('textarea')({
  border: '1px solid #d9d9d9',
  color: 'grey',
  paddingLeft: '10px',
  marginBottom: '15px',
  outline: 'none',
  minHeight: '35px',
  '&:hover, &:active, &:focus': {
    border: '1px solid var(--secondary-color)',
  },
})
