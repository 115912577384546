import React from 'react'

import './Button.css'

// Assets
import addBlack from '../../../assets/icons/add-black.svg'
import minusBlack from '../../../assets/icons/minus-black.svg'

const Button = (props) => {
  // const buttonClass = props.isFailed ? 'ButtonFailed' : 'Button' --> anteriormente estaba de la siguiente forma

  var buttonClass
  var image

  if (props.status === 'Ok' && props.isFailed) {
    buttonClass = 'Button'
    if (!props.statusCollapse) {
      image = addBlack
    } else {
      image = minusBlack
    }
  } else {
    image = props.image
    if (props.isFailed) {
      buttonClass = 'ButtonFailed'
    } else {
      buttonClass = 'Button'
    }
  }

  return (
    <div className={buttonClass}>
      <button className="ButtonText" onClick={props.clicked}>
        <img src={image} alt="+" />
        <span>{props.text}</span>
      </button>
    </div>
  )
}
export default Button
