import React, { useState } from 'react'
import { Collapse } from 'antd'
import { DeleteOutlined, UndoOutlined } from '@ant-design/icons'

import './ControlList.css'
import { labels } from '../../../../fixtures/panelExperts.fixture'
import iconAwesomeCheck from '../../../../assets/icons/awesome-check.svg'
import iconAwesomeFail from '../../../../assets/icons/awesome-fail.svg'
import {
  filterCheckDeleteList,
  filterUndoList,
} from '../../../../shared/filters'

const { Panel } = Collapse

const ControlList = (props) => {
  const [arrRemoveList, setArrRemoveList] = useState([])
  let nameControlList

  const genExtra = (index) => {
    let iconExtra

    const isItemDeleted = filterCheckDeleteList(arrRemoveList, index)

    if (isItemDeleted) {
      iconExtra = (
        <UndoOutlined
          onClick={(event) => {
            // If you don't want click extra trigger collapse, you can prevent this:
            event.stopPropagation()
            const undoList = filterUndoList(arrRemoveList, index)
            setArrRemoveList(undoList)
            props.onClick(undoList)
          }}
        />
      )
    } else {
      iconExtra = (
        <DeleteOutlined
          onClick={(event) => {
            // If you don't want click extra trigger collapse, you can prevent this:
            event.stopPropagation()
            setArrRemoveList((oldArray) => [...oldArray, index])
            props.onClick(index)
          }}
        />
      )
    }
    return iconExtra
  }

  const rows = props.response?.map((resp, index) => {
    const blackList = Object.keys(resp?.inputFields[0]?.value).map(
      (key, index) => {
        if (key === 'name') {
          nameControlList = resp?.inputFields[0]?.value[key]
        }

        return (
          <table className="ObjectControlList" key={index}>
            <tbody>
              <tr>
                <td className="ObjectRowsTitle">
                  <p
                    style={{
                      width: '8rem',
                    }}
                  >
                    {key}
                  </p>
                </td>
                <td className="ObjectRowsValue">
                  <p className="ControlListValue">
                    {resp?.inputFields[0]?.value[key]}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        )
      }
    )

    const resultsTableBody = (
      <tr key={index}>
        <td className="ControlListRows">{resp?.output}</td>
        <td className="ControlListRows">
          <img
            src={resp?.result === 'Ok' ? iconAwesomeCheck : iconAwesomeFail}
            alt="Result"
            style={{ width: '1.5rem' }}
          />
        </td>
      </tr>
    )

    const resultsTableBodyOk = (
      <tr key={index}>
        <td className="ControlListRows">{resp?.name}</td>
        <td className="ControlListRows">
          {typeof resp?.inputFields[0]?.value === 'string'
            ? resp?.inputFields[0]?.value
            : blackList}
        </td>
        <td className="ControlListRows">{resp?.output}</td>
        <td className="ControlListRows">
          <img
            src={resp?.result === 'Ok' ? iconAwesomeCheck : iconAwesomeFail}
            alt="Result"
            style={{ width: '1.5rem' }}
          />
        </td>
      </tr>
    )

    const resultsTable = (
      <table className="TableControlList" key={index}>
        <thead>
          <tr>
            <th className="ThControlList" style={{ width: '50%' }}>
              <b>{labels.output}</b>
            </th>
            <th className="ThControlList" style={{ width: '50%' }}>
              <b>{labels.result}</b>
            </th>
          </tr>
        </thead>
        <tbody>{resultsTableBody}</tbody>
      </table>
    )

    const resultsTableOK = (
      <table className="TableControlList" key={index}>
        <thead>
          <tr>
            <th className="ThControlList" style={{ width: '25%' }}>
              <b>{labels.field}</b>
            </th>
            <th className="ThControlList" style={{ width: '40%' }}>
              <b>{labels.input}</b>
            </th>
            <th className="ThControlList" style={{ width: '20%' }}>
              <b>{labels.output}</b>
            </th>
            <th className="ThControlList" style={{ width: '15%' }}>
              <b>{labels.result}</b>
            </th>
          </tr>
        </thead>
        <tbody>{resultsTableBodyOk}</tbody>
      </table>
    )

    return (
      <Panel
        header={
          resp?.result === 'Ok'
            ? resp?.name
            : `${resp?.name}: ${nameControlList}`
        }
        key={index}
        extra={props.response?.length === 1 ? null : genExtra(index)}
        disabled={filterCheckDeleteList(arrRemoveList, index)}
      >
        {resp?.result === 'Ok' ? (
          <div>{resultsTableOK}</div>
        ) : (
          <div>
            <div>{resultsTable}</div>
            <div>{blackList}</div>
          </div>
        )}
      </Panel>
    )
  })

  const callback = (key) => {}

  return (
    <div className="ControlList">
      <Collapse onChange={(key) => callback(key)}>{rows}</Collapse>
    </div>
  )
}

export default ControlList
