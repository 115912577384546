import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import './CreditStyles.css'

// Components and utils
import { Row, Col, Card, Modal, Skeleton } from 'antd'
import { VscLibrary } from 'react-icons/vsc'
import { SendMailNip } from './ProcessNip/SendMailNip'
import useToken from '../../utils/useToken'
import { API_URL } from '../../const/index'
import HistoryCredit from './ProcessNip/HistoryCredit'

const CreditClientDataShow = ({
  id,
  uuid,
  dataToSearchCredit,
  statusClietnNip,
  statusCredit,
  dataCreditFound,
  setIsCreditHistoryVerified,
  ambitDev,
  ambitProd,
  dataNipUser,
  creditConfig,
}) => {
  const { t } = useTranslation()
  const getToken = useToken()
  const { success, error } = Modal

  // Modal
  const [visibleModalSendNip, setVisibleModalSendNip] = useState(false)
  // Input data
  const [emailNip, setEmailNip] = useState('')
  const [loadingButton, setLoadingButton] = useState(false)
  // Control of errors
  const [errorEmail, setErrorEmail] = useState({
    visible: false,
    message: '',
  })
  // Api errors
  const [errorAPI, setErrorApi] = useState({
    visible: false,
    content: '',
  })
  // Loading card
  const [loadingCard, setLoadingCard] = useState(true)

  useEffect(() => {
    if (statusClietnNip) {
      setLoadingCard(false)
    } else {
      setLoadingCard(true)
      setTimeout(() => {
        setLoadingCard(false)
      }, 1200)
    }
  }, [statusClietnNip])

  // Validate email
  const regexValidateEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  // Show the modal
  const showModalSendNip = () => {
    setVisibleModalSendNip(true)
  }
  // Close the message - api errors
  const handleDismiss = () => {
    setErrorApi({ visible: false, content: '' })
  }

  //--------------- Function to send mail to start the process
  const sendEmailToVerifyNip = async () => {
    setLoadingButton(true)
    try {
      const token = await getToken({
        audience: 'veridocid',
      })

      const response = await fetch(`${API_URL}/credit/send_simple_email`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
          uuid,
          email: emailNip,
        }),
      })

      if (response.ok) {
        success({
          centered: true,
          closable: false,
          content: (
            <>
              <h5 style={{ marginBottom: '0', lineHeight: '1.4' }}>
                {t('succesTitleEmail')}
              </h5>
            </>
          ),
          bodyStyle: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          },
          onOk() {
            setVisibleModalSendNip(false)
            setEmailNip('')
          },
        })
      } else {
        error({
          centered: true,
          closable: false,
          content: (
            <>
              <h5 style={{ marginBottom: '0', lineHeight: '1.4' }}>
                {t('errorTitleEmail')}
              </h5>
            </>
          ),
          bodyStyle: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          },
          onOk() {
            setEmailNip('')
          },
        })
        setLoadingButton(false)
      }
      setLoadingButton(false)
    } catch (error) {
      setLoadingButton(false)
      setErrorApi({
        visible: true,
        content: `${t('messageErrorAPINip')}`,
      })
    }
  }

  //--------------- Modal options send NIP
  const handleSend = () => {
    const validateEmail = regexValidateEmail.test(
      String(emailNip).toLowerCase()
    )

    if (emailNip.length === 0) {
      setErrorEmail({
        visible: true,
        message: `${t('messageErrorEmptyInputEmail')}`,
      })
    } else if (!validateEmail) {
      setErrorEmail({
        visible: true,
        message: `${t('invalidEmailFormat')}`,
      })
    } else {
      setErrorEmail({
        visible: false,
        message: `${t('messageErrorEmptyInputEmail')}`,
      })

      // Call api send_mail_nip
      sendEmailToVerifyNip()
    }
  }

  const handleCancel = () => {
    setVisibleModalSendNip(false)
    // No muestra los mensajes de error
    setErrorEmail({
      visible: false,
      message: `${t('invalidEmailFormat')}`,
    })
  }

  //--------------- Data input client
  const changeDataEmailNip = (event) => {
    const clientEmail = event?.target?.value
    if (clientEmail.length === 0) {
      setErrorEmail({
        visible: true,
        message: `${t('invalidEmailFormat')}`,
      })
    } else {
      setErrorEmail({
        visible: false,
        message: `${t('invalidEmailFormat')}`,
      })
    }
    setEmailNip(clientEmail)
  }

  return (
    <>
      <Row className="title_header_custom_view_data">
        <Col span={24}>
          <h2
            style={{
              color: 'white',
              fontSize: 'clamp(1em, 1.39em, 1.55em)',
              letterSpacing: '0.5px',
            }}
          >
            {t('credit_history')}
          </h2>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card
            bordered={true}
            // loading={loadingCard}
            style={{ borderColor: '#c4c4c4', marginBottom: '3em' }}
          >
            <Skeleton loading={loadingCard}>
              {statusClietnNip ? (
                <HistoryCredit
                  data={dataToSearchCredit}
                  statusDataCredit={statusCredit}
                  dataBDCredit={dataCreditFound}
                  setStatusCreditHistoryVerifiedBD={setIsCreditHistoryVerified}
                  ambitDev={ambitDev}
                  ambitProd={ambitProd}
                  dataNipUser={dataNipUser}
                  creditConfig={creditConfig}
                />
              ) : (
                <div className="content_data_credit">
                  {/* Start process */}
                  <VscLibrary size={50} color="var(--primary-color)" />
                  <div className="content_description_view_data">
                    <h3 className="title_description_view_data">
                      {t('title_credit_history')}
                    </h3>
                    <p className="sub_title_view_data">
                      {t('description_credit_history')}
                    </p>
                  </div>
                  <button
                    className="btns_actions_primary_not_background btn_process_view_data"
                    onClick={() => showModalSendNip()}
                  >
                    {t('button_process_credit_history')}
                  </button>
                </div>
              )}
            </Skeleton>
          </Card>
          {/*----------- Modal send nip -----------*/}
          <SendMailNip
            title_send_mail={'Proceso de autenticación'}
            visibleModal={visibleModalSendNip}
            handleSendMail={handleSend}
            handleCancelSendEmail={handleCancel}
            changeDataEmailNip={changeDataEmailNip}
            emailNip={emailNip}
            stateLoadingSubmit={loadingButton}
            descriptionSendEmail={t('descriptionEmailNip')}
            // Errors
            visibleMessageError={errorEmail.visible}
            messageInputError={errorEmail.message}
            classError={errorEmail.visible ? 'error' : ''}
            // Api message error
            visibleMsgErrorApi={errorAPI.visible}
            headerErrorMsg={`${t('email')}`}
            apiErrorMsg={errorAPI.content}
            changeStateVisibleMsgErrorApi={handleDismiss}
          />
          {/*----------- Modal send nip -----------*/}
        </Col>
      </Row>
    </>
  )
}

export default CreditClientDataShow
