import { Step, Stepper } from '@mui/material'
import StepLabel from '@mui/material/StepLabel'
import useMediaQuery from '@mui/material/useMediaQuery'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { VscArrowLeft, VscChromeClose } from 'react-icons/vsc'
import { useHistory } from 'react-router-dom'
import SignProcessContext from '../../../../../context/SignProcess.context'
import {
  masiveSignature,
  startOrchestratorProcess,
} from '../../../../../utils/signContract.js'
import useToken from '../../../../../utils/useToken'
import Alert from '../../../../Alert/Alert.jsx'
import {
  Back,
  BtnClose,
  Close,
  Content,
  Next,
  Options,
  QontoConnector,
  QontoStepIcon,
  Steps,
  StepsZone,
  Title,
} from './Signature.jsx'
import Step0 from './Step0/Step0'
import Step1Option1 from './Step1Option1/Step1Option1'
import Step1Option2 from './Step1Option2/Step1Option2'
import Step1Option3 from './Step1Option3/Step1Option3'
import Step1Option4 from './Step1Option4/Step1Option4'
import { ModalListMasiveDocuments } from './ModalListMasiveDocuments'

export default function Signature(props) {
  const {
    openModal,
    closeModal,
    isActiveModal,
    setWrongDocuments,
    setDocumentsToSign,
    acceptProgress,
    documentIDs,
  } = useContext(SignProcessContext)

  const { setOpen, setSign, currentDocuments } = props
  const { t } = useTranslation()
  const [wait, setWait] = useState(false)
  const getToken = useToken()
  const matches = useMediaQuery('(max-width:600px)')
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    severity: '',
  })
  const [signatureData, setSignatureData] = useState(null)
  const [name, setName] = useState('')
  const steps = ['Tipo de firma', 'Configuracion']
  const [today, setToday] = useState('')
  const [option, setOption] = useState({
    option1: false,
    option2: false,
    option3: false,
    option4: false,
  })
  let docsToSign = []
  const history = useHistory()
  const handleClose = () => setOpen(false)

  const handleContinue = async () => {
    setWait(true)
    if (currentDocuments) {
      let data = {
        email: currentDocuments.email,
        documentInfo: currentDocuments.documentInfo,
      }

      const token = await getToken({
        audience: 'veridocid',
      })

      const { documentsInProgress, errorDocuments } =
        (await masiveSignature(token, data)) ?? {}
      docsToSign = documentsInProgress
      if (
        errorDocuments &&
        errorDocuments.length === 0 &&
        documentsInProgress &&
        documentsInProgress.length > 0
      ) {
        setDocumentsToSign(documentsInProgress)
        await acceptOrchestratorProgress()
        return
      }

      if (errorDocuments && errorDocuments.length > 0) {
        setWrongDocuments(errorDocuments)
        openModal()
      }

      if (documentsInProgress && documentsInProgress.length > 0) {
        setDocumentsToSign(documentsInProgress)
      }

      if (!errorDocuments && !documentsInProgress) {
        setAlert({
          open: true,
          message: `${t('messageErrorData')}`,
          severity: 'error',
        })
      }
    } else {
      let currentSign = signatureData
      let option = 0
      if (name) {
        let newName = name.split(' ')
        let newA = []
        let line = ''
        let canvaR = document.getElementById('textCanvasReal')
        let ctxR = canvaR.getContext('2d')
        ctxR.clearRect(113, 105, 113, 105)
        ctxR.font = 'bold 8px Arial'

        for (let name of newName) {
          if (`${line} ${name}`.length < 23) line = `${line} ${name}`
          else {
            newA.push(line)
            line = name
          }
        }

        if (newA.length === 0) ctxR.fillText(line, 0.5, 20)
        else {
          if (line !== '') newA.push(line)

          let Y = newA.length === 2 ? 6 : 0
          let plus = newA.length === 2 ? 10 : 10
          for (let namePart of newA) {
            Y = Y + plus
            ctxR.fillText(namePart, 0.5, Y)
          }
        }

        currentSign = ctxR.canvas.toDataURL()
        option = 3
      }

      setSign({ signature: currentSign, currentDate: today, option })
      setOpen(false)
    }
    setWait(false)
  }

  //Stepper
  const [activeStep, setActiveStep] = useState(0)
  const [completed, setCompleted] = useState({})

  const totalSteps = () => {
    return steps.length
  }

  const completedSteps = () => {
    return Object.keys(completed).length
  }

  const isLastStep = () => {
    return activeStep === totalSteps() - 1
  }

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps()
  }

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1
    setActiveStep(newActiveStep)
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1)
    setSignatureData(null)
  }

  const handleComplete = () => {
    const newCompleted = completed
    newCompleted[activeStep] = true
    setCompleted(newCompleted)
    handleNext()
  }

  const handleClear = () => {
    setSignatureData(null)
    setName('')
    setCompleted({})
    //Obtener Fecha
    const miliseconds = Date.now()
    let currentDate = new Date(miliseconds)
    const localeDate = currentDate.toLocaleDateString()
    setToday(localeDate)
  }

  const acceptOrchestratorProgress = async () => {
    let currentSign = signatureData
    if (name) {
      let newName = name.split(' ')
      let newA = []
      let line = ''
      let canvaR = document.getElementById('textCanvasReal')
      let ctxR = canvaR.getContext('2d')
      ctxR.clearRect(113, 105, 113, 105)
      ctxR.font = 'bold 10px Arial'

      for (let name of newName) {
        if (`${line} ${name}`.length < 23) line = `${line} ${name}`
        else {
          newA.push(line)
          line = name
        }
      }

      if (newA.length === 0) ctxR.fillText(line, 0.5, 20)
      else {
        if (line !== '') newA.push(line)

        let Y = newA.length === 2 ? 6 : 0
        let plus = newA.length === 2 ? 10 : 10
        for (let namePart of newA) {
          Y = Y + plus
          ctxR.fillText(namePart, 0.5, Y)
        }
      }

      currentSign = ctxR.canvas.toDataURL()
    }
    const token = await getToken({
      audience: 'veridocid',
    })

    if (
      (documentIDs && documentIDs.length > 0) ||
      (docsToSign && docsToSign.length > 0)
    ) {
      let data = {
        email: currentDocuments.email,
        documentInfo: documentIDs.length ? documentIDs : docsToSign,
        signature: currentSign.replace('data:image/png;base64,', ''),
      }
      acceptProgress()
      await startOrchestratorProcess(token, data)
      setOpen(false)
      history.push('/firma-masiva/contratos')
    } else {
      setAlert({
        open: true,
        message: `${t('noDataAvailable')}`,
        severity: 'error',
      })
      setWait(false)
    }
  }

  useEffect(() => {
    if (option.option1 || option.option2 || option.option3) handleClear()
  }, [option])

  return (
    <>
      {alert.open ? <Alert alert={alert} setAlert={setAlert} /> : null}
      <Close>
        <Title>
          {' '}
          {activeStep === 0
            ? `${t('signatureFormat')}`
            : option.option1
            ? `${t('handwritten')}`
            : option.option2
            ? `${t('image')}`
            : option.option3
            ? `${t('name')}`
            : `${t('signatureSaved')}`}
        </Title>
        <BtnClose
          onClick={handleClose}
          cursor={wait ? 'not-allowed' : 'pointer'}
        >
          <VscChromeClose size={15} strokeWidth={0.3} />
        </BtnClose>
      </Close>
      <Content sx={{}}>
        {activeStep === 0 ? (
          <Step0
            option={option}
            setOption={setOption}
            handleComplete={handleComplete}
          />
        ) : null}
        {activeStep === 1 ? (
          option.option1 ? (
            <Step1Option1
              signatureData={signatureData}
              setSignatureData={setSignatureData}
            />
          ) : option.option2 ? (
            <Step1Option2
              signatureData={signatureData}
              setSignatureData={setSignatureData}
              today={today}
            />
          ) : option.option3 ? (
            <>
              <Step1Option3 name={name} setName={setName} today={today} />
              <canvas
                id="textCanvasReal"
                width={113}
                height={35}
                display="none"
                style={{ display: 'none' }}
              ></canvas>
              <canvas
                id="textCanvas"
                width={452}
                height={140}
                style={{ display: 'none' }}
              ></canvas>
            </>
          ) : option.option4 ? (
            <Step1Option4
              setSign={setSign}
              setOpen={setOpen}
              currentDocuments={currentDocuments}
            />
          ) : null
        ) : null}
      </Content>
      <Steps container>
        <Options>
          {activeStep === 0 ? null : (
            <Back
              onClick={handleBack}
              bgcolor={wait ? '#aaa' : '#7777ff'}
              cursor={wait ? 'not-allowed' : 'pointer'}
            >
              <VscArrowLeft size={15} color={'#fff'} strokeWidth={0.3} />
            </Back>
          )}
          {(activeStep === 1 && signatureData) ||
          (activeStep === 1 && name !== '') ? (
            <Next
              onClick={wait ? null : handleContinue}
              bgcolor={
                wait ? 'var(--secondary-color)' : 'var(--secondary-color)'
              }
              cursor={wait ? 'wait' : 'pointer'}
            >
              {wait ? (
                <span>{`${t('loading')}...`}</span>
              ) : (
                <span>{`${t('accept')}`}</span>
              )}
            </Next>
          ) : null}
        </Options>
        <StepsZone item xs={12}>
          <Stepper
            activeStep={activeStep}
            sx={{
              width: matches ? '50%' : '30%',
              margin: '0 auto',
              padding: '20px 30px',
            }}
            connector={<QontoConnector />}
          >
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepLabel StepIconComponent={QontoStepIcon} />
              </Step>
            ))}
          </Stepper>
        </StepsZone>
      </Steps>
      <ModalListMasiveDocuments
        isActiveModal={isActiveModal}
        close={() => closeModal()}
        documentIDs={documentIDs}
        acceptOrchestratorProgress={acceptOrchestratorProgress}
      />
    </>
  )
}
