import { Grid } from '@material-ui/core'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { Table, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dimmer, Loader } from 'semantic-ui-react'
import useSWR from 'swr'
import useToken from '../utils/useToken'
import { API_URL_V3 } from '../const'
import { VscCheck, VscClose } from 'react-icons/vsc'

const headCells = [
  {
    dataIndex: 'documentID',
    title: 'Document ID',
    key: 'docID',
  },
  {
    dataIndex: 'documentName',
    title: 'Nombre del Documento',
    key: 'docName',
  },
  {
    dataIndex: 'okSign',
    title: 'Estatus de la Firma',
    align: 'center',
    key: 'status',
  },
  {
    dataIndex: 'okNom',
    title: 'Estatus de la NOM',
    align: 'center',
    key: 'statusNom',
  },
  {
    dataIndex: 'okEmail',
    title: 'Estatus de los correos',
    align: 'center',
    key: 'statusEmails',
  },
]

const PreviewContracts = () => {
  const { t } = useTranslation()
  const getToken = useToken()
  const [dataRows, setDataRows] = useState('')

  // Get templates
  const allMasiveStatusData = async (url) => {
    const token = await getToken({
      audience: 'veridocid',
    })

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })

    if (response.ok) {
      const responseJson = await response.json()
      return responseJson
    } else {
      return null
    }
  }

  const { data } = useSWR(
    `${API_URL_V3}/sign/status-masive-documents`,
    // `${API_URL_V3}/get-masive-status`,
    allMasiveStatusData,
    {
      refreshInterval: 3000,
    }
  )

  let newA = []
  useEffect(() => {
    if (data) {
      data?.documentStatus?.map((item, i) =>
        newA.push({
          key: i,
          documentID: item.documentID,
          documentName: item.documentName,
          okSign: (item.okSign = item.overallStatus ? (
            <>
              {item.okSign ? (
                <Tooltip placement="top" title="Todo Salio bien" zIndex={9}>
                  <VscCheck color="green" size={18} />
                </Tooltip>
              ) : (
                <Tooltip placement="top" title="En proceso" zIndex={9}>
                  <p>-</p>
                </Tooltip>
              )}
            </>
          ) : (
            <Tooltip placement="top" title="Ocurrio un error" zIndex={9}>
              <VscClose color="red" size={18} />
            </Tooltip>
          )),
          okNom: (item.okNom = item.overallStatus ? (
            <>
              {item.okNom ? (
                <Tooltip placement="top" title="Todo Salio bien" zIndex={9}>
                  <VscCheck color="green" size={18} />
                </Tooltip>
              ) : (
                <Tooltip placement="top" title="En proceso" zIndex={9}>
                  <p>-</p>
                </Tooltip>
              )}
            </>
          ) : (
            <Tooltip placement="top" title="Ocurrio un error" zIndex={9}>
              <VscClose color="red" size={18} />
            </Tooltip>
          )),
          okEmail: (item.okEmail = item.overallStatus ? (
            <>
              {item.okEmail ? (
                <Tooltip placement="top" title="Todo Salio bien" zIndex={9}>
                  <VscCheck color="green" size={18} />
                </Tooltip>
              ) : (
                <Tooltip placement="top" title="En proceso" zIndex={9}>
                  <p>-</p>
                </Tooltip>
              )}
            </>
          ) : (
            <Tooltip placement="top" title="Ocurrio un error" zIndex={9}>
              <VscClose color="red" size={18} />
            </Tooltip>
          )),
        })
      )

      setDataRows(newA)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <>
      <h1
        style={{
          display: 'inline',
          color: 'rgb(68, 68, 68)',
          fontSize: 'clamp(1.78em, 1.8em, 1.81em)',
        }}
      >
        {t('contractsToBeSigned')}
      </h1>
      <Dimmer.Dimmable dimmed={!data}>
        <Dimmer active={!data} inverted>
          <Loader>Cargando...</Loader>
        </Dimmer>
        <Grid
          container
          justifyContent="space-between"
          style={{
            marginBottom: '-30px',
          }}
        >
          <Grid
            item
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '1.4rem',
              marginBottom: '1.9rem',
            }}
          ></Grid>
        </Grid>

        <Box sx={{ width: '100%', paddingBottom: '4rem' }}>
          <Paper sx={{ width: '100%', mb: 2, mt: 4, boxShadow: 'none' }}>
            <Table
              className="antAprovalTable"
              columns={headCells}
              dataSource={dataRows}
              style={{ overflow: 'auto', fontFamily: 'inherit' }}
            />
          </Paper>
        </Box>
      </Dimmer.Dimmable>
    </>
  )
}

export default PreviewContracts
