import React from 'react'
import { Grid } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { filterSourceCheck } from '../../shared/filters'
import DocumentImageQuality from './DocumentImageQuality'
import DocumentSecurityFeatures from './DocumentSecurityFeatures'

const DocumentIDExtra = ({ idVerification, isSecurityFeatures }) => {
  const { t } = useTranslation()

  return (
    <>
      {idVerification?.documentImageQuality ? (
        filterSourceCheck(
          idVerification?.documentImageQuality,
          'BACK_IMAGE'
        ) ? (
          <Grid columns="equal" stackable>
            <Grid.Column width={8}>
              <DocumentImageQuality
                data={idVerification}
                src="FRONT_IMAGE"
                title={t('imageQualityFront')}
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <DocumentImageQuality
                data={idVerification}
                src="BACK_IMAGE"
                title={t('imageQualityBack')}
              />
            </Grid.Column>
          </Grid>
        ) : (
          <Grid columns="equal">
            <Grid.Column width={16}>
              <DocumentImageQuality
                data={idVerification}
                src="FRONT_IMAGE"
                title={t('imageQualityDatasheet')}
              />
            </Grid.Column>
          </Grid>
        )
      ) : null}
      {isSecurityFeatures ? (
        <Grid columns="equal">
          <Grid.Column width={16}>
            <DocumentSecurityFeatures
              data={idVerification}
              title={t('securityFeatures')}
            />
          </Grid.Column>
        </Grid>
      ) : null}
    </>
  )
}

export default DocumentIDExtra
