import { API_URL_V3 } from '../const/index.js'

export const getSignatures = async (token, page, rowsPerPage) => {
  try {
    const url = `${API_URL_V3}/sign_design?status=true&page=${page}&limit=${rowsPerPage}`
    // const url = `${API_URL_V3}/sign-design-get?status=true&page=${page}&limit=${rowsPerPage}`
    const params = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
    const response = await fetch(url, params)

    if (response.ok) {
      const result = await response.json()
      return result
    } else {
      const error = await response.json()
      return error
    }
  } catch (error) {
    return null
  }
}

export const desactiveSignature = async (token, uuid, isActive) => {
  try {
    const url = `${API_URL_V3}/sign_design/${uuid}`
    // const url = `${API_URL_V3}/sign-design-update/${uuid}`

    const params = {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        status: isActive,
      }),
    }
    const response = await fetch(url, params)

    if (response.ok) {
      const result = await response.json()
      return result
    } else {
      const error = await response.json()
      return error
    }
  } catch (error) {
    return null
  }
}

export const saveSignature = async (token, data) => {
  try {
    const url = `${API_URL_V3}/sign_design`
    // const url = `${API_URL_V3}/sign-design-add`
    const formdata = JSON.stringify(data)

    const params = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: formdata,
    }
    const response = await fetch(url, params)

    if (response.ok) {
      const result = await response.json()
      return result
    } else {
      const error = await response.json()
      return error
    }
  } catch (error) {
    return null
  }
}

export const getSignature = async (token, uuid) => {
  try {
    const url = `${API_URL_V3}/sign_design/${uuid}`
    // const url = `${API_URL_V3}/sign-design-get-by-id/${uuid}`

    const params = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await fetch(url, params)

    if (response.ok) {
      const result = await response.json()
      return result
    } else {
      const error = await response.json()
      return error
    }
  } catch (error) {
    return null
  }
}
