import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import useToken from '../../../../../utils/useToken'
import { MyButton } from '../../../../UI/ButtonSign/ButtonSign'
import {
  addDocument,
  fixedSignatory,
  addSign,
  signatory,
} from '../../../../../utils/signContract'
import { useTranslation } from 'react-i18next'
import { message as signMessage } from 'antd'
import { isArray } from 'lodash'

export default function OthersAndMe(props) {
  const {
    totalPages,
    file,
    fileBase64,
    signPositions,
    user,
    dataCountry,
    geolocation,
    checked,
    ip,
    sign,
    observers,
    signers,
    title,
    message,
    newWidth,
    newHeigth,
    onBorder,
    signBase64,
    isSorted,
  } = props

  const { t } = useTranslation()
  const getToken = useToken()
  const history = useHistory()

  const [wait, setWait] = useState(false)
  //Handle create contract
  const handleCreateContract = async () => {
    setWait(true)

    if (title === '' || message === '') {
      signMessage.warning(`${t('addTitleMessage')}`)
      setWait(false)
      return
    }

    const token = await getToken({
      audience: 'veridocid',
    })

    const data = {
      id: `${user.name.replace(/ /g, '')}_OthersAndMe`,
      country: dataCountry,
      geo: checked,
      language: 'es',
      signType: 'ELECTRONIC_SIGNATURE',
      document: fileBase64.replace('data:application/pdf;base64,', ''),
      documentName: file.name.replace('.docx', '.pdf').replace('.doc', '.pdf'),
      create_name: user.name,
      create_email: user.email,
      create_ip: ip,
      create_ubication: geolocation,
      signSize: {
        width: Number(newWidth),
        height: Number(newHeigth),
      },
      hasBorder: onBorder,
      logoSign: signBase64,
    }

    const resAddDocument = await addDocument(token, data)

    if (resAddDocument) {
      if (resAddDocument?.error) {
        if (isArray(resAddDocument?.error?.message)) {
          signMessage.error(`${resAddDocument.error?.message[0]}`)
        } else {
          signMessage.error(`${resAddDocument.error?.message}`)
        }
        return
      }

      if (resAddDocument.status === 200) {
        signMessage.success(`${t('addDocumentSuccess')}`)

        await executeFixedSignatory(
          token,
          resAddDocument.documentID,
          resAddDocument.id,
          resAddDocument.uuid
        )
      }

      if (resAddDocument.status !== 200 && !resAddDocument.error) {
        signMessage.warning(`${resAddDocument.message}`)
      }
    } else signMessage.error(`${t('errorAddDocument')}`)

    setWait(false)
  }

  const executeFixedSignatory = async (token, documentID, id, uuid) => {
    let newA = []
    let internalSignPositions = []
    //Evita que haya firmas en hojas inexistentes
    for (let signPosition of signPositions)
      if (signPosition.page <= totalPages)
        internalSignPositions.push(signPosition)

    //Independientemente del zoom las posiciones de la caja no cambiaran
    //En este punto se convierten las coordenadas a escala 1/1 y se remueve el atributo zoom
    for (let signPosition of internalSignPositions) {
      newA = [
        ...newA,
        {
          coordinates: {
            x: signPosition.coordinates.x / signPosition.zoom,
            y: signPosition.coordinates.y / signPosition.zoom,
          },
          page: signPosition.page,
          user: signPosition.user,
        },
      ]
    }

    const data = {
      id,
      uuid,
      documentID,
      staticSignPositions: newA,
      participants: [
        {
          name: user.name,
          email: user.email,
          biometric: false,
          signerNumber: 0,
        },
        ...signers,
      ],
      observers,
      isSorted: isSorted,
    }

    const resFixedSignatory = await fixedSignatory(token, data)

    if (resFixedSignatory) {
      if (resFixedSignatory.error) {
        if (isArray(resFixedSignatory?.error?.message)) {
          signMessage.error(`${resFixedSignatory.error?.message[0]}`)
        } else {
          signMessage.error(`${resFixedSignatory.error?.message}`)
        }
        return
      }

      if (resFixedSignatory.status === 200) {
        signMessage.success(`${t('fixedSignatorySuccess')}`)

        let participantId = ''
        for (let participant of resFixedSignatory.participants) {
          if (participant.email === user.email) {
            participantId = participant.participantId
            break
          }
        }

        await executeAddSignature(
          documentID,
          participantId,
          id,
          resFixedSignatory.uuid,
          token
        )
      }

      if (resFixedSignatory.status !== 200 && !resFixedSignatory.error) {
        signMessage.warning(`${resFixedSignatory.message}`)
      }
    } else signMessage.error(`${t('errorAddSignatories')}`)
  }

  const executeAddSignature = async (
    documentID,
    participantId,
    id,
    uuid,
    token
  ) => {
    let leftMargin = 22
    let newA = []

    if (sign.option === 3) {
      leftMargin = 9
    }

    let internalSignPositions = []
    //Evita que haya firmas en hojas inexistentes
    for (let signPosition of signPositions)
      if (signPosition.page <= totalPages)
        internalSignPositions.push(signPosition)

    for (let signPosition of internalSignPositions) {
      if (signPosition.user.email === user.email)
        newA.push({
          user: {
            email: user.email,
          },
          coordinates: {
            x: signPosition.coordinates.x + leftMargin,
            y: signPosition.coordinates.y + 19,
          },
          page: signPosition.page,
        })
    }

    const data = {
      id,
      uuid,
      documentID,
      participantId,
      staticSignPositions: newA,
      imageB64: sign.signature,
      ubicacion: geolocation,
      ip,
    }

    const resAddSign = await addSign(token, data)

    if (resAddSign) {
      if (resAddSign.error) {
        if (isArray(resAddSign?.error?.message))
          signMessage.error(`${resAddSign.error?.message[0]}`)
        else signMessage.error(`${resAddSign.error?.message}`)
        return
      }

      if (resAddSign.status === 200) {
        signMessage.success(`${t('signedDocumentSuccess')}`)

        executeSignatory(documentID, id, uuid, token)
      }

      if (resAddSign.status !== 200 && !resAddSign.error)
        signMessage.warning(`${resAddSign.message}`)
    } else signMessage.error(`${t('errorAddSign')}`)
  }

  const executeSignatory = async (documentID, id, uuid, token) => {
    // Only get signers data
    let arraySignatories = []

    signers.length > 0 &&
      signers.map((data) =>
        arraySignatories.push({ name: data?.name, email: data?.email })
      )

    const data = {
      id,
      uuid,
      documentID,
      message,
      title,
      signatory: arraySignatories,
      sharedWith: observers,
    }
    const resSignatory = await signatory(token, data)

    if (resSignatory) {
      if (resSignatory?.error) {
        if (isArray(resSignatory?.error?.message)) {
          signMessage.error(`${resSignatory.error?.message[0]}`)
        } else {
          signMessage.error(`${resSignatory.error?.message}`)
        }
        return
      }

      if (resSignatory.status === 200) {
        signMessage.success(`${t('sendEmails')}`)

        history.push(`/resultado-contrato/${documentID}/${uuid}/${id}`)
      }

      if (resSignatory.status !== 200 && !resSignatory.error)
        signMessage.warning(`${resSignatory.message}`)
    } else signMessage.error(`${t('erroSignatory')}`)
  }

  return (
    <>
      <MyButton
        type="button"
        disabled={wait}
        onClick={handleCreateContract}
        loading={wait}
      >
        Continuar
      </MyButton>
    </>
  )
}
