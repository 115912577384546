import React from 'react'
import { SiGravatar, SiMyspace } from 'react-icons/si'
import {
  FaFacebookSquare,
  FaInstagram,
  FaTelegram,
  FaTwitter,
  FaViber,
  FaWhatsapp,
  FaGoogle,
  FaYahoo,
  FaApple,
  FaFoursquare,
  FaGithub,
  FaMicrosoft,
  FaSkype,
  FaPinterest,
  FaSpotify,
  FaEbay,
  FaFlickr,
  FaLastfm,
  FaLinkedin,
  FaTumblr,
  FaVimeo,
  FaWeibo,
} from 'react-icons/fa'

const getSocialIcons = (socialName, color) => {
  switch (socialName) {
    case 'facebook':
      return <FaFacebookSquare color={color} size="1.8rem" />
    case 'google':
      return <FaGoogle color={color} size="1.8rem" />
    case 'instagram':
      return <FaInstagram color={color} size="1.8rem" />
    case 'telegram':
      return <FaTelegram color={color} size="1.8rem" />
    case 'twitter':
      return <FaTwitter color={color} size="1.8rem" />
    case 'viber':
      return <FaViber color={color} size="1.8rem" />
    case 'whatsapp':
      return <FaWhatsapp color={color} size="1.8rem" />
    case 'yahoo':
      return <FaYahoo color={color} size="1.6rem" />
    case 'apple':
      return <FaApple color={color} size="1.8rem" />
    case 'foursquare':
      return <FaFoursquare color={color} size="1.8rem" />
    case 'github':
      return <FaGithub color={color} size="1.8rem" />
    case 'microsoft':
      return <FaMicrosoft color={color} size="1.8rem" />
    case 'skype':
      return <FaSkype color={color} size="1.8rem" />
    case 'pinterest':
      return <FaPinterest color={color} size="1.8rem" />
    case 'spotify':
      return <FaSpotify color={color} size="1.8rem" />
    case 'ebay':
      return <FaEbay color={color} size="1.8rem" />
    case 'flickr':
      return <FaFlickr color={color} size="1.8rem" />
    case 'lastfm':
      return <FaLastfm color={color} size="1.8rem" />
    case 'linkedin':
      return <FaLinkedin color={color} size="1.8rem" />
    case 'tumblr':
      return <FaTumblr color={color} size="1.8rem" />
    case 'vimeo':
      return <FaVimeo color={color} size="1.8rem" />
    case 'weibo':
      return <FaWeibo color={color} size="1.8rem" />
    case 'gravatar':
      return <SiGravatar color={color} size="1.8rem" />
    case 'myspace':
      return <SiMyspace color={color} size="1.8rem" />
    default:
      return <span />
  }
}

export default getSocialIcons
