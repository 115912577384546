import React from 'react'
import PlatformLabel from '../components/UI/PlatformLabel/PlatformLabel'
import PlatformLabelWithInfo from '../components/UI/PlatformLabelWithInfo/PlatformLabelWithInfo'
import getSocialIcons from './getSocialIcons'

export const getRegisteredPlatforms = (account_details) => {
  const registeredPlatforms = Object.keys(account_details).map((key, index) => {
    const isInfo =
      account_details[key]?.photo ||
      account_details[key]?.name ||
      account_details[key]?.last_seen ||
      account_details[key]?.age

    if (account_details[key]?.registered && !isInfo) {
      const icon = getSocialIcons(key, '#60BDB2')
      const upper = key.charAt(0).toUpperCase()

      return (
        <PlatformLabel
          key={index}
          icon={icon}
          upper={upper}
          name={key}
          backgroundColor="#DFF2F0"
          color="#60BDB2"
        />
      )
    }
    return null
  })

  return registeredPlatforms
}

export const getUnregisteredPlatforms = (account_details) => {
  const unregisteredPlatforms = Object.keys(account_details).map(
    (key, index) => {
      if (!account_details[key]?.registered) {
        const textColor =
          account_details[key]?.registered === null ? '#FDACAC' : '#BDBDBD'
        const backColor =
          account_details[key]?.registered === null ? '#FFF6F6' : '#F0F1F4'
        const icon = getSocialIcons(key, textColor)
        const upper = key.charAt(0).toUpperCase()

        return (
          <PlatformLabel
            key={index}
            icon={icon}
            upper={upper}
            name={key}
            backgroundColor={backColor}
            color={textColor}
          />
        )
      }
      return null
    }
  )

  return unregisteredPlatforms
}

export const getRegisteredPlatformsWithInfo = (account_details) => {
  const registeredPlatformsWithInfo = Object.keys(account_details).map(
    (key, index) => {
      const isInfo =
        account_details[key]?.photo ||
        account_details[key]?.name ||
        account_details[key]?.last_seen ||
        account_details[key]?.age

      if (account_details[key]?.registered && isInfo) {
        const icon = getSocialIcons(key, '#60BDB2')
        const upper = key.charAt(0).toUpperCase()

        return (
          <PlatformLabelWithInfo
            key={index}
            icon={icon}
            upper={upper}
            text={key}
            backgroundColor="#DFF2F0"
            color="#60BDB2"
            photo={account_details[key]?.photo}
            name={account_details[key]?.name}
            city={account_details[key]?.city}
            country={account_details[key]?.country}
            bio={account_details[key]?.bio}
            age={account_details[key]?.age}
            id={account_details[key]?.id}
            handle={account_details[key]?.handle}
            gender={account_details[key]?.gender}
            lastSeen={account_details[key]?.last_seen}
          />
        )
      }
      return null
    }
  )

  return registeredPlatformsWithInfo
}
