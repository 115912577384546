import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from 'semantic-ui-react'
import { Image } from 'antd'

// Asstests, Shared
import { filterByCategory, filterCheckResult } from '../../shared/filters'
import iconAwesomeCheck from '../../assets/icons/awesome-check.svg'
import iconAwesomeFail from '../../assets/icons/awesome-fail.svg'
import iconAwesomeExclamation from '../../assets/icons/awesome-exclamation.svg'

const DocumentSecurityFeatures = ({ data, title }) => {
  const { t } = useTranslation()
  const securityFeatures = filterByCategory(
    data?.documentVerifications,
    'SecurityFeatures'
  )
  const isFailed = filterCheckResult(securityFeatures, 'Failed')

  const rows = securityFeatures?.map((res) => {
    const reference = res?.inputFields ? (
      <Image
        src={`data:image/${res?.ext ?? 'png'};base64,${
          res?.inputFields[0]?.value
        }`}
        alt="reference"
        width={'5rem'}
        height={'auto'}
        key={Math.random()}
      />
    ) : null

    const obtained = res?.inputFields ? (
      <Image
        src={`data:image/${res?.ext ?? 'png'};base64,${
          res?.inputFields[1]?.value
        }`}
        alt="obtained"
        width={'5rem'}
        height={'auto'}
        key={Math.random()}
      />
    ) : null

    return (
      <Table.Row key={Math.random()}>
        <Table.Cell width={4} textAlign={'center'}>
          {res?.name}
        </Table.Cell>
        <Table.Cell width={4} textAlign={'center'}>
          {reference}
        </Table.Cell>
        <Table.Cell width={4} textAlign={'center'}>
          {obtained}
        </Table.Cell>
        <Table.Cell width={2} textAlign={'center'}>
          {res?.output}
        </Table.Cell>
        <Table.Cell width={2} textAlign={'center'}>
          <img
            src={
              res?.result === 'Ok'
                ? iconAwesomeCheck
                : res?.result === 'Overriden'
                ? iconAwesomeExclamation
                : iconAwesomeFail
            }
            alt="Result"
            style={{ width: '1.5rem' }}
          />
        </Table.Cell>
      </Table.Row>
    )
  })

  return (
    <Fragment>
      <h3 className="ui header" style={{ marginTop: '1rem' }}>
        {title}
      </h3>
      <Table color={isFailed ? 'red' : 'green'}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={4} textAlign={'center'}>
              <strong>{t('name')}</strong>
            </Table.HeaderCell>
            <Table.HeaderCell width={4} textAlign={'center'}>
              <strong>{t('reference')}</strong>
            </Table.HeaderCell>
            <Table.HeaderCell width={4} textAlign={'center'}>
              <strong>{t('obtained')}</strong>
            </Table.HeaderCell>
            <Table.HeaderCell width={2} textAlign={'center'}>
              <strong>{t('output')}</strong>
            </Table.HeaderCell>
            <Table.HeaderCell width={2} textAlign={'center'}>
              <strong>{t('result')}</strong>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{rows}</Table.Body>
      </Table>
    </Fragment>
  )
}

export default DocumentSecurityFeatures
