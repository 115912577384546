import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Route, withRouter } from 'react-router-dom'
import { useAuth0 } from '../react-auth0-spa'

const PrivateRoute = ({
  component: Component,
  path,
  role,
  history,
  ...rest
}) => {
  const { loading, isAuthenticated, loginWithRedirect, user } = useAuth0()
  const [hasRole, setHasRole] = useState(false)
  const hasRoleCkeck = !!role

  useEffect(() => {
    if (loading || isAuthenticated) {
      return
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: path },
      })
    }
    fn()
  }, [loading, isAuthenticated, loginWithRedirect, path])

  useEffect(() => {
    if (user && hasRoleCkeck) {
      const hasRole =
        hasRoleCkeck &&
        user?.['https://vdid.sumamexico.com/roles']?.includes(role)
      setHasRole(hasRole)
      if (!hasRole) {
        history.push('/')
      }
    }
  }, [hasRoleCkeck, history, role, user])

  const render = (props) => {
    if (isAuthenticated === true) {
      if (hasRoleCkeck) {
        if (hasRole) {
          return <Component {...props} />
        } else {
          return null
        }
      } else {
        return <Component {...props} />
      }
    }
    return null
  }

  return <Route path={path} render={render} {...rest} />
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  role: PropTypes.oneOfType([PropTypes.string]),
}

export default withRouter(PrivateRoute)
