import React, { useState, useEffect } from 'react'
import { Grid, Button, Backdrop, CircularProgress } from '@mui/material'
import {
  Tools,
  Tool,
  MoreTools,
  Contract,
  Input,
  UploadPDF,
  Instructions,
  ShowPDF,
  DDZone,
} from './ViewPDFStyles.jsx'
import { PDFDocument } from 'pdf-lib'
import { message as signMessage } from 'antd'
import { Document, Page, pdfjs } from 'react-pdf'
import {
  VscArrowLeft,
  VscArrowRight,
  VscZoomIn,
  VscZoomOut,
  VscFilePdf,
} from 'react-icons/vsc'
import { convertDocx } from '../../../../utils/signContract.js'
import { useTranslation } from 'react-i18next'
import useToken from '../../../../utils/useToken.js'
import { IS_CVR } from '../../../../const/index.js'
import { useAuth0 } from '../../../../react-auth0-spa.js'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export default function ViewPDF(props) {
  const [loading, setLoading] = useState(false)
  const getToken = useToken()
  const { t } = useTranslation()
  const { user, isAuthenticated } = useAuth0()

  // Validate clientId
  const [clientID, setClientID] = useState({})

  useEffect(() => {
    if (isAuthenticated)
      setClientID(
        user?.['https://vdid.sumamexico.com/app_metadata']?.clientId ?? null
      )
  }, [isAuthenticated, user])

  const {
    changePDF,
    setFile,
    fileBase64,
    setFileBase64,
    page,
    setPage,
    totalPages,
    setTotalPages,
    zoom,
    setZoom,
    pageSize,
    handleRenderSuccess,
    dd_dragstart,
    setEventDD,
    isFirstChange,
    setIsFirstChange,
  } = props

  //METODOS PARA AGREGAR O CAMBIAR EL PDF---------------------------------------------------
  const clickPDF = () => document.getElementById('file').click()

  const stopDefault = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDragEnter = (e) => stopDefault(e)

  const handleDragLeave = (e) => stopDefault(e)

  const handleDragOver = (e) => stopDefault(e)

  const dropPDF = (e) => {
    stopDefault(e)
    const fileInput = document.getElementById('file')
    fileInput.files = e.dataTransfer.files
    changeInput()
  }

  const joinPdfs = async (resBase64) => {
    const _pdfs = []

    for (const a of resBase64) {
      if (a.type !== 'application/pdf') {
        signMessage.error(
          `Solo se pueden subir multiples archivos con extension pdf`
        )
        setFileBase64('false')
        return
      }
      let fileReader = new FileReader()
      fileReader.readAsDataURL(a)
      const targetResult = await new Promise((res, rej) => {
        fileReader.onload = (x) => res(x.target.result)
      })
      _pdfs.push(targetResult)
    }

    const finalDoc = await PDFDocument.create()
    // let i = 1
    for (const e of _pdfs) {
      const page = await PDFDocument.load(e),
        contentPages = await finalDoc.copyPages(page, page.getPageIndices())

      for (const k of contentPages) finalDoc.addPage(k)

      // i++
    }
    let finalPDF = Buffer.from(await finalDoc.save()).toString('base64')
    return finalPDF
  }

  const changeInput = async () => {
    const fileInput = document.getElementById('file')

    if (!fileInput.files.length) return

    if (fileInput.files.length > 1) {
      const res = await joinPdfs(fileInput.files)

      if (res === undefined) {
        setFile(false)
        setFileBase64('false')
        return
      } else {
        setFile(fileInput.files[0])
        setFileBase64('data:application/pdf;base64,' + res)
        return
      }
    }

    if (!fileInput.files[0]) {
      return
    }

    const transformMb = fileInput.files[0].size / 1024 / 1024

    if (IS_CVR.includes(clientID)) {
      if (transformMb > 40) {
        signMessage.error(`El archivo supera el limite de 40mb`)
        return
      }
    } else {
      if (transformMb > 20) {
        signMessage.error(`El archivo supera el limite de 20mb`)
        return
      }
    }

    if (fileInput.files[0].type === 'application/pdf') {
      const fileReader = new FileReader()
      if (fileInput.files[0]) {
        fileReader.readAsDataURL(fileInput.files[0])
        fileReader.onload = async (x) => {
          setFileBase64(x.target.result)
          setFile(fileInput.files[0])
        }
        if (isFirstChange) {
          setEventDD(false)
          setIsFirstChange(false)
        }
      } else setFileBase64('false')
      return
    } else {
      setLoading(true)
      const fileReader = new FileReader()
      const token = await getToken({ audience: 'veridocid' })

      if (fileInput.files[0]) {
        fileReader.readAsDataURL(fileInput.files[0])

        fileReader.onload = async (x) => {
          const result = await convertDocx(token, fileInput.files[0])

          if (result === null || result.error !== 0) {
            signMessage.error(
              `Ha ocurrio un error al subir el documento. Porfavor, intentalo nuevamente`
            )
            setLoading(false)
            return
          }

          setFile(fileInput.files[0])
          setFileBase64('data:application/pdf;base64,' + result.result)
          setLoading(false)
          if (isFirstChange) {
            setEventDD(false)
            setIsFirstChange(false)
          }
        }
      } else setFileBase64('false')
      return
    }
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setTotalPages(numPages)
    setPage(1)
  }

  //METODOS PARA DESPLAZARSE O INTERACTUAR CON EL PDF---------------------------------------
  const changePage = (offset) =>
    setPage((prevPageNumber) => prevPageNumber + offset)

  const previousPage = () => {
    if (page > 1) changePage(-1)
  }

  const nextPage = () => {
    if (page < totalPages) changePage(1)
  }

  const zoomOut = () => {
    let newZoom = zoom - 0.1
    newZoom = newZoom.toFixed(1)
    newZoom = parseFloat(newZoom)

    if (newZoom >= 0.1) setZoom(newZoom)
  }

  const zoomIn = () => {
    let newZoom = zoom + 0.1
    newZoom = newZoom.toFixed(1)
    newZoom = parseFloat(newZoom)

    if (newZoom <= 2) setZoom(newZoom)
  }

  return (
    <>
      <Backdrop
        sx={{
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <p>Procesando documento...</p>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid item xs={changePDF ? 12 : fileBase64 === 'false' ? 12 : 8}>
        <Tools container>
          <Grid item xs={5}>
            {fileBase64 !== 'false' ? (
              <Grid container>
                <Tool item xs={4}>
                  {page > 1 ? (
                    <Button onClick={previousPage} style={{ outline: 'none' }}>
                      <VscArrowLeft
                        size={24}
                        color={'#eee'}
                        strokeWidth={0.3}
                      />
                    </Button>
                  ) : null}
                </Tool>
                <Tool item xs={4}>
                  <div>
                    {page || (totalPages ? 1 : '--')} / {totalPages || '--'}
                  </div>
                </Tool>
                <Tool item xs={4}>
                  {page < totalPages ? (
                    <Button onClick={nextPage} style={{ outline: 'none' }}>
                      <VscArrowRight
                        size={24}
                        color={'#eee'}
                        strokeWidth={0.3}
                      />
                    </Button>
                  ) : null}
                </Tool>
              </Grid>
            ) : null}
          </Grid>
          <Grid item xs={5}>
            {fileBase64 !== 'false' ? (
              <Grid container>
                <Tool item xs={4}>
                  <Button onClick={zoomOut} style={{ outline: 'none' }}>
                    <VscZoomOut size={24} color={'#eee'} strokeWidth={0.3} />
                  </Button>
                </Tool>
                <Tool item xs={4}>
                  <div>{parseInt(zoom * 100)}%</div>
                </Tool>
                <Tool item xs={4}>
                  {zoom < 2 ? (
                    <Button onClick={zoomIn} style={{ outline: 'none' }}>
                      <VscZoomIn size={24} color={'#eee'} strokeWidth={0.3} />
                    </Button>
                  ) : null}
                </Tool>
              </Grid>
            ) : null}
          </Grid>
          <MoreTools item xs={2}>
            {fileBase64 !== 'false' ? (
              <Button style={{ outline: 'none' }} onClick={clickPDF}>
                <VscFilePdf size={24} color={'#eee'} strokeWidth={0.3} />
                <Input
                  accept=".pdf,.doc,.docx"
                  type="file"
                  name="file"
                  id="file"
                  onChange={changeInput}
                  multiple
                />
              </Button>
            ) : null}
          </MoreTools>
        </Tools>
        {fileBase64 === 'false' ? (
          <Contract
            item
            xs={12}
            id="drop-zone"
            onClick={clickPDF}
            onDrop={(e) => dropPDF(e)}
            onDragOver={(e) => handleDragOver(e)}
            onDragEnter={(e) => handleDragEnter(e)}
            onDragLeave={(e) => handleDragLeave(e)}
          >
            <Input
              accept=".pdf,.doc,.docx"
              type="file"
              name="file"
              id="file"
              onChange={changeInput}
              multiple
            />
            <VscFilePdf size={90} color={'var(--secondary-color)'} />
            <UploadPDF>{`${t('uploadPdf')}`}</UploadPDF>
            <Instructions>{`${t('clickOrDragPDF')}`}</Instructions>
          </Contract>
        ) : (
          <ShowPDF item xs={12} id="pdf_renderer">
            <Document file={fileBase64} onLoadSuccess={onDocumentLoadSuccess}>
              <Page
                pageNumber={page}
                scale={zoom}
                onRenderSuccess={(e) => handleRenderSuccess(e)}
              />
            </Document>
            <DDZone
              item
              id="ddzone"
              onDragStart={(e) => dd_dragstart(e)}
              width={pageSize[0]}
              height={pageSize[1]}
            />
          </ShowPDF>
        )}
      </Grid>
    </>
  )
}
