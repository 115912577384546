import React, { useState, useEffect } from 'react'
import { Collapse } from 'antd'
import { Right, RightTitle } from './ConfigurationsPDFStyle.jsx'
import MainConfigurations from './MainConfigurations/MainConfigurations'
import Signers from './Signers/Signers'
import Observers from './Observers/Observers'
import Message from './Message/Message'
import Sign from './Sign/Sign'
import OnlyMeSign from './ConfirmSign/OnlyMeSign'
import Others from './ConfirmSign/Others.jsx'
import OthersAndMe from './ConfirmSign/OthersAndMe.jsx'
import SignatureSettings from '../../SettingsSign/SettingsSign'
import './ConfigurationsPDFStyle.css'
import '../../../../styles/GeneralStylesContracts.css'

export default function ConfigurationsPDF(props) {
  const {
    totalPages,
    pageSize,
    file,
    fileBase64,
    whoSign,
    setWhoSign,
    sign,
    setSign,
    zoom,
    signers,
    setSigners,
    signPositions,
    setSignPositions,
    user,
    setOpen,
    boxSign,
    onBorder,
    setOnBorder,
    dd_dragstart,
    setImageBoxState,
    setNewWidth,
    newWidth,
    setNewHeigth,
    newHeigth,
    setSignBase64,
    signBase64,
  } = props
  const { Panel } = Collapse
  //MainConfigurations
  const [checked, setChecked] = useState(false) //Participant geolocation

  const [dataCountry, setDataCountry] = useState('Mexico')
  //Signers
  const [sortStatus, setSortStatus] = useState(false)
  const [currentDelete, setCurrentDelete] = useState([])
  // const [currentVerification, setCurrentVerification] = useState([])
  let currentVerification = []
  const [geolocation, setGeolocation] = useState('') //Me and creator geolocation
  const [ip, setIP] = useState('')
  //Observers
  const [observers, setObservers] = useState([])
  const [currentDeleteObserver, setCurrentDeleteObserver] = useState([])
  //Message
  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')

  const handleReset = () => {
    setSign({
      signature: '',
      currentDate: '',
      option: 1,
    })
    setSignPositions([])
    setChecked(false)
    setDataCountry('Mexico')
    setSortStatus(false)
    setSigners([])
    setGeolocation('')
    setIP('')
    setObservers([])
    setTitle('')
    setMessage('')
  }

  /*-------------------------------------------- 
    COMPROVE SIGNATORIES
  ----------------------------------------------*/

  const handleComproveSigners = () => {
    // Zona A (PDF)
    const orderSigners = document.getElementById('orderSigners')
    if (orderSigners) {
      //Elimina los hijos ya existentes, Evita duplicados
      const tope = orderSigners.childElementCount
      for (let i = 0; i < tope; i++) {
        orderSigners.removeChild(orderSigners.firstChild)
      }
      //Apartir de Signers creara las tarjetas
      for (let signer of signers) {
        let mainLi = document.createElement('li')
        let mainInformationDiv = document.createElement('div')
        let informationDiv = document.createElement('div')
        let informationDivDivEmail = document.createElement('div')
        let informationDivTextEmail = document.createTextNode(`${signer.email}`)
        let informationDivDivName = document.createElement('div')
        let informationDivTextName = document.createTextNode(`${signer.name}`)
        let deleteDiv = document.createElement('div')
        let deleteButton = document.createElement('button')
        let deleteButtonText = document.createTextNode('x')
        // let optionsDiv = document.createElement('div')
        // let optionsDivIdentity = document.createElement('div')
        // let checkIdentity = document.createElement('input')
        // let spanIdentity = document.createElement('span')
        // let textIdentity = document.createTextNode('Verificación de identidad')

        mainLi.classList = 'mainLi'
        mainLi.id = `${signer.email}_li`
        mainLi.style.cursor = sortStatus ? 'pointer' : 'normal'

        informationDiv.classList = 'informationDiv'
        informationDivDivName.appendChild(informationDivTextName)
        informationDiv.appendChild(informationDivDivName)
        informationDivDivEmail.appendChild(informationDivTextEmail)
        informationDiv.appendChild(informationDivDivEmail)

        mainInformationDiv.classList = 'mainInformationDiv'
        mainInformationDiv.appendChild(informationDiv)

        deleteDiv.classList = 'deleteDiv'

        deleteButton.appendChild(deleteButtonText)
        deleteButton.classList = 'deleteButton'
        deleteButton.id = `${signer.email}_li `
        deleteButton.onclick = function (e) {
          setCurrentDelete([{ element: e.target }])
        }
        deleteDiv.appendChild(deleteButton)
        mainInformationDiv.appendChild(deleteDiv)

        // optionsDiv.classList = 'optionsDiv'
        // optionsDivIdentity.classList = 'optionsDivIdentity'

        // spanIdentity.appendChild(textIdentity)
        // optionsDivIdentity.appendChild(spanIdentity)

        // checkIdentity.type = 'checkbox'
        // checkIdentity.classList = 'checkIdentity'
        // checkIdentity.checked = signer.biometric
        // checkIdentity.id = `${signer.email}_li  `
        // checkIdentity.onchange = function (e) {
        //   setCurrentVerification([{ element: e.target }])
        // }
        // optionsDivIdentity.appendChild(checkIdentity)
        // optionsDiv.appendChild(optionsDivIdentity)

        //mainInformationDiv.appendChild(optionsDiv)
        mainLi.appendChild(mainInformationDiv)
        orderSigners.appendChild(mainLi)
      }
    }
  }

  const handleCurrentSignersVerification = (currentVerification) => {
    if (currentVerification.length !== 0) {
      let newA = []
      //Para actualizar el arreglo de firmantes
      for (let signer of signers) {
        if (`${signer.email}_li` !== currentVerification[0].element.id.trim())
          newA = [...newA, signer]
        else
          newA = [
            ...newA,
            {
              uuid: signer.uuid,
              name: signer.name,
              email: signer.email,
              biometric: !signer.biometric,
            },
          ]
      }
      setSigners(newA)
    }
  }

  useEffect(() => {
    handleCurrentSignersVerification(currentVerification)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVerification])

  /*-------------------------------------------- 
    COMPROVE OBSERVERS
  ----------------------------------------------*/

  const handleComproveObserver = () => {
    const orderObserver = document.getElementById('orderObserver')
    if (orderObserver) {
      //Elimina los hijos ya existentes, Evita duplicados
      const childs = orderObserver.childElementCount
      for (let i = 0; i < childs; i++) {
        orderObserver.removeChild(orderObserver.firstChild)
      }
      //Apartir de observers creara las tarjetas
      for (let observer of observers) {
        let newSpan = document.createElement('span')
        let name_observer = document.createTextNode(`${observer.name}`)
        let email_observer = document.createTextNode(`${observer.email}`)
        newSpan.id = `${observer.email}_ctn`
        newSpan.classList = 'divLi'
        newSpan.style.cursor = 'normal'

        orderObserver.appendChild(newSpan)

        // Info signer
        let newSpanName = document.createElement('span')
        newSpanName.classList = 'spanNameSigner'
        newSpanName.appendChild(name_observer)

        let newSpanEmail = document.createElement('span')
        newSpanEmail.classList = 'spanEmailSigner'
        newSpanEmail.appendChild(email_observer)

        let newDivContentInfo = document.createElement('div')
        newDivContentInfo.classList = 'contentInfoAddSign'
        newDivContentInfo.appendChild(newSpanName)
        newDivContentInfo.appendChild(newSpanEmail)

        // Delete signer
        let newDiv = document.createElement('div')
        let newDivText = document.createTextNode('×')
        // newDiv.id = `${signer.email}_li `
        newDiv.classList = 'delLi'

        let deleteObserverBtn = document.createElement('span')
        deleteObserverBtn.appendChild(newDivText)
        deleteObserverBtn.id = `${observer.email}_li `
        deleteObserverBtn.classList = 'deleteButton'
        deleteObserverBtn.onclick = function (e) {
          setCurrentDeleteObserver([{ element: e.target }])
        }

        newDiv.appendChild(deleteObserverBtn)
        newSpan.appendChild(newDivContentInfo)
        newSpan.appendChild(newDiv)
      }
    }
  }

  useEffect(() => {
    handleComproveSigners()
    handleComproveObserver()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signers, sortStatus, observers])

  //Ayuda a eliminar un firmante manteniendo los datos actualizados
  const handleCurrentDelete = (currentDelete) => {
    if (currentDelete.length !== 0) {
      let newA = []
      let newAP = []
      //Para actualizar el arreglo de firmantes
      for (let signer of signers) {
        if (`${signer.email}_li` !== currentDelete[0].element.id.trim())
          newA = [...newA, signer]
      }
      //Para actualizar el arreglo de posiciones
      for (let signPosition of signPositions) {
        if (
          `${signPosition?.user?.email}_li` !==
          currentDelete[0].element.id.trim()
        )
          newAP = [...newAP, signPosition]
      }
      setSigners(newA)
      setSignPositions(newAP)
    }
  }

  const handleCurrentDeleteObservers = (currentDeleteObserver) => {
    if (currentDeleteObserver.length !== 0) {
      let newA = []
      //Para actualizar el arreglo de firmantes
      for (let observer of observers) {
        if (
          `${observer.email}_li` !== currentDeleteObserver[0].element.id.trim()
        )
          newA = [...newA, observer]
      }
      setObservers(newA)
    }
  }

  useEffect(() => {
    handleCurrentDelete(currentDelete)
    handleCurrentDeleteObservers(currentDeleteObserver)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDelete, currentDeleteObserver])

  return (
    <Right item xs={4}>
      <RightTitle>Configuraciones</RightTitle>
      <Collapse
        defaultActiveKey={['a', 'b', 'c']}
        style={{
          '& .antCollapseItem': {
            backgroundColor: 'rgb(238, 238, 238)',
          },
        }}
      >
        <Panel
          header="Principales"
          key="a"
          style={{
            backgroundColor: ' rgba(120,120,120,0.1)',
            color: ' rgba(0,0,0,.87)',
          }}
        >
          <MainConfigurations
            dataCountry={dataCountry}
            setDataCountry={setDataCountry}
            checked={checked}
            setChecked={setChecked}
            whoSign={whoSign}
            setWhoSign={setWhoSign}
            handleReset={handleReset}
          />
        </Panel>
        <Panel
          header="Firmantes y Observadores"
          key="b"
          style={{
            backgroundColor: ' rgba(120,120,120,0.1)',
            color: ' rgba(0,0,0,.87)',
          }}
        >
          {whoSign !== 'me' ? (
            <Signers
              signers={signers}
              setSigners={setSigners}
              sortStatus={sortStatus}
              setSortStatus={setSortStatus}
            />
          ) : null}
          <Observers observers={observers} setObservers={setObservers} />
          {observers.length !== 0 || signers.length !== 0 ? (
            <Message
              title={title}
              setTitle={setTitle}
              message={message}
              setMessage={setMessage}
            />
          ) : null}
        </Panel>

        <Panel
          header="Firma"
          key="c"
          style={{
            backgroundColor: ' rgba(120,120,120,0.1)',
            color: ' rgba(0,0,0,.87)',
          }}
        >
          <Sign
            totalPages={totalPages}
            pageSize={pageSize}
            user={user}
            zoom={zoom}
            sign={sign}
            signers={signers}
            signPositions={signPositions}
            setSignPositions={setSignPositions}
            setOpen={setOpen}
            geolocation={geolocation}
            setGeolocation={setGeolocation}
            ip={ip}
            setIP={setIP}
            boxSign={boxSign}
            dd_dragstart={dd_dragstart}
            whoSign={whoSign}
          />
        </Panel>
        <Panel
          header="Configuracion de firma"
          key={'d'}
          style={{
            backgroundColor: ' rgba(120,120,120,0.1)',
            color: ' rgba(0,0,0,.87)',
          }}
        >
          <SignatureSettings
            setImageBoxState={setImageBoxState}
            setNewWidth={setNewWidth}
            setNewHeigth={setNewHeigth}
            newWidth={newWidth}
            newHeigth={newHeigth}
            boxSign={boxSign}
            setOnBorder={setOnBorder}
            onBorder={onBorder}
            setSignBase64={setSignBase64}
          />
        </Panel>
        {signPositions.length !== 0 && whoSign === 'me' ? (
          <OnlyMeSign
            totalPages={totalPages}
            file={file}
            fileBase64={fileBase64}
            signPositions={signPositions}
            user={user}
            dataCountry={dataCountry}
            checked={checked}
            geolocation={geolocation}
            ip={ip}
            sign={sign}
            observers={observers}
            title={title}
            message={message}
            newWidth={newWidth}
            newHeigth={newHeigth}
            onBorder={onBorder}
            signBase64={signBase64}
          />
        ) : null}
        {signPositions.length !== 0 && whoSign === 'others' ? (
          <Others
            totalPages={totalPages}
            file={file}
            fileBase64={fileBase64}
            signPositions={signPositions}
            user={user}
            dataCountry={dataCountry}
            geolocation={geolocation}
            checked={checked}
            ip={ip}
            observers={observers}
            signers={signers}
            title={title}
            message={message}
            newWidth={newWidth}
            newHeigth={newHeigth}
            onBorder={onBorder}
            signBase64={signBase64}
            isSorted={sortStatus}
          />
        ) : null}
        {signPositions.length !== 0 && whoSign === 'me_others' ? (
          <OthersAndMe
            totalPages={totalPages}
            file={file}
            fileBase64={fileBase64}
            signPositions={signPositions}
            user={user}
            dataCountry={dataCountry}
            geolocation={geolocation}
            checked={checked}
            ip={ip}
            sign={sign}
            observers={observers}
            signers={signers}
            title={title}
            message={message}
            newWidth={newWidth}
            newHeigth={newHeigth}
            onBorder={onBorder}
            signBase64={signBase64}
            isSorted={sortStatus}
          />
        ) : null}
      </Collapse>
    </Right>
  )
}
