import { message as signMessage } from 'antd'
import { isArray, isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import {
  addDocument,
  addSign,
  fixedSignatory,
  signatory,
} from '../../../../../utils/signContract'
import useToken from '../../../../../utils/useToken'
import { MyButton } from '../../../../UI/ButtonSign/ButtonSign'

export default function OthersAndMe(props) {
  const {
    // Only Kavak
    redirectURL,
    dateExpiry,
    // General information
    totalPages,
    file,
    fileBase64,
    changePDF,
    templateData,
    user,
    sign,
    signPositions,
    signsLegalRepresentative,
    geolocation,
    ip,
    clientId,
    roles,
    isAuthenticated,
    newWidth,
    newHeigth,
    onBorder,
    signBase64,
    uuidTemplate,
  } = props

  const { t } = useTranslation()
  const getToken = useToken()
  const history = useHistory()

  const [lengthSignerEndorse, setLengthSignerEndorse] = useState(0)
  const [wait, setWait] = useState(false)

  // Comprobamos que firmante a selecionado endoso
  useEffect(() => {
    if (signPositions) {
      const findUser = signPositions.map((item) =>
        item?.user?.isEndorse === true &&
        item?.user?.isLegalRepresentative === false
          ? item?.user?.name
          : null
      )

      let result = findUser.filter(
        (item, index) => findUser.indexOf(item) === index
      )

      setLengthSignerEndorse(result.length - 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signPositions])

  const handleComprove = () => {
    let dinamicName = ''
    let dinamicEmail = ''
    let internalS = []
    let internalSP = []

    for (let signer of signPositions) {
      dinamicName = document.getElementById(`${signer.user.temporalID} `).value
      dinamicEmail = document.getElementById(
        `${signer.user.temporalID}  `
      ).value
      if (dinamicName === '' || dinamicEmail === '') {
        signMessage.warning(`${t('completeDataForm')}`)
        internalS = []
        break
      } else {
        let endorseData = !isEmpty(signer?.user?.endorseText)
          ? {
              email: dinamicEmail,
              endorseText: signer?.user?.endorseText || '',
              endorsePosition: signer?.user?.endorsePosition || {},
            }
          : { email: dinamicEmail }

        //Add signPositions
        internalSP.push({
          coordinates: {
            x: signer.coordinates.x,
            y: signer.coordinates.y,
          },
          page: signer.page,
          user: endorseData,
          zoom: signer.zoom,
        })

        let already = false
        for (let iS of internalS) {
          if (iS.email === dinamicEmail) {
            already = true
            break
          }
        }
        if (!already)
          internalS.push({
            biometric: signer.user.biometric,
            email: dinamicEmail,
            name: dinamicName,
            uuid: signer.user.uuid || '',
            sdk: signer.user.sdk,
            video: signer?.user?.video,
            videoText: signer?.user?.videoText,
            isLegalRepresentative: signer.user.isLegalRepresentative || false,
            isEndorse: signer.user.isEndorse || false,
            isProofAddress:signer?.user?.isProofAddress || false
          })
      }
    }
    const result = {
      signPositions: internalSP,
      signers: internalS,
    }
    return result
  }

  //Handle create contract
  const handleCreateContract = async () => {
    setWait(true)
    let signerEmails = []
    const result = handleComprove()

    //Verifica la cantidad de firmantes que tenga la posicion de endoso
    const getSignerEndorse = signPositions.map((item) =>
      item?.user?.endorsePosition ? item?.user?.name : null
    )

    let positionsSignerEndorse = getSignerEndorse.filter(
      (item, index) => getSignerEndorse.indexOf(item) === index
    )

    //Si tiene el permiso de endoso y la cantidad de check seleccionados no es suficiente, nos indica que hagamos eso
    if (
      isAuthenticated &&
      roles?.includes('EndorseUser') &&
      lengthSignerEndorse === 0 &&
      clientId === 'F0LDMiWc3mOlQjgtCLWIsA32U9og84UO'
    ) {
      setWait(false)
      signMessage.warning(`${t('checkEndosoValidation')}`)
      return
    }

    // Comprueba si un firmante tiene seleccionado el endoso y se mantiene la misma cantidad asumimos que no se movieron las cajas de firma y deberia hacerlo
    if (lengthSignerEndorse > positionsSignerEndorse.length - 1) {
      signMessage.warning(`${t('setEndorseSignBox')}`)
    } else {
      if (result.signers.length !== 0) {
        //Se valida si un firmante repite el correo del representante legal
        result?.signers?.map((signer) =>
          signer?.email === signsLegalRepresentative[0]?.user?.email &&
          signer?.isLegalRepresentative === false
            ? signerEmails.push({
                email: signer?.email,
                name: signer?.name,
              })
            : null
        )

        if (signerEmails.length > 0) {
          signMessage.warning(
            signerEmails.map(
              (repeatEmail) =>
                `${t('theSigner')} ${repeatEmail?.name} ${t(
                  'noRepeatEmailLegalRepresentative'
                )}`
            )
          )
        } else {
          const token = await getToken({
            audience: 'veridocid',
          })

          const data = {
            id: `${user.name.replace(/ /g, '')}_Template_OthersAndMe`,
            country: templateData.country,
            isMasive: templateData.isMasive,
            isMesa: templateData.isMesa,
            geo: templateData.geo,
            language: templateData.language,
            signType: 'ELECTRONIC_SIGNATURE',
            document: changePDF
              ? fileBase64.replace('data:application/pdf;base64,', '')
              : fileBase64,
            documentName: changePDF
              ? file.name.replace('.docx', '.pdf').replace('.doc', '.pdf')
              : templateData.document.name
                  .replace('.docx', '.pdf')
                  .replace('.doc', '.pdf'),
            create_name: user.name,
            create_email: user.email,
            create_ip: ip,
            create_ubication: geolocation,
            signSize: {
              width: Number(newWidth),
              height: Number(newHeigth),
            },
            hasBorder: onBorder,
            logoSign: signBase64,
            fromTemplate: uuidTemplate || '',
            redirect_url:
              clientId === 'ztZAFimBGaroCldIumuKs7t51ttZJAAD' ||
              clientId === 'F0LDMiWc3mOlQjgtCLWIsA32U9og84UO' ||
              clientId === 'E3WLUM3lTPxsJYRC1Wnv3TwOCQut4PR0' ||
              clientId === 'ZbYCTQwYQr2shSYw8M0CFpBG6fZkc0E6'
                ? redirectURL
                : '',
            expiresIn:
              clientId === 'ztZAFimBGaroCldIumuKs7t51ttZJAAD' ? dateExpiry : 10, //Solo para Kavak compras
          }

          const resAddDocument = await addDocument(token, data)

          if (resAddDocument) {
            if (isArray(resAddDocument?.error?.message)) {
              signMessage.error(`${resAddDocument.error?.message[0]}`)
            } else {
              signMessage.error(`${resAddDocument.error?.message}`)
            }

            if (resAddDocument.status === 200) {
              signMessage.success(`${t('addDocumentSuccess')}`)

              await executeFixedSignatory(
                token,
                resAddDocument.documentID,
                resAddDocument.id,
                resAddDocument.uuid,
                result
              )
            }

            if (resAddDocument.status !== 200 && !resAddDocument.error) {
              signMessage.warning(`${resAddDocument.message}`)
            }
          } else signMessage.error(`${t('errorAddDocument')}`)
        }
      }
    }

    setWait(false)
  }

  const executeFixedSignatory = async (token, documentID, id, uuid, result) => {
    let newA = []
    let internalSignPositions = []
    //Evita que haya firmas en hojas inexistentes
    for (let signPosition of result.signPositions)
      if (signPosition.page <= totalPages)
        internalSignPositions.push(signPosition)

    //Independientemente del zoom las posiciones de la caja no cambiaran
    //En este punto se convierten las coordenadas a escala 1/1 y se remueve el atributo zoom
    for (let signPosition of internalSignPositions) {
      newA.push({
        coordinates: {
          x: signPosition.coordinates.x / signPosition.zoom,
          y: signPosition.coordinates.y / signPosition.zoom,
        },
        page: signPosition.page,
        user: signPosition.user,
      })
    }

    let legalR = []

    if (signsLegalRepresentative.length > 0) {
      legalR = {
        user: {
          email: signsLegalRepresentative[0].user.email,
          name: signsLegalRepresentative[0].user.name,
        },
        signInfo: [],
      }

      // Add positions
      for (const signLegalRepresentativePositions of internalSignPositions) {
        if (
          signLegalRepresentativePositions?.user?.email ===
          signsLegalRepresentative[0]?.user?.email
        ) {
          legalR.signInfo.push({
            coordinates: {
              x: signLegalRepresentativePositions.coordinates.x,
              y: signLegalRepresentativePositions.coordinates.y,
            },
            page: signLegalRepresentativePositions.page,
          })
        }
      }
    }

    //Permite que si solo es un firmante, el uuid asignado sea el de la verifcacion
    if (window.localStorage.getItem('uuidVerification') && result.signers[0]) {
      let uuidVerification = window.localStorage.getItem('uuidVerification')
      result.signers[0].uuid = uuidVerification
    }

    const onlyParticipants = result?.signers?.filter(
      (signer) => signer?.email !== signsLegalRepresentative[0]?.user?.email
    )

    // Only get observers data
    let arrayObservers = []
    templateData?.observers.length > 0 &&
      templateData?.observers.map((data) =>
        arrayObservers.push({ name: data?.name, email: data?.email })
      )

    const data = {
      id,
      uuid,
      documentID,
      staticSignPositions: newA,
      participants: onlyParticipants,
      observers: arrayObservers,
      ...(!isEmpty(legalR) && { signsLegalRepresentative: legalR }),
    }

    const resFixedSignatory = await fixedSignatory(token, data)

    if (resFixedSignatory) {
      if (resFixedSignatory.error) {
        if (isArray(resFixedSignatory?.error?.message)) {
          signMessage.error(`${resFixedSignatory.error?.message[0]}`)
        } else {
          signMessage.error(`${resFixedSignatory.error?.message}`)
        }
        return
      }

      if (resFixedSignatory.status === 200) {
        signMessage.success(`${t('fixedSignatorySuccess')}`)

        let participantId = ''
        for (let participant of resFixedSignatory.participants) {
          if (participant.email === user.email) {
            participantId = participant.participantId
            break
          }
        }

        await executeAddSignature(
          documentID,
          participantId,
          id,
          resFixedSignatory.uuid,
          token,
          result
        )
      }

      if (resFixedSignatory.status !== 200 && !resFixedSignatory.error) {
        signMessage.warning(`${resFixedSignatory.message}`)
      }
    } else signMessage.error(`${t('errorAddSignatories')}`)
  }

  const executeAddSignature = async (
    documentID,
    participantId,
    id,
    uuid,
    token,
    result
  ) => {
    let leftMargin = 22
    let newA = []

    let internalSignPositions = []
    //Evita que haya firmas en hojas inexistentes
    for (let signPosition of result.signPositions)
      if (signPosition.page <= totalPages)
        internalSignPositions.push(signPosition)

    for (let signPosition of internalSignPositions) {
      let coordinateX = uuidTemplate
        ? signPosition.coordinates.x + 10
        : signPosition.coordinates.x + leftMargin
      let userSigner = !isEmpty(signPosition?.user?.endorseText)
        ? {
            email: user.email,
            endorseText: signPosition?.user?.endorseText || '',
            endorsePosition: signPosition?.user?.endorsePosition || {},
          }
        : { email: user.email }

      if (signPosition.user.email === user.email)
        newA.push({
          user: userSigner,
          coordinates: {
            x: coordinateX,
            y: signPosition.coordinates.y + 19,
          },
          page: signPosition.page,
        })
    }

    const data = {
      id,
      uuid,
      documentID,
      participantId,
      staticSignPositions: newA,
      imageB64: sign.signature,
      ubicacion: geolocation,
      ip,
    }

    const resAddSign = await addSign(token, data)

    if (resAddSign) {
      if (resAddSign.error) {
        if (isArray(resAddSign?.error?.message))
          signMessage.error(`${resAddSign.error?.message[0]}`)
        else signMessage.error(`${resAddSign.error?.message}`)
        return
      }

      if (resAddSign.status === 200) {
        signMessage.success(`${t('signedDocumentSuccess')}`)

        executeSignatory(documentID, id, uuid, token, result)
      }

      if (resAddSign.status !== 200 && !resAddSign.error)
        signMessage.warning(`${resAddSign.message}`)
    } else signMessage.error(`${t('errorAddSign')}`)
  }

  const executeSignatory = async (documentID, id, uuid, token, result) => {
    // Only get observers data
    let arrayObservers = []
    templateData?.observers.length > 0 &&
      templateData?.observers.map((data) =>
        arrayObservers.push({ name: data?.name, email: data?.email })
      )

    let newA = []
    for (let participant of result.signers) {
      if (participant.email !== user.email) newA.push(participant)
    }

    const data = {
      id,
      uuid,
      documentID,
      message: templateData.message,
      title: templateData.title,
      signatory: newA,
      sharedWith: arrayObservers,
    }
    const resSignatory = await signatory(token, data)

    if (resSignatory) {
      if (resSignatory?.error) {
        if (isArray(resSignatory?.error?.message)) {
          signMessage.error(`${resSignatory.error?.message[0]}`)
        } else {
          signMessage.error(`${resSignatory.error?.message}`)
        }
        return
      }

      if (resSignatory.status === 200) {
        signMessage.success(`${t('sendEmails')}`)

        history.push(`/resultado-contrato/${documentID}/${uuid}/${id}`)
      }

      if (resSignatory.status !== 200 && !resSignatory.error)
        signMessage.warning(`${resSignatory.message}`)
    } else signMessage.error(`${t('erroSignatory')}`)
  }

  return (
    <>
      <MyButton
        type="button"
        disabled={wait}
        onClick={handleCreateContract}
        loading={wait}
      >
        Continuar
      </MyButton>
    </>
  )
}
