import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'

export const Container = styled(Grid)({
  width: '100%',
  height: '292px',
  position: 'relative',
  zIndex: '10',
  userSelect: 'none',
})
