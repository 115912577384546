import { API_URL } from '../const'
import { backOff } from 'exponential-backoff'
const getOcrResults = async ({ ocrId, token }) => {
  const retryStatus = [500, 400, 401]
  const retryUntil = ['succeeded']
  const response = await fetch(`${API_URL}/ocr/results/${ocrId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  if (retryStatus.includes(response.status)) {
    throw new Error(`retryStatus ${response.status}`)
  }

  if (response.ok) {
    const results = await response.json()

    if (!retryUntil.includes(results.status)) {
      throw new Error(`retryUntil ${results}`)
    }

    return results
  } else {
    const error = await response.text()
    throw new Error(error)
  }
}

export const checkOcrAddress = async ({ ocrId, token }) => {
  try {
    const results = await backOff(() => getOcrResults({ ocrId, token }), {
      delayFirstAttempt: true,
      jitter: 'full',
      maxDelay: 3000,
      numOfAttempts: 20,
      startingDelay: 1000,
    })
    return results
  } catch (error) {
    console.error(error)
  }
}
