import React, { useState, useEffect, Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { format } from 'date-fns'
// Utils
import useToken from '../../utils/useToken'
import useSWR from 'swr'
import moment from 'moment'
import { find } from 'lodash'
// Components
import { Col, Row } from 'reactstrap'
import { Table, Grid, Pagination, Loader, Dimmer } from 'semantic-ui-react'
import CopyIdPopup from '../CopyIdPopup'

// Assets, Fixtures, Shared
import { labels } from '../../fixtures/panelExperts.fixture'
import face from '../../assets/icons/face.svg'
import { filterCategoryCheck } from '../../shared/filters'
import { API_DASHBOARD_URL } from '../../const'
import Filter from './Filter/Filter'
import { useTranslation } from 'react-i18next'
import { MdQuestionMark } from 'react-icons/md'

const ShowVerifications = ({ type, ...props }) => {
  const [selectedType, setSelectedType] = useState(type)
  const [activePage, setActivePage] = useState(1)
  const [register, setRegister] = useState(null)
  const limit = useState(10)[0]
  const history = useHistory()
  const getToken = useToken()
  const { t } = useTranslation()
  const [clients, setClients] = useState([])

  // Filters
  const [startDate, setStartDate] = useState(undefined)
  const [endDate, setEndDate] = useState(undefined)
  const [identifier, setIdentifier] = useState('')
  const [filters, setFilters] = useState([])
  const [client, setClient] = useState('')
  const [id, setId] = useState('')

  const changeIdentifier = (event) => setIdentifier(event?.target?.value)
  const changeId = (event) => setId(event?.target?.value)
  const changeClient = (event) => setClient(event)
  const changeStartDate = (event) => setStartDate(moment(event))
  const changeEndDate = (event) => setEndDate(moment(event))

  // Get Data
  const callToApiDashboard = async (url) => {
    const token = await getToken({
      audience: 'veridocid-dashboard',
    })

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })

    if (response.ok) {
      const responseJson = await response.json()
      return responseJson
    } else {
      return null
    }
  }

  const { data } = useSWR(
    `${API_DASHBOARD_URL}/experts/reports/${selectedType}?limit=${limit}&page=${activePage}&filters=${JSON.stringify(
      filters
    )}`,
    callToApiDashboard,
    { refreshInterval: 5000 }
  )

  useSWR(
    register?.hasNextPage
      ? `${API_DASHBOARD_URL}/experts/reports/${selectedType}?limit=${limit}&page=${
          activePage + 1
        }&filters=${JSON.stringify(filters)}`
      : null,
    callToApiDashboard
  )

  useEffect(() => {
    if (data) {
      setRegister(data)
    }

    setSelectedType(type)
  }, [data, type])

  // Render information
  const renderVerifications = (verification, index) => {
    const { uuid, id, status, createdAt, clientId, identifier } = verification
    const identificator = props.isSecurityScanner ? identifier : uuid
    let fullName

    if (!verification?.data?.documentData) {
      const failedName =
        verification?.data?.status === 'Checked' ? 'Sin Datos' : 'Verificando'
      fullName = verification?.data?.fullName ?? failedName
    } else {
      const name = find(verification?.data?.documentData, {
        type: 'Given Names',
        source: 'VISUAL',
      })?.value
      const fatherSurname = find(verification?.data?.documentData, {
        type: 'FatherSurname',
      })?.value
      const motherSurname = find(verification?.data?.documentData, {
        type: 'MotherSurname',
      })?.value

      fullName = find(verification?.data?.documentData, {
        type: 'Surname And Given Names',
        source: 'VISUAL',
      })?.value?.replace(/[\^]/g, ' ')

      if (fatherSurname && motherSurname && name) {
        fullName = `${fatherSurname} ${motherSurname} ${name}`
      } else {
        if (!fullName) {
          const documentHolderFullName = find(
            verification?.data?.documentData,
            {
              type: 'DocumentHolderFullName',
            }
          )?.value
          const surname =
            find(verification?.data?.documentData, {
              type: 'Surname',
            })?.value ?? null
          const name =
            find(verification?.data?.documentData, { type: 'Name' })?.value
              ?.value ?? null

          const fallbackName =
            name && surname ? `${surname} ${name}` : 'Sin Datos'

          fullName = documentHolderFullName || fallbackName
        }
      }
    }

    const isPositive =
      verification?.data && verification?.data?.globalResult === 'Ok'
    const isNegative = verification?.data ? !isPositive : false
    const isFacialVerification = filterCategoryCheck(
      verification?.data?.documentVerifications,
      'Biometrics'
    )

    const handleClick = () => {
      history.push(`/verificaciones/${selectedType}/${identificator}`)
    }

    const onSelectHandleClick = () => {
      history.push(`/seleccion/${selectedType}/${identificator}`)
    }

    return (
      <Table.Row key={index}>
        <Table.Cell
          positive={isPositive}
          negative={isNegative}
          warning={!verification.data}
          verticalAlign="middle"
          textAlign="center"
        >
          {format(new Date(createdAt), 'dd/MM/yyyy')}{' '}
          {format(new Date(createdAt), 'HH:mm:ss')}
        </Table.Cell>
        <Table.Cell
          positive={isPositive}
          negative={isNegative}
          warning={!verification.data}
          verticalAlign="middle"
          textAlign="center"
        >
          <CopyIdPopup text={identificator} copy={identificator}></CopyIdPopup>
        </Table.Cell>
        <Table.Cell
          positive={isPositive}
          negative={isNegative}
          warning={!verification.data}
          verticalAlign="middle"
          textAlign="center"
          width="two"
        >
          <CopyIdPopup text={id} copy={id}></CopyIdPopup>
        </Table.Cell>
        <Table.Cell
          positive={isPositive}
          negative={isNegative}
          warning={!verification.data}
          verticalAlign="middle"
          textAlign="center"
        >
          {clientId?.name || verification?.data?.userName}
        </Table.Cell>
        <Table.Cell
          positive={isPositive}
          negative={isNegative}
          warning={!verification.data}
          verticalAlign="middle"
          textAlign="center"
        >
          {fullName}
        </Table.Cell>
        <Table.Cell
          positive={isPositive}
          negative={isNegative}
          warning={!verification.data}
          verticalAlign="middle"
          textAlign="center"
        >
          {!verification
            ? 'Verificando'
            : verification?.data?.documentType ?? 'Sin datos'}
        </Table.Cell>

        <Table.Cell
          positive={isPositive}
          negative={isNegative}
          warning={!verification.data}
          verticalAlign="middle"
          textAlign="center"
        >
          {!verification?.globalResult ? (
            <MdQuestionMark size={20} color="orange" />
          ) : isFacialVerification ? (
            <img
              src={face}
              alt="face"
              style={{ width: '2rem', height: '1.5rem' }}
            />
          ) : verification?.globalResult === 'Ok' ? (
            <i className="ui green large check icon"></i>
          ) : (
            <i className="ui red large cancel icon"></i>
          )}
        </Table.Cell>
        <Table.Cell
          positive={isPositive}
          negative={isNegative}
          warning={!verification.data}
          verticalAlign="middle"
          textAlign="center"
        >
          {status === 'ManualIdentification' ? (
            <button
              className="ui mini button"
              style={{ background: '#26285a', color: '#fff' }}
              onClick={onSelectHandleClick}
            >
              {labels.identify}
            </button>
          ) : (
            <button
              className="ui mini button"
              style={{ background: '#26285a', color: '#fff' }}
              onClick={handleClick}
            >
              {labels.check}
            </button>
          )}
        </Table.Cell>
      </Table.Row>
    )
  }

  const handlePaginationChange = (e, { activePage }) => {
    setActivePage(activePage)
  }

  //   Search
  const onSearch = () => {
    let searchData = [
      {
        identifier: '',
        id: '',
        client: '',
        startDate: '',
        endDate: '',
      },
    ]
    searchData.forEach((data) => {
      if (identifier !== '') data.identifier = identifier
      if (id !== '') data.id = id
      if (client !== '') data.client = client
      if (startDate !== '' || startDate !== undefined)
        data.startDate = startDate
      if (endDate !== '' || endDate !== undefined) data.endDate = endDate
    })
    setFilters(searchData)
  }

  // Clear
  const onClear = () => {
    setId('')
    setClient('')
    setIdentifier('')
    setStartDate(undefined)
    setEndDate(undefined)
    setFilters([])
  }

  //------------ Get clients
  useEffect(() => {
    const getClients = async () => {
      const token = await getToken({
        audience: 'veridocid-dashboard',
      })

      const response = await fetch(`${API_DASHBOARD_URL}/experts/clients`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })

      if (response.ok) {
        const responseJson = await response.json()
        setClients(responseJson)
      }
    }

    if (data) getClients()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Fragment>
      <Filter
        t={t}
        identifier={identifier}
        changeIdentifier={changeIdentifier}
        id={id}
        changeId={changeId}
        client={client}
        changeClient={changeClient}
        startDate={startDate}
        changeStartDate={changeStartDate}
        endDate={endDate}
        changeEndDate={changeEndDate}
        onSearch={onSearch}
        onClear={onClear}
        clients={clients}
      />
      <Row className="grid ui equal width">
        <Col>
          <Dimmer.Dimmable dimmed={!data}>
            <Dimmer active={!data} inverted>
              <Loader>Cargando</Loader>
            </Dimmer>

            <Table
              inverted
              selectable
              fixed
              compact
              style={{ background: '#26285a', color: '#ffffff' }}
            >
              <Table.Header>
                <Table.Row verticalAlign="middle">
                  <Table.HeaderCell width={2} textAlign="center">
                    {labels.date}
                  </Table.HeaderCell>
                  <Table.HeaderCell width={3} textAlign="center">
                    {labels.identificator}
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2} textAlign="center">
                    {labels.idClient}
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2} textAlign="center">
                    {labels.client}
                  </Table.HeaderCell>
                  <Table.HeaderCell width={3} textAlign="center">
                    {labels.name}
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2} textAlign="center">
                    {labels.typeDocument}
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} textAlign="center">
                    {labels.globalR}
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2} textAlign="center">
                    {labels.show}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {register?.docs?.map(renderVerifications)}
              </Table.Body>
            </Table>
          </Dimmer.Dimmable>
        </Col>
      </Row>
      <br />
      <br />
      <Grid verticalAlign="middle">
        <Grid.Column width={12}>
          <Pagination
            activePage={activePage}
            onPageChange={handlePaginationChange}
            totalPages={register?.totalPages ?? 0}
          />
        </Grid.Column>
        <Grid.Column width={4} textAlign="right">
          {data ? (
            <>
              Mostrando hasta {data?.limit} de {data?.totalDocs}
            </>
          ) : null}
        </Grid.Column>
      </Grid>
    </Fragment>
  )
}

export default ShowVerifications
