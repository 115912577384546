import React from 'react'
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
} from '@mui/material'
import {
  VscArrowDown,
  VscArrowUp,
  VscCloudDownload,
  VscDesktopDownload,
} from 'react-icons/vsc'
import { Tag, Tooltip } from 'antd'
import { Button } from 'semantic-ui-react'

const TableResponsive = (props) => {
  const {
    register,
    selected,
    t,
    format,
    history,
    selectCreatedAtA,
    selectCreatedAtD,
    downloadContract,
    downloadContractNom,
  } = props
  return (
    <>
      <TableContainer>
        <Table aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell
                className="head_table_custom"
                onClick={
                  selected.createdAtD
                    ? selectCreatedAtA
                    : selected.createdAtA
                    ? selectCreatedAtD
                    : selectCreatedAtD
                }
              >
                {t('date')}{' '}
                {selected.createdAtD ? (
                  <VscArrowDown size={13} strokeWidth={0.8} cursor="pointer" />
                ) : selected.createdAtA ? (
                  <VscArrowUp size={13} strokeWidth={0.8} cursor="pointer" />
                ) : null}
              </TableCell>
              <TableCell className="head_table_custom">
                {t('documentHeaderTable')}
              </TableCell>
              <TableCell className="head_table_custom">{t('status')}</TableCell>
              <TableCell className="head_table_custom">
                {t('actions')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {register.docs.map((document, index) => (
              <TableRow key={index}>
                <TableCell
                  className="body_cell_table_custom"
                  onClick={
                    document?.participants &&
                    document?.participants.length !== 0
                      ? () => history.push(`/resultado-contrato/${document?.documentID}/${document?.uuid}/${document?.id}`)
                      : () => history.push(`/editar-contrato/${document?.documentID}/${document?.uuid}/${document?.id}`)
                  }
                >
                  {document?.createdAt ? (
                    <>
                      {format(new Date(document?.createdAt), 'dd/MM/yyyy')}{' '}
                      {format(new Date(document?.createdAt), 'HH:mm:ss')}
                    </>
                  ) : (
                    <p>-</p>
                  )}
                </TableCell>
                <TableCell
                  className="body_cell_table_custom"
                  onClick={
                    document?.participants &&
                    document?.participants.length !== 0
                      ? () => history.push(`/resultado-contrato/${document?.documentID}/${document?.uuid}/${document?.id}`)
                      : () => history.push(`/editar-contrato/${document?.documentID}/${document?.uuid}/${document?.id}`)
                  }
                >
                  {document?.documentName}
                </TableCell>
                <TableCell
                  className="body_cell_table_custom"
                  onClick={
                    document?.participants &&
                    document?.participants.length !== 0
                      ? () => history.push(`/resultado-contrato/${document?.documentID}/${document?.uuid}/${document?.id}`)
                      : () => history.push(`/editar-contrato/${document?.documentID}/${document?.uuid}/${document?.id}`)
                  }
                >
                  {document?.status === 'valid' ? (
                    <Tag color="orange">Pendiente</Tag>
                  ) : document?.status === 'signed' ? (
                    <Tag color="green">Firmado</Tag>
                  ) : document?.status === 'approved' ? (
                    <Tag color="lime">Aprobado</Tag>
                  ) : document?.status === 'pending_approval' ? (
                    <Tag color="purple">Aprobación pendiente</Tag>
                  ) : (
                    <Tag color="red">Cancelado</Tag>
                  )}
                </TableCell>
                <TableCell className="body_cell_table_custom">
                  {/* Download contract */}
                  <Tooltip title="Descargar documento">
                    <Button
                      disabled={
                        document?.participants.length === 0 ||
                        document?.status === 'valid' ||
                        document?.status === 'disabled' ||
                        document?.status === 'pending_approval' ||
                        document?.status === 'rejected' ||
                        document?.status === 'approved'
                          ? true
                          : false
                      }
                      type="button"
                      style={{
                        color: '#2d2f6d',
                        border: 'none',
                        outline: 'none',
                      }}
                      className="download_contract"
                      onClick={() =>
                        downloadContract(document?.documentID, document?.uuid)
                      }
                    >
                      <VscCloudDownload
                        size={20}
                        color={'#26285a'}
                        strokeWidth={0.1}
                      />
                    </Button>
                  </Tooltip>

                  {/* Download nom */}
                  <Tooltip title="Descargar la nom">
                    <Button
                      disabled={
                        document?.participants.length === 0 ||
                        document?.status === 'valid' ||
                        document?.status === 'disabled' ||
                        document?.status === 'pending_approval' ||
                        document?.status === 'rejected' ||
                        document?.status === 'approved'
                          ? true
                          : false
                      }
                      type="button"
                      style={{
                        color: '#2d2f6d',
                        border: 'none',
                        outline: 'none',
                      }}
                      className="download_contract"
                      onClick={() =>
                        downloadContractNom(
                          document?.documentID,
                          document?.uuid
                        )
                      }
                    >
                      <VscDesktopDownload
                        size={18}
                        color={'#26285a'}
                        strokeWidth={0.1}
                      />
                    </Button>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default TableResponsive
