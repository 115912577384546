import React from 'react'
import { useTranslation } from 'react-i18next'
import DashboardTemplatev2 from '../components/Signature/Templates/DashboardTemplate/DashboardTemplatev2'

export default function TemplatesAllDataShow() {
  const { t } = useTranslation()
  return (
    <>
      <h1
        style={{
          display: 'inline',
          color: 'rgb(68, 68, 68)',
          fontSize: 'clamp(1.78em, 1.82em, 1.8em)',
        }}
      >
        {t('templates')}
      </h1>
      <DashboardTemplatev2 showOptions={true} />
    </>
  )
}
