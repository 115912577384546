import { message as signMessage } from 'antd'
import { isArray } from 'lodash'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { createTemplate } from '../../../../../utils/templatesServices'
import useToken from '../../../../../utils/useToken'
import { MyButton } from '../../../../UI/ButtonSign/ButtonSign'

export default function Save(props) {
  const {
    totalPages,
    templateName,
    checked,
    file,
    fileBase64,
    signPositions,
    dataCountry,
    observers,
    title,
    message,
    whoSign,
    isMasive,
    isMesa,
    legalRepresentant,
    newWidth,
    newHeigth,
    onBorder,
    signBase64,
    listTermsAndConditions,
  } = props
  const getToken = useToken()
  const history = useHistory()

  const [wait, setWait] = useState(false)

  const handleComprove = () => {
    let allFine = {
      result: true,
      message: '',
    }

    if (templateName === '')
      allFine = {
        result: false,
        message: 'Ingresa el nombre de la plantilla',
      }

    if ((whoSign === 'me' && observers.length > 0) || whoSign !== 'me') {
      if (title === '' || message === '')
        allFine = {
          result: false,
          message: 'Ingresa el titulo y/o mensaje del correo',
        }
    }

    if (signPositions.length === 0)
      allFine = {
        result: false,
        message: 'Agrega al menos una firma para continuar',
      }

    if (isMasive) {
      let fine = false
      let otherSigners = 0 //ayuda a saber si existe al menos una firma de otro firmante, no es una solucion 100% efectiva
      for (let signPosition of signPositions) {
        if (legalRepresentant.email === signPosition.email) fine = true
        else otherSigners += 1
      }

      if (!fine)
        allFine = {
          result: false,
          message:
            'Agrega al menos una firma del representante legal para continuar',
        }

      if (otherSigners === 0)
        allFine = {
          result: false,
          message: 'Agrega las firmas de los participantes para continuar',
        }
    }

    return allFine
  }

  const handleSave = async () => {
    setWait(true)

    const allFine = handleComprove()

    if (allFine.result) {
      const token = await getToken({ audience: 'veridocid' })

      let internalSignPositions = []
      let signsLegalRepresentative = []
      //Evita que haya firmas en hojas inexistentes y Remueve las firmas del representante legal
      for (let signPosition of signPositions) {
        if (
          signPosition.page <= totalPages &&
          signPosition.email !== legalRepresentant.email
        )
          internalSignPositions.push(signPosition)
        if (
          signPosition.page <= totalPages &&
          signPosition.email === legalRepresentant.email
        )
          signsLegalRepresentative.push(signPosition)
      }

      const data = {
        id: `Template_${templateName}`.replaceAll(' ', ''),
        isMasive,
        isMesa,
        document: {
          name: file.name,
          base64: fileBase64.replace('data:application/pdf;base64,', ''),
          signs: internalSignPositions,
          signsLegalRepresentative,
          signSize: {
            width: Number(newWidth),
            height: Number(newHeigth),
          },
          hasBorder: onBorder,
          logoSign: signBase64,
          hasLogo: signBase64 ? true : false,
          withTermsAndConditions:
            listTermsAndConditions && listTermsAndConditions.length > 0
              ? true
              : false,
          listTermsAndConditions,
        },
        name: templateName,
        title,
        country: dataCountry,
        geo: checked,
        language: 'es',
        message,
        observers,
        whoSign,
      }

      const resCreateTemplate = await createTemplate(token, data)

      if (resCreateTemplate) {
        if (resCreateTemplate.error) {
          if (isArray(resCreateTemplate?.error?.message)) {
            signMessage.error(`${resCreateTemplate.error?.message[0]}`)
          } else {
            signMessage.error(`${resCreateTemplate.error?.message}`)
          }
          return
        }

        if (resCreateTemplate.status === 200) {
          signMessage.success(`${resCreateTemplate.message}`)

          history.push(`/contratos/plantillas`)
        }

        if (resCreateTemplate.status !== 200 && !resCreateTemplate.error) {
          signMessage.warning(`${resCreateTemplate.message}`)
        }
      } else
        signMessage.error(
          `No se pudo crear la plantilla. Porfavor, intentalo más tarde`
        )
    } else signMessage.warning(`${allFine.message}`)

    setWait(false)
  }

  return (
    <>
      <MyButton
        type="button"
        disabled={wait}
        onClick={handleSave}
        loading={wait}
      >
        Guardar plantilla
      </MyButton>
    </>
  )
}
