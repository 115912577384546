import useMediaQuery from '@mui/material/useMediaQuery'
import React from 'react'
import { Container, MyModal } from './ModalSign.jsx'
import Signature from './Signature/Signature'
import SignProcessProvider from '../../../../context/SignProcess.provider'

export default function ModalSign(props) {
  const { open, setOpen, setSign, currentDocuments, masive } = props

  const handleClose = () => setOpen(!open)
  const matches = useMediaQuery('(max-width:600px)')

  return (
    <MyModal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container style={{ width: matches ? '94%' : '600px' }}>
        <SignProcessProvider>
          <Signature
            masive={masive}
            setOpen={setOpen}
            setSign={setSign}
            currentDocuments={currentDocuments}
          />
        </SignProcessProvider>
      </Container>
    </MyModal>
  )
}
