import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
  format,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
} from 'date-fns'

const styles = makeStyles({
  container: {
    padding: '20px 20px',
    margin: '15px 0 -5px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: '#f8f9fa',
  },
  select: {
    padding: '5px',
    height: '40px',
    border: '1px solid #d9d9d9',
    outline: 'none',
    borderRadius: '0',
    cursor: 'pointer',
    '&:hover, &:active, &:focus': {
      boxShadow: '0 0 2px 3px rgba(24, 144, 255, 0.2)',
    },
  },
})

export default function FilterByDate(props) {
  const { filters, setFilters, alreadyExist, deleteFilter, setupCollapse } =
    props
  const classes = styles()
  const { t } = useTranslation()

  const case1 = (option) => {
    let xd = filters.length
    if (xd !== 0) {
      let newFilters = deleteFilter(option)
      setFilters(newFilters)
    }
  }

  const case2 = (option) => {
    const aE = alreadyExist(option)
    const st = 'T00:00:00.000-05:00'
    const end = 'T23:59:59.999-05:00'

    if (aE === true) {
      let newFilters = deleteFilter(option)
      let date = new Date().getTime()
      const startFormat = format(date, 'yyyy-MM-dd')
      const startDate = {
        createdAt: { $gte: new Date(`${startFormat}${st}`) },
      }
      const endFormat = format(date, 'yyyy-MM-dd')
      const endDate = {
        createdAt: { $lte: new Date(`${endFormat}${end}`) },
      }
      newFilters = [...newFilters, startDate]
      newFilters = [...newFilters, endDate]
      setFilters(newFilters)
    } else {
      let date = new Date().getTime()
      const startFormat = format(date, 'yyyy-MM-dd')
      const startDate = {
        createdAt: { $gte: new Date(`${startFormat}${st}`) },
      }
      const endFormat = format(date, 'yyyy-MM-dd')
      const endDate = {
        createdAt: { $lte: new Date(`${endFormat}${end}`) },
      }
      let newFilters = filters
      newFilters = [...newFilters, startDate]
      newFilters = [...newFilters, endDate]
      setFilters(newFilters)
    }
  }
  const case3 = (option) => {
    const aE = alreadyExist(option)
    const st = 'T00:00:00.000-05:00'
    const end = 'T23:59:59.999-05:00'

    if (aE === true) {
      let newFilters = deleteFilter(option)
      let date = new Date().getTime()
      let dat = date - 86400000
      const startFormat = format(dat, 'yyyy-MM-dd')
      const startDate = {
        createdAt: { $gte: new Date(`${startFormat}${st}`) },
      }
      const endFormat = format(dat, 'yyyy-MM-dd')
      const endDate = {
        createdAt: { $lte: new Date(`${endFormat}${end}`) },
      }
      newFilters = [...newFilters, startDate]
      newFilters = [...newFilters, endDate]
      setFilters(newFilters)
    } else {
      let date = new Date().getTime()
      let dat = date - 86400000
      const startFormat = format(dat, 'yyyy-MM-dd')
      const startDate = {
        createdAt: { $gte: new Date(`${startFormat}${st}`) },
      }
      const endFormat = format(dat, 'yyyy-MM-dd')
      const endDate = {
        createdAt: { $lte: new Date(`${endFormat}${end}`) },
      }
      let newFilters = filters
      newFilters = [...newFilters, startDate]
      newFilters = [...newFilters, endDate]
      setFilters(newFilters)
    }
  }

  const case4 = (option) => {
    const aE = alreadyExist(option)
    const st = 'T00:00:00.000-05:00'
    const end = 'T23:59:59.999-05:00'

    if (aE === true) {
      let newFilters = deleteFilter(option)
      let date = new Date().getTime()
      const sOW = startOfWeek(date)
      const startFormat = format(sOW, 'yyyy-MM-dd')
      const startDate = {
        createdAt: { $gte: new Date(`${startFormat}${st}`) },
      }
      const eOW = endOfWeek(date)
      const endFormat = format(eOW, 'yyyy-MM-dd')
      const endDate = {
        createdAt: { $lte: new Date(`${endFormat}${end}`) },
      }
      newFilters = [...newFilters, startDate]
      newFilters = [...newFilters, endDate]
      setFilters(newFilters)
    } else {
      let date = new Date().getTime()
      const sOW = startOfWeek(date)
      const startFormat = format(sOW, 'yyyy-MM-dd')
      const startDate = {
        createdAt: { $gte: new Date(`${startFormat}${st}`) },
      }
      const eOW = endOfWeek(date)
      const endFormat = format(eOW, 'yyyy-MM-dd')
      const endDate = {
        createdAt: { $lte: new Date(`${endFormat}${end}`) },
      }
      let newFilters = filters
      newFilters = [...newFilters, startDate]
      newFilters = [...newFilters, endDate]
      setFilters(newFilters)
    }
  }

  const case5 = (option) => {
    const aE = alreadyExist(option)
    const st = 'T00:00:00.000-05:00'
    const end = 'T23:59:59.999-05:00'

    if (aE === true) {
      let newFilters = deleteFilter(option)
      let date = new Date().getTime()
      let sOTW = startOfWeek(date)
      sOTW = sOTW.getTime()
      sOTW = sOTW - 86400000
      const sOLW = startOfWeek(sOTW)
      const startFormat = format(sOLW, 'yyyy-MM-dd')
      const startDate = {
        createdAt: { $gte: new Date(`${startFormat}${st}`) },
      }
      const eOLW = endOfWeek(sOTW)
      const endFormat = format(eOLW, 'yyyy-MM-dd')
      const endDate = {
        createdAt: { $lte: new Date(`${endFormat}${end}`) },
      }
      newFilters = [...newFilters, startDate]
      newFilters = [...newFilters, endDate]
      setFilters(newFilters)
    } else {
      let date = new Date().getTime()
      let sOTW = startOfWeek(date)
      sOTW = sOTW.getTime()
      sOTW = sOTW - 86400000
      const sOLW = startOfWeek(sOTW)
      const startFormat = format(sOLW, 'yyyy-MM-dd')
      const startDate = {
        createdAt: { $gte: new Date(`${startFormat}${st}`) },
      }
      const eOLW = endOfWeek(sOTW)
      const endFormat = format(eOLW, 'yyyy-MM-dd')
      const endDate = {
        createdAt: { $lte: new Date(`${endFormat}${end}`) },
      }
      let newFilters = filters
      newFilters = [...newFilters, startDate]
      newFilters = [...newFilters, endDate]
      setFilters(newFilters)
    }
  }

  const case6 = (option) => {
    const aE = alreadyExist(option)
    const st = 'T00:00:00.000-05:00'
    const end = 'T23:59:59.999-05:00'

    if (aE === true) {
      let newFilters = deleteFilter(option)
      let date = new Date().getTime()
      const sOM = startOfMonth(date)
      const startFormat = format(sOM, 'yyyy-MM-dd')
      const startDate = {
        createdAt: { $gte: new Date(`${startFormat}${st}`) },
      }
      const eOM = endOfMonth(date)
      const endFormat = format(eOM, 'yyyy-MM-dd')
      const endDate = {
        createdAt: { $lte: new Date(`${endFormat}${end}`) },
      }
      newFilters = [...newFilters, startDate]
      newFilters = [...newFilters, endDate]
      setFilters(newFilters)
    } else {
      let date = new Date().getTime()
      const sOM = startOfMonth(date)
      const startFormat = format(sOM, 'yyyy-MM-dd')
      const startDate = {
        createdAt: { $gte: new Date(`${startFormat}${st}`) },
      }
      const eOM = endOfMonth(date)
      const endFormat = format(eOM, 'yyyy-MM-dd')
      const endDate = {
        createdAt: { $lte: new Date(`${endFormat}${end}`) },
      }
      let newFilters = filters
      newFilters = [...newFilters, startDate]
      newFilters = [...newFilters, endDate]
      setFilters(newFilters)
    }
  }

  const case7 = (option) => {
    const aE = alreadyExist(option)
    const st = 'T00:00:00.000-05:00'
    const end = 'T23:59:59.999-05:00'

    if (aE === true) {
      let newFilters = deleteFilter(option)
      let date = new Date().getTime()
      let sOTM = startOfMonth(date)
      sOTM = sOTM.getTime()
      sOTM = sOTM - 86400000
      const sOLM = startOfMonth(sOTM)
      const startFormat = format(sOLM, 'yyyy-MM-dd')
      const startDate = {
        createdAt: { $gte: new Date(`${startFormat}${st}`) },
      }
      const eOLM = endOfMonth(sOTM)
      const endFormat = format(eOLM, 'yyyy-MM-dd')
      const endDate = {
        createdAt: { $lte: new Date(`${endFormat}${end}`) },
      }
      newFilters = [...newFilters, startDate]
      newFilters = [...newFilters, endDate]
      setFilters(newFilters)
    } else {
      let date = new Date().getTime()
      let sOTM = startOfMonth(date)
      sOTM = sOTM.getTime()
      sOTM = sOTM - 86400000
      const sOLM = startOfMonth(sOTM)
      const startFormat = format(sOLM, 'yyyy-MM-dd')
      const startDate = {
        createdAt: { $gte: new Date(`${startFormat}${st}`) },
      }
      const eOLM = endOfMonth(sOTM)
      const endFormat = format(eOLM, 'yyyy-MM-dd')
      const endDate = {
        createdAt: { $lte: new Date(`${endFormat}${end}`) },
      }
      let newFilters = filters
      newFilters = [...newFilters, startDate]
      newFilters = [...newFilters, endDate]
      setFilters(newFilters)
    }
  }

  const case8 = (option) => {
    const aE = alreadyExist(option)
    const st = 'T00:00:00.000-05:00'
    const end = 'T23:59:59.999-05:00'

    if (aE === true) {
      let newFilters = deleteFilter(option)
      let date = new Date().getTime()
      let dat = date - 7776000000
      const startFormat = format(dat, 'yyyy-MM-dd')
      const startDate = {
        createdAt: { $gte: new Date(`${startFormat}${st}`) },
      }
      const endFormat = format(date, 'yyyy-MM-dd')
      const endDate = {
        createdAt: { $lte: new Date(`${endFormat}${end}`) },
      }
      newFilters = [...newFilters, startDate]
      newFilters = [...newFilters, endDate]
      setFilters(newFilters)
    } else {
      let date = new Date().getTime()
      let dat = date - 7776000000
      const startFormat = format(dat, 'yyyy-MM-dd')
      const startDate = {
        createdAt: { $gte: new Date(`${startFormat}${st}`) },
      }
      const endFormat = format(date, 'yyyy-MM-dd')
      const endDate = {
        createdAt: { $lte: new Date(`${endFormat}${end}`) },
      }
      let newFilters = filters
      newFilters = [...newFilters, startDate]
      newFilters = [...newFilters, endDate]
      setFilters(newFilters)
    }
  }
  const case9 = (option) => {
    const aE = alreadyExist(option)
    const st = 'T00:00:00.000-05:00'
    const end = 'T23:59:59.999-05:00'

    if (aE === true) {
      let newFilters = deleteFilter(option)
      let date = new Date().getTime()
      date = date - 7776086400
      let dat = date - 7776000000
      const startFormat = format(dat, 'yyyy-MM-dd')
      const startDate = {
        createdAt: { $gte: new Date(`${startFormat}${st}`) },
      }
      const endFormat = format(date, 'yyyy-MM-dd')
      const endDate = {
        createdAt: { $lte: new Date(`${endFormat}${end}`) },
      }
      newFilters = [...newFilters, startDate]
      newFilters = [...newFilters, endDate]
      setFilters(newFilters)
    } else {
      let date = new Date().getTime()
      date = date - 7776086400
      let dat = date - 7776000000
      const startFormat = format(dat, 'yyyy-MM-dd')
      const startDate = {
        createdAt: { $gte: new Date(`${startFormat}${st}`) },
      }
      const endFormat = format(date, 'yyyy-MM-dd')
      const endDate = {
        createdAt: { $lte: new Date(`${endFormat}${end}`) },
      }
      let newFilters = filters
      newFilters = [...newFilters, startDate]
      newFilters = [...newFilters, endDate]
      setFilters(newFilters)
    }
  }
  const case10 = (option) => {
    const aE = alreadyExist(option)
    const st = 'T00:00:00.000-05:00'
    const end = 'T23:59:59.999-05:00'

    if (aE === true) {
      let newFilters = deleteFilter(option)
      let date = new Date().getTime()
      const sOY = startOfYear(date)
      const startFormat = format(sOY, 'yyyy-MM-dd')
      const startDate = {
        createdAt: { $gte: new Date(`${startFormat}${st}`) },
      }
      const eOY = endOfYear(date)
      const endFormat = format(eOY, 'yyyy-MM-dd')
      const endDate = {
        createdAt: { $lte: new Date(`${endFormat}${end}`) },
      }
      newFilters = [...newFilters, startDate]
      newFilters = [...newFilters, endDate]
      setFilters(newFilters)
    } else {
      let date = new Date().getTime()
      const sOY = startOfYear(date)
      const startFormat = format(sOY, 'yyyy-MM-dd')
      const startDate = {
        createdAt: { $gte: new Date(`${startFormat}${st}`) },
      }
      const eOY = endOfYear(date)
      const endFormat = format(eOY, 'yyyy-MM-dd')
      const endDate = {
        createdAt: { $lte: new Date(`${endFormat}${end}`) },
      }
      let newFilters = filters
      newFilters = [...newFilters, startDate]
      newFilters = [...newFilters, endDate]
      setFilters(newFilters)
    }
  }
  const case11 = (option) => {
    const aE = alreadyExist(option)
    const st = 'T00:00:00.000-05:00'
    const end = 'T23:59:59.999-05:00'

    if (aE === true) {
      let newFilters = deleteFilter(option)
      let date = new Date().getTime()
      let sOTY = startOfYear(date)
      sOTY = sOTY.getTime()
      sOTY = sOTY - 86400000
      const sOLY = startOfYear(sOTY)
      const startFormat = format(sOLY, 'yyyy-MM-dd')
      const startDate = {
        createdAt: { $gte: new Date(`${startFormat}${st}`) },
      }
      const eOLY = endOfYear(sOTY)
      const endFormat = format(eOLY, 'yyyy-MM-dd')
      const endDate = {
        createdAt: { $lte: new Date(`${endFormat}${end}`) },
      }
      newFilters = [...newFilters, startDate]
      newFilters = [...newFilters, endDate]
      setFilters(newFilters)
    } else {
      let date = new Date().getTime()
      let sOTY = startOfYear(date)
      sOTY = sOTY.getTime()
      sOTY = sOTY - 86400000
      const sOLY = startOfYear(sOTY)
      const startFormat = format(sOLY, 'yyyy-MM-dd')
      const startDate = {
        createdAt: { $gte: new Date(`${startFormat}${st}`) },
      }
      const eOLY = endOfYear(sOTY)
      const endFormat = format(eOLY, 'yyyy-MM-dd')
      const endDate = {
        createdAt: { $lte: new Date(`${endFormat}${end}`) },
      }
      let newFilters = filters
      newFilters = [...newFilters, startDate]
      newFilters = [...newFilters, endDate]
      setFilters(newFilters)
    }
  }
  const addFilter = (option, position) => {
    switch (option) {
      case 'createdAt':
        switch (position) {
          case '1':
            case1(option)
            break
          case '2':
            case2(option)
            break
          case '3':
            case3(option)
            break
          case '4':
            case4(option)
            break
          case '5':
            case5(option)
            break
          case '6':
            case6(option)
            break
          case '7':
            case7(option)
            break
          case '8':
            case8(option)
            break
          case '9':
            case9(option)
            break
          case '10':
            case10(option)
            break
          case '11':
            case11(option)
            break
          default:
            case1(option)
            break
        }
        break
      default:
        break
    }
  }

  return (
    <Grid
      container
      className={`${classes.container} view_more_optios_verify ${
        setupCollapse ? 'collapsed' : 'expanded'
      }`}
      aria-expanded={setupCollapse}
    >
      <strong style={{ marginBottom: '8px' }}>{t('show')}</strong>
      <select
        onChange={(e) => addFilter(e.target.name, e.target.value)}
        name="createdAt"
        className={classes.select}
      >
        <option value="1">{t('allRecords')}</option>
        <option value="2">{t('tRecords')}</option>
        <option value="3">{t('yRecords')}</option>
        <option value="4">{t('tWRecords')}</option>
        <option value="5">{t('lWRecords')}</option>
        <option value="6">{t('tMRecords')}</option>
        <option value="7">{t('lMRecords')}</option>
        <option value="8">{t('rTQ')}</option>
        <option value="9">{t('rFPQ')}</option>
        <option value="10">{t('tYRecords')}</option>
        <option value="11">{t('lYRecords')}</option>
      </select>
    </Grid>
  )
}
