import React, { useState } from 'react'
import { find, isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'

// Cmponents and utils
import {
  responseVersion1,
  responseVersion2,
} from '../../../fixtures/dataResponse.fixture'
import BelvoWithDataBank from './BelvoWithDataBank'
import BelvoWithDataFiscal from './BelvoWithDataFiscal'
import { Button, Modal, DatePicker } from 'antd'
import { VscInfo } from 'react-icons/vsc'
import useToken from '../../../utils/useToken'
import { API_URL } from '../../../const/index'
import SendMailBelvo from './SendMailBelvo'
import './../ComprobantesStyles.css'

const BelvoData = ({
  dataClient,
  dataBelvo,
  // Bank
  dataBankBD,
  statusBanckClientBD,
  // Fiscal
  dataFiscalBD,
  statusFiscalClientBD,
}) => {
  const { t } = useTranslation()
  const getToken = useToken()
  const { success, error } = Modal
  const { RangePicker } = DatePicker

  //   Bank data
  const [dataBanckClient, setDataBankClient] = useState({})
  const [loadingButton, setLoadingButton] = useState(false)
  const [showDataBank, setShowDataBank] = useState(false)

  //   Fiscal data
  const [dataFiscalClient, setDataFiscalClient] = useState({})
  const [loadingButtonFiscal, setLoadingButtonFiscal] = useState(false)

  const [showDataFiscal, setShowDataFiscal] = useState(false)

  const [selectDateToConsultFiscalData, setSelectDateToConsultFiscalData] =
    useState({ dateFrom: '', dateTo: '' })
  const [disabledButton, setDisabledButton] = useState(true)

  const [textEmptyResults, setTextEmptyResults] = useState({
    state: false,
    msg: '',
  })

  // Email
  const [loadingButtonResendEmail, setLoadingButtonResendEmail] =
    useState(false)

  // Modal
  const [visibleModalSendEmailBelvo, setVisibleModalEmailBelvo] =
    useState(false)

  // Input data
  const [emailDataBelvo, setEmailDataBelvo] = useState('')
  const [dataBankOrFiscal, setDataBankOrFiscal] = useState('retail')

  // Control of errors
  const [errorEmail, setErrorEmail] = useState({
    visible: false,
    message: '',
  })

  // Api errors
  const [errorAPI, setErrorApi] = useState({
    visible: false,
    content: '',
  })

  //----------  Data client bd
  let documentDataClient = dataClient?.data?.documentData
  let types

  if (Number(dataClient?.dataVersion) >= 2) {
    types = responseVersion2
  } else {
    types = responseVersion1
  }

  //--------- FullName
  const fullNameMrz = find(documentDataClient, {
    type: types.fullName,
  })?.value

  //--------- Nombre
  let name = find(documentDataClient, {
    type: types.name,
  })?.value?.replace(/[\^]/g, ' ')

  //--------- Apellido paterno
  let fatherSurname = find(documentDataClient, {
    type: types.fatherSurname,
  })?.value

  //--------- Apellido materno
  let motherSurname = find(documentDataClient, {
    type: types.motherSurname,
  })?.value

  let fullName =
    find(dataClient?.data?.documentData, {
      type: types.fullName,
      source: types.visual,
    })?.value?.replace(/[\^]/g, ' ') || null

  if (fatherSurname && motherSurname && name && !fullName) {
    fullName = `${fatherSurname} ${motherSurname} ${name}`
  } else if (!fullName && !fullNameMrz) {
    const documentHolderFullName = find(dataClient?.data?.documentData, {
      type: 'DocumentHolderFullName',
    })?.value

    const surname = find(dataClient?.data?.documentData, {
      type: 'Surname',
    })?.value
    const name = find(dataClient?.data?.documentData, {
      type: types.name,
    })?.value

    const fallbackName = name && surname ? `${surname} ${name}` : 'Sin Datos'

    fullName = documentHolderFullName || fallbackName
  } else if (!fullName) {
    fullName = fullNameMrz
  }

  // ------------------------ Get data bank
  const historyDataBank = async () => {
    setLoadingButton(true)
    const token = await getToken({
      audience: 'veridocid',
    })

    const response = await fetch(`${API_URL}/get_bank_data`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: dataClient?.id,
        uuid: dataClient?.uuid,
        link: dataBelvo.link_bank,
      }),
    })

    // console.log(response)
    const responseJSON = await response.json()
    if (response.ok) {
      //   console.log(responseJSON)
      setDataBankClient(responseJSON)
      setShowDataBank(true)
    } else {
      setShowDataBank(false)
      console.log(responseJSON)
    }
    setLoadingButton(false)
  }

  // ------------------------ Get data fiscal

  // Select date
  const changeDateToConsultFiscalData = (dates, dateStrings) => {
    if (dateStrings[0] === '' || dateStrings[1] === '') {
      setDisabledButton(true)
    } else {
      setDisabledButton(false)
      setSelectDateToConsultFiscalData({
        dateFrom: dateStrings[0],
        dateTo: dateStrings[1],
      })
    }
  }

  // API
  const historyDataFiscal = async () => {
    setLoadingButtonFiscal(true)
    const token = await getToken({
      audience: 'veridocid',
    })

    const response = await fetch(`${API_URL}/get_fiscal_data`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: dataClient?.id,
        uuid: dataClient?.uuid,
        // date_from: '2020-01-01',
        // date_to: '2021-02-01',
        date_from: selectDateToConsultFiscalData.dateFrom,
        date_to: selectDateToConsultFiscalData.dateTo,
        link: dataBelvo.link_fiscal,
      }),
    })

    // console.log(response)
    const responseJSON = await response.json()
    if (response.ok) {
      if (isEmpty(responseJSON)) {
        setShowDataFiscal(false)
        setTextEmptyResults({
          state: true,
          msg: 'No se han encontrado datos, una vez realizado la consulta. Puede realizar otra vez la consulta y si persiste el problema solicitar de nuevo los datos',
        })
      } else {
        setDataFiscalClient(responseJSON)
        setShowDataFiscal(true)
        setTextEmptyResults({
          state: false,
          msg: '',
        })
      }
    } else {
      console.log(responseJSON)
      setShowDataFiscal(false)
      setLoadingButtonFiscal(false)
    }
    setLoadingButtonFiscal(false)
  }

  // ------------------------- Modal send mail

  // Modal options
  const showModalEmailBelvo = () => {
    setVisibleModalEmailBelvo(true)
  }

  // Validate email
  const regexValidateEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  //   Close modal
  const handleCancel = () => {
    setVisibleModalEmailBelvo(false)
  }

  // Function to send mail to start the process
  const sendEmailToWidgetBelvoClient = async () => {
    setLoadingButtonResendEmail(true)
    try {
      const token = await getToken({
        audience: 'veridocid',
      })

      const response = await fetch(`${API_URL}/send-email-belvo`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: dataClient?.id,
          uuid: dataClient?.uuid,
          email: emailDataBelvo,
          type: dataBankOrFiscal,
        }),
      })

      if (response.ok) {
        success({
          centered: true,
          closable: false,
          content: (
            <>
              <h5 style={{ marginBottom: '0', lineHeight: '1.4' }}>
                {t('succesTitleEmail')}
              </h5>
            </>
          ),
          bodyStyle: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          },
          onOk() {
            setVisibleModalEmailBelvo(false)
            setEmailDataBelvo('')
          },
        })
      } else {
        error({
          centered: true,
          closable: false,
          content: (
            <>
              <h5 style={{ marginBottom: '0', lineHeight: '1.4' }}>
                {t('errorTitleEmail')}
              </h5>
            </>
          ),
          bodyStyle: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          },
          onOk() {
            setEmailDataBelvo('')
          },
        })
        setLoadingButtonResendEmail(false)
      }
      setLoadingButtonResendEmail(false)
    } catch (error) {
      setLoadingButtonResendEmail(false)
      setErrorApi({
        visible: true,
        content: `${t('messageErrorAPINip')}`,
      })
    }
  }

  //   Send mail
  const handleSend = () => {
    const validateEmail = regexValidateEmail.test(
      String(emailDataBelvo).toLowerCase()
    )

    if (emailDataBelvo.length === 0) {
      setErrorEmail({
        visible: true,
        message: `${t('messageErrorEmptyInputEmail')}`,
      })
    } else if (!validateEmail) {
      setErrorEmail({
        visible: true,
        message: `${t('invalidEmailFormat')}`,
      })
    } else {
      setErrorEmail({
        visible: false,
        message: `${t('messageErrorEmptyInputEmail')}`,
      })

      // Call api send_mail_nip
      sendEmailToWidgetBelvoClient()
    }
  }

  // Data input email
  const changeDataEmailBelvo = (event) => {
    const clientEmail = event?.target?.value
    if (clientEmail.length === 0) {
      setErrorEmail({
        visible: true,
        message: `${t('invalidEmailFormat')}`,
      })
    } else {
      setErrorEmail({
        visible: false,
        message: `${t('invalidEmailFormat')}`,
      })
    }
    setEmailDataBelvo(clientEmail)
  }

  // Data bank or fiscal - radio button
  const handleSelectBankOrFiscal = (e) => {
    setDataBankOrFiscal(e?.target?.value)
  }

  // Close the message - api errors
  const handleDismiss = () => {
    setErrorApi({ visible: false, content: '' })
  }

  return (
    <div className="content_show_data_credit">
      <div className="data_user">
        <p>{fullName}</p>
        {/* -------- Cuando se corriga el problema - de añadir las dos consultas se habilitaria el botón*/}
        <Button
          loading={loadingButtonResendEmail}
          className="btns_actions_primary_not_background"
          onClick={() => showModalEmailBelvo()}
        >
          Solicitar Datos
        </Button>
      </div>
      <div className="config_data_user" style={{ marginBottom: '1em' }}>
        <p>
          {fullName} ha terminado el proceso de comprobar sus datos{' '}
          {dataBelvo.link_bank ? `bancarios` : `fiscales`} de forma
          satisfactoria. Ahora puedes consultar su historial de comprobantes de
          pago
        </p>
      </div>

      {/* Bank */}
      {dataBelvo.link_bank ? (
        <>
          <div
            style={{
              fontWeight: '600',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '2em',
              flexWrap: 'wrap',
            }}
          >
            <div>
              <h4 style={{ color: 'var(--primary-color)', margin: '0' }}>
                Datos Bancarios
              </h4>
              <span style={{ color: 'var(--gray-color)', fontWeight: '400' }}>
                Visualice los datos de las cuentas bancarias
              </span>
            </div>
            {/* {!statusBanckClientBD ? (
          <Button
            loading={loadingButton}
            className="btns_actions_primary_not_background"
            // onClick={() => historyDataBank()}
            onClick={() => historyDataFiscal()}
          >
            Ver Datos
          </Button>
        ) : null} */}
            <Button
              loading={loadingButton}
              className="btns_actions_primary_not_background"
              // onClick={() => historyDataBank()}
              onClick={() => historyDataBank()}
            >
              Ver Datos
            </Button>
          </div>

          {/* Consulting data bank*/}
          {statusBanckClientBD || showDataBank ? (
            <BelvoWithDataBank showData={dataBankBD || dataBanckClient} />
          ) : (
            <div className="consulting_data_bank">
              <div
                style={{
                  backgroundColor: '#fafafa',
                  padding: '1.8em 0 0.9em 0',
                  textAlign: 'center',
                  margin: '1.5em 0',
                }}
              >
                <VscInfo size={20} color={'red'} />
                <p
                  style={{
                    padding: '1em 0 1.5em 0',
                    width: '300px',
                    margin: '0 auto',
                    color: 'var(--gray-color)',
                    lineHeight: '23px',
                  }}
                >
                  Por el momento no se han encontrado datos para mostrar, debe
                  darle al botón "Ver datos" para poder consultarlos
                </p>
              </div>
            </div>
          )}
        </>
      ) : null}

      {/* Fiscal */}
      {dataBelvo.link_fiscal ? (
        <>
          <div
            style={{
              fontWeight: '600',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '2em',
              flexWrap: 'wrap',
            }}
          >
            <div>
              <h4 style={{ color: 'var(--primary-color)', margin: '0' }}>
                Datos Fiscales
              </h4>
              {/* <span style={{ color: 'var(--gray-color)', fontWeight: '400' }}>
                Visualice los datos de comprobantes de ingresos y egresos
              </span> */}
            </div>
            {/* {!statusFiscalClientBD ? (
          <Button
            loading={loadingButtonFiscal}
            className="btns_actions_primary_not_background"
            onClick={() => historyDataFiscal()}
          >
            Ver Datos
          </Button>
        ) : null} */}
            <div
              className="content_actions"
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
              }}
            >
              <RangePicker
                onChange={changeDateToConsultFiscalData}
                // value={selectDateToConsultFiscalData}
                format={'YYYY-MM-DD'}
              />

              <Button
                loading={loadingButtonFiscal}
                className="btns_actions_primary_not_background"
                style={{ marginLeft: '1em' }}
                onClick={() => historyDataFiscal()}
                disabled={disabledButton}
              >
                Ver Datos
              </Button>
            </div>
          </div>

          {/* Consulting data fiscal*/}
          {statusFiscalClientBD || showDataFiscal ? (
            <BelvoWithDataFiscal
              showDataFiscal={dataFiscalBD || dataFiscalClient}
            />
          ) : (
            <div className="consulting_data_bank">
              <div
                style={{
                  backgroundColor: '#fafafa',
                  padding: '1.8em 0 0.9em 0',
                  textAlign: 'center',
                  margin: '1.5em 0',
                }}
              >
                <VscInfo size={20} color={'red'} />
                <p
                  style={{
                    padding: '1em 0 1.5em 0',
                    width: '300px',
                    margin: '0 auto',
                    color: 'var(--gray-color)',
                    lineHeight: '23px',
                  }}
                >
                  {!textEmptyResults.state
                    ? `Por el momento no se han encontrado datos para mostrar, debe
                  darle al botón "Ver datos" para poder consultarlos`
                    : textEmptyResults.msg}
                </p>
              </div>
            </div>
          )}
        </>
      ) : null}

      {/*----------- Modal send email belvo -----------*/}
      <SendMailBelvo
        title_send_mail={'Proceso de envío'}
        visibleModal={visibleModalSendEmailBelvo}
        handleSendMail={handleSend}
        handleCancelSendEmail={handleCancel}
        changeDataEmail={changeDataEmailBelvo}
        emailData={emailDataBelvo}
        stateLoadingSubmit={loadingButtonResendEmail}
        descriptionSendEmail={t('descriptionEmailBelvo')}
        // Radio button fiscal or bank
        changeSelectData={handleSelectBankOrFiscal}
        stateSelectData={dataBankOrFiscal}
        // Errors
        visibleMessageError={errorEmail.visible}
        messageInputError={errorEmail.message}
        classError={errorEmail.visible ? 'error' : ''}
        // Api message error
        visibleMsgErrorApi={errorAPI.visible}
        headerErrorMsg={`${t('email')}`}
        apiErrorMsg={errorAPI.content}
        changeStateVisibleMsgErrorApi={handleDismiss}
      />
      {/*----------- Modal send email belvo -----------*/}
    </div>
  )
}

export default BelvoData
