import React, { useState, useEffect } from 'react'
// Components and styles
import '../../styles/ContractStyles.css'
import { Grid } from '@material-ui/core'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import PDFEditClient from './PDFEditClient'
// Utils
import { useParams, useHistory } from 'react-router'
import useToken from '../../utils/useToken'
import {
  getDocumentBase64Sign,
  getDocumentDataSign,
} from '../../utils/getDataCustomize'
import { useAuth0 } from '../../react-auth0-spa'

const EditContract = () => {
  const getToken = useToken()
  const history = useHistory()
  const { uuid, id, documentID } = useParams()
  const [foundDocumentBase64, setFoundDocumentBase64] = useState(null)
  const [foundDocumentData, setFoundDocumentData] = useState(null)
  const [loadingData, setLoadingData] = useState(false)
  const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />

  // Auth0
  const { user } = useAuth0()

  useEffect(() => {
    const getDocumentBase64 = async () => {
      setLoadingData(true)
      if (uuid) {
        try {
          const token = await getToken({
            audience: 'veridocid-dashboard',
          })
          const base64 = await getDocumentBase64Sign(token, documentID, uuid)

          setFoundDocumentBase64(base64)
          setLoadingData(false)
        } catch (error) {
          console.error('error', error)
          setLoadingData(false)
        }
      }
    }

    getDocumentBase64()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, documentID])

  useEffect(() => {
    const getDocumentDataClient = async () => {
      if (uuid) {
        try {
          const token = await getToken({
            audience: 'veridocid-dashboard',
          })
          const data = await getDocumentDataSign(token, documentID, uuid)
          setFoundDocumentData(data)
        } catch (error) {
          console.error('error', error)
        }
      }
    }

    getDocumentDataClient()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, documentID])

  const returnVerify = () => {
    history.push(`/resultados/${uuid}`)
  }

  const returnContracts = () => history.push(`/contratos`)

  return (
    <Grid container className="main_container_contract_client">
      <div className="content_info_contract">
        {/* Info client */}
        <div className="content_info_contract_part1">
          <div className="client_doc">
            <h1
              className="ui header"
              style={{
                display: 'inline',
                color: 'rgb(68, 68, 68)',
                fontSize: 'clamp(1.7em, 1.75em, 1.8em)',
                marginBottom: '0',
              }}
            >
              {foundDocumentData ? foundDocumentData?.documentName : 'Contrato'}
            </h1>
            <p style={{ marginTop: '1rem', fontWeight: '700' }}>
              ID del cliente:{' '}
              <span
                style={{
                  backgroundColor: '#e5e5e5',
                  color: 'rgb(80, 82, 145)',
                  padding: '0.3rem 0.7rem',
                  borderRadius: '3px',
                }}
              >
                {id}
              </span>
            </p>
          </div>
          <button
            type="button"
            onClick={
              !foundDocumentData?.fromVerification
                ? returnContracts
                : returnVerify
            }
            className="return_verification"
          >
            Regresar
          </button>
        </div>
      </div>

      {/* Contract */}
      <div className="content_contract_client">
        {loadingData ? (
          <div className="content_loading_data_contract">
            <Spin
              indicator={antIcon}
              tip="Cargando..."
              style={{ color: 'var(--secondary-color)' }}
            />
          </div>
        ) : (
          <div className="content_data_contract">
            <PDFEditClient
              fileBase64={
                foundDocumentBase64
                  ? foundDocumentBase64.includes('data:application/pdf;base64,')
                    ? foundDocumentBase64
                    : 'data:application/pdf;base64,' + foundDocumentBase64
                  : null
              }
              idDoc={foundDocumentData ? foundDocumentData?.documentID : null}
              uuid={uuid}
              idClient={id}
              useAuth0={user}
              file={foundDocumentData}
            />
          </div>
        )}
      </div>
    </Grid>
  )
}

export default EditContract
