import { API_URL_V3 } from '../const/index.js'

//addDocument
export const addDocument = async (token, data) => {
  try {
    const url = `${API_URL_V3}/sign`
    // const url = `${API_URL_V3}/sign-add-document`
    const formdata = JSON.stringify(data)

    const params = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: formdata,
    }
    const response = await fetch(url, params)
    const result = await response.json()

    return result
  } catch (err) {
    console.log(err)
    return null
  }
}

//fixedSignatory
export const fixedSignatory = async (token, data) => {
  try {
    const url = `${API_URL_V3}/sign/fixed-signatory`
    // const url = `${API_URL_V3}/sign-fixed-signatory`

    const formdata = JSON.stringify(data)

    const params = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: formdata,
    }
    const response = await fetch(url, params)
    const result = await response.json()

    return result
  } catch (err) {
    console.log(err)
    return null
  }
}

//Add Sign
export const addSign = async (token, data) => {
  try {
    const url = `${API_URL_V3}/add_sign`
    // const url = `${API_URL_V3}/add-sign`

    const formdata = JSON.stringify(data)

    const params = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: formdata,
    }
    const response = await fetch(url, params)
    const result = await response.json()

    return result
  } catch (err) {
    console.log(err)
    return null
  }
}

//Signatory
export const signatory = async (token, data) => {
  try {
    const url = `${API_URL_V3}/sign/signatory`
    // const url = `${API_URL_V3}/sign-signatory`

    const formdata = JSON.stringify(data)

    const params = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: formdata,
    }
    const response = await fetch(url, params)
    const result = await response.json()

    return result
  } catch (err) {
    console.log(err)
    return null
  }
}

//get IP
export const getIP = async () => {
  try {
    const url = `https://geolocation-db.com/json/`

    const params = {
      method: 'GET',
    }

    const response = await fetch(url, params)
    const result = await response.json()

    return result
  } catch (err) {
    console.log(err)
    return null
  }
}

export const getIPOption2 = async () => {
  try {
    const url = `https://api.ipify.org/?format=json`

    const params = {
      method: 'GET',
    }

    const response = await fetch(url, params)
    const result = await response.json()

    return result
  } catch (err) {
    console.log(err)
    return null
  }
}

export const convertDocx = async (token, file) => {
  try {
    let formdata = new FormData()
    formdata.append('doc', file)

    const url = `${API_URL_V3}/docx-to-pdf`

    const params = {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` },
      body: formdata,
    }

    const response = await fetch(url, params)

    if (response.ok) {
      const result = await response.json()
      return result
    } else {
      const error = await response.json()
      return error
    }
  } catch (err) {
    return null
  }
}

//Espacio temporal para revision de contrato y firma masiva
export const aprovedOrRejectPDF = async (token, data) => {
  try {
    const url = `${API_URL_V3}/sign/aprove-pdf`
    // const url = `${API_URL_V3}/approve-pdf`
    const formdata = JSON.stringify(data)

    const params = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: formdata,
    }
    const response = await fetch(url, params)
    const result = await response.json()

    return result
  } catch (err) {
    console.log(err)
    return null
  }
}

export const masiveSignature = async (token, data) => {
  try {
    const url = `${API_URL_V3}/sign/validate-masive-documents`
    // const url = `${API_URL_V3}/validate-masive-documents`
    const formdata = JSON.stringify(data)

    const params = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: formdata,
    }
    const response = await fetch(url, params)
    const result = await response.json()

    return result
  } catch (err) {
    console.log(err)
    return null
  }
}

export const startOrchestratorProcess = async (token, data) => {
  const url = `${API_URL_V3}/sign/multiple-sign-docs`
  // const url = `http://localhost:7071/api/signOrchestrator?clientId=Ej4QpQ33HYwZ2JXYTLCf6c1li3vTLhjm@clients`
  const formdata = JSON.stringify(data)

  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: formdata,
  }
  const response = await fetch(url, params)
  if (response.ok) {
    return response.json()
  }
  return null
}
