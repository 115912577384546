import React, { useState } from 'react'
import { Dropdown } from 'semantic-ui-react'
import i18next from 'i18next'

import languageOptions from '../fixtures/languageOptions'
import { TfiWorld } from 'react-icons/tfi'

const DropdownLanguage = () => {
  const [language, setLanguage] = useState('es')

  const onSelectedLanguageHandler = async (e, { value }) => {
    await i18next.changeLanguage(value)
    setLanguage(value)
  }

  return (
    <Dropdown
      button
      simple
      options={languageOptions}
      icon={<TfiWorld size={15} style={{ margin: '0 -4px 3px 10px' }} color='gray' />}
      onChange={onSelectedLanguageHandler}
      value={language}
    />
  )
}

export default DropdownLanguage
