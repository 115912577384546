import React from 'react'
import {
  Container,
  Logo,
  InternalContainer,
  InputText,
  Time,
} from './Step1Option3.jsx'
import BoxSign from '../../../../../../assets/caja firma.svg'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTranslation } from 'react-i18next'

export default function Step1Option3(props) {
  const { name, setName, today } = props
  const { t } = useTranslation()
  const matches600 = useMediaQuery('(max-width:600px)')
  const matches480 = useMediaQuery('(max-width:480px)')
  const matches420 = useMediaQuery('(max-width:420px)')
  const matches360 = useMediaQuery('(max-width:360px)')

  const handleChange = (e) => {
    const newValue = e.target.value
    if (!newValue) setName('')
    else setName(newValue)
  }

  return (
    <Container style={{ height: matches600 ? '245px' : '292px' }}>
      <Logo src={BoxSign} alt="boxSign" draggable={false} />
      <InternalContainer
        matches360={matches360 ? 'true' : 'false'}
        matches420={matches420 ? 'true' : 'false'}
        matches480={matches480 ? 'true' : 'false'}
        matches600={matches600 ? 'true' : 'false'}
      >
        <InputText
          matches360={matches360 ? 'true' : 'false'}
          matches420={matches420 ? 'true' : 'false'}
          matches480={matches480 ? 'true' : 'false'}
          matches600={matches600 ? 'true' : 'false'}
          onChange={handleChange}
          type="text"
          placeholder={`${t('Full Name')}`}
          value={name}
        />
      </InternalContainer>
      <Time>{today}</Time>
    </Container>
  )
}
