import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import useToken from '../../../../../utils/useToken'
import { aprovedOrRejectPDF } from '../../../../../utils/signContract'
import { BtnAproved } from '../../../../UI/ButtonSign/ButtonSign'
import { message as signMessage } from 'antd'
import { useTranslation } from 'react-i18next'
import { isArray } from 'lodash'

export default function Aprove(props) {
  const { documentID, uuid, id, signPositions, message } = props
  const getToken = useToken()
  const { t } = useTranslation()
  const history = useHistory()

  const [wait, setWait] = useState(false)

  const handleAprove = async () => {
    setWait(true)
    let approvalPositions = []

    for (let signPosition of signPositions)
      approvalPositions.push({
        user: {
          email: signPosition.email,
          name: signPosition.name,
        },
        coordinates: {
          x: signPosition.positionX,
          y: signPosition.positionY,
        },
        page: signPosition.page,
        text: signPosition.message ?? message,
      })

    const data = {
      isApproved: true,
      id,
      documentID,
      uuid,
      approvalPositions,
    }

    const token = await getToken({
      audience: 'veridocid',
    })

    const resAprovePDF = await aprovedOrRejectPDF(token, data)

    if (resAprovePDF) {
      if (resAprovePDF.error) {
        if (isArray(resAprovePDF?.error?.message)) {
          signMessage.error(`${resAprovePDF.error?.message[0]}`)
        } else {
          signMessage.error(`${resAprovePDF.error?.message}`)
        }
        return
      }


      if (resAprovePDF.status === 200) {
        signMessage.success(`${resAprovePDF.message}`)

        history.push(`/contratos/plantillas/mesa`)
      }

      if (resAprovePDF.status !== 200 && !resAprovePDF.error) {
        signMessage.warning(`${resAprovePDF.message}`)
      }
    } else signMessage.error(`${t('messageErrorData')}`)

    setWait(false)
  }

  return (
    <>
      <BtnAproved
        type="button"
        onClick={handleAprove}
        disabled={wait}
        loading={wait}
      >
        Aprobar
      </BtnAproved>
    </>
  )
}
