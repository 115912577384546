import { Collapse, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { VscInfo } from 'react-icons/vsc'
import OnlyMeSign from './ConfirmSign/OnlyMeSign'
import Others from './ConfirmSign/Others'
import OthersAndMe from './ConfirmSign/OthersAndMe'
import Geolocation from './Geolocation/Geolocation'
import Sign from './ShowSign/showSign'
import Signers from './Signers/Signers'
import Signs from './Signs/Signs'
import { MyButton, Right, RightTitle } from './UseTemplateStyle'
import VideoVerification from './VideoVerification/VideoVerification'

export default function UseTemplate(props) {
  const {
    t,
    totalPages,
    file,
    fileBase64,
    changePDF,
    templateData,
    user,
    isAuthenticated,
    roles,
    clientID,
    sign,
    signPositions,
    signsLegalRepresentative,
    whoSign,
    geolocation,
    setGeolocation,
    ip,
    setIP,
    setOpen,
    //pageSize,
    zoom,
    setSignPositions,
    dd_dragstart,
    boxSign,
    handleEndorseText,
    handleClickEndorseBox,
    newWidth,
    newHeigth,
    onBorder,
    signBase64,
    uuidTemplate,
    handleVideoVerificationText,
  } = props

  const { Panel } = Collapse
  const [findUser, setFindUser] = useState('')
  const [findUserVideoVerification, setFindUserVideoVerification] = useState('')
  const [dateExpiry, setDateExpiry] = useState(5)
  const [redirectURL, setRedirectURL] = useState('https://www.kavak.com/mx')

  const handleOpen = () => setOpen(true)

  // Data bank or fiscal - radio button
  const onChangeDateExpiry = (e) => {
    const selectDay = e?.target?.value
    setDateExpiry(selectDay)
  }

  // Add the redirect url
  const onChangeRedirectURL = (e) => {
    const url = e?.target?.value
    setRedirectURL(url)
  }

  useEffect(() => {
    //Permite mapear los nombres y eliminar los duplicados
    const findUser = signPositions.map((item) =>
      item?.user?.isEndorse === true ? item?.user?.name : null
    )
    let result = findUser.filter(
      (item, index) => findUser.indexOf(item) === index
    )
    setFindUser(result)

    //Permite mapear los nombres y eliminar los duplicados
    const findVideoVerification = signPositions.map((item) =>
      item?.user?.video === true ? item?.user?.name : null
    )
    let resultVideoVerification = findVideoVerification.filter(
      (item, index) => findVideoVerification.indexOf(item) === index
    )
    setFindUserVideoVerification(resultVideoVerification)
  }, [signPositions])

  return (
    <Right item xs={4}>
      <RightTitle>Plantilla</RightTitle>
      <Collapse
        defaultActiveKey={['a', 'b', 'c', 'd']}
        style={{
          '& .antCollapseItem': {
            backgroundColor: 'rgb(238, 238, 238)',
          },
        }}
      >
        <Panel
          header="Datos de los firmantes"
          key="a"
          style={{
            backgroundColor: ' rgba(120,120,120,0.1)',
            color: ' rgba(0,0,0,.87)',
          }}
        >
          <Signers />
        </Panel>
        {clientID?.clientId === 'YVNNc03FL9G8Zt58yJxYgqq0VqMXKRfm' ||
        clientID?.clientId === 'ZcO0euZ9ekObdfCXa1XW4VvqLze4Mi8R' ||
        clientID?.clientId === 'PCjGAWQk80Oz9PCwQKHdjfrbQfVFwIwa' ||
        clientID?.clientId === 'TaDlnYwJD3CLV6cnLOmUqobPdkFBqR49' ||
        clientID?.clientId === 'CdS5CULl5bNGY4EyNXLWXCeaAjdnhDbF' ||
        clientID?.clientId === 'gkOhlOFKrxmcowZawGThfeeIAQwDsxff' ||
        clientID?.clientId === 'iWu9ttlsyzggGEu0WxMz7aIz1CpbgbJS' ? (
          <Panel
            header="Verificación por video"
            key="c"
            style={{
              backgroundColor: ' rgba(120,120,120,0.1)',
              color: ' rgba(0,0,0,.87)',
            }}
          >
            <p style={{ color: 'var(--gray-color)' }}>
              En este proceso de verificación debe agregar una frase corta para
              que el firmante la pueda replicar en un video
            </p>
            {findUserVideoVerification
              ? findUserVideoVerification.map((item, index) =>
                  item !== null ? (
                    <VideoVerification
                      key={index}
                      signers={item}
                      signPositions={signPositions}
                      handleVideoVerificationText={handleVideoVerificationText}
                    />
                  ) : null
                )
              : null}
          </Panel>
        ) : null}

        <Panel
          header="Firma"
          key="d"
          style={{
            backgroundColor: ' rgba(120,120,120,0.1)',
            color: ' rgba(0,0,0,.87)',
          }}
        >
          <Sign dd_dragstart={dd_dragstart} whoSign={whoSign} />
        </Panel>

        {/* Any client */}
        {clientID?.clientId !== 'F0LDMiWc3mOlQjgtCLWIsA32U9og84UO' ? (
          <Panel
            header="Endosar Firma"
            key="b"
            style={{
              backgroundColor: ' rgba(120,120,120,0.1)',
              color: ' rgba(0,0,0,.87)',
            }}
          >
            <p style={{ color: 'var(--gray-color)' }}>
              Seleccione que firmante(s) va a generar el endoso, coloque un
              texto apropiado y arraste su firma al documento
            </p>
            {findUser
              ? findUser.map((item, index) =>
                  item !== null ? (
                    <Signs
                      key={index}
                      zoom={zoom}
                      sign={sign}
                      signers={item}
                      signPositions={signPositions}
                      setSignPositions={setSignPositions}
                      boxSign={boxSign}
                      dd_dragstart={dd_dragstart}
                      handleEndorseText={handleEndorseText}
                      handleClickEndorseBox={handleClickEndorseBox}
                    />
                  ) : null
                )
              : null}
          </Panel>
        ) : null}

        {/* Kavak */}
        {isAuthenticated &&
        roles?.includes('EndorseUser') &&
        clientID?.clientId === 'F0LDMiWc3mOlQjgtCLWIsA32U9og84UO' ? (
          <Panel
            header="Endosar Firma"
            key="b"
            style={{
              backgroundColor: ' rgba(120,120,120,0.1)',
              color: ' rgba(0,0,0,.87)',
            }}
          >
            <p style={{ color: 'var(--gray-color)' }}>
              Seleccione que firmante(s) va a generar el endoso, coloque un
              texto apropiado y arraste su firma al documento
            </p>
            {findUser
              ? findUser.map((item, index) =>
                  item !== null ? (
                    <Signs
                      key={index}
                      zoom={zoom}
                      sign={sign}
                      signers={item}
                      signPositions={signPositions}
                      setSignPositions={setSignPositions}
                      boxSign={boxSign}
                      dd_dragstart={dd_dragstart}
                      handleEndorseText={handleEndorseText}
                      handleClickEndorseBox={handleClickEndorseBox}
                    />
                  ) : null
                )
              : null}
          </Panel>
        ) : null}

        <Panel
          header="Aceptar y continuar"
          key="e"
          style={{
            backgroundColor: ' rgba(120,120,120,0.1)',
            color: ' rgba(0,0,0,.87)',
          }}
        >
          <Geolocation
            geolocation={geolocation}
            setGeolocation={setGeolocation}
            setIP={setIP}
          />
          {/* Caducidad solo para Kavak Compras */}
          {clientID?.clientId === 'ztZAFimBGaroCldIumuKs7t51ttZJAAD' ? (
            <div className="config_form__content_input">
              <label htmlFor="select_expiration_days" className="lbl_input">
                <Tooltip title={`${t('setExpiryDays')}`}>
                  <VscInfo
                    color="rgb(140, 140, 140)"
                    size={16}
                    style={{ cursor: 'pointer', marginRight: '10px' }}
                  />
                </Tooltip>
                Caducidad del contrato
              </label>
              <select
                name="select_expiration_days"
                className="select_country"
                onChange={onChangeDateExpiry}
                value={dateExpiry}
              >
                <option value={5}>5 días</option>
                <option value={10}>10 días</option>
                <option value={20}>20 días</option>
              </select>
            </div>
          ) : null}

          {/* Redireccionamiento solo las cuentas de Kavak */}
          {clientID?.clientId === 'ztZAFimBGaroCldIumuKs7t51ttZJAAD' ||
          clientID?.clientId === 'F0LDMiWc3mOlQjgtCLWIsA32U9og84UO' ||
          clientID?.clientId === 'E3WLUM3lTPxsJYRC1Wnv3TwOCQut4PR0' ||
          clientID?.clientId === 'ZbYCTQwYQr2shSYw8M0CFpBG6fZkc0E6' ? (
            <div
              className="content-input_add"
              style={{ marginTop: '1rem', marginBottom: '1rem' }}
            >
              <label htmlFor="redirect_url_sign" className="lbl_input">
                <Tooltip title={`${t('redirectedSign')}`}>
                  <VscInfo
                    color="rgb(140, 140, 140)"
                    size={16}
                    style={{ cursor: 'pointer', marginRight: '10px' }}
                  />
                </Tooltip>
                Redireccionamiento
              </label>
              <input
                type="url"
                name="redirect_url_sign"
                pattern="https://.*"
                value={redirectURL}
                onChange={onChangeRedirectURL}
                placeholder="url"
                style={{ color: 'var(--black-color)' }}
              />
            </div>
          ) : null}

          {/* Signers */}
          {whoSign !== 'others' ? (
            <>
              <MyButton onClick={handleOpen}>
                {sign ? 'Cambiar firma' : 'Firmar'}
              </MyButton>
            </>
          ) : null}
          {whoSign === 'me' && sign && fileBase64 !== 'false' ? (
            <OnlyMeSign
              // Only kavak
              redirectURL={redirectURL}
              dateExpiry={dateExpiry}
              // General data
              totalPages={totalPages}
              file={file}
              fileBase64={fileBase64}
              changePDF={changePDF}
              templateData={templateData}
              user={user}
              signPositions={signPositions}
              signsLegalRepresentative={signsLegalRepresentative}
              geolocation={geolocation}
              ip={ip}
              sign={sign}
              // Validations Kavak
              clientId={clientID?.clientId}
              roles={roles}
              isAuthenticated={isAuthenticated}
              newWidth={newWidth}
              newHeigth={newHeigth}
              onBorder={onBorder}
              signBase64={signBase64}
              uuidTemplate={uuidTemplate}
            />
          ) : null}
          {whoSign === 'others_me' && sign && fileBase64 !== 'false' ? (
            <OthersAndMe
              // Only kavak
              redirectURL={redirectURL}
              dateExpiry={dateExpiry}
              // General data
              totalPages={totalPages}
              file={file}
              fileBase64={fileBase64}
              changePDF={changePDF}
              templateData={templateData}
              user={user}
              sign={sign}
              signPositions={signPositions}
              signsLegalRepresentative={signsLegalRepresentative}
              geolocation={geolocation}
              ip={ip}
              // Validations Kavak
              clientId={clientID?.clientId}
              roles={roles}
              isAuthenticated={isAuthenticated}
              newWidth={newWidth}
              newHeigth={newHeigth}
              onBorder={onBorder}
              signBase64={signBase64}
              uuidTemplate={uuidTemplate}
            />
          ) : null}
          {whoSign === 'others' && fileBase64 !== 'false' ? (
            <Others
              // Only kavak
              redirectURL={redirectURL}
              dateExpiry={dateExpiry}
              // General data
              totalPages={totalPages}
              file={file}
              fileBase64={fileBase64}
              changePDF={changePDF}
              templateData={templateData}
              user={user}
              signPositions={signPositions}
              signsLegalRepresentative={signsLegalRepresentative}
              geolocation={geolocation}
              ip={ip}
              // Validations Kavak
              clientId={clientID?.clientId}
              roles={roles}
              isAuthenticated={isAuthenticated}
              newWidth={newWidth}
              newHeigth={newHeigth}
              onBorder={onBorder}
              signBase64={signBase64}
              uuidTemplate={uuidTemplate}
            />
          ) : null}
        </Panel>
      </Collapse>
    </Right>
  )
}
