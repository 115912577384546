/**
 * Labels for modal experts
 */
export const labels = {
  error: 'Error',
  errorTitle: '¡Oh, no! Algo salió mal.',
  errorDescription: 'Por favor, vuelve a intentarlo más tarde.',
  checked: 'Liberada',
  checkedTitle: 'La verificación ha sido liberada correctamente.',
  checkedDescription:
    'Para checar la verificación ve a la sección de registros.',
  manualIdentification: 'Enviada a identificación manual',
  manualIdentificationTitle:
    'La verificación ha sido enviada a identificación correctamente.',
  manualIdentificationDescription:
    'Para revisar la verificación ve al panel de expertos.',
  ok: 'Ok',
  red: 'red',
  green: 'green',
  checkmark: 'checkmark',
  warning: 'warning',
}
