import { Image, message } from 'antd'
import download from 'downloadjs'
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Header, Placeholder, Segment } from 'semantic-ui-react'
import { useAuth0 } from '../../react-auth0-spa'
import CarouselVerification from '../UI/CarouselVerification/CarouselVerification'
// import CardBiometrics from './ConsultationServices/CardBiometrics/CardBiometrics'
import CardBiometrics from '../../components/ConsultationServices/CardBiometrics/CardBiometrics'
// import setImages from '../../utils/setImages'
// import { getImagesIdPath } from '../../utils/getImagesPath'
import { Button } from 'semantic-ui-react'
import { filterByCategory, filterCategoryCheck } from '../../shared/filters'

import {
  API_DASHBOARD_URL,
  NMP_FINANCIERA_CLIENTS,
  NMP_CLIENTS,
} from '../../const'
import useToken from '../../utils/useToken'
import '../ConsultationServices/ConsultationServices.css'

const PlaceHolder = () => (
  <Placeholder style={{ height: 150, width: 150 }}>
    <Placeholder.Image />
  </Placeholder>
)

const VerificationImage = ({ image }) => {
  if (image === null) {
    return <PlaceHolder></PlaceHolder>
  } else if (image) {
    return <Image src={image} width={'fit-content'} />
  } else {
    return null
  }
}

const DocumentType = ({ documentType }) => {
  if (documentType === null) {
    return (
      <Placeholder>
        <Placeholder.Header>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
      </Placeholder>
    )
  } else if (documentType) {
    return <>{documentType}</>
  } else {
    return null
  }
}

const DocumentID = ({
  front,
  back,
  documentType,
  isSecurityScanner,
  frontInfrared,
  backInfrared,
  frontUltraviolet,
  backUltraviolet,
  data,
  facial,
  portrait,
  isChangeStatusAllowed,
}) => {
  const { t } = useTranslation()
  const getToken = useToken()
  const { user, isAuthenticated } = useAuth0()
  const [clientID, setClientID] = useState({})
  const [loadingVideo, setLoadingVideo] = useState(false)
  const isGlobalResultChanged = data?.isGlobalResultChanged
  const isBiometrics = filterCategoryCheck(
    data?.data?.documentVerifications,
    'Biometrics'
  )
  const biometrics = filterByCategory(
    data?.data?.documentVerifications,
    'Biometrics'
  )
  const fraudFaceList = filterByCategory(
    data?.data?.documentVerifications,
    'FraudFaceList'
  )

  useEffect(() => {
    if (isAuthenticated) {
      setClientID(user?.['https://vdid.sumamexico.com/app_metadata'] ?? {})
    }
  }, [isAuthenticated, user])

  const downloadVideo = async (uuid) => {
    try {
      setLoadingVideo(true)
      const token = await getToken({
        audience: 'veridocid-dashboard',
      })
      const response = await fetch(
        `${API_DASHBOARD_URL}/files/video-sign/${uuid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      if (response.ok) {
        const json = await response.text()
        if (json) {
          download('data:video/webm;base64,' + json, `${uuid}`, 'video/webm')
          message.success('Se descargo con exito el video')
          setLoadingVideo(false)
        } else {
          setLoadingVideo(false)
          message.error(
            'No se pudo descargar al video. Debido a su inexistencia o formato inválido'
          )
        }
      }
    } catch (error) {
      setLoadingVideo(false)
      message.error(
        'Hemos tenido un error inesperado. Por favor, intentalo más tarde'
      )
    }
  }

  const downloadImages = async (front, back) => {
    if (front) download(front, `front-${data?.uuid}`, 'image/png')
    if (back) download(back, `back-${data?.uuid}`, 'image/png')
  }

  return (
    <Fragment>
      {isSecurityScanner ? (
        <div className="ui container">
          <h2>
            <DocumentType documentType={documentType}></DocumentType>
          </h2>
          <br />
          <CarouselVerification
            white={front}
            infrared={frontInfrared}
            ultraviolet={frontUltraviolet}
          />

          <br />
          <CarouselVerification
            white={back}
            infrared={backInfrared}
            ultraviolet={backUltraviolet}
          />
        </div>
      ) : (
        <div className="ui container">
          <h2>
            <DocumentType documentType={documentType}></DocumentType>
          </h2>
          <VerificationImage image={front}></VerificationImage>
          <br></br>
          {back ? <VerificationImage image={back}></VerificationImage> : null}
          {clientID.clientId &&
          NMP_FINANCIERA_CLIENTS.includes(clientID.clientId) ? (
            <Button
              disabled={!front ? true : false}
              className="btns_actions_with_background"
              style={{ marginTop: '1rem' }}
              onClick={() => downloadImages(front, back)}
            >
              {t('download')}
            </Button>
          ) : null}

          <br />
          {isBiometrics ? (
            <>
              <Header attached="top" className="AdditionalVerifications-header">
                <h2
                  style={{
                    fontSize: 'clamp(0.86rem, 0.95rem, 1.1rem)',
                    textAlign: 'center',
                    width: '100%',
                  }}
                >
                  {t('facialBiometrics')}
                </h2>
              </Header>
              <Segment attached style={{ padding: '0' }}>
                <CardBiometrics
                  dataBiometrics={biometrics}
                  facial={facial}
                  portrait={portrait}
                  data={data?.data}
                  fraudFaceList={fraudFaceList}
                  isGlobalResultChanged={isGlobalResultChanged}
                  isChangeStatusAllowed={isChangeStatusAllowed}
                  uuid={data?.uuid}
                />
              </Segment>
            </>
          ) : null}
          {NMP_CLIENTS.includes(clientID.clientId) &&
          data?.data?.videoLiveness ? (
            <>
              <Header attached="top" className="AdditionalVerifications-header">
                <h2
                  style={{
                    fontSize: 'clamp(0.86rem, 0.95rem, 1.1rem)',
                    textAlign: 'center',
                    width: '100%',
                  }}
                >
                  {t('videoCheck')}
                </h2>
              </Header>
              <Segment attached style={{ padding: '0' }}>
                <div>
                  {data?.data?.videoLiveness?.videoResult && (
                    <h3
                      style={{
                        padding: '10px 1rem 6px',
                        fontSize: 'clamp(0.93rem, 0.94rem, 0.95rem)',
                      }}
                    >
                      {data?.data?.videoLiveness?.videoResult}{' '}
                    </h3>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      flexDirection: 'column',
                      alignItems: 'center',
                      margin: '-2px 0px 20px 0px',
                    }}
                  >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div style={{ margin: '0 10px' }}>
                        <strong style={{ paddingTop: '5px' }}>
                          {t('videoScore')}
                        </strong>
                        <p
                          style={{
                            fontSize: '1.5rem',
                            margin: '0',
                            padding: '0.3rem 0 0.8rem',
                          }}
                        >
                          {data?.data?.videoLiveness?.videoScore || '0'}
                        </p>
                      </div>
                      {data?.data?.videoLiveness?.lifeTestScore && (
                        <div style={{ margin: '0 10px' }}>
                          <strong style={{ paddingTop: '5px' }}>
                            {t('videoScoreBiometric')}
                          </strong>
                          <p
                            style={{
                              fontSize: '1.5rem',
                              margin: '0',
                              padding: '0.3rem 0 0.8rem',
                            }}
                          >
                            {data?.data?.videoLiveness?.lifeTestScore || '0'}
                          </p>
                        </div>
                      )}
                    </div>
                    <Button
                      loading={loadingVideo}
                      className="btns_actions_with_background"
                      onClick={() => downloadVideo(data?.uuid)}
                    >
                      {t('download')}
                    </Button>
                  </div>
                </div>
              </Segment>
            </>
          ) : null}
        </div>
      )}
    </Fragment>
  )
}

export default DocumentID
