import { API_DASHBOARD_URL, API_URL_V3 } from '../const/index.js'

export const desactiveTemplates = async (token, uuid, isActive) => {
  try {
    const url = `${API_URL_V3}/sign_template/${uuid}`
    // const url = `${API_URL_V3}/sign-template-update/${uuid}`

    const params = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ status: isActive }),
    }
    const response = await fetch(url, params)

    if (response.ok) {
      const responseJson = await response.json()
      return responseJson
    } else {
      const error = await response.json()
      return error
    }
  } catch (error) {
    return null
  }
}

export const createTemplate = async (token, data) => {
  try {
    const url = `${API_URL_V3}/sign_template`
    // const url = `${API_URL_V3}/sign-template-add`
    const formdata = JSON.stringify(data)

    const params = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: formdata,
    }
    const response = await fetch(url, params)

    if (response.ok) {
      const responseJson = await response.json()
      return responseJson
    } else {
      const error = await response.json()
      return error
    }
  } catch (err) {
    return null
  }
}

export const getTemplate = async (token, templateID) => {
  try {
    const url = `${API_DASHBOARD_URL}/sign/data-template/${templateID}`

    const params = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await fetch(url, params)
    if (response.ok) {
      const result = await response.json()
      return result
    } else {
      const error = await response.json()
      return error
    }
  } catch (err) {
    return null
  }
}

export const getTemplateBase64 = async (token, templateID) => {
  try {
    const url = `${API_DASHBOARD_URL}/sign/template/${templateID}`

    const params = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await fetch(url, params)
    if (response.ok) {
      const result = await response.text()
      return result
    } else {
      const error = await response.json()
      return error
    }
  } catch (error) {
    return null
  }
}
