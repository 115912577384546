import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'

// Components
import BodyVerificationResults from '../components/PanelExperts/VerificationResultsID/BodyVerificationResults'

// Styles
import 'semantic-ui-css/semantic.css'
import '../styles/VerificationResults.css'
import { labels } from '../fixtures/panelExperts.fixture'
import leftArrow from '../assets/icons/arrow-left.svg'

const VerificationResults = () => {
  const history = useHistory()

  const onBackHandler = () => {
    history.goBack()
  }

  return (
    <Fragment>
      <div className="TitleVerifications">
        <h1
          className="ui header"
          style={{
            display: 'inline',
            color: 'rgb(68, 68, 68)',
            fontSize: 'clamp(1.7em, 1.75em, 1.8em)',
            marginBottom: '0',
          }}
        >
          {labels.titleVerificationResults}
        </h1>
        <button className="ButtonVerifications" onClick={onBackHandler}>
          <img src={leftArrow} alt="left" className="LeftArrow" />
          {labels.buttonBack}
        </button>
      </div>
      <BodyVerificationResults />
      <br />
    </Fragment>
  )
}

export default VerificationResults
