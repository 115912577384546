import React from 'react'

export const SignatoriesContract = ({ data, t }) => (
  <div className="content_signatories content_data_panel">
    <h4 className="title_panel_custom">{t('contract_data__signatories')}</h4>
    <div className="description_panel_custom">
      {data?.participants && data?.participants.length !== 0 ? (
        data?.participants.map((signatory, i) => (
          <p key={i}>{signatory.email || '-'}</p>
        ))
      ) : (
        <p>Termina de configurar tu contrato</p>
      )}
    </div>
  </div>
)
