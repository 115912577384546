import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import useToken from '../../../../../utils/useToken'
import { MyButton } from '../../../../UI/ButtonSign/ButtonSign'
import {
  addDocument,
  fixedSignatory,
  signatory,
} from '../../../../../utils/signContract'
import { useTranslation } from 'react-i18next'
import { message as signMessage } from 'antd'
import { isArray } from 'lodash'

export default function Others(props) {
  const {
    totalPages,
    file,
    fileBase64,
    signPositions,
    user,
    dataCountry,
    geolocation,
    checked,
    ip,
    observers,
    signers,
    title,
    message,
    newWidth,
    newHeigth,
    onBorder,
    signBase64,
    isSorted,
  } = props

  const { t } = useTranslation()
  const getToken = useToken()
  const history = useHistory()

  const [wait, setWait] = useState(false)
  //Handle create contract
  const handleCreateContract = async () => {
    setWait(true)

    if (title === '' || message === '') {
      signMessage.warning(`${t('addTitleMessage')}`)
      setWait(false)
      return
    }

    const token = await getToken({
      audience: 'veridocid',
    })

    const data = {
      id: `${user.name.replace(/ /g, '')}_Others`,
      country: dataCountry,
      geo: checked,
      language: 'es',
      signType: 'ELECTRONIC_SIGNATURE',
      document: fileBase64.replace('data:application/pdf;base64,', ''),
      documentName: file.name.replace('.docx', '.pdf').replace('.doc', '.pdf'),
      create_name: user.name,
      create_email: user.email,
      create_ip: ip,
      create_ubication: geolocation,
      signSize: {
        width: Number(newWidth),
        height: Number(newHeigth),
      },
      hasBorder: onBorder,
      logoSign: signBase64,
    }

    const resAddDocument = await addDocument(token, data)

    if (resAddDocument) {
      if (resAddDocument?.error) {
        if (isArray(resAddDocument?.error?.message)) {
          signMessage.error(`${resAddDocument.error?.message[0]}`)
        } else {
          signMessage.error(`${resAddDocument.error?.message}`)
        }
        return
      }

      if (resAddDocument.status === 200) {
        signMessage.success(`${t('addDocumentSuccess')}`)

        await executeFixedSignatory(
          token,
          resAddDocument.documentID,
          resAddDocument.id,
          resAddDocument.uuid
        )
      }

      if (resAddDocument.status !== 200 && !resAddDocument.error) {
        signMessage.warning(`${resAddDocument.message}`)
      }
    } else signMessage.error(`${t('errorAddDocument')}`)

    setWait(false)
  }

  const executeFixedSignatory = async (token, documentID, id, uuid) => {
    let newA = []
    let internalSignPositions = []
    //Evita que haya firmas en hojas inexistentes
    for (let signPosition of signPositions)
      if (signPosition.page <= totalPages)
        internalSignPositions.push(signPosition)

    //Independientemente del zoom las posiciones de la caja no cambiaran
    //En este punto se convierten las coordenadas a escala 1/1 y se remueve el atributo zoom
    for (let signPosition of internalSignPositions)
      newA.push({
        coordinates: {
          x: signPosition.coordinates.x / signPosition.zoom,
          y: signPosition.coordinates.y / signPosition.zoom,
        },
        page: signPosition.page,
        user: signPosition.user,
      })

    const data = {
      id,
      uuid,
      documentID,
      staticSignPositions: newA,
      participants: signers,
      observers,
      isSorted: isSorted,
    }

    const resFixedSignatory = await fixedSignatory(token, data)

    if (resFixedSignatory) {
      if (resFixedSignatory.error) {
        if (isArray(resFixedSignatory?.error?.message)) {
          signMessage.error(`${resFixedSignatory.error?.message[0]}`)
        } else {
          signMessage.error(`${resFixedSignatory.error?.message}`)
        }
        return
      }

      if (resFixedSignatory.status === 200) {
        signMessage.success(`${t('fixedSignatorySuccess')}`)

        await executeSignatory(documentID, id, resFixedSignatory.uuid, token)
      }

      if (resFixedSignatory.status !== 200 && !resFixedSignatory.error) {
        signMessage.warning(`${resFixedSignatory.message}`)
      }
    } else signMessage.error(`${t('errorAddSignatories')}`)
  }

  const executeSignatory = async (documentID, id, uuid, token) => {
    // Only get signers data
    let arraySignatories = []

    signers.length > 0 &&
      signers.map((data) =>
        arraySignatories.push({ name: data?.name, email: data?.email })
      )

    const data = {
      id,
      uuid,
      documentID,
      message,
      title,
      signatory: arraySignatories,
      sharedWith: observers,
    }

    const resSignatory = await signatory(token, data)

    if (resSignatory) {
      if (resSignatory?.error) {
        if (isArray(resSignatory?.error?.message)) {
          signMessage.error(`${resSignatory.error?.message[0]}`)
        } else {
          signMessage.error(`${resSignatory.error?.message}`)
        }
        return
      }

      if (resSignatory.status === 200) {
        signMessage.success(`${t('sendEmails')}`)

        history.push(`/resultado-contrato/${documentID}/${uuid}/${id}`)
      }

      if (resSignatory.status !== 200 && !resSignatory.error)
        signMessage.warning(`${resSignatory.message}`)
    } else signMessage.error(`${t('erroSignatory')}`)
  }

  return (
    <>
      <MyButton
        type="button"
        disabled={wait}
        onClick={handleCreateContract}
        loading={wait}
      >
        Continuar
      </MyButton>
    </>
  )
}
