import FlagSelect from '../components/UI/FlagSelect/FlagSelect'

/**
 * options about documents
 */
export const votingCardOptions = [
  { value: 'VOTING_CARD_C', label: 'Credencial para votar Tipo C' },
  {
    value: 'VOTING_CARD',
    label: 'Credencial para votar Tipo D, E, F, G y H',
  },
]

export const countryCodeOptions = [
  {
    value: '1',
    name: 'USA',
    label: <FlagSelect country="us" countryCode="+1" />,
  },
  {
    value: '52',
    name: 'Mexico',
    label: <FlagSelect country="mx" countryCode="+52" />,
  },
  {
    value: '213',
    name: 'Algeria',
    label: <FlagSelect country="dz" countryCode="+213" />,
  },
  {
    value: '376',
    name: 'Andorra',
    label: <FlagSelect country="ad" countryCode="+376" />,
  },
  {
    value: '244',
    name: 'Angola',
    label: <FlagSelect country="ao" countryCode="+244" />,
  },
  {
    value: '1264',
    name: 'Anguilla',
    label: <FlagSelect country="ai" countryCode="+1264" />,
  },
  {
    value: '1268',
    name: 'Antigua & Barbuda',
    label: <FlagSelect country="ag" countryCode="+1268" />,
  },
  {
    value: '54',
    name: 'Argentina',
    label: <FlagSelect country="ar" countryCode="+54" />,
  },
  {
    value: '374',
    name: 'Armenia',
    label: <FlagSelect country="am" countryCode="+374" />,
  },
  {
    value: '297',
    name: 'Aruba',
    label: <FlagSelect country="aw" countryCode="+297" />,
  },
  {
    value: '61',
    name: 'Australia',
    label: <FlagSelect country="au" countryCode="+61" />,
  },
  {
    value: '43',
    name: 'Austria',
    label: <FlagSelect country="at" countryCode="+43" />,
  },
  {
    value: '994',
    name: 'Azerbaijan',
    label: <FlagSelect country="az" countryCode="+994" />,
  },
  {
    value: '1242',
    name: 'Bahamas',
    label: <FlagSelect country="bs" countryCode="+1242" />,
  },
  {
    value: '973',
    name: 'Bahrain',
    label: <FlagSelect country="bh" countryCode="+973" />,
  },
  {
    value: '880',
    name: 'Bangladesh',
    label: <FlagSelect country="bd" countryCode="+880" />,
  },
  {
    value: '1246',
    name: 'Barbados',
    label: <FlagSelect country="bb" countryCode="+1246" />,
  },
  {
    value: '375',
    name: 'Belarus',
    label: <FlagSelect country="by" countryCode="+375" />,
  },
  {
    value: '32',
    name: 'Belgium',
    label: <FlagSelect country="be" countryCode="+32" />,
  },
  {
    value: '501',
    name: 'Belize',
    label: <FlagSelect country="bz" countryCode="+501" />,
  },
  {
    value: '229',
    name: 'Benin',
    label: <FlagSelect country="bj" countryCode="+229" />,
  },
  {
    value: '1441',
    name: 'Bermuda',
    label: <FlagSelect country="bm" countryCode="+1441" />,
  },
  {
    value: '975',
    name: 'Bhutan',
    label: <FlagSelect country="bt" countryCode="+975" />,
  },
  {
    value: '591',
    name: 'Bolivia',
    label: <FlagSelect country="bo" countryCode="+591" />,
  },
  {
    value: '387',
    name: 'Bosnia Herzegovina',
    label: <FlagSelect country="ba" countryCode="+387" />,
  },
  {
    value: '267',
    name: 'Botswana',
    label: <FlagSelect country="bw" countryCode="+267" />,
  },
  {
    value: '55',
    name: 'Brazil',
    label: <FlagSelect country="br" countryCode="+55" />,
  },
  {
    value: '673',
    name: 'Brunei',
    label: <FlagSelect country="bn" countryCode="+673" />,
  },
  {
    value: '359',
    name: 'Bulgaria',
    label: <FlagSelect country="bg" countryCode="+359" />,
  },
  {
    value: '226',
    name: 'Burkina Faso',
    label: <FlagSelect country="bf" countryCode="+226" />,
  },
  {
    value: '257',
    name: 'Burundi',
    label: <FlagSelect country="bi" countryCode="+257" />,
  },
  {
    value: '855',
    name: 'Cambodia',
    label: <FlagSelect country="kh" countryCode="+855" />,
  },
  {
    value: '237',
    name: 'Cameroon',
    label: <FlagSelect country="cm" countryCode="+237" />,
  },
  {
    value: '1',
    name: 'Canada',
    label: <FlagSelect country="ca" countryCode="+1" />,
  },
  {
    value: '238',
    name: 'Cape Verde Islands',
    label: <FlagSelect country="cv" countryCode="+238" />,
  },
  {
    value: '1345',
    name: 'Cayman Islands',
    label: <FlagSelect country="ky" countryCode="+1345" />,
  },
  {
    value: '236',
    name: 'Central African Republic',
    label: <FlagSelect country="cf" countryCode="+236" />,
  },
  {
    value: '56',
    name: 'Chile',
    label: <FlagSelect country="cl" countryCode="+56" />,
  },
  {
    value: '86',
    name: 'China',
    label: <FlagSelect country="cn" countryCode="+86" />,
  },
  {
    value: '57',
    name: 'Colombia',
    label: <FlagSelect country="co" countryCode="+57" />,
  },
  {
    value: '269',
    name: 'Comoros',
    label: <FlagSelect country="km" countryCode="+269" />,
  },
  {
    value: '242',
    name: 'Congo',
    label: <FlagSelect country="cg" countryCode="+242" />,
  },
  {
    value: '682',
    name: 'Cook Islands',
    label: <FlagSelect country="ck" countryCode="+682" />,
  },
  {
    value: '506',
    name: 'Costa Rica',
    label: <FlagSelect country="cr" countryCode="+506" />,
  },
  {
    value: '385',
    name: 'Croatia',
    label: <FlagSelect country="hr" countryCode="+385" />,
  },
  {
    value: '53',
    name: 'Cuba',
    label: <FlagSelect country="cu" countryCode="+53" />,
  },
  {
    value: '90',
    name: 'Cyprus - North',
    label: <FlagSelect country="cy" countryCode="+90(North)" />,
  },
  {
    value: '357',
    name: 'Cyprus - South',
    label: <FlagSelect country="cy" countryCode="+357(South)" />,
  },
  {
    value: '420',
    name: 'Czech Republic',
    label: <FlagSelect country="cz" countryCode="+420" />,
  },
  {
    value: '45',
    name: 'Denmark',
    label: <FlagSelect country="dk" countryCode="+45" />,
  },
  {
    value: '253',
    name: 'Djibouti',
    label: <FlagSelect country="dj" countryCode="+253" />,
  },
  {
    value: '1809',
    name: 'Dominica',
    label: <FlagSelect country="dm" countryCode="+1809" />,
  },
  {
    value: '1809',
    name: 'Dominican Republic',
    label: <FlagSelect country="do" countryCode="+1809" />,
  },
  {
    value: '593',
    name: 'Ecuador',
    label: <FlagSelect country="ec" countryCode="+593" />,
  },
  {
    value: '20',
    name: 'Egypt',
    label: <FlagSelect country="eg" countryCode="+20" />,
  },
  {
    value: '503',
    name: 'El Salvador',
    label: <FlagSelect country="sv" countryCode="+503" />,
  },
  {
    value: '240',
    name: 'Equatorial Guinea',
    label: <FlagSelect country="gq" countryCode="+240" />,
  },
  {
    value: '291',
    name: 'Eritrea',
    label: <FlagSelect country="er" countryCode="+291" />,
  },
  {
    value: '372',
    name: 'Estonia',
    label: <FlagSelect country="ee" countryCode="+372" />,
  },
  {
    value: '251',
    name: 'Ethiopia',
    label: <FlagSelect country="et" countryCode="+251" />,
  },
  {
    value: '500',
    name: 'Falkland Islands',
    label: <FlagSelect country="fk" countryCode="+500" />,
  },
  {
    value: '298',
    name: 'Faroe Islands',
    label: <FlagSelect country="fo" countryCode="+298" />,
  },
  {
    value: '679',
    name: 'Fiji',
    label: <FlagSelect country="fj" countryCode="+679" />,
  },
  {
    value: '358',
    name: 'Finland',
    label: <FlagSelect country="fi" countryCode="+358" />,
  },
  {
    value: '33',
    name: 'France',
    label: <FlagSelect country="fr" countryCode="+33" />,
  },
  {
    value: '594',
    name: 'French Guiana',
    label: <FlagSelect country="gf" countryCode="+594" />,
  },
  {
    value: '689',
    name: 'French Polynesia',
    label: <FlagSelect country="pf" countryCode="+689" />,
  },
  {
    value: '241',
    name: 'Gabon',
    label: <FlagSelect country="ga" countryCode="+241" />,
  },
  {
    value: '220',
    name: 'Gambia',
    label: <FlagSelect country="gm" countryCode="+220" />,
  },
  {
    value: '7880',
    name: 'Georgia',
    label: <FlagSelect country="ge" countryCode="+7880" />,
  },
  {
    value: '49',
    name: 'Germany',
    label: <FlagSelect country="de" countryCode="+49" />,
  },
  {
    value: '233',
    name: 'Ghana',
    label: <FlagSelect country="gh" countryCode="+233" />,
  },
  {
    value: '350',
    name: 'Gibraltar',
    label: <FlagSelect country="gi" countryCode="+350" />,
  },
  {
    value: '30',
    name: 'Greece',
    label: <FlagSelect country="gr" countryCode="+30" />,
  },
  {
    value: '299',
    name: 'Greenland',
    label: <FlagSelect country="gl" countryCode="+299" />,
  },
  {
    value: '1473',
    name: 'Grenada',
    label: <FlagSelect country="gd" countryCode="+1473" />,
  },
  {
    value: '590',
    name: 'Guadeloupe',
    label: <FlagSelect country="gp" countryCode="+590" />,
  },
  {
    value: '671',
    name: 'Guam',
    label: <FlagSelect country="gu" countryCode="+671" />,
  },
  {
    value: '502',
    name: 'Guatemala',
    label: <FlagSelect country="gt" countryCode="+502" />,
  },
  {
    value: '224',
    name: 'Guinea',
    label: <FlagSelect country="gn" countryCode="+224" />,
  },
  {
    value: '245',
    name: 'Guinea - Bissau',
    label: <FlagSelect country="gw" countryCode="+245" />,
  },
  {
    value: '592',
    name: 'Guyana',
    label: <FlagSelect country="gy" countryCode="+592" />,
  },
  {
    value: '509',
    name: 'Haiti',
    label: <FlagSelect country="ht" countryCode="+509" />,
  },
  {
    value: '504',
    name: 'Honduras',
    label: <FlagSelect country="hn" countryCode="+504" />,
  },
  {
    value: '852',
    name: 'Hong Kong',
    label: <FlagSelect country="hk" countryCode="+852" />,
  },
  {
    value: '36',
    name: 'Hungary',
    label: <FlagSelect country="hu" countryCode="+36" />,
  },
  {
    value: '354',
    name: 'Iceland',
    label: <FlagSelect country="is" countryCode="+354" />,
  },
  {
    value: '91',
    name: 'India',
    label: <FlagSelect country="in" countryCode="+91" />,
  },
  {
    value: '62',
    name: 'Indonesia',
    label: <FlagSelect country="id" countryCode="+62" />,
  },
  {
    value: '964',
    name: 'Iraq',
    label: <FlagSelect country="iq" countryCode="+964" />,
  },
  {
    value: '98',
    name: 'Iran',
    label: <FlagSelect country="ir" countryCode="+98" />,
  },
  {
    value: '353',
    name: 'Ireland',
    label: <FlagSelect country="ie" countryCode="+353" />,
  },
  {
    value: '972',
    name: 'Israel',
    label: <FlagSelect country="il" countryCode="+972" />,
  },
  {
    value: '39',
    name: 'Italy',
    label: <FlagSelect country="it" countryCode="+39" />,
  },
  {
    value: '1876',
    name: 'Jamaica',
    label: <FlagSelect country="jm" countryCode="+1876" />,
  },
  {
    value: '81',
    name: 'Japan',
    label: <FlagSelect country="jp" countryCode="+81" />,
  },
  {
    value: '962',
    name: 'Jordan',
    label: <FlagSelect country="jo" countryCode="+962" />,
  },
  {
    value: '7',
    name: 'Kazakhstan',
    label: <FlagSelect country="kz" countryCode="+7" />,
  },
  {
    value: '254',
    name: 'Kenya',
    label: <FlagSelect country="ke" countryCode="+254" />,
  },
  {
    value: '686',
    name: 'Kiribati',
    label: <FlagSelect country="ki" countryCode="+686" />,
  },
  {
    value: '850',
    name: 'Korea - North',
    label: <FlagSelect country="kp" countryCode="+850" />,
  },
  {
    value: '82',
    name: 'Korea - South',
    label: <FlagSelect country="kr" countryCode="+82" />,
  },
  {
    value: '965',
    name: 'Kuwait',
    label: <FlagSelect country="kw" countryCode="+965" />,
  },
  {
    value: '996',
    name: 'Kyrgyzstan',
    label: <FlagSelect country="kg" countryCode="+996" />,
  },
  {
    value: '856',
    name: 'Laos',
    label: <FlagSelect country="la" countryCode="+856" />,
  },
  {
    value: '371',
    name: 'Latvia',
    label: <FlagSelect country="lv" countryCode="+371" />,
  },
  {
    value: '961',
    name: 'Lebanon',
    label: <FlagSelect country="lb" countryCode="+961" />,
  },
  {
    value: '266',
    name: 'Lesotho',
    label: <FlagSelect country="ls" countryCode="+266" />,
  },
  {
    value: '231',
    name: 'Liberia',
    label: <FlagSelect country="lr" countryCode="+231" />,
  },
  {
    value: '218',
    name: 'Libya',
    label: <FlagSelect country="ly" countryCode="+218" />,
  },
  {
    value: '417',
    name: 'Liechtenstein',
    label: <FlagSelect country="li" countryCode="+417" />,
  },
  {
    value: '370',
    name: 'Lithuania',
    label: <FlagSelect country="lt" countryCode="+370" />,
  },
  {
    value: '352',
    name: 'Luxembourg',
    label: <FlagSelect country="lu" countryCode="+352" />,
  },
  {
    value: '853',
    name: 'Macao',
    label: <FlagSelect country="mo" countryCode="+853" />,
  },
  {
    value: '389',
    name: 'Macedonia',
    label: <FlagSelect country="mk" countryCode="+389" />,
  },
  {
    value: '261',
    name: 'Madagascar',
    label: <FlagSelect country="mg" countryCode="+261" />,
  },
  {
    value: '265',
    name: 'Malawi',
    label: <FlagSelect country="mw" countryCode="+265" />,
  },
  {
    value: '60',
    name: 'Malaysia',
    label: <FlagSelect country="my" countryCode="+60" />,
  },
  {
    value: '960',
    name: 'Maldives',
    label: <FlagSelect country="mv" countryCode="+960" />,
  },
  {
    value: '223',
    name: 'Mali',
    label: <FlagSelect country="ml" countryCode="+223" />,
  },
  {
    value: '356',
    name: 'Malta',
    label: <FlagSelect country="mt" countryCode="+356" />,
  },
  {
    value: '692',
    name: 'Marshall Islands',
    label: <FlagSelect country="mh" countryCode="+692" />,
  },
  {
    value: '596',
    name: 'Martinique',
    label: <FlagSelect country="mq" countryCode="+596" />,
  },
  {
    value: '222',
    name: 'Mauritania',
    label: <FlagSelect country="mr" countryCode="+222" />,
  },
  {
    value: '269',
    name: 'Mayotte',
    label: <FlagSelect country="yt" countryCode="+269" />,
  },
  {
    value: '691',
    name: 'Micronesia',
    label: <FlagSelect country="fm" countryCode="+691" />,
  },
  {
    value: '373',
    name: 'Moldova',
    label: <FlagSelect country="md" countryCode="+373" />,
  },
  {
    value: '377',
    name: 'Monaco',
    label: <FlagSelect country="mc" countryCode="+377" />,
  },
  {
    value: '976',
    name: 'Mongolia',
    label: <FlagSelect country="mn" countryCode="+976" />,
  },
  {
    value: '1664',
    name: 'Montserrat',
    label: <FlagSelect country="ms" countryCode="+1664" />,
  },
  {
    value: '212',
    name: 'Morocco',
    label: <FlagSelect country="ma" countryCode="+212" />,
  },
  {
    value: '258',
    name: 'Mozambique',
    label: <FlagSelect country="mz" countryCode="+258" />,
  },
  {
    value: '95',
    name: 'Myanmar',
    label: <FlagSelect country="mn" countryCode="+95" />,
  },
  {
    value: '264',
    name: 'Namibia',
    label: <FlagSelect country="na" countryCode="+264" />,
  },
  {
    value: '674',
    name: 'Nauru',
    label: <FlagSelect country="nr" countryCode="+674" />,
  },
  {
    value: '977',
    name: 'Nepal',
    label: <FlagSelect country="np" countryCode="+977" />,
  },
  {
    value: '31',
    name: 'Netherlands',
    label: <FlagSelect country="nl" countryCode="+31" />,
  },
  {
    value: '687',
    name: 'New Caledonia',
    label: <FlagSelect country="nc" countryCode="+687" />,
  },
  {
    value: '64',
    name: 'New Zealand',
    label: <FlagSelect country="nz" countryCode="+64" />,
  },
  {
    value: '505',
    name: 'Nicaragua',
    label: <FlagSelect country="ni" countryCode="+505" />,
  },
  {
    value: '227',
    name: 'Niger',
    label: <FlagSelect country="ne" countryCode="+227" />,
  },
  {
    value: '234',
    name: 'Nigeria',
    label: <FlagSelect country="ng" countryCode="+234" />,
  },
  {
    value: '683',
    name: 'Niue',
    label: <FlagSelect country="nu" countryCode="+683" />,
  },
  {
    value: '672',
    name: 'Norfolk Islands',
    label: <FlagSelect country="nf" countryCode="+672" />,
  },
  {
    value: '670',
    name: 'Northern Marianas',
    label: <FlagSelect country="np" countryCode="+670" />,
  },
  {
    value: '47',
    name: 'Norway',
    label: <FlagSelect country="no" countryCode="+47" />,
  },
  {
    value: '968',
    name: 'Oman',
    label: <FlagSelect country="om" countryCode="+968" />,
  },
  {
    value: '92',
    name: 'Pakistan',
    label: <FlagSelect country="pk" countryCode="+92" />,
  },
  {
    value: '680',
    name: 'Palau',
    label: <FlagSelect country="pw" countryCode="+680" />,
  },
  {
    value: '507',
    name: 'Panama',
    label: <FlagSelect country="pa" countryCode="+507" />,
  },
  {
    value: '675',
    name: 'Papua New Guinea',
    label: <FlagSelect country="pg" countryCode="+675" />,
  },
  {
    value: '595',
    name: 'Paraguay',
    label: <FlagSelect country="py" countryCode="+595" />,
  },
  {
    value: '51',
    name: 'Peru',
    label: <FlagSelect country="pe" countryCode="+51" />,
  },
  {
    value: '63',
    name: 'Philippines',
    label: <FlagSelect country="ph" countryCode="+63" />,
  },
  {
    value: '48',
    name: 'Poland',
    label: <FlagSelect country="pl" countryCode="+48" />,
  },
  {
    value: '351',
    name: 'Portugal',
    label: <FlagSelect country="pt" countryCode="+351" />,
  },
  {
    value: '1787',
    name: 'Puerto Rico',
    label: <FlagSelect country="pr" countryCode="+1787" />,
  },
  {
    value: '974',
    name: 'Qatar',
    label: <FlagSelect country="qa" countryCode="+974" />,
  },
  {
    value: '262',
    name: 'Reunion',
    label: <FlagSelect country="re" countryCode="+262" />,
  },
  {
    value: '40',
    name: 'Romania',
    label: <FlagSelect country="ro" countryCode="+40" />,
  },
  {
    value: '7',
    name: 'Russia',
    label: <FlagSelect country="ru" countryCode="+7" />,
  },
  {
    value: '250',
    name: 'Rwanda',
    label: <FlagSelect country="rw" countryCode="+250" />,
  },
  {
    value: '378',
    name: 'San Marino',
    label: <FlagSelect country="sm" countryCode="+378" />,
  },
  {
    value: '239',
    name: 'Sao Tome & Principe',
    label: <FlagSelect country="st" countryCode="+239" />,
  },
  {
    value: '966',
    name: 'Saudi Arabia',
    label: <FlagSelect country="sa" countryCode="+996" />,
  },
  {
    value: '221',
    name: 'Senegal',
    label: <FlagSelect country="sn" countryCode="+221" />,
  },
  {
    value: '381',
    name: 'Serbia',
    label: <FlagSelect country="cs" countryCode="+381" />,
  },
  {
    value: '248',
    name: 'Seychelles',
    label: <FlagSelect country="sc" countryCode="+248" />,
  },
  {
    value: '232',
    name: 'Sierra Leone',
    label: <FlagSelect country="sl" countryCode="+232" />,
  },
  {
    value: '65',
    name: 'Singapore',
    label: <FlagSelect country="sg" countryCode="+65" />,
  },
  {
    value: '421',
    name: 'Slovak Republic',
    label: <FlagSelect country="sk" countryCode="+421" />,
  },
  {
    value: '386',
    name: 'Slovenia',
    label: <FlagSelect country="si" countryCode="+386" />,
  },
  {
    value: '677',
    name: 'Solomon Islands',
    label: <FlagSelect country="sb" countryCode="+677" />,
  },
  {
    value: '252',
    name: 'Somalia',
    label: <FlagSelect country="so" countryCode="+252" />,
  },
  {
    value: '27',
    name: 'South Africa',
    label: <FlagSelect country="za" countryCode="+27" />,
  },
  {
    value: '34',
    name: 'Spain',
    label: <FlagSelect country="es" countryCode="+34" />,
  },
  {
    value: '94',
    name: 'Sri Lanka',
    label: <FlagSelect country="lk" countryCode="+94" />,
  },
  {
    value: '290',
    name: 'St. Helena',
    label: <FlagSelect country="sh" countryCode="+290" />,
  },
  {
    value: '1869',
    name: 'St. Kitts',
    label: <FlagSelect country="kn" countryCode="+1859" />,
  },
  {
    value: '1758',
    name: 'St. Lucia',
    label: <FlagSelect country="sc" countryCode="+1758" />,
  },
  {
    value: '597',
    name: 'Suriname',
    label: <FlagSelect country="sr" countryCode="+597" />,
  },
  {
    value: '249',
    name: 'Sudan',
    label: <FlagSelect country="sd" countryCode="+249" />,
  },
  {
    value: '268',
    name: 'Swaziland',
    label: <FlagSelect country="sz" countryCode="+268" />,
  },
  {
    value: '46',
    name: 'Sweden',
    label: <FlagSelect country="se" countryCode="+46" />,
  },
  {
    value: '41',
    name: 'Switzerland',
    label: <FlagSelect country="ch" countryCode="+41" />,
  },
  {
    value: '963',
    name: 'Syria',
    label: <FlagSelect country="sy" countryCode="+963" />,
  },
  {
    value: '886',
    name: 'Taiwan',
    label: <FlagSelect country="tw" countryCode="+886" />,
  },
  {
    value: '992',
    name: 'Tajikistan',
    label: <FlagSelect country="tj" countryCode="+992" />,
  },
  {
    value: '66',
    name: 'Thailand',
    label: <FlagSelect country="th" countryCode="+66" />,
  },
  {
    value: '228',
    name: 'Togo',
    label: <FlagSelect country="tg" countryCode="+228" />,
  },
  {
    value: '676',
    name: 'Tonga',
    label: <FlagSelect country="to" countryCode="+676" />,
  },
  {
    value: '1868',
    name: 'Trinidad & Tobago',
    label: <FlagSelect country="tt" countryCode="+1868" />,
  },
  {
    value: '216',
    name: 'Tunisia',
    label: <FlagSelect country="tn" countryCode="+216" />,
  },
  {
    value: '90',
    name: 'Turkey',
    label: <FlagSelect country="tr" countryCode="+90" />,
  },
  {
    value: '993',
    name: 'Turkmenistan',
    label: <FlagSelect country="tm" countryCode="+993" />,
  },
  {
    value: '1649',
    name: 'Turks & Caicos Islands',
    label: <FlagSelect country="tc" countryCode="+1649" />,
  },
  {
    value: '688',
    name: 'Tuvalu',
    label: <FlagSelect country="tv" countryCode="+688" />,
  },
  {
    value: '256',
    name: 'Uganda',
    label: <FlagSelect country="ug" countryCode="+256" />,
  },
  {
    value: '44',
    name: 'UK',
    label: <FlagSelect country="gb" countryCode="+44" />,
  },
  {
    value: '380',
    name: 'Ukraine',
    label: <FlagSelect country="ua" countryCode="+380" />,
  },
  {
    value: '971',
    name: 'United Arab Emirates',
    label: <FlagSelect country="ae" countryCode="+971" />,
  },
  {
    value: '598',
    name: 'Uruguay',
    label: <FlagSelect country="uy" countryCode="+598" />,
  },
  {
    value: '998',
    name: 'Uzbekistan',
    label: <FlagSelect country="uz" countryCode="+998" />,
  },
  {
    value: '678',
    name: 'Vanuatu',
    label: <FlagSelect country="vu" countryCode="+678" />,
  },
  {
    value: '379',
    name: 'Vatican City',
    label: <FlagSelect country="va" countryCode="+379" />,
  },
  {
    value: '58',
    name: 'Venezuela',
    label: <FlagSelect country="ve" countryCode="+58" />,
  },
  {
    value: '84',
    name: 'Vietnam',
    label: <FlagSelect country="vn" countryCode="+84" />,
  },
  {
    value: '1',
    name: 'Virgin Islands - British',
    label: <FlagSelect country="vg" countryCode="+1" />,
  },
  {
    value: '1',
    name: 'Virgin Islands - US',
    label: <FlagSelect country="vi" countryCode="+1" />,
  },
  {
    value: '681',
    name: 'Wallis & Futuna',
    label: <FlagSelect country="wf" countryCode="+681" />,
  },
  {
    value: '969',
    name: 'Yemen',
    label: <FlagSelect country="ye" countryCode="+969(North)" />,
  },
  {
    value: '967',
    name: 'Yemen',
    label: <FlagSelect country="ye" countryCode="+967(South)" />,
  },
  {
    value: '260',
    name: 'Zambia',
    label: <FlagSelect country="zm" countryCode="+260" />,
  },
  {
    value: '263',
    name: 'Zimbabwe',
    label: <FlagSelect country="zw" countryCode="+263" />,
  },
]

export const initialCountryCode = {
  value: '52',
  name: 'Mexico',
  label: <FlagSelect country="mx" countryCode="+52" />,
}
