import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'

export const MainContainer = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

export const InternalContainer = styled(Grid)({
  width: '333px',
  height: '130px',
  margin: `0 auto`,
  marginTop: '10px',
  border: '1px solid #aaa',
  position: 'relative',
  zIndex: '90',
  userSelect: 'none',
})

export const Clean = styled(Grid)({
  width: '27px',
  height: '27px',
  position: 'absolute',
  zIndex: '100',
  top: 0,
  right: 0,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'white',
  userSelect: 'none',
  '&:hover, &:active, &:focus': {
    backgroundColor: '#f186aa2c',
    borderColor: 'var(--secondary-color)',
    boxShadow: 'none !important',
  },
})

export const Instructions = styled('span')({
  marginTop: '10px',
  color: '#757575',
})
