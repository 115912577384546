import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Form as FormCaptureDataSDK,
  Input as InputFormSDK,
  Transition,
  Label,
} from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import {
  Modal as ModalCaptureData,
  Card as CardCaptureData,
  Row,
  Col,
  Tooltip,
  Radio,
  Switch,
} from 'antd'
import { VscDeviceCamera, VscCloudUpload, VscInfo } from 'react-icons/vsc'
import jwt from 'jsonwebtoken'
import '../styles/Home.css'
import useToken from '../utils/useToken'
import { filterCheckPermissions } from '../shared/filters'
//SDK
import { WebVerification } from 'vdid-sdk-web'
import { API_DASHBOARD_URL } from '../const/index.js'
import { useAuth0 } from '../react-auth0-spa'

const styles = {
  divWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  button: {
    background: 'var(--primary-color)',
    color: '#fff',
    borderRadius: '3px',
  },

  icon: {
    color: 'white',
    opacity: '1',
  },
}

const BtnStartVerification = () => {
  const { user } = useAuth0()
  const history = useHistory()
  const { t } = useTranslation()

  //Modal para escoger una opción de capturar datos
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isVisibleModalConfig, setIsVisibleModalConfig] = useState(false)
  //Data to SDK
  const [idUserSDK, setIdUserSDK] = useState('')
  const [optionsSelfieSDK, setOptionsSelfieSDK] = useState(false)
  const [optionVerifyIpSDK, setOptionVerifyIp] = useState(false)
  //Entering the wrong data at input - Form SDK
  const [msgError, setMsgError] = useState('')
  const [visibleMsgError, setVisibleMsgError] = useState(false)
  //Any option selected - Form SDK
  const [msgErrorSelect, setMsgErrorSelect] = useState('')
  const [visibleMsgErrorSelect, setVisibleMsgErrorSelect] = useState(false)
  //Loading and disabled submit button
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [token, setToken] = useState(null)
  const [tokenID, setTokenID] = useState(null)
  const getToken = useToken()

  useEffect(() => {
    const getTokens = async () => {
      const tokenObtained = await getToken({
        audience: 'veridocid-dashboard',
      })

      const tokenIDObtained = await getToken({
        audience: 'veridocid',
      })

      setToken(tokenObtained)
      setTokenID(tokenIDObtained)

      const decoded = jwt.decode(tokenObtained)

      setOptionsSelfieSDK(
        filterCheckPermissions(decoded?.permissions, 'id:selfie')
      )
    }

    getTokens()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /*------ SDK -------*/
  const sdkCreateVerification = async ({
    idUser,
    optionSelfie,
    optionVerifyIp,
  }) => {
    try {
      const response = await fetch(
        `${API_DASHBOARD_URL}/vdid/createVerification`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: idUser,
            selfie: optionSelfie,
            verifyIp: optionVerifyIp,
            redirect_url: 'https://verificaciones.sumamexico.com/',
            token: `Bearer ${tokenID}`,
            name: user.name,
            email: user.email,
            user_id: user.sub
          }),
        }
      )

      if (response.ok === true) {
        const responseJSON = await response.json()

        //Reset values
        setIdUserSDK('')
        setOptionsSelfieSDK(false)
        setOptionVerifyIp(false)

        //Close Modal - Form SDK
        setIsVisibleModalConfig(false)

        //Message success
        let secondsToGo = 6

        const modalSucessDataSDK = ModalCaptureData.success({
          title: `${t('captureImages')}`,
          content: `${t('messageModalSuccessSDK1')} ${secondsToGo} ${t(
            'messageModalSuccessSDK2'
          )}`,
          centered: true,
          closable: false,
          width: '320px',
          className: 'success_modal_sdk',
        })

        const timer = setInterval(() => {
          secondsToGo -= 1
          modalSucessDataSDK.update({
            content: `${t('messageModalSuccessSDK1')} ${secondsToGo} ${t(
              'messageModalSuccessSDK2'
            )}`,
          })
        }, 1000)

        setTimeout(() => {
          //Restar time and destroy modal
          clearInterval(timer)
          modalSucessDataSDK.destroy()
          //SDK
          const vdid = new WebVerification(responseJSON.apiKey)
          vdid.verifyIdentity(responseJSON.identifier)
        }, secondsToGo * 1000)
      } else {
        ModalCaptureData.error({
          title: `${t('captureImages')}`,
          content: `${t('messageErrorData')}`,
          centered: true,
          closable: false,
          width: '320px',
          className: 'error_modal_sdk',
        })
      }
    } catch (error) {
      console.log(
        'En estos momentos tenemos problemas, favor intentar más tarde',
        error
      )
    }
  }

  /*------- Modal -------*/
  //Modal functions
  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleClose = () => {
    setIsModalVisible(false)
  }

  //User options (modal) - capture data SDK
  const showModalConfigSDK = () => {
    setIsVisibleModalConfig(true)
  }

  //ID
  const onChangeInputIdUser = (event) => {
    const idSDK = event?.target?.value
    if (idSDK.length === 0) {
      setVisibleMsgError(true)
      setMsgError(`${t('errorInputSDK')}`)
    } else {
      setVisibleMsgError(false)
      setMsgError(`${t('errorInputSDK')}`)
    }
    setIdUserSDK(idSDK?.replace(/\s/g, ''))
  }

  //Selfie
  const onChangeRadioButtonSelfie = (event) => {
    const optionUser = event?.target?.value
    setOptionsSelfieSDK(optionUser)
  }

  // Options - verify ip (SEON)
  const onChangeVerifyIp = (event) => {
    const checkOptionIp = event
    setOptionVerifyIp(checkOptionIp)
  }

  //Options modal
  const handleCancelConfig = () => {
    setIsVisibleModalConfig(false)
    //Error message
    setVisibleMsgError(false)
    setMsgError('')
  }

  //Submit data to validate SDK
  const handleSubmitConfig = () => {
    if (idUserSDK === '') {
      setVisibleMsgError(true)
      setMsgError(`${t('errorInputSDK')}`)
      setTimeout(() => {
        setVisibleMsgError(false)
      }, 3500)
    } else if (optionsSelfieSDK === null) {
      setVisibleMsgErrorSelect(true)
      setMsgErrorSelect(`${t('errorRadioButtonSDK')}`)
      setTimeout(() => {
        setVisibleMsgErrorSelect(false)
      }, 3500)
    } else {
      setDisabled(true)
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
        //Send request
        sdkCreateVerification({
          idUser: idUserSDK,
          optionSelfie: optionsSelfieSDK,
          optionVerifyIp: optionVerifyIpSDK,
        })
        setDisabled(false)
      }, 1000)
    }
  }

  //Redireccionar a la url de verificar
  const uploadImages = () => {
    let path = '/verificar'
    history.push(path)
  }

  return (
    <>
      <div style={styles.divWrapper}>
        <Button
          className="ui button right btn_custom_verificate"
          style={{ ...styles.button, display: 'flex', height: 'fit-content' }}
          //onClick={routerChange}
          onClick={showModal}
        >
          {t('verify')}
        </Button>
      </div>

      {/* Modal para mostrar las opciones para poder capturar los datos */}
      <ModalCaptureData
        centered={true}
        title={`${t('titleSelectOption')}`}
        open={isModalVisible}
        closable={false}
        style={{ textAlign: 'center' }}
        footer={
          <Button
            key="close"
            loading={false}
            onClick={handleClose}
            id="btn-sdk-close"
          >
            {t('closeModalOptionsSDK')}
          </Button>
        }
      >
        <Row gutter={[24, 16]} justify="center" align="middle">
          <Col xs={20} sm={18} md={12} lg={12} xl={12}>
            <Tooltip placement="bottom" title={`${t('captureImagesSDK')}`}>
              <CardCaptureData
                bordered={true}
                hoverable={true}
                className="modal_card"
                onClick={showModalConfigSDK}
              >
                <strong style={{ color: '#172B4D' }}>
                  {t('captureImages')}
                </strong>
                <VscDeviceCamera className="icon__modal_card" />
              </CardCaptureData>
            </Tooltip>
          </Col>
          <Col xs={20} sm={18} md={12} lg={12} xl={12}>
            <Tooltip placement="bottom" title={`${t('uploadImagesDashboard')}`}>
              <CardCaptureData
                bordered={true}
                hoverable={true}
                className="modal_card"
                onClick={uploadImages}
              >
                <strong style={{ color: '#172B4D' }}>
                  {t('uploadImages')}
                </strong>
                <VscCloudUpload className="icon__modal_card" />
              </CardCaptureData>
            </Tooltip>
          </Col>
        </Row>
      </ModalCaptureData>

      {/* Modal to configure the sdk */}
      <ModalCaptureData
        centered={true}
        title={'SDK'}
        open={isVisibleModalConfig}
        closable={false}
        footer={[
          <Button key="cancel" onClick={handleCancelConfig} id="btn-sdk-cancel">
            {t('cancelDataSDK')}
          </Button>,
          <Button
            key="submit"
            onClick={handleSubmitConfig}
            loading={loading}
            disabled={disabled}
            id="btn-sdk-submit"
          >
            {t('submitDataSDK')}
          </Button>,
        ]}
      >
        <FormCaptureDataSDK>
          <FormCaptureDataSDK.Field>
            <p>{t('labelInputIdSDK')}</p>
            <InputFormSDK
              fluid
              placeholder="Ingresar un ID"
              onChange={onChangeInputIdUser}
              value={idUserSDK}
            />
            {/* Control errors */}
            <Transition
              animation="drop"
              duration={500}
              visible={visibleMsgError}
            >
              <Label pointing color="red" as="span">
                {`${msgError}`}
              </Label>
            </Transition>
          </FormCaptureDataSDK.Field>
          {/* Selfie */}
          <FormCaptureDataSDK.Field>
            <p>{t('labelRadioButtonSelfieSDK')}</p>
            <Radio.Group
              onChange={onChangeRadioButtonSelfie}
              value={optionsSelfieSDK}
            >
              <Radio value={true}>{t('yes')}</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </FormCaptureDataSDK.Field>
          {/* Control errors */}
          <Transition
            animation="drop"
            duration={500}
            visible={visibleMsgErrorSelect}
          >
            <Label pointing color="red" as="span">
              {`${msgErrorSelect}`}
            </Label>
          </Transition>
          {/* Others services */}
          <FormCaptureDataSDK.Field>
            <p style={{ padding: '0', margin: '1.8rem 0 0.4rem 0' }}>
              {t('ipTitle')}{' '}
              <Tooltip title={`${t('info_verify_ip')}`} placement="right">
                <VscInfo style={{ cursor: 'pointer' }} size={14} />
              </Tooltip>
            </p>
            <Switch
              onChange={onChangeVerifyIp}
              checked={optionVerifyIpSDK}
              size="default"
              className="switch_sdk"
            />
          </FormCaptureDataSDK.Field>
        </FormCaptureDataSDK>
      </ModalCaptureData>
    </>
  )
}

export default BtnStartVerification
