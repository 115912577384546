import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Loading from '../components/Loading'
import ModalSign from '../components/Signature/PDFSignatureNC/Modal/ModalSign'
import ChangePDF from '../components/Signature/PDFSignatureNC/ViewPDF/ViewPDF.jsx'
import {
  Container,
  Details,
  InternalContainer,
} from '../components/Signature/Templates/UseTemplate/SignTemplateStyle.jsx'
import ViewPDF from '../components/Signature/Templates/UseTemplate/ViewPDF/ViewPDF.jsx'
import { useAuth0 } from '../react-auth0-spa.js'
import { getTemplate, getTemplateBase64 } from '../utils/templatesServices.js'
import useToken from '../utils/useToken.js'
// import boxSign from '../assets/caja firma.svg'
import { message as signMessage } from 'antd'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import boxSign from '../assets/caja_firma.png'
import '../components/Signature/Templates/UseTemplate/SignTemplateStyle.css'
import UseTemplate from '../components/Signature/Templates/UseTemplate/UseTemplate.jsx'
import { API_URL_V3 } from '../const/index'
import { useStyles } from '../styles/PDFSignatureV2'
import { getVerificationSigner } from '../utils/getDataCustomize.js'

let signPositionsDOM = []
let offsetClickDown

// fin
export default function SignTemplate() {
  const classes = useStyles()
  const { t } = useTranslation()
  let { uuidTemplate } = useParams()
  const { user, isAuthenticated } = useAuth0()
  const getToken = useToken()
  const history = useHistory()

  //Obtener informacion de plantilla
  const [consult, setConsult] = useState(true)
  const [templateData, setTemplateData] = useState({})
  //ViewPDF
  const [changePDF, setChangePDF] = useState(false)
  const [file, setFile] = useState('')
  const [fileBase64, setFileBase64] = useState('false')
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [pageSize, setPageSize] = useState([612, 792])
  const [onBorder, setOnBorder] = useState(true)
  const [allSigners, setAllSigners] = useState([])
  //Modal
  const [open, setOpen] = useState(false)
  //Data
  const [geolocation, setGeolocation] = useState('')
  const [ip, setIP] = useState('')
  const [sign, setSign] = useState('')
  const [signPositions, setSignPositions] = useState([])
  const [currentSelect, setCurrentSelect] = useState('')
  const [currentDrop, setCurrentDrop] = useState([])
  const [eventDD, setEventDD] = useState(false)
  const [isFirstChange, setIsFirstChange] = useState(true)
  const [signsLegalRepresentative, setSignsLegalRepresentative] = useState([])
  const [whoSign, setWhoSign] = useState('')
  const [oneTime, setOneTime] = useState(true)
  const [refreshVideoVerification, setRefreshVideoVerification] = useState(null)
  const [proofAddress, setProofAddress] = useState(null)
  const [signVideoVerification, setSignVideoVerification] = useState('')
  const [signProofAddress, setSignProofAddress] = useState('')
  const [refreshEndorse, setRefreshEndorse] = useState(null)
  const [signEndorse, setSignEndorse] = useState('')
  const [endorseText, setEndorseText] = useState(
    'Endoso en propiedad de la presente factura del auto a favor de UVI TECH, S.A.P.I de C.V'
  )

  const [imgBoxState, setImageBoxState] = useState(null)

  // Biometric option
  const [biometricOption, setBiometricOption] = useState(false)
  const [userSelectBiometric, setUserSelectBiometric] = useState('')

  const [newWidth, setNewWidth] = useState(136)
  const [newHeigth, setNewHeigth] = useState(73)

  // Roles
  const [roles, setRoles] = useState([])
  const [clientID, setClientID] = useState({})
  const [signBase64, setSignBase64] = useState('')

  useEffect(() => {
    if (isAuthenticated) {
      setRoles(user?.['https://vdid.sumamexico.com/roles'] ?? [])
      setClientID(user?.['https://vdid.sumamexico.com/app_metadata'] ?? {})
    }
  }, [isAuthenticated, user])

  const handleRenderSuccess = (pgd) => {
    let canvas = document.getElementById('pdf_renderer')
    canvas = canvas.firstChild
    canvas = canvas.firstChild
    canvas = canvas.firstChild
    setPageSize([canvas.style.width, canvas.style.height])

    handleComproveSigns()
  }

  const dd_dragstart = (e) => e.dataTransfer.setData('text/plain', e.target.id)

  const dd_dragover = (e) => e.preventDefault()

  const dd_drop = (e) => {
    e.preventDefault()

    const getContainerElement = e.target.parentElement

    let eX = 0
    let eY = 0

    const newElement = document.getElementById(e.dataTransfer.getData('text'))
    const element = e.target.innerHTML

    if (!getContainerElement.id && !getContainerElement.className) {
      let [, x, y] = getContainerElement?.parentElement?.id
        .split('-')
        .splice(-3)

      const { coordinates } = signPositionsDOM.find(
        (sign) =>
          sign.coordinates.x === Number(x) && sign.coordinates.y === Number(y)
      )
      eX = coordinates.x + e.offsetX
      eY = coordinates.y + e.offsetY
    } else {
      eX = e.offsetX
      eY = e.offsetY
    }

    if (offsetClickDown) {
      eY = eY - (offsetClickDown.y - offsetClickDown.height)
      eX = eX - (offsetClickDown.x - offsetClickDown.width)
    }

    //Actualiza el arreglo con los datos actuales del elemento dropeado
    setCurrentDrop([{ newElement, element, eX, eY }])
  }

  // nuevo
  const dd_mousedown = (e) => {
    let getContainerElement = e.target.parentElement
    if (getContainerElement.id === 'emailBox') {
      getContainerElement = getContainerElement.parentElement
    }

    let width = getContainerElement.offsetWidth / 2
    width += 10
    const height = getContainerElement.offsetHeight / 2

    offsetClickDown = { width, height, x: e.offsetX, y: e.offsetY }
  }
  const dd_mouseup = () => {
    offsetClickDown = null
  }
  // fin

  const handleComproveSigns = () => {
    if (imgBoxState == null) {
      setImageBoxState(boxSign)
    }
    const dzone = document.getElementById('ddzone')
    // Zona A (PDF)

    if (dzone) {
      if (!eventDD) {
        dzone.addEventListener('dragover', dd_dragover)
        dzone.addEventListener('drop', dd_drop)
        dzone.addEventListener('mousedown', dd_mousedown)
        dzone.addEventListener('mouseup', dd_mouseup)
        dzone.addEventListener('dragstart', dd_dragstart)
        setEventDD(true)
      }

      //Elimina los hijos ya existentes, Evita duplicados
      const tope = dzone.childElementCount
      for (let i = 0; i < tope; i++) {
        dzone.removeChild(dzone.firstChild)
      }

      //Comprueba cada uno de los firmantes
      // let internalSignPositions = signPositions
      let internalSignPositions = [...signPositions]

      // Filtra las firmas por email para determinar el total de firmante
      if (allSigners.length === 0) {
        let arrTemp = []
        arrTemp = signPositions
        let hash = {}
        arrTemp = arrTemp.filter((o) =>
          hash[o.user.email] ? false : (hash[o.user.email] = true)
        )
        setAllSigners(arrTemp)
      }

      for (let [i, signer] of internalSignPositions.entries()) {
        //Si el firmante tiene una firma activa en esta hoja, creara su caja de firma
        if (signer.page === page) {
          let mainDiv = document.createElement('div')
          let leftDiv = document.createElement('div')
          let rightDiv = document.createElement('div')
          let rightDiv_Close = document.createElement('span')
          let rightText = document.createTextNode('×')
          let rightDiv_AddSign = document.createElement('button')
          let rightAddSign = document.createTextNode('+')
          let imgBox = document.createElement('img')
          let internalDiv = document.createElement('div')
          // let internalDivEmail = document.createTextNode(signer?.user?.email)
          let emailBox = document.createElement('div')
          let emailText = document.createTextNode(`${signer?.user?.email}`)

          let endorseDiv = document.createElement('div')

          mainDiv.id = `sign${i}`

          if (signer.user.showEndorse) {
            endorseDiv.style.position = 'absolute'
            endorseDiv.style.top = 'calc(100% - 1px)'
            endorseDiv.style.lineHeight = '13px'
            endorseDiv.style.textAlign = 'center'
            endorseDiv.style.padding = '0.6rem 0.2rem'
            endorseDiv.style.marginLeft = '-16px'
            endorseDiv.style.whiteSpace = 'nowrap'

            endorseDiv.appendChild(
              document.createTextNode(signer.user.endorseText)
            )

            mainDiv.appendChild(endorseDiv)
          }

          mainDiv.draggable = true
          mainDiv.classList = `${classes.mainDiv}`
          mainDiv.style.fontSize = `${9 * zoom}px`
          mainDiv.style.width = `${136 * zoom}px`
          mainDiv.style.height = `${73 * zoom}px`

          leftDiv.style.width = `${newWidth * zoom}px`
          leftDiv.style.height = `${newHeigth * zoom}px`

          if (!onBorder) imgBox.style.opacity = 0

          if (signBase64) {
            imgBox.src = `data:image/jpg;base64,${signBase64}`
          } else {
            imgBox.src = imgBoxState
          }

          imgBox.draggable = false
          // imgBox.style.width = `${136 * zoom}px`
          // imgBox.style.height = `${73 * zoom}px`

          imgBox.style.width = `${newWidth * zoom}px`
          imgBox.style.height = `${newHeigth * zoom}px`
          imgBox.style.position = 'relative'

          emailBox.classList = `${classes.emailBox}`
          emailBox.id = `emailBox`
          emailBox.style.width = `100%`
          emailBox.style.height = `${73 * zoom}px`
          emailBox.style.display = 'flex'
          emailBox.style.flexDirection = 'column'
          emailBox.style.alignItems = 'center'
          emailBox.style.justifyContent = 'center'
          emailBox.style.paddingLeft = '0px'

          if (
            signer.user.email.includes('Firmante') ||
            isEmpty(sign) ||
            signer.user.isLegalRepresentative === true
          ) {
            emailBox.appendChild(emailText)
          }

          leftDiv.appendChild(imgBox)
          leftDiv.appendChild(emailBox)

          rightDiv.classList = `${classes.rightDiv}`
          rightDiv.style.width = `${20 * zoom}px`
          rightDiv.style.height = `${newHeigth * zoom}px`

          internalDiv.classList = 'internalDiv'

          if (signer.user.email === user.email && !isEmpty(sign)) {
            internalDiv.style.width = `${
              sign.signature === ''
                ? 113 * zoom
                : sign.option !== 3
                ? 80 * zoom
                : 113 * zoom
            }px`
            internalDiv.style.height = `${35 * zoom}px`
            internalDiv.classList = 'image_sign'
            internalDiv.style.paddingLeft = `${10 * zoom}px`
            internalDiv.style.backgroundImage = `url(${sign.signature})`
            // internalDiv.style.display = 'none'
            internalDiv.style.backgroundSize = `${
              sign.option !== 3 ? 80 * zoom : 113 * zoom
            }px ${35 * zoom}px`

            leftDiv.appendChild(internalDiv)
          }

          mainDiv.appendChild(leftDiv)
          mainDiv.appendChild(rightDiv)

          mainDiv.setAttribute(
            'id',
            `${signer?.user?.email}%PXY%${signer?.page}-${signer?.coordinates.x}-${signer?.coordinates.y}`
          )

          // Close signature
          rightDiv_Close.classList = `${classes.rightDiv_Close}`
          rightDiv_Close.style.width = `${20 * zoom}px`
          rightDiv_Close.style.height = `${20 * zoom}px`
          rightDiv_Close.style.fontSize = `${20 * zoom}px`
          rightDiv_Close.style.paddingLeft = `${0.5 * zoom}px`
          rightDiv_Close.style.paddingBottom = `${4 * zoom}px`
          rightDiv_Close.style.marginBottom = `${5 * zoom}px`
          rightDiv_Close.setAttribute(
            'id',
            `${signer?.user?.email}%PXY%${signer?.page}-${signer?.coordinates.x}-${signer?.coordinates.y} `
          )
          rightDiv_AddSign.classList = 'rightDiv_AddSign'
          rightDiv_AddSign.style.width = `${20 * zoom}px`
          rightDiv_AddSign.style.height = `${20 * zoom}px`
          rightDiv_AddSign.style.fontSize = `${20 * zoom}px`
          rightDiv_AddSign.style.paddingLeft = `${0.5 * zoom}px`
          rightDiv_AddSign.style.paddingBottom = `${4 * zoom}px`
          rightDiv_AddSign.style.marginBottom = `${5 * zoom}px`
          rightDiv_AddSign.setAttribute(
            'id',
            `${signer?.user?.email}%PXY%${signer?.page}-${signer?.coordinates.x}-${signer?.coordinates.y} `
          )

          rightDiv_Close.onclick = function (e) {
            let newA = []
            signMessage.info(`Se elimino con éxito la firma`)
            for (let signer of signPositions) {
              if (signer) {
                if (
                  `${signer?.user?.email}%PXY%${signer?.page}-${signer?.coordinates.x}-${signer?.coordinates.y}` !==
                    e.target.id.trim() ||
                  signer.page !== page
                ) {
                  if (
                    signer?.user?.endorsePosition &&
                    `${signer?.user?.email}%PXY%${signer?.page}-${signer?.coordinates.x}-${signer?.coordinates.y}` ===
                      e.target.id.trim()
                  ) {
                  } else {
                    newA = [...newA, signer]
                  }
                }
              }
            }
            setSignPositions(newA)
            document.getElementById(`${e.target.id.trim()}`).remove()
          }

          rightDiv_AddSign.onclick = async (e) => {
            let currentPosition
            rightDiv_AddSign.disabled = true

            for (let signer of signPositions) {
              if (
                `${signer?.user?.email}%PXY%${signer?.page}-${signer?.coordinates.x}-${signer?.coordinates.y}` ===
                e.target.id.trim()
              ) {
                currentPosition = signer
                break
              }
            }

            const url = `${API_URL_V3}/scale-signs`

            let data = {
              signPosition: currentPosition.coordinates,
              pdfDocument: fileBase64,
            }

            const formdata = JSON.stringify(data)

            const params = {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: formdata,
            }
            const response = await fetch(url, params)
            if (response.ok) {
              const scaleSignPositions = await response.json()

              let newB = signPositions
              for (let count = 1; count <= totalPages; count++) {
                if (count !== page && count > page) {
                  newB.push({
                    coordinates: scaleSignPositions.signPositions[count - 1],
                    page: count,
                    user: currentPosition.user,
                    zoom: currentPosition.zoom,
                  })
                }
              }
              setSignPositions(newB)
              signMessage.info(`${t('addMoreSignatures')}`)
            }
            rightDiv_AddSign.disabled = false
          }

          rightDiv_Close.appendChild(rightText)
          rightDiv_AddSign.appendChild(rightAddSign)

          rightDiv.appendChild(rightDiv_Close)

          rightDiv.appendChild(rightDiv_AddSign)

          mainDiv.ondragstart = function (e) {
            setCurrentSelect(e.target.id)
          }

          //convierte las coordenadas a relacion 1/1 y las ajusta al zoom actual
          mainDiv.style.top =
            (signer?.coordinates?.y / signer?.zoom) * zoom + 'px'
          mainDiv.style.marginLeft =
            (signer?.coordinates?.x / signer?.zoom) * zoom + 'px'

          dzone.appendChild(mainDiv)
        }
      }

      //Zona de los firmantes
      const sZone = document.getElementById('signers_zone')

      if (sZone) {
        //Por cada posicion de firma, se sacara la informacion de los participantes sin que se repitan
        let positionEmails = []
        let pushPE = true
        for (let signPosition of signPositions) {
          if (positionEmails.length === 0) {
            positionEmails.push(signPosition.user)
          } else {
            pushPE = true
            for (let positionE of positionEmails) {
              if (positionE.email === signPosition.user.email) {
                pushPE = false
                break
              }
            }
            if (pushPE) {
              positionEmails.push(signPosition.user)
            }
          }
        }

        if (oneTime) {
          let me = false,
            setMe = false,
            setMeandOthers = false,
            onlySignersNoRL = []
          for (let signer of positionEmails) {
            if (
              signer.temporalID === user.email &&
              signer.isLegalRepresentative === false
            )
              me = true
          }

          for (let signer of positionEmails) {
            if (signer.isLegalRepresentative === false) {
              onlySignersNoRL.push(signer)
            }
          }

          if (me && onlySignersNoRL.length === 1) {
            setWhoSign('me')
            setMe = true
          }
          if (me && onlySignersNoRL.length > 1) {
            setWhoSign('others_me')
            setMeandOthers = true
          }
          if (!me) setWhoSign('others')

          //Una vez identificados los participantes se crearan las tarjetas de firma
          for (let signerEmail of positionEmails) {
            let mainLi = document.createElement('li')
            let mainDiv = document.createElement('div')
            let labelTitle = document.createElement('label')
            let labelTitleText = document.createTextNode(
              signerEmail.temporalID === user.name
                ? 'Yo'
                : signerEmail.temporalID
            )
            let labelName = document.createElement('label')
            let labelNameText = document.createTextNode('Nombre')
            let inputName = document.createElement('input')
            let labelEmail = document.createElement('label')
            let labelEmailText = document.createTextNode('Email')
            let inputEmail = document.createElement('input')

            // Buscador uuid
            let contentUUID = document.createElement('div')
            let contentUUIDActions = document.createElement('div')
            let labelUUID = document.createElement('label')
            let labelUUIDText = document.createTextNode('UUID')
            let inputUUID = document.createElement('input')
            let searchUUID = document.createElement('span')

            // Identity
            let optionsDiv = document.createElement('div')
            let optionsDivIdentity = document.createElement('div')
            let checkIdentity = document.createElement('input')
            let spanIdentity = document.createElement('span')
            let textIdentity = document.createTextNode('Biometría Facial')

            // Endorse Checkbox
            let optionsEndorseDiv = document.createElement('div')
            let optionsEndorseDivIdentity = document.createElement('div')
            let checkEndorseIdentity = document.createElement('input')
            let spanEndorseIdentity = document.createElement('span')
            let textEndorseIdentity = document.createTextNode('Endosar Firma')

            // VideoVerification Checkbox
            let optionsVideoVerificationDiv = document.createElement('div')
            let optionsVideoVerificationDivIdentity =
              document.createElement('div')
            let checkVideoVerificationIdentity = document.createElement('input')
            let spanVideoVerificationIdentity = document.createElement('span')
            let textVideoVerificationIdentity = document.createTextNode(
              'Verificacion de Video'
            )

            // Proof of address Checkbox
            let optionsProofAddressDiv = document.createElement('div')
            let optionsProofAddressDivIdentity = document.createElement('div')
            let checkProofAddressIdentity = document.createElement('input')
            let spanProofAddressIdentity = document.createElement('span')
            let textProofAddressIdentity = document.createTextNode(
              'Comprobante de domicilio'
            )

            // Creacion de la etiqueta Verificacion
            let optionsVerificationDiv = document.createElement('div')
            let optionsVerificationDivIdentity = document.createElement('div')
            let checkVerificationIdentity = document.createElement('input')
            let spanVerificationIdentity = document.createElement('span')
            let textVerificationIdentity = document.createTextNode(
              'Verificación Facial'
            )

            mainLi.classList = 'mainLi'

            // -------- Title signer
            labelTitle.appendChild(labelTitleText)
            labelTitle.classList = 'labelTitleTemplateForm'
            mainDiv.appendChild(labelTitle)

            //-------- Search uuid
            contentUUID.style.width = '100%'
            contentUUID.style.paddingBottom = '1rem'
            contentUUID.classList = `contentUuid-${signerEmail?.name}`

            labelUUID.appendChild(labelUUIDText)
            labelUUID.classList = 'labelTemplateForm'
            // Input
            inputUUID.type = 'text'
            inputUUID.placeholder = 'Buscar uuid...'
            inputUUID.classList = 'input_search_uuid'
            contentUUID.appendChild(labelUUID)
            // Button
            searchUUID.classList = 'btn_search'
            searchUUID.setAttribute('id', 'btn_search_uuid')
            searchUUID.onclick = () =>
              onSearchUUID(inputUUID.value, signerEmail?.name)
            // Contents
            contentUUIDActions.classList = 'content_search_main'
            contentUUIDActions.appendChild(inputUUID)
            contentUUIDActions.appendChild(searchUUID)
            contentUUID.appendChild(contentUUIDActions)

            // Para el primer firmante no se muestra estas opciones (buscador uuid y verificación)
            if (
              signerEmail?.email === user?.email ||
              signerEmail?.email.includes('sumamexico.com') ||
              signerEmail?.email.includes('gmail.com') ||
              (signerEmail?.temporalID === positionEmails.at(0)?.temporalID &&
                window.localStorage.getItem('uuidVerification'))
            ) {
              contentUUID.style.display = 'none'
              optionsVerificationDiv.style.display = 'none'
            }

            mainDiv.appendChild(contentUUID)

            //--------- Input Name
            labelName.appendChild(labelNameText)
            labelName.classList = 'labelTemplateForm'
            mainDiv.appendChild(labelName)

            inputName.type = 'text'
            inputName.placeholder = signerEmail.name
            inputName.id = `${signerEmail.temporalID} `
            inputName.classList = 'inputTemplateForm'
            if (signerEmail.name.indexOf('Firmante') !== 0)
              inputName.value = `${signerEmail.name}`

            mainDiv.appendChild(inputName)

            labelEmail.appendChild(labelEmailText)
            labelEmail.classList = 'labelTemplateForm'
            mainDiv.appendChild(labelEmail)

            inputEmail.type = 'text'
            inputEmail.placeholder = signerEmail.email
            inputEmail.id = `${signerEmail.temporalID}  `
            inputEmail.classList = 'inputTemplateForm'
            if (signerEmail.email.indexOf('Firmante') !== 0)
              inputEmail.value = `${signerEmail.email}`

            mainDiv.appendChild(inputEmail)

            optionsDiv.classList = 'optionsDiv'
            optionsDivIdentity.classList = `optionsDivIdentity biometric-${signerEmail?.name}`
            optionsDivIdentity.id = `biometric-${signerEmail?.name}`

            optionsEndorseDiv.classList = 'optionsDiv'
            optionsEndorseDivIdentity.classList = 'optionsDivIdentity'

            optionsVideoVerificationDiv.classList = 'optionsDiv'
            optionsVideoVerificationDivIdentity.classList = 'optionsDivIdentity'

            optionsProofAddressDiv.classList = 'optionsDiv'
            optionsProofAddressDivIdentity.classList = 'optionsDivIdentity'

            optionsVerificationDiv.classList = 'optionsDiv'
            optionsVerificationDivIdentity.classList = `optionsDivIdentity`
            optionsVerificationDivIdentity.id = `optionsDivIdentity-${signerEmail?.name}`

            if (
              isAuthenticated &&
              !roles?.includes('EndorseUser') &&
              clientID?.clientId === 'F0LDMiWc3mOlQjgtCLWIsA32U9og84UO'
            ) {
              optionsEndorseDivIdentity.style.display = 'none'
            }

            // Biometric
            if (window.localStorage.getItem('uuidVerification')) {
              checkIdentity.type = 'checkbox'
              checkIdentity.classList = 'checkIdentity'
              checkIdentity.checked = signerEmail.biometric
              checkIdentity.id = `${signerEmail.email}_li  `

              // Cuando es "yo" o "yo y otros" se debe esconder el check de biometria, debido a que si esta activado no se manda la imagen del rostro lo que puede dar errores
              if (
                setMe ||
                (signerEmail?.email === user?.email && setMeandOthers)
              ) {
                optionsDivIdentity.style.display = 'none'
              }

              // Validaciones solo Kavak para establecer el check del endoso desde inicio (solo el primer firmante)
              if (
                signerEmail?.temporalID === positionEmails.at(0)?.temporalID &&
                isAuthenticated &&
                roles?.includes('EndorseUser') &&
                !signerEmail?.isLegalRepresentative &&
                clientID?.clientId === 'F0LDMiWc3mOlQjgtCLWIsA32U9og84UO'
              ) {
                checkIdentity.checked = true
                setBiometricOption(true)
              }

              checkIdentity.onchange = function (e) {
                setUserSelectBiometric(signerEmail.temporalID)
                setBiometricOption(e.target.checked)
              }
              optionsDivIdentity.appendChild(checkIdentity)

              optionsDiv.appendChild(optionsDivIdentity)

              mainDiv.appendChild(optionsDiv)
              mainLi.appendChild(mainDiv)
            }

            // VideoVerification Signature
            checkVideoVerificationIdentity.type = 'checkbox'
            checkVideoVerificationIdentity.classList = 'checkIdentity'
            checkVideoVerificationIdentity.checked = signerEmail.video
            checkVideoVerificationIdentity.id = `${signerEmail.email}_li  `

            // Proof Address Signature
            checkProofAddressIdentity.type = 'checkbox'
            checkProofAddressIdentity.classList = 'checkIdentity'
            checkProofAddressIdentity.checked = signerEmail.isProofAddress
            checkProofAddressIdentity.id = `${signerEmail.email}_li  `

            // Endorse Signature
            checkEndorseIdentity.type = 'checkbox'
            checkEndorseIdentity.classList = 'checkIdentity'
            checkEndorseIdentity.checked = signerEmail.isEndorse
            checkEndorseIdentity.id = `${signerEmail.email}_li  `

            // Creacion de checkbox para verificacion
            checkVerificationIdentity.type = 'checkbox'
            checkVerificationIdentity.classList = 'checkVerification'
            checkVerificationIdentity.checked = signerEmail.isVerification
            checkVerificationIdentity.id = `${signerEmail.temporalID}`

            checkEndorseIdentity.onchange = function (e) {
              setRefreshEndorse(null)
              setSignEndorse(signerEmail.temporalID)
              setRefreshEndorse(e.target.checked)
            }

            checkVideoVerificationIdentity.onchange = function (e) {
              setRefreshVideoVerification(null)
              setSignVideoVerification(signerEmail.temporalID)
              setRefreshVideoVerification(e.target.checked)
            }

            checkProofAddressIdentity.onchange = function (e) {
              setProofAddress(null)
              setSignProofAddress(signerEmail.temporalID)
              setProofAddress(e.target.checked)
            }

            checkVerificationIdentity.onchange = function (e) {
              let auxSignPositions = signPositions

              document.getElementsByClassName(
                `contentUuid-${signerEmail?.name}`
              )[0].style.display = e.target.checked ? 'none' : 'block'

              optionsVideoVerificationDiv.style.display = e.target.checked
                ? 'block'
                : 'none'

              optionsProofAddressDiv.style.display = e.target.checked
                ? 'block'
                : 'none'

              if (!e.target.checked) setRefreshVideoVerification(false)

              // Ocultar el check de biometria si eligen verificación
              let biometricDivCheckBox = document.getElementById(
                `biometric-${signerEmail?.name}`
              )

              if (biometricDivCheckBox !== null) {
                biometricDivCheckBox.style.display = e.target.checked
                  ? 'none'
                  : 'flex'
              }
              for (let signPosition of auxSignPositions)
                if (e.target.id === signPosition.user.temporalID) {
                  signPosition.user.biometric = e.target.checked
                    ? false
                    : signerEmail.biometric
                  signPosition.user.uuid = e.target.checked
                    ? ''
                    : signerEmail.uuid
                  signPosition.user.sdk = e.target.checked
                    ? true
                    : signerEmail.sdk
                }
              setSignPositions([...auxSignPositions])
            }

            inputUUID.onkeyup = () => {
              document.getElementById(
                `optionsDivIdentity-${signerEmail?.name}`
              ).style.display = inputUUID.value ? 'none' : 'flex'
            }

            // Checkbox the verificación
            optionsVerificationDivIdentity.appendChild(
              checkVerificationIdentity
            )
            optionsVerificationDiv.appendChild(optionsVerificationDivIdentity)
            mainDiv.appendChild(optionsVerificationDiv)

            // Se muestra el checkbox de verificacion
            optionsEndorseDivIdentity.appendChild(checkEndorseIdentity)
            optionsEndorseDiv.appendChild(optionsEndorseDivIdentity)
            mainDiv.appendChild(optionsEndorseDiv)

            // Se valida para que solo se muestre si es un cliente de Monte
            if (
              clientID?.clientId === 'YVNNc03FL9G8Zt58yJxYgqq0VqMXKRfm' ||
              clientID?.clientId === 'ZcO0euZ9ekObdfCXa1XW4VvqLze4Mi8R' ||
              clientID?.clientId === 'PCjGAWQk80Oz9PCwQKHdjfrbQfVFwIwa' ||
              clientID?.clientId === 'TaDlnYwJD3CLV6cnLOmUqobPdkFBqR49' ||
              clientID?.clientId === 'CdS5CULl5bNGY4EyNXLWXCeaAjdnhDbF' ||
              clientID?.clientId === 'gkOhlOFKrxmcowZawGThfeeIAQwDsxff' ||
              clientID?.clientId === 'iWu9ttlsyzggGEu0WxMz7aIz1CpbgbJS'
            ) {
              optionsVideoVerificationDiv.style.display = 'none'
              optionsVideoVerificationDivIdentity.appendChild(
                checkVideoVerificationIdentity
              )
              optionsVideoVerificationDiv.appendChild(
                optionsVideoVerificationDivIdentity
              )
              mainDiv.appendChild(optionsVideoVerificationDiv)

              optionsProofAddressDiv.style.display = 'none'
              optionsProofAddressDivIdentity.appendChild(
                checkProofAddressIdentity
              )
              optionsProofAddressDiv.appendChild(optionsProofAddressDivIdentity)
              mainDiv.appendChild(optionsProofAddressDiv)
            }

            // Spans
            spanIdentity.classList = 'spanOptionsText'
            spanIdentity.appendChild(textIdentity)
            optionsDivIdentity.appendChild(spanIdentity)

            spanVideoVerificationIdentity.classList = 'spanOptionsText'
            spanVideoVerificationIdentity.appendChild(
              textVideoVerificationIdentity
            )
            optionsVideoVerificationDivIdentity.appendChild(
              spanVideoVerificationIdentity
            )

            spanProofAddressIdentity.classList = 'spanOptionsText'
            spanProofAddressIdentity.appendChild(textProofAddressIdentity)
            optionsProofAddressDivIdentity.appendChild(spanProofAddressIdentity)

            spanEndorseIdentity.classList = 'spanOptionsText'
            spanEndorseIdentity.appendChild(textEndorseIdentity)
            optionsEndorseDivIdentity.appendChild(spanEndorseIdentity)

            spanVerificationIdentity.classList = 'spanOptionsText'
            spanVerificationIdentity.appendChild(textVerificationIdentity)
            optionsVerificationDivIdentity.appendChild(spanVerificationIdentity)

            mainLi.appendChild(mainDiv)

            if (signerEmail.isLegalRepresentative === true) {
              mainDiv.style.display = 'none'
            }

            mainDiv.classList = 'mainDivTemplateForm'
            mainDiv.id = signerEmail.temporalID
            sZone.appendChild(mainDiv)
          }

          setOneTime(false)
        }
      }

      //Zona B (Zona derecha)
      const bzone = document.getElementById('ddzoneB')

      if (bzone) {
        //Elimina los hijos ya existentes, Evita duplicados
        const topeB = bzone.childElementCount
        for (let i = 0; i < topeB; i++) {
          bzone.removeChild(bzone.firstChild)
        }

        //Por cada firmante en el arreglo de la zona B se creara su caja
        for (let signer of allSigners) {
          let newDivB = document.createElement('div')

          newDivB.classList = 'taskB'
          newDivB.style.width = `${136 * zoom}px`
          newDivB.style.height = `${75 * zoom}px`
          newDivB.style.fontSize = `${9 * zoom}px`
          newDivB.setAttribute('draggable', true)

          let newImg = document.createElement('img')
          newImg.src = boxSign
          newImg.height = 75 * zoom
          newImg.width = 136 * zoom
          newImg.style.position = 'absolute'
          newImg.style.zIndex = 0
          // newImg.style.backgroundColor = 'blue'
          newImg.setAttribute('draggable', false)
          newDivB.appendChild(newImg)

          let internalDiv = document.createElement('div')
          internalDiv.style.height = `${75 * zoom}px`
          internalDiv.style.width = `${110 * zoom}px`
          internalDiv.style.overflow = 'hidden'
          internalDiv.style.display = 'flex'
          internalDiv.style.flexDirection = 'column'
          internalDiv.style.flexWrap = 'wrap'
          internalDiv.style.justifyContent = 'center'
          internalDiv.style.alignItems = 'center'
          internalDiv.style.zIndex = 98 * zoom

          let contentEmail = document.createTextNode(`${signer.user.email}`)
          internalDiv.appendChild(contentEmail)
          newDivB.appendChild(internalDiv)

          newDivB.setAttribute('id', `${signer.user.email}`)

          bzone.appendChild(newDivB)
        }
      }
    }
  }

  const handleEndorseText = (target) => {
    const { id, value } = target,
      auxSignPositions = [...signPositions]

    setEndorseText(value)
    for (const signPosition of auxSignPositions)
      if (
        id === signPosition.user.temporalID &&
        signPosition.user.endorsePosition
      ) {
        signPosition.user.endorseText = value
      }
    setSignPositions([...auxSignPositions])
  }

  const handleVideoVerificationText = (target) => {
    const { id, value } = target,
      auxSignPositions = [...signPositions]

    for (const signPosition of auxSignPositions)
      if (id === signPosition.user.temporalID) {
        signPosition.user.videoText = value
      }

    setSignPositions([...auxSignPositions])
  }

  useEffect(() => {
    if (proofAddress === null || signProofAddress === '') return

    const auxSignPositions = [...signPositions],
      auxSignPositionsNotNull = []

    for (let signPosition of auxSignPositions) {
      if (signProofAddress === signPosition.user.temporalID)
        signPosition.user.isProofAddress = proofAddress
      else if (proofAddress === false) continue

      auxSignPositionsNotNull.push(signPosition)
    }

    setSignPositions([...auxSignPositionsNotNull])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proofAddress])

  useEffect(() => {
    if (refreshEndorse === null || signEndorse === '') return

    const auxSignPositions = [...signPositions],
      auxSignPositionsNotNull = []

    for (let signPosition of auxSignPositions) {
      if (signEndorse === signPosition.user.temporalID)
        if (!signPosition.user.endorsePosition)
          signPosition.user.isEndorse = refreshEndorse
        else if (refreshEndorse === false) continue

      auxSignPositionsNotNull.push(signPosition)
    }

    setSignPositions([...auxSignPositionsNotNull])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshEndorse])

  useEffect(() => {
    if (refreshVideoVerification === null || signVideoVerification === '')
      return

    const auxSignPositions = [...signPositions],
      auxSignPositionsNotNull = []

    for (let signPosition of auxSignPositions) {
      if (signVideoVerification === signPosition.user.temporalID)
        if (!signPosition.user.endorsePosition)
          signPosition.user.video = refreshVideoVerification
        else if (refreshVideoVerification === false) continue

      auxSignPositionsNotNull.push(signPosition)
    }

    setSignPositions([...auxSignPositionsNotNull])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshVideoVerification])

  useEffect(() => {
    const auxSignPositions = [...signPositions]

    for (let position of auxSignPositions) {
      if (userSelectBiometric === position.user.temporalID)
        position.user.biometric = biometricOption
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [biometricOption, userSelectBiometric])

  const handleClickEndorseBox = (info) => {
    let newA = []
    const adjustX = 77 * zoom
    const adjustY = 36.5 * zoom
    let codsX = 100 - adjustX
    let codsY = 50 - adjustY
    let actualPositions = signPositions

    let tempData = info.split('-')

    let currentUserData = {
      email: tempData[1],
      temporalID: tempData[2],
      name: tempData[0],
      biometric: false,
      uuid: '',
      isEndorse: false,
      endorseText,
      showEndorse: true,
      endorsePosition: { x: codsX, y: codsY },
    }

    newA = [
      ...actualPositions,
      {
        user: currentUserData,
        coordinates: { x: codsX, y: codsY },
        page,
        zoom,
      },
    ]

    setSignPositions(newA)
  }

  useEffect(() => {
    handleComproveSigns()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signPositions, sign, imgBoxState, newWidth, newHeigth, onBorder])

  useEffect(() => {
    //Recibe mediante el useEffect el dato actual a agregar
    const handleCurrentUpdate = (currentDrop) => {
      //Valida que haya un elemento valido arrastrable
      if (currentDrop.length !== 0) {
        //ajust centra la caja para un mejor control del drop
        const adjustX = 77 * zoom
        const adjustY = 36.5 * zoom
        let codsX = currentDrop[0].eX - adjustX
        let codsY = currentDrop[0].eY - adjustY
        let actualPositions = signPositions

        if (currentSelect !== '') {
          let newA = []
          for (let signPosition of signPositions) {
            if (
              `${signPosition.user.email}%PXY%${signPosition.page}-${signPosition.coordinates.x}-${signPosition.coordinates.y}` !==
              currentSelect
            )
              newA = [...newA, signPosition]
          }
          actualPositions = newA
          setCurrentSelect('')
        }
        let newA = [],
          currentUserData = {}

        if (currentDrop[0].newElement != null) {
          let newSign = currentDrop[0].newElement.id.trim()
          let tempData = newSign.split('-')

          if (tempData.length > 1) {
            currentUserData = {
              email: tempData[1],
              temporalID: tempData[2],
              name: tempData[0],
              biometric: false,
              uuid: '',
              isEndorse: false,
              endorseText,
              showEndorse: true,
              endorsePosition: { x: codsX, y: codsY },
            }
          } else {
            currentUserData = {
              email: tempData[0],
              temporalID: tempData[0],
              name: tempData[0],
              uuid: '',
              biometric: false,
            }
          }
        }

        for (let signPosition of signPositions)
          if (
            `${signPosition.user.email}%PXY%${signPosition.page}-${signPosition.coordinates.x}-${signPosition.coordinates.y}` ===
            currentSelect
          )
            currentUserData = {
              ...signPosition.user,
            }

        newA = [
          ...actualPositions,
          {
            user: currentUserData,
            coordinates: { x: codsX, y: codsY },
            page,
            zoom,
          },
        ]
        setSignPositions(newA)
        signPositionsDOM = newA
      }
    }

    handleCurrentUpdate(currentDrop)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDrop])

  const handleGetTemplate = async (uuidTemplate) => {
    const token = await getToken({
      audience: 'veridocid-dashboard',
    })
    // Template base64
    const getTemplateB64 = await getTemplateBase64(token, uuidTemplate)
    // Template data
    const resGetTemplate = await getTemplate(token, uuidTemplate)

    if (resGetTemplate && getTemplateB64) {
      if (resGetTemplate.error) signMessage.error(`${resGetTemplate.error}`)

      if (resGetTemplate.status === 200) {
        signMessage.info(`${resGetTemplate.message}`)

        setTemplateData(resGetTemplate.result)
        setFileBase64(getTemplateB64)

        if (resGetTemplate?.result?.document) {
          setOnBorder(resGetTemplate?.result?.document?.hasBorder)
          setSignBase64(resGetTemplate?.result?.document?.signLogo)
          setNewHeigth(resGetTemplate?.result?.document?.signSize?.height)
          setNewWidth(resGetTemplate?.result?.document?.signSize?.width)
        }

        let newA = []
        let userDataSign = {}
        let signOnlyMe = false,
          signMeAndOthers = false

        //Verify meAndOthers
        if (
          resGetTemplate.result.document.signs.length > 1 &&
          (resGetTemplate.result.document.signs[0]?.email === user?.email ||
            resGetTemplate.result.document.signs[0]?.email.includes(
              'sumamexico.com'
            ))
        ) {
          signMeAndOthers = true
        }

        // Verify onlyMe
        if (
          resGetTemplate.result.document.signs.length === 1 &&
          (resGetTemplate.result.document.signs[0]?.email === user?.email ||
            resGetTemplate.result.document.signs[0]?.email.includes(
              'sumamexico.com'
            ) ||
            resGetTemplate.result.document.signs[0]?.email.includes(
              'gmail.com'
            ))
        ) {
          signOnlyMe = true
        }

        for (let sign of resGetTemplate.result.document.signs) {
          //Si es yo no debe tener la biometria en true
          if (signOnlyMe) {
            userDataSign = {
              temporalID: sign.email,
              email: sign.email,
              name: sign.name,
              biometric: false,
              uuid: '',
              isLegalRepresentative: false,
              isEndorse: sign.isEndorse,
            }
          } else if (sign.email === user?.email && signMeAndOthers) {
            //Si es yo y otros no debe tener la biometria en true
            userDataSign = {
              temporalID: sign.email,
              email: sign.email,
              name: sign.name,
              biometric: false,
              uuid: '',
              isLegalRepresentative: false,
              isEndorse: sign.isEndorse,
            }
          } else {
            // Validación para que solo el primer firmante se muestre el endoso, solo Kavak
            if (
              sign?.email ===
                resGetTemplate.result.document.signs.at(0)?.email &&
              isAuthenticated &&
              roles?.includes('EndorseUser') &&
              clientID?.clientId === 'F0LDMiWc3mOlQjgtCLWIsA32U9og84UO'
            ) {
              userDataSign = {
                temporalID: sign.email,
                email: sign.email,
                name: sign.name,
                biometric: true,
                uuid: '',
                isLegalRepresentative: false,
                isEndorse: sign.isEndorse,
              }
            } else {
              userDataSign = {
                temporalID: sign.email,
                email: sign.email,
                name: sign.name,
                biometric: false,
                uuid: '',
                isLegalRepresentative: false,
                isEndorse: sign.isEndorse,
              }
            }
          }

          newA.push({
            user: userDataSign,
            coordinates: {
              x: sign.positionX,
              y: sign.positionY,
            },
            page: sign.page,
            zoom: 1,
          })
        }

        setSignPositions(newA)

        for (let sign of resGetTemplate.result.document
          .signsLegalRepresentative) {
          newA.push({
            user: {
              temporalID: sign.email,
              email: sign.email,
              name: sign.name,
              biometric: false,
              uuid: '',
              isLegalRepresentative: true,
            },
            coordinates: {
              x: sign.positionX,
              y: sign.positionY,
            },
            page: sign.page,
            zoom: 1,
          })
        }
        setSignPositions(newA)
        signPositionsDOM = newA

        const legalR = []
        for (let sign of resGetTemplate.result.document
          .signsLegalRepresentative) {
          legalR.push({
            user: {
              temporalID: sign.email,
              email: sign.email,
              name: sign.name,
              biometric: false,
              uuid: '',
            },
            coordinates: {
              x: sign.positionX,
              y: sign.positionY,
            },
            page: sign.page,
            zoom: 1,
          })
        }
        setSignsLegalRepresentative(legalR)
      }

      if (resGetTemplate.status !== 200 && !resGetTemplate.error) {
        signMessage.warning(resGetTemplate.message)
      }
    } else signMessage.error(`${t('notGetData')}`)

    setConsult(false)
  }

  useEffect(() => {
    if (consult) handleGetTemplate(uuidTemplate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consult, roles, clientID])

  if (consult) {
    return <Loading />
  }

  const onSearchUUID = async (val, nameSelect) => {
    if (!isEmpty(val)) {
      let btnIsDisabled = document.getElementById('btn_search_uuid')
      let updateData = document.getElementById(`${nameSelect} `)
      btnIsDisabled.disabled = true

      const token = await getToken({
        audience: 'veridocid-dashboard',
      })

      const dataVerification = await getVerificationSigner(token, val)

      if (isEmpty(dataVerification)) {
        signMessage.warning(`${t('notUuidFound')}: ${val}`)
      } else {
        if (dataVerification?.status === 400) {
          signMessage.error(`${t('notVerificationsAdd')}`)
        } else {
          //Add the uuid in the signer
          const auxSignPositions = [...signPositions]

          for (let signPosition of auxSignPositions) {
            if (signPosition?.user?.name === nameSelect) {
              //Add uuid
              signPosition.user.uuid = val
            }
            setSignPositions([...auxSignPositions])
          }
          //Update state of elemenst
          for (let signPosition of signPositions) {
            if (signPosition?.user?.name === nameSelect) {
              updateData.value = dataVerification?.name
              updateData.disabled = true
            }
          }
        }
      }
      btnIsDisabled.disabled = false
    } else {
      signMessage.error(`${t('necesaryInputUUID')}`)
      // Inputs
      let btnIsDisabled = document.getElementById('btn_search_uuid')
      let updateData = document.getElementById(`${nameSelect} `)
      updateData.value = ''
      updateData.disabled = false
      btnIsDisabled.disabled = false
    }
  }

  const viewPdf = !changePDF ? (
    <ViewPDF
      optionChange={true}
      setChangePDF={setChangePDF}
      fileBase64={fileBase64}
      setFileBase64={setFileBase64}
      page={page}
      setPage={setPage}
      totalPages={totalPages}
      setTotalPages={setTotalPages}
      zoom={zoom}
      setZoom={setZoom}
      pageSize={pageSize}
      handleRenderSuccess={handleRenderSuccess}
    />
  ) : (
    <Grid item xs={8}>
      <ChangePDF
        changePDF={changePDF}
        setFile={setFile}
        fileBase64={fileBase64}
        setFileBase64={setFileBase64}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        setTotalPages={setTotalPages}
        zoom={zoom}
        setZoom={setZoom}
        pageSize={pageSize}
        handleRenderSuccess={handleRenderSuccess}
        dd_dragstart={dd_dragstart}
        setEventDD={setEventDD}
        isFirstChange={isFirstChange}
        setIsFirstChange={setIsFirstChange}
      />
    </Grid>
  )

  return (
    <Container container>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Details>Firma a partir de plantilla</Details>
        <Button
          style={{
            color: '#fff',
            border: 'none',
            outline: 'none',
            padding: '0.6rem 0.9rem',
            borderRadius: '3px',
            background: 'var(--secondary-color)',
            width: '83px',
            height: '38px',
          }}
          onClick={() => history.goBack()}
        >
          Regresar
        </Button>
      </div>
      <InternalContainer container>
        {viewPdf}
        <UseTemplate
          t={t}
          handleEndorseText={handleEndorseText}
          handleClickEndorseBox={handleClickEndorseBox}
          handleVideoVerificationText={handleVideoVerificationText}
          totalPages={totalPages}
          file={file}
          fileBase64={fileBase64}
          changePDF={changePDF}
          templateData={templateData}
          user={user}
          isAuthenticated={isAuthenticated}
          roles={roles}
          clientID={clientID}
          sign={sign}
          signPositions={signPositions}
          setSignPositions={setSignPositions}
          signsLegalRepresentative={signsLegalRepresentative}
          whoSign={whoSign}
          geolocation={geolocation}
          setGeolocation={setGeolocation}
          ip={ip}
          setIP={setIP}
          setOpen={setOpen}
          pageSize={pageSize}
          zoom={zoom}
          boxSign={boxSign}
          onBorder={onBorder}
          dd_dragstart={dd_dragstart}
          newWidth={newWidth}
          newHeigth={newHeigth}
          signBase64={signBase64}
          uuidTemplate={uuidTemplate}
        />
        {open ? (
          <ModalSign open={open} setOpen={setOpen} setSign={setSign} />
        ) : null}
      </InternalContainer>
    </Container>
  )
}
