import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'
import { Button } from 'semantic-ui-react'

export const Container = styled(Grid)({
  width: '100%',
  height: '1000px',
  marginBottom: '110px',
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
})

export const Details = styled(Grid)({
  color: 'rgb(68, 68, 68)',
  fontSize: 'clamp(1.8em, 1.88em, 1.9em)',
})

export const InternalContainer = styled(Grid)({
  width: '100%',
  border: '0.5px solid #c8c8c8',
})

export const BtnReturn = styled(Button)({
  backgroundColor: 'var(--secondary-color)',
  border: 'none',
  outline: 'none',
  padding: '0.6rem 1rem',
  borderRadius: '3px',
  color: '#fff',
})
