const moment = require('moment-timezone');

export const getDate = (date) => {
  const timeNow = String(
    moment(date).tz("America/Mexico_City").format("YYYY-MM-DD HH:mm:ss")
  )
  return timeNow.substring(0,10)
};

export const getTime = (date) => {
  const timeNow = String(
    moment(date).tz("America/Mexico_City").format("YYYY-MM-DD HH:mm:ss")
  )
  return timeNow.substring(11,19)
};