import {
  IconButton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
} from '@mui/material'
import { Tag, Tooltip } from 'antd'
import { capitalize } from 'lodash'
import React from 'react'
import {
  VscArrowDown,
  VscArrowUp,
  VscCloudDownload,
  VscDesktopDownload,
  VscEye,
  VscMail,
  VscTrash,
} from 'react-icons/vsc'
import { Button } from 'semantic-ui-react'
import CopyIdPopup from '../CopyIdPopup'

const TableDesktop = (props) => {
  const {
    register,
    selected,
    t,
    format,
    history,
    selectCreatedAtA,
    selectCreatedAtD,
    downloadContract,
    downloadContractNom,
    mailForwarding,
    cancelContract,
    setOpenModalDP,
    setDocID,
  } = props

  const renderRegisters = (document, index) => {
    let getParticipantWithUUID
    if (document?.participants && document?.participants.length > 0) {
      getParticipantWithUUID = document?.participants.filter(
        (p) => p.uuid && p.uuid !== ''
      )
    }
    return (
      <TableRow key={index}>
        <TableCell
          className="body_cell_table_custom"
          style={{ cursor: 'pointer' }}
          onClick={
            document?.participants && document?.participants.length !== 0
              ? () =>
                  history.push(
                    `/resultado-contrato/${document?.documentID}/${document?.uuid}/${document?.id}`
                  )
              : () =>
                  history.push(
                    `/editar-contrato/${document?.documentID}/${document?.uuid}/${document?.id}`
                  )
          }
        >
          {document?.createdAt ? (
            <>
              {format(new Date(document?.createdAt), 'dd/MM/yyyy')}{' '}
              {format(new Date(document?.createdAt), 'HH:mm:ss')}
            </>
          ) : (
            <p>-</p>
          )}
        </TableCell>
        <TableCell className="body_cell_table_custom">
          <CopyIdPopup text={document?.documentID} copy={document?.documentID}>
            {document?.documentID}
          </CopyIdPopup>
        </TableCell>
        <TableCell
          className="body_cell_table_custom"
          style={{ cursor: 'pointer' }}
          onClick={
            document?.participants && document?.participants.length !== 0
              ? () =>
                  history.push(
                    `/resultado-contrato/${document?.documentID}/${document?.uuid}/${document?.id}`
                  )
              : () =>
                  history.push(
                    `/editar-contrato/${document?.documentID}/${document?.uuid}/${document?.id}`
                  )
          }
        >
          <p
            style={{
              width: '180px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              wordWrap: 'break-word',
            }}
          >
            {document?.documentName}
          </p>
        </TableCell>
        <TableCell className="body_cell_table_custom">
          <Tooltip
            placement="top"
            title={
              document?.participants && document?.participants.length > 0 ? (
                document?.participants &&
                document?.participants.map((p, i) => (
                  <p key={i}>
                    {p?.name} -
                    {p?.status === 'signed' ? 'Firmado' : 'Pendiente'}
                  </p>
                ))
              ) : (
                <p>Sin firmantes</p>
              )
            }
          >
            {document?.participants && document?.participants.length > 0 ? (
              document.participants
                .map((participant) => capitalize(participant?.name))
                .join(', ')
            ) : (
              <p>Seguir editando</p>
            )}
          </Tooltip>
        </TableCell>
        <TableCell className="body_cell_table_custom">
          {getParticipantWithUUID && getParticipantWithUUID.length > 0 ? (
            <Tooltip title={t('seeSignatoriesWithVerification')}>
              <IconButton
                aria-label="expand document"
                size="small"
                onClick={() => {
                  setOpenModalDP(true)
                  setDocID(document?.documentID)
                }}
              >
                <VscEye />
              </IconButton>
            </Tooltip>
          ) : (
            <p>-</p>
          )}
        </TableCell>
        <TableCell
          className="body_cell_table_custom"
          style={{ cursor: 'pointer' }}
          onClick={
            document?.participants && document?.participants.length !== 0
              ? () =>
                  history.push(
                    `/resultado-contrato/${document?.documentID}/${document?.uuid}/${document?.id}`
                  )
              : () =>
                  history.push(
                    `/editar-contrato/${document?.documentID}/${document?.uuid}/${document?.id}`
                  )
          }
        >
          {document?.status === 'valid' ? (
            <Tag color="orange">Pendiente</Tag>
          ) : document?.status === 'signed' ? (
            <Tag color="green">Firmado</Tag>
          ) : document?.status === 'approved' ? (
            <Tag color="lime">Aprobado</Tag>
          ) : document?.status === 'pending_approval' ? (
            <Tag color="purple">Aprobación pendiente</Tag>
          ) : (
            <Tag color="red">Cancelado</Tag>
          )}
        </TableCell>
        <TableCell className="body_cell_table_custom">
          {/* Download contract */}
          <Tooltip title="Descargar documento">
            <Button
              disabled={
                document?.participants.length === 0 ||
                document?.status === 'valid' ||
                document?.status === 'disabled' ||
                document?.status === 'pending_approval' ||
                document?.status === 'rejected' ||
                document?.status === 'approved'
                  ? true
                  : false
              }
              type="button"
              style={{
                color: '#2d2f6d',
                border: 'none',
                outline: 'none',
              }}
              className="download_contract"
              onClick={() =>
                downloadContract(document?.documentID, document?.uuid)
              }
            >
              <VscCloudDownload size={20} color={'#26285a'} strokeWidth={0.1} />
            </Button>
          </Tooltip>

          {/* Download nom */}
          <Tooltip title="Descargar la nom">
            <Button
              disabled={
                document?.participants.length === 0 ||
                document?.status === 'valid' ||
                document?.status === 'disabled' ||
                document?.status === 'pending_approval' ||
                document?.status === 'rejected' ||
                document?.status === 'approved'
                  ? true
                  : false
              }
              type="button"
              style={{
                color: '#2d2f6d',
                border: 'none',
                outline: 'none',
              }}
              className="download_contract"
              onClick={() =>
                downloadContractNom(document?.documentID, document?.uuid)
              }
            >
              <VscDesktopDownload
                size={18}
                color={'#26285a'}
                strokeWidth={0.1}
              />
            </Button>
          </Tooltip>

          {/* Mail forwarding */}
          <Tooltip title="Reenviar los correos">
            <Button
              disabled={
                document?.participants.length === 0 ||
                document?.status === 'signed' ||
                document?.status === 'disabled' ||
                document?.status === 'rejected'
                  ? true
                  : false
              }
              type="button"
              style={{
                color: '#2d2f6d',
                border: 'none',
                outline: 'none',
              }}
              className="download_contract"
              onClick={() => mailForwarding(document?.id, document?.documentID)}
            >
              <VscMail size={18} color={'#26285a'} strokeWidth={0.1} />
            </Button>
          </Tooltip>

          {/* Delete contract */}
          <Tooltip title="Cancelar contrato">
            <Button
              disabled={document?.status === 'valid' ? false : true}
              type="button"
              style={{
                color: '#2d2f6d',
                border: 'none',
                outline: 'none',
              }}
              className="download_contract"
              onClick={
                document?.status === 'valid'
                  ? () => cancelContract(document?.documentID)
                  : null
              }
            >
              <VscTrash size={20} color={'red'} />
            </Button>
          </Tooltip>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <>
      <TableContainer>
        <Table aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell
                className="head_table_custom"
                onClick={
                  selected.createdAtD
                    ? selectCreatedAtA
                    : selected.createdAtA
                    ? selectCreatedAtD
                    : selectCreatedAtD
                }
              >
                {t('date')}{' '}
                {selected.createdAtD ? (
                  <VscArrowDown size={13} strokeWidth={0.8} cursor="pointer" />
                ) : selected.createdAtA ? (
                  <VscArrowUp size={13} strokeWidth={0.8} cursor="pointer" />
                ) : null}
              </TableCell>
              <TableCell className="head_table_custom">
                {' '}
                {t('documentID')}
              </TableCell>
              <TableCell className="head_table_custom">
                {t('documentHeaderTable')}
              </TableCell>
              <TableCell className="head_table_custom">
                {t('contract_data__signatories')}
              </TableCell>
              <TableCell className="head_table_custom">
                {t('verificationHeaderTable')}
              </TableCell>
              <TableCell className="head_table_custom">{t('status')}</TableCell>
              <TableCell className="head_table_custom">
                {t('actions')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{register?.docs.map(renderRegisters)}</TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default TableDesktop
