import React from 'react'
import { VscChromeClose } from 'react-icons/vsc'
import { Button, Modal } from 'semantic-ui-react'
import { DocumentsErrorList } from '../../../DocumentsList.js'
import { BtnClose } from './Signature.jsx'

export const ModalListMasiveDocuments = ({
  isActiveModal = false,
  close,
  documentIDs,
  acceptOrchestratorProgress,
}) => (
  <Modal open={isActiveModal} className="accept-masive">
    <Modal.Header style={{ position: 'relative' }}>
      <h3 style={{ color: '#172b4d' }}>
        Los siguientes documentos no podrán ser firmados
      </h3>
      <BtnClose onClick={close}>
        <VscChromeClose size={15} strokeWidth={0.3} />
      </BtnClose>
    </Modal.Header>
    <Modal.Content>
      <DocumentsErrorList />
    </Modal.Content>
    <Modal.Actions>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        }}
      >
        {documentIDs && documentIDs.length > 0 ? (
          <Button
            content="Firmar"
            labelPosition="right"
            icon="checkmark"
            onClick={acceptOrchestratorProgress}
            positive
            id="btn-sdk-submit"
          />
        ) : (
          <p style={{ marginLeft: '10px', fontSize: '0.9rem' }}>
            No hay documentos para firmar
          </p>
        )}
      </div>
    </Modal.Actions>
  </Modal>
)
