import { Image } from 'antd'
import download from 'downloadjs'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Table } from 'semantic-ui-react'
import { API_DASHBOARD_URL, NMP_FINANCIERA_CLIENTS } from '../../../const'
import useToken from '../../../utils/useToken'

const CardOcrAddressWithInfo = ({
  dataOcrAddress,
  setIsOcrAddressVerified,
  setIsImageSelected,
  receiptFound,
  clientID,
}) => {
  const getToken = useToken()
  const { t } = useTranslation()
  const [receipt, setReceipt] = useState(null)
  let createdTime
  let documentResultsFields
  // let address
  let fullAddress
  let name
  let month
  let dateIssue
  let nameModel
  let period
  let isError
  let alternativeMonth
  let error

  const getReceipt = async ({ image, type }) => {
    try {
      const token = await getToken({
        audience: 'veridocid-dashboard',
      })

      const response = await fetch(
        `${API_DASHBOARD_URL}/files?filePath=${image}&type=${type}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )

      if (response.ok) {
        const b64 = await response.text()

        setReceipt(b64 === 'null' ? false : `data:image/png;base64,${b64}`)
      } else {
        const error = await response.json()
        console.error(error)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onRetryHandler = () => {
    setIsOcrAddressVerified(false)
    setIsImageSelected(false)
  }

  const filterObject = (obj, valueToFind) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([key, val]) => key === valueToFind)
    )
  }

  const getDateVerification = (dateOfVerification) => {
    let monthOfVerification = dateOfVerification?.getMonth()
    const year = dateOfVerification
      ?.getUTCFullYear()
      ?.toLocaleString('en-US', { minimumIntegerDigits: 4, useGrouping: false })
    const month =
      monthOfVerification === '11'
        ? '12'
        : (monthOfVerification += 1)?.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })
    const date = dateOfVerification
      ?.getUTCDate()
      ?.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })

    return `${year}-${month}-${date}`
  }
  const downloadButton = (source) => {
    if (receipt) download(source, `ocr-${dataOcrAddress?.uuid}`, 'image/png')
  }

  if (dataOcrAddress?.error) {
    isError = true

    switch (dataOcrAddress?.error) {
      case 'Could not detect the document type':
        error = 'No se pudo detectar el tipo de documento.'
        break
      case 'Document not supported, current supported documents are: cfe, telmex, izzi, totalplay':
        error =
          'Documento no soportado, los documentos soportados actuales son: cfe, telmex, izzi, totalplay'
        break
      default:
        error = dataOcrAddress?.error
    }
  } else {
    createdTime = new Date(dataOcrAddress?.data?.createdDateTime)

    if (dataOcrAddress?.data?.analyzeResult?.documentResults) {
      documentResultsFields =
        dataOcrAddress?.data?.analyzeResult?.documentResults?.map(
          (res) => res?.fields
        )
    } else {
      documentResultsFields =
        dataOcrAddress?.data?.analyzeResult?.documents?.map(
          (res) => res?.fields
        )
    }
    /* address = documentResultsFields?.map((resAddress) =>
      filterObject(resAddress, 'direccion')
    ) */
    name = documentResultsFields?.map((resName) =>
      filterObject(resName, 'nombre')
    )

    fullAddress = dataOcrAddress?.data?.fullAddress
    nameModel = dataOcrAddress?.data?.formNameModel

    dateIssue = documentResultsFields?.map((resDateIssue) =>
      filterObject(resDateIssue, 'fechaEmision')
    )

    month = documentResultsFields?.map((resMonth) =>
      filterObject(resMonth, 'mesFacturacion')
    )
    alternativeMonth = documentResultsFields?.map((resMonth) =>
      filterObject(resMonth, 'mesDeFacturacion')
    )
    period = documentResultsFields?.map((resPeriod) =>
      filterObject(resPeriod, 'periodoFacturado')
    )
    getReceipt({ image: receiptFound, type: 'ocr' })
  }

  const getOcrAddressInfo = () => (
    <>
      <Table color={'green'}>
        <Table.Body>
          {/* Create */}
          <Table.Row>
            <Table.Cell width={6}>
              <strong>{t('verificationDate')}:</strong>
            </Table.Cell>
            <Table.Cell>{getDateVerification(createdTime)}</Table.Cell>
          </Table.Row>
          {/* Name model */}
          {nameModel ? (
            <Table.Row>
              <Table.Cell width={6}>
                <strong>{t('proofNameModel')}:</strong>
              </Table.Cell>
              <Table.Cell>
                {nameModel === 'totaplay'
                  ? 'TOTALPLAY'
                  : nameModel.toUpperCase() ?? 'Comprobante no identificado'}
              </Table.Cell>
            </Table.Row>
          ) : null}
          {/* Name */}
          <Table.Row>
            <Table.Cell width={6}>
              <strong>{t('name')}:</strong>
            </Table.Cell>
            <Table.Cell>
              {name ? name[0]?.nombre?.content : 'Nombre no encontrado'}
            </Table.Cell>
          </Table.Row>
          {/* Period */}
          <Table.Row>
            <Table.Cell>
              <strong>
                {dateIssue !== undefined && !isEmpty(dateIssue[0])
                  ? t('proofDateIssue')
                  : t('proofAddressDate')}
                :
              </strong>
            </Table.Cell>
            <Table.Cell>
              {(period && period[0]?.periodoFacturado?.content) ??
                (month && month[0]?.mesFacturacion?.content) ??
                (alternativeMonth &&
                  alternativeMonth[0]?.mesDeFacturacion?.content) ??
                (dateIssue && dateIssue[0]?.fechaEmision?.content) ??
                'Fecha no encontrada'}
            </Table.Cell>
          </Table.Row>
          {/* Address */}
          <Table.Row>
            <Table.Cell>
              <strong>{t('address')}:</strong>
            </Table.Cell>
            <Table.Cell>
              {/* {address[0]?.direccion?.text ?? 'Dirección no encontrada'} */}
              {fullAddress
                ? fullAddress.split('^').map((e) => `${e} `)
                : 'Dirección no encontrada'}
            </Table.Cell>
          </Table.Row>
          {/* Proof */}
          <Table.Row>
            <Table.Cell>
              <strong>{t('proof')}:</strong>
            </Table.Cell>
            <Table.Cell>
              <Image src={receipt} width={'auto'} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      {receipt && NMP_FINANCIERA_CLIENTS.includes(clientID.clientId) ? (
        <div className="ButtonsCard">
          <Button onClick={() => downloadButton(receipt)}>
            {t('download')}
          </Button>
        </div>
      ) : null}
    </>
  )

  const getOcrAddressError = () => (
    <>
      <Table color={'red'}>
        <Table.Body>
          <Table.Row>
            <Table.Cell width={6}>
              <strong>Error:</strong>
            </Table.Cell>
            <Table.Cell>{error}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          content="Volver a intentar"
          icon="reply"
          labelPosition="left"
          onClick={onRetryHandler}
        />
      </div>
    </>
  )

  return isError ? getOcrAddressError() : getOcrAddressInfo()
}

export default CardOcrAddressWithInfo
