import { API_URL_V3, API_URL } from '../../const'

export const resetId = async (uuid, token) => {
  const response = await fetch(`${API_URL}/id/v3/resetVerificationSdk`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      uuid,
    }),
  })

  return response
}

export const resetService = async ({ uuid, token, type }) => {
  const url = `${API_URL_V3}/reset-verification-services`

  const response = await fetch(`${url}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      type,
      uuid,
    }),
  })

  const responseJson = await response.json()
  if (response.ok) {
    return { response: responseJson, status: response.status }
  } else {
    return { response: responseJson, status: response.status }
  }
}
