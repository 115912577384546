import React from 'react'
import { Grid } from '@mui/material'
import { Tooltip, Input } from 'antd'
import { Button } from 'semantic-ui-react'

export const FilterTable = ({
  t,
  templateID,
  changeTemplateID,
  templateName,
  changeTemplateName,
  templateTitle,
  changeTemplateTitle,
  onSearch,
  onClear,
  showFilters,
}) => (
  <>
    {showFilters && (
      <div
        style={{
          backgroundColor: '#F8F9FA',
          padding: '2rem 2rem 10px',
          marginBottom: '2rem',
        }}
      >
        {/* Form */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
            <span style={{ fontSize: '0.89rem', color: 'var(--black-color)' }}>
              {t('templateIDFilter')}
            </span>
            <Input
              placeholder="Ingrese el id de la plantilla"
              onChange={changeTemplateID}
              value={templateID}
              style={{ padding: '0.5rem 0.6rem', marginTop: '0.6rem' }}
              className="custom_input_form_search"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
            <span style={{ fontSize: '0.89rem', color: 'var(--black-color)' }}>
              {t('templateName')}
            </span>
            <Input
              placeholder="Ingrese el nombre de la plantilla"
              onChange={changeTemplateName}
              value={templateName}
              style={{ padding: '0.5rem 0.6rem', marginTop: '0.6rem' }}
              className="custom_input_form_search"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
            <span style={{ fontSize: '0.89rem', color: 'var(--black-color)' }}>
              {t('templateTitle')}
            </span>
            <Input
              placeholder="Ingrese el titulo de la plantilla"
              onChange={changeTemplateTitle}
              value={templateTitle}
              style={{ padding: '0.5rem 0.6rem', marginTop: '0.6rem' }}
              className="custom_input_form_search"
            />
          </Grid>
        </Grid>
        {/* Actions */}
        <Grid
          style={{
            margin: '20px 0px 10px 0px',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row-reverse',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <div>
            <Tooltip
              title={'Exporta los resultados en un archivo excel'}
              placement="bottom"
            ></Tooltip>
          </div>
          <div>
            <Tooltip
              title={'Una vez introducidos los valores, dale click para buscar'}
              placement="bottom"
            >
              <Button
                onClick={onSearch}
                className="btn_search_data"
                size="tiny"
              >
                {t('search')}
              </Button>
            </Tooltip>
          </div>
          <div>
            <Tooltip
              title={'Limpiar los filtros de búsqueda'}
              placement="bottom"
            >
              <Button onClick={onClear} className="btn_clear_data" size="tiny">
                {t('clear')}
              </Button>
            </Tooltip>
          </div>
        </Grid>
      </div>
    )}
  </>
)
