import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'
import { VscCheck } from 'react-icons/vsc'
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector'

//ModalSign
export const Close = styled(Grid)({
  position: 'relative',
  width: '100%',
  display: 'flex',
  // justifyContent: 'flex-end',
  // alignItems: 'center',
  padding: '0.8rem 0',
  border: '0',
  borderBottom: '1px solid #e6e6e6',
  marginBottom: '0.7rem',
})

export const BtnClose = styled(Grid)((props) => ({
  height: '100%',
  verticalAlign: 'middle',
  cursor: props.cursor ? props.cursor : 'pointer',
  position: 'absolute',
  top: '0',
  right: '0',
  padding: '1rem 1rem 0 1rem',
  borderRadius: '0 3px 0 0',
  color: '#ff4493',
  '&:hover': {
    backgroundColor: '#e8e8e8',
  },
}))

export const Title = styled(Grid)({
  width: '100%',
  textAlign: 'center',
  fontSize: 'clamp(1em, 1.55em, 1.6em)',
  color: '#172b4d',
  userSelect: 'none',
})

export const Content = styled(Grid)({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
  userSelect: 'none',
  rowGap: '18px',
  padding: '1rem 2rem',
  margin: '0 auto',
})

export const Steps = styled(Grid)({
  width: '100%',
  userSelect: 'none',
})

export const Options = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  width: '100%',
  padding: '0.6rem 0',
  // height: '50px',
})

export const Back = styled(Grid)((props) => ({
  minWidth: '25px',
  height: '25px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '100%',
  backgroundColor: props.bgcolor ? props.bgcolor : '#7777ff',
  marginRight: '20px',
  cursor: props.cursor ? props.cursor : 'pointer',
}))

export const MovilClose = styled(Grid)({
  minWidth: '25px',
  height: '25px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '100%',
  backgroundColor: '#FF6347',
  marginRight: '20px',
  cursor: 'pointer',
})

export const Next = styled(Grid)((props) => ({
  mr: 1,
  borderRadius: '10% / 50%',
  backgroundColor: props.bgcolor ? props.bgcolor : 'var(--secondary-color)',
  width: '110px',
  height: '25px',
  fontSize: '12px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#fff',
  cursor: props.cursor ? props.cursor : 'pointer',
  userSelect: 'none',
}))

export const StepsZone = styled(Grid)({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

// Custom step
export const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: 'var(--secondary-color)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: 'var(--secondary-color)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: 'var(--secondary-color)',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: 'var(--secondary-color)',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}))

export function QontoStepIcon(props) {
  const { active, completed, className } = props

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <VscCheck className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  )
}
