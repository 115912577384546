import React from 'react'
import { Divider } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { Collapse } from 'antd'

import ResultLabel from '../../UI/ResultLabel/ResultLabel'
import getDomainDetails from '../../../utils/getDomainDetails'
import DomainLabel from '../../UI/DomainLabel/DomainLabel'
import {
  getRegisteredPlatforms,
  getRegisteredPlatformsWithInfo,
  getUnregisteredPlatforms,
} from '../../../utils/getPlatforms'
import { getAppliedRules } from '../../../utils/getAppliedRules'

const { Panel } = Collapse

const CardEmailWithInfo = ({ dataEmail }) => {
  const { t } = useTranslation()
  const account_details = dataEmail?.account_details
  const domain_details = dataEmail?.domain_details
  const breach_details = dataEmail?.breach_details
  const breaches = breach_details?.breaches
  const applied_rules = dataEmail?.applied_rules
  const label =
    dataEmail?.score >= 21
      ? { backgroundColor: '#FDACAC', color: '#B04242', text: t('decline') }
      : dataEmail?.score >= 11
      ? { backgroundColor: '#FFEFD4', color: '#DDAD5E', text: t('check') }
      : { backgroundColor: '#60BDB2', color: '#FFFFFF', text: 'Ok' }

  const infoBreaches = breaches?.map((res, index) => (
    <div
      style={{ display: 'flex', flexDirection: 'column-reverse', width: '33%' }}
      key={index}
    >
      <p>{res?.date}</p>
      <p>{res?.domain || 'unknown'}</p>
    </div>
  ))

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div style={{ width: '100%' }}>
        <h3>
          <strong>{t('infoEmail')}</strong>
        </h3>
        <p>{dataEmail?.email}</p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            height: '2rem',
          }}
        >
          <h2 style={{ textAlign: 'center' }}>{dataEmail.score}/100</h2>
          <ResultLabel
            text={label?.text}
            backgroundColor={label?.backgroundColor}
            color={label?.color}
          />
        </div>
      </div>
      <Divider clearing />
      <div style={{ width: '100%' }}>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td style={{ width: '50%' }}>Deliverable</td>
              <td style={{ width: '50%' }}>
                {dataEmail?.deliverable ? 'Yes' : 'No'}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Divider clearing />
      {breaches?.length > 0 ? (
        <>
          <Collapse accordion>
            <Panel
              header={`Data breaches: ${breach_details?.number_of_breaches}`}
            >
              <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
                {infoBreaches}
              </div>
            </Panel>
          </Collapse>
        </>
      ) : (
        <div style={{ width: '100%' }}>
          <table style={{ width: '100%' }}>
            <tbody>
              <tr>
                <td style={{ width: '50%' }}>Data breaches</td>
                <td style={{ width: '50%' }}>0</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      <Divider clearing />
      <div style={{ width: '100%', height: 'auto' }}>
        <h3>
          <strong>{t('registeredPlatforms')}</strong>
        </h3>
        <div>{getRegisteredPlatformsWithInfo(account_details)}</div>
        <div
          style={{
            display: 'grid',
            gridAutoFlow: 'dense',
            gridTemplateColumns: 'repeat(auto-fill, minmax(14rem, 1fr))',
          }}
        >
          {getRegisteredPlatforms(account_details)}
        </div>
      </div>
      <Divider clearing />
      <div style={{ width: '100%', height: 'auto' }}>
        <h3>
          <strong>{t('unregisteredPlatforms')}</strong>
        </h3>
        <div
          style={{
            display: 'grid',
            gridAutoFlow: 'dense',
            gridTemplateColumns: 'repeat(auto-fill, minmax(14rem, 1fr))',
          }}
        >
          {getUnregisteredPlatforms(account_details)}
        </div>
      </div>
      {domain_details ? (
        <>
          <Divider clearing />
          <div style={{ width: '100%' }}>
            <h3>
              <strong>{t('domain')}</strong>
            </h3>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <DomainLabel
                text="Custom"
                color={domain_details?.custom ? '#60BDB2' : '#BDBDBD'}
                backgroundColor={domain_details?.custom ? '#DFF2F0' : '#F0F1F4'}
              />
              <DomainLabel
                text="Free"
                color={domain_details?.free ? '#60BDB2' : '#BDBDBD'}
                backgroundColor={domain_details?.free ? '#DFF2F0' : '#F0F1F4'}
              />
              <DomainLabel
                text="Disposable"
                color={domain_details?.disposable ? '#60BDB2' : '#BDBDBD'}
                backgroundColor={
                  domain_details?.disposable ? '#DFF2F0' : '#F0F1F4'
                }
              />
            </div>
            <br />
            <table style={{ width: '100%' }}>
              <tbody>{getDomainDetails(domain_details)}</tbody>
            </table>
          </div>
        </>
      ) : null}
      <Divider clearing />
      <div style={{ width: '100%', height: 'auto' }}>
        <h3>
          <strong>{t('applied_rules')}</strong>
        </h3>
        {getAppliedRules(applied_rules)}
      </div>
    </div>
  )
}

export default CardEmailWithInfo
