import { find, upperCase } from 'lodash'
import React, { Fragment, useEffect } from 'react'
import { Table } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import MobileStepper from '@mui/material/MobileStepper'
import Button from '@mui/material/Button'
import { VscChevronRight, VscChevronLeft } from 'react-icons/vsc'
import {
  responseVersion1,
  responseVersion2,
} from '../../../fixtures/dataResponse.fixture'
import moment from 'moment-timezone'
import { useAuth0 } from '../../../react-auth0-spa'

const CardAntecedentesWithInfo = ({ dataCourt, dataVerification }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [steps, setSteps] = React.useState([])
  const { user, isAuthenticated } = useAuth0()
  const [activeStep, setActiveStep] = React.useState(0)
  const [maxSteps, setMaxSteps] = React.useState(0)

  // Validations to criminal record only kavak
  const [clientID, setClientID] = React.useState({})
  let types, criminalRecordConditionKavak

  useEffect(() => {
    if (isAuthenticated)
      setClientID(user?.['https://vdid.sumamexico.com/app_metadata'] ?? {})
  }, [isAuthenticated, user])

  if (clientID?.clientId === 'F0LDMiWc3mOlQjgtCLWIsA32U9og84UO') {
    if (dataVerification?.data && dataCourt && dataCourt.length > 0) {
      const data = dataVerification?.data?.documentData

      if (Number(dataVerification?.dataVersion) >= 2) types = responseVersion2
      else types = responseVersion1

      let fullName =
        find(data, {
          type: types.fullName,
          source: types.visual,
        })?.value?.replace(/[\^]/g, ' ') || null
      const fullNameMrz = find(data, { type: types.fullName })?.value
      const dataName = find(data, { type: types.name })?.value?.replace(
        /[\^]/g,
        ' '
      )
      const fatherSurname = find(data, {
        type: types.fatherSurname,
        source: types.visual,
      })?.value
      const motherSurname = find(data, {
        type: types.motherSurname,
        source: types.visual,
      })?.value
      const surname = find(data, { type: 'Surname' })?.value
      const name = find(data, { type: 'Name' })?.value

      if (name && surname) {
        fullName = `${name} ${surname}`
      } else if (fatherSurname && motherSurname && dataName) {
        fullName = `${dataName} ${fatherSurname} ${motherSurname}`
      } else {
        fullName = fullNameMrz
      }

      /*eslint no-useless-escape: "off"*/
      let date = find(data, { type: types.dateOfBirth })?.value
      let dateofbirth = moment(date, 'DD-MM-YYYY').format('YYYY')

      // Si al filtrar encontramos que le nombre de la verificación coincide con el nombre del demandado el array tendra datos
      let coincidenceNameExpDemandado = dataCourt.filter((record) =>
        upperCase(
          record?.objExpediente?.exp_demandado?.replace(
            /[&\/\\#,+()$~%.'":*?<>{}]/g,
            ''
          )
        )?.includes(
          upperCase(fullName?.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''))
        )
      )

      // Si al filtrar encontramos que le nombre de la verificación coincide con el nombre del actor el array tendra datos
      let coincidenceNameExpActor = dataCourt.filter((record) =>
        upperCase(
          record?.objExpediente?.exp_actor?.replace(
            /[&\/\\#,+()$~%.'":*?<>{}]/g,
            ''
          )
        )?.includes(
          upperCase(fullName?.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''))
        )
      )

      // Si al filtrar encontramos demandas de la persona cuando tenia menos de 18 años el array tendra datos
      let coincidenceAge = dataCourt.filter(
        (record) =>
          +moment(record?.objExpediente?.exp_fecha).format('YYYY') -
            +dateofbirth <
          18
      )

      // Match resut
      if (
        (coincidenceNameExpDemandado?.length > 0 ||
          coincidenceNameExpActor?.length > 0) &&
        coincidenceAge.length === 0
      ) {
        criminalRecordConditionKavak = true
      } else {
        criminalRecordConditionKavak = false
      }
    } else {
      criminalRecordConditionKavak = true
    }
  } else {
    criminalRecordConditionKavak = true
  }

  useEffect(() => {
    let tempData = []

    dataCourt.map((e, index) =>
      tempData.push({
        description: (
          <Table color={dataCourt.length === 0 ? 'red' : 'green'}>
            <Table.Body key={index}>
              <Table.Row>
                <Table.Cell>
                  <strong>{t('date')}</strong>
                </Table.Cell>
                <Table.Cell>
                  {e?.objExpediente.exp_fecha
                    ? e?.objExpediente.exp_fecha
                    : t('noData')}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <strong>{t('recordNumber')}</strong>
                </Table.Cell>
                <Table.Cell>
                  {e?.objExpediente.exp_num_expediente
                    ? e?.objExpediente.exp_num_expediente
                    : t('noData')}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <strong>{t('year')}</strong>
                </Table.Cell>
                <Table.Cell>
                  {e?.objExpediente.exp_anio
                    ? e?.objExpediente.exp_anio
                    : t('noData')}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <strong>{t('state')}</strong>
                </Table.Cell>
                <Table.Cell>
                  {e?.objExpediente.exp_estado
                    ? e?.objExpediente.exp_estado
                    : t('noData')}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <strong>{t('city')}</strong>
                </Table.Cell>
                <Table.Cell>
                  {e?.objExpediente.exp_ciudad
                    ? e?.objExpediente.exp_ciudad
                    : t('noData')}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <strong>{t('court')}</strong>
                </Table.Cell>
                <Table.Cell>
                  {e?.objExpediente.exp_juzgado
                    ? e?.objExpediente.exp_juzgado
                    : t('noData')}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <strong>{t('op')}</strong>
                </Table.Cell>
                <Table.Cell>
                  {e?.objExpediente.exp_op
                    ? e?.objExpediente.exp_op
                    : t('noData')}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <strong>{t('toca')}</strong>
                </Table.Cell>
                <Table.Cell>
                  {e?.objExpediente.exp_toca
                    ? e?.objExpediente.exp_toca
                    : t('noData')}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <strong>{t('performer')}</strong>
                </Table.Cell>
                <Table.Cell>
                  {e?.objExpediente.exp_actor
                    ? e?.objExpediente.exp_actor
                    : t('noData')}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <strong>{t('defendant')}</strong>
                </Table.Cell>
                <Table.Cell>
                  {e?.objExpediente.exp_demandado
                    ? e?.objExpediente.exp_demandado
                    : t('noData')}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <strong>{t('type')}</strong>
                </Table.Cell>
                <Table.Cell>
                  {e?.objExpediente.exp_tipo
                    ? e?.objExpediente.exp_tipo
                    : t('noData')}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        ),
      })
    )

    setMaxSteps(tempData.length)
    setSteps(tempData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCourt])

  const handleNext = (page) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = (page) => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const chargeData = () => {
    return (
      <Box sx={{ flexGrow: 1 }}>
        {/* Stepper */}
        <MobileStepper
          variant="text"
          style={{ marginTop: '8px' }}
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={() => handleNext(activeStep)}
              disabled={activeStep === maxSteps - 1}
            >
              Siguiente
              {theme.direction === 'rtl' ? (
                <VscChevronLeft size={16} strokeWidth={1} />
              ) : (
                <VscChevronRight size={16} strokeWidth={1} />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={() => handleBack(activeStep)}
              disabled={activeStep === 0}
            >
              {theme.direction === 'rtl' ? (
                <VscChevronRight size={16} strokeWidth={1} />
              ) : (
                <VscChevronLeft size={16} strokeWidth={1} />
              )}
              Anterior
            </Button>
          }
        />
        {/* Information */}
        <Box sx={{ width: '100%' }}>{steps[activeStep]?.description}</Box>
      </Box>
    )
  }

  return (
    <Fragment>
      {dataCourt && dataCourt.length !== 0 ? (
        !criminalRecordConditionKavak ? (
          <p style={{ padding: '0.6rem 0' }}>{t('matchCriminalRecord')}</p>
        ) : (
          chargeData()
        )
      ) : (
        <p style={{ padding: '0.6rem 0' }}>{t('noDataAvailable')}</p>
      )}
    </Fragment>
  )
}

export default CardAntecedentesWithInfo
