import React from 'react'

import './VerificationResume.css'
import { labels } from '../../../../fixtures/panelExperts.fixture'

const VerificationResume = (props) => {
  const rows = props.response?.map((resp, index) => (
    <tr key={index}>
      <td className="VerificationResumeRows">
        <b>{resp?.name}</b>
      </td>
      <td className="VerificationResumeRows">{resp?.value}</td>
    </tr>
  ))

  return (
    <div className="VerificationResume">
      <table className="TableVerificationResume">
        <thead>
          <tr>
            <th className="ThVerificationResume" style={{ width: '25%' }}>
              <b>{labels.field}</b>
            </th>
            <th className="ThVerificationResume" style={{ width: '40%' }}>
              <b>{labels.value}</b>
            </th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  )
}

export default VerificationResume
