/**
 * Labels for carousel
 */

export const labels = {
  front: 'Anverso',
  back: 'Reverso',
  frontUltra: 'Anverso Ultravioleta',
  backUltra: 'Reverso Ultravioleta',
  frontInfra: 'Anverso Infrarrojo',
  backInfra: 'Reverso Infrarrojo',
  selectTemplate: 'Selecciona una plantilla',
}
