import React, { useState } from 'react'
import { Grid } from '@mui/material'
import {
  LegalRepresentantGrid,
  LegalRepresentantGridLeft,
  LegalRepresentantCard,
  LegalRepresentantCardInput,
} from './LegalRepresentantStyle'
import { VscAdd, VscEdit } from 'react-icons/vsc'
import { message as signMessage } from 'antd'
import { useTranslation } from 'react-i18next'

export default function LegalRepresentant(props) {
  const {
    legalRepresentant,
    setLegalRepresentant,
    signers,
    setSigners,
    signPositions,
    setSignPositions,
    whoSign,
    user,
  } = props
  const { t } = useTranslation()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')

  const handleName = (e) => setName(e.target.value)

  const handleEmail = (e) => setEmail(e.target.value)

  const handleAddLegalRepresentant = () => {
    if (name === '' || email === '') {
      signMessage.warning(`${t('completeDataForm')}`)
    } else {
      if (
        (user?.email === email && whoSign === 'me') ||
        (user?.email === email && whoSign === 'me_others') ||
        (signers[0]?.email === email && whoSign === 'me')
      ) {
        signMessage.warning(
          `El representante legal no puede tener el correo del mismo firmante`
        )
      } else {
        setSigners([
          ...signers,
          {
            name,
            email,
          },
        ])
        setLegalRepresentant({
          name,
          email,
        })
      }
    }
  }

  const handleChangeLegalRepresentant = () => {
    if (name === '' || email === '') {
      signMessage.warning(`${t('completeDataForm')}`)
    } else if (signers[0]?.email === email && whoSign === 'me') {
      signMessage.warning(
        `El representante legal no puede tener el correo del mismo firmante`
      )
    } else {
      let newSigners = []
      let newSignPositions = []

      for (let signer of signers) {
        if (signer.email !== legalRepresentant.email)
          newSigners.push({
            email: signer.email,
            name: signer.name,
          })
        else
          newSigners.push({
            email,
            name,
          })
      }
      setSigners(newSigners)

      for (let signPosition of signPositions) {
        if (signPosition.email !== legalRepresentant.email)
          newSignPositions.push(signPosition)
        else
          newSignPositions.push({
            email,
            name,
            page: signPosition.page,
            positionX: signPosition.positionX,
            positionY: signPosition.positionY,
            zoom: signPosition.zoom,
          })
      }
      setSignPositions(newSignPositions)

      setLegalRepresentant({
        name,
        email,
      })
    }
  }

  return (
    <>
      <LegalRepresentantGrid container>
        <LegalRepresentantGridLeft item xs={12} sm={12} md={12} lg={12} xl={10}>
          <span
            style={{
              fontSize: 'clamp(.9em,1em,1.1em)',
              color: 'var(--secondary-color)',
            }}
          >
            Representante Legal
          </span>
          <span
            style={{ fontSize: 'clamp(.5rem,.85rem,.9rem)' }}
            className="description_config_client"
          >
            Agregue al representante Legal
          </span>
        </LegalRepresentantGridLeft>
      </LegalRepresentantGrid>
      <LegalRepresentantCard item xs={12}>
        <label>Nombre</label>
        <LegalRepresentantCardInput
          type="text"
          value={name}
          onChange={handleName}
        />
        <label>Correo</label>
        <LegalRepresentantCardInput
          type="text"
          value={email}
          onChange={handleEmail}
        />
        <Grid container className="actions_add">
          {legalRepresentant.name === '' && legalRepresentant.email === '' ? (
            <button
              type="button"
              className="new_add"
              onClick={handleAddLegalRepresentant}
            >
              <VscAdd color="white" size={20} strokeWidth={1} />
            </button>
          ) : (
            <button
              type="button"
              className="new_add"
              onClick={handleChangeLegalRepresentant}
            >
              <VscEdit color="white" size={20} strokeWidth={1} />
            </button>
          )}
        </Grid>
      </LegalRepresentantCard>
    </>
  )
}
