import React from 'react'
import { Collapse, Table } from 'antd'
import { find } from 'lodash'
// import { creditColumns } from './columns'

const HistoryCreditWithInfo = ({ data }) => {
  const { Panel } = Collapse
  const { Column } = Table
  // console.log(data)

  const cuentaClient = find(data?.creditos, 'cuentaActual')?.cuentaActual

  return (
    <div className="content_show_data_credit">
      <div className="config_data_user">
        <p style={{ color: 'var(--gray-color)' }}>
          <strong>Folio de consulta:</strong> {data?.folioConsulta || '-'}
        </p>
        <p style={{ color: 'var(--gray-color)' }}>
          <strong>Clave de otorgante: </strong>
          {data?.claveOtorgante || '-'}
        </p>
        <Collapse
          bordered={false}
          defaultActiveKey={['1']}
          style={{
            marginTop: '2em',
            marginBottom: '1em',
          }}
        >
          {/*----------- Creditos -----------*/}
          {data?.creditos ? (
            <Panel
              style={{
                backgroundColor: 'rgb(238, 238, 238)',
                border: '0',
                marginBottom: '20px',
                borderRadius: '2px',
              }}
              header="Créditos"
              key="1"
              extra={
                <p style={{ color: 'var(--gray-color)' }}>
                  <strong>Cuenta Actual:</strong> {cuentaClient || '-'}
                </p>
              }
            >
              <Table
                // columns={creditColumns}
                dataSource={data?.creditos}
                scroll={{ x: 1200 }}
                // key={data?.creditos}
              >
                {/* Fecha de apertura */}
                <Column
                  title="Fecha de apertura"
                  dataIndex="fechaAperturaCuenta"
                  key="fechaAperturaCuenta"
                  render={(fechaApertura) => <>{fechaApertura || '-'}</>}
                />

                {/* Fecha de actualización */}
                <Column
                  title="Fecha de Actualización"
                  dataIndex="fechaActualizacion"
                  key="fechaActualizacion"
                  render={(fechaActualizacion) => (
                    <>{fechaActualizacion || '-'}</>
                  )}
                />

                {/* Fecha de cierre */}
                <Column
                  title="Fecha de cierre"
                  dataIndex="fechaCierreCuenta"
                  key="fechaCierreCuenta"
                  render={(fechaCierreCuenta) => (
                    <>{fechaCierreCuenta || '-'}</>
                  )}
                />

                {/* historicoPagos ----------- Falta interpretación */}
                {/* <Column
                  title="Historico de Pagos"
                  dataIndex="historicoPagos"
                  key="historicoPagos"
                  render={(historicoPagos) => <>{historicoPagos || '-'}</>}
                /> */}

                {/* Garantia */}
                <Column
                  title="Garantia"
                  dataIndex="garantia"
                  key="garantia"
                  render={(garantia) => <>{garantia || '-'}</>}
                />

                {/* Otorgante */}
                <Column
                  title="Otorgante"
                  dataIndex="nombreOtorgante"
                  key="nombreOtorgante"
                  render={(nombreOtorgante) => <>{nombreOtorgante || '-'}</>}
                />

                {/* Fecha de Reporte  otorgante*/}
                <Column
                  title="Fecha de Reporte - otorgante"
                  dataIndex="fechaReporte"
                  key="fechaReporte"
                  render={(fechaReporte) => <>{fechaReporte || '-'}</>}
                />

                {/* Tipo de crédito */}
                <Column
                  title="Tipo de crédito"
                  dataIndex="tipoCredito"
                  key="tipoCredito"
                  render={(tipoCredito) => (
                    <>
                      {tipoCredito === 'AA'
                        ? 'Arrendamiento Automotriz'
                        : tipoCredito === 'AB'
                        ? 'Automotriz Bancario'
                        : tipoCredito === 'AE'
                        ? 'Física Actividad Empresarial'
                        : tipoCredito === 'AM'
                        ? 'Aparatos/Muebles'
                        : tipoCredito === 'AR'
                        ? 'Arrendamiento'
                        : tipoCredito === 'AV'
                        ? 'Aviación'
                        : tipoCredito === 'BC'
                        ? 'Banca Comunal'
                        : tipoCredito === 'BL'
                        ? 'Bote/Lancha'
                        : tipoCredito === 'BR'
                        ? 'Bienes Raíces'
                        : tipoCredito === 'CA'
                        ? 'Compra De Automóvil'
                        : tipoCredito === 'CC'
                        ? 'Crédito Al Consumo'
                        : tipoCredito === 'CF'
                        ? 'Crédito Fiscal'
                        : tipoCredito === 'CO'
                        ? 'Consolidación'
                        : tipoCredito === 'CP'
                        ? 'Crédito Personal Al Consumo'
                        : tipoCredito === 'ED'
                        ? 'Editorial'
                        : tipoCredito === 'EQ'
                        ? 'Equipo'
                        : tipoCredito === 'FF'
                        ? 'Fondeo Fira'
                        : tipoCredito === 'FI'
                        ? 'Fianza'
                        : tipoCredito === 'FT'
                        ? 'Factoraje'
                        : tipoCredito === 'GS'
                        ? 'Grupo Solidario'
                        : tipoCredito === 'HB'
                        ? 'Hipotecario Bancario'
                        : tipoCredito === 'HE'
                        ? 'Préstamo Tipo Home Equity'
                        : tipoCredito === 'HV'
                        ? 'Hipotecario ó Vivienda'
                        : tipoCredito === 'LC'
                        ? 'Línea de Crédito'
                        : tipoCredito === 'MC'
                        ? 'Mejoras a la Casa'
                        : tipoCredito === 'NG'
                        ? 'Préstamo No Garantizado'
                        : tipoCredito === 'PB'
                        ? 'Préstamo Personal Bancario'
                        : tipoCredito === 'PC'
                        ? 'Procampo'
                        : tipoCredito === 'PE'
                        ? 'Préstamo Para Estudiante'
                        : tipoCredito === 'PG'
                        ? 'Préstamo Garantizado'
                        : tipoCredito === 'PQ'
                        ? 'Préstamo Quirografario'
                        : tipoCredito === 'PM'
                        ? 'Préstamo Empresarial'
                        : tipoCredito === 'PN'
                        ? 'Préstamo de Nómina'
                        : tipoCredito === 'PP'
                        ? 'Préstamo Personal'
                        : tipoCredito === 'SH'
                        ? 'Segunda Hipoteca'
                        : tipoCredito === 'TC'
                        ? 'Tarjeta De Crédito'
                        : tipoCredito === 'TD'
                        ? 'Tarjeta Departamental'
                        : tipoCredito === 'TG'
                        ? 'Tarjeta Garantizada'
                        : tipoCredito === 'TS'
                        ? 'Tarjeta De Servicios'
                        : tipoCredito === 'VR'
                        ? 'Vehículo Recreativo'
                        : tipoCredito === 'OT'
                        ? 'Otros'
                        : tipoCredito === 'NC'
                        ? 'Desconocido'
                        : '-'}
                    </>
                  )}
                />

                {/* Tipo de cuenta */}
                <Column
                  title="Tipo de cuenta"
                  dataIndex="tipoCuenta"
                  key="tipoCuenta"
                  render={(tipoCuenta) => (
                    <>
                      {tipoCuenta === 'F'
                        ? 'Pagos fijos'
                        : tipoCuenta === 'H'
                        ? 'Hipoteca'
                        : tipoCuenta === 'L'
                        ? 'Sin límite preestablecido'
                        : tipoCuenta === 'R'
                        ? 'Revolvente'
                        : tipoCuenta === 'Q'
                        ? 'Quirografario'
                        : tipoCuenta === 'A'
                        ? 'Crédito de habilitación o avío'
                        : tipoCuenta === 'E'
                        ? 'Crédito refaccionario'
                        : tipoCuenta === 'P'
                        ? 'Crédito prendario'
                        : '-'}
                    </>
                  )}
                />

                {/* Tipo de responsabilidad */}
                <Column
                  title="Tipo de responsabilidad"
                  dataIndex="tipoResponsabilidad"
                  key="tipoResponsabilidad"
                  render={(tipoResponsabilidad) => (
                    <>
                      {tipoResponsabilidad === 'I'
                        ? 'Individual (titular)'
                        : tipoResponsabilidad === 'M'
                        ? 'Mancomunado'
                        : tipoResponsabilidad === 'O'
                        ? 'Obligatorio Solidario'
                        : tipoResponsabilidad === 'A'
                        ? 'Aval'
                        : tipoResponsabilidad === 'T'
                        ? 'Titular con Aval'
                        : '-'}
                    </>
                  )}
                />

                {/* Crédito máximo */}
                <Column
                  title="Crédito máximo"
                  dataIndex="creditoMaximo"
                  key="creditoMaximo"
                  render={(creditoMaximo) => <>{creditoMaximo || '-'}</>}
                />

                {/* Límite de crédito */}
                <Column
                  title="Límite de crédito"
                  dataIndex="limiteCredito"
                  key="limiteCredito"
                  render={(limiteCredito) => <>{limiteCredito || '-'}</>}
                />

                {/* Saldo Actual */}
                <Column
                  title="Saldo Actual"
                  dataIndex="saldoActual"
                  key="saldoActual"
                  render={(saldoActual) => <>{saldoActual || '-'}</>}
                />

                {/* Saldo Vencido */}
                <Column
                  title="Saldo Vencido"
                  dataIndex="saldoVencido"
                  key="saldoVencido"
                  render={(saldoVencido) => <>{saldoVencido || '-'}</>}
                />

                {/* Fecha del Peor Atraso */}
                <Column
                  title="Fecha del Peor Atraso"
                  dataIndex="fechaPeorAtraso"
                  key="fechaPeorAtraso"
                  render={(fechaPeorAtraso) => <>{fechaPeorAtraso || '-'}</>}
                />

                {/* Saldo Vencido Peor Atraso */}
                <Column
                  title="Saldo Vencido Peor Atraso"
                  dataIndex="saldoVencidoPeorAtraso"
                  key="saldoVencidoPeorAtraso"
                  render={(saldoVencidoPeorAtraso) => (
                    <>{saldoVencidoPeorAtraso || '-'}</>
                  )}
                />

                {/* Fecha de la Último pago*/}
                <Column
                  title="Fecha del Último Pago"
                  dataIndex="fechaUltimoPago"
                  key="fechaUltimoPago"
                  render={(fechaUltimoPago) => <>{fechaUltimoPago || '-'}</>}
                />

                {/* Frecuencia de Pagos */}
                <Column
                  title="Frecuencia de Pagos"
                  dataIndex="frecuenciaPagos"
                  key="frecuenciaPagos"
                  render={(frecuenciaPagos) => (
                    <>
                      {frecuenciaPagos === 'A'
                        ? 'Anual'
                        : frecuenciaPagos === 'B'
                        ? 'Bimestral'
                        : frecuenciaPagos === 'C'
                        ? 'Catorcenal'
                        : frecuenciaPagos === 'D'
                        ? 'Deducción del Salario'
                        : frecuenciaPagos === 'E'
                        ? 'Semestral'
                        : frecuenciaPagos === 'M'
                        ? 'Mensual'
                        : frecuenciaPagos === 'Q'
                        ? 'Quincenal'
                        : frecuenciaPagos === 'R'
                        ? 'Pago Mínimo Para Cuentas Revolventes'
                        : frecuenciaPagos === 'S'
                        ? 'Semanal'
                        : frecuenciaPagos === 'T'
                        ? 'Trimestral'
                        : frecuenciaPagos === 'U'
                        ? 'Una Sola Exhibición'
                        : '-'}
                    </>
                  )}
                />

                {/* Número de pagos */}
                <Column
                  title="Número de Pagos"
                  dataIndex="numeroPagos"
                  key="numeroPagos"
                  render={(numeroPagos) => <>{numeroPagos || '-'}</>}
                />

                {/* Cantidad de Pagos Incumplidos */}
                <Column
                  title="Cantidad de Pagos Incumplidos"
                  dataIndex="peorAtraso"
                  key="peorAtraso"
                  render={(peorAtraso) => <>{peorAtraso || '-'}</>}
                />

                {/* Número de pagos vencidos */}
                <Column
                  title="Número de Pagos Vencidos"
                  dataIndex="numeroPagosVencidos"
                  key="numeroPagosVencidos"
                  render={(numeroPagosVencidos) => (
                    <>{numeroPagosVencidos || '-'}</>
                  )}
                />

                {/* Fecha de la Última Compra */}
                <Column
                  title="Fecha de la Última Compra"
                  dataIndex="fechaUltimaCompra"
                  key="fechaUltimaCompra"
                  render={(fechaUltimaCompra) => (
                    <>{fechaUltimaCompra || '-'}</>
                  )}
                />

                {/* Pago Actual */}
                <Column
                  title="Pago Actual"
                  dataIndex="pagoActual"
                  key="pagoActual"
                  render={(pagoActual) => <>{pagoActual || '-'}</>}
                />

                {/* Total de pagos reportados */}
                <Column
                  title="Total de Pagos Reportados"
                  dataIndex="totalPagosReportados"
                  key="totalPagosReportados"
                  render={(totalPagosReportados) => (
                    <>{totalPagosReportados || '-'}</>
                  )}
                />

                {/* Valor de Activo Valuacion */}
                <Column
                  title="Valor de Activo Valuación"
                  dataIndex="valorActivoValuacion"
                  key="valorActivoValuacion"
                  render={(valorActivoValuacion) => (
                    <>{valorActivoValuacion || '-'}</>
                  )}
                />

                {/* Registro impugnado */}
                <Column
                  title="Registor Impugnado"
                  dataIndex="registroImpugnado"
                  key="registroImpugnado"
                  render={(registroImpugnado) => (
                    <>{registroImpugnado || '-'}</>
                  )}
                />

                {/* Clave de prevenión */}
                <Column
                  title="Prevención"
                  dataIndex="clavePrevencion"
                  key="clavePrevencion"
                  width={200}
                  render={(clavePrevencion) => (
                    <>
                      {clavePrevencion === 'AD'
                        ? 'Cuenta o monto en aclaración'
                        : clavePrevencion === 'CA'
                        ? 'Cartera al Corriente Vendida o cedida a un usuario de una Sociedad'
                        : clavePrevencion === 'CC'
                        ? 'Cuenta cancelada o cerrada'
                        : clavePrevencion === 'CD'
                        ? 'Convenio y disminución de pago'
                        : clavePrevencion === 'CL'
                        ? 'Cuenta cerrada que estuvo en cobranza y fue pagada totalmente sin causar quebranto'
                        : clavePrevencion === 'CO'
                        ? 'Crédito en Controversia'
                        : clavePrevencion === 'CV'
                        ? 'Cuenta que no está al corriente vendida o cedida a un usuario de una Sociedad'
                        : clavePrevencion === 'FD'
                        ? 'Cuenta Fraudulenta'
                        : clavePrevencion === 'FN'
                        ? 'Fraude no Atribuible al Consumidor'
                        : clavePrevencion === 'FP'
                        ? 'Fianza pagada'
                        : clavePrevencion === 'FR'
                        ? 'Adjudicación o aplicación de garantía'
                        : clavePrevencion === 'GP'
                        ? 'Ejecución de Garantía Prendaria o Fiduciaria en Pago por Crédito'
                        : clavePrevencion === 'IA'
                        ? 'Cuenta Inactiva'
                        : clavePrevencion === 'IM'
                        ? 'Integrante causante de mora'
                        : clavePrevencion === 'IS'
                        ? 'Integrante que fue subsidiado para evitar mora'
                        : clavePrevencion === 'LC'
                        ? 'Convenio de finiquito o pago menor acordado con el Consumidor'
                        : clavePrevencion === 'LG'
                        ? 'Pago menor por programa institucional o de gobierno, incluyendo los apoyos a damnificados por catástrofes naturales'
                        : clavePrevencion === 'LO'
                        ? 'En Localización'
                        : clavePrevencion === 'LS'
                        ? 'Tarjeta de Crédito Extraviada o Robada'
                        : clavePrevencion === 'NA'
                        ? 'Cuenta al corriente vendida o cedida a un NO Usuario de una Sociedad'
                        : clavePrevencion === 'NV'
                        ? 'Cuenta que no está al corriente vendida o cedida a un NO Usuario de una Sociedad'
                        : clavePrevencion === 'PC'
                        ? 'Cuenta en Cobranza'
                        : clavePrevencion === 'PD'
                        ? 'Prórroga otorgada debido a un desastre natural'
                        : clavePrevencion === 'PE'
                        ? 'Prórroga otorgada al acreditado por situaciones especiales'
                        : clavePrevencion === 'PI'
                        ? 'Prórroga otorgada al acreditado por invalidez, defunción'
                        : clavePrevencion === 'PR'
                        ? 'Prórroga otorgada debido a una pérdida de relación laboral'
                        : clavePrevencion === 'RA'
                        ? 'Cuenta reestructurada sin pago menor, por programa institucional o gubernamental, incluyendo los apoyos a damnificados por catástrofes naturales'
                        : clavePrevencion === 'RI'
                        ? 'Robo de identidad'
                        : clavePrevencion === 'RF'
                        ? 'Resolución judicial favorable al cliente'
                        : clavePrevencion === 'RN'
                        ? 'Cuenta reestructurada debido a un proceso judicial'
                        : clavePrevencion === 'RV'
                        ? 'Cuenta reestructurada sin pago menor por modificación de la situación del cliente, a petición de éste'
                        : clavePrevencion === 'SG'
                        ? 'Demanda por el Otorgante'
                        : clavePrevencion === 'UP'
                        ? 'Cuenta que causa quebranto'
                        : clavePrevencion === 'VR'
                        ? 'Dación en Pagos ó Renta'
                        : '-'}
                    </>
                  )}
                />

                {/* Unidad monetaria */}
                <Column
                  title="Unidad monetaria"
                  dataIndex="claveUnidadMonetaria"
                  key="claveUnidadMonetaria"
                  render={(claveUnidadMonetaria) => (
                    <>
                      {claveUnidadMonetaria === 'MX'
                        ? 'Pesos Mexicanos'
                        : claveUnidadMonetaria === 'US'
                        ? 'Dólares'
                        : claveUnidadMonetaria === 'UD'
                        ? 'Unidades de Inversión'
                        : '-'}
                    </>
                  )}
                />
              </Table>
            </Panel>
          ) : null}

          {/*----------- Consultas -----------*/}
          {data?.consultas ? (
            <Panel
              header="Consultas"
              key="2"
              style={{
                backgroundColor: 'rgb(238, 238, 238)',
                border: '0',
                marginBottom: '20px',
                borderRadius: '2px',
              }}
            >
              <Table dataSource={data?.consultas} scroll={{ x: 1000 }}>
                {/* Fecha de consulta */}
                <Column
                  title="Fecha de consulta"
                  dataIndex="fechaConsulta"
                  key="fechaConsulta"
                  render={(fechaConsulta) => <>{fechaConsulta || '-'}</>}
                />
                {/* Otorgante */}
                <Column
                  title="Otorgante"
                  dataIndex="nombreOtorgante"
                  key="nombreOtorgante"
                  render={(nombreOtorgante) => <>{nombreOtorgante || '-'}</>}
                />
                {/* Teléfono del Otorgante */}
                <Column
                  title="Teléfono del Otorgante"
                  dataIndex="telefonoOtorgante"
                  key="telefonoOtorgante"
                  render={(telefonoOtorgante) => (
                    <>{telefonoOtorgante || '-'}</>
                  )}
                />
                {/* Dirección del Otorgante */}
                <Column
                  title="Dirección del Otorgante"
                  dataIndex="direccionOtorgante"
                  key="direccionOtorgante"
                  render={(direccionOtorgante) => (
                    <>{direccionOtorgante || '-'}</>
                  )}
                />
                {/* Monto solicitado */}
                <Column
                  title="Monto solicitado"
                  dataIndex="importeCredito"
                  key="importeCredito"
                  render={(importeCredito) => <>{importeCredito || '-'}</>}
                />

                {/* Tipo de crédito */}
                <Column
                  title="Tipo de crédito"
                  dataIndex="tipoCredito"
                  key="tipoCredito"
                  render={(tipoCredito) => (
                    <>
                      {tipoCredito === 'AA'
                        ? 'Arrendamiento Automotriz'
                        : tipoCredito === 'AB'
                        ? 'Automotriz Bancario'
                        : tipoCredito === 'AE'
                        ? 'Física Actividad Empresarial'
                        : tipoCredito === 'AM'
                        ? 'Aparatos/Muebles'
                        : tipoCredito === 'AR'
                        ? 'Arrendamiento'
                        : tipoCredito === 'AV'
                        ? 'Aviación'
                        : tipoCredito === 'BC'
                        ? 'Banca Comunal'
                        : tipoCredito === 'BL'
                        ? 'Bote/Lancha'
                        : tipoCredito === 'BR'
                        ? 'Bienes Raíces'
                        : tipoCredito === 'CA'
                        ? 'Compra De Automóvil'
                        : tipoCredito === 'CC'
                        ? 'Crédito Al Consumo'
                        : tipoCredito === 'CF'
                        ? 'Crédito Fiscal'
                        : tipoCredito === 'CO'
                        ? 'Consolidación'
                        : tipoCredito === 'CP'
                        ? 'Crédito Personal Al Consumo'
                        : tipoCredito === 'ED'
                        ? 'Editorial'
                        : tipoCredito === 'EQ'
                        ? 'Equipo'
                        : tipoCredito === 'FF'
                        ? 'Fondeo Fira'
                        : tipoCredito === 'FI'
                        ? 'Fianza'
                        : tipoCredito === 'FT'
                        ? 'Factoraje'
                        : tipoCredito === 'GS'
                        ? 'Grupo Solidario'
                        : tipoCredito === 'HB'
                        ? 'Hipotecario Bancario'
                        : tipoCredito === 'HE'
                        ? 'Préstamo Tipo Home Equity'
                        : tipoCredito === 'HV'
                        ? 'Hipotecario ó Vivienda'
                        : tipoCredito === 'LC'
                        ? 'Línea de Crédito'
                        : tipoCredito === 'MC'
                        ? 'Mejoras a la Casa'
                        : tipoCredito === 'NG'
                        ? 'Préstamo No Garantizado'
                        : tipoCredito === 'PB'
                        ? 'Préstamo Personal Bancario'
                        : tipoCredito === 'PC'
                        ? 'Procampo'
                        : tipoCredito === 'PE'
                        ? 'Préstamo Para Estudiante'
                        : tipoCredito === 'PG'
                        ? 'Préstamo Garantizado'
                        : tipoCredito === 'PQ'
                        ? 'Préstamo Quirografario'
                        : tipoCredito === 'PM'
                        ? 'Préstamo Empresarial'
                        : tipoCredito === 'PN'
                        ? 'Préstamo de Nómina'
                        : tipoCredito === 'PP'
                        ? 'Préstamo Personal'
                        : tipoCredito === 'SH'
                        ? 'Segunda Hipoteca'
                        : tipoCredito === 'TC'
                        ? 'Tarjeta De Crédito'
                        : tipoCredito === 'TD'
                        ? 'Tarjeta Departamental'
                        : tipoCredito === 'TG'
                        ? 'Tarjeta Garantizada'
                        : tipoCredito === 'TS'
                        ? 'Tarjeta De Servicios'
                        : tipoCredito === 'VR'
                        ? 'Vehículo Recreativo'
                        : tipoCredito === 'OT'
                        ? 'Otros'
                        : tipoCredito === 'NC'
                        ? 'Desconocido'
                        : '-'}
                    </>
                  )}
                />
                {/* Tipo de responsabilidad */}
                <Column
                  title="Tipo de responsabilidad"
                  dataIndex="tipoResponsabilidad"
                  key="tipoResponsabilidad"
                  render={(tipoResponsabilidad) => (
                    <>
                      {tipoResponsabilidad === 'I'
                        ? 'Individual (titular)'
                        : tipoResponsabilidad === 'M'
                        ? 'Mancomunado'
                        : tipoResponsabilidad === 'O'
                        ? 'Obligatorio Solidario'
                        : tipoResponsabilidad === 'A'
                        ? 'Aval'
                        : tipoResponsabilidad === 'T'
                        ? 'Titular con Aval'
                        : '-'}
                    </>
                  )}
                />
                {/* Unidad monetaria */}
                <Column
                  title="Unidad monetaria"
                  dataIndex="claveUnidadMonetaria"
                  key="claveUnidadMonetaria"
                  render={(claveUnidadMonetaria) => (
                    <>
                      {claveUnidadMonetaria === 'MX'
                        ? 'Pesos Mexicanos'
                        : claveUnidadMonetaria === 'US'
                        ? 'Dólares'
                        : claveUnidadMonetaria === 'UD'
                        ? 'Unidades de Inversión'
                        : '-'}
                    </>
                  )}
                />
              </Table>
            </Panel>
          ) : null}

          {/*----------- Domicilios -----------*/}
          {data?.domicilios ? (
            <Panel
              header="Domicilios"
              key="3"
              style={{
                backgroundColor: 'rgb(238, 238, 238)',
                border: '0',
                marginBottom: '20px',
                borderRadius: '2px',
              }}
            >
              <Table dataSource={data?.domicilios} scroll={{ x: 1000 }}>
                {/* Fecha de Registro de Domicilio */}
                <Column
                  title="Registro de Domicilio"
                  dataIndex="fechaRegistroDomicilio"
                  key="fechaRegistroDomicilio"
                  render={(fechaRegistroDomicilio) => (
                    <>{fechaRegistroDomicilio || '-'}</>
                  )}
                />
                {/* Fecha de residencia */}
                <Column
                  title="Registro de Residencia"
                  dataIndex="fechaResidencia"
                  key="fechaResidencia"
                  render={(fechaResidencia) => <>{fechaResidencia || '-'}</>}
                />
                {/* Número de telefono */}
                <Column
                  title="Número de teléfono"
                  dataIndex="numeroTelefono"
                  key="numeroTelefono"
                  render={(numeroTelefono) => <>{numeroTelefono || '-'}</>}
                />
                {/* Dirección */}
                <Column
                  title="Dirección"
                  dataIndex="direccion"
                  key="direccion"
                  render={(direccion) => <>{direccion || '-'}</>}
                />
                {/* Municipio */}
                <Column
                  title="Municipio"
                  dataIndex="delegacionMunicipio"
                  key="delegacionMunicipio"
                  render={(delegacionMunicipio) => (
                    <>{delegacionMunicipio || '-'}</>
                  )}
                />
                {/* Colonia */}
                <Column
                  title="Colonia"
                  dataIndex="coloniaPoblacion"
                  key="coloniaPoblacion"
                  render={(coloniaPoblacion) => <>{coloniaPoblacion || '-'}</>}
                />
                {/* Ciudad */}
                <Column
                  title="Ciudad"
                  dataIndex="ciudad"
                  key="ciudad"
                  render={(ciudad) => <>{ciudad || '-'}</>}
                />
                {/* Estado */}
                <Column
                  title="Estado"
                  dataIndex="estado"
                  key="estado"
                  render={(estado) => (
                    <>
                      {estado === 'AGS'
                        ? 'AGUASCALIENTES'
                        : estado === 'BC'
                        ? 'BAJA CALIFORNIA NORTE'
                        : estado === 'BCS'
                        ? 'BAJA CALIFORNIA SUR'
                        : estado === 'CAMP'
                        ? 'CAMPECHE'
                        : estado === 'CDMX'
                        ? 'CIUDAD DE MÉXICO'
                        : estado === 'CHIS'
                        ? 'CHIAPAS'
                        : estado === 'CHIH'
                        ? 'CHIHUAHUA'
                        : estado === 'COAH'
                        ? 'COHAHUILA'
                        : estado === 'COL'
                        ? 'COLIMA'
                        : estado === 'DF'
                        ? 'DISTRITO FEDERAL'
                        : estado === 'DGO'
                        ? 'DURANGO'
                        : estado === 'GTO'
                        ? 'GUANAJUATO'
                        : estado === 'GRO'
                        ? 'GUERRERO'
                        : estado === 'HGO'
                        ? 'HIDALGO'
                        : estado === 'JAL'
                        ? 'JALISCO'
                        : estado === 'MEX'
                        ? 'MÉXICO'
                        : estado === 'MICH'
                        ? 'MICHOCÁN'
                        : estado === 'MOR'
                        ? 'MORELOS'
                        : estado === 'NAY'
                        ? 'NAYARIT'
                        : estado === 'NL'
                        ? 'NUEVO LEÓN'
                        : estado === 'OAX'
                        ? 'OXACA'
                        : estado === 'PUE'
                        ? 'PUEBLA'
                        : estado === 'QRO'
                        ? 'QUERÉTARO'
                        : estado === 'QROO'
                        ? 'QUINTANA ROO'
                        : estado === 'SLP'
                        ? 'SAN LUIS POTOSÍ'
                        : estado === 'SIN'
                        ? 'SINALOA'
                        : estado === 'SON'
                        ? 'SONORA'
                        : estado === 'TAB'
                        ? 'TABASCO'
                        : estado === 'TAMP'
                        ? 'TAMAULIPAS'
                        : estado === 'TLAX'
                        ? 'TLAXCALA'
                        : estado === 'VER'
                        ? 'VERACRUZ'
                        : estado === 'YUC'
                        ? 'YUCATÁN'
                        : estado === 'ZAC'
                        ? 'ZACATECAS'
                        : '-'}
                    </>
                  )}
                />
                {/* Código Postal */}
                <Column
                  title="Código Postal"
                  dataIndex="CP"
                  key="CP"
                  render={(CP) => <>{CP || '-'}</>}
                />
                {/* Tipo de Asentamiento */}
                <Column
                  title="Tipo de Asentamiento"
                  dataIndex="tipoAsentamiento"
                  key="tipoAsentamiento"
                  render={(tipoAsentamiento) => (
                    <>
                      {tipoAsentamiento === '0'
                        ? 'PENDIENTE POR ACTUALIZAR'
                        : tipoAsentamiento === '1'
                        ? 'AEROPUERTO'
                        : tipoAsentamiento === '2'
                        ? 'BARRIO'
                        : tipoAsentamiento === '3'
                        ? 'BASE NAVAL'
                        : tipoAsentamiento === '4'
                        ? 'CAMPAMENTO'
                        : tipoAsentamiento === '5'
                        ? 'CENTRO'
                        : tipoAsentamiento === '6'
                        ? 'CENTRO URBANO'
                        : tipoAsentamiento === '7'
                        ? 'COLONIA'
                        : tipoAsentamiento === '8'
                        ? 'CONDOMINIO'
                        : tipoAsentamiento === '9'
                        ? 'CONGREGACION'
                        : tipoAsentamiento === '10'
                        ? 'CONJUNTO HABITACIONAL'
                        : tipoAsentamiento === '11'
                        ? 'CONJUNTO HABITACIONAL RESIDENCIAL'
                        : tipoAsentamiento === '12'
                        ? 'CONJUNTO HABITACIONAL URBANO'
                        : tipoAsentamiento === '13'
                        ? 'CONJUNTO POPULAR'
                        : tipoAsentamiento === '14'
                        ? 'CONJUNTO RESIDENCIAL'
                        : tipoAsentamiento === '15'
                        ? 'CONJUNTO URBANO'
                        : tipoAsentamiento === '16'
                        ? 'CONJUNTO URBANO POPULAR'
                        : tipoAsentamiento === '17'
                        ? 'EJIDO'
                        : tipoAsentamiento === '18'
                        ? 'ESCUELA'
                        : tipoAsentamiento === '19'
                        ? 'ESTACION DE RADIO'
                        : tipoAsentamiento === '20'
                        ? 'EX-HACIENDA'
                        : tipoAsentamiento === '21'
                        ? 'EX-RANCHO'
                        : tipoAsentamiento === '22'
                        ? 'FABRICA'
                        : tipoAsentamiento === '23'
                        ? 'FINCA'
                        : tipoAsentamiento === '24'
                        ? 'FRACCIONAMIENTO'
                        : tipoAsentamiento === '25'
                        ? 'FRACCIONAMIENTO INDUSTRIAL'
                        : tipoAsentamiento === '26'
                        ? 'FRACCIONAMIENTO RESIDENCIAL'
                        : tipoAsentamiento === '27'
                        ? 'GRANJA'
                        : tipoAsentamiento === '28'
                        ? 'HACIENDA'
                        : tipoAsentamiento === '29'
                        ? 'INGENIO'
                        : tipoAsentamiento === '30'
                        ? 'JUNTA AUXILIA'
                        : tipoAsentamiento === '31'
                        ? 'MODULO HABIT'
                        : tipoAsentamiento === '32'
                        ? 'PARQUE'
                        : tipoAsentamiento === '33'
                        ? 'PARQUE INDUS'
                        : tipoAsentamiento === '34'
                        ? 'CUADRILLA'
                        : tipoAsentamiento === '35'
                        ? 'POBLADO COM'
                        : tipoAsentamiento === '36'
                        ? 'PUEBLO'
                        : tipoAsentamiento === '37'
                        ? 'RANCHO O RAN'
                        : tipoAsentamiento === '38'
                        ? 'RESIDENCIAL'
                        : tipoAsentamiento === '39'
                        ? 'VILLA'
                        : tipoAsentamiento === '40'
                        ? 'UNIDAD HABIT'
                        : tipoAsentamiento === '41'
                        ? 'ZONA FEDERAL'
                        : tipoAsentamiento === '42'
                        ? 'ZONA HABITAC'
                        : tipoAsentamiento === '43'
                        ? 'ZONA INDUSTR'
                        : tipoAsentamiento === '44'
                        ? 'ZONA RESIDEN'
                        : tipoAsentamiento === '45'
                        ? 'ZONA URBANA'
                        : tipoAsentamiento === '46'
                        ? 'ZONA URBANA'
                        : tipoAsentamiento === '47'
                        ? 'CAMPO MILITA'
                        : tipoAsentamiento === '48'
                        ? 'VIVIENDA POPU'
                        : tipoAsentamiento === '49'
                        ? 'CLUB DE GOLF'
                        : tipoAsentamiento === '50'
                        ? 'COOPERATIVA'
                        : tipoAsentamiento === '51'
                        ? 'CIUDAD'
                        : tipoAsentamiento === '52'
                        ? 'OFICINA DE CO'
                        : tipoAsentamiento === '53'
                        ? 'GRAN USUARIO'
                        : tipoAsentamiento === '54'
                        ? 'ZONA COMERC'
                        : tipoAsentamiento === '55'
                        ? 'ZONA RURAL'
                        : '-'}
                    </>
                  )}
                />
                {/* Tipo de Domicilio */}
                <Column
                  title="Tipo de Domicilio"
                  dataIndex="tipoDomicilio"
                  key="tipoDomicilio"
                  render={(tipoDomicilio) => (
                    <>
                      {tipoDomicilio === 'N'
                        ? 'Negocio'
                        : tipoDomicilio === 'O'
                        ? 'Domicilio del otorgante'
                        : tipoDomicilio === 'C'
                        ? 'Casa'
                        : tipoDomicilio === 'P'
                        ? 'Apartado Postal'
                        : tipoDomicilio === 'E'
                        ? 'Empleo'
                        : '-'}
                    </>
                  )}
                />
              </Table>
            </Panel>
          ) : null}

          {/*----------- Empleos -----------*/}
          {data?.empleos ? (
            <Panel
              header="Empleos"
              key="4"
              style={{
                backgroundColor: 'rgb(238, 238, 238)',
                border: '0',
                marginBottom: '20px',
                borderRadius: '2px',
              }}
            >
              <Table dataSource={data?.empleos} scroll={{ x: 1500 }}>
                {/* Fecha contratación */}
                <Column
                  title="Fecha contratación"
                  dataIndex="fechaContratacion"
                  key="fechaContratacion"
                  render={(fechaContratacion) => (
                    <>{fechaContratacion || '-'}</>
                  )}
                />
                {/* Fecha del Último Empleo */}
                <Column
                  title="Fecha del Último Empleo"
                  dataIndex="fechaUltimoDiaEmpleo"
                  key="fechaUltimoDiaEmpleo"
                  render={(fechaUltimoDiaEmpleo) => (
                    <>{fechaUltimoDiaEmpleo || '-'}</>
                  )}
                />
                {/* Fecha de Verificación de Empleo */}
                <Column
                  title="Fecha de Verificación de Empleo"
                  dataIndex="fechaVerificacionEmpleo"
                  key="fechaVerificacionEmpleo"
                  render={(fechaVerificacionEmpleo) => (
                    <>{fechaVerificacionEmpleo || '-'}</>
                  )}
                />
                {/* Nombre de la empresa */}
                <Column
                  title="Nombre de la empresa"
                  dataIndex="nombreEmpresa"
                  key="nombreEmpresa"
                  render={(nombreEmpresa) => <>{nombreEmpresa || '-'}</>}
                />
                {/* Puesto de Trabajo */}
                <Column
                  title="Puesto de Trabajo"
                  dataIndex="puesto"
                  key="puesto"
                  render={(puesto) => <>{puesto || '-'}</>}
                />
                {/* Salario Mensual */}
                <Column
                  title="Salario Mensual"
                  dataIndex="salarioMensual"
                  key="salarioMensual"
                  render={(salarioMensual) => <>{salarioMensual || '-'}</>}
                />
                {/* Clave Moneda */}
                <Column
                  title="Moneda"
                  dataIndex="claveMoneda"
                  key="claveMoneda"
                  render={(claveMoneda) => (
                    <>
                      {claveMoneda === 'MX'
                        ? 'Pesos Mexicanos'
                        : claveMoneda === 'US'
                        ? 'Dólares'
                        : claveMoneda === 'UD'
                        ? 'Unidades de Inversión'
                        : '-'}
                    </>
                  )}
                />
                {/* Número de telefono */}
                <Column
                  title="Número de teléfono"
                  dataIndex="numeroTelefono"
                  key="numeroTelefono"
                  render={(numeroTelefono) => <>{numeroTelefono || '-'}</>}
                />
                {/* Fax */}
                <Column
                  title="Fax"
                  dataIndex="fax"
                  key="fax"
                  render={(fax) => <>{fax || '-'}</>}
                />
                {/* Extensión */}
                <Column
                  title="Extensión"
                  dataIndex="extension"
                  key="extension"
                  render={(extension) => <>{extension || '-'}</>}
                />
                {/* Ciudad */}
                <Column
                  title="Ciudad"
                  dataIndex="ciudad"
                  key="ciudad"
                  render={(ciudad) => <>{ciudad || '-'}</>}
                />
                {/* Dirección */}
                <Column
                  title="Dirección"
                  dataIndex="direccion"
                  key="direccion"
                  render={(direccion) => <>{direccion || '-'}</>}
                />
                {/* Municipio */}
                <Column
                  title="Municipio"
                  dataIndex="delegacionMunicipio"
                  key="delegacionMunicipio"
                  render={(delegacionMunicipio) => (
                    <>{delegacionMunicipio || '-'}</>
                  )}
                />
                {/* Colonia */}
                <Column
                  title="Colonia"
                  dataIndex="coloniaPoblacion"
                  key="coloniaPoblacion"
                  render={(coloniaPoblacion) => <>{coloniaPoblacion || '-'}</>}
                />
                {/* Estado */}
                <Column
                  title="Estado"
                  dataIndex="estado"
                  key="estado"
                  render={(estado) => (
                    <>
                      {estado === 'AGS'
                        ? 'AGUASCALIENTES'
                        : estado === 'BC'
                        ? 'BAJA CALIFORNIA NORTE'
                        : estado === 'BCS'
                        ? 'BAJA CALIFORNIA SUR'
                        : estado === 'CAMP'
                        ? 'CAMPECHE'
                        : estado === 'CDMX'
                        ? 'CIUDAD DE MÉXICO'
                        : estado === 'CHIS'
                        ? 'CHIAPAS'
                        : estado === 'CHIH'
                        ? 'CHIHUAHUA'
                        : estado === 'COAH'
                        ? 'COHAHUILA'
                        : estado === 'COL'
                        ? 'COLIMA'
                        : estado === 'DF'
                        ? 'DISTRITO FEDERAL'
                        : estado === 'DGO'
                        ? 'DURANGO'
                        : estado === 'GTO'
                        ? 'GUANAJUATO'
                        : estado === 'GRO'
                        ? 'GUERRERO'
                        : estado === 'HGO'
                        ? 'HIDALGO'
                        : estado === 'JAL'
                        ? 'JALISCO'
                        : estado === 'MEX'
                        ? 'MÉXICO'
                        : estado === 'MICH'
                        ? 'MICHOCÁN'
                        : estado === 'MOR'
                        ? 'MORELOS'
                        : estado === 'NAY'
                        ? 'NAYARIT'
                        : estado === 'NL'
                        ? 'NUEVO LEÓN'
                        : estado === 'OAX'
                        ? 'OXACA'
                        : estado === 'PUE'
                        ? 'PUEBLA'
                        : estado === 'QRO'
                        ? 'QUERÉTARO'
                        : estado === 'QROO'
                        ? 'QUINTANA ROO'
                        : estado === 'SLP'
                        ? 'SAN LUIS POTOSÍ'
                        : estado === 'SIN'
                        ? 'SINALOA'
                        : estado === 'SON'
                        ? 'SONORA'
                        : estado === 'TAB'
                        ? 'TABASCO'
                        : estado === 'TAMP'
                        ? 'TAMAULIPAS'
                        : estado === 'TLAX'
                        ? 'TLAXCALA'
                        : estado === 'VER'
                        ? 'VERACRUZ'
                        : estado === 'YUC'
                        ? 'YUCATÁN'
                        : estado === 'ZAC'
                        ? 'ZACATECAS'
                        : '-'}
                    </>
                  )}
                />
                {/* Código Postal */}
                <Column
                  title="Código Postal"
                  dataIndex="CP"
                  key="CP"
                  render={(CP) => <>{CP || '-'}</>}
                />
              </Table>
            </Panel>
          ) : null}

          {/*----------- Scores  -----------*/}
          {data?.scores ? (
            <Panel
              header="Scores"
              key="5"
              style={{
                backgroundColor: 'rgb(238, 238, 238)',
                border: '0',
                marginBottom: '20px',
                borderRadius: '2px',
              }}
            >
              <Table dataSource={data?.scores} scroll={{ x: 800 }}>
                {/* Modelo de Score */}
                <Column
                  title="Tipo o Modelo de Score"
                  dataIndex="nombreScore"
                  key="nombreScore"
                  render={(nombreScore) => <>{nombreScore || '-'}</>}
                />
                {/* Valor */}
                <Column
                  title="Valor"
                  dataIndex="valor"
                  key="valor"
                  render={(valor) => <>{valor || '-'}</>}
                />
                {/* Razones */}
                <Column
                  title="Razones"
                  dataIndex="razones"
                  key="razones"
                  render={(razones) => (
                    <ul>
                      {razones.map((razon, key) => (
                        <li key={key}>
                          {razon === 'A0'
                            ? 'Historial de pago de la cuenta es demasiado nuevo'
                            : razon === 'A1'
                            ? 'Último reporte de la Cuenta en estatus de morosidad'
                            : razon === 'A2'
                            ? 'Monto de crédito disponibles en cuentas revolventes'
                            : razon === 'A3'
                            ? 'Monto de deuda en cuentas es demasiado alto'
                            : razon === 'A4'
                            ? 'Monto de deuda en el banco/nacional en cuentas revolventes'
                            : razon === 'A5'
                            ? 'Monto de deuda clasificado en cobranza'
                            : razon === 'A6'
                            ? 'Monto de deuda en las cuentas morosas'
                            : razon === 'A7'
                            ? 'Monto de deuda en cuentas abiertas recientemente es demasiado alto'
                            : razon === 'A8'
                            ? 'Monto de deuda en cuentas revolventes abiertas recientemente de banco/nacionales es muy alta'
                            : razon === 'A9'
                            ? 'Monto de deuda en cuentas abiertos recientemente en empresas de financiamiento al consumo es demasiado alto'
                            : razon === 'B0'
                            ? 'Monto de deuda en cuentas de minoristas abiertas recientemente es demasiado alta'
                            : razon === 'B1'
                            ? 'Monto de deuda en cuentas revolventes abiertas recientemente es demasiado alto'
                            : razon === 'B2'
                            ? 'Monto de deuda en cuentas de empresas de financiamiento de ventas abiertas recientemente es muy alto'
                            : razon === 'B3'
                            ? 'Monto de deuda en las cuentas de minoristas'
                            : razon === 'B4'
                            ? 'Monto de deuda en cuentas revolventes'
                            : razon === 'B5'
                            ? 'Monto de deuda en cuentas de crédito revolventes es demasiado alto'
                            : razon === 'B6'
                            ? 'Montos en mora en cuentas'
                            : razon === 'D0'
                            ? 'Declaración de quiebra reportada'
                            : razon === 'D1'
                            ? 'Fecha de la última consulta muy reciente'
                            : razon === 'D2'
                            ? 'Morosidad en las cuentas'
                            : razon === 'D3'
                            ? 'Morosidad en cuentas abiertas recientemente'
                            : razon === 'D4'
                            ? 'Eliminación de registro público o la cobranza reportada'
                            : razon === 'D5'
                            ? 'La frecuencia de la morosidad'
                            : razon === 'D6'
                            ? 'Nivel de morosidad en las cuentas'
                            : razon === 'D7'
                            ? 'Morosidad grave'
                            : razon === 'D8'
                            ? 'Morosidad grave, y registro público, o la cobranza reportada'
                            : razon === 'D9'
                            ? 'Morosidad grave, registro público eliminado, o la cobranza reportada'
                            : razon === 'E0'
                            ? 'Información Demográfica'
                            : razon === 'E1'
                            ? 'Falta información demográfica'
                            : razon === 'E2'
                            ? 'Número de consultas'
                            : razon === 'E3'
                            ? 'Frecuencia de las consultas'
                            : razon === 'E4'
                            ? 'La falta de información reciente de la cuenta'
                            : razon === 'E5'
                            ? 'Monto de deuda en los registros públicos eliminados'
                            : razon === 'E6'
                            ? 'Proporción del saldo sobre el límite de las cuentas'
                            : razon === 'F0'
                            ? 'Historial de pagos disponible es insuficiente'
                            : razon === 'F1'
                            ? 'Falta de cuentas de crédito de reciente creación'
                            : razon === 'F2'
                            ? 'Falta de cuentas revolventes de reciente creación'
                            : razon === 'F3'
                            ? 'Falta de información reciente de préstamo de autofinanciamiento'
                            : razon === 'F4'
                            ? 'Falta de información reciente préstamo de auto'
                            : razon === 'F5'
                            ? 'Falta de información reciente de banco/nacional revolvente'
                            : razon === 'F6'
                            ? 'Falta de información reciente sobre cuentas de empresas de financiamiento al consumo'
                            : razon === 'F7'
                            ? 'Falta de información reciente de préstamos'
                            : razon === 'F8'
                            ? 'Falta de información reciente de préstamos hipotecarios'
                            : razon === 'F9'
                            ? 'Falta de información reciente de préstamos no hipotecarios'
                            : razon === 'G0'
                            ? 'Falta de información reciente de cuentas de minoristas'
                            : razon === 'G1'
                            ? 'Falta de información reciente de cuentas revolventes'
                            : razon === 'G2'
                            ? 'No hay créditos hipotecarios reportados'
                            : razon === 'G3'
                            ? 'No hay información de saldos revolventes recientes de Bancos/Nacional'
                            : razon === 'G4'
                            ? 'No hay información de saldos no hipotecarios recientes'
                            : razon === 'G5'
                            ? 'No hay saldos de cuentas minoristas'
                            : razon === 'G6'
                            ? 'No hay saldos revolventes recientes'
                            : razon === 'J0'
                            ? 'Tiempo de Duración de las cuentas se han establecido'
                            : razon === 'J1'
                            ? 'Tiempo de Duración de las cuentas de automóviles se han establecido'
                            : razon === 'J2'
                            ? 'Tiempo de Duración de las cuentas revolventes del banco/nacionales se han establecido'
                            : razon === 'J3'
                            ? 'Tiempo de Duración de los préstamos de compañías de financiamiento al consumo se han establecido'
                            : razon === 'J4'
                            ? 'Tiempo de Duración de los préstamos a plazos se han establecido'
                            : razon === 'J5'
                            ? 'Tiempo de Duración de las cuentas hipotecarias reportado se han establecido'
                            : razon === 'J6'
                            ? 'Tiempo de Duración de los préstamos a plazos abiertos se han establecido'
                            : razon === 'J7'
                            ? 'Tiempo de Duración de las cuentas de minoristas se han establecido'
                            : razon === 'J8'
                            ? 'Tiempo de Duración de cuentas revolventes se han establecido'
                            : razon === 'J9'
                            ? 'Tiempo transcurrido desde la actividad de la cuenta es demasiado largo'
                            : razon === 'K0'
                            ? 'Tiempo transcurrido desde la Morosidad es demasiado reciente o desconocida'
                            : razon === 'K1'
                            ? 'Tiempo transcurrido desde la eliminación del registro público o de la cobranza es demasiado corto'
                            : razon === 'K2'
                            ? 'Tiempo transcurrido desde la apertura de la cuenta más reciente es demasiado corto'
                            : razon === 'K3'
                            ? 'Tiempo transcurrido desde la más reciente apertura de cuenta automática es demasiado corto'
                            : razon === 'K4'
                            ? 'Tiempo transcurrido desde la apertura de cuenta revolvente de banco/nacionales más reciente es demasiado corto'
                            : razon === 'K5'
                            ? 'Tiempo transcurrido desde la apertura de cuenta de empresas de financiamiento de consumo más reciente es demasiado corto'
                            : razon === 'K6'
                            ? 'Tiempo transcurrido desde la apertura de cuenta de préstamo a plazos más reciente es demasiado corto'
                            : razon === 'K7'
                            ? 'Tiempo transcurrido desde la apertura de cuenta minorista más recientes'
                            : razon === 'K8'
                            ? 'Tiempo transcurrido desde que se abrió la cuenta revolvente más reciente'
                            : razon === 'K9'
                            ? 'Ha transcurrido muy poco tiempo desde que se abrió la cuenta más reciente de "sales finance"'
                            : razon === 'M0'
                            ? 'Tiene un número de cuentas actualmente en impago'
                            : razon === 'M1'
                            ? 'Número de cuentas "marcadas" con impago'
                            : razon === 'M2'
                            ? 'Número de cuentas con impago reciente'
                            : razon === 'M3'
                            ? 'Número de cuentas revolventes bancarias/nacionales activas'
                            : razon === 'M4'
                            ? 'Número de cuentas activas de "retail"'
                            : razon === 'M5'
                            ? 'Número de registros públicos adversos/derogados'
                            : razon === 'M6'
                            ? 'Número de cuentas revolventes bancarias/nacionales con saldo'
                            : razon === 'M7'
                            ? 'Número de cuentas revolventes bancarias/nacionales'
                            : razon === 'M8'
                            ? 'Número de cuentas revolventes bancarias/nacionales u otras cuentas revolventes'
                            : razon === 'M9'
                            ? 'Número de cobranzas registradas'
                            : razon === 'N0'
                            ? 'Número de cuentas de "consumerfinancecompany" establecidas relativas a la longitud de la historia del consumidor'
                            : razon === 'N1'
                            ? 'Número de consultas por compañías de financiamiento al consumo'
                            : razon === 'N2'
                            ? 'Número de cuentas establecidas'
                            : razon === 'N3'
                            ? 'Número de "open installmentloans"'
                            : razon === 'N4'
                            ? 'Número de cuentas recientemente abiertas con compañías de financiamiento al consumo'
                            : razon === 'N5'
                            ? 'Número de cuentas de "retail"'
                            : razon === 'N6'
                            ? 'Número de cuentas de "retail" con saldo'
                            : razon === 'N7'
                            ? 'Número de cuentas revolventes'
                            : razon === 'N8'
                            ? 'Número de cuentas revolventes con saldos más altos que su límite'
                            : razon === 'P0'
                            ? 'La proporción del saldo vs límite en las cuentas de préstamo automotriz es muy alta'
                            : razon === 'P1'
                            ? 'La proporción del saldo vs límite en las cuentas con impago es muy alta'
                            : razon === 'P2'
                            ? 'La proporción del saldo vs límite en las cuentas de financiamiento al consumo es muy alta'
                            : razon === 'P3'
                            ? 'La proporción del saldo vs límite en las cuentas de "retail" es muy alta'
                            : razon === 'P5'
                            ? 'La proporción del saldo vs el límite de crédito en cuentas revolventes nacionales o en otras cuentas revolventes es muy alta'
                            : razon === 'P6'
                            ? 'La proporción del saldo vs límites de crédito en cuentas revolventes es muy alta'
                            : razon === 'P7'
                            ? 'La proporción del saldo vs límite en "sales financecompany" es muy alta'
                            : razon === 'P8'
                            ? 'La proporción del saldo vs el préstamo en créditos hipotecarios es muy alta'
                            : razon === 'P9'
                            ? 'La proporción de saldo del préstamo vs la cantidad del préstamo es muy alta'
                            : razon === 'Q0'
                            ? 'La proporción de saldos revolventes vs saldos totales es muy alta'
                            : razon === 'Q1'
                            ? 'La proporción de saldos vs límites de créditos en las cuentas revolventes bancarias/nacionales es muy alta'
                            : razon === 'R0'
                            ? 'Muy pocas cuentas han sido pagadas actualmente como fue convenido'
                            : razon === 'R1'
                            ? 'Muy pocas cuentas con saldos'
                            : razon === 'R2'
                            ? 'Muy pocas cuentas con información reciente de pagos'
                            : razon === 'R3'
                            ? 'Muy pocas cuentas activas'
                            : razon === 'R4'
                            ? 'Muy pocas cuentas revolventes bancarias/nacionales'
                            : razon === 'R5'
                            ? 'Muy pocas cuentas revolventes bancarias/nacionales con información reciente de pagos'
                            : razon === 'R6'
                            ? 'Muy pocas cuentas con compañías de financiamiento al consumo con información reciente de pagos'
                            : razon === 'R7'
                            ? 'Muy pocas cuentas de "instalment"R8Muy pocas cuentas de "retail"'
                            : razon === 'R9'
                            ? 'Muy pocas cuentas de "retail" con información reciente de pagos'
                            : razon === 'S0'
                            ? 'Muy pocas cuentas revolventes'
                            : razon === 'S1'
                            ? 'Muy pocas cuentas revolventes con información reciente de pagos'
                            : razon === 'S2'
                            ? 'Muy pocas cuentas de "sales financecompany" con información reciente de pagos'
                            : razon === 'T0'
                            ? 'Muchas cuentas abiertas recientemente'
                            : razon === 'T1'
                            ? 'Muchas cuentas con saldos'
                            : razon === 'T2'
                            ? 'Muchas cuentas revolventes bancarias/nacionales'
                            : razon === 'T3'
                            ? 'Muchas cuentas de compañías financiamiento al consumo'
                            : razon === 'T4'
                            ? 'Muchas cuentas de "instalment"'
                            : razon === 'T5'
                            ? 'Muchas consultas en los últimos 12 meses'
                            : razon === 'T6'
                            ? 'Muchas cuentas activas recientemente'
                            : razon === 'T7'
                            ? 'Muchas cuentas de automóvil activas recientemente'
                            : razon === 'T8'
                            ? 'Muchas cuentas revolventes bancarias/nacionales activas recientemente'
                            : razon === 'T9'
                            ? 'Muchas cuentas de compañías financiamiento al consumo recientemente activas'
                            : razon === 'U0'
                            ? 'Muchas cuentas de "instalment loan" recientemente activas'
                            : razon === 'U1'
                            ? 'Muchas cuentas de "retail" recientemente activas'
                            : razon === 'U2'
                            ? 'Muchas cuentas de "sales financecompany" recientemente activas'
                            : razon === 'U4'
                            ? 'Muchas cuentas recientemente abiertas con saldos'
                            : razon === 'U5'
                            ? 'Muchas cuentas revolventes bancarias/nacionales recientemente abiertas'
                            : razon === 'U6'
                            ? 'Muchas cuentas de compañías de financiamiento al consumo recientemente abiertas'
                            : razon === 'U7'
                            ? 'Muchas cuentas de "instalment" recientemente abiertas'
                            : razon === 'U8'
                            ? 'Muchas cuentas de "retail" recientemente abiertas con saldos'
                            : razon === 'U9'
                            ? 'Muchas cuentas revolventes recientemente abiertas'
                            : razon === 'V0'
                            ? 'Muchas cuentas revolventes recientemente abiertas con saldos'
                            : razon === 'V1'
                            ? 'Muchas cuentas de "sales financecompany" recientemente abiertasV2Muchas cuentas de "retail"'
                            : razon === 'V3'
                            ? 'Muchas cuentas revolventes'
                            : razon === 'V4'
                            ? 'Muchas cuentas revolventes bancarias/nacionales recientemente abiertas con saldos'
                            : razon === 'W3'
                            ? 'Número de cuentas "subprime"'
                            : razon === 'W4'
                            ? 'Falta de información de cargo reciente a tarjeta'
                            : razon === 'W5'
                            ? 'Sin saldos de cargo a tarjeta recientes'
                            : razon === 'W6'
                            ? 'Proporción de saldos vs límites de crédito en cuentas de "tarjetas de cargo" es muy alta'
                            : razon === 'W7'
                            ? 'Sin saldos de cuenta recientesW8La proporción de "Cash Advances" vs el "Límite de Cash Advance" es muy alta'
                            : razon === 'W9'
                            ? 'Cash Advance Activity on Accounts'
                            : razon === 'X0'
                            ? 'Pagos pendientes en las cuentas'
                            : razon === 'C1'
                            ? 'No existe el expediente.'
                            : razon === 'C2'
                            ? 'El expediente encontrado no tiene cuentas.'
                            : razon === 'C3'
                            ? 'El expediente encontrado está marcado como fallecido.'
                            : razon === 'C4'
                            ? 'El expediente encontrado no tiene cuantas activas en los últimos 24 meses.'
                            : razon === 'C5'
                            ? 'El expediente está marcado como fraudulento o sospechoso de fraude.'
                            : razon === 'SC'
                            ? 'No pudo calcularse score.'
                            : '-'}
                        </li>
                      ))}
                    </ul>
                  )}
                />
              </Table>
            </Panel>
          ) : null}
        </Collapse>
      </div>
    </div>
  )
}

export default HistoryCreditWithInfo
