import React from 'react'
import { Collapse, Table } from 'antd'
import './../CreditStyles.css'

const BelvoWithDataBank = ({ showData }) => {
  const { Column } = Table
  const { Panel } = Collapse

  const aggregations = showData?.incomes.map((e) => e?.aggregations)
  const sources = showData?.incomes.map((e) => e?.sources)

  return (
    <div>
      <Collapse
        bordered={false}
        style={{
          marginTop: '2em',
          marginBottom: '1em',
        }}
        key="collapse_bank"
      >
        {showData?.accounts ? (
          <Panel
            style={{
              backgroundColor: 'rgb(238, 238, 238)',
              border: '0',
              marginBottom: '20px',
              borderRadius: '2px',
            }}
            header="Cuentas"
            key="1"
          >
            <Table
              dataSource={showData?.accounts}
              scroll={{ x: 1700 }}
              style={{ margin: '1.4em 0 0 0' }}
              key="table_1"
            >
              {/* Fecha de consulta */}
              <Column
                title="Fecha de consulta"
                dataIndex="created_at"
                key="created_at"
                render={(created_at) => <>{created_at.split('T')[0] || '-'}</>}
              />

              {/* Último acceso */}
              <Column
                title="Fecha del último acceso exitoso"
                dataIndex="last_accessed_at"
                key="last_accessed_at"
                render={(last_accessed_at) => (
                  <>{last_accessed_at.split('T')[0] || '-'}</>
                )}
              />

              {/* Institución */}
              <Column
                title="Institución"
                dataIndex="institution"
                key="institution"
                render={(institution) => (
                  <>
                    {institution?.name === 'amex_mx_retail'
                      ? 'Amex'
                      : institution?.name === 'banamex_mx_retail'
                      ? 'Banamex'
                      : institution?.name === 'banamex_mx_business'
                      ? 'Banamex'
                      : institution?.name === 'bancoazteca_mx_retail'
                      ? 'Banco Azteca'
                      : institution?.name === 'bancoppel_mx_retail'
                      ? 'Bancoppel'
                      : institution?.name === 'banorte_mx_retail'
                      ? 'Banorte'
                      : institution?.name === 'banorte_mx_business'
                      ? 'Banorte'
                      : institution?.name === 'banregio_mx_retail'
                      ? 'Banregio'
                      : institution?.name === 'banregio_mx_business'
                      ? 'Banregio'
                      : institution?.name === 'bancomer_mx_retail'
                      ? 'BBVA'
                      : institution?.name === 'bancomer_mx_business'
                      ? 'BBVA'
                      : institution?.name === 'hey_mx_retail'
                      ? 'Hey Banco'
                      : institution?.name === 'hsbc_mx_retail'
                      ? 'HSBC'
                      : institution?.name === 'inbursa_mx_retail'
                      ? 'Inbursa'
                      : institution?.name === 'liverpool_mx_retail'
                      ? 'Liverpool'
                      : institution?.name === 'santander_mx_retail'
                      ? 'Santander'
                      : institution?.name === 'santander_mx_business'
                      ? 'Santander'
                      : institution?.name === 'scotiabank_mx_retail'
                      ? 'Scotiabank'
                      : institution?.name || '-'}
                  </>
                )}
              />
              {/* Tipo de institución */}
              <Column
                title="Tipo de institución"
                dataIndex="institution"
                key="institution"
                render={(institution) => (
                  <>
                    {institution?.type === 'bank'
                      ? 'Banco'
                      : institution?.type === 'fiscal'
                      ? 'Fiscal'
                      : '-'}
                  </>
                )}
              />
              {/* Nombre de la cuenta */}
              <Column
                title="Nombre de la cuenta"
                dataIndex="name"
                key="name"
                render={(name) => <>{name || '-'}</>}
              />
              {/* Número  de cuenta*/}
              <Column
                title="Número de cuenta"
                dataIndex="number"
                key="number"
                render={(number) => <>{number || '-'}</>}
              />
              {/* Tipo  de cuenta*/}
              <Column
                title="Tipo de cuenta"
                dataIndex="type"
                key="type"
                render={(type) => <>{type || '-'}</>}
              />
              {/* Categoría */}
              <Column
                title="Categoría"
                dataIndex="category"
                key="category"
                render={(category) => <>{category || '-'}</>}
              />
              {/* Balance Actual */}
              <Column
                title="Balance Actual"
                dataIndex="balance"
                key="balance"
                render={(balance) => <>{balance.current || '-'}</>}
              />
              {/* Balance Disponible */}
              <Column
                title="Balance Disponible"
                dataIndex="balance"
                key="balance"
                render={(balance) => <>{balance.available || '-'}</>}
              />
              {/* Datos de crédito */}
              {/* <Column
                title="Datos de crédito"
                dataIndex="credit_data"
                key="credit_data"
                render={(credit_data) => <>{credit_data || '-'}</>}
              /> */}
              {/* Datos de préstamo */}
              {/* <Column
                title="Datos de préstamo"
                dataIndex="loan_data"
                key="loan_data"
                render={(loan_data) => <>{loan_data || '-'}</>}
              /> */}
              {/* Moneda */}
              <Column
                title="Moneda"
                dataIndex="currency"
                key="currency"
                render={(currency) => (
                  <>
                    {currency === 'MXN'
                      ? 'Pesos Mexicanos'
                      : currency === 'COP'
                      ? 'Pesos Colombianos'
                      : currency === 'BRL'
                      ? 'Real Brasileño'
                      : '-'}
                  </>
                )}
              />
            </Table>
          </Panel>
        ) : null}

        {/* ------------- Ingresos -------------  */}
        {showData?.incomes ? (
          <Panel
            style={{
              backgroundColor: 'rgb(238, 238, 238)',
              border: '0',
              marginBottom: '20px',
              borderRadius: '2px',
            }}
            header="Ingresos"
            key="2"
          >
            {/* Cuentas */}
            <h5
              style={{
                padding: '0',
                margin: '1em',
                color: 'var(--gray-color)',
              }}
            >
              Cuenta(s)
            </h5>
            <Table
              dataSource={showData?.incomes}
              scroll={{ x: 2000 }}
              style={{ margin: '1em 0 0 0' }}
              key="table_2"
            >
              {/* Fecha de consulta */}
              <Column
                title="Fecha de consulta"
                dataIndex="account"
                key="account"
                render={(account) => (
                  <>{account?.created_at.split('T')[0] || '-'}</>
                )}
              />
              {/* Último acceso */}
              <Column
                title="Fecha del último acceso exitoso"
                dataIndex="account"
                key="account"
                render={(account) => (
                  <>{account?.last_accessed_at.split('T')[0] || '-'}</>
                )}
              />
              {/* Institucion */}
              <Column
                title="Institución"
                dataIndex="account"
                key="account"
                render={(account) => (
                  <>
                    {account.institution.name === 'amex_mx_retail'
                      ? 'Amex'
                      : account.institution.name === 'banamex_mx_retail'
                      ? 'Banamex'
                      : account.institution.name === 'banamex_mx_business'
                      ? 'Banamex'
                      : account.institution.name === 'bancoazteca_mx_retail'
                      ? 'Banco Azteca'
                      : account.institution.name === 'bancoppel_mx_retail'
                      ? 'Bancoppel'
                      : account.institution.name === 'banorte_mx_retail'
                      ? 'Banorte'
                      : account.institution.name === 'banorte_mx_business'
                      ? 'Banorte'
                      : account.institution.name === 'banregio_mx_retail'
                      ? 'Banregio'
                      : account.institution.name === 'banregio_mx_business'
                      ? 'Banregio'
                      : account.institution.name === 'bancomer_mx_retail'
                      ? 'BBVA'
                      : account.institution.name === 'bancomer_mx_business'
                      ? 'BBVA'
                      : account.institution.name === 'hey_mx_retail'
                      ? 'Hey Banco'
                      : account.institution.name === 'hsbc_mx_retail'
                      ? 'HSBC'
                      : account.institution.name === 'inbursa_mx_retail'
                      ? 'Inbursa'
                      : account.institution.name === 'liverpool_mx_retail'
                      ? 'Liverpool'
                      : account.institution.name === 'santander_mx_retail'
                      ? 'Santander'
                      : account.institution.name === 'santander_mx_business'
                      ? 'Santander'
                      : account.institution.name === 'scotiabank_mx_retail'
                      ? 'Scotiabank'
                      : account.institution.name || '-'}
                  </>
                )}
              />
              {/* Tipo de Institucion */}
              <Column
                title="Tipo de Institución"
                dataIndex="account"
                key="account"
                render={(account) => (
                  <>
                    {account?.institution?.type === 'bank'
                      ? 'Banco'
                      : account?.institution?.type === 'fiscal'
                      ? 'Fiscal'
                      : '-'}
                  </>
                )}
              />
              {/* Nombre de la cuenta */}
              <Column
                title="Nombre de la cuenta"
                dataIndex="account"
                key="account"
                render={(account) => <>{account?.name || '-'}</>}
              />
              {/* Número de cuenta */}
              <Column
                title="Número de cuenta"
                dataIndex="account"
                key="account"
                render={(account) => <>{account?.number || '-'}</>}
              />
              {/* Categoria de cuenta */}
              <Column
                title="Categoria de cuenta"
                dataIndex="account"
                key="account"
                render={(account) => <>{account?.category || '-'}</>}
              />
              {/* Balance actual */}
              <Column
                title="Balance actual"
                dataIndex="account"
                key="account"
                render={(account) => <>{account?.balance?.current || '-'}</>}
              />
              {/* Balance disponible */}
              <Column
                title="Balance disponible"
                dataIndex="account"
                key="account"
                render={(account) => <>{account?.balance?.available || '-'}</>}
              />
              {/* Tipo de factura */}
              <Column
                title="Tipo de factura"
                dataIndex="account"
                key="account"
                render={(account) => <>{account?.type || '-'}</>}
              />
              {/* Moneda */}
              <Column
                title="Moneda"
                dataIndex="account"
                key="account"
                render={(account) => (
                  <>
                    {account?.currency === 'MXN'
                      ? 'Pesos Mexicanos'
                      : account?.currency === 'COP'
                      ? 'Pesos Colombianos'
                      : account?.currency === 'BRL'
                      ? 'Real Brasileño'
                      : '-'}
                  </>
                )}
              />
            </Table>

            {/*------- Métricas de ingresos --------*/}
            {aggregations ? (
              <>
                <h5
                  style={{
                    padding: '0',
                    margin: '1em',
                    color: 'var(--gray-color)',
                  }}
                >
                  Métricas de ingresos
                </h5>

                <Table
                  dataSource={aggregations[0]}
                  scroll={{ x: 1500 }}
                  style={{ margin: '1em 0 0 0' }}
                  key="table_3"
                >
                  {/* Tipos de ingresos */}
                  <Column
                    title="Tipos de ingresos"
                    dataIndex="type"
                    key="type"
                    render={(type) => (
                      <>
                        {type === 'SALARY'
                          ? 'Salario'
                          : type === 'CASH_DEPOSIT'
                          ? 'Déposito en efectivo'
                          : type === 'PENSION'
                          ? 'Pension'
                          : type === 'UNKNOWN'
                          ? 'Desconocido'
                          : type === 'ALL'
                          ? 'Todos'
                          : type || '-'}
                      </>
                    )}
                  />
                  {/* Unidad de periodos */}
                  <Column
                    title="Unidad de periodos"
                    dataIndex="period_unit"
                    key="period_unit"
                    render={(period_unit) => <>{period_unit || '-'}</>}
                  />
                  {/* Periodos de retroceso */}
                  <Column
                    title="Periodos de retroceso"
                    dataIndex="lookback_periods"
                    key="lookback_periods"
                    render={(lookback_periods) => <>{lookback_periods || 0}</>}
                  />
                  {/* Periodos Completos */}
                  <Column
                    title="Periodos Completos"
                    dataIndex="full_periods"
                    key="full_periods"
                    render={(full_periods) => <>{full_periods || 0}</>}
                  />
                  {/* Periodos con ingresos */}
                  <Column
                    title="Periodos con ingresos"
                    dataIndex="periods_with_income"
                    key="periods_with_income"
                    render={(periods_with_income) => (
                      <>{periods_with_income || 0}</>
                    )}
                  />
                  {/* Ingresos estándar por periodo */}
                  <Column
                    title="Ingreso estándar por periodo"
                    dataIndex="std_income_per_period"
                    key="std_income_per_period"
                    render={(std_income_per_period) => (
                      <>{std_income_per_period || 0}</>
                    )}
                  />
                  {/* Ingresos mínimos por periodo */}
                  <Column
                    title="Ingresos mínimos por periodo"
                    dataIndex="minimum_income_per_period"
                    key="minimum_income_per_period"
                    render={(minimum_income_per_period) => (
                      <>{minimum_income_per_period || 0}</>
                    )}
                  />
                  {/* Ingresos medios por periodo */}
                  <Column
                    title="Ingresos medios por periodo"
                    dataIndex="median_income_per_period"
                    key="median_income_per_period"
                    render={(median_income_per_period) => (
                      <>{median_income_per_period || 0}</>
                    )}
                  />
                  {/* Ingresos máximos por periodo */}
                  <Column
                    title="Ingresos máximos por periodo"
                    dataIndex="maximum_income_per_period"
                    key="maximum_income_per_period"
                    render={(maximum_income_per_period) => (
                      <>{maximum_income_per_period || 0}</>
                    )}
                  />
                  {/* Número total de transacciones de ingresos */}
                  <Column
                    title="Número total de transacciones de ingresos"
                    dataIndex="total_number_of_sources"
                    key="total_number_of_sources"
                    render={(total_number_of_sources) => (
                      <>{total_number_of_sources || 0}</>
                    )}
                  />
                  {/* Confianza mínima */}
                  <Column
                    title="Confianza mínima"
                    dataIndex="minimum_confidence"
                    key="minimum_confidence"
                    render={(minimum_confidence) => (
                      <>
                        {minimum_confidence === 'HIGH'
                          ? 'Alto'
                          : minimum_confidence === 'MEDIUM'
                          ? 'Medio'
                          : minimum_confidence || '-'}
                      </>
                    )}
                  />
                </Table>
              </>
            ) : null}

            {/*------- Fuentes de ingresos --------*/}
            {sources ? (
              <>
                <h5
                  style={{
                    padding: '0',
                    margin: '1em',
                    color: 'var(--gray-color)',
                  }}
                >
                  Fuentes de ingresos
                </h5>

                <Table
                  dataSource={sources[0]}
                  scroll={{ x: 3200 }}
                  style={{ margin: '1em 0 0 0' }}
                  key="table_4"
                >
                  {/* Tipos de fuentes */}
                  <Column
                    title="Tipos de fuentes"
                    dataIndex="type"
                    key="type"
                    render={(type) => (
                      <>
                        {type === 'SALARY'
                          ? 'Salario'
                          : type === 'CASH_DEPOSIT'
                          ? 'Déposito en efectivo'
                          : type === 'PENSION'
                          ? 'Pension'
                          : type === 'UNKNOWN'
                          ? 'Desconocido'
                          : type || '-'}
                      </>
                    )}
                  />
                  {/* Confianza */}
                  <Column
                    title="Confianza"
                    dataIndex="confidence"
                    key="confidence"
                    render={(confidence) => (
                      <>
                        {confidence === 'HIGH'
                          ? 'Alto'
                          : confidence === 'MEDIUM'
                          ? 'Medio'
                          : confidence || '-'}
                      </>
                    )}
                  />
                  {/* Importe mínimo de transacciones */}
                  <Column
                    title="Importe mínimo de transacciones"
                    dataIndex="minimum_transaction_amount"
                    key="minimum_transaction_amount"
                    render={(minimum_transaction_amount) => (
                      <>{minimum_transaction_amount || 0}</>
                    )}
                  />
                  {/* Número mínimo de días entre transacciones de ingresos */}
                  <Column
                    title="Número mínimo de días entre transacciones de ingresos"
                    dataIndex="minimum_days_between_consecutive_income_transactions"
                    key="minimum_days_between_consecutive_income_transactions"
                    render={(
                      minimum_days_between_consecutive_income_transactions
                    ) => (
                      <>
                        {minimum_days_between_consecutive_income_transactions ||
                          0}
                      </>
                    )}
                  />
                  {/* Importe medio de transacciones */}
                  <Column
                    title="Importe medio de transacciones"
                    dataIndex="median_transaction_amount"
                    key="median_transaction_amount"
                    render={(median_transaction_amount) => (
                      <>{median_transaction_amount || 0}</>
                    )}
                  />
                  {/* Número medio de días entre transacciones de ingresos */}
                  <Column
                    title="Número medio de días entre transacciones de ingresos"
                    dataIndex="median_days_between_consecutive_income_transactions"
                    key="median_days_between_consecutive_income_transactions"
                    render={(
                      median_days_between_consecutive_income_transactions
                    ) => (
                      <>
                        {median_days_between_consecutive_income_transactions ||
                          0}
                      </>
                    )}
                  />
                  {/* Importe máximo de transacciones */}
                  <Column
                    title="Importe máximo de transacciones"
                    dataIndex="maximum_transaction_amount"
                    key="maximum_transaction_amount"
                    render={(maximum_transaction_amount) => (
                      <>{maximum_transaction_amount || 0}</>
                    )}
                  />
                  {/* Número máximo de días entre transacciones de ingresos */}
                  <Column
                    title="Número máximo de días entre transacciones de ingresos"
                    dataIndex="maximum_days_between_consecutive_income_transactions"
                    key="maximum_days_between_consecutive_income_transactions"
                    render={(
                      maximum_days_between_consecutive_income_transactions
                    ) => (
                      <>
                        {maximum_days_between_consecutive_income_transactions ||
                          0}
                      </>
                    )}
                  />
                  {/* Importe estándar de transacciones */}
                  <Column
                    title="Importe estándar de transacciones"
                    dataIndex="std_transaction_amount"
                    key="std_transaction_amount"
                    render={(std_transaction_amount) => (
                      <>{std_transaction_amount || 0}</>
                    )}
                  />
                  {/* Número estándar de días entre transacciones de ingresos */}
                  <Column
                    title="Número estándar de días entre transacciones de ingresos"
                    dataIndex="std_days_between_consecutive_income_transactions"
                    key="std_days_between_consecutive_income_transactions"
                    render={(
                      std_days_between_consecutive_income_transactions
                    ) => (
                      <>
                        {std_days_between_consecutive_income_transactions || 0}
                      </>
                    )}
                  />
                  {/* Días desde la última transacción */}
                  <Column
                    title="Días desde la última transacción"
                    dataIndex="days_since_last_transaction"
                    key="days_since_last_transaction"
                    render={(days_since_last_transaction) => (
                      <>{days_since_last_transaction || 0}</>
                    )}
                  />
                  {/* Frecuencia de transacciones */}
                  <Column
                    title="Frecuencia de transacciones"
                    dataIndex="frequency"
                    key="frequency"
                    render={(frequency) => (
                      <>
                        {frequency === 'MONTHLY'
                          ? 'Mensual'
                          : frequency === 'FORTNIGHTLY'
                          ? 'Quincenal'
                          : frequency === 'WEEKLY'
                          ? 'Semanal'
                          : frequency === 'IRREGULAR'
                          ? 'Irregular'
                          : frequency || '-'}
                      </>
                    )}
                  />
                  {/* Transacciones */}
                  <Column
                    title="Transacciones"
                    dataIndex="transactions"
                    key="transactions"
                    width={350}
                    render={(transactions) => (
                      <ul>
                        {transactions.map((data, key) => (
                          <>
                            <li key={key}>{data.description}</li>
                            <ul>
                              <li>{data.amount}</li>
                              <li>{data.value_date}</li>
                            </ul>
                          </>
                        ))}
                      </ul>
                    )}
                  />
                </Table>
              </>
            ) : null}
          </Panel>
        ) : null}
      </Collapse>
    </div>
  )
}

export default BelvoWithDataBank
