import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { Pagination } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { Hidden } from '@material-ui/core'

const styles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingBottom: '20px',
    textAlign: 'center',
  },
  options: {
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: { marginRight: '10px' },
})

export default function Settings(props) {
  const { activePage, totalPages, data, handlePaginationChange, setLimit } =
    props
  const { t } = useTranslation()
  const classes = styles()

  return (
    <Grid container className={classes.container}>
      <Grid
        item
        xs={12}
        sm={12}
        md={8}
        lg={6}
        xl={6}
        className={classes.options}
      >
        <Hidden xsDown>
          <Pagination
            activePage={activePage}
            onPageChange={handlePaginationChange}
            totalPages={totalPages}
          />
        </Hidden>
        <Hidden smUp>
          <Pagination
            activePage={activePage}
            onPageChange={handlePaginationChange}
            totalPages={totalPages}
            firstItem={false}
            lastItem={false}
            siblingRange={0}
          />
        </Hidden>
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={4} xl={4} className={classes.options}>
        <span>
          {data ? (
            <>
              <span>{t('showingUpTo')} </span>
              <select
                name="cars"
                id="cars"
                form="carform"
                value={data?.limit}
                onChange={(e) => setLimit(e.target.value)}
              >
                <option value="10"> 10</option>
                <option value="25"> 25</option>
                <option value="50"> 50</option>
                <option value="100"> 100</option>
              </select>
              <span>
                {`  ${t('of')}`} {data?.totalRecords}
              </span>
            </>
          ) : null}
        </span>
      </Grid>
    </Grid>
  )
}
