import getImage from './getImage'

const setImages = ({
  dataVersion,
  getToken,
  anverso,
  _anverso,
  reverso,
  _reverso,
  facial,
  _facial,
  front,
  _front,
  frontIR,
  _frontInfrared,
  frontUV,
  _frontUltraviolet,
  back,
  _back,
  backIR,
  _backInfrared,
  backUV,
  _backUltraviolet,
  _portrait,
  portrait,
  setAnverso,
  setReverso,
  setFacial,
  setFacialEnrollImg,
  setFront,
  setFrontIR,
  setFrontUV,
  setBack,
  setBackIR,
  setBackUV,
  setPortrait,
  _frontOriginal,
  _backOriginal,
  frontOriginal,
  setFrontOriginal,
  backOriginal,
  setBackOriginal,
}) => {
  if (anverso === null) {
    if (_anverso) {
      getImage({
        key: 'anverso',
        image: _anverso,
        dataVersion,
        getToken,
        setAnverso,
      })
    }
  }

  if (reverso === null) {
    if (_reverso) {
      getImage({
        key: 'reverso',
        image: _reverso,
        dataVersion,
        getToken,
        setReverso,
      })
    }
  }

  if (facial === null) {
    if (_facial) {
      getImage({
        key: 'facial',
        image: _facial,
        dataVersion,
        getToken,
        setFacial,
        setFacialEnrollImg,
      })
    }
  }

  if (front === null) {
    if (_front) {
      getImage({
        key: 'front',
        image: _front,
        dataVersion,
        getToken,
        setFront,
      })
    }
  }

  if (frontIR === null) {
    if (_frontInfrared) {
      getImage({
        key: 'frontInfrared',
        image: _frontInfrared,
        dataVersion,
        getToken,
        setFrontIR,
      })
    }
  }

  if (frontUV === null) {
    if (_frontUltraviolet) {
      getImage({
        key: 'frontUltraviolet',
        image: _frontUltraviolet,
        dataVersion,
        getToken,
        setFrontUV,
      })
    }
  }

  if (back === null) {
    if (_back) {
      getImage({
        key: 'back',
        image: _back,
        dataVersion,
        getToken,
        setBack,
      })
    }
  }

  if (backIR === null) {
    if (_backInfrared) {
      getImage({
        key: 'backInfrared',
        image: _backInfrared,
        dataVersion,
        getToken,
        setBackIR,
      })
    }
  }

  if (backUV === null) {
    if (_backUltraviolet) {
      getImage({
        key: 'backUltraviolet',
        image: _backUltraviolet,
        dataVersion,
        getToken,
        setBackUV,
      })
    }
  }

  if (portrait === null) {
    if (_portrait) {
      getImage({
        key: 'portrait',
        image: _portrait,
        dataVersion,
        getToken,
        setPortrait,
      })
    }
  }

  if (frontOriginal === null) {
    if (_frontOriginal) {
      getImage({
        key: 'frontOriginal',
        image: _frontOriginal,
        dataVersion,
        getToken,
        setFrontOriginal,
      })
    }
  }

  if (backOriginal === null) {
    if (_backOriginal) {
      getImage({
        key: 'backOriginal',
        image: _backOriginal,
        dataVersion,
        getToken,
        setBackOriginal,
      })
    }
  }
}

export default setImages
