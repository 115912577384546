import { Collapse, Image, Typography } from 'antd'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { BtnShowDetail } from '../../../../components/UI/ButtonSign/ButtonSign'
import {
  getBiometricImageSignProcess,
  getCountParticipantsAndSignatories,
  getDataSignWithBiometricResult,
  getPortraitImageSignProcess,
} from '../../../../utils/getDataCustomize'
import useToken from '../../../../utils/useToken.js'
import { MessageBox, MessageZone, Right, TextBiometric } from './OptionsStyle'
import Aprove from './Verdict/Aprove'
import Reject from './Verdict/Reject'

export default function Options(props) {
  const {
    message,
    setMessage,
    zoom,
    user,
    documentID,
    uuid,
    id,
    signPositions,
    dd_dragstart,
    participants,
    clientId,
  } = props

  const history = useHistory()
  const { Paragraph } = Typography
  const handleMessage = (e) => setMessage(e.target.value)

  const { Panel } = Collapse
  const getToken = useToken()
  const [dataSigners, setDataSigners] = useState([])
  const [countSignWithVerification, setCountSignWithVerification] = useState({})

  const redirectToVerification = (participantUuid) => {
    history.push(`/resultados/${participantUuid}`)
  }

  useEffect(() => {
    const getImageBiometric = async () => {
      const token = await getToken({
        audience: 'veridocid-dashboard',
      })

      let images = []
      let imageBiometric
      let imagePortrait
      let resultLiveness

      if (participants !== null) {
        for (const participant of participants) {
          if (participant.biometric && participant.uuid) {
            //Get biometric image
            imageBiometric = await getBiometricImageSignProcess(
              token,
              `${documentID}/${participant.participantId}.jpg`
            )
            //Get portrait image
            imagePortrait = await getPortraitImageSignProcess(
              token,
              participant.uuid
            )
          }
          if (participant.uuid) {
            resultLiveness = await getDataSignWithBiometricResult(
              token,
              participant.uuid
            )
          }

          images.push({
            name: participant?.name,
            uuid: participant?.uuid,
            id: resultLiveness?.id ?? 'Sin datos',
            biometric: imageBiometric ?? '',
            portrait: imagePortrait ?? '',
            liveness: resultLiveness?.signResultLiveness ?? 'Sin datos',
            scoreLiveness: resultLiveness?.signScoreLiveness ?? 'Sin datos',
            statusVerification: resultLiveness?.status ?? 'Sin datos',
            globalResultVerification:
              resultLiveness?.globalResult ?? 'Sin datos',
            globalResultDescriptionVerification:
              resultLiveness?.globalResultDescription ?? 'Sin datos',
          })
        }

        setDataSigners(images)
      }
    }

    const countParticipantsAndSigns = async () => {
      const token = await getToken({
        audience: 'veridocid',
      })
      let getInfo = await getCountParticipantsAndSignatories(token, documentID)
      if (getInfo !== null) {
        setCountSignWithVerification(getInfo)
      }
    }

    // Validaciones para Kavak_compras, Kavak_ventas y Kavak
    if (
      clientId === 'F0LDMiWc3mOlQjgtCLWIsA32U9og84UO' ||
      clientId === 'zpTUG6XKjkCN1XAJzTS3ccp6Nx8bXNNf' ||
      clientId === 'ztZAFimBGaroCldIumuKs7t51ttZJAAD'
    ) {
      getImageBiometric()
      countParticipantsAndSigns()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participants, clientId])

  return (
    <>
      <Right item xs={4}>
        <Collapse defaultActiveKey={['1', '2']}>
          <Panel header="Revisión" key="1">
            {/* Message */}
            <span className="description_config_client">
              Apruebe o rechaze del documento, indicando un mensaje descriptivo
            </span>
            <form style={{ marginTop: '1rem' }}>
              <div className="content-input_add">
                <label htmlFor="description_review" className="lbl_input">
                  Mensaje de confirmación
                </label>
                <input
                  type="text"
                  name="description_review"
                  placeholder="Apruebo o rechazo por..."
                  value={message}
                  onChange={handleMessage}
                />
              </div>
            </form>
            {message !== '' ? (
              <div style={{ margin: '1rem 0 1.4rem' }}>
                <span className="description_config_client">
                  Ubique en donde quiere visualizar la confirmación de la
                  revisión
                </span>
                <MessageZone>
                  <MessageBox
                    height={`${30 * zoom}px`}
                    fontSize={`${13 * zoom}px`}
                    padding={`0 ${5 * zoom}px`}
                    id={user.email}
                    draggable={message !== '' ? true : false}
                    onDragStart={(e) => dd_dragstart(e)}
                  >
                    {message}
                  </MessageBox>
                </MessageZone>
              </div>
            ) : null}

            {/* Actions */}
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {signPositions.length > 0 ? (
                <Aprove
                  documentID={documentID}
                  uuid={uuid}
                  id={id}
                  signPositions={signPositions}
                  message={message}
                />
              ) : null}
              <Reject documentID={documentID} uuid={uuid} id={id} />
            </div>
          </Panel>
          {/* Validaciones para Kavak_compras, Kavak_ventas y Kavak */}
          {participants !== null &&
          (clientId === 'F0LDMiWc3mOlQjgtCLWIsA32U9og84UO' ||
            clientId === 'zpTUG6XKjkCN1XAJzTS3ccp6Nx8bXNNf' ||
            clientId === 'ztZAFimBGaroCldIumuKs7t51ttZJAAD') ? (
            <Panel header="Verificaciones" key="2">
              <span className="description_config_client">
                Visualice las imagenes, la cantidad de firmantes, los resultados y los
                datos de la biometría
              </span>
              {/* Counter */}
              {countSignWithVerification !== null ||
              !isEmpty(countSignWithVerification) ? (
                <div style={{ marginTop: '0.9rem' }}>
                  <TextBiometric>
                    <strong>Total de participantes:</strong>{' '}
                    {countSignWithVerification?.total || 0}
                  </TextBiometric>
                  <TextBiometric>
                    <strong>Total de firmas:</strong>{' '}
                    {countSignWithVerification?.signs || 0}
                  </TextBiometric>
                </div>
              ) : null}
              {/* Biometría */}
              {dataSigners.length > 0 ? (
                dataSigners.map((signer, i) => (
                  <div key={i} style={{ margin: '1rem 0' }}>
                    <h4
                      style={{
                        fontWeight: '600',
                        color: 'var(--dark-purple-color)',
                        letterSpacing: '0.6px',
                      }}
                    >
                      Firmante {i + 1}
                    </h4>
                    <TextBiometric>
                      <strong>Nombre:</strong> {signer?.name || ''}
                    </TextBiometric>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      <strong
                        style={{
                          color: 'var(--black-light-color)',
                          fontSize: 'clamp(0.85rem, 0.88rem, 0.9rem)',
                          paddingRight: '2px',
                        }}
                      >
                        ID:
                      </strong>{' '}
                      {signer?.id ? (
                        <Paragraph
                          copyable
                          style={{
                            color: 'var(--black-light-color)',
                            fontSize: 'clamp(0.85rem, 0.88rem, 0.9rem)',
                            margin: 0,
                          }}
                        >
                          {signer?.id || '-'}
                        </Paragraph>
                      ) : (
                        <TextBiometric
                          style={{ paddingBottom: '0', paddingTop: '0.04rem' }}
                        >
                          Sin datos
                        </TextBiometric>
                      )}
                    </div>
                    <TextBiometric>
                      <strong>UUID:</strong> {signer?.uuid || ''}
                    </TextBiometric>
                    <TextBiometric>
                      <strong>Resultado de la verificación:</strong>{' '}
                      {signer?.globalResultVerification || ''}
                    </TextBiometric>
                    <TextBiometric>
                      <strong>Estatus de la verificación:</strong>{' '}
                      {signer?.statusVerification || ''}
                    </TextBiometric>
                    {signer?.biometric && signer?.portrait ? (
                      <>
                        <TextBiometric>
                          <strong>Liveness:</strong> {signer?.liveness || ''}
                        </TextBiometric>
                        <TextBiometric>
                          <strong>Score:</strong> {signer?.scoreLiveness || ''}
                        </TextBiometric>
                      </>
                    ) : null}
                    {signer?.biometric && signer?.portrait ? (
                      <div
                        style={{
                          margin: '1rem 0',
                          display: 'flex',
                          flexWrap: 'wrap',
                        }}
                        className="biometric_content_main"
                      >
                        <Image
                          src={`data:image/png;base64,${signer?.biometric}`}
                          width={84}
                          height={89}
                          alt="biometric"
                          style={{
                            objectFit: 'cover',
                          }}
                        />
                        <Image
                          src={`data:image/png;base64,${signer?.portrait}`}
                          width={89}
                          height={89}
                          alt="portrait"
                          style={{ objectFit: 'fill' }}
                        />
                      </div>
                    ) : null}
                  </div>
                ))
              ) : (
                <p>No tiene biometría</p>
              )}
            </Panel>
          ) : (
            <Panel header="Participantes" key="2">
              {participants && participants.length > 0
                ? participants.map((signer, i) => (
                    <div key={i} style={{ margin: '1rem 0' }}>
                      <h4
                        style={{
                          fontWeight: '600',
                          color: 'var(--dark-purple-color)',
                          letterSpacing: '0.6px',
                        }}
                      >
                        Firmante {i + 1}
                      </h4>
                      <TextBiometric>
                        <strong>Nombre:</strong> {signer?.name || ''}
                      </TextBiometric>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                        }}
                      >
                        {signer?.uuid ? (
                          <BtnShowDetail
                            onClick={() => {
                              redirectToVerification(signer?.uuid)
                            }}
                          >
                            Ver Detalle
                          </BtnShowDetail>
                        ) : (
                          <TextBiometric>
                            El firmante no tiene verificación
                          </TextBiometric>
                        )}
                      </div>
                    </div>
                  ))
                : <p>No hay firmantes</p>}
            </Panel>
          )}
        </Collapse>
      </Right>
    </>
  )
}
