/**
 * Data Version 1
 */
export const responseVersion1 = {
  name: 'Name',
  fatherSurname: 'FatherSurname',
  motherSurname: 'MotherSurname',
  fullName: 'FullName',
  documentNumber: 'DocumentNumber',
  personalNumber: 'PersonalNumber',
  voterKey: 'Voter_Key',
  ocrNumber: 'OcrNumber',
  numberOfDuplicates: 'NumberOfDuplicates',
  dateOfBirth: 'DateOfBirth',
  dateOfExpiry: 'DateOfExpiry',
  passport: 'Passport',
  votingCard: 'VotingCard',
  visual: 'OCR',
  countryCodeMex: 'MEX',
}

/**
 * Data Version 2
 */
export const responseVersion2 = {
  name: 'Given Names',
  fatherSurname: 'Father Surname',
  motherSurname: 'Mother Surname',
  fullName: 'Surname And Given Names',
  documentNumber: 'Document Number',
  personalNumber: 'Personal Number',
  voterKey: 'Voter Key',
  ocrNumber: 'OCR Number',
  numberOfDuplicates: 'Number of Duplicates',
  dateOfBirth: 'Date of Birth',
  dateOfExpiry: 'Date of Expiry',
  passport: 'PASSPORT',
  votingCard: 'VOTING_CARD',
  visual: 'VISUAL',
  countryCodeMex: 'MEX',
}
