import React, { Fragment, useState } from 'react'
import { Modal } from 'antd'
import { Input, Button, Form, Message } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

import '../ConsultationServices.css'
import useToken from '../../../utils/useToken'
import { API_URL } from '../../../const/index'
import { useAuth0 } from '../../../react-auth0-spa'
import CardListaNominalWhitinfo from './CardListaNominalWhitinfo'
import { votingCardOptions } from '../../../fixtures/consultationServices'
import votingCardCSideA from '../../../assets/templates/VotingCardC_SideA.png'
import votingCardCSideB from '../../../assets/templates/VotingCardC_SideB.png'

const ListaNominal = ({
  id,
  data,
  cic,
  handleChangeInputCic,
  ocr,
  handleChangeInputOcr,
  voterKey,
  handleChangeInputVoterKey,
  numEmision,
  handleChangeInputNumEmision,
  ciudadano,
  handleChangeInputCiudadano,
  ineStatus,
  dataIneFound,
  setIsIneVerified,
  onVerifyListaNominal,
  uuid,
  isVotingCardC,
}) => {
  // Message error API
  const [contentMsgApi, setContentMsgApi] = useState('')
  const [visibleMsgApi, setVisibleMsgApi] = useState(false)
  // Data Lista Nominal
  const [dataListaNominal, setDataListaNominal] = useState(null)
  const [verificando, setVerificando] = useState(false)
  const [isTypeC, setIsTypeC] = useState(isVotingCardC)
  const { t } = useTranslation()
  const { user } = useAuth0()
  const getToken = useToken()
  let valueSelect

  if (isVotingCardC) {
    valueSelect = votingCardOptions[0]
  } else {
    valueSelect = votingCardOptions[1]
  }

  const callToIneApiD = async () => {
    setVerificando(true)
    let body

    try {
      const token = await getToken({
        audience: 'veridocid',
      })

      if (/^([0-9]{9})$/.test(ocr)) {
        body = JSON.stringify({
          id,
          identificadorCiudadano: ocr,
          cic,
          uuid,
          name: user.name,
          email: user.email,
        })
      } else {
        body = JSON.stringify({
          id,
          ocr,
          cic,
          uuid,
          name: user.name,
          email: user.email,
        })
      }

      const response = await fetch(`${API_URL}/gov/ine`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body,
      })

      // console.log('Api D', response)
      if (response.ok) {
        const responseJSON = await response.json()
        if (responseJSON?.estatus === 'ERROR') {
          setVisibleMsgApi(true)
          setContentMsgApi(
            responseJSON?.informacionAdicional ?? responseJSON?.mensaje
          )
          setVerificando(false)
        } else {
          onVerifyListaNominal(responseJSON)
          setDataListaNominal(responseJSON)
          setIsIneVerified(true)
          // Reset message error
          setVisibleMsgApi(false)
          setContentMsgApi('')
        }
      } else {
        const responseJSON = await response.json()
        setVisibleMsgApi(true)
        setContentMsgApi(
          responseJSON?.informacionAdicional ?? responseJSON?.mensaje
        )
        setVerificando(false)
      }
    } catch (error) {
      setVisibleMsgApi(true)
      setContentMsgApi(
        'INE ha Fallado inesperadamente, por favor realizar de nuevo la consulta'
      )
      setVerificando(false)
    }
  }

  const handleClickD = () => {
    callToIneApiD()
  }

  const callToIneApiC = async () => {
    setVerificando(true)
    try {
      const token = await getToken({
        audience: 'veridocid',
      })

      const response = await fetch(`${API_URL}/gov/ine`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
          ocr,
          claveElector: voterKey,
          numeroEmision: numEmision,
          uuid,
          name: user.name,
          email: user.email,
        }),
      })

      // console.log('Api C', response)
      if (response.ok) {
        const responseJSON = await response.json()
        if (responseJSON?.estatus === 'ERROR') {
          setVisibleMsgApi(true)
          setContentMsgApi(
            responseJSON?.informacionAdicional ?? responseJSON?.mensaje
          )
          setVerificando(false)
        } else {
          onVerifyListaNominal(responseJSON)
          setDataListaNominal(responseJSON)
          setIsIneVerified(true)

          // Reset message error
          setVisibleMsgApi(false)
          setContentMsgApi('')
        }
      } else {
        const responseJSON = await response.json()
        setVisibleMsgApi(true)
        setContentMsgApi(
          responseJSON?.informacionAdicional ?? responseJSON?.mensaje
        )
        setVerificando(false)
      }
    } catch (error) {
      setVisibleMsgApi(true)
      setContentMsgApi(
        'INE ha Fallado inesperadamente, por favor realizar de nuevo la consulta'
      )
      setVerificando(false)
    }
  }

  const handleClickC = () => {
    callToIneApiC()
  }

  const onVotingCardChangeHandler = (selected) => {
    if (selected?.value === 'VOTING_CARD_C') {
      setIsTypeC(true)
    } else {
      setIsTypeC(false)
    }
  }

  // Handle close message error
  const changeStateVisibleMsgErrorApi = () => {
    setVisibleMsgApi(false)
    setContentMsgApi('')
  }

  const showModalInfo = () => {
    Modal.info({
      content: (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <img
            src={votingCardCSideA}
            alt="VotingCardC"
            style={{ width: '20rem', height: '13rem' }}
          />
          <img
            src={votingCardCSideB}
            alt="VotingCardC"
            style={{ width: '20rem', height: '13rem' }}
          />
        </div>
      ),
      title: 'Credencial para votar Tipo C',
      width: '50rem',
    })
  }

  return (
    <Fragment>
      <div className="ui container">
        {ineStatus ? (
          <CardListaNominalWhitinfo
            dataListaNominal={dataIneFound || dataListaNominal}
            cic={cic}
            ocr={ocr}
            voterKey={voterKey}
            numEmision={numEmision}
            ciudadano={ciudadano}
          />
        ) : (
          <Fragment>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ width: '28rem' }}>
                <Select
                  onChange={(selectedVotingCard) => {
                    onVotingCardChangeHandler(selectedVotingCard)
                  }}
                  options={votingCardOptions}
                  defaultValue={valueSelect}
                />
              </div>
              <Button icon="info" onClick={showModalInfo} />
            </div>
            {isTypeC ? (
              <div className="ui container">
                <br />
                <Form>
                  <Form.Field>
                    <p>{t('ocrNumber')}</p>
                    <Input
                      placeholder={t('ocrNumber')}
                      value={ocr}
                      onChange={handleChangeInputOcr}
                      maxLength={13}
                    />
                  </Form.Field>
                </Form>
                <br />
                <Form>
                  <Form.Field>
                    <p>Clave de elector</p>
                    <Input
                      placeholder="Clave de elector"
                      value={voterKey}
                      onChange={handleChangeInputVoterKey}
                      maxLength={18}
                    />
                  </Form.Field>
                </Form>
                <br />
                <Form>
                  <Form.Field>
                    <p>Numero de emisión</p>
                    <Input
                      placeholder="Número de emisión"
                      value={numEmision}
                      onChange={handleChangeInputNumEmision}
                      maxLength={2}
                    />
                  </Form.Field>
                </Form>
                <br />
                {visibleMsgApi ? (
                  <Message
                    error
                    onDismiss={changeStateVisibleMsgErrorApi}
                    header={'Error Lista nominal'}
                    content={contentMsgApi}
                  />
                ) : null}
                <div className="ButtonsCard">
                  <Button
                    style={{
                      background: '#26285a',
                      color: '#fff',
                      margin: '0',
                    }}
                    onClick={handleClickC}
                    disabled={verificando}
                    loading={verificando}
                  >
                    {t('send')}
                  </Button>
                </div>
              </div>
            ) : (
              <div className="ui container">
                <br />
                <Form>
                  <Form.Field>
                    <p>{t('cicNumber')}</p>
                    <Input
                      placeholder={t('cicNumber')}
                      value={cic}
                      onChange={handleChangeInputCic}
                      maxLength={13}
                    />
                  </Form.Field>
                </Form>
                {/* <Form>
                      <Form.Field>
                        <p>Clave de elector</p>
                        <Input
                          placeholder="Clave de elector"
                          value={voterKey}
                          onChange={handleChangeInputVoterKey}
                          maxLength={18}
                        />
                      </Form.Field>
                    </Form> */}
                {/* <br />
                    <Form>
                      <Form.Field>
                        <p>Numero de emisión</p>
                        <Input
                          placeholder="Número de emisión"
                          value={numEmision}
                          onChange={handleChangeInputNumEmision}
                          maxLength={2}
                        />
                      </Form.Field>
                    </Form> */}
                <br />
                <Form>
                  <Form.Field>
                    {/* <p>Identificador ciudadano</p> */}
                    <p>{t('ocrNumber')}</p>
                    <Input
                      placeholder={t('ocrNumber')}
                      value={ocr}
                      onChange={handleChangeInputOcr}
                      maxLength={13}
                    />
                  </Form.Field>
                </Form>
                <br />
                {visibleMsgApi ? (
                  <Message
                    error
                    onDismiss={changeStateVisibleMsgErrorApi}
                    header={'Error Lista nominal'}
                    content={contentMsgApi}
                  />
                ) : null}

                <div className="ButtonsCard">
                  <Button
                    style={{
                      background: '#26285a',
                      color: '#fff',
                      margin: '0',
                    }}
                    onClick={handleClickD}
                    disabled={verificando}
                    loading={verificando}
                  >
                    {t('send')}
                  </Button>
                </div>
              </div>
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  )
}
export default ListaNominal
