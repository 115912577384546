import React, { useState } from 'react'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import './UploadImages.css'
import { InboxOutlined } from '@ant-design/icons'
import Upload from '../../../../components/Upload'
import { API_DASHBOARD_URL } from '../../../../const'
import useToken from '../../../../utils/useToken'

import { labels } from '../../../../fixtures/panelExperts.fixture'
import loading from '../../../../../src/assets/loading.svg'
import iconAwesomeCheck from '../../../../assets/icons/awesome-check.svg'
import iconAwesomeFail from '../../../../assets/icons/awesome-fail.svg'

const UploadImages = ({ onUpload, type, ...props }) => {
  const getToken = useToken()
  const { confirm } = Modal
  const [showIconAwesomeFront, setShowIconAwesomeFront] = useState(false)
  const [showIconAwesomeBack, setShowIconAwesomeBack] = useState(false)
  const [isFrontUploaded, setIsFrontUploaded] = useState(false)
  const [isBackUploaded, setIsBackUploaded] = useState(false)
  const [isFrontSelect, setIsFrontSelect] = useState(false)
  const [isBackSelect, setIsBackSelect] = useState(false)
  const [isFrontLoading, setIsFrontLoading] = useState(false)
  const [isBackLoading, setIsBackLoading] = useState(false)

  const [anverso, setAnverso] = useState(null)
  const [reverso, setReverso] = useState(null)
  const _onUpload = async ({ id, file }) => {
    const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    }

    let base64 = null

    if (file) {
      base64 = await getBase64(file)
      base64 = base64.split('base64,')[1]
    }

    //console.log(id)
    //console.log(base64)
    switch (id) {
      case 'anverso':
        setAnverso(base64)
        setIsFrontSelect((prevIsFrontSelect) => !prevIsFrontSelect)
        setShowIconAwesomeFront(false)
        setIsFrontUploaded(false)
        break
      case 'reverso':
        setReverso(base64)
        setIsBackSelect((prevIsBackSelect) => !prevIsBackSelect)
        setShowIconAwesomeBack(false)
        setIsBackUploaded(false)
        break
      default:
        break
    }
  }
  const replaceImage = async (id) => {
    switch (id) {
      case 'anverso':
        setIsFrontLoading(true)
        break
      case 'reverso':
        setIsBackLoading(true)
        break
      default:
        break
    }
    try {
      let image = null
      let name = null
      switch (id) {
        case 'anverso':
          image = anverso
          name = 'frontImageCropped'
          break
        case 'reverso':
          image = reverso
          name = 'backImageCropped'
          break
        default:
          break
      }

      const token = await getToken({
        audience: 'veridocid-dashboard',
      })

      const response = await fetch(
        `${API_DASHBOARD_URL}/experts/records/replaceImage`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            uuid: props.uuid,
            image,
            name,
            type,
          }),
        }
      )
      if (response.ok) {
        switch (id) {
          case 'anverso':
            setIsFrontLoading(false)
            setShowIconAwesomeFront(true)
            setIsFrontUploaded(true)
            onUpload && onUpload(image, 'front')
            break
          case 'reverso':
            setIsBackLoading(false)
            setShowIconAwesomeBack(true)
            setIsBackUploaded(true)
            onUpload && onUpload(image, 'back')
            break
          default:
            break
        }
      } else {
        const error = await response.json()
        console.error(error)
        switch (id) {
          case 'anverso':
            setIsFrontLoading(false)
            setShowIconAwesomeFront(true)
            break
          case 'reverso':
            setIsBackLoading(false)
            setShowIconAwesomeBack(true)
            break
          default:
            break
        }
      }
    } catch (error) {
      console.error(error)
      alert(JSON.stringify(error))
    }
    // setIsOverwriting(false)
  }

  const showModalConfirm = (image) => {
    confirm({
      title: '¿Estás seguro?',
      icon: <ExclamationCircleOutlined />,
      content: 'Esta acción es irreversible.',
      cancelText: 'No',
      okText: 'Si',
      onOk() {
        replaceImage(image)
      },
      onCancel() {},
    })
  }

  return (
    <div className="UploadImages">
      <div hidden={!isFrontLoading}>
        <img src={loading} alt="Loading" />
      </div>
      <div hidden={isFrontLoading} className="UploadFront">
        <p style={{ fontSize: '1.3rem' }}>{labels.selectNewFront}</p>
        <Upload id="anverso" onUpload={_onUpload}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
        </Upload>
        <img
          className="IconFront"
          src={isFrontUploaded ? iconAwesomeCheck : iconAwesomeFail}
          hidden={!showIconAwesomeFront}
          alt="Result"
        />
        <button
          className="ButtonConfirm"
          disabled={!isFrontSelect}
          onClick={() => showModalConfirm('anverso')}
        >
          {labels.confirmNewFront}
        </button>
      </div>
      <div hidden={!isBackLoading}>
        <img src={loading} alt="Loading" />
      </div>
      {props?.documentType !== 'PASSPORT' ? (
        <div hidden={isBackLoading} className="UploadBack">
          <p style={{ fontSize: '1.3rem' }}>{labels.selectNewBack}</p>
          <Upload id="reverso" onUpload={_onUpload}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
          </Upload>
          <img
            className="IconBack"
            src={isBackUploaded ? iconAwesomeCheck : iconAwesomeFail}
            hidden={!showIconAwesomeBack}
            alt="Result"
          />
          <button
            className="ButtonConfirm"
            disabled={!isBackSelect}
            onClick={() => showModalConfirm('reverso')}
          >
            {labels.confirmNewBack}
          </button>
        </div>
      ) : null}
    </div>
  )
}

export default UploadImages
