import React from 'react'

/**
 * labels for panelExperts
 */
export const labels = {
  title: 'Panel de Verificaciones',
  titleTemplateSelection: 'Selección de plantilla manual',
  titleVerificationResults: 'Resultados de la Verificación',
  identificator: 'Identificador',
  clientID: 'ID de Cliente',
  user: 'Usuario',
  name: 'Nombre',
  start: 'Desde',
  end: 'Hasta',
  filter: 'Filtrar',
  field: 'Campo',
  value: 'Valor',
  editDocumentData: 'Editar Datos del Documento',
  add: 'Añadir',
  save: 'Guardar',
  cancel: 'Cancelar',
  delete: 'Borrar',
  addDocumentData: 'Añadir datos del documento',
  editMRZ: 'Editar líneas MRZ',
  verifyMRZ: 'Verificar MRZ de nuevo',
  mrz: 'MRZ',
  result: 'Resultado',
  input: 'Entrada',
  output: 'Salida',
  date: 'Fecha',
  idClient: 'ID de Cliente',
  client: 'Cliente',
  typeDocument: 'T. de Documento',
  globalR: 'R. Global',
  globalResult: 'Resultado Global',
  status: 'Estado',
  show: 'Ver',
  check: 'Revisar',
  reject: 'Rechazar',
  accept: 'Aprobar',
  imageObtained: 'Imagen Obtenida',
  referenceDocument: 'Documento de Referencia',
  front: 'Anverso',
  back: 'Reverso',
  downloadImages: 'Descargar Imágenes',
  verify: 'Verificar',
  notProcess: 'No procesable',
  falseDocument: 'Documento Falso',
  securityLevel: 'Nivel de Seguridad',
  verificationResume: 'Resumen de la Verificación',
  documentData: 'Datos del Documento',
  identification: 'Identificación',
  dataFormat: 'Formato de Datos',
  dataIntegrity: 'Integridad de Datos',
  controlList: 'Lista de Control',
  securityFeatures: 'Características de Seguridad',
  sendBackIdentification: 'Enviar de vuelta a identificación',
  checked: 'Revisado',
  buttonBack: 'Atrás',
  overwrite: 'Sobreescribir',
  dimensionsOfficialDoc: 'Dimensiones de los documentos oficiales',
  infoImages: 'Información de las imágenes',
  filterDocuments: 'Filtro de Documentos Disponibles',
  documentType: 'Tipo de Documento',
  country: 'País',
  state: 'Estado/Provincia',
  availableDocuments: 'Documentos Disponibles',
  editImages: 'Editar Imágenes',
  uploadImages: 'Subir Imágenes Nuevas',
  expertsComments: 'Comentarios del Experto',
  editFront: 'Editar Anverso',
  editBack: 'Editar Reverso',
  selectNewFront: 'Seleccionar Nuevo Anverso',
  selectNewBack: 'Seleccionar Nuevo Reverso',
  confirmNewFront: 'Confirmar Anverso',
  confirmNewBack: 'Confirmar Reverso',
  confirm: 'Confirmar',
  rotateLeft: 'Girar a la Izquierda',
  rotateRight: 'Girar a la Derecha',
  cut: 'Recortar',
  undoChanges: 'Deshacer Cambios',
  startDate: 'Fecha de Inicio',
  endDate: 'Fecha de Fin',
  verificationTime: 'Tiempo de Verificación',
  verificationTotal: 'Verificaciones Totales',
  verificationSuccess: 'Verificaciones Exitosas',
  verificationWarnings: 'Verificaciones Dudosas',
  verificationFailed: 'Verificaciones Fallidas',
  spentCredits: 'Créditos Gastados',
  tableOfExperts: 'Mesa de Expertos',
  biometrics: 'Verificaciones Biométricas',
  fraudFaceList: 'Lista Negra Facial',
  identify: 'Identificar',
  select: 'Seleccionar',
  falsePositive: 'Falso Positivo',
  falsePositiveFace: 'Falso Positivo Facial',
  addFraudFace: 'Agregar a lista facial',
  documentImageQuality: 'Calidad de Imagen',
  range: 'Rango',
}

/**
 * Status for panel experts
 */
export const statusFixture = {
  checked: 'Checked',
  manual: 'ManualIdentification',
  waiting: 'WaitingChecking',
  checking: 'ManualChecking',
  falsePositive: 'FalsePositive',
  falsePositiveFace: 'FalsePositiveFace',
}

/**
 * Set current date
 */
const today = new Date()
const year = today.getFullYear()
const month = String(
  '' +
    (today.getMonth() + 1).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })
)
const date = String(
  '' +
    today
      .getDate()
      .toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
)
export const currentDate = `${year}-${month}-${date}`

/**
 * options for edit document data
 */
export const menuOptions = [
  { id: 's1', value: 'Surname', name: 'Apellido' },
  { id: 'ms1', value: 'MotherSurname', name: 'Apellido Materno' },
  { id: 'fs1', value: 'FatherSurname', name: 'Apellido Paterno' },
  { id: 'aa1', value: 'AddressArea', name: 'Área Dirección' },
  { id: 'pa1', value: 'PermanentAddress_Suburb', name: 'Barrio' },
  { id: 'pa2', value: 'PermanentAddress_Street', name: 'Calle' },
  { id: 'as1', value: 'AddressStreet', name: 'Calle Dirección' },
  { id: 'ac1', value: 'AddressCity', name: 'Ciudad Dirección' },
  { id: 'pa3', value: 'PermanentAddress_City', name: 'Ciudad/Estado' },
  { id: 'vk1', value: 'Voter_Key', name: 'Clave de Elector' },
  { id: 'ap1', value: 'AddressPostalCode', name: 'Código Postal Dirección' },
  { id: 'ac2', value: 'AddressCountryCode', name: 'Código de País Dirección' },
  { id: 'ic1', value: 'IssuerCountryCode', name: 'Código del País Emisor' },
  { id: 'pa4', value: 'PermanentAddress', name: 'Dirección' },
  { id: 'as2', value: 'Address_State', name: 'Estado' },
  { id: 'fd1', value: 'FullDateOfIssue', name: 'Fecha de Emisión' },
  { id: 'de1', value: 'DateOfExpiry', name: 'Fecha de Expiración' },
  { id: 'bd1', value: 'Birthdate', name: 'Fecha de Nacimiento' },
  { id: 'dr1', value: 'DateOfRegistration', name: 'Fecha de Registro' },
  { id: 'al1', value: 'Address_Location', name: 'Localidad' },
  { id: 'ip1', value: 'IssuingPlace', name: 'Lugar de Emisión' },
  { id: 'bp1', value: 'BirthdatePlace', name: 'Lugar de Nacimiento' },
  { id: 'rp1', value: 'RegistryPlace', name: 'Lugar de Registro' },
  { id: 'am1', value: 'Address_Municipality', name: 'Municipio' },
  { id: 'n1', value: 'Nacionality', name: 'Nacionalidad' },
  { id: 'n2', value: 'Name', name: 'Nombre' },
  { id: 'fn1', value: 'FullName', name: 'Nombre Completo' },
  { id: 'ic2', value: 'IssuerCountryName', name: 'Nombre del País Emisor' },
  { id: 'pn1', value: 'PersonalNumber', name: 'Número Personal' },
  { id: 'on1', value: 'OcrNumber', name: 'Número Personal OCR' },
  { id: 'asn1', value: 'AddressStreetNumber', name: 'Número de Calle' },
  { id: 'dn1', value: 'DocumentNumber', name: 'Número de Documento' },
  { id: 'dn2', value: 'NumberOfDuplicates', name: 'Número de Duplicados' },
  { id: 'ac3', value: 'AddressCountry', name: 'País Dirección' },
  { id: 'is1', value: 'IssuingStateName', name: 'País de Emisión' },
  { id: 'ac4', value: 'AddressCounty', name: 'Provincia Dirección' },
  { id: 's2', value: 'Section', name: 'Sección' },
  { id: 's3', value: 'Sex', name: 'Sexo' },
  { id: 'ast1', value: 'AddressStreetType', name: 'Tipo de Calle Dirección' },
]

/**
 * options about countries
 */
export const countryOptions = [
  { label: 'Afghanistan', value: 'AF' },
  { label: 'land Islands', value: 'AX' },
  { label: 'Albania', value: 'AL' },
  { label: 'Algeria', value: 'DZ' },
  { label: 'American Samoa', value: 'AS' },
  { label: 'AndorrA', value: 'AD' },
  { label: 'Angola', value: 'AO' },
  { label: 'Anguilla', value: 'AI' },
  { label: 'Antarctica', value: 'AQ' },
  { label: 'Antigua and Barbuda', value: 'AG' },
  { label: 'Argentina', value: 'AR' },
  { label: 'Armenia', value: 'AM' },
  { label: 'Aruba', value: 'AW' },
  { label: 'Australia', value: 'AU' },
  { label: 'Austria', value: 'AT' },
  { label: 'Azerbaijan', value: 'AZ' },
  { label: 'Bahamas', value: 'BS' },
  { label: 'Bahrain', value: 'BH' },
  { label: 'Bangladesh', value: 'BD' },
  { label: 'Barbados', value: 'BB' },
  { label: 'Belarus', value: 'BY' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Belize', value: 'BZ' },
  { label: 'Benin', value: 'BJ' },
  { label: 'Bermuda', value: 'BM' },
  { label: 'Bhutan', value: 'BT' },
  { label: 'Bolivia', value: 'BO' },
  { label: 'Bosnia and Herzegovina', value: 'BA' },
  { label: 'Botswana', value: 'BW' },
  { label: 'Bouvet Island', value: 'BV' },
  { label: 'Brazil', value: 'BR' },
  { label: 'British Indian Ocean Territory', value: 'IO' },
  { label: 'Brunei Darussalam', value: 'BN' },
  { label: 'Bulgaria', value: 'BG' },
  { label: 'Burkina Faso', value: 'BF' },
  { label: 'Burundi', value: 'BI' },
  { label: 'Cambodia', value: 'KH' },
  { label: 'Cameroon', value: 'CM' },
  { label: 'Canada', value: 'CA' },
  { label: 'Cape Verde', value: 'CV' },
  { label: 'Cayman Islands', value: 'KY' },
  { label: 'Central African Republic', value: 'CF' },
  { label: 'Chad', value: 'TD' },
  { label: 'Chile', value: 'CL' },
  { label: 'China', value: 'CN' },
  { label: 'Christmas Island', value: 'CX' },
  { label: 'Cocos (Keeling) Islands', value: 'CC' },
  { label: 'Colombia', value: 'CO' },
  { label: 'Comoros', value: 'KM' },
  { label: 'Congo', value: 'CG' },
  { label: 'Congo, The Democratic Republic of the', value: 'CD' },
  { label: 'Cook Islands', value: 'CK' },
  { label: 'Costa Rica', value: 'CR' },
  { label: 'Cote DIvoire', value: 'CI' },
  { label: 'Croatia', value: 'HR' },
  { label: 'Cuba', value: 'CU' },
  { label: 'Cyprus', value: 'CY' },
  { label: 'Czech Republic', value: 'CZ' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Djibouti', value: 'DJ' },
  { label: 'Dominica', value: 'DM' },
  { label: 'Dominican Republic', value: 'DO' },
  { label: 'Ecuador', value: 'EC' },
  { label: 'Egypt', value: 'EG' },
  { label: 'El Salvador', value: 'SV' },
  { label: 'Equatorial Guinea', value: 'GQ' },
  { label: 'Eritrea', value: 'ER' },
  { label: 'Estonia', value: 'EE' },
  { label: 'Ethiopia', value: 'ET' },
  { label: 'Falkland Islands (Malvinas)', value: 'FK' },
  { label: 'Faroe Islands', value: 'FO' },
  { label: 'Fiji', value: 'FJ' },
  { label: 'Finland', value: 'FI' },
  { label: 'France', value: 'FR' },
  { label: 'French Guiana', value: 'GF' },
  { label: 'French Polynesia', value: 'PF' },
  { label: 'French Southern Territories', value: 'TF' },
  { label: 'Gabon', value: 'GA' },
  { label: 'Gambia', value: 'GM' },
  { label: 'Georgia', value: 'GE' },
  { label: 'Germany', value: 'DE' },
  { label: 'Ghana', value: 'GH' },
  { label: 'Gibraltar', value: 'GI' },
  { label: 'Greece', value: 'GR' },
  { label: 'Greenland', value: 'GL' },
  { label: 'Grenada', value: 'GD' },
  { label: 'Guadeloupe', value: 'GP' },
  { label: 'Guam', value: 'GU' },
  { label: 'Guatemala', value: 'GT' },
  { label: 'Guernsey', value: 'GG' },
  { label: 'Guinea', value: 'GN' },
  { label: 'Guinea-Bissau', value: 'GW' },
  { label: 'Guyana', value: 'GY' },
  { label: 'Haiti', value: 'HT' },
  { label: 'Heard Island and Mcdonald Islands', value: 'HM' },
  { label: 'Holy See (Vatican City State)', value: 'VA' },
  { label: 'Honduras', value: 'HN' },
  { label: 'Hong Kong', value: 'HK' },
  { label: 'Hungary', value: 'HU' },
  { label: 'Iceland', value: 'IS' },
  { label: 'India', value: 'IN' },
  { label: 'Indonesia', value: 'value' },
  { label: 'Iran, Islamic Republic Of', value: 'IR' },
  { label: 'Iraq', value: 'IQ' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Isle of Man', value: 'IM' },
  { label: 'Israel', value: 'IL' },
  { label: 'Italy', value: 'IT' },
  { label: 'Jamaica', value: 'JM' },
  { label: 'Japan', value: 'JP' },
  { label: 'Jersey', value: 'JE' },
  { label: 'Jordan', value: 'JO' },
  { label: 'Kazakhstan', value: 'KZ' },
  { label: 'Kenya', value: 'KE' },
  { label: 'Kiribati', value: 'KI' },
  { label: 'Korea, Democratic PeopleS Republic of', value: 'KP' },
  { label: 'Korea, Republic of', value: 'KR' },
  { label: 'Kuwait', value: 'KW' },
  { label: 'Kyrgyzstan', value: 'KG' },
  { label: 'Lao PeopleS Democratic Republic', value: 'LA' },
  { label: 'Latvia', value: 'LV' },
  { label: 'Lebanon', value: 'LB' },
  { label: 'Lesotho', value: 'LS' },
  { label: 'Liberia', value: 'LR' },
  { label: 'Libyan Arab Jamahiriya', value: 'LY' },
  { label: 'Liechtenstein', value: 'LI' },
  { label: 'Lithuania', value: 'LT' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Macao', value: 'MO' },
  { label: 'Macedonia, The Former Yugoslav Republic of', value: 'MK' },
  { label: 'Madagascar', value: 'MG' },
  { label: 'Malawi', value: 'MW' },
  { label: 'Malaysia', value: 'MY' },
  { label: 'Maldives', value: 'MV' },
  { label: 'Mali', value: 'ML' },
  { label: 'Malta', value: 'MT' },
  { label: 'Marshall Islands', value: 'MH' },
  { label: 'Martinique', value: 'MQ' },
  { label: 'Mauritania', value: 'MR' },
  { label: 'Mauritius', value: 'MU' },
  { label: 'Mayotte', value: 'YT' },
  { label: 'Mexico', value: 'MX' },
  { label: 'Micronesia, Federated States of', value: 'FM' },
  { label: 'Moldova, Republic of', value: 'MD' },
  { label: 'Monaco', value: 'MC' },
  { label: 'Mongolia', value: 'MN' },
  { label: 'Montenegro', value: 'ME' },
  { label: 'Montserrat', value: 'MS' },
  { label: 'Morocco', value: 'MA' },
  { label: 'Mozambique', value: 'MZ' },
  { label: 'Myanmar', value: 'MM' },
  { label: 'Namibia', value: 'NA' },
  { label: 'Nauru', value: 'NR' },
  { label: 'Nepal', value: 'NP' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'Netherlands Antilles', value: 'AN' },
  { label: 'New Caledonia', value: 'NC' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Nicaragua', value: 'NI' },
  { label: 'Niger', value: 'NE' },
  { label: 'Nigeria', value: 'NG' },
  { label: 'Niue', value: 'NU' },
  { label: 'Norfolk Island', value: 'NF' },
  { label: 'Northern Mariana Islands', value: 'MP' },
  { label: 'Norway', value: 'NO' },
  { label: 'Oman', value: 'OM' },
  { label: 'Pakistan', value: 'PK' },
  { label: 'Palau', value: 'PW' },
  { label: 'Palestinian Territory, Occupied', value: 'PS' },
  { label: 'Panama', value: 'PA' },
  { label: 'Papua New Guinea', value: 'PG' },
  { label: 'Paraguay', value: 'PY' },
  { label: 'Peru', value: 'PE' },
  { label: 'Philippines', value: 'PH' },
  { label: 'Pitcairn', value: 'PN' },
  { label: 'Poland', value: 'PL' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Qatar', value: 'QA' },
  { label: 'Reunion', value: 'RE' },
  { label: 'Romania', value: 'RO' },
  { label: 'Russian Federation', value: 'RU' },
  { label: 'RWANDA', value: 'RW' },
  { label: 'Saint Helena', value: 'SH' },
  { label: 'Saint Kitts and Nevis', value: 'KN' },
  { label: 'Saint Lucia', value: 'LC' },
  { label: 'Saint Pierre and Miquelon', value: 'PM' },
  { label: 'Saint Vincent and the Grenadines', value: 'VC' },
  { label: 'Samoa', value: 'WS' },
  { label: 'San Marino', value: 'SM' },
  { label: 'Sao Tome and Principe', value: 'ST' },
  { label: 'Saudi Arabia', value: 'SA' },
  { label: 'Senegal', value: 'SN' },
  { label: 'Serbia', value: 'RS' },
  { label: 'Seychelles', value: 'SC' },
  { label: 'Sierra Leone', value: 'SL' },
  { label: 'Singapore', value: 'SG' },
  { label: 'Slovakia', value: 'SK' },
  { label: 'Slovenia', value: 'SI' },
  { label: 'Solomon Islands', value: 'SB' },
  { label: 'Somalia', value: 'SO' },
  { label: 'South Africa', value: 'ZA' },
  { label: 'South Georgia and the South Sandwich Islands', value: 'GS' },
  { label: 'Spain', value: 'ES' },
  { label: 'Sri Lanka', value: 'LK' },
  { label: 'Sudan', value: 'SD' },
  { label: 'Suriname', value: 'SR' },
  { label: 'Svalbard and Jan Mayen', value: 'SJ' },
  { label: 'Swaziland', value: 'SZ' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'Syrian Arab Republic', value: 'SY' },
  { label: 'Taiwan, Province of China', value: 'TW' },
  { label: 'Tajikistan', value: 'TJ' },
  { label: 'Tanzania, United Republic of', value: 'TZ' },
  { label: 'Thailand', value: 'TH' },
  { label: 'Timor-Leste', value: 'TL' },
  { label: 'Togo', value: 'TG' },
  { label: 'Tokelau', value: 'TK' },
  { label: 'Tonga', value: 'TO' },
  { label: 'Trinvaluead and Tobago', value: 'TT' },
  { label: 'Tunisia', value: 'TN' },
  { label: 'Turkey', value: 'TR' },
  { label: 'Turkmenistan', value: 'TM' },
  { label: 'Turks and Caicos Islands', value: 'TC' },
  { label: 'Tuvalu', value: 'TV' },
  { label: 'Uganda', value: 'UG' },
  { label: 'Ukraine', value: 'UA' },
  { label: 'United Arab Emirates', value: 'AE' },
  { label: 'United Kingdom', value: 'GB' },
  { label: 'United States', value: 'US' },
  { label: 'United States Minor Outlying Islands', value: 'UM' },
  { label: 'Uruguay', value: 'UY' },
  { label: 'Uzbekistan', value: 'UZ' },
  { label: 'Vanuatu', value: 'VU' },
  { label: 'Venezuela', value: 'VE' },
  { label: 'Viet Nam', value: 'VN' },
  { label: 'Virgin Islands, British', value: 'VG' },
  { label: 'Virgin Islands, U.S.', value: 'VI' },
  { label: 'Wallis and Futuna', value: 'WF' },
  { label: 'Western Sahara', value: 'EH' },
  { label: 'Yemen', value: 'YE' },
  { label: 'Zambia', value: 'ZM' },
  { label: 'Zimbabwe', value: 'ZW' },
]

/**
 * Mexico states
 */
export const mexicoStates = [
  { label: 'Aguascalientes', value: 'AG' },
  { label: 'Baja California Norte', value: 'BN' },
  { label: 'Baja California Sur', value: 'BS' },
  { label: 'Coahuila', value: 'CO' },
  { label: 'Chihuahua', value: 'CH' },
  { label: 'Colima', value: 'CL' },
  { label: 'Campeche', value: 'CA' },
  { label: 'Chiapas', value: 'CS' },
  { label: 'Ciudad de México', value: 'CDMX' },
  { label: 'Estado de México', value: 'EDO' },
  { label: 'Durango', value: 'DG' },
  { label: 'Guerrero', value: 'GE' },
  { label: 'Guanajuato', value: 'GJ' },
  { label: 'Hvaluealgo', value: 'HD' },
  { label: 'Jalisco', value: 'JA' },
  { label: 'Michoacán', value: 'MC' },
  { label: 'Morelos', value: 'MR' },
  { label: 'Nayarit', value: 'NA' },
  { label: 'Nuevo León', value: 'NL' },
  { label: 'Oaxaca', value: 'OA' },
  { label: 'Puebla', value: 'PU' },
  { label: 'Querétaro', value: 'QE' },
  { label: 'Quintana Roo', value: 'QI' },
  { label: 'Sinaloa', value: 'SI' },
  { label: 'San Luis Potosí', value: 'SL' },
  { label: 'Sonora', value: 'SO' },
  { label: 'Tamaulipas', value: 'TA' },
  { label: 'Tabasco', value: 'TB' },
  { label: 'Tlaxcala', value: 'TL' },
  { label: 'Veracruz', value: 'VC' },
  { label: 'Yucatán', value: 'YU' },
  { label: 'Zacatecas', value: 'ZA' },
]

/**
 * options about documents
 */
export const documentOptions = [
  { id: 'v2', value: 'VOTING_CARD', label: 'Carnet de Votante' },
  { id: 'p1', value: 'PASSPORT', label: 'Pasaporte' },
  { id: 'c1', value: 'DRIVING_LICENSE', label: 'Carnet de Conducir' },
  { id: 'i1', value: 'IDENTITY_CARD', label: 'Carnet de Identidad' },
  { id: 'i2', value: 'IMMIGRANT_CARD', label: 'Tarjeta de Inmigrante' },
  { id: 'r2', value: 'RESIDENCE_PERMIT', label: 'Residente Permanente' },
  { id: 'o1', value: 'NOT_DEFINED', label: 'Otro' },
  {
    id: 'r3',
    value: 'REGISTRATION_CERTIFICATE',
    label: 'Certificado de Registro',
  },
  {
    id: 'r1',
    value: 'REFUGEE_TRAVEL_DOCUMENT',
    label: 'Documento de Refugiado',
  },
  { id: 's1', value: 'SEDESOL_CARD', label: 'Tarjeta SEDESOL' },
  { id: 'v1', value: 'VISA', label: 'Visado' },
  { id: 'l1', value: 'LAISSEZ_PASSER', label: 'Laissez Passer' },
]

/**
 * options about comment experts
 */
export const commentOptions = [
  {
    label: 'No es posible verificar el documento, cortado/incompleto.',
    value: 'comment01',
  },
  {
    label: 'No es posible verificar el documento, desgastado/maltratado.',
    value: 'comment02',
  },
  {
    label: 'No es posible verificar el documento, no es legible.',
    value: 'comment03',
  },
  {
    label:
      'No es posible verificar el documento, el anverso y reverso son iguales.',
    value: 'comment04',
  },
  {
    label:
      'No es posible verificar el documento, incompleto, le falta el anverso/reverso.',
    value: 'comment05',
  },
  {
    label:
      'No es posible verificar el documento, el anverso y reverso son diferentes identificaciones.',
    value: 'comment06',
  },
  {
    label:
      'No es posible verificar el documento, el anverso y reverso están invertidos.',
    value: 'comment07',
  },
  { label: 'El tipo de documento no fue identificado.', value: 'comment08' },
  { label: 'El documento es una captura de pantalla.', value: 'comment09' },
  { label: 'La vigencia del documento esta vencida.', value: 'comment10' },
  {
    label:
      'No es posible verificar el documento, el anverso/reverso contiene más de un documento en la misma imagen.',
    value: 'comment11',
  },
  {
    label: 'El documento no es una identificación oficial.',
    value: 'comment12',
  },
  { label: 'Documento Alterado.', value: 'comment13' },
  { label: 'El documento es una fotocopia.', value: 'comment14' },
  { label: 'Nombre encontrado en listas negras.', value: 'comment15' },
  {
    label:
      'Para la verificación de Pasaporte solo se captura la hoja de datos.',
    value: 'comment16',
  },
  {
    label:
      'Biometría facial no aprobada, el rostro de la selfie está cubierto/incompleto.',
    value: 'comment17',
  },
  {
    label:
      'Biometría facial no aprobada, el rostro de la selfie no corresponde con la fotografía del documento.',
    value: 'comment18',
  },
  {
    label:
      'Biometría facial no aprobada, porcentaje de coincidencia entre la selfie y la fotografía del documento bajo.',
    value: 'comment19',
  },
  {
    label: 'Inconsistencias en la extracción de datos (OCR) del documento.',
    value: 'comment20',
  },
  {
    label:
      'Inconsistencias en las verificaciones en la sección de formato de los datos del documento.',
    value: 'comment21',
  },
  {
    label:
      'Inconsistencias en las verificaciones en la sección de integridad de los datos del documento.',
    value: 'comment22',
  },
  {
    label:
      'El área del MRZ no se pudo procesar correctamente, vuelva a intentar con imágenes de mejor calidad.',
    value: 'comment23',
  },
]

export const commentOptionsCaliente = [
  { label: 'Caliplay: FAKE ID/ID FALSO', value: 'comment24' },
  { label: 'Caliplay: BLACK LISTS/LISTA NEGRA', value: 'comment25' },
  { label: 'Caliplay: COPY ID/ID FOTOCOPIA', value: 'comment26' },
  { label: 'Caliplay: CROPPED ID/ID RECORTADO', value: 'comment27' },
  { label: 'Caliplay: UNREADABLE ID/ID ILEGIBLE', value: 'comment28' },
  { label: 'Caliplay: SCANNED ID/ID ESCANEADO', value: 'comment29' },
  { label: 'Caliplay: SCREENSHOT ID/ID DE PANTALLA', value: 'comment30' },
  { label: 'Caliplay: PENDING ID/ID PENDIENTE', value: 'comment31' },
  { label: 'Caliplay: FOREIGN ID/ID EXTRANJERO', value: 'comment32' },
  {
    label: 'Caliplay: NAME MISMATCH/NO COINCIDE EL NOMBRE',
    value: 'comment33',
  },
  { label: 'Caliplay: BAD ID/ID MALO', value: 'comment34' },
  { label: 'Caliplay: ID ERROR/ERROR DE ID', value: 'comment35' },
  { label: 'Caliplay: NOT SUPPORTED', value: 'comment36' },
  { label: 'Caliplay: UNDER AGE PLAYER', value: 'comment37' },
  { label: 'Caliplay: EXPIRED ID', value: 'comment38' },
]

/**
 * Initial component state
 */
export const initialComponent = { content: <span />, status: false }

/**
 * Initial checked modal
 */
export const initialCheckedModal = { isChecked: false, open: false }

/**
 * Initial error modal
 */
export const initialErrorModal = { isError: false, open: false }

/**
 * Initial send modal
 */
export const initalSendedModal = { isSended: false, open: false }

/**
 * Initial warning modal
 */
export const initialWarningModal = { isWarning: false, open: false }

/**
 * Initial manual identification modal
 */
export const initialManualIdentificationModal = {
  isManualIdentification: false,
  open: false,
}

/**
 * Mocks for template selection
 */
export const mockInfoImages = (
  <div className="mockInfo">
    <span>Anverso: 774 x 1032 pixels, 72 DPI, 27.31 x 36.41 cms</span>
    <span>Reverso: 774 x 1032 pixels, 72 DPI, 27.31 x 36.41 cms</span>
  </div>
)
export const mockDimensionsImages = (
  <div className="mockDimensions">
    <span>P: 12.5 × 8.8 cms</span>
    <span>TD1: 8.56 × 5.39 cms</span>
    <span>TD2: 10.5 × 7.4 cms</span>
  </div>
)

/**
 * Categories of Panel Experts
 */
export const categories = {
  biometrics: 'Biometrics',
  controlList: 'ControlList',
  formatData: 'DataFormat',
  integrityData: 'DataIntegrity',
  identification: 'Identification',
  securityFeatures: 'SecurityFeatures',
  documentData: 'DocumentData',
  verificationResume: 'VerificationResume',
  fraudFaceList: 'FraudFaceList',
  documentImageQuality: 'DocumentImageQuality',
}
