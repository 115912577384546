import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { message, Modal } from 'antd'
import { Grid, Segment, Button } from 'semantic-ui-react'
import { InboxOutlined } from '@ant-design/icons'

// Components
import Upload from './Upload'

// Utils
import useToken from '../utils/useToken'
import { checkVerificationStatus } from '../utils/checkVerificationStatus'
import { API_URL } from '../const'
import { useAuth0 } from '../react-auth0-spa'

const FormSecurityScanner = ({ id, uuid }) => {
  const getToken = useToken()
  const { t } = useTranslation()
  const { user } = useAuth0()
  const [frontImage, setFrontImage] = useState(null)
  const [frontInfraredImage, setFrontInfraredImage] = useState(null)
  const [frontUltravioletImage, setFrontUltravioletImage] = useState(null)
  const [backImage, setBackImage] = useState(null)
  const [backInfraredImage, setBackInfraredImage] = useState(null)
  const [backUltravioletImage, setBackUltravioletImage] = useState(null)
  const [verificando, setVerificando] = useState(false)

  /** */
  const verificar = async () => {
    if (backImage && !backUltravioletImage && !backInfraredImage) {
      message.error('Cargar imagenes de reverso restantes.')
      return
    }

    setVerificando(true)

    try {
      const token = await getToken({
        audience: 'veridocid',
      })

      const body =
        frontImage && backImage
          ? JSON.stringify({
              id,
              frontImage,
              frontInfraredImage,
              frontUltravioletImage,
              backImage,
              backInfraredImage,
              backUltravioletImage,
              uuid,
              name: user.name,
              email: user.email,
              user_id: user.sub
            })
          : JSON.stringify({
              id,
              frontImage,
              frontInfraredImage,
              frontUltravioletImage,
              uuid,
              name: user.name,
              email: user.email,
              user_id: user.sub
            })

      const result = await fetch(`${API_URL}/id/v3/securityScanner`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body,
      })

      if (result.ok) {
        const uuid = await result.text()
        const status = await checkVerificationStatus({
          uuid,
          token,
          isScanner: true,
        })
        if (status === 'Checked') {
          showModalInfo('ok')
          return
        } else if (
          ['ManualIdentification', 'ManualChecking'].includes(status)
        ) {
          showModalInfo('experts')
          return
        } else {
          showModalInfo()
          return
        }
      } else {
        const error = await result.text()

        if (error === 'ID already exists.') {
          showModalInfo('exists')
        } else {
          const errorJson = await result.json()

          console.error(errorJson)
          showModalInfo('failed')
        }
      }
      setVerificando(false)
    } catch (error) {
      console.log(error)
      showModalInfo('failed')
      setVerificando(false)
    }
  }

  /** */
  const onUpload = async ({ id, file }) => {
    // console.log(file)
    const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    }

    let base64 = null

    if (file) {
      base64 = await getBase64(file)
      base64 = base64.split('base64,')[1]
    }

    switch (id) {
      case 'front':
        setFrontImage(base64)
        break
      case 'frontInfrared':
        setFrontInfraredImage(base64)
        break
      case 'frontUltraviolet':
        setFrontUltravioletImage(base64)
        break
      case 'back':
        setBackImage(base64)
        break
      case 'backInfrared':
        setBackInfraredImage(base64)
        break
      case 'backUltraviolet':
        setBackUltravioletImage(base64)
        break
      default:
        break
    }
  }

  const showModalInfo = (status) => {
    switch (status) {
      case 'ok':
        Modal.success({
          content: `Tu verificación se ha realizado correctamente.`,
          onOk() {
            window.location.reload()
          },
        })
        break
      case 'failed':
        Modal.error({
          title: '¡Oh, no! Algo salió mal',
          content: 'Por favor, vuelve a intentarlo.',
        })
        break
      case 'experts':
        Modal.warning({
          content: `Tu verificación ha sido enviada a la mesa de expertos.`,
          onOk() {
            window.location.reload()
          },
        })
        break
      case 'exists':
        Modal.error({
          title: 'El id ingresado ya existe.',
          content: 'Intenta ingresando otro id.',
        })
        break
      default:
        Modal.warning({
          content: `La verificación ha tomado más tiempo de lo esperado.`,
          onOk() {
            window.location.reload()
          },
        })
    }
  }

  return (
    <Segment attached>
      <Grid columns="equal">
        {/* Anverso */}
        <div
          style={{ width: '100%', textAlign: 'center', padding: '0.6rem 0' }}
        >
          <h3
            style={{
              textAlign: 'center',
              padding: '0.6rem 0',
            }}
          >
            {t('frontImage')}
          </h3>
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <div style={{ margin: '1rem auto' }}>
            <p style={{ textAlign: 'center' }}>{t('white')}</p>
            <Upload id="front" onUpload={onUpload}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
            </Upload>
          </div>
          <div style={{ margin: '1rem auto' }}>
            <p style={{ textAlign: 'center' }}>{t('infrared')}</p>
            <Upload id="frontInfrared" onUpload={onUpload}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
            </Upload>
          </div>
          <div style={{ margin: '1rem auto' }}>
            <p style={{ textAlign: 'center' }}>{t('ultraviolet')}</p>
            <Upload id="frontUltraviolet" onUpload={onUpload}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
            </Upload>
          </div>
        </div>
        {/* Reverso */}
        <div
          style={{ width: '100%', textAlign: 'center', padding: '0.6rem 0' }}
        >
          <h3
            style={{
              textAlign: 'center',
              padding: '0.6rem 0',
            }}
          >
            {t('backImage')}
          </h3>
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <div style={{ margin: '1rem auto' }}>
            <p style={{ textAlign: 'center' }}>{t('white')}</p>
            <Upload id="back" onUpload={onUpload}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
            </Upload>
          </div>
          <div style={{ margin: '1rem auto' }}>
            <p style={{ textAlign: 'center' }}>{t('infrared')}</p>
            <Upload id="backInfrared" onUpload={onUpload}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
            </Upload>
          </div>
          <div style={{ margin: '1rem auto' }}>
            <p style={{ textAlign: 'center' }}>{t('ultraviolet')}</p>
            <Upload id="backUltraviolet" onUpload={onUpload}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
            </Upload>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1rem 1rem 1.7rem',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <p>
            <strong style={{ color: 'red' }}>{t('note')}:</strong>{' '}
            {t('notePassport')}
          </p>
          <Button
            disabled={
              verificando ||
              !frontImage ||
              !frontInfraredImage ||
              !frontUltravioletImage
            }
            loading={verificando}
            style={{ background: '#26285a', color: '#fff' }}
            onClick={verificar}
          >
            {t('verifyDocument')}
          </Button>
        </div>
      </Grid>
    </Segment>
  )
}

export default FormSecurityScanner
