import React, { useState } from 'react'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import './Biometrics.css'
import { labels, categories } from '../../../../fixtures/panelExperts.fixture'
import iconAwesomeCheck from '../../../../assets/icons/awesome-check.svg'
import iconAwesomeFail from '../../../../assets/icons/awesome-fail.svg'
import { API_DASHBOARD_URL } from '../../../../const'
import useToken from '../../../../utils/useToken'

const Biometrics = ({
  uuid,
  type,
  livePortrait,
  documentPortrait,
  ...props
}) => {
  const getToken = useToken()
  const { confirm } = Modal
  const [isClicked, setIsClicked] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const [output, setOutput] = useState(null)
  const [isOverwriting, setIsOverwriting] = useState(false)
  const dataBiometrics = props.response

  const onCancelHandler = () => {
    setIsEdit(false)
    setIsClicked(null)
  }

  const onEditHandler = (key) => {
    setIsClicked(key)
    setIsEdit(true)
  }

  const onChangeInputHandler = (e) => {
    const newOutput = e.target.value
    setOutput(Number(newOutput))
    if (newOutput.match(/[0-9]/g) && Number(newOutput) <= 100) {
      setIsOverwriting(true)
    } else {
      setIsOverwriting(false)
    }
  }

  const showModalConfirm = (value) => {
    confirm({
      title: '¿Estás seguro?',
      icon: <ExclamationCircleOutlined />,
      content: 'Esta acción es irreversible.',
      cancelText: 'No',
      okText: 'Si',
      onOk() {
        onChangeBiometricsValue(value)
      },
      onCancel() {},
    })
  }

  const showModalInfo = (status) => {
    if (status) {
      Modal.success({
        content: `Se ha modificado el valor correctamente.`,
        onOk() {
          window.location.reload()
        },
      })
    } else {
      Modal.error({
        title: '¡Oh, no! Algo salió mal',
        content: 'Por favor, vuelve a intentarlo.',
      })
    }
  }

  const onChangeBiometricsValue = async (key) => {
    try {
      const token = await getToken({
        audience: 'veridocid-dashboard',
      })
      dataBiometrics[key].output = output
      const response = await fetch(
        `${API_DASHBOARD_URL}/experts/records/updateDocumentVerification`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            uuid,
            output,
            category: categories.biometrics,
            key,
            type,
          }),
        }
      )
      if (response.ok) {
        showModalInfo(true)
        onCancelHandler()
      } else {
        const error = await response.json()
        console.error(error)
        showModalInfo(false)
      }
    } catch (error) {
      showModalInfo(false)
      console.error(error)
    }
    setIsOverwriting(false)
  }

  const rows = dataBiometrics?.map((resp, index) => {
    const value =
      isEdit && isClicked === index ? (
        <input
          defaultValue={resp?.output}
          style={{ width: '3rem' }}
          minLength="1"
          maxLength="6"
          onChange={(event) => onChangeInputHandler(event)}
        />
      ) : (
        <span className="BiometricsOutput" onClick={() => onEditHandler(index)}>
          {resp?.output}
        </span>
      )

    const rowOutput = resp?.result ? (
      <td className="BiometricsRows">{resp?.output}</td>
    ) : (
      <td className="BiometricsRows">
        {value}
        {isClicked === index ? (
          <div className="EditButtons">
            <button className="CancelButton" onClick={onCancelHandler}>
              {labels.cancel}
            </button>
            <button
              className="OverwriteButton"
              disabled={!isOverwriting}
              onClick={() => showModalConfirm(isClicked)}
            >
              {labels.overwrite}
            </button>
          </div>
        ) : null}
      </td>
    )

    const showModalDetails = () => {
      const rowsIcaoAttributes = resp?.icaoAttributes?.map((attr, index) => (
        <tr key={index}>
          <td className="BiometricsRows">{attr?.name}</td>
          <td className="BiometricsRows">{attr?.rangeStatus}</td>
          <td className="BiometricsRows">
            <img
              src={attr?.result ? iconAwesomeCheck : iconAwesomeFail}
              alt="Result"
              style={{ width: '0.9rem' }}
            />
          </td>
        </tr>
      ))

      const content = (
        <table className="TableBiometrics">
          <thead>
            <tr>
              <th className="ThBiometrics" style={{ width: '35%' }}>
                <b>{labels.name || '-'}</b>
              </th>
              <th className="ThBiometrics" style={{ width: '40%' }}>
                <b>{labels.range || '-'}</b>
              </th>
              <th className="ThBiometrics" style={{ width: '30%' }}>
                <b>{labels.result || '-'}</b>
              </th>
            </tr>
          </thead>
          <tbody>{rowsIcaoAttributes}</tbody>
        </table>
      )

      Modal.info({
        title: 'Icao Attributes',
        content,
      })
    }

    return (
      <tr key={index}>
        <td className="BiometricsRows">
          {resp?.icaoAttributes ? (
            <div className="BiometricsDetail">
              {resp?.name}
              <button className="DetailButton" onClick={showModalDetails}>
                Detalles
              </button>
            </div>
          ) : (
            resp?.name
          )}
        </td>
        <td className="BiometricsRows">
          {livePortrait && livePortrait !== null ? (
            <img
              style={{
                maxWidth: '10rem',
                maxHeight: '10rem',
                margin: '0 1rem',
              }}
              src={`${livePortrait?.data}`}
              alt="portrait"
            />
          ) : (
            <p>Sin imagen</p>
          )}
          {documentPortrait && documentPortrait !== null ? (
            <img
              style={{
                maxWidth: '10rem',
                maxHeight: '10rem',
                margin: '0 1rem',
              }}
              src={`${documentPortrait?.data}`}
              alt="portrait of the documet"
            />
          ) : (
            <p>Sin imagen</p>
          )}
        </td>
        {rowOutput}
        <td className="BiometricsRows">
          <img
            src={resp?.result ? iconAwesomeCheck : iconAwesomeFail}
            alt="Result"
            style={{ width: '0.9rem' }}
          />
        </td>
      </tr>
    )
  })

  return (
    <div className="Biometrics">
      <table className="TableBiometrics">
        <thead>
          <tr>
            <th className="ThBiometrics" style={{ width: '25%' }}>
              <b>{labels.name || '-'}</b>
            </th>
            <th className="ThBiometrics" style={{ width: '40%' }}>
              <b>{labels.input || '-'}</b>
            </th>
            <th className="ThBiometrics" style={{ width: '20%' }}>
              <b>{labels.output || '-'}</b>
            </th>
            <th className="ThBiometrics" style={{ width: '15%' }}>
              <b>{labels.result || '-'}</b>
            </th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  )
}

export default Biometrics
