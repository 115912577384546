import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'

export const Right = styled(Grid)({
  width: '100%',
  height: '950px',
  backgroundColor: '#F6F8FA',
  position: 'relative',
  overflowY: 'auto',
  userSelect: 'none',
})

export const MessageZone = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-around',
  textAlign: 'center',
  margin: '10px auto',
  padding: '0.6rem 1.3rem',
})

export const MessageBox = styled(Grid)((props) => ({
  cursor: 'pointer',
  borderBottom: '1px solid #ccc',
  backgroundColor: '#d7d7d7b8',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'auto',
  height: props.height ? props.height : '30px',
  fontSize: props.fontSize ? props.fontSize : '13px',
  padding: props.padding ? props.padding : '0 5px',
}))

export const TextBiometric = styled('p')({
  margin: '0',
  color: 'var(--black-light-color)',
  fontSize: 'clamp(0.85rem, 0.88rem, 0.9rem)',
  padding: '0.2rem 0 0.2rem',
})
