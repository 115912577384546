import { styled } from '@mui/material/styles'
import { Snackbar, Alert } from '@mui/material'

export const Snack = styled(Snackbar)({
  width: '330px',
  minHeight: '30px',
  backgroundColor: '#fff',
  borderRadius: '4%',
})

export const SnackMessage = styled(Alert)((props) => ({
  width: '330px',
  minHeight: '30px',
  backgroundColor: props.bgcolor ? `${props.bgcolor}33` : '#fff',
  border: `2px solid ${
    props.bgcolor ? `${props.bgcolor}88` : 'var(--secondary-color)'
  }`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  userSelect: 'none',
}))
