import React, { Fragment, useState } from 'react'
import { Button, Form, Label, Transition } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

import '../ConsultationServices.css'
import CardRFCWithInfo from './CardRFCWithInfo.js'
import useToken from '../../../utils/useToken'
import { API_URL } from '../../../const/index'
import { useAuth0 } from '../../../react-auth0-spa'

const RFC = ({
  id,
  uuid,
  rfc,
  rfcStatus,
  dataRfcFound,
  setIsRfcVerified,
  handleChangeInputRFC,
  onVerifyRfc,
}) => {
  const [dataRfc, setDataRfc] = useState(null)
  const [verificando, setVerificando] = useState(false)

  //Control de errores
  const [inputError, setInputError] = useState(false)
  const [inputMsgErr, setInputMsgErr] = useState('')

  //Configurations
  const { t } = useTranslation()
  const { user } = useAuth0()
  const getToken = useToken()

  const callToVerifyRFC = async () => {
    setVerificando(true)
    try {
      const token = await getToken({
        audience: 'veridocid',
      })

      const response = await fetch(`${API_URL}/gov/rfc/validate`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
          rfc,
          uuid,
          name: user.name,
          email: user.email,
        }),
      })

      if (response.ok) {
        const reponseJSON = await response.json()

        if (reponseJSON?.estatus === 'OK') {
          onVerifyRfc(reponseJSON)
          setDataRfc(reponseJSON)
          setIsRfcVerified(true)
          //console.log(reponseJSON)
        } else {
          setIsRfcVerified(false)
          //Error
          setInputError(true)
          setInputMsgErr(reponseJSON?.mensaje)
          setTimeout(() => {
            setInputError(false)
          }, 3000)
          //console.log(reponseJSON)
        }
      } else {
        console.log(`La respuesta de la API FRC no se encontro`)
      }

      setVerificando(false)
    } catch (error) {
      console.log(`La llamada a la API de FRC a fallo`)
      setVerificando(false)
    }
  }

  // Call API to verify a RFC
  const handleClick = () => {
    if (rfc !== '') {
      setInputError(false)
      callToVerifyRFC()
      //console.log(`${rfc}`)
    } else {
      setInputError(true)
      setInputMsgErr(`${t('messageErrorEmptyInputRfc')}`)
      setTimeout(() => {
        setInputError(false)
      }, 3000)
    }
  }

  return (
    <Fragment>
      <div
        className="ui container"
        style={{ marginLeft: '0', marginRight: '0' }}
      >
        {rfcStatus ? (
          <CardRFCWithInfo dataRfc={dataRfcFound || dataRfc} />
        ) : (
          <>
            <Form>
              <Form.Field>
                <p>{t('rfc')}</p>

                <div className="ui fluid input">
                  <input
                    type="text"
                    value={rfc}
                    maxLength={13}
                    placeholder="RFC"
                    onChange={handleChangeInputRFC}
                    style={{ textTransform: 'uppercase' }}
                  />
                </div>

                <Transition
                  animation="drop"
                  duration={500}
                  visible={inputError}
                >
                  <Label
                    pointing
                    color="red"
                    as="span"
                    style={{ lineHeight: '15px' }}
                  >
                    {`${inputMsgErr}`}
                  </Label>
                </Transition>
              </Form.Field>
            </Form>
            <br />
            <div className="ButtonsCard">
              <Button
                onClick={handleClick}
                disabled={verificando}
                loading={verificando}
              >
                {t('send')}
              </Button>
            </div>
          </>
        )}
      </div>
    </Fragment>
  )
}

export default RFC
