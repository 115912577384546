import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'

export const ObserversGrid = styled('div')({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 'clamp(.5rem,.85rem,.9rem)',
  marginBottom: '1rem',
})

export const ObserversGridLeft = styled('div')({
  width: '70%',
})

export const ObserversGridRight = styled('div')({
  width: '30%',
  textAlign: 'center',
})

export const ObserversCard = styled(Grid)({
  width: '100%',
  minHeight: '250px',
  backgroundColor: '#fff',
  padding: '20px 18px',
  display: 'flex',
  flexDirection: 'column',
})
