import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useParams, useHistory } from 'react-router-dom'
import download from 'downloadjs'
import { Typography } from 'antd'
import { useAuth0 } from '../../../react-auth0-spa'
import { API_DASHBOARD_URL } from '../../../const'
import useToken from '../../../utils/useToken'
import {
  labels,
  initialComponent,
  initialErrorModal,
  initialCheckedModal,
  initialManualIdentificationModal,
  statusFixture,
  categories,
  commentOptions,
  commentOptionsCaliente,
} from '../../../fixtures/panelExperts.fixture'

// Styles
import './BodyVerificationResults.css'

// Components
// import CopyIdPopup from '../../CopyIdPopup'
import DocumentData from './DocumentData/DocumentData'
import FormatData from './FormatData/FormatData'
import Identification from './Identification/Identification'
import IntegrityData from './IntegrityData/IntegrityData'
import ControlList from './ControlList/ControlList'
import SecurityFeatures from './SecurityFeatures/SecurityFeatures'
import VerificationResume from './VerificationResume/VerificationResume'
import Biometrics from './Biometrics/Biometrics'
import FaceFraudList from './FaceFraudList/FaceFraudList'
import DocumentImageQuality from './DocumentImageQuality/DocumentImageQuality'
import CarouselExperts from '../../UI/CarouselExperts/CarouselExperts'
import Button from '../../UI/Button/Button'
import Spinner from '../../UI/Spinner/Spinner'
import ModalExperts from '../../UI/ModalExperts/ModalExperts'

// Assets
import ineFront from '../../../assets/inefront.png'
import ineBack from '../../../assets/ineback.png'
import passport from '../../../assets/pasaporte.png'
import warning from '../../../assets/icons/warning.svg'
import check from '../../../assets/icons/check.svg'
import addBlack from '../../../assets/icons/add-black.svg'
import addWhite from '../../../assets/icons/add-white.svg'
import minusBlack from '../../../assets/icons/minus-black.svg'
import minusWhite from '../../../assets/icons/minus-white.svg'

//Shared
import {
  filterByCategory,
  filterCheckResult,
  filterCategoryCheck,
  filterCheckResultFalse,
  filterCheckResultTrue,
} from '../../../shared/filters'

const BodyVerificationResults = () => {
  const { uuid, type } = useParams()
  const { user } = useAuth0()
  const getToken = useToken()
  const { Paragraph } = Typography
  const [data, setData] = useState(null)
  const [contentData, setContentData] = useState(initialComponent)
  const [contentFormat, setContentFormat] = useState(initialComponent)
  const [contentIdentification, setContentIdentification] =
    useState(initialComponent)
  const [contentIntegrity, setContentIntegrity] = useState(initialComponent)
  const [contentControl, setContentControl] = useState(initialComponent)
  const [contentSecurity, setContentSecurity] = useState(initialComponent)
  const [contentResume, setContentResume] = useState(initialComponent)
  const [contentBiometrics, setContentBiometrics] = useState(initialComponent)
  const [contentFraudFaceList, setContentFraudFaceList] =
    useState(initialComponent)
  const [contentImageQuality, setContentImageQuality] =
    useState(initialComponent)
  const [isResumeOpen, setIsResumeOpen] = useState(false)
  const [isDataOpen, setIsDataOpen] = useState(false)
  const [isIdentificationOpen, setIsIdentificationOpen] = useState(false)
  const [isFormatOpen, setIsFormatOpen] = useState(false)
  const [isIntegrityOpen, setIsIntegrityOpen] = useState(false)
  const [isConstrolOpen, setIsControlOpen] = useState(false)
  const [isSecurityOpen, setIsSecurityOpen] = useState(false)
  const [isBiometricsOpen, setIsBiometricsOpen] = useState(false)
  const [isFraudFaceListOpen, setIsFraudFaceListOpen] = useState(false)
  const [isImageQualityOpen, setIsImageQualityOpen] = useState(false)
  const [controlListModified, setControlListModified] = useState([])

  const [documentVerifications, setDocumentVerifications] = useState(null)
  const [anverso, setAnverso] = useState(null)
  const [reverso, setReverso] = useState(null)
  const [frontUltraviolet, setFrontUltraviolet] = useState(null)
  const [backUltraviolet, setBackUltraviolet] = useState(null)
  const [frontInfrared, setFrontInfrared] = useState(null)
  const [backInfrared, setBackInfrared] = useState(null)
  const [livePortrait, setLivePortrait] = useState(null)
  const [documentPortrait, setDocumentPortrait] = useState(null)
  const [documentType, setDocumentType] = useState(null)
  const [globalResult, setGlobalResult] = useState(null)
  const [securityLevel, setSecurityLevel] = useState(null)
  const [formatData, setFormatData] = useState(null)
  const [integrityData, setIntegrityData] = useState(null)
  const [resumeVerification, setResumeVerification] = useState(null)
  const [biometrics, setBiometrics] = useState(null)
  const [fraudFaceList, setFraudFaceList] = useState(null)
  const [identificationData, setIdentificationData] = useState(null)
  const [controlList, setControlList] = useState(null)
  const [securityFeatures, setSecurityFeatures] = useState(null)
  const [documentData, setDocumentData] = useState(null)
  const [documentImageQuality, setDocumentImageQuality] = useState(null)
  const [isChecked, setIsChecked] = useState(initialCheckedModal)
  const [isError, setIsError] = useState(initialErrorModal)
  const [isWaiting, setIsWaiting] = useState(false)
  const [isManualIdentification, setIsManualIdentification] = useState(
    initialManualIdentificationModal
  )
  const [globalResultButton, setGlobalResultButton] = useState(null)
  const [expertComments, setExpertComments] = useState(null)
  const [isComments, setIsComments] = useState(false)
  const [attachmentsFraudFace, SetAttachmentsFraudFace] = useState(null)
  const [isCaliente, setIsCaliente] = useState(false)
  const history = useHistory()
  const { confirm } = Modal

  useEffect(() => {
    const getData = async () => {
      try {
        const token = await getToken({
          audience: 'veridocid-dashboard',
        })

        const response = await fetch(
          `${API_DASHBOARD_URL}/experts/reports/${type}/${uuid}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )

        if (response.ok) {
          const json = await response.json()
          setData(json)
        } else {
          const error = await response.json()
          console.error(error)
        }
      } catch (error) {
        console.error(error)
      }
    }

    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid])

  useEffect(() => {
    if (!data) {
      return
    }

    if (data?.status !== statusFixture.checking) {
      history.goBack()
    }

    const getImage = async (img, typeImg) => {
      switch (typeImg) {
        case 'front':
          setAnverso({ data: img })
          break
        case 'back':
          setReverso({ data: img })
          break
        case 'frontUltra':
          setFrontUltraviolet({ data: img })
          break
        case 'backUltra':
          setBackUltraviolet({ data: img })
          break
        case 'frontInfra':
          setFrontInfrared({ data: img })
          break
        case 'backInfra':
          setBackInfrared({ data: img })
          break
        case 'documentPortraitImage':
          setDocumentPortrait({ data: img })
          break
        case 'faceImage':
          setLivePortrait({ data: img })
          break
        default:
          break
      }
    }

    if (data?.frontImageCropped) {
      getImage(data?.frontImageCropped, 'front')
    } else if (data?.frontImage) {
      getImage(data?.frontImage, 'front')
    }
    if (data?.backImageCropped) {
      getImage(data?.backImageCropped, 'back')
    } else if (data?.backImage) {
      getImage(data?.backImage, 'back')
    }
    if (data?.frontUltravioletImageCropped) {
      getImage(data?.frontUltravioletImageCropped, 'frontUltra')
    } else if (data?.frontUltravioletImage) {
      getImage(data?.frontUltravioletImage, 'frontUltra')
    }
    if (data?.backUltravioletImageCropped) {
      getImage(data?.backUltravioletImageCropped, 'backUltra')
    } else if (data?.backUltravioletImage) {
      getImage(data?.backUltravioletImage, 'backUltra')
    }
    if (data?.frontInfraredImageCropped) {
      getImage(data?.frontInfraredImageCropped, 'frontInfra')
    } else if (data?.frontInfraredImage) {
      getImage(data?.frontInfraredImage, 'frontInfra')
    }
    if (data?.backInfraredImageCropped) {
      getImage(data?.backInfraredImageCropped, 'backInfra')
    } else if (data?.backInfraredImage) {
      getImage(data?.backInfraredImage, 'backInfra')
    }

    if (data?.documentPortraitImage) {
      getImage(data?.documentPortraitImage, 'documentPortraitImage')
    }
    if (data?.faceImage) {
      getImage(data?.faceImage, 'faceImage')
    }

    if (data?.paramsFraud) {
      SetAttachmentsFraudFace(data.paramsFraud)
    }

    const documentType = data?.documentType ?? false
    setDocumentType(documentType)

    const documentVerifications = data?.documentVerifications
    setDocumentVerifications(documentVerifications)

    setGlobalResult({
      status: data?.globalResult,
      description: data?.globalResultDescription,
    })

    setSecurityLevel({
      status: data?.securityLevel,
      description: data?.securityLevelDescription,
    })

    const formatData = filterByCategory(
      documentVerifications,
      categories.formatData
    )
    setFormatData(formatData)

    const integrityData = filterByCategory(
      documentVerifications,
      categories.integrityData
    )
    setIntegrityData(integrityData)

    const identificationData = filterByCategory(
      documentVerifications,
      categories.identification
    )
    setIdentificationData(identificationData)

    const controlList = filterByCategory(
      documentVerifications,
      categories.controlList
    )
    setControlList(controlList)

    const security = filterByCategory(
      documentVerifications,
      categories.securityFeatures
    )
    setSecurityFeatures(security)

    setResumeVerification([
      { name: labels.startDate, value: data?.startDateUtc },
      { name: labels.endDate, value: data?.endDateUtc },
      { name: labels.user, value: data?.userName },
      { name: labels.verificationTime, value: data?.checkTime },
      { name: labels.verificationTotal, value: data?.totalChecks },
      { name: labels.verificationSuccess, value: data?.successChecks },
      { name: labels.verificationWarnings, value: data?.warningChecks },
      { name: labels.verificationFailed, value: data?.failedChecks },
      { name: labels.tableOfExperts, value: data?.expertUsed ? 'Si' : 'No' },
      { name: labels.spentCredits, value: data?.spentCredits },
    ])

    setIsCaliente(data?.userName?.includes('Caliente'))

    const biometrics = filterByCategory(
      documentVerifications,
      categories.biometrics
    )
    setBiometrics(biometrics)

    const fraudFaceList = filterByCategory(
      documentVerifications,
      categories.fraudFaceList
    )
    setFraudFaceList(fraudFaceList)

    setDocumentData(data?.documentData || null)

    setGlobalResultButton(data?.globalResult)

    setDocumentImageQuality(data?.documentImageQuality)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const onChangeStatusHandler = async (status, isRejected, isAccepted) => {
    setIsWaiting(true)
    const token = await getToken({
      audience: 'veridocid-dashboard',
    })
    const auth = await getToken({
      audience: 'veridocid',
    })
    let body

    if (controlListModified?.length >= 1) {
      const newControlList = [...controlList]

      controlListModified
        .sort((a, b) => b - a)
        .map((item) => {
          newControlList.splice(item, 1)
          return item
        })

      body = JSON.stringify({
        uuid,
        status,
        newControlList,
        type,
        name: user.name,
        email: user.email,
        auth,
      })
    } else if (isRejected) {
      body = JSON.stringify({
        uuid,
        status,
        expertComments,
        type,
        name: user.name,
        email: user.email,
        isRejected,
        auth,
      })
    } else if (isAccepted) {
      body = JSON.stringify({
        uuid,
        status,
        type,
        name: user.name,
        email: user.email,
        isAccepted,
        auth,
      })
    } else {
      body = JSON.stringify({
        uuid,
        status,
        type,
        name: user.name,
        email: user.email,
        auth,
      })
    }

    const response = await fetch(
      `${API_DASHBOARD_URL}/experts/records/changeStatus`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body,
      }
    )

    if (response.ok) {
      if (
        status === statusFixture.checked ||
        status === statusFixture.falsePositive ||
        status === statusFixture.falsePositiveFace
      ) {
        setIsWaiting(false)
        setIsChecked({ isChecked: true, open: true })
      } else {
        setIsWaiting(false)
        setIsManualIdentification({ isManualIdentification: true, open: true })
      }
    } else {
      setIsWaiting(false)
      setIsError({ isError: true, open: true })
    }
  }

  const formatDataCheck = { isFailed: filterCheckResult(formatData, 'Failed') }

  const integrityDataCheck = {
    isFailed: filterCheckResult(integrityData, 'Failed'),
  }

  const controlListCheck = {
    isFailed: filterCheckResult(controlList, 'Warning'),
  }

  const securityFeaturesCheck = {
    isFailed: filterCheckResult(securityFeatures, 'Failed'),
  }

  const identificationCheck = {
    isFailed: filterCheckResult(identificationData, 'Failed'),
  }

  const biometricsCheck = { isFailed: filterCheckResultFalse(biometrics) }

  const fraudFaceListCheck = {
    isFailed: filterCheckResultTrue(fraudFaceList),
  }

  const imageQualityCheck = {
    isFailed: filterCheckResult(documentImageQuality, 'ERROR'),
  }

  const onClickOptionsHandler = (active, typeCat) => {
    switch (typeCat) {
      case categories.documentData:
        setContentData(
          active
            ? {
                content: <DocumentData response={documentData} />,
                status: active,
              }
            : { content: <span />, status: active }
        )
        setIsDataOpen((prevOpen) => !prevOpen)
        setContentFormat(initialComponent)
        setContentIdentification(initialComponent)
        setContentIntegrity(initialComponent)
        setContentControl(initialComponent)
        setContentSecurity(initialComponent)
        setContentResume(initialComponent)
        setContentBiometrics(initialComponent)
        setContentFraudFaceList(initialComponent)
        setContentImageQuality(initialComponent)
        setIsResumeOpen(false)
        setIsIdentificationOpen(false)
        setIsFormatOpen(false)
        setIsIntegrityOpen(false)
        setIsControlOpen(false)
        setIsSecurityOpen(false)
        setIsBiometricsOpen(false)
        setIsFraudFaceListOpen(false)
        setIsImageQualityOpen(false)
        break
      case categories.formatData:
        setContentFormat(
          active
            ? {
                content: (
                  <FormatData
                    response={formatData}
                    backImage={
                      documentType === 'PASSPORT'
                        ? anverso?.data
                        : reverso?.data
                    }
                    documentType={documentType}
                    uuid={uuid}
                    type={type}
                  />
                ),
                status: active,
              }
            : { content: <span />, status: active }
        )
        setIsFormatOpen((prevOpen) => !prevOpen)
        setContentData(initialComponent)
        setContentIdentification(initialComponent)
        setContentIntegrity(initialComponent)
        setContentControl(initialComponent)
        setContentSecurity(initialComponent)
        setContentResume(initialComponent)
        setContentBiometrics(initialComponent)
        setContentFraudFaceList(initialComponent)
        setContentImageQuality(initialComponent)
        setIsResumeOpen(false)
        setIsDataOpen(false)
        setIsIdentificationOpen(false)
        setIsIntegrityOpen(false)
        setIsControlOpen(false)
        setIsSecurityOpen(false)
        setIsBiometricsOpen(false)
        setIsFraudFaceListOpen(false)
        setIsImageQualityOpen(false)
        break
      case categories.identification:
        setContentIdentification(
          active
            ? {
                content: (
                  <Identification
                    response={identificationData}
                    uuid={uuid}
                    type={type}
                  />
                ),
                status: active,
              }
            : { content: <span />, status: active }
        )
        setIsIdentificationOpen((prevOpen) => !prevOpen)
        setContentFormat(initialComponent)
        setContentData(initialComponent)
        setContentIntegrity(initialComponent)
        setContentControl(initialComponent)
        setContentSecurity(initialComponent)
        setContentResume(initialComponent)
        setContentBiometrics(initialComponent)
        setContentFraudFaceList(initialComponent)
        setContentImageQuality(initialComponent)
        setIsResumeOpen(false)
        setIsDataOpen(false)
        setIsFormatOpen(false)
        setIsIntegrityOpen(false)
        setIsControlOpen(false)
        setIsSecurityOpen(false)
        setIsBiometricsOpen(false)
        setIsFraudFaceListOpen(false)
        setIsImageQualityOpen(false)
        break
      case categories.integrityData:
        setContentIntegrity(
          active
            ? {
                content: (
                  <IntegrityData
                    response={integrityData}
                    uuid={uuid}
                    type={type}
                  />
                ),
                status: active,
              }
            : { content: <span />, status: active }
        )
        setIsIntegrityOpen((prevOpen) => !prevOpen)
        setContentFormat(initialComponent)
        setContentIdentification(initialComponent)
        setContentData(initialComponent)
        setContentControl(initialComponent)
        setContentSecurity(initialComponent)
        setContentResume(initialComponent)
        setContentBiometrics(initialComponent)
        setContentFraudFaceList(initialComponent)
        setContentImageQuality(initialComponent)
        setIsResumeOpen(false)
        setIsDataOpen(false)
        setIsIdentificationOpen(false)
        setIsFormatOpen(false)
        setIsControlOpen(false)
        setIsSecurityOpen(false)
        setIsBiometricsOpen(false)
        setIsFraudFaceListOpen(false)
        setIsImageQualityOpen(false)
        break
      case categories.controlList:
        setContentControl(
          active
            ? {
                content: (
                  <ControlList
                    response={controlList}
                    onClick={(list) => onControlListHandler(list)}
                  />
                ),
                status: active,
              }
            : { content: <span />, status: active }
        )
        setIsControlOpen((prevOpen) => !prevOpen)
        setContentFormat(initialComponent)
        setContentIdentification(initialComponent)
        setContentIntegrity(initialComponent)
        setContentData(initialComponent)
        setContentSecurity(initialComponent)
        setContentResume(initialComponent)
        setContentBiometrics(initialComponent)
        setContentFraudFaceList(initialComponent)
        setContentImageQuality(initialComponent)
        setIsResumeOpen(false)
        setIsDataOpen(false)
        setIsIdentificationOpen(false)
        setIsFormatOpen(false)
        setIsIntegrityOpen(false)
        setIsSecurityOpen(false)
        setIsBiometricsOpen(false)
        setIsFraudFaceListOpen(false)
        setIsImageQualityOpen(false)
        break
      case categories.securityFeatures:
        setContentSecurity(
          active
            ? {
                content: (
                  <SecurityFeatures
                    response={securityFeatures}
                    uuid={uuid}
                    type={type}
                  />
                ),
                status: active,
              }
            : { content: <span />, status: active }
        )
        setIsSecurityOpen((prevOpen) => !prevOpen)
        setContentFormat(initialComponent)
        setContentIdentification(initialComponent)
        setContentIntegrity(initialComponent)
        setContentData(initialComponent)
        setContentControl(initialComponent)
        setContentResume(initialComponent)
        setContentBiometrics(initialComponent)
        setContentFraudFaceList(initialComponent)
        setContentImageQuality(initialComponent)
        setIsResumeOpen(false)
        setIsDataOpen(false)
        setIsIdentificationOpen(false)
        setIsFormatOpen(false)
        setIsIntegrityOpen(false)
        setIsControlOpen(false)
        setIsBiometricsOpen(false)
        setIsFraudFaceListOpen(false)
        setIsImageQualityOpen(false)
        break
      case categories.verificationResume:
        setContentResume(
          active
            ? {
                content: <VerificationResume response={resumeVerification} />,
                status: active,
              }
            : { content: <span />, status: active }
        )
        setIsResumeOpen((prevOpen) => !prevOpen)
        setContentFormat(initialComponent)
        setContentIdentification(initialComponent)
        setContentIntegrity(initialComponent)
        setContentData(initialComponent)
        setContentControl(initialComponent)
        setContentSecurity(initialComponent)
        setContentBiometrics(initialComponent)
        setContentFraudFaceList(initialComponent)
        setContentImageQuality(initialComponent)
        setIsDataOpen(false)
        setIsIdentificationOpen(false)
        setIsFormatOpen(false)
        setIsIntegrityOpen(false)
        setIsControlOpen(false)
        setIsSecurityOpen(false)
        setIsBiometricsOpen(false)
        setIsFraudFaceListOpen(false)
        setIsImageQualityOpen(false)
        break
      case categories.biometrics:
        setContentBiometrics(
          active
            ? {
                content: (
                  <Biometrics
                    response={biometrics}
                    uuid={uuid}
                    type={type}
                    livePortrait={livePortrait}
                    documentPortrait={documentPortrait}
                  />
                ),
                status: active,
              }
            : { content: <span />, status: active }
        )
        setIsBiometricsOpen((prevOpen) => !prevOpen)
        setContentFormat(initialComponent)
        setContentIdentification(initialComponent)
        setContentIntegrity(initialComponent)
        setContentData(initialComponent)
        setContentControl(initialComponent)
        setContentSecurity(initialComponent)
        setContentResume(initialComponent)
        setContentFraudFaceList(initialComponent)
        setContentImageQuality(initialComponent)
        setIsResumeOpen(false)
        setIsDataOpen(false)
        setIsIdentificationOpen(false)
        setIsFormatOpen(false)
        setIsIntegrityOpen(false)
        setIsControlOpen(false)
        setIsSecurityOpen(false)
        setIsFraudFaceListOpen(false)
        setIsImageQualityOpen(false)
        break
      case categories.fraudFaceList:
        setContentFraudFaceList(
          active
            ? {
                content: (
                  <FaceFraudList
                    response={fraudFaceList}
                    uuid={uuid}
                    type={type}
                    livePortrait={livePortrait}
                    attachments={attachmentsFraudFace}
                  />
                ),
                status: active,
              }
            : { content: <span />, status: active }
        )
        setIsFraudFaceListOpen((prevOpen) => !prevOpen)
        setContentFormat(initialComponent)
        setContentIdentification(initialComponent)
        setContentIntegrity(initialComponent)
        setContentData(initialComponent)
        setContentControl(initialComponent)
        setContentSecurity(initialComponent)
        setContentResume(initialComponent)
        setContentBiometrics(initialComponent)
        setContentImageQuality(initialComponent)
        setIsResumeOpen(false)
        setIsDataOpen(false)
        setIsIdentificationOpen(false)
        setIsFormatOpen(false)
        setIsIntegrityOpen(false)
        setIsControlOpen(false)
        setIsSecurityOpen(false)
        setIsBiometricsOpen(false)
        setIsImageQualityOpen(false)
        break
      case categories.documentImageQuality:
        setContentImageQuality(
          active
            ? {
                content: (
                  <DocumentImageQuality response={documentImageQuality} />
                ),
                status: active,
              }
            : { content: <span />, status: active }
        )
        setIsImageQualityOpen((prevOpen) => !prevOpen)
        setContentFormat(initialComponent)
        setContentIdentification(initialComponent)
        setContentIntegrity(initialComponent)
        setContentData(initialComponent)
        setContentControl(initialComponent)
        setContentSecurity(initialComponent)
        setContentResume(initialComponent)
        setContentBiometrics(initialComponent)
        setContentFraudFaceList(initialCheckedModal)
        setIsResumeOpen(false)
        setIsDataOpen(false)
        setIsIdentificationOpen(false)
        setIsFormatOpen(false)
        setIsIntegrityOpen(false)
        setIsControlOpen(false)
        setIsSecurityOpen(false)
        setIsBiometricsOpen(false)
        setIsFraudFaceListOpen(false)
        break
      default:
        console.log('Missing type')
    }
  }

  const onDownloadHandler = async () => {
    const token = await getToken({
      audience: 'veridocid-dashboard',
    })

    const response = await fetch(
      `${API_DASHBOARD_URL}/experts/records/downloadImages/${type}/${uuid}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    if (response.ok) {
      const data = await response.blob()
      download(data, `${uuid}.zip`, 'application/zip')
    }
  }

  const contentObtained = anverso?.data ? (
    <CarouselExperts
      imageFront={anverso?.data ? anverso?.data : null}
      imageBack={reverso?.data ? reverso?.data : null}
      imageFrontUltraviolet={
        frontUltraviolet?.data ? frontUltraviolet?.data : null
      }
      imageBackUltraviolet={
        backUltraviolet?.data ? backUltraviolet?.data : null
      }
      imageFrontInfrared={frontInfrared?.data ? frontInfrared?.data : null}
      imageBackimageFrontInfrared={
        backInfrared?.data ? backInfrared?.data : null
      }
      name="Obtained"
      title={labels.imageObtained}
      titleInfo={labels.globalResult}
      info={globalResult?.description}
      icon={globalResult?.status === 'Ok' ? check : warning}
      type={type}
    />
  ) : (
    <div
      style={{
        height: '100%',
        display: 'grid',
        placeItems: 'center',
      }}
    >
      <Spinner />
    </div>
  )

  const contentReference = anverso?.data ? (
    <CarouselExperts
      imageFront={documentType === 'PASSPORT' ? passport : ineFront}
      imageBack={documentType === 'PASSPORT' ? null : ineBack}
      name="Reference"
      title={labels.referenceDocument}
      titleInfo={labels.securityLevel}
      info={securityLevel?.description}
    />
  ) : (
    <div
      style={{
        height: '100%',
        display: 'grid',
        placeItems: 'center',
      }}
    >
      <Spinner />
    </div>
  )

  const optionDocumentData = documentData ? (
    <Button
      text={labels.documentData}
      image={isDataOpen ? minusBlack : addBlack}
      status={globalResultButton}
      clicked={() =>
        onClickOptionsHandler(!contentData.status, categories.documentData)
      }
    />
  ) : (
    <span />
  )

  const optionIdentification = filterCategoryCheck(
    documentVerifications,
    categories.identification
  ) ? (
    <Button
      status={globalResultButton}
      text={labels.identification}
      image={
        isIdentificationOpen
          ? identificationCheck?.isFailed
            ? minusWhite
            : minusBlack
          : identificationCheck?.isFailed
          ? addWhite
          : addBlack
      }
      clicked={() =>
        onClickOptionsHandler(
          !contentIdentification.status,
          categories.identification
        )
      }
      isFailed={identificationCheck?.isFailed}
    />
  ) : (
    <span />
  )

  const optionFormatData = filterCategoryCheck(
    documentVerifications,
    categories.formatData
  ) ? (
    <Button
      status={globalResultButton}
      text={labels.dataFormat}
      image={
        isFormatOpen
          ? formatDataCheck?.isFailed
            ? minusWhite
            : minusBlack
          : formatDataCheck?.isFailed
          ? addWhite
          : addBlack
      }
      clicked={() =>
        onClickOptionsHandler(!contentFormat.status, categories.formatData)
      }
      isFailed={formatDataCheck?.isFailed}
    />
  ) : (
    <span />
  )

  const optionIntegrityData = filterCategoryCheck(
    documentVerifications,
    categories.integrityData
  ) ? (
    <Button
      status={globalResultButton}
      statusCollapse={isIntegrityOpen}
      text={labels.dataIntegrity}
      image={
        isIntegrityOpen
          ? integrityDataCheck?.isFailed
            ? minusWhite
            : minusBlack
          : integrityDataCheck?.isFailed
          ? addWhite
          : addBlack
      }
      clicked={() =>
        onClickOptionsHandler(
          !contentIntegrity.status,
          categories.integrityData
        )
      }
      isFailed={integrityDataCheck?.isFailed}
    />
  ) : (
    <span />
  )

  const optionControlList = filterCategoryCheck(
    documentVerifications,
    categories.controlList
  ) ? (
    <Button
      status={globalResultButton}
      text={labels.controlList}
      image={
        isConstrolOpen
          ? controlListCheck?.isFailed
            ? minusWhite
            : minusBlack
          : controlListCheck?.isFailed
          ? addWhite
          : addBlack
      }
      clicked={() =>
        onClickOptionsHandler(!contentControl.status, categories.controlList)
      }
      isFailed={controlListCheck?.isFailed}
    />
  ) : (
    <span />
  )

  const optionSecurityFeatures = filterCategoryCheck(
    documentVerifications,
    categories.securityFeatures
  ) ? (
    <Button
      status={globalResultButton}
      text={labels.securityFeatures}
      image={
        isSecurityOpen
          ? securityFeaturesCheck?.isFailed
            ? minusWhite
            : minusBlack
          : securityFeaturesCheck?.isFailed
          ? addWhite
          : addBlack
      }
      clicked={() =>
        onClickOptionsHandler(
          !contentSecurity.status,
          categories.securityFeatures
        )
      }
      isFailed={securityFeaturesCheck?.isFailed}
    />
  ) : (
    <span />
  )

  const optionResume = resumeVerification ? (
    <Button
      text={labels.verificationResume}
      image={isResumeOpen ? minusBlack : addBlack}
      clicked={() =>
        onClickOptionsHandler(
          !contentResume.status,
          categories.verificationResume
        )
      }
      status={globalResultButton}
    />
  ) : (
    <span />
  )

  const optionBiometrics = filterCategoryCheck(
    documentVerifications,
    categories.biometrics
  ) ? (
    <Button
      text={labels.biometrics}
      image={
        isBiometricsOpen
          ? biometricsCheck?.isFailed
            ? minusWhite
            : minusBlack
          : biometricsCheck?.isFailed
          ? addWhite
          : addBlack
      }
      clicked={() =>
        onClickOptionsHandler(!contentBiometrics.status, categories.biometrics)
      }
      isFailed={biometricsCheck?.isFailed}
      status={globalResultButton}
    />
  ) : (
    <span />
  )

  const optionFraudFaceList = filterCategoryCheck(
    documentVerifications,
    categories.fraudFaceList
  ) ? (
    <Button
      text={labels.fraudFaceList}
      image={
        isFraudFaceListOpen
          ? fraudFaceListCheck?.isFailed
            ? minusWhite
            : minusBlack
          : fraudFaceListCheck?.isFailed
          ? addWhite
          : addBlack
      }
      clicked={() =>
        onClickOptionsHandler(
          !contentFraudFaceList.status,
          categories.fraudFaceList
        )
      }
      isFailed={fraudFaceListCheck?.isFailed}
      status={globalResultButton}
    />
  ) : (
    <span />
  )

  const optionImageQuality = documentImageQuality ? (
    <Button
      text={labels.documentImageQuality}
      image={
        isImageQualityOpen
          ? imageQualityCheck?.isFailed
            ? minusWhite
            : minusBlack
          : imageQualityCheck?.isFailed
          ? addWhite
          : addBlack
      }
      clicked={() =>
        onClickOptionsHandler(
          !contentImageQuality.status,
          categories.documentImageQuality
        )
      }
      isFailed={imageQualityCheck?.isFailed}
      status={globalResultButton}
    />
  ) : (
    <span />
  )

  const onControlListHandler = (list) => {
    if (typeof list === 'number') {
      setControlListModified((oldArray) => [...oldArray, list])
    } else {
      setControlListModified(list)
    }
  }

  const showModalConfirm = (status, isRejected, isAccepted) => {
    confirm({
      title: '¿Estás seguro?',
      icon: <ExclamationCircleOutlined />,
      content: 'Esta acción es irreversible.',
      cancelText: 'No',
      okText: 'Si',
      onOk() {
        onChangeStatusHandler(status, isRejected, isAccepted)
      },
      onCancel() {},
    })
  }

  const addFace = async () => {
    try {
      setIsWaiting(true)
      const token = await getToken({
        audience: 'veridocid-dashboard',
      })
      const auth = await getToken({
        audience: 'veridocid',
      })

      const response = await fetch(
        `${API_DASHBOARD_URL}/experts/add/facefraud`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            uuid,
            type,
            token: auth,
          }),
        }
      )

      if (response.ok) {
        setIsWaiting(false)
        Modal.success({
          content:
            'Las imagenes han sido agregadas con éxito a la lista fraudulenta.',
        })
      } else {
        setIsWaiting(false)
        Modal.error({
          title: '¡Oh, no! Algo salió mal',
          content: 'Por favor, vuelve a intentarlo.',
        })
      }
    } catch (error) {
      Modal.error({
        title: '¡Oh, no! Algo salió mal',
        content: 'Por favor, vuelve a intentarlo.',
      })
      console.error(error)
    }
  }

  const showFaceModalConfirm = () => {
    confirm({
      title: '¿Estás seguro?',
      icon: <ExclamationCircleOutlined />,
      content: 'Esta acción es irreversible.',
      cancelText: 'No',
      okText: 'Si',
      onOk() {
        addFace()
      },
      onCancel() {},
    })
  }

  const onCommentExpertHandler = (selectedComment) => {
    setExpertComments(selectedComment)
    setIsComments(true)
  }

  return (
    <div className="Main">
      <div className="Uuid">
        {/* <span style={{ marginRight: '1rem' }}>UUID:</span>
        <div className="CopyUuid">
          <CopyIdPopup text={uuid} copy={uuid} />
        </div> */}
        <span
          style={{
            marginRight: '0.6rem',
            fontWeight: '600',
            color: 'var(--primary-color)',
          }}
        >
          UUID:
        </span>
        <div className="CopyUuid" style={{ maxWidth: '360px' }}>
          {/* <CopyIdPopup text={uuid} copy={uuid} /> */}
          <Paragraph
            copyable
            style={{
              marginBottom: '0',
              color: 'var(--black-color)',
            }}
            code
          >
            {uuid}
          </Paragraph>
        </div>
      </div>
      <div className="Documents">
        <div className="Obtained">{contentObtained}</div>
        <div className="Reference">{contentReference}</div>
      </div>
      <div className="Options">
        {optionResume}
        {contentResume.content}
        {optionDocumentData}
        {contentData.content}
        {optionIdentification}
        {contentIdentification.content}
        {optionFormatData}
        {contentFormat.content}
        {optionIntegrityData}
        {contentIntegrity.content}
        {optionSecurityFeatures}
        {contentSecurity.content}
        {optionControlList}
        {contentControl.content}
        {optionBiometrics}
        {contentBiometrics.content}
        {optionFraudFaceList}
        {contentFraudFaceList.content}
        {optionImageQuality}
        {contentImageQuality.content}
      </div>
      <div className="Comments">
        <div
          style={{
            marginBottom: '1em',
          }}
        >
          <b
            style={{
              // textDecoration: 'underline',
              color: '#303030',
              fontSize: 'clamp(1em, 1.1em ,1.2em)',
            }}
          >
            {labels.expertsComments}
          </b>
          <b style={{ color: '#D30F0F', fontSize: '1.2rem' }}>*</b>
        </div>
        <Select
          value={expertComments}
          options={isCaliente ? commentOptionsCaliente : commentOptions}
          onChange={(selectedComment) =>
            onCommentExpertHandler(selectedComment)
          }
        />
      </div>
      <div className="Buttons">
        <button
          className="ButtonChecked"
          onClick={() => showModalConfirm(statusFixture.checked)}
        >
          {labels.checked}
        </button>
        <button
          className="ButtonAccepted"
          onClick={() => showModalConfirm(statusFixture.checked, false, true)}
          disabled={globalResult?.status === 'Ok'}
        >
          {labels.accept}
        </button>
        <button
          className="ButtonRejected"
          onClick={() => showModalConfirm(statusFixture.checked, true)}
          disabled={!isComments}
        >
          {labels.reject}
        </button>
        <button
          className="ButtonSend"
          onClick={() => showModalConfirm(statusFixture.manual)}
        >
          {labels.sendBackIdentification}
        </button>
        <button
          className="ButtonFalsePositive"
          onClick={() => showModalConfirm(statusFixture.falsePositive)}
          disabled={!controlListCheck?.isFailed}
        >
          {labels.falsePositive}
        </button>
        <button
          className="ButtonFalsePositive"
          onClick={() => showModalConfirm(statusFixture.falsePositiveFace)}
          disabled={!fraudFaceListCheck?.isFailed}
        >
          {labels.falsePositiveFace}
        </button>
        <button
          className="ButtonAddFace"
          onClick={() => showFaceModalConfirm()}
        >
          {labels.addFraudFace}
        </button>
        <button className="ButtonDownload" onClick={onDownloadHandler}>
          {labels.downloadImages}
        </button>
      </div>
      <ModalExperts
        isOpenModal={
          isChecked?.open
            ? isChecked
            : isError?.open
            ? isError
            : isManualIdentification
        }
        isLoading={isWaiting}
      />
    </div>
  )
}

export default BodyVerificationResults
