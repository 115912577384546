import React from 'react'
import { Popover } from 'antd'
import { isEmpty } from 'lodash'
import { VscInfo } from 'react-icons/vsc'
import { Button } from 'semantic-ui-react'

export const ShowCounter = ({
  loadingCount,
  countSignWithVerification,
  data,
  counts,
}) => (
  <div className="content_download_contract">
    <Popover
      trigger="click"
      content={
        !isEmpty(countSignWithVerification) && !loadingCount ? (
          <div
            style={{
              padding: '0.6rem 0.54rem',
              fontSize: '0.94rem',
            }}
          >
            <p style={{ margin: '0' }}>
              {`Participantes: ${countSignWithVerification?.total}`}
            </p>
            <p>{`Firmas: ${countSignWithVerification?.signs}`}</p>
          </div>
        ) : (
          <div
            style={{
              padding: '0.6rem 0.54rem',
              fontSize: '0.94rem',
            }}
          >
            <p style={{ margin: '0' }}>Participantes: 0</p>
            <p>Firmas: 0</p>
          </div>
        )
      }
    >
      <Button
        loading={loadingCount ? true : false}
        disabled={data?.participants.length === 0 ? true : false}
        type="button"
        style={{
          color: '#2d2f6d',
          border: 'none',
          outline: 'none',
        }}
        className="download_contract"
        onClick={counts}
      >
        <VscInfo size={18} />
      </Button>
    </Popover>
  </div>
)
