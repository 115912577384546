import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Container } from './Step1Option4.jsx'
import { Loader, Dimmer } from 'semantic-ui-react'
import {
  masiveSignature,
  startOrchestratorProcess,
} from '../../../../../../utils/signContract'
import Step1Option1 from '../Step1Option1/Step1Option1'
import Step1Option2 from '../Step1Option2/Step1Option2'
import Step1Option3 from '../Step1Option3/Step1Option3'
import SignProcessContext from '../../../../../../context/SignProcess.context.js'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { TextField } from '@material-ui/core'
import '../stylesSteps.css'

import {
  getSignatures,
  desactiveSignature,
  saveSignature,
  getSignature,
} from '../../../../../../utils/savedSignatures'
import useToken from '../../../../../../utils/useToken.js'

import Grid from '@mui/material/Grid'
import { VscTrash } from 'react-icons/vsc'
import { VscPinnedDirty } from 'react-icons/vsc'
import Alert from '../../../../../../components/Alert/Alert.jsx'
import { useTranslation } from 'react-i18next'
import { ModalListMasiveDocuments } from '../ModalListMasiveDocuments.jsx'
import { isArray } from 'lodash'

export default function Step1Option4(props) {
  const {
    openModal,
    closeModal,
    isActiveModal,
    setWrongDocuments,
    setDocumentsToSign,
    acceptProgress,
    documentIDs,
  } = useContext(SignProcessContext)

  const { setSign, setOpen, currentDocuments } = props
  const { t } = useTranslation()
  const history = useHistory()
  const getToken = useToken()

  const [signatureData, setSignatureData] = useState(null)
  let today = ''
  let pinSignature

  const [showCreateForm, setShowCreateForm] = useState(false)
  const [signerName, setSignerName] = useState('')
  const [data, setData] = useState([])
  const [projectRun, setProjectRun] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const [selectedSigner, setSelectedSigner] = React.useState(0)

  const [name, setName] = useState('')

  const [alert, setAlert] = useState({
    open: false,
    message: '',
    severity: 'info',
  })

  const handleChange = (event) => {
    setSelectedSigner(event.target.value)
  }

  useEffect(() => {
    if (!projectRun) {
      listSavedSignatures()
      setProjectRun(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const listSavedSignatures = async () => {
    setLoading(true)
    const token = await getToken({
      audience: 'veridocid',
    })

    const response = await getSignatures(token, 1, 10)

    if (response?.error) {
      if (isArray(response?.error?.message)) {
        setAlert({
          open: true,
          message: `${response.error?.message[0]}`,
          severity: 'error',
        })
      } else {
        setAlert({
          open: true,
          message: `${response.error?.message}`,
          severity: 'error',
        })
      }
      setLoading(false)
      return
    }

    setData(response.result)
    setLoading(false)
  }

  const createSigner = async () => {
    setLoading(true)
    let currentSign = signatureData

    if (selectedSigner === 3) {
      if (name) {
        let newName = name.split(' ')
        let newA = []
        let line = ''
        let canvaR = document.getElementById('textCanvasReal')
        let ctxR = canvaR.getContext('2d')
        ctxR.clearRect(113, 105, 113, 105)
        ctxR.font = 'bold 10px Arial'

        for (let name of newName) {
          if (`${line} ${name}`.length < 23) line = `${line} ${name}`
          else {
            newA.push(line)
            line = name
          }
        }

        if (newA.length === 0) ctxR.fillText(line, 0.5, 20)
        else {
          if (line !== '') newA.push(line)

          let Y = newA.length === 2 ? 6 : 0
          let plus = newA.length === 2 ? 10 : 10
          for (let namePart of newA) {
            Y = Y + plus
            ctxR.fillText(namePart, 0.5, Y)
          }
        }

        currentSign = ctxR.canvas.toDataURL()
        setSignatureData(currentSign)
      }
    }

    if (signerName === '') {
      setAlert({
        open: true,
        message: 'Es necesario indicar el Nombre para identificar tu firma.',
        severity: 'error',
      })
      setLoading(false)
      return
    }
    if (currentSign === null) {
      setAlert({
        open: true,
        message: 'Es necesario llenar el formato de firma seleccionado.',
        severity: 'error',
      })
      setLoading(false)
      return
    }

    const token = await getToken({
      audience: 'veridocid',
    })

    let data = {
      id: `design_type${selectedSigner}_${signerName}`,
      name: signerName,
      type:
      selectedSigner === 1
        ? "autografa"
        : selectedSigner === 2
        ? "imagen"
        : selectedSigner === 3
        ? "nombre"
        : "sin tipo",
      signature: currentSign.split('base64,')[1],
      status: true,
    }

    const response = await saveSignature(token, data)

    if (response?.error) {
      if (isArray(response?.error?.message)) {
        setAlert({
          open: true,
          message: `${response.error?.message[0]}`,
          severity: 'error',
        })
      } else {
        setAlert({
          open: true,
          message: `${response.error?.message}`,
          severity: 'error',
        })
      }
      setLoading(false)
      return
    }

    setSignerName('')
    setSignatureData(null)
    setShowCreateForm(false)
    setLoading(false)
    listSavedSignatures()
  }

  const signerForm = () => {
    setShowCreateForm(!showCreateForm)
    setSelectedSigner(0)
    return
  }

  const deleteSigner = async (rowData) => {
    setLoading(true)
    const token = await getToken({ audience: 'veridocid' })

    let isActive = !rowData.status

    const response = await desactiveSignature(token, rowData.uuid, isActive)

    if (response?.error) {
      if (isArray(response?.error?.message)) {
        setAlert({
          open: true,
          message: `${response.error?.message[0]}`,
          severity: 'error',
        })
      } else {
        setAlert({
          open: true,
          message: `${response.error?.message}`,
          severity: 'error',
        })
      }
      setLoading(false)
      return
    }

    setLoading(false)
    setSignatureData(null)
    listSavedSignatures()
  }

  const signerActive = async (data) => {
    setLoading(true)
    if (currentDocuments) {
      const token = await getToken({
        audience: 'veridocid',
      })

      let dataMasive = {
        email: currentDocuments.email,
        documentInfo: currentDocuments.documentInfo,
      }

      const { documentsInProgress, errorDocuments } =
        (await masiveSignature(token, dataMasive)) ?? {}

      if (
        errorDocuments &&
        errorDocuments.length === 0 &&
        documentsInProgress &&
        documentsInProgress.length > 0
      ) {
        pinSignature = data
        setDocumentsToSign(documentsInProgress)
        await acceptOrchestratorProgress()
        return
      }

      if (errorDocuments && errorDocuments.length > 0) {
        setWrongDocuments(errorDocuments)
        openModal()
      }

      if (documentsInProgress && documentsInProgress.length > 0) {
        pinSignature = data
        setDocumentsToSign(documentsInProgress)
      }

      if (!errorDocuments && !documentsInProgress) {
        setAlert({
          open: true,
          message: `${t('messageErrorData')}`,
          severity: 'error',
        })
      }
    } else {
      const token = await getToken({
        audience: 'veridocid',
      })

      const resGetSigature = await getSignature(token, data.uuid)

      if (resGetSigature?.error) {
        if (isArray(resGetSigature?.error?.message)) {
          setAlert({
            open: true,
            message: `${resGetSigature.error?.message[0]}`,
            severity: 'error',
          })
        } else {
          setAlert({
            open: true,
            message: `${resGetSigature.error?.message}`,
            severity: 'error',
          })
        }
        setLoading(false)
        return
      }

      const miliseconds = Date.now()
      let currentDate = new Date(miliseconds)
      const localeDate = currentDate.toLocaleDateString()

      setSign({
        signature: `data:image/png;base64,${resGetSigature.result.base64}`,
        currentDate: localeDate,
        option: 0,
      })
      setOpen(false)
    }
    setLoading(false)
  }

  const acceptOrchestratorProgress = async () => {
    if (
      ((documentIDs && documentIDs.length > 0) ||
        (currentDocuments && currentDocuments?.documentInfo.length > 0)) &&
      pinSignature?.uuid
    ) {
      const token = await getToken({
        audience: 'veridocid',
      })

      const resGetSigature = await getSignature(token, pinSignature.uuid)

      if (resGetSigature?.error) {
        if (isArray(resGetSigature?.error?.message)) {
          setAlert({
            open: true,
            message: `${resGetSigature.error?.message[0]}`,
            severity: 'error',
          })
        } else {
          setAlert({
            open: true,
            message: `${resGetSigature.error?.message}`,
            severity: 'error',
          })
        }
        setLoading(false)
        return
      }

      let signMasive = {
        email: currentDocuments.email,
        documentInfo: documentIDs.length
          ? documentIDs
          : currentDocuments?.documentInfo,
        signature: resGetSigature.result.base64.replace(
          'data:image/png;base64,',
          ''
        ),
      }

      acceptProgress()
      await startOrchestratorProcess(token, signMasive)
      setOpen(false)
      setLoading(false)
      history.push('/firma-masiva/contratos')
    } else {
      setAlert({
        open: true,
        message: `${t('noDataAvailable')}`,
        severity: 'error',
      })
      setLoading(false)
    }
  }

  return (
    <Dimmer.Dimmable dimmed={loading} style={{ width: '100%' }}>
      <Dimmer active={loading} inverted>
        <Loader>{`${t('loading')}...`}</Loader>
      </Dimmer>
      <Container container>
        <Alert alert={alert} setAlert={setAlert} />
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '10px',
          }}
        >
          <h3 style={{ margin: '0' }}>
            {showCreateForm
              ? `${t('saveSignature')}`
              : `${t('savedSignatures')}`}
          </h3>
          <button
            type="button"
            onClick={() => signerForm()}
            className="btn_add_sign"
          >
            {showCreateForm ? `${t('hide')}` : `${t('newSignature')}`}
          </button>
        </div>
        <div
          style={{
            overflow: 'auto',
            width: '100%',
            height: 'calc(100% - 40px)',
            margin: '1rem 0 2rem',
          }}
        >
          {showCreateForm ? (
            <>
              <Grid
                container
                style={{ padding: '0px 10px', marginTop: '20px' }}
              >
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="select-label">{`${t(
                      'typeOfSignature'
                    )}`}</InputLabel>
                    <Select
                      labelId="select-label"
                      id="select-label"
                      value={selectedSigner}
                      label={`${t('typeOfSignature')}`}
                      onChange={handleChange}
                      style={{ height: '53px' }}
                    >
                      <MenuItem value={0} disabled>
                        {`${t('select')}`}
                      </MenuItem>
                      <MenuItem value={1}>{`${t('handwritten')}`}</MenuItem>
                      <MenuItem value={2}>{`${t('image')}`}</MenuItem>
                      <MenuItem value={3}>{`${t('name')}`}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {selectedSigner !== 0 ? (
                  <Grid item xs={12} mt={4}>
                    <TextField
                      id="outlined-basic"
                      label={`${t('signerName')}`}
                      variant="outlined"
                      onChange={(text) => setSignerName(text.target.value)}
                      value={signerName}
                      fullWidth
                      style={{ height: '53px' }}
                    />
                  </Grid>
                ) : null}

                {selectedSigner === 1 ? (
                  <Grid
                    container
                    justifyContent={'center'}
                    alignItems={'center'}
                    mt={5}
                  >
                    <Step1Option1
                      signatureData={signatureData}
                      setSignatureData={setSignatureData}
                      style={{ width: '544px' }}
                    />
                  </Grid>
                ) : selectedSigner === 2 ? (
                  <Grid
                    container
                    justifyContent={'center'}
                    alignItems={'center'}
                    mt={5}
                  >
                    <Step1Option2
                      signatureData={signatureData}
                      setSignatureData={setSignatureData}
                      today={today}
                    />
                  </Grid>
                ) : selectedSigner === 3 ? (
                  <>
                    <Step1Option3 name={name} setName={setName} today={today} />
                    <canvas
                      id="textCanvasReal"
                      width={113}
                      height={35}
                      display="none"
                      style={{ display: 'none' }}
                    ></canvas>
                    <canvas
                      id="textCanvas"
                      width={452}
                      height={140}
                      style={{ display: 'none' }}
                    ></canvas>
                  </>
                ) : null}
                {selectedSigner !== 0 ? (
                  <div
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      margin: '1rem 0',
                    }}
                  >
                    <button
                      type="button"
                      onClick={() => createSigner()}
                      className="btn_save_signs"
                    >
                      {`${t('create')}`}
                    </button>
                  </div>
                ) : null}
              </Grid>
            </>
          ) : (
            <Grid container style={{ padding: '1rem 1px 2px 1px' }}>
              <Grid item xs={12}>
                {data.map((item, index) => (
                  <div key={index}>
                    <ul
                      style={{ listStyle: 'none', padding: '0', margin: '0' }}
                    >
                      <li className="item_save_signs">
                        <p style={{ margin: '0' }}>{item?.name ?? '-'}</p>
                        <span
                          style={{
                            fontSize: 'clamp(0.8rem, 0.84rem, 0.9rem)',
                            color: 'gray',
                          }}
                        >
                          {item?.type ?? '-'}
                        </span>
                        <div style={{ justifySelf: 'end' }}>
                          <VscPinnedDirty
                            size={20}
                            style={{
                              color: '#3f3ca4',
                              cursor: 'pointer',
                              marginRight: '6px',
                            }}
                            onClick={() => signerActive(item)}
                          />
                          <VscTrash
                            size={20}
                            style={{
                              color: 'red',
                              cursor: 'pointer',
                              marginRight: '5px',
                            }}
                            onClick={() => deleteSigner(item)}
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                ))}
              </Grid>
              <ModalListMasiveDocuments
                isActiveModal={isActiveModal}
                close={() => closeModal()}
                documentIDs={documentIDs}
                acceptOrchestratorProgress={acceptOrchestratorProgress}
              />
            </Grid>
          )}
        </div>
      </Container>
    </Dimmer.Dimmable>
  )
}
