import {
  getData,
  getParticipantsOfDocument,
} from '../../utils/getDataCustomize'
import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'semantic-ui-react'
import useToken from '../../utils/useToken'
import { isEmpty } from 'lodash'
import { VscClose } from 'react-icons/vsc'

export const ModalDataParticipants = ({
  setJsonVerification,
  setFailedBiometrics,
  setOpenEnrollModal,
  setOpenModalDP,
  openModalDP,
  setAlert,
  docID,
  t,
}) => {
  const getToken = useToken()
  const [loading, setLoading] = useState(false)
  const [participants, setParticipants] = useState(null)

  useEffect(() => {
    const getDataParticipants = async (id) => {
      setLoading(true)
      const token = await getToken({
        audience: 'veridocid-dashboard',
      })

      const data = await getParticipantsOfDocument(token, id)

      if (data?.error || data === null) {
        setLoading(false)
        setAlert({
          open: true,
          message: `Hubo un error`,
          severity: 'error',
        })
        return
      }

      if (data) {
        setLoading(false)
        setParticipants(data.participants)
      }
    }

    if (docID) {
      getDataParticipants(docID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docID])

  const modalVerification = async (uuid) => {
    const token = await getToken({
      audience: 'veridocid-dashboard',
    })
    const json = await getData(token, uuid)
    if (json) {
      setJsonVerification(json)
    }

    const isFailedBiometrics = json.idVerification?.data?.globalResult

    if (isFailedBiometrics !== 'Ok') {
      setFailedBiometrics(true)
    }
    setOpenEnrollModal(true)
  }

  return (
    <Modal
      style={{
        textAlign: 'center',
        position: 'static',
        height: 'auto',
        padding: '0 0 1.4rem',
        maxWidth: '320px',
      }}
      open={openModalDP}
      size="small"
    >
      <Modal.Header
        style={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'left',
          justifyContent: 'space-between',
          paddingRight: '0.6rem',
          paddingLeft: '1rem'
        }}
      >
        <span style={{ color: 'var(--primary-color)' }}>
          Participantes con verificaciones
        </span>
        <VscClose
          size={17}
          strokeWidth={1.8}
          style={{ cursor: 'pointer' }}
          onClick={() => setOpenModalDP(false)}
        />
      </Modal.Header>
      {loading ? (
        <p style={{ marginTop: '10px' }}>{t('loading')}...</p>
      ) : (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(13rem, 1fr))',
            justifyItems: 'center',
            marginTop: '14px',
            rowGap: '20px',
          }}
        >
          {participants &&
            participants.map((data, i) => (
              <div
                key={i}
                style={{
                  fontSize: 'clamp(0.84rem, 0.85rem, 0.86rem)',
                  backgroundColor: !isEmpty(data.idVerification)
                    ? data.idVerification.globalResult === 'Ok'
                      ? '#C7F2A4'
                      : '#F8C4B4'
                    : '#F7F7F7',
                  padding: '1rem 0 15px',
                  borderRadius: '10px',
                  width: '80%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                <strong>{data?.participant?.name || '-'}</strong>
                <p style={{ padding: '10px 0', margin: '0' }}>
                  {data?.participant?.participantId || '-'}
                </p>
                {data?.participant?.uuid !== '' &&
                !isEmpty(data.idVerification) ? (
                  <Button
                    onClick={() => modalVerification(data?.participant?.uuid)}
                    className="btn_clear_data"
                    style={{ margin: '0', padding: '0' }}
                    size="tiny"
                  >
                    Ver
                  </Button>
                ) : (
                  <p>Sin verificación</p>
                )}
              </div>
            ))}
        </div>
      )}
    </Modal>
  )
}
