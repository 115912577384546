import download from 'downloadjs'
import { useTranslation } from 'react-i18next'
import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

// Styles and components
import '../../styles/ContractStyles.css'
import { LoadingOutlined } from '@ant-design/icons'
import { Row, Col, Card, Collapse, Spin, Typography } from 'antd'

// Utils
import {
  getDataSign,
  getDataContractsWithVerification,
  getDocumentBase64Sign,
  getCountParticipantsAndSignatories,
} from '../../utils/getDataCustomize'
import { isEmpty } from 'lodash'
import useToken from '../../utils/useToken'
import { getDate, getTime } from '../../utils/getDate'
import {
  DownloadContract,
  DateSignContract,
  LegalRepresentativeContract,
  ShowCounter,
  SignatoriesContract,
  StatusContract,
  ViewContract,
} from './ContractUI'
const { Text } = Typography

const ContractsClientDataShow = ({ id }) => {
  const { Panel } = Collapse
  const history = useHistory()
  const getToken = useToken()
  const { uuid } = useParams()
  const { t } = useTranslation()
  const [data, setData] = useState(null)
  const [dataSign, setDataSign] = useState(null)
  const [loadingState, setLoadingState] = useState(false)
  const [loadingDownload, setLoadingDownload] = useState(false)
  const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />
  // Count
  const [countSignWithVerification, setCountSignWithVerification] = useState({})
  const [loadingCount, setLoadingCount] = useState(false)

  useEffect(() => {
    const getDataSignClient = async () => {
      setLoadingState(true)
      if (uuid) {
        try {
          const token = await getToken({
            audience: 'veridocid-dashboard',
          })

          const json = await getDataSign(token, uuid)
          setData(json)
          setLoadingState(false)
        } catch (error) {
          console.error('error', error)
          setLoadingState(false)
        }
      }
    }

    getDataSignClient()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid])

  useEffect(() => {
    const getDataSign = async () => {
      setLoadingState(true)
      if (uuid) {
        try {
          const token = await getToken({
            audience: 'veridocid-dashboard',
          })

          const json = await getDataContractsWithVerification(token, uuid)
          setDataSign(json)
          setLoadingState(false)
        } catch (error) {
          console.error('error', error)
          setLoadingState(false)
        }
      }
    }

    getDataSign()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid])

  // View Contract
  const handleViewContract = (documentId) => {
    if (data) {
      history.push(`/resultado-contrato/${documentId}/${uuid}/${id}`)
    }
  }

  // Edit contract
  const handleEditContract = (documentId) => {
    if (data) {
      history.push(`/editar-contrato/${documentId}/${uuid}/${id}`)
    }
  }

  // Download contract
  const downloadContract = async (clientDocId) => {
    setLoadingDownload(true)

    try {
      const token = await getToken({
        audience: 'veridocid-dashboard',
      })
      const base64 = await getDocumentBase64Sign(token, clientDocId, uuid)

      if (base64 && clientDocId) {
        download(
          base64.includes('data:application/pdf;base64,')
            ? base64
            : 'data:application/pdf;base64,' + base64,
          `${clientDocId}`,
          'application/pdf'
        )
      }

      setLoadingDownload(false)
    } catch (error) {
      console.log(error)
      setLoadingDownload(false)
    }
  }

  // Get count of participants and signatories
  const counts = async (docID) => {
    setLoadingCount(true)
    try {
      const token = await getToken({
        audience: 'veridocid',
      })

      let getInfo = await getCountParticipantsAndSignatories(token, docID)

      if (getInfo !== null) {
        setCountSignWithVerification(getInfo)
      }
      setLoadingCount(false)
    } catch (error) {
      setLoadingCount(false)
    }
  }

  return (
    <>
      <Row className="title_header_contract">
        <Col span={24}>
          <h2
            style={{
              color: 'white',
              fontSize: 'clamp(1em, 1.39em, 1.55em)',
              letterSpacing: '0.5px',
            }}
          >
            {t('title_contract')}
          </h2>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card
            bordered={true}
            style={{ borderColor: '#D4D4D5', marginBottom: '2em' }}
          >
            {loadingState ? (
              <div className="content_loading_data_contract">
                <Spin
                  indicator={antIcon}
                  tip="Cargando..."
                  style={{ color: 'var(--primary-color)' }}
                />
              </div>
            ) : (
              <div className="content_data_contract">
                {!loadingState &&
                (data?.signDocument.length > 0 ||
                  dataSign?.signDocument.length > 0) ? (
                  <Collapse
                    bordered={true}
                    // defaultActiveKey={['0']}
                    className="data_contract_collapse_custom"
                  >
                    {data?.signDocument
                      ? data?.signDocument.map((dataDoc, index) => (
                          <Panel
                            header={`Contrato ${
                              dataDoc?.documentName || 'Sin nombre'
                            }`}
                            key={index}
                            className="data_contract__collapse_custom_panel"
                          >
                            <div className="content_status_general_sign">
                              {/* Document */}
                              <div className="content_file content_data_panel">
                                <h4 className="title_panel_custom">
                                  {t('contract_data__file')}
                                </h4>
                                <div className="description_panel_custom">
                                  <Text
                                    style={{
                                      color: '#2d2f6d',
                                      fontSize: '0.97rem',
                                      fontWeight: '500',
                                      paddingRight: '10px',
                                    }}
                                    ellipsis
                                  >
                                    {dataDoc?.documentName}
                                  </Text>
                                  <div
                                    className="content_main_actions_contract"
                                    style={{
                                      display: 'grid',
                                      gridTemplateColumns:
                                        'repeat(auto-fit, minmax(30%, 1fr))',
                                      alignItems: 'center',
                                    }}
                                  >
                                    {/* View contract */}
                                    <ViewContract
                                      data={dataDoc}
                                      handleEditContract={() =>
                                        handleEditContract(dataDoc?.documentID)
                                      }
                                      handleViewContract={() =>
                                        handleViewContract(dataDoc?.documentID)
                                      }
                                    />
                                    {/* Download contract */}
                                    <DownloadContract
                                      data={dataDoc}
                                      loadingDownload={loadingDownload}
                                      downloadContract={() =>
                                        downloadContract(dataDoc?.documentID)
                                      }
                                    />
                                    {/* Show counter */}
                                    <ShowCounter
                                      data={dataDoc}
                                      countSignWithVerification={
                                        countSignWithVerification
                                      }
                                      loadingCount={loadingCount}
                                      counts={() => counts(dataDoc?.documentID)}
                                    />
                                    {/* Spaces */}
                                    <div />
                                  </div>
                                </div>
                              </div>
                              {/* Signatories */}
                              <SignatoriesContract t={t} data={dataDoc} />
                              {/* Status */}
                              <StatusContract t={t} data={dataDoc} />
                              {/* Date sign */}
                              <DateSignContract t={t} data={dataDoc} />
                              {/* Representante legal */}
                              {dataDoc?.signsLegalRepresentative !== null ||
                              !isEmpty(dataDoc?.signsLegalRepresentative) ? (
                                <LegalRepresentativeContract
                                  t={t}
                                  data={dataDoc}
                                />
                              ) : null}
                              {/* Info contract */}
                              <div className="content_data_author_contract">
                                {dataDoc?.creatorEmail ? (
                                  <p>
                                    {t('contract_data__create_by')}:{' '}
                                    <span>
                                      {!dataDoc?.creatorName
                                        ? dataDoc?.creatorEmail
                                        : dataDoc?.creatorName || '-'}
                                    </span>
                                  </p>
                                ) : null}
                                {dataDoc?.createdAt ? (
                                  <>
                                    <p>
                                      {/* Fecha */}
                                      {t('contract_data__date_create')}:{' '}
                                      <span>
                                        {getDate(dataDoc?.createdAt) || '-'}
                                      </span>
                                    </p>
                                    <p>
                                      {/* Hora */}
                                      {t('contract_data__hour_create')}:{' '}
                                      <span>
                                        {getTime(dataDoc?.createdAt) || '-'}
                                      </span>
                                    </p>
                                  </>
                                ) : null}
                                {dataDoc?.status ? (
                                  <p>
                                    {t('contract_data__status')}:{' '}
                                    <span>
                                      {dataDoc?.status === 'valid'
                                        ? 'En proceso'
                                        : dataDoc?.status === 'disabled'
                                        ? 'Deshabilitado'
                                        : 'Completado' || '-'}
                                    </span>
                                  </p>
                                ) : null}
                                {dataDoc?.country ? (
                                  <p>
                                    {t('country')}:{' '}
                                    <span>{dataDoc?.country || '-'}</span>
                                  </p>
                                ) : null}
                              </div>
                              {/* Last */}
                            </div>
                          </Panel>
                        ))
                      : null}
                    <h4
                      style={{
                        padding: '0.9rem 0 0.9rem 1.1rem',
                        color: '#2d2f6d',
                      }}
                    >
                      Contratos como participante
                    </h4>
                    {dataSign?.signDocument
                      ? dataSign?.signDocument.map((sign, index) => (
                          <Panel
                            header={`${sign?.documentName || 'Sin nombre'}`}
                            key={index + 20}
                            className="data_contract__collapse_custom_panel"
                          >
                            <div className="content_status_general_sign">
                              {/* Document */}
                              <div className="content_file content_data_panel">
                                <h4 className="title_panel_custom">
                                  {t('contract_data__file')}
                                </h4>
                                <div className="description_panel_custom">
                                  <Text
                                    style={{
                                      color: '#2d2f6d',
                                      fontSize: '0.97rem',
                                      fontWeight: '500',
                                      paddingRight: '10px',
                                    }}
                                    ellipsis
                                  >
                                    {sign?.documentName}
                                  </Text>
                                  <div
                                    className="content_main_actions_contract"
                                    style={{
                                      display: 'grid',
                                      gridTemplateColumns:
                                        'repeat(auto-fit, minmax(20%, 1fr))',
                                      alignItems: 'center',
                                    }}
                                  >
                                    {/* View contract */}
                                    <ViewContract
                                      data={sign}
                                      handleEditContract={() =>
                                        handleEditContract(sign?.documentID)
                                      }
                                      handleViewContract={() =>
                                        handleViewContract(sign?.documentID)
                                      }
                                    />
                                    {/* Download contract */}
                                    <DownloadContract
                                      data={sign}
                                      loadingDownload={loadingDownload}
                                      downloadContract={() =>
                                        downloadContract(sign?.documentID)
                                      }
                                    />
                                    {/* Show counter */}
                                    <ShowCounter
                                      data={sign}
                                      countSignWithVerification={
                                        countSignWithVerification
                                      }
                                      loadingCount={loadingCount}
                                      counts={() => counts(sign?.documentID)}
                                    />
                                    {/* Spaces */}
                                    <div />
                                  </div>
                                </div>
                              </div>

                              {/* Signatories */}
                              <SignatoriesContract t={t} data={sign} />
                              {/* Status */}
                              <StatusContract t={t} data={sign} />
                              {/* Date sign */}
                              <DateSignContract t={t} data={sign} />
                              {/* Representante legal */}
                              {!isEmpty(sign?.signsLegalRepresentative) ? (
                                <LegalRepresentativeContract
                                  t={t}
                                  data={sign}
                                />
                              ) : null}
                              {/* Info contract */}
                              <div className="content_data_author_contract">
                                {sign?.creatorEmail ? (
                                  <p>
                                    {t('contract_data__create_by')}:{' '}
                                    <span>
                                      {!sign?.creatorName
                                        ? sign?.creatorEmail
                                        : sign?.creatorName || '-'}
                                    </span>
                                  </p>
                                ) : null}
                                {sign?.createdAt ? (
                                  <>
                                    <p>
                                      {/* Fecha */}
                                      {t('contract_data__date_create')}:{' '}
                                      <span>
                                        {getDate(sign?.createdAt) || '-'}
                                      </span>
                                    </p>
                                    <p>
                                      {/* Hora */}
                                      {t('contract_data__hour_create')}:{' '}
                                      <span>
                                        {getTime(sign?.createdAt) || '-'}
                                      </span>
                                    </p>
                                  </>
                                ) : null}
                                {sign?.status ? (
                                  <p>
                                    {t('contract_data__status')}:{' '}
                                    <span>
                                      {sign?.status === 'valid'
                                        ? 'En proceso'
                                        : sign?.status === 'approved'
                                        ? 'Aprobado'
                                        : sign?.status === 'rejected'
                                        ? 'Rechazado'
                                        : 'Completado' || '-'}
                                    </span>
                                  </p>
                                ) : null}
                                {sign?.country ? (
                                  <p>
                                    {t('country')}:{' '}
                                    <span>{sign?.country || '-'}</span>
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </Panel>
                        ))
                      : null}
                  </Collapse>
                ) : (
                  <p>Aún no has enviado un contrato</p>
                )}
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default ContractsClientDataShow
