import { styled } from '@mui/material/styles'
import { Button } from 'antd'

export const MyButton = styled(Button)({
  borderColor: 'var(--secondary-color)',
  color: 'var(--secondary-color)',
  outline: 'none !important',
  transition: 'ease-in-out 250ms all',
  margin: '1.5rem 0 1rem 0rem',
  '&:hover, &:active, &:focus': {
    outline: 'none !important',
    borderColor: 'var(--secondary-color)',
    backgroundColor: 'var(--secondary-color)',
    color: 'white',
  },
})

export const BtnReject = styled(Button)({
  borderColor: 'var(--light-pink-color)',
  color: 'var(--light-pink-color)',
  outline: 'none !important',
  margin: '0.9rem 0',
  transition: 'ease-in-out 250ms all',
  '&:hover, &:active, &:focus': {
    outline: 'none !important',
    borderColor: 'var(--light-pink-color)',
    backgroundColor: 'var(--light-pink-color)',
    color: 'white',
  },
})

export const BtnAproved = styled(Button)({
  backgroundColor: 'var(--primary-color)',
  borderColor: 'var(--primary-color)',
  color: '#fff',
  outline: 'none !important',
  margin: '0.9rem 0',
  transition: 'ease-in-out 250ms all',
  '&:hover, &:active, &:focus': {
    color: '#fff',
    outline: 'none !important',
    borderColor: 'var(--btn-hover-purple-color)',
    backgroundColor: 'var(--btn-hover-purple-color)',
  },
})

export const BtnShowDetail = styled(Button)({
  backgroundColor: 'var(--secondary-color)',
  borderColor: 'var(--secondary-color)',
  color: '#fff',
  outline: 'none !important',
  margin: '0.9rem 0',
  transition: 'ease-in-out 250ms all',
  '&:hover, &:active, &:focus': {
    color: '#fff',
    outline: 'none !important',
    borderColor: 'var(--secondary-color)',
    backgroundColor: 'var(--secondary-color)',
  },
})
