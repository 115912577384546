import React, { useEffect, useState } from 'react'
import { message, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import Select from 'react-select'
import { useParams, useHistory } from 'react-router-dom'
import download from 'downloadjs'
import qs from 'qs'
import { Typography } from 'antd'

import { API_DASHBOARD_URL } from '../../../const'
import { useAuth0 } from '../../../react-auth0-spa'
import useToken from '../../../utils/useToken'
import {
  labels,
  mockInfoImages,
  mockDimensionsImages,
  countryOptions,
  documentOptions,
  mexicoStates,
  commentOptions,
  initialCheckedModal,
  initialErrorModal,
  statusFixture,
  commentOptionsCaliente,
} from '../../../fixtures/panelExperts.fixture'
// import templates from '../../../fixtures/templates.fixture'

// Styles
import './BodyTemplateSelection.css'
import 'react-multi-carousel/lib/styles.css'
import '../../../../node_modules/react-multi-carousel/lib/styles.css'

import CarouselExperts from '../../UI/CarouselExperts/CarouselExperts'
import Spinner from '../../UI/Spinner/Spinner'
// import EditImages from './EditImages/EditImages'
import UploadImages from './UploadImages/UploadImages'
import ModalExperts from '../../UI/ModalExperts/ModalExperts'
// import CopyIdPopup from '../../CopyIdPopup'
import { FormControlLabel, Switch } from '@material-ui/core'

// Assets
import defaultImage from '../../../assets/default.png'
import notFound from '../../../assets/broken-1.png'
import AvailableDocumentsCarousel from './AvailableDocumentsCarousel/AvailableDocumentsCarousel'

const BodyTemplateSelection = () => {
  const { uuid, type } = useParams()
  const { user } = useAuth0()
  const getToken = useToken()
  const { Paragraph } = Typography
  const [data, setData] = useState(null)
  const [isCountrySelect, setIsCountrySelect] = useState(true)
  const [isDocumentTypeSelect, setIsDocumentTypeSelect] = useState(true)
  const [canSelectState, setCanSelectState] = useState(false)
  const [isComments, setIsComments] = useState(false)
  const [isChecked, setIsChecked] = useState(initialCheckedModal)
  const [isError, setIsError] = useState(initialErrorModal)
  const [isWaiting, setIsWaiting] = useState(false)
  const [expertComments, setExpertComments] = useState(null)
  const [country, setCountry] = useState({ label: 'Mexico', value: 'MX' })
  const [documentTypeTemplate, setDocumentTypeTemplate] = useState({
    id: 'v2',
    value: 'VOTING_CARD',
    label: 'Carnet de Votante',
  })
  const [state, setState] = useState(null)
  const [filteredTemplates, setFilteredTemplates] = useState(null)
  const [isTemplateOnClicked, setIsTemplateOnClicked] = useState(false)
  const [isTemplateIdSelected, setIsTemplateIdSelected] = useState(false)
  const [selectedTemplateId, setSelectedTemplateId] = useState(null)
  const [selectedTemplateName, setSelectedTemplateName] = useState(null)
  const [selectedTemplateImage, setSelectedTemplateImage] = useState(null)
  const [selectedTemplateBackImage, setSelectedTemplateBackImage] =
    useState(null)
  const [alreadyCropped, setAlreadyCropped] = useState(false)
  const [styleImage, setStyleImage] = useState(null)

  const [frontOriginal, setFrontOriginal] = useState(null)
  const [backOriginal, setBackOriginal] = useState(null)
  const [frontUltraviolet, setFrontUltraviolet] = useState(null)
  const [backUltraviolet, setBackUltraviolet] = useState(null)
  const [frontInfrared, setFrontInfrared] = useState(null)
  const [backInfrared, setBackInfrared] = useState(null)
  const [documentType, setDocumentType] = useState(null)
  const [isCaliente, setIsCaliente] = useState(false)
  const history = useHistory()
  const { confirm } = Modal
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  }

  useEffect(() => {
    const getData = async () => {
      try {
        const token = await getToken({
          audience: 'veridocid-dashboard',
        })

        const response = await fetch(
          `${API_DASHBOARD_URL}/experts/reports/${type}/${uuid}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )

        if (response.ok) {
          const json = await response.json()
          setData(json)
        } else {
          const error = await response.json()
          console.error(error)
        }
      } catch (error) {
        console.error(error)
      }
    }

    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid])

  const getImage = async (img, typeImg) => {
    switch (typeImg) {
      case 'front':
        setFrontOriginal({ data: img })
        break
      case 'back':
        setBackOriginal({ data: img })
        break
      case 'frontUltra':
        setFrontUltraviolet({ data: img })
        break
      case 'backUltra':
        setBackUltraviolet({ data: img })
        break
      case 'frontInfra':
        setFrontInfrared({ data: img })
        break
      case 'backInfra':
        setBackInfrared({ data: img })
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (!data) {
      return
    }

    if (data?.status !== statusFixture.manual) {
      history.goBack()
    }

    if (data?.frontImageCropped) {
      getImage(data?.frontImageCropped, 'front')
    } else if (data?.frontImage) {
      getImage(data?.frontImage, 'front')
    }
    if (data?.backImageCropped) {
      getImage(data?.backImageCropped, 'back')
    } else if (data?.backImage) {
      getImage(data?.backImage, 'back')
    }
    if (data?.frontUltravioletImageCropped) {
      getImage(data?.frontUltravioletImageCropped, 'frontUltra')
    } else if (data?.frontUltravioletImage) {
      getImage(data?.frontUltravioletImage, 'frontUltra')
    }
    if (data?.backUltravioletImageCropped) {
      getImage(data?.backUltravioletImageCropped, 'backUltra')
    } else if (data?.backUltravioletImage) {
      getImage(data?.backUltravioletImage, 'backUltra')
    }
    if (data?.frontInfraredImageCropped) {
      getImage(data?.frontInfraredImageCropped, 'frontInfra')
    } else if (data?.frontInfraredImage) {
      getImage(data?.frontInfraredImage, 'frontInfra')
    }
    if (data?.backInfraredImageCropped) {
      getImage(data?.backInfraredImageCropped, 'backInfra')
    } else if (data?.backInfraredImage) {
      getImage(data?.backInfraredImage, 'backInfra')
    }

    setDocumentType(data?.data?.documentType)

    setIsCaliente(data?.userName?.includes('Caliente'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const onChangeStatusHandler = async (status) => {
    setIsWaiting(true)
    const token = await getToken({
      audience: 'veridocid-dashboard',
    })
    const auth = await getToken({
      audience: 'veridocid',
    })

    let response

    if (status === statusFixture.checked) {
      response = await fetch(
        `${API_DASHBOARD_URL}/experts/records/changeStatus`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            uuid,
            status,
            expertComments,
            type,
            name: user.name,
            email: user.email,
            auth,
          }),
        }
      )
    } else {
      response = await fetch(
        `${API_DASHBOARD_URL}/experts/records/changeStatus`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            uuid,
            status,
            templateId: selectedTemplateId,
            alreadyCropped,
            type,
            name: user.name,
            email: user.email,
            auth,
          }),
        }
      )
    }

    if (response.ok) {
      setIsWaiting(false)
      setIsChecked({ isChecked: true, open: true })
    } else {
      setIsWaiting(false)
      setIsError({ isError: true, open: true })
    }
  }

  const onCountryChangeHanlder = (selectedCountry) => {
    setIsCountrySelect(true)

    if (selectedCountry.label === 'Venezuela') {
      setCountry({ label: 'Venezuela, Bolivarian Republic of', value: 'VE' })
    } else {
      setCountry(selectedCountry)
    }

    if (
      selectedCountry?.label === 'Mexico' &&
      documentTypeTemplate?.value === 'DRIVING_LICENSE'
    ) {
      setCanSelectState(true)
    } else {
      setCanSelectState(false)
    }
  }

  const onDocumentTypeChangeHanlder = (selectedDocument) => {
    setDocumentTypeTemplate(selectedDocument)
    setIsDocumentTypeSelect(true)
    switch (selectedDocument?.value) {
      case 'DRIVING_LICENSE':
        if (country?.label === 'Mexico') {
          setCanSelectState(true)
        }
        break
      default:
        setCanSelectState(false)
    }
  }

  const onStateChangeHandler = (selectedState) => {
    setState(selectedState)
  }

  const onCommentExpertHandler = (selectedComment) => {
    setExpertComments(selectedComment)
    setIsComments(true)
  }

  const addFace = async () => {
    try {
      setIsWaiting(true)
      const token = await getToken({
        audience: 'veridocid-dashboard',
      })
      const auth = await getToken({
        audience: 'veridocid',
      })

      const response = await fetch(
        `${API_DASHBOARD_URL}/experts/add/facefraud`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            uuid,
            type,
            token: auth,
          }),
        }
      )

      if (response.ok) {
        setIsWaiting(false)
        Modal.success({
          content:
            'Las imagenes han sido agregadas con éxito a la lista fraudulenta.',
        })
      } else {
        setIsWaiting(false)
        Modal.error({
          title: '¡Oh, no! Algo salió mal',
          content: 'Por favor, vuelve a intentarlo.',
        })
      }
    } catch (error) {
      Modal.error({
        title: '¡Oh, no! Algo salió mal',
        content: 'Por favor, vuelve a intentarlo.',
      })
      console.error(error)
    }
  }

  const showFaceModalConfirm = () => {
    confirm({
      title: '¿Estás seguro?',
      icon: <ExclamationCircleOutlined />,
      content: 'Esta acción es irreversible.',
      cancelText: 'No',
      okText: 'Si',
      onOk() {
        addFace()
      },
      onCancel() {},
    })
  }

  const contentObtained = frontOriginal?.data ? (
    <CarouselExperts
      imageFront={frontOriginal?.data ? frontOriginal?.data : null}
      imageBack={backOriginal?.data ? backOriginal?.data : null}
      imageFrontUltraviolet={
        frontUltraviolet?.data ? frontUltraviolet?.data : null
      }
      imageBackUltraviolet={
        backUltraviolet?.data ? backUltraviolet?.data : null
      }
      imageFrontInfrared={frontInfrared?.data ? frontInfrared?.data : null}
      imageBackimageFrontInfrared={
        backInfrared?.data ? backInfrared?.data : null
      }
      name="Obtained"
      title={labels.imageObtained}
      titleInfo={labels.infoImages}
      info={mockInfoImages}
      type={type}
    />
  ) : (
    <div
      style={{
        height: '100%',
        display: 'grid',
        placeItems: 'center',
      }}
    >
      <Spinner />
    </div>
  )

  const contentReference = (
    <CarouselExperts
      imageFront={
        !isTemplateOnClicked ? defaultImage : selectedTemplateImage || notFound
      }
      imageBack={selectedTemplateBackImage || null}
      name="Reference"
      title={labels.referenceDocument}
      titleInfo={labels.dimensionsOfficialDoc}
      info={mockDimensionsImages}
      isDefault={!isTemplateOnClicked}
    />
  )

  const onDownloadHandler = async () => {
    const token = await getToken({
      audience: 'veridocid-dashboard',
    })

    const response = await fetch(
      `${API_DASHBOARD_URL}/experts/records/downloadImages/${type}/${uuid}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    if (response.ok) {
      const data = await response.blob()
      download(data, `${uuid}.zip`, 'application/zip')
    }
  }

  const showModalConfirm = (typeButton) => {
    confirm({
      title: '¿Estás seguro?',
      icon: <ExclamationCircleOutlined />,
      content: 'Esta acción es irreversible.',
      cancelText: 'No',
      okText: 'Si',
      onOk() {
        if (typeButton === labels.verify) {
          onChangeStatusHandler(statusFixture.waiting)
        } else {
          onChangeStatusHandler(statusFixture.checked)
        }
      },
      onCancel() {},
    })
  }

  const showModalInfo = () => {
    if (selectedTemplateId && selectedTemplateName) {
      Modal.success({
        content: `La plantilla ${selectedTemplateName} se ha seleccionado correctamente.`,
      })
      setIsTemplateIdSelected(true)
    } else {
      Modal.error({
        title: '¡Oh, no! Algo salió mal',
        content: 'Por favor, vuelve a intentarlo.',
      })
      setIsTemplateIdSelected(false)
    }
  }

  const onFilteredHandler = async () => {
    try {
      setIsTemplateOnClicked(false)
      const token = await getToken({
        audience: 'veridocid-dashboard',
      })

      const query = qs.stringify({
        country: country.label,
        documentType: documentTypeTemplate.value,
      })

      const response = await fetch(
        `${API_DASHBOARD_URL}/experts/records/templates?${query}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      if (response.ok) {
        const templates = await response.json()
        setFilteredTemplates(templates)
      } else {
        const error = await response.json()
        console.error(error)
        message.error('Error al obtener las plantillas')
      }
    } catch (error) {
      console.error(error)
      message.error('Error al obtener las plantillas')
    }
  }

  // Select template
  const onSelectTemplateHandler = (id, name, image, back) => {
    setSelectedTemplateBackImage(back)
    setSelectedTemplateId(id)
    setSelectedTemplateName(name)
    setSelectedTemplateImage(image)
    setIsTemplateOnClicked(true)
    setStyleImage({ border: '4px outset rgba(64, 24, 255, 0.47)' })
  }

  return (
    <div className="Main">
      <div className="Uuid" style={{ marginBottom: '2.5em' }}>
        <span
          style={{
            marginRight: '0.6rem',
            fontWeight: '600',
            color: 'var(--primary-color)',
          }}
        >
          UUID:
        </span>
        <div className="CopyUuid" style={{ maxWidth: '360px' }}>
          {/* <CopyIdPopup text={uuid} copy={uuid} /> */}
          <Paragraph
            copyable
            style={{
              marginBottom: '0',
              color: 'var(--black-color)',
            }}
            code
          >
            {uuid}
          </Paragraph>
        </div>
      </div>
      <div className="Documents">
        <div className="Obtained">{contentObtained}</div>
        <div className="Reference">{contentReference}</div>
      </div>
      <div className="FilterDocuments">
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <b
            style={{
              // textDecoration: 'underline',
              color: '#303030',
              fontSize: 'clamp(1em, 1.1em ,1.2em)',
              paddingBottom: '0.5em',
            }}
          >
            {labels.filterDocuments}
          </b>
          <button
            className="ButtonFilterTemplate"
            disabled={!isCountrySelect || !isDocumentTypeSelect}
            onClick={onFilteredHandler}
          >
            {labels.filter}
          </button>
        </div>
        <div className="FilterDocumentsOptions">
          <div className="FDoptions">
            <b
              style={{
                fontSize: 'clamp(0.9em,1em,1.1em)',
                fontWeight: '600',
                color: 'var(--primary-color)',
                paddingBottom: '5px',
              }}
            >
              {labels.country}
            </b>
            <Select
              value={country}
              onChange={(selectedCountry) =>
                onCountryChangeHanlder(selectedCountry)
              }
              options={countryOptions}
            />
          </div>
          <div className="FDoptions">
            <b
              style={{
                fontSize: 'clamp(0.9em,1em,1.1em)',
                fontWeight: '600',
                color: 'var(--primary-color)',
                paddingBottom: '5px',
              }}
            >
              {labels.documentType}
            </b>
            <Select
              value={documentTypeTemplate}
              onChange={(selectedDocument) =>
                onDocumentTypeChangeHanlder(selectedDocument)
              }
              options={documentOptions}
            />
          </div>
          <div className="FDinput">
            <b
              style={{
                fontSize: 'clamp(0.9em,1em,1.1em)',
                fontWeight: '600',
                color: 'var(--primary-color)',
                paddingBottom: '5px',
              }}
            >
              {labels.state}
            </b>
            <Select
              value={state}
              onChange={(selectedState) => {
                onStateChangeHandler(selectedState)
              }}
              options={mexicoStates}
              isDisabled={!canSelectState}
            />
          </div>
        </div>
      </div>
      <div className="AvailableDocuments">
        {filteredTemplates?.length ? (
          <>
            <b
              style={{
                // textDecoration: 'underline',
                marginBottom: '1em',
                fontSize: 'clamp(1em, 1.1em ,1.2em)',
                color: 'var(--black-color)',
              }}
            >
              {labels.availableDocuments}
            </b>
            <div>
              <AvailableDocumentsCarousel
                responsive={responsive}
                templates={filteredTemplates}
                fallbackImage={notFound}
                onClick={(template) => {
                  onSelectTemplateHandler(
                    template?.templateId,
                    template?.name,
                    template?.image,
                    template?.backImage
                  )
                }}
                style={styleImage}
                templateClicked={selectedTemplateId}
              />
              <button
                className="ButtonSelect"
                disabled={!isTemplateOnClicked}
                onClick={showModalInfo}
              >
                {labels.select}
              </button>
            </div>
          </>
        ) : null}
      </div>
      {/* <div className="EditImagesOption">
        <b className="TitleClickable" style={{ width: '9.5rem' }}>
          {labels.editImages}
        </b>
        <EditImages
          frontImage={
            anverso?.data
              ? `data:image/${anverso?.ext ?? 'png'};base64,${anverso?.data}`
              : null
          }
          backImage={
            reverso?.data
              ? `data:image/${reverso?.ext ?? 'png'};base64,${reverso?.data}`
              : null
          }
        />
      </div> */}
      <div className="UploadImagesOption">
        <b className="TitleClickable" style={{ width: '13.7rem' }}>
          {labels.uploadImages}
        </b>
        <FormControlLabel
          value="start"
          control={
            <Switch
              defaultChecked={false}
              onChange={(e) => setAlreadyCropped(e.target.checked)}
            />
          }
          label="Recortado"
          labelPlacement="start"
          style={{ flexDirection: 'row' }}
        />
        <UploadImages
          uuid={uuid}
          isBack={backOriginal?.data ? true : false}
          onUpload={getImage}
          type={type}
          documentType={documentType}
        />
      </div>
      <div className="Comments">
        <div
          style={{
            marginBottom: '1em',
          }}
        >
          <b
            style={{
              // textDecoration: 'underline',
              color: '#303030',
              fontSize: 'clamp(1em, 1.1em ,1.2em)',
            }}
          >
            {labels.expertsComments}
          </b>
          <b style={{ color: '#D30F0F', fontSize: '1.2rem' }}>*</b>
        </div>
        <Select
          value={expertComments}
          options={isCaliente ? commentOptionsCaliente : commentOptions}
          onChange={(selectedComment) =>
            onCommentExpertHandler(selectedComment)
          }
        />
      </div>
      <div className="Buttons">
        <button
          className="ButtonFake"
          disabled={!isComments}
          onClick={showModalConfirm}
        >
          {labels.falseDocument}
        </button>
        <button
          className="ButtonNotProcess"
          disabled={!isComments}
          onClick={showModalConfirm}
        >
          {labels.notProcess}
        </button>
        <button
          className="ButtonVerify"
          disabled={!isTemplateIdSelected}
          onClick={() => showModalConfirm(labels.verify)}
        >
          {labels.verify}
        </button>
        <button
          className="ButtonAddFace"
          onClick={() => showFaceModalConfirm()}
        >
          {labels.addFraudFace}
        </button>
        <button className="ButtonDownload" onClick={onDownloadHandler}>
          {labels.downloadImages}
        </button>
      </div>
      <ModalExperts
        isOpenModal={isChecked?.open ? isChecked : isError}
        isLoading={isWaiting}
      />
    </div>
  )
}

export default BodyTemplateSelection
