import React, { useState } from 'react'
import { Image, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import './SecurityFeatures.css'
import { labels, categories } from '../../../../fixtures/panelExperts.fixture'
import iconAwesomeCheck from '../../../../assets/icons/awesome-check.svg'
import iconAwesomeFail from '../../../../assets/icons/awesome-fail.svg'
import iconAwesomeExclamation from '../../../../assets/icons/awesome-exclamation.svg'
import { API_DASHBOARD_URL } from '../../../../const'
import useToken from '../../../../utils/useToken'

const SecurityFeatures = ({ uuid, type, ...props }) => {
  const getToken = useToken()
  const { confirm } = Modal
  const [isClicked, setIsClicked] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const [output, setOutput] = useState(null)
  const [isOverwriting, setIsOverwriting] = useState(false)
  const dataSecurityFeatures = props.response

  const onCancelHandler = () => {
    setIsEdit(false)
    setIsClicked(null)
  }

  const onEditHandler = (key) => {
    setIsClicked(key)
    setIsEdit(true)
  }

  const onChangeInputHandler = (e) => {
    const newOutput = e.target.value
    setOutput(Number(newOutput))
    if (newOutput.match(/[0-9]/g) && Number(newOutput) <= 100) {
      setIsOverwriting(true)
    } else {
      setIsOverwriting(false)
    }
  }

  const showModalConfirm = (value) => {
    confirm({
      title: '¿Estás seguro?',
      icon: <ExclamationCircleOutlined />,
      content: 'Esta acción es irreversible.',
      cancelText: 'No',
      okText: 'Si',
      onOk() {
        onChangeSecurityFeaturesValue(value)
      },
      onCancel() {},
    })
  }

  const showModalInfo = (status) => {
    if (status) {
      Modal.success({
        content: `Se ha modificado el valor correctamente.`,
        onOk() {
          window.location.reload()
        },
      })
    } else {
      Modal.error({
        title: '¡Oh, no! Algo salió mal',
        content: 'Por favor, vuelve a intentarlo.',
      })
    }
  }

  const onChangeSecurityFeaturesValue = async (key) => {
    try {
      const token = await getToken({
        audience: 'veridocid-dashboard',
      })
      dataSecurityFeatures[key].output = output
      const response = await fetch(
        `${API_DASHBOARD_URL}/experts/records/updateDocumentVerification`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            uuid,
            output,
            category: categories.securityFeatures,
            key,
            type,
          }),
        }
      )
      if (response.ok) {
        showModalInfo(true)
        onCancelHandler()
      } else {
        const error = await response.json()
        console.error(error)
        showModalInfo(false)
      }
    } catch (error) {
      showModalInfo(false)
      console.error(error)
    }
    setIsOverwriting(false)
  }

  const rows = dataSecurityFeatures?.map((resp, index) => {
    const inputFields = resp?.inputFields?.map((values) => (
      <Image
        src={`data:image/${resp?.ext ?? 'png'};base64,${values?.value}`}
        alt="origin"
        width={'8rem'}
        height={'auto'}
        key={Math.random()}
      />
    ))

    const value =
      isEdit && isClicked === index ? (
        <input
          defaultValue={resp?.output}
          style={{ width: '3rem' }}
          minLength="1"
          maxLength="6"
          onChange={(event) => onChangeInputHandler(event)}
        />
      ) : (
        <span
          className="SecurityFeaturesOutput"
          onClick={() => onEditHandler(index)}
        >
          {resp?.output}
        </span>
      )

    const rowOutput =
      resp?.result === 'Ok' || resp?.result === 'Overriden' ? (
        <td className="SecurityFeaturesRows">{resp?.output}</td>
      ) : (
        <td className="SecurityFeaturesRows">
          {value}
          {isClicked === index ? (
            <div className="EditButtons">
              <button className="CancelButton" onClick={onCancelHandler}>
                {labels.cancel}
              </button>
              <button
                className="OverwriteButton"
                disabled={!isOverwriting}
                onClick={() => showModalConfirm(isClicked)}
              >
                {labels.overwrite}
              </button>
            </div>
          ) : null}
        </td>
      )

    return (
      <tr key={index}>
        <td className="SecurityFeaturesRows">{resp?.name}</td>
        <td className="SecurityFeaturesRows">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
            }}
          >
            {inputFields}
          </div>
        </td>
        {rowOutput}
        <td className="SecurityFeaturesRows">
          <img
            src={
              resp?.result === 'Ok'
                ? iconAwesomeCheck
                : resp?.result === 'Overriden'
                ? iconAwesomeExclamation
                : iconAwesomeFail
            }
            alt="Result"
            style={{ width: '1.5rem' }}
          />
        </td>
      </tr>
    )
  })

  return (
    <div className="SecurityFeatures">
      <table className="TableSecurityFeatures">
        <thead>
          <tr>
            <th className="ThSecurityFeatures" style={{ width: '25%' }}>
              <b>{labels.field}</b>
            </th>
            <th className="ThSecurityFeatures" style={{ width: '40%' }}>
              <b>{labels.input}</b>
            </th>
            <th className="ThSecurityFeatures" style={{ width: '20%' }}>
              <b>{labels.output}</b>
            </th>
            <th className="ThSecurityFeatures" style={{ width: '15%' }}>
              <b>{labels.result}</b>
            </th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  )
}

export default SecurityFeatures
