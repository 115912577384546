import React from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { Grid, Button } from '@mui/material'
import { Tools, Tool, MoreTools, ShowPDF, DDZone } from './ViewPDFStyle'
import {
  VscArrowLeft,
  VscArrowRight,
  VscZoomIn,
  VscZoomOut,
  VscGoToFile,
} from 'react-icons/vsc'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export default function ViewPDF(props) {
  const {
    optionChange,
    setChangePDF,
    fileBase64,
    setFileBase64,
    page,
    setPage,
    totalPages,
    setTotalPages,
    zoom,
    setZoom,
    pageSize,
    handleRenderSuccess,
  } = props

  const onDocumentLoadSuccess = ({ numPages }) => {
    setTotalPages(numPages)
    setPage(1)
  }
  //METODOS PARA DESPLAZARSE O INTERACTUAR CON EL PDF---------------------------------------
  const changePage = (offset) =>
    setPage((prevPageNumber) => prevPageNumber + offset)

  const previousPage = () => {
    if (page > 1) changePage(-1)
  }

  const nextPage = () => {
    if (page < totalPages) changePage(1)
  }

  const zoomOut = () => {
    let newZoom = zoom - 0.1
    newZoom = newZoom.toFixed(1)
    newZoom = parseFloat(newZoom)

    if (newZoom >= 0.1) setZoom(newZoom)
  }

  const zoomIn = () => {
    let newZoom = zoom + 0.1
    newZoom = newZoom.toFixed(1)
    newZoom = parseFloat(newZoom)

    if (newZoom <= 2) setZoom(newZoom)
  }

  const handlePDF = () => {
    setFileBase64('false')
    setChangePDF(true)
  }

  return (
    <>
      <Grid item xs={fileBase64 === 'false' ? 12 : 8}>
        <Tools container>
          <Grid item xs={5}>
            <Grid container>
              <Tool item xs={4}>
                {page > 1 ? (
                  <Button onClick={previousPage} style={{ outline: 'none' }}>
                    <VscArrowLeft size={24} color={'#eee'} strokeWidth={0.3} />
                  </Button>
                ) : null}
              </Tool>
              <Tool item xs={4}>
                <div>
                  {page || (totalPages ? 1 : '--')} / {totalPages || '--'}
                </div>
              </Tool>
              <Tool item xs={4}>
                {page < totalPages ? (
                  <Button onClick={nextPage} style={{ outline: 'none' }}>
                    <VscArrowRight size={24} color={'#eee'} strokeWidth={0.3} />
                  </Button>
                ) : null}
              </Tool>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid container>
              <Tool item xs={4}>
                <Button onClick={zoomOut} style={{ outline: 'none' }}>
                  <VscZoomOut size={24} color={'#eee'} strokeWidth={0.3} />
                </Button>
              </Tool>
              <Tool item xs={4}>
                <div>{parseInt(zoom * 100)}%</div>
              </Tool>
              <Tool item xs={4}>
                {zoom < 2 ? (
                  <Button onClick={zoomIn} style={{ outline: 'none' }}>
                    <VscZoomIn size={24} color={'#eee'} strokeWidth={0.3} />
                  </Button>
                ) : null}
              </Tool>
            </Grid>
          </Grid>
          {optionChange ? (
            <MoreTools item xs={2}>
              {fileBase64 !== 'false' ? (
                <Button onClick={handlePDF} style={{ outline: 'none' }}>
                  <VscGoToFile size={24} color={'#eee'} strokeWidth={0.3} />
                </Button>
              ) : null}
            </MoreTools>
          ) : null}
        </Tools>
        <ShowPDF item xs={12} id="pdf_renderer">
          <Document
            file={`data:application/pdf;base64,${fileBase64}`}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page
              pageNumber={page}
              scale={zoom}
              onRenderSuccess={(e) => handleRenderSuccess(e)}
            />
          </Document>
          <DDZone item id="ddzone" width={pageSize[0]} height={pageSize[1]} />
        </ShowPDF>
      </Grid>
    </>
  )
}
