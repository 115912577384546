import React, { useEffect, useState } from 'react'

export default function VideoVerification(props) {
  const { signers, signPositions, handleVideoVerificationText } = props
  const [dataSigner, setDataSigner] = useState({})

  useEffect(() => {
    const foundData = signPositions.find(
      (signer) => signer?.user?.name === signers
    )

    setDataSigner(foundData?.user)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signers])

  return (
    <>
      <strong style={{ fontWeight: '600' }}>{signers}</strong>
      <textarea
        placeholder={`Ingrese la frase para el ${signers}`}
        maxLength={800}
        style={{ marginTop: '1rem ' }}
        className="input_search_uuid"
        id={`${dataSigner?.temporalID}`}
        onChange={(e) => handleVideoVerificationText(e.target)}
      />
    </>
  )
}
