import React, { useState, useEffect } from 'react'
import { useAuth0 } from '../react-auth0-spa.js'
import {
  Container,
  Details,
  InternalContainer,
  BtnReturn,
} from '../components/Signature/PDFSignatureNC/ContractStyle.jsx'
import ViewPDF from '../components/Signature/PDFSignatureNC/ViewPDF/ViewPDF'
import ConfigurationsPDF from '../components/Signature/PDFSignatureNC/ConfigurationsPDF/ConfigurationsPDF.jsx'
import ModalSign from '../components/Signature/PDFSignatureNC/Modal/ModalSign.js'
import boxSign from '../assets/caja firma.svg'
import '../components/Signature/PDFSignatureNC/ContractStyle.css'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

let signPositionsDOM = []
let offsetClickDown

export default function Contract() {
  const { user } = useAuth0()
  const { t } = useTranslation()
  //ViewPDF
  const [file, setFile] = useState(false)
  const [fileBase64, setFileBase64] = useState('false')
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [pageSize, setPageSize] = useState([612, 792])
  //ConfigurationsPDF
  const [currentDrop, setCurrentDrop] = useState([])
  const [currentSelect, setCurrentSelect] = useState('')
  const [sign, setSign] = useState({
    signature: '',
    currentDate: '',
    option: 1,
  })
  const [whoSign, setWhoSign] = useState('me')
  const [signers, setSigners] = useState([])
  const [signPositions, setSignPositions] = useState([])
  const [eventDD, setEventDD] = useState(false)
  //Modal
  const [open, setOpen] = useState(false)

  // Setting Size
  const [newWidth, setNewWidth] = useState(136)
  const [newHeigth, setNewHeigth] = useState(73)

  // Settings Border
  const [imgBoxState, setImageBoxState] = useState(null)
  const [onBorder, setOnBorder] = useState(true)
  const [signBase64, setSignBase64] = useState('')

  const handleRenderSuccess = (pgd) => {
    let canvas = document.getElementById('pdf_renderer')
    canvas = canvas.firstChild
    canvas = canvas.firstChild
    canvas = canvas.firstChild
    setPageSize([canvas.style.width, canvas.style.height])

    handleComproveSigns()
  }

  const dd_dragstart = (e) => e.dataTransfer.setData('text/plain', e.target.id)

  const dd_dragover = (e) => e.preventDefault()

  const dd_drop = (e) => {
    e.preventDefault()

    const getContainerElement = e.target.parentElement

    let eX = 0
    let eY = 0

    const element = document.getElementById(e.dataTransfer.getData('text'))
    const newElement = document.getElementById(e.dataTransfer.getData('text'))

    if (!getContainerElement.id && !getContainerElement.className) {
      let [, x, y] = getContainerElement?.parentElement?.id
        .split('-')
        .splice(-3)

      const { coordinates } = signPositionsDOM.find(
        (sign) =>
          sign.coordinates.x === Number(x) && sign.coordinates.y === Number(y)
      )

      eX = coordinates.x + e.offsetX
      eY = coordinates.y + e.offsetY
    } else {
      eX = e.offsetX
      eY = e.offsetY
    }

    if (offsetClickDown) {
      eY = eY - (offsetClickDown.y - offsetClickDown.height)
      eX = eX - (offsetClickDown.x - offsetClickDown.width)
    }

    //Actualiza el arreglo con los datos actuales del elemento dropeado
    setCurrentDrop([{ newElement, element, eX, eY }])
  }

  const dd_mousedown = (e) => {
    let getContainerElement = e.target.parentElement
    if (getContainerElement.id === 'emailBox') {
      getContainerElement = getContainerElement.parentElement
    }

    let width = getContainerElement.offsetWidth / 2
    width += 10
    const height = getContainerElement.offsetHeight / 2

    offsetClickDown = { width, height, x: e.offsetX, y: e.offsetY }
  }

  const dd_mouseup = () => {
    offsetClickDown = null
  }

  const handleComproveSigns = () => {
    if (imgBoxState == null) {
      setImageBoxState(boxSign)
    }

    // Zona A (PDF)
    const dzone = document.getElementById('ddzone')

    if (dzone) {
      //Agrega una sola vez los eventos drop y dropover a la Zona A
      if (!eventDD) {
        dzone.addEventListener('dragover', dd_dragover)
        dzone.addEventListener('drop', dd_drop)
        dzone.addEventListener('mousedown', dd_mousedown)
        dzone.addEventListener('mouseup', dd_mouseup)
        setEventDD(true)
      }

      //Elimina los hijos ya existentes, Evita duplicados
      const tope = dzone.childElementCount
      for (let i = 0; i < tope; i++) {
        dzone.removeChild(dzone.firstChild)
      }

      let internalSignPositions = [...signPositions]

      //Comprueba cada uno de los firmantes
      for (let [i, signer] of internalSignPositions.entries()) {
        //Si el firmante tiene una firma activa en esta hoja, creara su caja de firma
        if (signer.page === page) {
          let mainDiv = document.createElement('div')
          let leftDiv = document.createElement('div')
          let rightDiv = document.createElement('div')
          let rightDiv_Close = document.createElement('div')
          let rightCloseText = document.createTextNode('×')
          let rightDiv_AddSign = document.createElement('div')
          let rightAddSign = document.createTextNode('+')
          let imgBox = document.createElement('img')
          let internalDiv = document.createElement('div')
          let internalDivEmail = document.createTextNode(signer.user.email)
          let date = document.createElement('div')
          let dateText = document.createTextNode(sign.currentDate)

          mainDiv.id = `sign${i}`

          for (let signerEndorse of signers) {
            if (signerEndorse?.isEndorse) {
              if (signerEndorse.email === signer.user.email) {
                let endorseDiv = document.createElement('div')
                let endorseTextSign = document.createTextNode(
                  `${signerEndorse.endorseText}`
                )
                endorseDiv.style.position = 'absolute'
                endorseDiv.style.top = 'calc(100% - 1px)'
                endorseDiv.style.lineHeight = '13px'
                endorseDiv.style.textAlign = 'center'
                endorseDiv.style.padding = '0.6rem 0.2rem'
                endorseDiv.style.marginLeft = '-16px'
                endorseDiv.style.whiteSpace = 'nowrap'

                endorseDiv.appendChild(endorseTextSign)
                mainDiv.appendChild(endorseDiv)
                break
              }
            }
          }

          mainDiv.draggable = true
          mainDiv.classList = 'mainDiv'
          mainDiv.style.fontSize = `${9 * zoom}px`
          mainDiv.style.width = `${newWidth * zoom}px`
          mainDiv.style.height = `${newHeigth * zoom}px`

          leftDiv.style.width = `${newWidth * zoom}px`
          leftDiv.style.height = `${newHeigth * zoom}px`
          leftDiv.style.display = `flex`
          leftDiv.style.justifyContent = `center`
          leftDiv.style.alignItems = `center`

          !onBorder ? (imgBox.style.opacity = 0) : (imgBox.src = imgBoxState)

          imgBox.draggable = false

          imgBox.style.width = `${newWidth * zoom}px`
          imgBox.style.height = `${newHeigth * zoom}px`
          imgBox.style.position = 'relative'

          leftDiv.appendChild(imgBox)
          internalDiv.classList = 'internalDiv'

          if (signer.user.email === user.email && whoSign !== 'others') {
            internalDiv.style.width = `${
              sign.signature === ''
                ? 113 * zoom
                : sign.option !== 3
                ? 80 * zoom
                : 113 * zoom
            }px`
            internalDiv.style.height = `${35 * zoom}px`
            internalDiv.style.paddingLeft = `${10 * zoom}px`
            internalDiv.style.backgroundImage = `url(${sign.signature})`
            internalDiv.style.backgroundSize = `${
              sign.option !== 3 ? 80 * zoom : 113 * zoom
            }px ${35 * zoom}px`

            date.classList = 'date'
            date.style.fontSize = `${9 * zoom}px`
            date.style.left = `${10 * zoom}px`
            date.style.bottom = `${6 * zoom}px`
            date.appendChild(dateText)
            leftDiv.appendChild(date)

            leftDiv.appendChild(internalDiv)
          } else {
            internalDiv.style.width = `${110 * zoom}px`
            internalDiv.style.height = `${35 * zoom}px`
            internalDiv.appendChild(internalDivEmail)
            leftDiv.appendChild(internalDiv)
          }
          rightDiv.classList = 'rightDiv'
          rightDiv.style.width = `${1 * zoom}px`
          rightDiv.style.height = `${newHeigth * zoom}px`

          rightDiv_Close.classList = 'rightDiv_Close'
          rightDiv_Close.style.width = `${20 * zoom}px`
          rightDiv_Close.style.height = `${20 * zoom}px`
          rightDiv_Close.style.fontSize = `${20 * zoom}px`
          rightDiv_Close.style.paddingLeft = `${0.5 * zoom}px`
          rightDiv_Close.style.paddingBottom = `${4 * zoom}px`
          rightDiv_Close.style.marginBottom = `${5 * zoom}px`
          rightDiv_Close.setAttribute(
            'id',
            `${signer?.user?.email}%PXY%${signer?.page}-${signer?.coordinates.x}-${signer?.coordinates.y} `
          )

          rightDiv_AddSign.classList = 'rightDiv_AddSign'
          rightDiv_AddSign.style.width = `${20 * zoom}px`
          rightDiv_AddSign.style.height = `${20 * zoom}px`
          rightDiv_AddSign.style.fontSize = `${20 * zoom}px`
          rightDiv_AddSign.style.paddingLeft = `${0.5 * zoom}px`
          rightDiv_AddSign.style.paddingBottom = `${4 * zoom}px`
          rightDiv_AddSign.style.marginBottom = `${5 * zoom}px`
          rightDiv_AddSign.setAttribute(
            'id',
            `${signer?.user?.email}%PXY%${signer?.page}-${signer?.coordinates.x}-${signer?.coordinates.y} `
          )
          rightDiv_Close.onclick = function (e) {
            let newA = []
            for (let signer of signPositions) {
              if (
                `${signer?.user?.email}%PXY%${signer?.page}-${signer?.coordinates.x}-${signer?.coordinates.y}` !==
                  e.target.id.trim() ||
                signer.page !== page
              )
                newA = [...newA, signer]
            }
            setSignPositions(newA)
            document.getElementById(`${e.target.id.trim()}`).remove()
          }

          rightDiv_AddSign.onclick = function (e) {
            let currentPosition
            for (let signer of signPositions) {
              if (
                `${signer?.user?.email}%PXY%${signer?.page}-${signer?.coordinates.x}-${signer?.coordinates.y}` ===
                e.target.id.trim()
              ) {
                currentPosition = signer
                break
              }
            }

            let newB = signPositions
            for (let count = 1; count <= totalPages; count++) {
              if (count !== page)
                newB.push({
                  coordinates: currentPosition.coordinates,
                  page: count,
                  user: currentPosition.user,
                  zoom: currentPosition.zoom,
                })
            }

            setSignPositions(newB)
          }
          rightDiv_Close.appendChild(rightCloseText)
          rightDiv_AddSign.appendChild(rightAddSign)

          rightDiv.appendChild(rightDiv_Close)
          rightDiv.appendChild(rightDiv_AddSign)

          mainDiv.appendChild(leftDiv)
          mainDiv.appendChild(rightDiv)

          mainDiv.setAttribute(
            'id',
            `${signer?.user?.email}%PXY%${signer?.page}-${signer?.coordinates.x}-${signer?.coordinates.y}`
          )
          mainDiv.ondragstart = function (e) {
            setCurrentSelect(e.target.id)
          }
          //convierte las coordenadas a relacion 1/1 y las ajusta al zoom actual
          mainDiv.style.top =
            (signer?.coordinates?.y / signer?.zoom) * zoom + 'px'
          mainDiv.style.marginLeft =
            (signer?.coordinates?.x / signer?.zoom) * zoom + 'px'
          dzone.appendChild(mainDiv)
        }
      }

      //Zona B (Zona derecha)
      const bzone = document.getElementById('ddzoneB')

      if (bzone) {
        //Elimina los hijos ya existentes, Evita duplicados
        const topeB = bzone.childElementCount
        for (let i = 0; i < topeB; i++) {
          bzone.removeChild(bzone.firstChild)
        }

        //Por cada firmante en el arreglo de la zona B se creara su caja
        for (let signer of signers) {
          let newDivB = document.createElement('div')
          newDivB.classList = 'taskB'
          newDivB.style.width = `${136 * zoom}px`
          newDivB.style.height = `${75 * zoom}px`
          newDivB.style.fontSize = `${9 * zoom}px`
          newDivB.setAttribute('draggable', true)

          let newImg = document.createElement('img')
          newImg.src = boxSign
          newImg.height = 75 * zoom
          newImg.width = 136 * zoom
          newImg.style.position = 'absolute'
          newImg.style.zIndex = 0
          // newImg.style.backgroundColor = 'blue'
          newImg.setAttribute('draggable', false)
          newDivB.appendChild(newImg)

          let internalDiv = document.createElement('div')
          internalDiv.style.height = `${75 * zoom}px`
          internalDiv.style.width = `${110 * zoom}px`
          internalDiv.style.overflow = 'hidden'
          internalDiv.style.display = 'flex'
          internalDiv.style.flexDirection = 'column'
          internalDiv.style.flexWrap = 'wrap'
          internalDiv.style.justifyContent = 'center'
          internalDiv.style.alignItems = 'center'
          internalDiv.style.zIndex = 98 * zoom

          let contentEmail = document.createTextNode(`${signer.email}`)
          internalDiv.appendChild(contentEmail)
          newDivB.appendChild(internalDiv)

          newDivB.setAttribute('id', `${signer.email}`)

          bzone.appendChild(newDivB)
        }
      }
    }
  }

  useEffect(() => {
    handleComproveSigns()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signPositions, sign, signers, imgBoxState, newWidth, newHeigth, onBorder])

  useEffect(() => {
    const handleCurrentUpdate = (currentDrop) => {
      //Valida que haya un elemento valido arrastrable
      if (currentDrop.length !== 0 && currentDrop[0].element) {
        //ajust centra la caja para un mejor control del drop
        const adjustX = 77 * zoom
        const adjustY = 36.5 * zoom
        let codsX = currentDrop[0].eX - adjustX
        let codsY = currentDrop[0].eY - adjustY
        let actualPositions = signPositions

        if (currentSelect !== '') {
          let newA = []
          for (let signPosition of signPositions) {
            if (
              `${signPosition.user.email}%PXY%${signPosition.page}-${signPosition.coordinates.x}-${signPosition.coordinates.y}` !==
              currentSelect
            )
              newA = [...newA, signPosition]
          }
          actualPositions = newA
          setCurrentSelect('')
        }

        let newA = []
        let email = currentDrop[0].element.id.trim()
        let nEmail = email.split('%PXY%', 1)
        newA = [
          ...actualPositions,
          {
            user: { email: nEmail[0], name: user.name },
            coordinates: { x: codsX, y: codsY },
            page,
            zoom,
          },
        ]
        setSignPositions(newA)
        signPositionsDOM = newA
      }
    }

    handleCurrentUpdate(currentDrop)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDrop])

  // History
  const history = useHistory()

  const returnContracts = () => {
    history.push(`/contratos`)
  }

  return (
    <Container container>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '2rem',
        }}
      >
        <Details>{`${t('newContract')}`}</Details>
        <BtnReturn onClick={() => returnContracts()} type="button">
          {`${t('return_home')}`}
        </BtnReturn>
      </div>
      <InternalContainer container>
        <ViewPDF
          setFile={setFile}
          fileBase64={fileBase64}
          setFileBase64={setFileBase64}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          setTotalPages={setTotalPages}
          zoom={zoom}
          setZoom={setZoom}
          pageSize={pageSize}
          handleRenderSuccess={handleRenderSuccess}
          dd_dragstart={dd_dragstart}
        />
        {fileBase64 !== 'false' ? (
          <ConfigurationsPDF
            totalPages={totalPages}
            pageSize={pageSize}
            file={file}
            fileBase64={fileBase64}
            whoSign={whoSign}
            setWhoSign={setWhoSign}
            sign={sign}
            setSign={setSign}
            zoom={zoom}
            signers={signers}
            setSigners={setSigners}
            signPositions={signPositions}
            setSignPositions={setSignPositions}
            user={user}
            setOpen={setOpen}
            dd_dragstart={dd_dragstart}
            boxSign={boxSign}
            onBorder={onBorder}
            setOnBorder={setOnBorder}
            setImageBoxState={setImageBoxState}
            setNewWidth={setNewWidth}
            newWidth={newWidth}
            setNewHeigth={setNewHeigth}
            newHeigth={newHeigth}
            setSignBase64={setSignBase64}
            signBase64={signBase64}
          />
        ) : null}
      </InternalContainer>
      {open ? (
        <ModalSign open={open} setOpen={setOpen} setSign={setSign} />
      ) : null}
    </Container>
  )
}
