import React, { useState } from 'react'

// Components
import { Switch, Button, Input } from 'antd'
import FormAddObserver from './FormAddObserver'
import FormAddSignatory from './FormAddSignatory'
// Utils
import './ConfigClienteStyles.css'

const ConfigClienteSign = ({
  // Signatories
  signers,
  changeInputSearch,
  changeInputNameSignatory,
  changeInputEmailSignatory,
  onChangeCheckCreateVerification,
  searchUUID,
  nameSignatory,
  emailSignatory,
  checkCreateVerification,
  handleVerificationData,
  addNewSignatory,
  cancelNewSignatory,
  set_visibility_error,
  set_msg_error,
  foundNameVerification,
  // Effects
  stateLoadingAddSigner,
  // Order signatories
  handleOrder,
  sortStatus,
  statusSwitchSort,
  stateTextSort,
  // Observers
  changeInputNameObserver,
  changeInputEmailObserver,
  nameObserver,
  emailObserver,
  addNewObserver,
  cancelNewObserver,
  set_visibility_error__observer,
  set_msg_error__observer,
  // Message email
  handleChangeTitle,
  handleChangeMessage,
  titleMessage,
  contentMessage,
  visibleErrorMessageEmail,
  classErrorMessageEmail,
  contentErrorMessageEmail,
}) => {
  // const [days, setDays] = useState('10')

  // Collapse
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [isCollapsedFormSignatory, setIsCollapsedFormSignatories] =
    useState(false)

  // Limite de tiempo en la duración del link del contrato
  /* const handleValidateLinkDays = (e) => {
    const days = e?.target?.value
    setDays(days)
  } */

  //---------------------- Añadir observadores (open or close form to add observers)
  const showFormToAddObserver = () => {
    setIsCollapsed(!isCollapsed)
  }

  //---------------------- Añadir firmantes (open or close form to add observers)
  const showFormToAddSignatory = () => {
    setIsCollapsedFormSignatories(!isCollapsedFormSignatory)
  }

  return (
    <div className="content_config_client">
      <span className="description_config_client">
        Configure sus opciones que mas se ajusten a sus necesidades
      </span>
      <form className="config_form">
        {/* Duration link */}
        {/* Una vez se modifique o se incluya el tiempo de duración del link se habilitará esta parte
        <div className="config_form__content_input">
          <label htmlFor="duration" className="lbl_input">
            Duration del enlace del contrato
          </label>
          <Radio.Group
            defaultValue={'10'}
            name="duration_days"
            onChange={handleValidateLinkDays}
            value={days}
          >
            <Radio value={'10'}>10 días</Radio>
            <Radio value={'20'}>20 días</Radio>
            <Radio value={'30'}>30 días</Radio>
          </Radio.Group>
        </div> */}
        {/* signatories */}
        <div
          className="config_form__content_input"
          style={{ margin: '0 0 0 0' }}
        >
          <div className="two_columns_data_form">
            <div className="form_data_description">
              <label htmlFor="signatories" className="lbl_input">
                Firmantes
              </label>
              <span className="description_action_form">
                Agregue nuevos firmantes para añadir a su documento, el primer
                firmante siempre debe de ser el correspondiente a la
                verificación.
              </span>
            </div>
            <Button size={'small'} onClick={() => showFormToAddSignatory()}>
              Agregar
            </Button>
          </div>
          <FormAddSignatory
            signers={signers}
            onSearchIdentifier={changeInputSearch}
            onChangeNameSign={changeInputNameSignatory}
            onChangeEmailSign={changeInputEmailSignatory}
            onChangeCheckCreateVerification={onChangeCheckCreateVerification}
            onAddSign={addNewSignatory}
            onCancelSign={cancelNewSignatory}
            handleVerificationData={handleVerificationData}
            // Data form
            valueSearchIdentifier={searchUUID}
            valueNameSign={nameSignatory}
            valueEmailSign={emailSignatory}
            checkCreateVerification={checkCreateVerification}
            // Effects
            stateLoadingAddSigner={stateLoadingAddSigner}
            // Validation
            foundNameVerification={foundNameVerification}
            // Error message
            msg_visibility={set_visibility_error}
            content_msg_visibility={set_msg_error}
            // Collapse
            stateCollapse={isCollapsedFormSignatory}
          />
          {/*---- Sort ----*/}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              marginTop: '1rem',
            }}
          >
            <Switch
              defaultChecked
              style={{
                width: '30px',
                marginRight: '10px',
              }}
              checked={sortStatus}
              onChange={handleOrder}
            />
            <span>{stateTextSort}</span>
          </div>
          {/*---- Add signatories ----*/}
          <ul
            id="orderSigners"
            style={{
              width: '100%',
              padding: '0',
              marginTop: '10px',
            }}
          ></ul>
        </div>
        {/* observers */}
        <div
          className="config_form__content_input"
          style={{ margin: '0 0 1.3rem 0' }}
        >
          <div className="two_columns_data_form">
            <div className="form_data_description">
              <label htmlFor="observers" className="lbl_input">
                Observador
              </label>
              <span className="description_action_form">
                Agregue uno o varios observadores para que sigan el proceso de
                firma
              </span>
            </div>
            <Button size={'small'} onClick={() => showFormToAddObserver()}>
              Agregar
            </Button>
          </div>
          <FormAddObserver
            onChangeNameObserver={changeInputNameObserver}
            onChangeEmailObserver={changeInputEmailObserver}
            onAddObserver={addNewObserver}
            onCancelObserver={cancelNewObserver}
            valueNameObserver={nameObserver}
            valueEmailObserver={emailObserver}
            // Error message
            msg_visibility_observer={set_visibility_error__observer}
            content_msg_visibility_observer={set_msg_error__observer}
            // Collapse
            stateCollapseFormObserver={isCollapsed}
          />
          {/*---- Add signatories ----*/}
          <div
            id="orderObserver"
            style={{
              width: '100%',
              padding: '0',
              marginTop: '10px',
            }}
          ></div>
        </div>
        {/* Message */}
        <div className="config_form__content_input">
          <p className="lbl_input">Personalización del mensaje</p>
          <div className="content_data_message_sign">
            <label htmlFor="title_msg_sign">Título</label>
            <Input
              type="text"
              name="title_msg_sign"
              onChange={handleChangeTitle}
              value={titleMessage}
              style={{ color: 'gray' }}
            />
            <label htmlFor="msg_sign">Mensaje</label>
            <Input.TextArea
              onChange={handleChangeMessage}
              value={contentMessage}
              style={{ color: 'gray' }}
            />
          </div>
          {/* Error */}
          {visibleErrorMessageEmail ? (
            <div
              className="message_error"
              style={{ display: `${classErrorMessageEmail}` }}
            >
              <h5 className="title_msg_error" style={{ color: '#ff6363' }}>
                Envío de correo(s)
              </h5>
              <p className="content_msg_error">{contentErrorMessageEmail}</p>
            </div>
          ) : null}
        </div>
      </form>
    </div>
  )
}

export default ConfigClienteSign
