import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

// Components and utils
import { Row, Col, Card, Modal, Skeleton } from 'antd'
import { VscArchive } from 'react-icons/vsc'
import './ComprobantesStyles.css'
import SendMailBelvo from './ProcessBelvo/SendMailBelvo'
import useToken from '../../utils/useToken'
import { API_URL } from '../../const/index'
import BelvoData from './ProcessBelvo/BelvoData'

const ComprobantesIngresosClientDataShow = ({
  id,
  uuid,
  //   Name client
  dataClient,
  //   Data belvo
  dataLinkBelvoFound,
  statusCheckUser,
  //   Data bank and fiscal
  statusBankData,
  generalDataBank,
  statusFiscalData,
  generalDataFiscal,
}) => {
  const { t } = useTranslation()
  const getToken = useToken()
  const { success, error } = Modal

  // Modal
  const [visibleModalSendEmailBelvo, setVisibleModalEmailBelvo] =
    useState(false)
  // Input data
  const [emailDataBelvo, setEmailDataBelvo] = useState('')
  const [loadingButton, setLoadingButton] = useState(false)
  const [dataBankOrFiscal, setDataBankOrFiscal] = useState('retail')
  // Control of errors
  const [errorEmail, setErrorEmail] = useState({
    visible: false,
    message: '',
  })
  // Api errors
  const [errorAPI, setErrorApi] = useState({
    visible: false,
    content: '',
  })

  //   Laoding card - verified status user
  const [loadingCard, setLoadingCard] = useState(false)

  useEffect(() => {
    if (statusCheckUser) {
      setLoadingCard(false)
    } else {
      setLoadingCard(true)
      setTimeout(() => {
        setLoadingCard(false)
      }, 1200)
    }
  }, [statusCheckUser])

  //---------------    Modal options
  const showModalEmailBelvo = () => {
    setVisibleModalEmailBelvo(true)
  }

  // Validate email
  const regexValidateEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  //   Close modal
  const handleCancel = () => {
    setVisibleModalEmailBelvo(false)
  }

  //--------------- Function to send mail to start the process
  const sendEmailToWidgetBelvoClient = async () => {
    setLoadingButton(true)
    try {
      const token = await getToken({
        audience: 'veridocid',
      })

      const response = await fetch(`${API_URL}/send-email-belvo`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
          uuid,
          email: emailDataBelvo,
          type: dataBankOrFiscal,
        }),
      })

      // console.log(response)
      // const reponseJSON = await response.json()

      if (response.ok) {
        // console.log(reponseJSON.message)
        success({
          centered: true,
          closable: false,
          content: (
            <>
              <h5 style={{ marginBottom: '0', lineHeight: '1.4' }}>
                {t('succesTitleEmail')}
              </h5>
            </>
          ),
          bodyStyle: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          },
          onOk() {
            setVisibleModalEmailBelvo(false)
            setEmailDataBelvo('')
          },
        })
      } else {
        error({
          centered: true,
          closable: false,
          content: (
            <>
              <h5 style={{ marginBottom: '0', lineHeight: '1.4' }}>
                {t('errorTitleEmail')}
              </h5>
            </>
          ),
          bodyStyle: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          },
          onOk() {
            setEmailDataBelvo('')
          },
        })
        setLoadingButton(false)
      }
      setLoadingButton(false)
    } catch (error) {
      setLoadingButton(false)
      setErrorApi({
        visible: true,
        content: `${t('messageErrorAPINip')}`,
      })
    }
  }

  //   Send mail
  const handleSend = () => {
    const validateEmail = regexValidateEmail.test(
      String(emailDataBelvo).toLowerCase()
    )

    if (emailDataBelvo.length === 0) {
      setErrorEmail({
        visible: true,
        message: `${t('messageErrorEmptyInputEmail')}`,
      })
    } else if (!validateEmail) {
      setErrorEmail({
        visible: true,
        message: `${t('invalidEmailFormat')}`,
      })
    } else {
      setErrorEmail({
        visible: false,
        message: `${t('messageErrorEmptyInputEmail')}`,
      })

      // Call api send_mail_nip
      sendEmailToWidgetBelvoClient()
    }
  }

  //--------------- Data input client
  const changeDataEmailBelvo = (event) => {
    const clientEmail = event?.target?.value
    if (clientEmail.length === 0) {
      setErrorEmail({
        visible: true,
        message: `${t('invalidEmailFormat')}`,
      })
    } else {
      setErrorEmail({
        visible: false,
        message: `${t('invalidEmailFormat')}`,
      })
    }
    setEmailDataBelvo(clientEmail)
  }

  // Data bank or fiscal - radio button
  const handleSelectBankOrFiscal = (e) => {
    const selectData = e?.target?.value
    setDataBankOrFiscal(selectData)
  }

  // Close the message - api errors
  const handleDismiss = () => {
    setErrorApi({ visible: false, content: '' })
  }
  return (
    <>
      <Row className="title_header_custom_view_data">
        <Col span={24}>
          <h2
            style={{
              color: 'white',
              fontSize: 'clamp(1em, 1.39em, 1.55em)',
              letterSpacing: '0.5px',
            }}
          >
            {t('payment_vouchers')}
          </h2>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card
            bordered={true}
            style={{ borderColor: '#c4c4c4', marginBottom: '3em' }}
          >
            <Skeleton loading={loadingCard}>
              {statusCheckUser ? (
                <BelvoData
                  // Client
                  dataClient={dataClient}
                  dataBelvo={dataLinkBelvoFound}
                  //   Bank
                  dataBankBD={generalDataBank}
                  statusBanckClientBD={statusBankData}
                  // Fiscal
                  dataFiscalBD={generalDataFiscal}
                  statusFiscalClientBD={statusFiscalData}
                />
              ) : (
                <div className="content_data_process_belvo">
                  {/* Start process */}
                  <VscArchive size={50} color="var(--primary-color)" />
                  <div className="content_description_view_data">
                    <h3 className="title_description_view_data">
                      {t('title_payment_vouchers')}
                    </h3>
                    <p className="sub_title_view_data">
                      {t('description_payment_vouchers')}
                    </p>
                  </div>
                  <button
                    className="btns_actions_primary_not_background btn_process_view_data"
                    onClick={() => showModalEmailBelvo()}
                  >
                    {t('button_process_credit_history')}
                  </button>
                </div>
              )}
            </Skeleton>
          </Card>
          {/*----------- Modal send email belvo -----------*/}
          <SendMailBelvo
            title_send_mail={'Proceso de envío'}
            visibleModal={visibleModalSendEmailBelvo}
            handleSendMail={handleSend}
            handleCancelSendEmail={handleCancel}
            changeDataEmail={changeDataEmailBelvo}
            emailData={emailDataBelvo}
            stateLoadingSubmit={loadingButton}
            descriptionSendEmail={t('descriptionEmailBelvo')}
            // Radio button fiscal or bank
            changeSelectData={handleSelectBankOrFiscal}
            stateSelectData={dataBankOrFiscal}
            // Errors
            visibleMessageError={errorEmail.visible}
            messageInputError={errorEmail.message}
            classError={errorEmail.visible ? 'error' : ''}
            // Api message error
            visibleMsgErrorApi={errorAPI.visible}
            headerErrorMsg={`${t('email')}`}
            apiErrorMsg={errorAPI.content}
            changeStateVisibleMsgErrorApi={handleDismiss}
          />
          {/*----------- Modal send email belvo -----------*/}
        </Col>
      </Row>
    </>
  )
}

export default ComprobantesIngresosClientDataShow
