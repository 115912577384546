import { labels } from '../../../fixtures/panelExperts.fixture'
import { DatePicker as DatePickerCustom, Select } from 'antd'
import React from 'react'
import './Filter.css'

const { Option } = Select

const Filter = ({
  t,
  identifier,
  changeIdentifier,
  id,
  changeId,
  client,
  changeClient,
  startDate,
  changeStartDate,
  endDate,
  changeEndDate,
  onSearch,
  onClear,
  clients,
}) => (
  <div className="Filter" style={{ marginTop: '2em' }}>
    <div className="content_inputs_expert">
      {/* Identifier */}
      <div className="input_expert">
        <label htmlFor="identificator">{labels.identificator}</label>
        <input
          id="identificator"
          className="text_input_expert"
          type="text"
          value={identifier}
          placeholder={labels.identificator}
          onChange={changeIdentifier}
        />
      </div>
      {/* ID */}
      <div className="input_expert">
        <label htmlFor="clientID">{labels.clientID}</label>
        <input
          id="clientID"
          className="text_input_expert"
          type="text"
          placeholder={labels.clientID}
          value={id}
          onChange={changeId}
        />
      </div>
      {/* Cliente */}
      <div className="input_expert">
        <label htmlFor="user">{labels.client}</label>
        <Select
          value={client}
          onChange={changeClient}
          style={{ width: '100%' }}
          className="custom_input_form_search_doc"
        >
          <Option value="">{t('select')}</Option>
          {clients && clients.length > 0 ? (
            <>
              {clients.map((client, i) => (
                <Option key={i} value={client?._id}>
                  {client?.name}
                </Option>
              ))}
            </>
          ) : (
            <Option value={'not_clients'}>{t('noCustomers')}</Option>
          )}
        </Select>
      </div>
      {/* Start date*/}
      <div className="input_expert">
        <label htmlFor="user">{labels.start}</label>
        <DatePickerCustom
          style={{ width: '100%', padding: '0.5rem 0.6rem' }}
          onChange={(date, dateString) => changeStartDate(dateString)}
          value={startDate}
          allowClear={false}
          format="YYYY/MM/DD"
          placeholder={labels.start}
        />
      </div>
      {/* End date*/}
      <div className="input_expert">
        <label htmlFor="user">{labels.end}</label>
        <DatePickerCustom
          style={{ width: '100%', padding: '0.5rem 0.6rem' }}
          onChange={(date, dateString) => changeEndDate(dateString)}
          value={endDate}
          allowClear={false}
          format="YYYY/MM/DD"
          placeholder={labels.end}
        />
      </div>
    </div>
    <div className="content_actions_expert">
      <button onClick={onSearch} className="btns_actions_with_background">
        {labels.filter}
      </button>
      <button onClick={onClear} className="btns_actions_primary_not_background">
        Limpiar
      </button>
    </div>
  </div>
)

export default Filter
