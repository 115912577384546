import React from 'react'
import { Typography, Tooltip as TooltipDesign, message } from 'antd'
import { useTranslation } from 'react-i18next'
import { Table, Button } from 'semantic-ui-react'
import { VscTrash, VscHistory } from 'react-icons/vsc'
import { desactiveTemplates } from '../../../../utils/templatesServices'
import useToken from '../../../../utils/useToken'
import { useHistory } from 'react-router-dom'
import { isArray } from 'lodash'

// Table
const TableDesktop = (props) => {
  const { register, showOptions, setDelayData, uuidVerification } = props
  const history = useHistory()
  const getToken = useToken()
  const { t } = useTranslation()

  // Render body table
  const renderRegistros = (registro, i) => {
    const { Paragraph } = Typography
    return (
      <Table.Row key={i} style={{ cursor: 'pointer' }}>
        <Table.Cell
          verticalAlign="middle"
          textAlign="center"
          style={{ padding: '1rem 0' }}
          onClick={
            showOptions
              ? () => showDetails(registro?.uuid)
              : () =>
                  showDetailsWithVerification(registro?.uuid, uuidVerification)
          }
        >
          {registro?.name || '-'}
        </Table.Cell>
        <Table.Cell
          verticalAlign="middle"
          textAlign="center"
          style={{ padding: '1rem 0', cursor: 'text' }}
        >
          {registro?.uuid || '-'}
        </Table.Cell>
        <Table.Cell
          verticalAlign="middle"
          textAlign="center"
          style={{ padding: '1rem 0' }}
          onClick={
            showOptions
              ? () => showDetails(registro?.uuid)
              : () =>
                  showDetailsWithVerification(registro?.uuid, uuidVerification)
          }
        >
          <TooltipDesign title={registro?.title}>
            <Paragraph
              ellipsis={(true, { rows: 1 })}
              style={{ margin: '0 auto', width: '160px' }}
            >
              {registro?.title || '-'}
            </Paragraph>
          </TooltipDesign>
        </Table.Cell>
        <Table.Cell
          verticalAlign="middle"
          textAlign="center"
          style={{ padding: '1rem 0' }}
          onClick={
            showOptions
              ? () => showDetails(registro?.uuid)
              : () =>
                  showDetailsWithVerification(registro?.uuid, uuidVerification)
          }
        >
          <TooltipDesign title={registro?.message}>
            <Paragraph
              ellipsis={(true, { rows: 1 })}
              style={{ margin: '0 auto', width: '160px' }}
            >
              {registro?.message || '-'}
            </Paragraph>
          </TooltipDesign>
        </Table.Cell>
        {showOptions ? (
          <Table.Cell
            verticalAlign="middle"
            textAlign="center"
            style={{ padding: '1rem 0' }}
          >
            {registro?.status === true ? (
              <>
                <TooltipDesign title="Deshabilitar plantilla">
                  <Button
                    size="tiny"
                    circular
                    type="button"
                    style={{
                      border: 'none',
                      outline: 'none',
                    }}
                    className="download_contract"
                    icon={<VscTrash color="red" size={17} strokeWidth={0} />}
                    onClick={() =>
                      changeStatusTemplate(
                        registro?.uuid,
                        registro?.id,
                        registro?.status
                      )
                    }
                  />
                </TooltipDesign>
              </>
            ) : (
              <>
                <TooltipDesign title="Habilitar plantilla">
                  <Button
                    size="tiny"
                    circular
                    type="button"
                    style={{
                      border: 'none',
                      outline: 'none',
                    }}
                    className="download_contract"
                    icon={
                      <VscHistory color="green" size={17} strokeWidth={0} />
                    }
                    onClick={() =>
                      changeStatusTemplate(
                        registro?.uuid,
                        registro?.id,
                        registro?.status
                      )
                    }
                  />
                </TooltipDesign>
              </>
            )}
          </Table.Cell>
        ) : null}
      </Table.Row>
    )
  }

  const changeStatusTemplate = async (uuid, id, status) => {
    const token = await getToken({
      audience: 'veridocid',
    })

    const deleteUserTemplate = await desactiveTemplates(token, uuid, !status)

    if (deleteUserTemplate.error) {
      if (isArray(deleteUserTemplate?.error?.message)) {
        message.error(`${deleteUserTemplate.error?.message[0]}`)
      } else {
        message.error(`${deleteUserTemplate.error?.message}`)
      }
      return
    }

    if (deleteUserTemplate.status === 200 && deleteUserTemplate !== null) {
      if (status === true) {
        message.success(`${t('theTemplate')} ${id} ${t('deactivated')}`)
      } else {
        message.success(`${t('theTemplate')} ${id} ${t('activated')}`)
      }
      setDelayData(4000)
    } else {
      if (status === true) {
        message.error(`${t('errorDeactivated')} ${id}. ${t('please')}`)
      } else {
        message.error(`${t('errorActivated')} ${id}. ${t('please')}`)
      }
    }
  }

  const showDetails = (uuid) => {
    if (window.localStorage.getItem('uuidVerification')) {
      window.localStorage.removeItem('uuidVerification')
    }
    history.push(`/contratos/plantillas/firma-plantilla/${uuid}`)
  }

  const showDetailsWithVerification = (uuidTemplate, uuidVerificaction) => {
    window.localStorage.setItem('uuidVerification', uuidVerificaction)
    history.push(`/contratos/plantillas/firma-plantilla/${uuidTemplate}`)
  }

  return (
    <Table
      singleLine
      selectable
      compact
      style={{
        border: 'none',
        borderRadius: '0',
        color: '#303232',
        marginBottom: '1.5rem',
      }}
    >
      <Table.Header>
        <Table.Row verticalAlign="middle">
          <Table.HeaderCell
            width={2}
            textAlign="center"
            style={{
              backgroundColor: 'var(--primary-color)',
              color: 'white',
              borderRadius: '0',
            }}
          >
            {t('name')}
          </Table.HeaderCell>

          <Table.HeaderCell
            width={5}
            textAlign="center"
            style={{
              backgroundColor: 'var(--primary-color)',
              color: 'white',
              borderRadius: '0',
            }}
          >
            {t('templateID')}
          </Table.HeaderCell>

          <Table.HeaderCell
            width={3}
            textAlign="center"
            style={{
              backgroundColor: 'var(--primary-color)',
              color: 'white',
              borderRadius: '0',
            }}
          >
            {t('title')}
          </Table.HeaderCell>

          <Table.HeaderCell
            width={3}
            textAlign="center"
            style={{
              backgroundColor: 'var(--primary-color)',
              color: 'white',
              borderRadius: '0',
            }}
          >
            {t('message')}
          </Table.HeaderCell>

          {showOptions ? (
            <Table.HeaderCell
              width={2}
              textAlign="center"
              style={{
                backgroundColor: 'var(--primary-color)',
                color: 'white',
                borderRadius: '0',
              }}
            >
              {t('actions')}
            </Table.HeaderCell>
          ) : null}
        </Table.Row>
      </Table.Header>
      <Table.Body>{register.map(renderRegistros)}</Table.Body>
    </Table>
  )
}

export default TableDesktop
