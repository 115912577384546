import React from 'react'
import { useTranslation } from 'react-i18next'

// Components
import {
  Form as FormSendNip,
  Transition,
  Label,
  Button,
  Message,
} from 'semantic-ui-react'
import { Modal } from 'antd'
// Text mask
import MaskedInput from 'react-text-mask'
import emailMask from 'text-mask-addons/dist/emailMask'

export const SendMailNip = (props) => {
  const { t } = useTranslation()

  const {
    visibleModal,
    handleSendMail,
    handleCancelSendEmail,
    changeDataEmailNip,
    emailNip,
    descriptionSendEmail,
    // Error
    visibleMessageError,
    messageInputError,
    title_send_mail,
    classError,
    stateLoadingSubmit,
    // Api message errors
    changeStateVisibleMsgErrorApi,
    apiErrorMsg,
    headerErrorMsg,
    visibleMsgErrorApi,
  } = props

  return (
    <Modal
      centered={true}
      title={title_send_mail}
      open={visibleModal}
      closable={false}
      // style={{ top: '20%' }}
      footer={[
        <Button
          key="send_nip"
          onClick={handleSendMail}
          id="btn-actions_process-submit"
          style={{ marginRight: '10px' }}
          loading={stateLoadingSubmit}
        >
          {t('submitDataSDK')}
        </Button>,
        <Button
          key="cancel"
          onClick={handleCancelSendEmail}
          id="btn-actions_process-close"
        >
          {t('cancelDataSDK')}
        </Button>,
      ]}
    >
      <FormSendNip error={visibleMsgErrorApi}>
        <FormSendNip.Field>
          <p style={{ marginBottom: '0.2em' }}>{t('email')}</p>
          <span style={{ color: 'gray' }}>{`${descriptionSendEmail}`}</span>
          <div
            className={`ui fluid input ${classError}`}
            style={{ marginTop: '1rem' }}
          >
            <MaskedInput
              keepCharPositions={false}
              mask={emailMask}
              guide={true}
              placeholder="ejemplo@email.com"
              value={emailNip}
              onChange={changeDataEmailNip}
            />
          </div>
          {/* Errors */}
          <Transition
            animation="drop"
            duration={500}
            visible={visibleMessageError}
          >
            <Label pointing color="red" as="span">
              {`${messageInputError}`}
            </Label>
          </Transition>
          {/*Mensajes de error de la API  */}
          <Message
            error
            onDismiss={changeStateVisibleMsgErrorApi}
            header={headerErrorMsg}
            content={apiErrorMsg}
          />
        </FormSendNip.Field>
      </FormSendNip>
    </Modal>
  )
}
