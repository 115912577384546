import React, { Fragment, useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Components
import { Button } from 'semantic-ui-react'
import AdditionalVerifications from '../components/AdditionalVerifications'
import ModalEnrollment from '../components/ModalEnrollment'
import IdVerifications from '../components/IdVerifications'
import {
  Tooltip,
  Popover,
  Tabs,
  Modal as ResetServices,
  message as signMessage,
} from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import ContractsClientDataShow from '../components/ContractsView/ContractsClientDataShow'
import CreditClientDataShow from '../components/ViewDataClient/CreditClientDataShow'
import ComprobantesIngresosClientDataShow from '../components/ViewDataClient/ComprobantesIngresosClientDataShow'

// Utils
import useToken from '../utils/useToken'
import { find } from 'lodash'
import { message, Modal } from 'antd'
import jwt from 'jsonwebtoken'
import { getData } from '../utils/getDataCustomize'
import {
  getImagesIdPath,
  getImagesSecurityScannerPath,
} from '../utils/getImagesPath'
import setImages from '../utils/setImages'

// Fixtures, Shared, Assets, Styles
import '../styles/ClientAllDataShow.css'
import {
  responseVersion1,
  responseVersion2,
} from '../fixtures/dataResponse.fixture'
import { filterCheckPermissions } from '../shared/filters'
import leftArrow from '../assets/icons/arrow-left.svg'
import enrollIcon from '../assets/user-check.svg'
import ButtonPdfReport from '../components/UI/ButtonPdfReport/ButtonPdfReport'
import { AiOutlineMore } from 'react-icons/ai'

// Functions to reset services
import { resetId, resetService } from '../utils/resetServices'

const ClientAllDataShow = ({
  openInModal = false,
  jsonVerification,
  failedBiometricsModal,
}) => {
  const history = useHistory()
  const { uuid } = useParams()
  const { t } = useTranslation()
  const [data, setData] = useState(null)
  const [anverso, setAnverso] = useState(null)
  const [reverso, setReverso] = useState(null)
  const [facial, setFacial] = useState(null)
  const [portrait, setPortrait] = useState(null)
  const [front, setFront] = useState(null)
  const [frontIR, setFrontIR] = useState(null)
  const [frontUV, setFrontUV] = useState(null)
  const [back, setBack] = useState(null)
  const [backIR, setBackIR] = useState(null)
  const [backUV, setBackUV] = useState(null)
  const [id, setId] = useState(null)
  const [documentType, setDocumentType] = useState(null)

  const [curp, setCurp] = useState('')
  const [curpAntecedentesPenales, setCurpAntecedentesPenales] = useState('')
  const [cic, setCic] = useState('')
  const [ocr, setOcr] = useState('')
  const [voterKey, setVoterKey] = useState('')
  const [numEmision, setNumEmision] = useState('')
  const [ciudadano, setCiudadano] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [isNotProcess, setIsNotProcess] = useState(false)
  const [isHideNominalList, setIsHideNominalList] = useState(false)
  const [isMexican, setIsMexican] = useState(false)
  const [ip, setIp] = useState('')
  const [rfcUser, setRfc] = useState('')
  const [curpNss, setCurpNss] = useState('')
  //const [emailNss, setEmailNss] = useState('')
  const [curpImss, setCurpImss] = useState('')
  const [rfcList69, setRfcList69] = useState('')
  const [givenName, setGivenName] = useState('')
  const [surName, setSurName] = useState('')

  //Controlar el error del ingreso y captura del curp en los inputs de renapo, nss y antecedentes
  const [curpInputErrorNss, setCurpInputErrorNss] = useState({
    classInputError: '',
    visibleMessageError: false,
    messageError: '',
  })

  const [curpInputErrorRenapo, setCurpInputErrorRenapo] = useState({
    classInputError: '',
    visibleMessageError: false,
    messageError: '',
  })

  const [curpInputErrorAntecedentes, setCurpInputErrorAntecedentes] = useState({
    classInputError: '',
    visibleMessageError: false,
    messageError: '',
  })

  const [curpInputErrorImss, setCurpInputErrorImss] = useState({
    classInputError: '',
    visibleMessageError: false,
    messageError: '',
  })

  //Controlar el error del ingreso y captura de los nombres en antecedentes
  const [nameInputErrorAntecedentes, setNameInputErrorAntecedentesPenales] =
    useState({
      classInputError: '',
      visibleMessageError: false,
      messageError: '',
    })

  const [
    surnameInputErrorAntecedentes,
    setSurnameInputErrorAntecedentesPenales,
  ] = useState({
    classInputError: '',
    visibleMessageError: false,
    messageError: '',
  })

  const [isRenapoVerified, setIsRenapoVerified] = useState(false)
  const [isIneVerified, setIsIneVerified] = useState(false)
  const [isTruoraVerified, setIsTruoraVerified] = useState(false)
  const [isTruoraAllowed, setIsTruoraAllowed] = useState(false)
  const [isOcrAddressVerified, setIsOcrAddressVerified] = useState(false)
  const [isOcrAddressAllowed, setIsOcrAddressAllowed] = useState(false)
  const [isSecurityScannerVerified, setIsSecurityScannerVerified] =
    useState(false)
  const [isSecurityScannerAllowed, setIsSecurityScanneAllowed] = useState(false)
  const [isPhoneVerified, setIsPhoneVerified] = useState(false)
  const [isPhoneAllowed, setIsPhoneAllowed] = useState(false)
  const [isEmailVerified, setIsEmailVerified] = useState(false)
  const [isEmailAllowed, setIsEmailAllowed] = useState(false)
  const [isIpVerified, setIsIpVerified] = useState(false)
  const [isIpAllowed, setIsIpAllowed] = useState(false)
  const [isRfcVerified, setIsRfcVerified] = useState(false)
  const [isRfcAllowed, setIsRfcAllowed] = useState(false)
  const [isNssVerified, setIsNssVerified] = useState(false)
  const [isNssAllowed, setIsNssAllowed] = useState(false)
  const [isImssVerified, setIsImssVerified] = useState(false)
  const [isImssAllowed, setIsImssAllowed] = useState(false)
  const [isList69Verified, setIsList69Verified] = useState(false)
  const [isList69Allowed, setisList69Allowed] = useState(false)
  const [isCourtVerified, setIsCourtVerified] = useState(false)
  const [isCourtAllowed, setIsCourtAllowed] = useState(false)
  const [isRenapoAllowed, setIsRenapoAllowed] = useState(false)
  const [isIneAllowed, setIsIneAllowed] = useState(false)
  // Historial
  const [isCreditHistoryVerified, setIsCreditHistoryVerified] = useState(false)
  const [isClientNipVerified, setIsClientNipVerified] = useState(false)
  // Credit permission (dev)
  const [isCreditHistoryAllowed, setIsCreditHistoryAllowed] = useState(false)
  // Credit permission (prod)
  const [permissionCreditProd, setPermissionCreditProd] = useState(false)
  // Belvo
  const [isBelvoDataLinkVerified, setIsBelvoDataLinkVerified] = useState(false)
  const [isBelvoDataBankVerified, setIsBelvoDataBankVerified] = useState(false)
  const [isBelvoDataFiscalVerified, setIsBelvoDataFiscalVerified] =
    useState(false)
  const [isBelvoAllowed, setIsBelvoAllowed] = useState(false)
  // Reset services
  const [isResetAllowed, setIsResetAllowed] = useState(false)
  const [isIdResetAllowed, setIsIdResetAllowd] = useState(false)
  const [isSdkVerification, setIsSdkVerification] = useState(false)
  //-------------------- Contratos permission test
  const [isContractsAllowed, setIsContractsAllowed] = useState(false)

  const [openEnrollModal, setOpenEnrollModal] = useState(false)
  const [facialEnrollImg, setFacialEnrollImg] = useState(null)
  const [nameEnroll, setNameEnroll] = useState(null)
  const [surnameEnroll, setSurnameEnroll] = useState(null)
  const [loadingEnrollSubmit, setLoadingEnrollSubmit] = useState(false)
  const [imgToFileList, setImageToFileList] = useState([])
  const [failedBiometrics, setFailedBiometrics] = useState(false)
  const [decodedToken, setDecodedToken] = useState(null)
  const [decodedDashboardToken, setDecodedDashboardToken] = useState(null)
  const [isFaceEnrollPermissionActive, setIsFaceEnrollPermissionActive] =
    useState(false)
  const [isAlreadyEnrolled, setIsAlreadyEnrolled] = useState(false)
  const [isChangeStatusAllowed, SetIsChangeStatusAllowed] = useState(false)
  const getToken = useToken()

  //---------- Push to Contract
  const handleContract = () => {
    if (data)
      history.push(
        `/nuevo-contrato/${data?.idVerification?.id}/${data?.idVerification?.uuid}`
      )
  }

  //---------- Tab panels
  const { TabPane } = Tabs
  const [actionTab, setActionTab] = useState('1')
  const [visiblePopover, setVisiblePopover] = useState(false)

  /** */
  useEffect(() => {
    const enrollFilter = async () => {
      let facePermission = ''

      try {
        const token = await getToken({
          audience: 'veridocid',
        })

        const decoded = jwt.decode(token)

        setDecodedToken(decoded)

        facePermission =
          JSON.stringify(
            decoded?.permissions?.includes('face:identify'),
            null,
            2
          )?.replace('"', '') || 'FacePermission'

        // const response = await fetch(
        //   'https://veridocid.azure-api.net/api/face/externalIdentifier/' + uuid,
        //   {
        //     headers: {
        //       'Content-Type': 'application/json',
        //       Authorization: `Bearer ${token}`,
        //     },
        //   }
        // )

        // if (response.ok) {
        //   setIsAlreadyEnrolled(true)
        //   if (facePermission === 'true') {
        //     setIsFaceEnrollPermissionActive(true)
        //   }
        // } else {
        //   const error = await response.json()
        //   if (facePermission === 'true') {
        //     setIsFaceEnrollPermissionActive(true)
        //   }
        // }
      } catch (error) {
        if (!facePermission) {
          setIsFaceEnrollPermissionActive(true)
        }
      }
    }

    enrollFilter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid])
  /** */
  useEffect(() => {
    const getDataVerifications = async () => {
      if (uuid) {
        try {
          const token = await getToken({
            audience: 'veridocid-dashboard',
          })

          const decoded = jwt.decode(token)

          setDecodedDashboardToken(decoded)

          const json = await getData(token, uuid)
          setData(json)

          const isFailedBiometrics = json.idVerification?.data?.globalResult

          if (isFailedBiometrics !== 'Ok') {
            setFailedBiometrics(true)
          }
        } catch (error) {
          history.goBack()
          signMessage.warning(`No se encontró la verificación.`)
        }
      }
    }

    if (!openInModal) {
      getDataVerifications()
    } else {
      setData(jsonVerification)
      setFailedBiometrics(failedBiometricsModal)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid])

  useEffect(() => {
    if (!data) {
      return
    }

    let fields
    const dataVersion = data?.idVerification?.dataVersion ?? ''
    const documentTypeVerification = data?.idVerification?.data?.documentType
    const globalResult = data?.idVerification?.data?.globalResult
    const sourceType = data?.idVerification?.data?.sourceType
    let countryCode = data?.idVerification?.data?.countryCode

    if (Number(dataVersion) >= 2) {
      fields = responseVersion2
      setIsNotProcess(documentTypeVerification === 'NOT_IDENTIFIED')
      setIsHideNominalList(documentTypeVerification !== fields.votingCard)
    } else {
      fields = responseVersion1
      setIsNotProcess(globalResult === 'UnableToProcess')
      setIsHideNominalList(documentTypeVerification !== fields.votingCard)
    }

    if (countryCode !== fields.countryCodeMex) {
      countryCode = find(data?.idVerification?.data?.documentData, {
        type: 'Nationality Code',
        source: 'MRZ',
      })?.value
    }

    setIsMexican(countryCode === fields.countryCodeMex)

    setIsOcrAddressAllowed(
      filterCheckPermissions(decodedToken?.permissions, 'ocr')
    )

    setIsSecurityScanneAllowed(
      filterCheckPermissions(decodedToken?.permissions, 'id:securityscanner') &&
        sourceType !== 'Scanner'
    )

    setIsTruoraAllowed(
      filterCheckPermissions(decodedToken?.permissions, 'criminalrecord')
    )

    setIsEmailAllowed(
      filterCheckPermissions(decodedToken?.permissions, 'check:email')
    )

    setIsPhoneAllowed(
      filterCheckPermissions(decodedToken?.permissions, 'check:phone')
    )

    setIsIpAllowed(
      filterCheckPermissions(decodedToken?.permissions, 'check:ip')
    )

    setIsRfcAllowed(
      filterCheckPermissions(decodedToken?.permissions, 'rfc:validate')
    )

    setisList69Allowed(
      filterCheckPermissions(decodedToken?.permissions, 'check:list69')
    )

    setIsNssAllowed(filterCheckPermissions(decodedToken?.permissions, 'nss'))

    setIsImssAllowed(filterCheckPermissions(decodedToken?.permissions, 'imss'))

    // Credit - dev permission
    setIsCreditHistoryAllowed(
      filterCheckPermissions(decodedToken?.permissions, 'credit')
    )

    // Credit - prod permission
    setPermissionCreditProd(
      filterCheckPermissions(decodedToken?.permissions, 'credit:prod')
    )

    setIsBelvoAllowed(
      filterCheckPermissions(decodedToken?.permissions, 'ingresos')
    )

    // Reset services - permission
    setIsResetAllowed(
      filterCheckPermissions(decodedToken?.permissions, 'reset:services')
    )

    setIsIdResetAllowd(
      filterCheckPermissions(decodedToken?.permissions, 'id:reset')
    )

    setIsContractsAllowed(
      filterCheckPermissions(decodedToken?.permissions, 'sign:suma')
    )

    setIsCourtAllowed(
      filterCheckPermissions(decodedToken?.permissions, 'legalrecord')
    )

    setIsRenapoAllowed(
      filterCheckPermissions(decodedToken?.permissions, 'curp')
    )

    setIsIneAllowed(filterCheckPermissions(decodedToken?.permissions, 'ine'))

    SetIsChangeStatusAllowed(
      filterCheckPermissions(decodedDashboardToken?.permissions, 'id:update')
    )

    if (data.curpVerification) {
      setIsRenapoVerified(true)
    }

    // if (data.ineVerification) {
    if (data.ineVerification && data.ineVerification.length !== 0) {
      setIsIneVerified(true)
    }

    if (data.criminalRecord) {
      setIsTruoraVerified(true)
    }

    if (data.ocrAddress) {
      setIsOcrAddressVerified(true)
    }

    if (data.securityScannerVerification) {
      setIsSecurityScannerVerified(true)
    }

    if (data.emailVerification) {
      setIsEmailVerified(true)
    }

    if (data.phoneVerification) {
      setIsPhoneVerified(true)
    }

    if (data.ipVerification) {
      setIsIpVerified(true)
    }

    if (data.rfcValidation) {
      setIsRfcVerified(true)
    }

    if (data.nssGet) {
      setIsNssVerified(true)
    }

    if (data.historialLaboralImss) {
      setIsImssVerified(true)
    }

    if (data.historialCredito) {
      setIsCreditHistoryVerified(true)
    }

    if (data.userNip) {
      setIsClientNipVerified(true)
    }

    if (data.belvoLinkListFiscalData || data.belvoLinkListBankData) {
      setIsBelvoDataLinkVerified(true)
    }

    if (data.belvoBankData) {
      setIsBelvoDataBankVerified(true)
    }

    if (data.belvoFiscalData) {
      setIsBelvoDataFiscalVerified(true)
    }

    if (data.list69BY69) {
      setIsList69Verified(true)
    }

    if (data.courtRecord) {
      setIsCourtVerified(true)
    }

    setIsSdkVerification(data?.idVerification?.data?.verificationSdk)

    const { _anverso, _reverso, _facial, _portrait } = getImagesIdPath(
      dataVersion,
      data?.idVerification
    )

    const {
      _front,
      _frontInfrared,
      _frontUltraviolet,
      _back,
      _backInfrared,
      _backUltraviolet,
    } = getImagesSecurityScannerPath(data?.securityScannerVerification)

    setImages({
      dataVersion,
      getToken,
      anverso,
      _anverso,
      reverso,
      _reverso,
      facial,
      _facial,
      front,
      _front,
      frontIR,
      _frontInfrared,
      frontUV,
      _frontUltraviolet,
      back,
      _back,
      backIR,
      _backInfrared,
      backUV,
      _backUltraviolet,
      _portrait,
      portrait,
      setAnverso,
      setReverso,
      setFacial,
      setFacialEnrollImg,
      setFront,
      setFrontIR,
      setFrontUV,
      setBack,
      setBackIR,
      setBackUV,
      setPortrait,
    })

    const documentType = data?.idVerification?.data?.templateName ?? false
    setDocumentType(documentType)

    /** Send CURP value found to RENAPO card as props */
    const curpfound = find(data?.idVerification?.data?.documentData, {
      type: fields.personalNumber,
    })?.value

    // Enviar el nombre y el apellido a AntecedentesPenales
    const motherSurnameFound = find(data?.idVerification?.data?.documentData, {
      type: 'Mother Surname',
    })?.value

    const fatherSurnameFound = find(data?.idVerification?.data?.documentData, {
      type: 'Father Surname',
    })?.value

    const surnameFound = `${fatherSurnameFound} ${motherSurnameFound}`

    const nameFound = find(data?.idVerification?.data?.documentData, {
      type: 'Name',
    })?.value

    if (nameFound) setGivenName(nameFound)
    setSurName(surnameFound)

    //objeto con la opciones para mandar el mensaje de error al tener un valor vacío en el input curp
    const emptyDataCurpInput = {
      classInputError: 'error',
      visibleMessageError: true,
      messageError: `${t('msgEmptyInputCurp')}`,
    }

    //Si el CURP que se envia es menor a 18 o es inválido no se introduce en el input
    if (curpfound?.length < 18 || curpfound === undefined) {
      setCurpInputErrorRenapo(emptyDataCurpInput)
      setCurpInputErrorAntecedentes(emptyDataCurpInput)
      setCurpInputErrorNss(emptyDataCurpInput)
      setCurpInputErrorImss(emptyDataCurpInput)
    } else {
      setCurpInputErrorRenapo(validDataCurpInput)
      setCurpInputErrorAntecedentes(validDataCurpInput)
      setCurpInputErrorNss(validDataCurpInput)
      setCurpInputErrorImss(validDataCurpInput)
      //Curps
      setCurp(curpfound)
      setCurpAntecedentesPenales(curpfound)
      setCurpNss(curpfound)
      setCurpImss(curpfound)
    }

    const idfound = data?.idVerification?.id
    setId(idfound)

    const cicfound = find(data?.idVerification?.data?.documentData, {
      type: fields.documentNumber,
    })?.value
    setCic(cicfound)

    const ocrfound =
      Number(dataVersion) >= 2
        ? find(data?.idVerification?.data?.documentData, {
            type: fields.ocrNumber,
          })?.value
        : find(
            find(data?.idVerification?.data?.documentVerifications, {
              name: 'Número Personal',
            })?.inputFields,
            (idCiudadano) => idCiudadano?.value?.includes('Número Personal')
          )?.value?.substr(-13) ?? ''
    setOcr(ocrfound)

    const voterKeyfound = find(data?.idVerification?.data?.documentData, {
      type: fields.voterKey,
    })?.value
    setVoterKey(voterKeyfound)

    const numEmisionfound = find(data?.idVerification?.data?.documentData, {
      type: fields.numberOfDuplicates,
    })?.value
    setNumEmision(numEmisionfound)

    const ciudadanofound =
      find(
        find(data?.idVerification?.data?.documentVerifications, {
          name: 'Número Personal',
        })?.inputFields,
        (idCiudadano) => idCiudadano?.value?.includes('Número Personal')
      )?.value?.substr(-13) ?? ''
    setCiudadano(ciudadanofound)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  /** */
  //Objeto con las opciones para mandar el mensaje de error al tener un curp inválido
  const invalidDataCurpInput = {
    classInputError: 'error',
    visibleMessageError: true,
    messageError: `${t('msgInvalidCurp')}`,
  }

  //Objeto con las opciones para ocultar el mensaje de erro
  const validDataCurpInput = {
    classInputError: '',
    visibleMessageError: false,
    messageError: `${t('msgInvalidCurp')}`,
  }

  //Objeto con las opciones para mandar el mensaje de error al tener un nombre inválido
  const invalidDataNameInput = {
    classInputError: 'error',
    visibleMessageError: true,
    messageError: `${t('msgInvalidName')}`,
  }

  const invalidDataSurnameInput = {
    classInputError: 'error',
    visibleMessageError: true,
    messageError: `${t('msgInvalidSurname')}`,
  }

  //Objeto con las opciones para ocultar el mensaje de error de nombre
  const validDataNameInput = {
    classInputError: '',
    visibleMessageError: false,
    messageError: ``,
  }

  const validDataSurnameInput = {
    classInputError: '',
    visibleMessageError: false,
    messageError: ``,
  }

  const handleChangeInputCurpRenapo = (event) => {
    const curp = event?.target?.value
    if (curp?.length < 18) {
      setCurpInputErrorRenapo(invalidDataCurpInput)
      if (curp?.length === 0) {
        setCurpInputErrorRenapo(validDataCurpInput)
      }
    } else {
      setCurpInputErrorRenapo(validDataCurpInput)
    }

    setCurp(curp?.toUpperCase())
  }

  const handleChangeInputCurpAntecedentesPenales = (event) => {
    const curpAntecedentes = event?.target?.value
    if (curpAntecedentes?.length < 18) {
      setCurpInputErrorAntecedentes(invalidDataCurpInput)
      if (curpAntecedentes?.length === 0) {
        setCurpInputErrorAntecedentes(validDataCurpInput)
      }
    } else {
      setCurpInputErrorAntecedentes(validDataCurpInput)
    }

    setCurpAntecedentesPenales(curpAntecedentes?.toUpperCase())
  }

  const handleChangeInputCic = (event) => {
    setCic(event.target.value)
  }

  const handleChangeInputOcr = (event) => {
    setOcr(event.target.value)
  }

  const handleChangeInputVoterKey = (event) => {
    setVoterKey(event.target.value)
  }

  const handleChangeInputNumEmision = (event) => {
    setNumEmision(event.target.value)
  }

  const handleChangeInputCiudadano = (event) => {
    setCiudadano(event.target.value)
  }

  const handleChangeInputEmail = (event) => {
    const emailInput = event?.target?.value
    setEmail(emailInput)
  }

  const handleChangeInputPhone = (event) => {
    const phoneInput = event?.target?.value
    setPhone(phoneInput?.replace(/[A-Za-z.*+?^${}()|[\]\\]/g, ''))
  }

  const handleChangeInputIp = (event) => {
    const ipInput = event?.target?.value
    setIp(ipInput)
  }

  const handleChangeInputRFC = (event) => {
    const rfcInput = event?.target?.value
    setRfc(rfcInput.toUpperCase())
  }

  const handleChangeInputRFCList69 = (event) => {
    const rfcInput = event?.target?.value
    setRfcList69(rfcInput.toUpperCase())
  }

  const handleChangeInputCurpNss = (event) => {
    const curpNss = event?.target?.value
    if (curpNss?.length < 18) {
      setCurpInputErrorNss(invalidDataCurpInput)
      if (curpNss?.length === 0) {
        setCurpInputErrorNss(validDataCurpInput)
      }
    } else {
      setCurpInputErrorNss(validDataCurpInput)
    }

    setCurpNss(curpNss?.toUpperCase())
  }

  /* const handleChangeInputNssforEmail = (event) => {
    const emailNSSInput = event?.target?.value
    setEmailNss(emailNSSInput)
  } */

  const handleChangeInputCurpImss = (event) => {
    const curpImss = event?.target?.value
    if (curpImss?.length < 18) {
      setCurpInputErrorImss(invalidDataCurpInput)
      if (curpImss?.length === 0) {
        setCurpInputErrorImss(validDataCurpInput)
      }
    } else {
      setCurpInputErrorImss(validDataCurpInput)
    }

    setCurpImss(curpImss?.toUpperCase())
  }

  const handleChangeInputNameAntecedentesPenales = (event) => {
    const nameAntecedentes = event?.target?.value
    const regex = /^([a-zA-ZùÙüÜäàáëèéïìíöòóüùúÄÀÁËÈÉÏÌÍÖÒÓÜÚñÑ\s]+)$/gim
    if (regex.test(nameAntecedentes)) {
      setNameInputErrorAntecedentesPenales(validDataNameInput)
    } else {
      setNameInputErrorAntecedentesPenales(invalidDataNameInput)
    }

    setGivenName(nameAntecedentes.toUpperCase())
  }

  const handleChangeInputSurnameAntecedentesPenales = (event) => {
    const surnameAntecedentes = event?.target?.value
    const regex = /^([a-zA-ZùÙüÜäàáëèéïìíöòóüùúÄÀÁËÈÉÏÌÍÖÒÓÜÚñÑ\s]+)$/gim

    if (regex.test(surnameAntecedentes)) {
      setSurnameInputErrorAntecedentesPenales(validDataSurnameInput)
    } else {
      setSurnameInputErrorAntecedentesPenales(invalidDataSurnameInput)
    }

    setSurName(surnameAntecedentes.toUpperCase())
  }

  //onVerify functions
  const onVerifyRenapo = (json) => {
    if (data) {
      setData((prevData) => ({ ...prevData, curpVerification: json }))
    } else {
      setData({ curpVerification: json })
    }
  }

  const onVerifyListaNominal = (json) => {
    if (data) {
      setData((prevData) => ({ ...prevData, ineVerification: json }))
    } else {
      setData({ ineVerification: json })
    }
  }

  const onVerifyCriminalRecord = (json) => {
    if (data) {
      setData((prevData) => ({ ...prevData, criminalRecord: json }))
    } else {
      setData({ criminalRecord: json })
    }
  }

  const onVerifyOcrAddress = (json) => {
    if (data) {
      setData((prevData) => ({ ...prevData, ocrAddress: json }))
    } else {
      setData({ ocrAddress: json })
    }
  }

  const onVerifyEmail = (json) => {
    if (data) {
      setData((prevData) => ({ ...prevData, emailVerification: json }))
    } else {
      setData({ emailVerification: json })
    }
  }

  const onVerifyPhone = (json) => {
    if (data) {
      setData((prevData) => ({ ...prevData, phoneVerification: json }))
    } else {
      setData({ phoneVerification: json })
    }
  }

  const onVerifyIp = (json) => {
    if (data) {
      setData((prevData) => ({ ...prevData, ipVerification: json }))
    } else {
      setData({ ipVerification: json })
    }
  }

  const onVerifyRfc = (json) => {
    if (data) {
      setData((prevData) => ({ ...prevData, rfcVerification: json }))
    } else {
      setData({ rfcVerification: json })
    }
  }

  const onVerifyNss = (json) => {
    if (data) {
      setData((prevData) => ({ ...prevData, nssVerification: json }))
    } else {
      setData({ nssVerification: json })
    }
  }

  const onVerifyImss = (json) => {
    if (data) {
      setData((prevData) => ({ ...prevData, imssVerification: json }))
    } else {
      setData({ imssVerification: json })
    }
  }

  const onVerifyList69 = (json) => {
    if (data) {
      setData((prevData) => ({ ...prevData, list69: json }))
    } else {
      setData({ list69: json })
    }
  }

  const onVerifyCourtRecord = (json) => {
    if (data) {
      setData((prevData) => ({ ...prevData, courtRecords: json }))
    } else {
      setData({ courtRecords: json })
    }
  }
  /** */

  const handleclickEnrollment = () => {
    setOpenEnrollModal(true)
    const _facial = data?.idVerification?.files?.faceImage
    if (_facial && !failedBiometrics) {
      setImageToFileList([
        {
          uid: '-1',
          //preview: _facial,
          //b64: facialEnrollImg,
          //url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
          url: `data:image/png;base64,${facialEnrollImg}`,
        },
      ])
    }
  }

  /** */
  const onUpload = async ({ id, file }) => {
    const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    }

    let base64 = null

    if (file) {
      base64 = await getBase64(file)
      base64 = base64.split('base64,')[1]
    }

    switch (id) {
      case 'facial_enroll':
        setFacialEnrollImg(base64)
        break
      default:
        break
    }
  }
  /** */
  const handleSubmitEnroll = async () => {
    if (!facialEnrollImg) {
      message.error('La imagen es requerida')
      return
    }
    if (!nameEnroll) {
      message.error('Es necesario escribir un nombre')
      return
    }

    if (!surnameEnroll) {
      message.error('Es necesario escribir un apellido')
      return
    }

    try {
      setLoadingEnrollSubmit(true)

      const token = await getToken({
        audience: 'veridocid',
      })

      const response = await fetch('https://veridocid.azure-api.net/api/face', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          faceImageBase64: facialEnrollImg,
          name: nameEnroll,
          surname1: surnameEnroll,
          externalIdentifier: uuid,
          enabled: true,
        }),
      })

      if (response.ok) {
        setLoadingEnrollSubmit(false)
        await response.json()
        setOpenEnrollModal(false)
        setIsAlreadyEnrolled(true)
      } else {
        setLoadingEnrollSubmit(false)
      }
    } catch (error) {
      setLoadingEnrollSubmit(false)
    }
  }
  /** */
  const handleChangeNameEnroll = (event) => {
    setNameEnroll(event.target.value)
  }

  const handleChangeSurnameEnroll = (event) => {
    setSurnameEnroll(event.target.value)
  }

  const onBackHandler = () => {
    history.push('/')
  }
  /** */

  // --------------------------- Reset services
  const { confirm } = ResetServices
  var titleConfirm = '¿Estás seguro que quieres eliminar los datos?'

  /* IP */
  const [messageIp, setMessageIP] = useState({ visible: false, msg: '' })

  const handleResetIp = async () => {
    const token = await getToken({ audience: 'veridocid' })

    confirm({
      title: titleConfirm,
      icon: <ExclamationCircleOutlined />,
      okText: 'Sí',
      cancelText: 'No',
      async onOk() {
        const { response, status } = await resetService({
          uuid,
          token,
          type: 'ip',
        })
        if (status === 200) {
          setMessageIP({ visible: true, msg: response.message })
          setIsIpVerified(false)
          setIp('')
        } else {
          setMessageIP({ visible: true, msg: response.message })
        }

        setTimeout(() => {
          setMessageIP({ visible: false, msg: response.message })
        }, 2500)
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  /* Email */
  const [messageEmail, setMessageEmail] = useState({ visible: false, msg: '' })

  const handleResetEmail = async () => {
    const token = await getToken({ audience: 'veridocid' })

    confirm({
      title: titleConfirm,
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      async onOk() {
        const { response, status } = await resetService({
          uuid,
          token,
          type: 'email',
        })
        if (status === 200) {
          setMessageEmail({ visible: true, msg: response.message })
          setIsEmailVerified(false)
          setEmail('')
        } else {
          setMessageEmail({ visible: true, msg: response.message })
        }

        setTimeout(() => {
          setMessageEmail({ visible: false, msg: response.message })
        }, 2500)
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  /* Phone */
  const [messagePhone, setMessagePhone] = useState({ visible: false, msg: '' })

  const handleResetPhone = async () => {
    const token = await getToken({ audience: 'veridocid' })

    confirm({
      title: titleConfirm,
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      async onOk() {
        const { response, status } = await resetService({
          uuid,
          token,
          type: 'phone',
        })
        if (status === 200) {
          setMessagePhone({ visible: true, msg: response.message })
          setIsPhoneVerified(false)
          setPhone('')
        } else {
          setMessagePhone({ visible: true, msg: response.message })
        }

        setTimeout(() => {
          setMessagePhone({ visible: false, msg: response.message })
        }, 2500)
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  /* OCR Address */
  const [messageOcrAddress, setMessageOcrAddress] = useState({
    visible: false,
    msg: '',
  })

  const handleResetOcrAddress = async () => {
    const token = await getToken({ audience: 'veridocid' })

    confirm({
      title: titleConfirm,
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      async onOk() {
        const { response, status } = await resetService({
          uuid,
          token,
          type: 'ocr',
        })
        if (status === 200) {
          setMessageOcrAddress({ visible: true, msg: response.message })
          setIsOcrAddressVerified(false)
        } else {
          setMessageOcrAddress({ visible: true, msg: response.message })
        }

        setTimeout(() => {
          setMessageOcrAddress({ visible: false, msg: response.message })
        }, 2500)
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  /* RENAPO */
  const [messageRenapo, setMessageRenapo] = useState({
    visible: false,
    msg: '',
  })

  const handleResetRenapo = async () => {
    const token = await getToken({ audience: 'veridocid' })

    confirm({
      title: titleConfirm,
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      async onOk() {
        const { response, status } = await resetService({
          uuid,
          token,
          type: 'renapo',
        })
        if (status === 200) {
          setMessageRenapo({ visible: true, msg: response.message })
          setIsRenapoVerified(false)
        } else {
          setMessageRenapo({ visible: true, msg: response.message })
        }

        setTimeout(() => {
          setMessageRenapo({ visible: false, msg: response.message })
        }, 2500)
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  /* INE */
  const [messageIne, setMessageIne] = useState({
    visible: false,
    msg: '',
  })

  const handleResetIne = async () => {
    const token = await getToken({ audience: 'veridocid' })

    confirm({
      title: titleConfirm,
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      async onOk() {
        const { response, status } = await resetService({
          uuid,
          token,
          type: 'ine',
        })
        if (status === 200) {
          setMessageIne({ visible: true, msg: response.message })
          setIsIneVerified(false)
        } else {
          setMessageIne({ visible: true, msg: response.message })
        }

        setTimeout(() => {
          setMessageIne({ visible: false, msg: response.message })
        }, 2500)
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  /* RFC */
  const [messageRfc, setMessageRfc] = useState({
    visible: false,
    msg: '',
  })

  const handleResetRfc = async () => {
    const token = await getToken({ audience: 'veridocid' })

    confirm({
      title: titleConfirm,
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      async onOk() {
        const { response, status } = await resetService({
          uuid,
          token,
          type: 'rfc',
        })
        if (status === 200) {
          setMessageRfc({ visible: true, msg: response.message })
          setIsRfcVerified(false)
        } else {
          setMessageRfc({ visible: true, msg: response.message })
        }

        setTimeout(() => {
          setMessageRfc({ visible: false, msg: response.message })
        }, 2500)
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  /* IMSS & NSS */
  const [messageHistorialImss, setMessageHistorialImss] = useState({
    visible: false,
    msg: '',
  })

  const handleResetHistorialImss = async () => {
    const token = await getToken({ audience: 'veridocid' })

    confirm({
      title: titleConfirm,
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      async onOk() {
        const { status: responseNss } = await resetService({
          uuid,
          token,
          type: 'nss',
        })
        if (responseNss === 200) {
          const { response: responseImss, status } = await resetService({
            uuid,
            token,
            type: 'imss',
          })
          if (status === 200) {
            setMessageHistorialImss({
              visible: true,
              msg: responseImss.message,
            })
            setIsNssVerified(false)
            setIsImssVerified(false)
          } else {
            setMessageHistorialImss({
              visible: true,
              msg: responseImss.message,
            })
          }
        } else {
          setMessageHistorialImss({ visible: true, msg: responseNss.message })
        }

        setTimeout(() => {
          setMessageHistorialImss({ visible: false, msg: responseNss.message })
        }, 2500)
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  /* List69 */
  const [messageList69, setMessageList69] = useState({
    visible: false,
    msg: '',
  })

  const handleResetList69 = async () => {
    const token = await getToken({ audience: 'veridocid' })

    confirm({
      title: titleConfirm,
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      async onOk() {
        const { response, status } = await resetService({
          uuid,
          token,
          type: 'list69',
        })
        if (status === 200) {
          setMessageList69({ visible: true, msg: response.message })
          setIsList69Verified(false)
        } else {
          setMessageList69({ visible: true, msg: response.message })
        }

        setTimeout(() => {
          setMessageList69({ visible: false, msg: response.message })
        }, 2500)
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  /* Legal Record */
  const [messageLegalRecord, setMessageLegalRecord] = useState({
    visible: false,
    msg: '',
  })

  const handleResetLegalRecord = async () => {
    const token = await getToken({
      audience: 'veridocid',
    })

    confirm({
      title: titleConfirm,
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      async onOk() {
        const { response, status } = await resetService({
          uuid,
          token,
          type: 'court',
        })
        if (status === 200) {
          setMessageLegalRecord({ visible: true, msg: response.message })
          setIsCourtVerified(false)
        } else {
          setMessageLegalRecord({ visible: true, msg: response.message })
        }

        setTimeout(() => {
          setMessageLegalRecord({ visible: false, msg: response.message })
        }, 2500)
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  const onResetHandler = async () => {
    const token = await getToken({
      audience: 'veridocid',
    })

    confirm({
      title: '¿Estás seguro?',
      icon: <ExclamationCircleOutlined />,
      content: 'Esta acción es irreversible.',
      cancelText: 'No',
      okText: 'Sí',
      async onOk() {
        try {
          const response = await resetId(uuid, token)
          if (response.ok) {
            Modal.success({
              content: 'Su verificación ha sido reiniciada.',
              onOk() {
                history.push('/')
              },
            })
          } else {
            Modal.error({
              content: '¡Oh, no! Algo salió mal',
            })
          }
        } catch (error) {
          Modal.error({
            content: '¡Oh, no! Algo salió mal',
          })
        }
      },
      onCancel() {},
    })
  }

  return (
    <Fragment>
      <div className="TitleClientAllDataShow">
        <div>
          <h2
            className="ui header"
            style={{
              color: 'rgb(68, 68, 68)',
              fontSize: 'clamp(1.5em, 1.7em, 1.9em)',
              fontWeight: '350',
            }}
          >
            <strong>{t('verificationSummary')}</strong>
          </h2>
          <h2
            className="ui header"
            style={{
              color: '#2d2f6d',
              fontSize: 'clamp(0.9em, 1.16em, 1.2em)',
            }}
          >
            {t('clientId')}:{' '}
            <span
              style={{
                backgroundColor: '#cfcfcf',
                padding: '0.3rem 0.5rem',
                borderRadius: '5px',
              }}
            >
              {data?.idVerification?.id}
            </span>
          </h2>
        </div>
        {openInModal ? null : (
          <div className="ButtonsClientAllDataShow">
            {/* Return */}
            <button className="return_button_data" onClick={onBackHandler}>
              <img
                src={leftArrow}
                alt="left"
                className="LeftArrowClientAllDataShow"
              />
              {t('goBack')}
            </button>
            <div className="content_actions_buttons">
              {/* PDF Report */}
              <ButtonPdfReport uuid={uuid} />
              {/* Permiso para contratos */}
              {isContractsAllowed ? (
                <button
                  onClick={handleContract}
                  className="btns_actions_not_background"
                  style={{
                    marginRight: '10px',
                    marginLeft: '10px',
                    display: 'block',
                  }}
                >
                  {t('send_contract')}
                </button>
              ) : null}
              {/* Reset Id Verification */}
              {isIdResetAllowed && isSdkVerification ? (
                <button
                  onClick={onResetHandler}
                  className="btns_actions_not_background"
                  style={{
                    marginRight: '10px',
                    marginLeft: '10px',
                    display: 'block',
                  }}
                >
                  <p>{t('reset_id')}</p>
                </button>
              ) : null}
              {/* More options */}
              <Tooltip title={t('more_options')}>
                <Popover
                  content={
                    <>
                      <div
                        className="options_to_tab"
                        onClick={() => {
                          setVisiblePopover(false)
                          setActionTab('1')
                        }}
                      >
                        <p>{t('tab_verification')}</p>
                      </div>
                      {/* Contratos */}
                      {isContractsAllowed ? (
                        <div
                          className="options_to_tab"
                          onClick={() => {
                            setVisiblePopover(false)
                            setActionTab('2')
                          }}
                        >
                          <p>{t('tab_contract')}</p>
                        </div>
                      ) : null}
                      {/* Historial de crédto */}
                      {isCreditHistoryAllowed || permissionCreditProd ? (
                        <div
                          className="options_to_tab"
                          onClick={() => {
                            setVisiblePopover(false)
                            setActionTab('3')
                          }}
                        >
                          <p>{t('credit_history')}</p>
                        </div>
                      ) : null}

                      {/* Belvo */}
                      {isBelvoAllowed ? (
                        <div
                          className="options_to_tab"
                          onClick={() => {
                            setVisiblePopover(false)
                            setActionTab('4')
                          }}
                        >
                          <p>{t('payment_vouchers')}</p>
                        </div>
                      ) : null}
                    </>
                  }
                  title={t('title_popover')}
                  trigger="click"
                  placement="bottomRight"
                  visible={visiblePopover}
                  onVisibleChange={(visible) => setVisiblePopover(visible)}
                >
                  {/* More options button */}
                  <button className="more_options_view">
                    <AiOutlineMore strokeWidth={1.2} size={28} />
                  </button>
                </Popover>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
      {isFaceEnrollPermissionActive &&
      !isAlreadyEnrolled &&
      !failedBiometrics ? (
        <Button
          onClick={handleclickEnrollment}
          floated="right"
          style={{
            background: '#2F363C',
            color: '#fff',
          }}
        >
          <img
            src={enrollIcon}
            alt="enrollIcon"
            style={{ width: '16px', height: '16px', marginRight: '3px' }}
          />{' '}
          Enrolar
        </Button>
      ) : null}

      <ModalEnrollment
        setOpenEnrollModal={setOpenEnrollModal}
        openEnrollModal={openEnrollModal}
        onUpload={onUpload}
        imgToFileList={imgToFileList}
        handleChangeNameEnroll={handleChangeNameEnroll}
        handleChangeSurnameEnroll={handleChangeSurnameEnroll}
        handleSubmitEnroll={handleSubmitEnroll}
        loadingEnrollSubmit={loadingEnrollSubmit}
      />

      {/* ------------------- Select options data verification and contracts -------------------*/}
      <Tabs activeKey={actionTab}>
        {/* Id Verifications */}
        <TabPane tab="" key="1">
          <IdVerifications
            anverso={anverso}
            reverso={reverso}
            front={front}
            back={back}
            frontIR={frontIR}
            backIR={backIR}
            frontUV={frontUV}
            backUV={backUV}
            documentType={documentType}
            idVerification={data?.idVerification}
            securityScannerVerification={data?.securityScannerVerification}
            isSecurityScannerAllowed={isSecurityScannerAllowed}
            isSecurityScannerVerified={isSecurityScannerVerified}
            setIsSecurityScannerVerified={setIsSecurityScannerVerified}
            uuid={uuid}
            id={id}
            facial={facial}
            portrait={portrait}
            openInModal={openInModal}
            isChangeStatusAllowed={isChangeStatusAllowed}
          />
          {!openInModal ? (
            <AdditionalVerifications
              idVerification={data?.idVerification}
              isNotProcess={isNotProcess}
              isMexican={isMexican}
              isHideNominalList={isHideNominalList}
              isIneAllowed={isIneAllowed}
              isRenapoAllowed={isRenapoAllowed}
              isOcrAddressAllowed={isOcrAddressAllowed}
              isTruoraAllowed={isTruoraAllowed}
              isEmailAllowed={isEmailAllowed}
              isPhoneAllowed={isPhoneAllowed}
              isIpAllowed={isIpAllowed}
              isRfcAllowed={isRfcAllowed}
              isList69Allowed={isList69Allowed}
              isNssAllowed={isNssAllowed}
              isImssAllowed={isImssAllowed}
              isCourtAllowed={isCourtAllowed}
              data={data}
              curp={curp}
              id={id}
              uuid={uuid}
              voterKey={voterKey}
              numEmision={numEmision}
              ocr={ocr}
              cic={cic}
              ciudadano={ciudadano}
              curpAntecedentesPenales={curpAntecedentesPenales}
              email={email}
              phone={phone}
              ip={ip}
              rfcUser={rfcUser}
              curpNSS={curpNss}
              curpIMSS={curpImss}
              rfcList69={rfcList69}
              givenName={givenName}
              surName={surName}
              //emailNSS={emailNss}
              onVerifyCriminalRecord={onVerifyCriminalRecord}
              onVerifyOcrAddress={onVerifyOcrAddress}
              onVerifyListaNominal={onVerifyListaNominal}
              onVerifyRenapo={onVerifyRenapo}
              onVerifyEmail={onVerifyEmail}
              onVerifyPhone={onVerifyPhone}
              onVerifyIp={onVerifyIp}
              onVerifyRfc={onVerifyRfc}
              onVerifyNss={onVerifyNss}
              onVerifyImss={onVerifyImss}
              onVerifyList69={onVerifyList69}
              onVerifyCourtRecord={onVerifyCourtRecord}
              handleChangeInputCic={handleChangeInputCic}
              handleChangeInputOcr={handleChangeInputOcr}
              handleChangeInputVoterKey={handleChangeInputVoterKey}
              handleChangeInputNumEmision={handleChangeInputNumEmision}
              handleChangeInputCiudadano={handleChangeInputCiudadano}
              handleChangeInputCurpAntecedentesPenales={
                handleChangeInputCurpAntecedentesPenales
              }
              handleChangeInputCurp={handleChangeInputCurpRenapo}
              handleChangeInputEmail={handleChangeInputEmail}
              handleChangeInputPhone={handleChangeInputPhone}
              handleChangeInputIp={handleChangeInputIp}
              handleChangeInputRFC={handleChangeInputRFC}
              handleChangeInputNssforCurp={handleChangeInputCurpNss}
              handleChangeInputImssforCurp={handleChangeInputCurpImss}
              handleChangeInputRFCList69={handleChangeInputRFCList69}
              handleChangeInputNameAntecedentesPenales={
                handleChangeInputNameAntecedentesPenales
              }
              handleChangeInputSurnameAntecedentesPenales={
                handleChangeInputSurnameAntecedentesPenales
              }
              //handleChangeInputNssforEmail={handleChangeInputNssforEmail}
              isRenapoVerified={isRenapoVerified}
              setIsRenapoVerified={setIsRenapoVerified}
              isIneVerified={isIneVerified}
              setIsIneVerified={setIsIneVerified}
              isOcrAddressVerified={isOcrAddressVerified}
              setIsOcrAddressVerified={setIsOcrAddressVerified}
              isTruoraVerified={isTruoraVerified}
              setIsTruoraVerified={setIsTruoraVerified}
              isEmailVerified={isEmailVerified}
              setIsEmailVerified={setIsEmailVerified}
              isPhoneVerified={isPhoneVerified}
              setIsPhoneVerified={setIsPhoneVerified}
              isIpVerified={isIpVerified}
              setIsIpVerified={setIsIpVerified}
              isRfcVerified={isRfcVerified}
              setIsRfcVerified={setIsRfcVerified}
              isNssVerified={isNssVerified}
              setIsNssVerified={setIsNssVerified}
              isImssVerified={isImssVerified}
              setIsImssVerified={setIsImssVerified}
              setIsList69Verified={setIsList69Verified}
              isList69Verified={isList69Verified}
              isCourtVerified={isCourtVerified}
              setIsCourtVerified={setIsCourtVerified}
              facial={facial}
              portrait={portrait}
              //Controlar el ingreso de datos del curp - renapo, antecedentes y nss
              classErrorCurpInputRenapo={curpInputErrorRenapo.classInputError}
              visibleErrorCurpInputRenapo={
                curpInputErrorRenapo.visibleMessageError
              }
              messageErrorCurpInputRenapo={curpInputErrorRenapo.messageError}
              //
              classErrorCurpInputAntecedentes={
                curpInputErrorAntecedentes.classInputError
              }
              visibleErrorCurpInputAntecedentes={
                curpInputErrorAntecedentes.visibleMessageError
              }
              messageErrorCurpInputAntecedentes={
                curpInputErrorAntecedentes.messageError
              }
              //
              classErrorCurpInputNss={curpInputErrorNss.classInputError}
              visibleErrorCurpInputNss={curpInputErrorNss.visibleMessageError}
              messageErrorCurpInputNss={curpInputErrorNss.messageError}
              //
              classErrorCurpInputImss={curpInputErrorImss.classInputError}
              visibleErrorCurpInputImss={curpInputErrorImss.visibleMessageError}
              messageErrorCurpInputImss={curpInputErrorImss.messageError}
              //
              classErrorNameInputAntecedentes={
                nameInputErrorAntecedentes.classInputError
              }
              visibleErrorNameInputAntecedentes={
                nameInputErrorAntecedentes.visibleMessageError
              }
              messageErrorNameInputAntecedentes={
                nameInputErrorAntecedentes.messageError
              }
              //
              classErrorSurnameInputAntecedentes={
                surnameInputErrorAntecedentes.classInputError
              }
              visibleErrorSurnameInputAntecedentes={
                surnameInputErrorAntecedentes.visibleMessageError
              }
              messageErrorSurnameInputAntecedentes={
                surnameInputErrorAntecedentes.messageError
              }
              // Reset services
              isResetAllowed={isResetAllowed}
              handleResetIp={handleResetIp}
              messageIp={messageIp}
              handleResetEmail={handleResetEmail}
              messageEmail={messageEmail}
              handleResetPhone={handleResetPhone}
              messagePhone={messagePhone}
              handleResetOcrAddress={handleResetOcrAddress}
              messageOcrAddress={messageOcrAddress}
              handleResetRenapo={handleResetRenapo}
              messageRenapo={messageRenapo}
              handleResetIne={handleResetIne}
              messageIne={messageIne}
              handleResetRfc={handleResetRfc}
              messageRfc={messageRfc}
              handleResetList69={handleResetList69}
              messageList69={messageList69}
              handleResetHistorialImss={handleResetHistorialImss}
              messageHistorialImss={messageHistorialImss}
              handleResetLegalRecord={handleResetLegalRecord}
              messageLegalRecord={messageLegalRecord}
            />
          ) : null}
        </TabPane>
        {!openInModal ? (
          <>
            {/* Contracts */}
            <TabPane tab="" key="2">
              <ContractsClientDataShow id={id} />
            </TabPane>
            {/* Círculo de crédito */}
            {isCreditHistoryAllowed || permissionCreditProd ? (
              <TabPane tab="" key="3">
                <CreditClientDataShow
                  id={id}
                  uuid={uuid}
                  dataToSearchCredit={data?.idVerification}
                  statusClietnNip={isClientNipVerified}
                  statusCredit={isCreditHistoryVerified}
                  dataCreditFound={
                    isCreditHistoryVerified ? data?.historialCredito : false
                  }
                  setIsCreditHistoryVerified={setIsCreditHistoryVerified}
                  dataNipUser={data?.userNip ? data?.userNip : null}
                  ambitDev={isCreditHistoryAllowed}
                  ambitProd={permissionCreditProd}
                  creditConfig={data?.creditConfig ? data?.creditConfig : null}
                />
              </TabPane>
            ) : null}

            {/* Belvo */}
            {isBelvoAllowed ? (
              <TabPane tab="" key="4">
                <ComprobantesIngresosClientDataShow
                  id={id}
                  uuid={uuid}
                  // Data client
                  dataClient={data?.idVerification}
                  // Link user
                  statusCheckUser={isBelvoDataLinkVerified}
                  dataLinkBelvoFound={
                    isBelvoDataLinkVerified
                      ? {
                          link_fiscal: data?.belvoLinkListFiscalLink,
                          link_bank: data?.belvoLinkListBankLink,
                        }
                      : false
                  }
                  // Fiscal and bank data
                  statusBankData={isBelvoDataBankVerified}
                  generalDataBank={
                    isBelvoDataBankVerified ? data?.belvoBankData : false
                  }
                  statusFiscalData={isBelvoDataFiscalVerified}
                  generalDataFiscal={
                    isBelvoDataFiscalVerified ? data?.belvoFiscalData : false
                  }
                />
              </TabPane>
            ) : null}
          </>
        ) : null}
      </Tabs>
    </Fragment>
  )
}

export default ClientAllDataShow
