import React from 'react'
import { Typography } from 'antd'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const styles = makeStyles({
  item: {
    height: '50px',
    display: 'flex',
    borderBottom: '1px solid #dfdfdf',
    outline: 'none',
    '&:hover, &:active, &:focus': {
      backgroundColor: '#dfdfdf',
    },
  },
  column: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'var(--primary-color)',
    color: '#ffffff',
    height: '50px',
    fontWeight: 'bold',
  },
  results: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    '& > div': {
      margin: 0,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    minHeight: '100%',
    marginBottom: '1.8rem',
  },
})

const TableMobile = (props) => {
  const { register, showOptions, uuidVerification } = props
  const { t } = useTranslation()
  const history = useHistory()
  const classes = styles()

  const showDetails = (uuid) => {
    if (window.localStorage.getItem('uuidVerification')) {
      window.localStorage.removeItem('uuidVerification')
    }
    history.push(`/contratos/plantillas/firma-plantilla/${uuid}`)
  }

  const showDetailsWithVerification = (uuidTemplate, uuidVerificaction) => {
    window.localStorage.setItem('uuidVerification', uuidVerificaction)
    history.push(`/contratos/plantillas/firma-plantilla/${uuidTemplate}`)
  }

  // Render body table
  const renderRegistrosMovile = (registro, i) => {
    const { Paragraph } = Typography
    return (
      <Grid
        item
        xs={12}
        key={i}
        className={classes.item}
        style={{ cursor: 'pointer' }}
      >
        <Grid
          item
          xs={5}
          className={classes.results}
          onClick={
            showOptions
              ? () => showDetails(registro?.uuid)
              : () =>
                  showDetailsWithVerification(registro?.uuid, uuidVerification)
          }
        >
          <p>{registro?.title || '-'}</p>
        </Grid>
        <Grid
          item
          xs={7}
          className={classes.results}
          onClick={
            showOptions
              ? () => showDetails(registro?.uuid)
              : () =>
                  showDetailsWithVerification(registro?.uuid, uuidVerification)
          }
        >
          <Paragraph
            ellipsis={(true, { rows: 1 })}
            style={{ width: '90%', textAlign: 'center' }}
          >
            {registro?.message || '-'}
          </Paragraph>
        </Grid>
      </Grid>
    )
  }
  return (
    <Grid container className={classes.container}>
      <Grid item xs={5} className={classes.column}>
        {t('title')}
      </Grid>
      <Grid item xs={7} className={classes.column}>
        {t('message')}
      </Grid>
      {register.map(renderRegistrosMovile)}
    </Grid>
  )
}

export default TableMobile
