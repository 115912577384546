import React, { Fragment, useState } from 'react'
import { Button, Form, Transition, Label, Message } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

import '../ConsultationServices.css'
import CardAntecedentesWithInfo from './CardAntecedentesWithInfo'
import useToken from '../../../utils/useToken'
import { API_URL } from '../../../const/index'

const AntecedentesPenalesV2 = ({
  dataVerification,
  givenname,
  surname,
  id,
  uuid,
  courtStatus,
  handleChangeInputName,
  handleChangeInputSurname,
  dataCourtFound,
  setIsCourtVerified,
  onVerifyCourtRecord,
  //Control de errores cuando se refleja cambios en el ingreso de datos en el input curp
  classErrorNameInputAntecedentes,
  visibleErrorNameInputAntecedentes,
  messageErrorNameInputAntecedentes,
  classErrorSurnameInputAntecedentes,
  visibleErrorSurnameInputAntecedentes,
  messageErrorSurnameInputAntecedentes,
}) => {
  const [verificando, setVerificando] = useState(false)
  const [dataCourtRecord, setCourtRecord] = useState(null)

  //Control de errores de la API
  const [visibleApiErros, setVisibleApiErrors] = useState(false)
  const [apiErrorMsg, setApiErrorMsg] = useState('')
  const [classErrorInput, setClassErrorInput] = useState('')

  const { t } = useTranslation()
  const getToken = useToken()

  const getAntecedentesPenales = async () => {
    try {
      const token = await getToken({
        audience: 'veridocid',
      })

      const response = await fetch(`${API_URL}/legalrecord/v2`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
          uuid,
          name: givenname,
          surname,
        }),
      })
      const responseJSON = await response.json()

      if (response.ok) {
        onVerifyCourtRecord(responseJSON)
        setCourtRecord(responseJSON?.results)
        setIsCourtVerified(true)
      } else {
        setIsCourtVerified(false)
        setClassErrorInput('error')
        setVisibleApiErrors(true)
        setApiErrorMsg(`${t('endpointMessageErrorLegalRecord')}`)
        setVerificando(false)
      }
    } catch (error) {
      setVisibleApiErrors(true)
      setApiErrorMsg(`${t('messageErrorData')}`)
      setVerificando(false)
      throw error
    }
  }

  const changeStateVisibleMsgErrorApi = () => {
    setVisibleApiErrors(false)
    setClassErrorInput('')
  }

  return (
    <Fragment>
      <div className="ui container">
        {courtStatus ? (
          <CardAntecedentesWithInfo
            dataCourt={dataCourtFound || dataCourtRecord}
            dataVerification={dataVerification}
          />
        ) : (
          <>
            <Form error={visibleApiErros}>
              <Form.Field>
                <p>{t('name')}</p>
                <div
                  className={`ui fluid input ${
                    classErrorNameInputAntecedentes || classErrorInput
                  }`}
                >
                  <input
                    type="text"
                    placeholder={t('name')}
                    value={givenname}
                    onChange={handleChangeInputName}
                    disabled={verificando}
                  />
                </div>
                {/* Control errors */}
                <Transition
                  animation="drop"
                  duration={500}
                  visible={visibleErrorNameInputAntecedentes}
                >
                  <Label
                    pointing
                    color="red"
                    as="span"
                    style={{ lineHeight: '15px' }}
                  >
                    {`${messageErrorNameInputAntecedentes}`}
                  </Label>
                </Transition>
              </Form.Field>

              <Form.Field>
                <p>{t('surname')}</p>
                <div
                  className={`ui fluid input ${
                    classErrorSurnameInputAntecedentes || classErrorInput
                  }`}
                >
                  <input
                    type="text"
                    placeholder={t('surname')}
                    value={
                      surname.includes('^')
                        ? surname.replace('^', ' ')
                        : surname
                    }
                    onChange={handleChangeInputSurname}
                    disabled={verificando}
                  />
                </div>
                {/* Control errors */}
                <Transition
                  animation="drop"
                  duration={500}
                  visible={visibleErrorSurnameInputAntecedentes}
                >
                  <Label
                    pointing
                    color="red"
                    as="span"
                    style={{ lineHeight: '15px' }}
                  >
                    {`${messageErrorSurnameInputAntecedentes}`}
                  </Label>
                </Transition>
              </Form.Field>
              {/*Mensajes de error de la API  */}
              <Message
                error
                onDismiss={changeStateVisibleMsgErrorApi}
                header={'Antecedentes Legales'}
                content={apiErrorMsg}
                id="my_msg"
              />
            </Form>
            <br />
            <div className="ButtonsCard">
              <Button
                onClick={() => {
                  if (givenname === undefined || givenname === '') {
                    //Control de errores
                    setClassErrorInput('error')
                    setVisibleApiErrors(true)
                    setApiErrorMsg(`${t('msgEmptyInputName')}`)
                  } else if (surname === undefined || surname === '') {
                    setClassErrorInput('error')
                    setVisibleApiErrors(true)
                    setApiErrorMsg(`${t('msgEmptyInputSurname')}`)
                  } else if (visibleErrorNameInputAntecedentes) {
                    setVisibleApiErrors(false)
                    setClassErrorInput('')
                  } else if (visibleErrorSurnameInputAntecedentes) {
                    setVisibleApiErrors(false)
                    setClassErrorInput('')
                  } else {
                    getAntecedentesPenales()
                  }
                }}
                disabled={verificando}
                loading={verificando}
              >
                {t('send')}
              </Button>
            </div>
          </>
        )}
      </div>
    </Fragment>
  )
}

export default AntecedentesPenalesV2
