import React, { useState } from 'react'
import { Grid, Button } from '@material-ui/core'
import {
  VscArrowLeft,
  VscArrowRight,
  VscZoomIn,
  VscZoomOut,
  VscDesktopDownload,
} from 'react-icons/vsc'
import '../../styles/ContractStyles.css'
import download from 'downloadjs'

// PDF
import { Document, Page, pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const PDFClient = ({ fileBase64, idDoc }) => {
  const [page, setPageNumber] = useState(1)
  const [zoom, setZoom] = useState(1)
  const [numPages, setNumPages] = useState(0)

  //   File
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
    setPageNumber(1)
  }

  //   Pages
  const changePage = (offset) =>
    setPageNumber((prevPageNumber) => prevPageNumber + offset)

  const previousPage = () => {
    if (page > 1) changePage(-1)
  }

  const nextPage = () => {
    if (page < numPages) changePage(1)
  }

  //   Zoom
  const zoomOut = () => {
    let newZoom = zoom - 0.1
    newZoom = newZoom.toFixed(1)
    newZoom = parseFloat(newZoom)

    if (newZoom >= 0.1) setZoom(newZoom)
  }

  const zoomIn = () => {
    let newZoom = zoom + 0.1
    newZoom = newZoom.toFixed(1)
    newZoom = parseFloat(newZoom)

    if (newZoom <= 2) setZoom(newZoom)
  }

  const downloadContract = () => {
    if (fileBase64) {
      download(fileBase64, `${idDoc}`, 'application/pdf')
    }
  }

  return (
    <Grid container>
      {/* Tools */}
      <Grid container className="container_tools_pdf">
        <Grid item xs={4}>
          {/* Pages */}
          {fileBase64 ? (
            <Grid container className="tools_pdf">
              <Grid item xs={3} className="tool_pdf">
                {page > 1 ? (
                  <Button style={{ outline: 'none' }} onClick={previousPage}>
                    <VscArrowLeft size={24} color={'#eee'} />
                  </Button>
                ) : null}
              </Grid>
              <Grid item xs={3} className="tool_pdf">
                <div>
                  {page || (numPages ? 1 : '--')} / {numPages || '--'}
                </div>
              </Grid>
              <Grid item xs={3} className="tool_pdf">
                {page < numPages ? (
                  <Button style={{ outline: 'none' }} onClick={nextPage}>
                    <VscArrowRight size={24} color={'#eee'} />
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          ) : null}
        </Grid>
        {/* Zoom */}
        <Grid item xs={4}>
          {fileBase64 ? (
            <Grid container>
              <Grid item xs={3} className="tool_pdf">
                <Button style={{ outline: 'none' }} onClick={zoomOut}>
                  <VscZoomOut size={24} color={'#eee'} />
                </Button>
              </Grid>
              <Grid item xs={3} className="tool_pdf">
                <div>{parseInt(zoom * 100)}%</div>
              </Grid>
              <Grid item xs={3} className="tool_pdf">
                {zoom < 2 ? (
                  <Button style={{ outline: 'none' }} onClick={zoomIn}>
                    <VscZoomIn size={24} color={'#eee'} />
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          ) : null}
        </Grid>
        {/* Download */}
        <Grid
          item
          xs={4}
          style={{ display: 'grid', justifyContent: 'flex-end' }}
        >
          {fileBase64 ? (
            <Button
              style={{ outline: 'none' }}
              onClick={() => downloadContract()}
            >
              <VscDesktopDownload size={20} color={'#eee'} />
            </Button>
          ) : null}
        </Grid>
      </Grid>
      {/* Render PDF */}
      <div className="content_render_file" id="pdf_renderer">
        {fileBase64 ? (
          <Document file={fileBase64} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={page} scale={zoom} />
          </Document>
        ) : (
          <p>No se pudo cargar el documento</p>
        )}
      </div>
    </Grid>
  )
}

export default PDFClient
