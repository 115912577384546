import React, { Fragment, useEffect, useState } from 'react'
import { Dimmer, Loader, Pagination } from 'semantic-ui-react'
import TableResponsive from './TableResponsive'
import { API_DASHBOARD_URL } from '../../const'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ExportToCsv } from 'export-to-csv'
import { FilterTable } from './FilterTable'
import useToken from '../../utils/useToken'
import { Hidden } from '@material-ui/core'
import TableDesktop from './TableDesktop'
import {
  disabledContract,
  getDocumentBase64Sign,
  getDocumentBase64SignNom,
  signEmailForwarding,
} from '../../utils/getDataCustomize'
import Alert from '../Alert/Alert'
import { format } from 'date-fns'
import download from 'downloadjs'
import { Skeleton } from 'antd'
import moment from 'moment'
import useSWR from 'swr'
import { ModalDataParticipants } from './ModalDataParticipants'
import ModalVerification from '../ModalVerification'
import { isArray } from 'lodash'
import { useAuth0 } from '../../react-auth0-spa'

const ContractsTablev2 = () => {
  const history = useHistory()
  const getToken = useToken()
  const { user } = useAuth0()
  const { t } = useTranslation()
  const [activePage, setActivePage] = useState(1)
  const [register, setRegister] = useState(null)
  const [delay, setDelay] = useState(4000)
  const [limit, setLimit] = useState(10)
  const [selected, setSelected] = useState({
    createdAtD: true,
    createdAtA: false,
  })

  // Alert
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    severity: '',
  })

  // Modals
  const [failedBiometrics, setFailedBiometrics] = useState(false)
  const [jsonVerification, setJsonVerification] = useState({})
  const [openEnrollModal, setOpenEnrollModal] = useState(false)
  const [openModalDP, setOpenModalDP] = useState(false)
  const [docID, setDocID] = useState(null)

  // Search
  const [loadingExport, setLoadingExport] = useState(false)
  const [startDate, setStartDate] = useState(undefined)
  const [endDate, setEndDate] = useState(undefined)
  const [documentID, setDocumentID] = useState('')
  const [documentName, setDocumentName] = useState('')
  const [filters, setFilters] = useState([])
  const [country, setCountry] = useState('')
  const [status, setStatus] = useState('')
  const [name, setName] = useState('')

  const changeDocumentID = (event) => setDocumentID(event?.target?.value)
  const changeDocumentName = (event) => setDocumentName(event?.target?.value)
  const changeName = (event) => setName(event?.target?.value)
  const changeStartDate = (event) => setStartDate(moment(event))
  const changeEndDate = (event) => setEndDate(moment(event))
  const changeCountry = (value) => setCountry(value)
  const changeStatus = (value) => setStatus(value)

  //   Get all contracts
  const showContracts = async (url) => {
    const token = await getToken({
      audience: 'veridocid-dashboard',
    })

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })

    if (response.ok) {
      const responseJson = await response.json()
      return responseJson
    } else {
      return null
    }
  }

  const { data } = useSWR(
    `${API_DASHBOARD_URL}/sign?limit=${limit}&page=${activePage}&sortBy=${
      selected.createdAtD ? 1 : selected.createdAtA ? 2 : 1
    }&filters=${JSON.stringify(filters)}`,
    showContracts,
    {
      refreshInterval: delay,
    }
  )

  useSWR(
    register?.hasNextPage
      ? `${API_DASHBOARD_URL}/sign?limit=${limit}&page=${
          activePage + 1
        }&sortBy=${
          selected.createdAtD ? 1 : selected.createdAtA ? 2 : 1
        }&filters=${JSON.stringify(filters)}`
      : null,
    showContracts
  )

  useEffect(() => {
    if (data) {
      const wait =
        Math.ceil(data.totalDocs / 10) < 5000
          ? 5000
          : Math.ceil(data.totalDocs / 8)
      setDelay(wait)
      setRegister(data)
    }
  }, [limit, data])

  const handlePaginationChange = (e, { activePage }) =>
    setActivePage(activePage)

  // Sort
  const selectCreatedAtD = () =>
    setSelected({
      createdAtD: true,
      createdAtA: false,
    })

  const selectCreatedAtA = () =>
    setSelected({
      createdAtD: false,
      createdAtA: true,
    })

  // Actions
  const downloadContract = async (documentID, uuid) => {
    try {
      const token = await getToken({
        audience: 'veridocid-dashboard',
      })
      const base64 = await getDocumentBase64Sign(token, documentID, uuid)

      if (base64 && documentID) {
        download(
          base64.includes('data:application/pdf;base64,')
            ? base64
            : 'data:application/pdf;base64,' + base64,
          `${documentID}`,
          'application/pdf'
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  const downloadContractNom = async (documentID, uuid) => {
    try {
      const token = await getToken({
        audience: 'veridocid-dashboard',
      })
      const nomFile = await getDocumentBase64SignNom(token, documentID, uuid)

      download(
        'data:application/octet-stream;base64,' + nomFile,
        `${documentID}.nom`,
        'application/octet-stream'
      )
    } catch (error) {
      console.log(error)
    }
  }

  const mailForwarding = async (id, documentID) => {
    const token = await getToken({
      audience: 'veridocid',
    })

    const response = await signEmailForwarding(token, { id, documentID })

    if (response?.error) {
      if (isArray(response?.error?.message)) {
        setAlert({
          open: true,
          message: `${response.error?.message[0]}`,
          severity: 'error',
        })
      } else {
        setAlert({
          open: true,
          message: `${response.error?.message}`,
          severity: 'error',
        })
      }
      return
    }

    if (response !== null) {
      setAlert({
        open: true,
        message: `${t('successfulMailForwarding')}`,
        severity: 'success',
      })
    } else {
      setAlert({
        open: true,
        message: `${t('errorForwardingMails')}`,
        severity: 'error',
      })
    }
  }

  const cancelContract = async (documentID) => {
    const token = await getToken({ audience: 'veridocid' })

    const response = await disabledContract(token, {
      documentID,
      disable: true,
      email: user?.email,
      user: user?.name,
    })

    if (response?.error) {
      if (isArray(response?.error?.message)) {
        setAlert({
          open: true,
          message: `${response.error?.message[0]}`,
          severity: 'error',
        })
      } else {
        setAlert({
          open: true,
          message: `${response.error?.message}`,
          severity: 'error',
        })
      }
      return
    }

    setAlert({
      open: true,
      message: 'Se ha cancelado con éxito el documento',
      severity: 'success',
    })
  }

  //   Search
  const onSearch = () => {
    let searchData = [
      {
        documentID: '',
        documentName: '',
        status: '',
        name: '',
        country: '',
        startDate: '',
        endDate: '',
      },
    ]
    searchData.forEach((data) => {
      if (name !== '') data.name = name
      if (country !== '') data.country = country
      if (status !== '') data.status = status
      if (documentID !== '') data.documentID = documentID
      if (documentName !== '') data.documentName = documentName
      if (startDate !== '' || startDate !== undefined)
        data.startDate = startDate
      if (endDate !== '' || endDate !== undefined) data.endDate = endDate
    })
    setFilters(searchData)
    setActivePage(1)
  }
  // Clear
  const onClear = () => {
    setName('')
    setCountry('')
    setStatus('')
    setDocumentID('')
    setDocumentName('')
    setStartDate(undefined)
    setEndDate(undefined)
    setFilters([])
    setActivePage(1)
  }
  // Export
  const onExport = async () => {
    let data = []
    let exportData = {}
    setLoadingExport(true)

    if (filters && filters.length === 0) {
      const emptyData = {
        documentName: '',
        documentID: '',
        startDate: '',
        endDate: '',
        country: '',
        status: '',
        name: '',
      }
      exportData = emptyData
    } else {
      filters.forEach((data) => {
        if (data?.documentName !== '')
          exportData.documentName = data?.documentName
        else exportData.documentName = ''
        if (data?.documentID !== '') exportData.documentID = data?.documentID
        else exportData.documentID = ''
        if (data?.startDate !== '' || data?.startDate !== undefined)
          exportData.startDate = data?.startDate
        else exportData.startDate = ''
        if (data?.endDate !== '' || data?.endDate !== undefined)
          exportData.endDate = data?.endDate
        else exportData.endDate = ''
        if (data?.country !== '') exportData.country = data?.country
        else exportData.country = ''
        if (data?.status !== '') exportData.status = data?.status
        else exportData.status = ''
        if (data?.name !== '') exportData.name = data?.name
        else exportData.name = ''
      })
    }

    const token = await getToken({
      audience: 'veridocid-dashboard',
    })

    const response = await fetch(`${API_DASHBOARD_URL}/sign/export-data`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(exportData),
    })

    if (response.ok) {
      let dataExport = await response.json()
      dataExport?.docs.map((doc) =>
        data.push({
          Fecha: moment(doc?.createdAt).format('yyyy/MM/DD - h:mm:ss'),
          Fecha_firma:
            doc?.status === 'signed' || doc?.status === 'disabled'
              ? moment(doc?.updatedAt).format('yyyy/MM/DD - h:mm:ss')
              : '-',
          Documento_id: doc?.documentID ?? '-',
          Template_id: doc?.fromTemplate ?? '-',
          Firmantes: doc?.participants.map((e) => e.name).join(),
          Estado: doc?.status,
          Verificaciones: doc?.participants
            .filter((p) => p.uuid !== '')
            .map((data) => data.uuid)
            .join(),
        })
      )
    }

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: moment().format('DD/MM/yyyy'),
      headers: [
        'Fecha',
        'Fecha de la firma',
        'Document ID',
        'ID Plantilla',
        'Firmantes',
        'Estatus',
        'Verificaciones',
      ],
    }
    const csvExporter = new ExportToCsv(options)

    if (csvExporter?._options) {
      csvExporter.generateCsv(data)
      setLoadingExport(false)
    }
  }

  return (
    <Fragment>
      {/* Alert */}
      {alert.open ? <Alert alert={alert} setAlert={setAlert} /> : null}
      {/* Search */}
      <FilterTable
        t={t}
        startDate={startDate}
        changeStartDate={changeStartDate}
        endDate={endDate}
        changeEndDate={changeEndDate}
        name={name}
        changeName={changeName}
        documentID={documentID}
        changeDocumentID={changeDocumentID}
        documentName={documentName}
        changeDocumentName={changeDocumentName}
        country={country}
        changeCountry={changeCountry}
        status={status}
        changeStatus={changeStatus}
        onSearch={onSearch}
        onClear={onClear}
        onExport={onExport}
        loadingExport={loadingExport}
      />
      {/* Modal to verification */}
      {openEnrollModal ? (
        <ModalVerification
          setOpenEnrollModal={setOpenEnrollModal}
          openEnrollModal={openEnrollModal}
          jsonVerification={jsonVerification}
          failedBiometrics={failedBiometrics}
        />
      ) : null}

      {/* Modal to show participants  */}
      <ModalDataParticipants
        setJsonVerification={setJsonVerification}
        setFailedBiometrics={setFailedBiometrics}
        setOpenEnrollModal={setOpenEnrollModal}
        setOpenModalDP={setOpenModalDP}
        openModalDP={openModalDP}
        setAlert={setAlert}
        docID={docID}
        t={t}
      />
      {/* Table */}
      <Dimmer.Dimmable dimmed={!data}>
        <Dimmer active={!data} inverted>
          <Loader>{t('loading')}</Loader>
        </Dimmer>
        <Hidden smDown>
          {register ? (
            <TableDesktop
              t={t}
              format={format}
              register={register}
              history={history}
              selected={selected}
              selectCreatedAtA={selectCreatedAtA}
              selectCreatedAtD={selectCreatedAtD}
              downloadContract={downloadContract}
              downloadContractNom={downloadContractNom}
              mailForwarding={mailForwarding}
              cancelContract={cancelContract}
              setOpenModalDP={setOpenModalDP}
              setDocID={setDocID}
            />
          ) : (
            <>
              <Skeleton.Input active block style={{ height: '600px' }} />
            </>
          )}
        </Hidden>

        <Hidden mdUp>
          {register ? (
            <TableResponsive
              t={t}
              format={format}
              register={register}
              history={history}
              selected={selected}
              selectCreatedAtA={selectCreatedAtA}
              selectCreatedAtD={selectCreatedAtD}
              downloadContract={downloadContract}
              downloadContractNom={downloadContractNom}
            />
          ) : (
            <>
              <Skeleton.Input active block style={{ height: '600px' }} />
            </>
          )}
        </Hidden>

        <div className="content_pagination_table_custom">
          {/* Show data */}
          <div className="content_feature_to_pagunation">
            <p>{t('show')}</p>
            <select
              name="table_contract"
              id="table_contract"
              className="select_limit_to_pagination"
              onChange={(e) => setLimit(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <p>
              {' '}
              {t('of')} {register?.totalDocs}
            </p>
          </div>
          {/* Pagination */}
          <Pagination
            secondary
            firstItem={null}
            lastItem={null}
            activePage={activePage}
            onPageChange={handlePaginationChange}
            totalPages={register?.totalPages ?? 0}
          />
        </div>
      </Dimmer.Dimmable>
    </Fragment>
  )
}

export default ContractsTablev2
