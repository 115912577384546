import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'

export const Tools = styled(Grid)({
  height: '50px',
  backgroundColor: 'var(--secondary-color)',
  color: '#eee',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: '500',
})

export const Tool = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
})

export const MoreTools = styled(Grid)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
})

export const ShowPDF = styled(Grid)({
  width: '100%',
  height: '900px',
  padding: '50px',
  overflow: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  backgroundColor: '#E6E6E6',
  flexDirection: 'row',
  position: 'relative',
  zIndex: '98',
})

export const DDZone = styled(Grid)((props) => ({
  width: props.width ? props.width : 612,
  height: props.height ? props.height : 792,
  position: 'absolute',
  zIndex: '95',
  overflow: 'hidden',
}))
