import { API_URL } from '../const/index.js'

export const updateCustomColors = async (token, body) => {
  try {
    const url = `${API_URL}/update-custom-dashboard`
    const formdata = JSON.stringify(body)

    const params = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: formdata,
    }

    const response = await fetch(url, params)
    const result = await response.json()

    return result
  } catch (error) {
    console.error(error)
    return null
  }
}

export const getCustomColors = async (token) => {
  try {
    const url = `${API_URL}/get-custom-dashboard`

    const params = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const response = await fetch(url, params)
    const result = await response.json()

    return result
  } catch (error) {
    console.error(error)
    return null
  }
}
