import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

// Components
import { Button, Header, Icon, Modal } from 'semantic-ui-react'
import SpinnerModal from '../SpinnerModal/SpinnerModal'

//Fixtures
import { labels } from './ModalExperts.fixture'

const ModalExperts = (props) => {
  const history = useHistory()
  const [open, setOpen] = useState(false)

  return props?.isLoading ? (
    <div>
      <Modal
        basic
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={props?.isLoading}
        size="small"
        id="modalRevision"
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <SpinnerModal />
      </Modal>
    </div>
  ) : (
    <div>
      <Modal
        basic
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={props?.isOpenModal?.open || open}
        size="small"
        id="modalRevision"
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Header icon>
          <Icon
            name={
              props?.isOpenModal?.isChecked ? labels.checkmark : labels.warning
            }
            color={props?.isOpenModal?.isChecked ? labels.green : labels.red}
          />
          <b
            style={{
              color: props?.isOpenModal?.isChecked ? labels.green : labels.red,
            }}
          >
            {props?.isOpenModal?.isChecked
              ? labels.checked
              : props?.isOpenModal?.isError
              ? labels.error
              : labels.manualIdentification}
          </b>
        </Header>
        <Modal.Content>
          <p>
            {props?.isOpenModal?.isChecked
              ? labels.checkedTitle
              : props?.isOpenModal?.isError
              ? labels.errorTitle
              : labels.manualIdentificationTitle}
          </p>
          <p>
            {props?.isOpenModal?.isChecked
              ? labels.checkedDescription
              : props?.isOpenModal?.isError
              ? labels.errorDescription
              : labels.manualIdentificationDescription}
          </p>
        </Modal.Content>
        <Modal.Actions style={{ alignSelf: 'flex-end', margin: '0 20%' }}>
          <Button
            color={props?.isOpenModal?.isChecked ? labels.green : labels.red}
            inverted
            onClick={() => history.goBack()}
            style={{ textAlign: 'center' }}
          >
            <Icon name="checkmark" />
            {labels.ok}
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  )
}

export default ModalExperts
