import React from 'react'
import { useTranslation } from 'react-i18next'

const CardList69Withinfo = ({ dataList69 }) => {
  const { t } = useTranslation()
  const fontStyle = {
    fontWeight: '700',
    color: '#313030',
  }

  const gridLayout = {
    display: 'flex',
    flexDirection: 'column',
    margin: '0.5em 0 0.8rem 0',
  }

  const gridData = {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '5px',
    paddingTop: '5px',
    backgroundColor: '#F8F9FA',
    padding: '1rem 1.6rem',
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div style={{ width: '100%' }}>
        <h3>
          <strong>{t('infoList69')}</strong>
        </h3>

        <div style={gridLayout}>
          <span style={fontStyle}>{t('message')}</span>
          {dataList69?.message ? (
            <p>{dataList69?.message}</p>
          ) : (
            <p>Sin datos</p>
          )}
        </div>

        <div style={gridLayout}>
          <span style={fontStyle}>{t('list69Title')}</span>
          {dataList69?.list69 ? (
            <p style={gridData}>
              <span>
                <strong style={{ color: 'gray' }}>RFC:</strong>{' '}
                {dataList69?.list69?.rfc}
              </span>
              <span>
                <strong style={{ color: 'gray' }}>Razón Social:</strong>{' '}
                {dataList69?.list69?.razonSocial}
              </span>
              <span>
                <strong style={{ color: 'gray' }}>{t('situation')}:</strong>{' '}
                {dataList69?.list69?.situacion}
              </span>
            </p>
          ) : (
            <p>Sin datos</p>
          )}
        </div>

        {/* 69B */}
        <div style={gridLayout}>
          <span style={fontStyle}>{t('List69BTitle')}</span>
          {dataList69?.list69B ? (
            <p style={gridData}>
              <span>
                <strong style={{ color: 'gray' }}>RFC:</strong>{' '}
                {dataList69?.list69B?.rfc}
              </span>
              <span>
                <strong style={{ color: 'gray' }}>Razón Social:</strong>{' '}
                {dataList69?.list69B?.razonSocial}
              </span>
              <span>
                <strong style={{ color: 'gray' }}>{t('situation')}:</strong>{' '}
                {dataList69?.list69B?.situacion}
              </span>
            </p>
          ) : (
            <p>Sin datos</p>
          )}
        </div>
      </div>
    </div>
  )
}

export default CardList69Withinfo
