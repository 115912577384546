import React from 'react'

import './DocumentImageQuality.css'
import { labels } from '../../../../fixtures/panelExperts.fixture'
import iconAwesomeCheck from '../../../../assets/icons/awesome-check.svg'
import iconAwesomeFail from '../../../../assets/icons/awesome-fail.svg'

const DocumentImageQuality = ({ response }) => {
  const frontImageQuality = response?.filter(
    ({ source }) => source === 'FRONT_IMAGE'
  )
  const backImageQuality = response?.filter(
    ({ source }) => source === 'BACK_IMAGE'
  )
  const isBackImage = backImageQuality?.length >= 1

  const getRows = (arrayToRender) =>
    arrayToRender?.map((resp, index) => {
      return (
        <tr key={index}>
          <td className="DocumentImageQualityRows">{resp?.name}</td>
          <td className="DocumentImageQualityRows">
            <img
              src={
                resp?.result === 'ERROR' ? iconAwesomeFail : iconAwesomeCheck
              }
              alt="Result"
              style={{ width: '1.5rem' }}
            />
          </td>
        </tr>
      )
    })

  return (
    <div className="DocumentImageQuality">
      <div style={{ width: isBackImage ? '50%' : '100%' }}>
        <h3 className="TitleDocumentImageQuality">{labels.front}</h3>
        <table className="TableDocumentImageQuality">
          <thead>
            <tr>
              <th className="ThDocumentImageQuality" style={{ width: '25%' }}>
                <b>{labels.name}</b>
              </th>
              <th className="ThDocumentImageQuality" style={{ width: '15%' }}>
                <b>{labels.result}</b>
              </th>
            </tr>
          </thead>
          <tbody>{getRows(frontImageQuality)}</tbody>
        </table>
      </div>

      {isBackImage ? (
        <div style={{ width: '50%' }}>
          <h3 className="TitleDocumentImageQuality">{labels.back}</h3>
          <table className="TableDocumentImageQuality">
            <thead>
              <tr>
                <th className="ThDocumentImageQuality" style={{ width: '25%' }}>
                  <b>{labels.name}</b>
                </th>
                <th className="ThDocumentImageQuality" style={{ width: '15%' }}>
                  <b>{labels.result}</b>
                </th>
              </tr>
            </thead>
            <tbody>{getRows(frontImageQuality)}</tbody>
          </table>
        </div>
      ) : null}
    </div>
  )
}

export default DocumentImageQuality
