import React from 'react'
import { Divider } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { VscInfo } from 'react-icons/vsc'

const CardImssWithInfo = ({ dataImss, nss }) => {
  const { t } = useTranslation()

  const fontStyle = {
    fontWeight: '550',
  }

  const gridLayout = {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    margin: '0.5em 0',
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h4 style={{ fontWeight: 'bold' }}>{dataImss.nombre || 'Usuario'}</h4>
          <h4 style={fontStyle}>{`${t('nss')}: ${nss}`}</h4>
        </div>
        <Divider clearing />
        <h3 style={{ paddingBottom: '0.5em' }}>Semanas cotizadas</h3>
        {dataImss.semanasCotizadas ? (
          <div style={gridLayout}>
            <p>{'Semanas cotizadas'}</p>
            <span style={fontStyle}>
              {dataImss?.semanasCotizadas?.semanasCotizadas}
            </span>
            <p>{'Semanas reintegradas'}</p>
            <span style={fontStyle}>
              {dataImss?.semanasCotizadas?.semanasReintegradas}
            </span>
            <p>{'Semanas descontadas'}</p>
            <span style={fontStyle}>
              {dataImss?.semanasCotizadas?.semanasDescontadas}
            </span>
          </div>
        ) : (
          <div
            style={{
              backgroundColor: '#fafafa',
              padding: '1.3em 0 0.6em 0',
              textAlign: 'center',
            }}
          >
            <VscInfo size={20} color="var(--red)" />
            <p style={{ padding: '1em 0' }}>
              Por el momento no se han encontrado datos
            </p>
          </div>
        )}
        <Divider clearing style={{ color: 'red' }} />
        <h3 style={{ paddingBottom: '0.5em' }}>Historial laboral</h3>
        {dataImss.historialLaboral && dataImss.historialLaboral.length > 0 ? (
          <div>
            {dataImss.historialLaboral.map((history, key) => (
              <div
                style={{
                  margin: '1.3em 0',
                  paddingBottom: '0.7em',
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, 1fr)',
                  borderBottom: '1px solid #d7d7d7',
                }}
                key={key}
              >
                <p>{'Fecha de alta'}</p>
                <span style={fontStyle}>{history?.fechaAlta}</span>
                <p>{'Fecha de baja'}</p>
                <span style={fontStyle}>{history?.fechaBaja}</span>
                <p>{'Salario base'}</p>
                <span style={fontStyle}>{history?.salarioBaseCotizacion}</span>
                <p>{'Entidad federativa'}</p>
                <span style={fontStyle}>{history?.entidadFederativa}</span>
                <p>{'Nombre del patrón'}</p>
                <span style={fontStyle}>{history?.nombrePatron}</span>
                <p>{'Registro patronal'}</p>
                <span style={fontStyle}>{history?.registroPatronal}</span>
              </div>
            ))}
          </div>
        ) : (
          <div
            style={{
              backgroundColor: '#fafafa',
              padding: '1.3em 0 0.6em 0',
              textAlign: 'center',
            }}
          >
            <VscInfo size={20} color="var(--red)" />
            <p style={{ padding: '1em 0' }}>
              Por el momento no se han encontrado datos
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default CardImssWithInfo
