import React, { useEffect, useState } from 'react'
import { FaUserAlt } from 'react-icons/fa'
import { FiLogOut } from 'react-icons/fi'
import { NavLink as RouterNavLink } from 'react-router-dom'
import logoVeridoc from '../assets/VeriDocID_50.png'
// import veriDocID_WB from '../assets/VeriDocID_50_WB.svg'
import { Switch, useDarkreader } from 'react-darkreader'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { getCustomColors } from '../utils/profileServices'
import useToken from '../utils/useToken'

import {
  Button,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from 'reactstrap'
import { useAuth0 } from '../react-auth0-spa'
import '../styles/Dashboard.css'
import DropdownLanguage from './DropdownLanguage'
import { VscSignOut } from 'react-icons/vsc'

const NavBar = () => {
  const getToken = useToken()
  const [isOpen, setIsOpen] = useState(false)
  const [roles, setRoles] = useState([])
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0()
  const toggleNav = () => setIsOpen(!isOpen)
  const { t } = useTranslation()
  const [isDark, { toggle }] = useDarkreader(false)
  const [logo, setLogo] = useState(null)

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    })

  const history = useHistory()

  const routerChange = () => {
    let path = '/'
    history.push(path)
  }

  const getColors = async () => {
    const token = await getToken({
      audience: 'veridocid',
    })

    const customColors = await getCustomColors(token)

    if (customColors?.result?.isLogoCustomize) {
      setLogo(customColors?.result?.logo)
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      setRoles(user?.['https://vdid.sumamexico.com/roles'] ?? [])
      getColors()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, user])

  return (
    <div className="nav-container">
      <Navbar id="principal-nav" color="light" light expand="md">
        <Container>
          <NavbarBrand>
            {isDark ? (
              <img
                src={
                  logo === null ? logoVeridoc : 'data:image/png;base64,' + logo
                }
                style={{ objectFit: 'contain' }}
                width="100"
                alt="logo"
                height="50"
                onClick={routerChange}
              />
            ) : (
              <img
                src={
                  logo === null ? logoVeridoc : 'data:image/png;base64,' + logo
                }
                width="100"
                style={{ objectFit: 'contain' }}
                alt="logo"
                height="50"
                onClick={routerChange}
              />
            )}
          </NavbarBrand>
          <NavbarToggler onClick={toggleNav} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              {/*--------- Menu Item ---------*/}
              <NavItem>
                <NavLink
                  tag={RouterNavLink}
                  to="/"
                  exact
                  activeClassName="router-link-exact-active"
                  style={{ paddingRight: '0', marginRight: '14px' }}
                >
                  {t('records')}
                </NavLink>
              </NavItem>
              {/*--------- Firma elecrónica ---------*/}
              <UncontrolledDropdown
                nav
                inNavbar
                style={{ paddingRight: '0', marginRight: '12px' }}
              >
                <DropdownToggle nav caret>
                  {t('eletronicSignature')}
                </DropdownToggle>
                <DropdownMenu style={{ padding: '0' }}>
                  {/* Documents */}
                  <DropdownItem style={{ padding: '0' }}>
                    <NavLink
                      tag={RouterNavLink}
                      to="/contratos"
                      exact
                      activeClassName="router-link-exact-active"
                      style={{
                        width: '100%',
                        padding: '10px 15px',
                      }}
                    >
                      {t('documents')}
                    </NavLink>
                  </DropdownItem>
                  {/* Templates */}
                  <DropdownItem style={{ padding: '0' }}>
                    <NavLink
                      tag={RouterNavLink}
                      to="/contratos/plantillas"
                      exact
                      activeClassName="router-link-exact-active"
                      style={{
                        width: '100%',
                        padding: '10px 15px',
                      }}
                    >
                      {t('templates')}
                    </NavLink>
                  </DropdownItem>
                  {/* Mesa */}
                  {isAuthenticated && roles?.includes('tableReview') ? (
                    <DropdownItem style={{ padding: '0' }}>
                      <NavLink
                        tag={RouterNavLink}
                        to="/contratos/plantillas/mesa"
                        exact
                        activeClassName="router-link-exact-active"
                        style={{
                          width: '100%',
                          padding: '10px 15px',
                        }}
                      >
                        {t('reviewTable')}
                      </NavLink>
                    </DropdownItem>
                  ) : null}

                  {/* Firma masiva - Representante legal*/}
                  {isAuthenticated && roles?.includes('legalRepresentative') ? (
                    <DropdownItem style={{ padding: '0' }}>
                      <NavLink
                        tag={RouterNavLink}
                        to="/firma-masiva"
                        exact
                        activeClassName="router-link-exact-active"
                        style={{
                          width: '100%',
                          padding: '10px 15px',
                        }}
                      >
                        {t('massiveSignature')}
                      </NavLink>
                    </DropdownItem>
                  ) : null}

                  {isAuthenticated && roles?.includes('legalRepresentative') ? (
                    <DropdownItem style={{ padding: '0' }}>
                      <NavLink
                        tag={RouterNavLink}
                        to="/firma-masiva/contratos"
                        exact
                        activeClassName="router-link-exact-active"
                        style={{
                          width: '100%',
                          padding: '10px 15px',
                        }}
                      >
                        Contratos Firmados
                      </NavLink>
                    </DropdownItem>
                  ) : null}
                </DropdownMenu>
              </UncontrolledDropdown>

              {/* Menu item - documentación*/}
              <NavItem>
                <NavLink
                  tag={RouterNavLink}
                  to="/documentation"
                  exact
                  activeClassName="router-link-exact-active"
                >
                  {t('menuItemDocumentation')}
                </NavLink>
              </NavItem>

              {/* Panel de experto */}
              {isAuthenticated && roles?.includes('PanelOfExperts') ? (
                <UncontrolledDropdown
                  nav
                  inNavbar
                  style={{ paddingRight: '0', marginRight: '12px' }}
                >
                  <DropdownToggle nav caret>
                    {t('panelExperts')}
                  </DropdownToggle>
                  <DropdownMenu style={{ padding: '0' }}>
                    {/* Id */}
                    <DropdownItem style={{ padding: '0' }}>
                      <NavLink
                        tag={RouterNavLink}
                        to="/panelexperts/id"
                        exact
                        activeClassName="router-link-exact-active"
                        style={{
                          width: '100%',
                          padding: '10px 15px',
                        }}
                      >
                        {t('id')}
                      </NavLink>
                    </DropdownItem>
                    {/* Scanner */}
                    <DropdownItem style={{ padding: '0' }}>
                      <NavLink
                        tag={RouterNavLink}
                        to="/panelexperts/scanner"
                        exact
                        activeClassName="router-link-exact-active"
                        style={{
                          width: '100%',
                          padding: '10px 15px',
                        }}
                      >
                        {t('scanner')}
                      </NavLink>
                    </DropdownItem>
                    {/* NMP */}
                    <DropdownItem style={{ padding: '0' }}>
                      <NavLink
                        tag={RouterNavLink}
                        to="/panelexperts/nmp"
                        exact
                        activeClassName="router-link-exact-active"
                        style={{
                          width: '100%',
                          padding: '10px 15px',
                        }}
                      >
                        {t('nmp')}
                      </NavLink>
                    </DropdownItem>
                    {/* Coppel */}
                    <DropdownItem style={{ padding: '0' }}>
                      <NavLink
                        tag={RouterNavLink}
                        to="/panelexperts/coppel"
                        exact
                        activeClassName="router-link-exact-active"
                        style={{
                          width: '100%',
                          padding: '10px 15px',
                        }}
                      >
                        {t('coppel')}
                      </NavLink>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              ) : null}
            </Nav>
            {/* Dark theme */}
            <NavItem
              className="d-none d-md-block"
              style={{ marginRight: '20px' }}
            >
              <Switch checked={isDark} onChange={toggle} styling="docusaurus" />
            </NavItem>
            {/* Dropdown */}
            <NavItem className="d-none d-md-block">
              <DropdownLanguage />
            </NavItem>
            <Nav className="d-none d-md-block" navbar>
              {!isAuthenticated && (
                <NavItem>
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    className="btn-margin"
                    onClick={() => loginWithRedirect({})}
                  >
                    {t('login')}
                  </Button>
                </NavItem>
              )}
              {isAuthenticated && (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret id="profileDropDown">
                    <img
                      src={user.picture}
                      alt="Profile"
                      className="nav-user-profile rounded-circle"
                      width="50"
                    />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem header>{user.name}</DropdownItem>
                    <DropdownItem
                      tag={RouterNavLink}
                      to="/profile"
                      className="dropdown-profile"
                      activeClassName="router-link-exact-active"
                    >
                      <FaUserAlt className="mr-3" /> {t('profile')}
                    </DropdownItem>
                    <DropdownItem
                      id="qsLogoutBtn"
                      onClick={() => logoutWithRedirect()}
                    >
                      <VscSignOut size={14} style={{ marginRight: '10px' }} />
                      {t('logout')}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </Nav>
            {!isAuthenticated && (
              <Nav className="d-md-none" navbar>
                <NavItem>
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    block
                    onClick={() => loginWithRedirect({})}
                  >
                    {t('login')}
                  </Button>
                </NavItem>
              </Nav>
            )}
            {isAuthenticated && (
              <Nav
                className="d-md-none justify-content-between"
                navbar
                style={{ minHeight: 170 }}
              >
                <NavItem>
                  <span className="user-info">
                    <img
                      src={user.picture}
                      alt="Profile"
                      className="nav-user-profile d-inline-block rounded-circle mr-3"
                      width="50"
                    />
                    <h6 className="d-inline-block">{user.name}</h6>
                  </span>
                </NavItem>
                <NavItem>
                  <FaUserAlt className="mr-3" />
                  <RouterNavLink
                    to="/profile"
                    activeClassName="router-link-exact-active"
                  >
                    {t('profile')}
                  </RouterNavLink>
                </NavItem>
                <NavItem>
                  <FiLogOut className="mr-3" />
                  <RouterNavLink
                    to="#"
                    id="qsLogoutBtn"
                    onClick={() => logoutWithRedirect()}
                  >
                    {t('logout')}
                  </RouterNavLink>
                </NavItem>
              </Nav>
            )}
          </Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

export default NavBar
