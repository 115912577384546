import { styled } from '@mui/material/styles'
import { Modal, Grid } from '@mui/material'

//ModalSign
export const MyModal = styled(Modal)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const Container = styled(Grid)({
  width: '600px',
  backgroundColor: '#fff',
  outline: 'none',
  borderRadius: '3px',
  boxShadow:
    '0 3px 6px -4px rgba(0,0,0,.12),0 6px 16px 0 rgba(0,0,0,.08),0 9px 28px 8px rgba(0,0,0,.05)',
})
