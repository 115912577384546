import React from 'react'
import { Pagination } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

export default function Settings(props) {
  const { activePage, totalPages, data, handlePaginationChange, setLimit } =
    props
  const { t } = useTranslation()

  return (
    <div className="content_pagination_table_custom">
      <div className="content_feature_to_pagunation">
        <p>{t('show')}</p>
        <select
          name="table_contract"
          id="table_contract"
          className="select_limit_to_pagination"
          onChange={(e) => setLimit(e.target.value)}
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
        <p>
          {' '}
          {t('of')} {data?.totalDocs}
        </p>
      </div>
      <Pagination
        activePage={activePage}
        onPageChange={handlePaginationChange}
        totalPages={totalPages}
        secondary
        firstItem={null}
        lastItem={null}
        siblingRange={0}
      />
    </div>
  )
}
