import React from 'react'
import { Table } from 'semantic-ui-react'
import { Collapse } from 'antd'

const { Panel } = Collapse

const CardControlListCollapse = ({ dataControlList }) => {
  const rows = dataControlList.map((res, index) => {
    const controlListValues = res.inputFields[0]?.value
    let nameControlList

    const infoControlList = Object.keys(controlListValues).map((key, index) => {
      if (key === 'name') {
        nameControlList = controlListValues[key]
      }

      return (
        <Table.Row key={index}>
          <Table.Cell>
            <strong>{key}</strong>
          </Table.Cell>
          <Table.Cell>{controlListValues[key]}</Table.Cell>
        </Table.Row>
      )
    })

    return (
      <Panel header={`${res?.name}: ${nameControlList}`} key={index}>
        {infoControlList}
      </Panel>
    )
  })

  return (
    <>
      <Table color={'red'}>
        <Table.Body>
          <Collapse accordion>{rows}</Collapse>
        </Table.Body>
      </Table>
    </>
  )
}

export default CardControlListCollapse
