import React from 'react'

import './ResultLabel.css'

const ResultLabel = ({ text, backgroundColor, color }) => (
  <div
    className="ResultLabel"
    style={{
      backgroundColor,
    }}
  >
    <h3 style={{ color }}>{text}</h3>
  </div>
)

export default ResultLabel
