import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Button } from 'semantic-ui-react'
import { makeStyles } from '@material-ui/core/styles'
import { BlockPicker } from 'react-color'
import { message as signMessage } from 'antd'
import jwt from 'jsonwebtoken'
import Loading from '../components/Loading'
import { useAuth0 } from '../react-auth0-spa'
import useToken from '../utils/useToken'
import { getCustomColors, updateCustomColors } from '../utils/profileServices'

const useStyles = makeStyles((theme) => ({
  buttonFilter: {
    backgroundColor: 'transparent !important',
    border: 'solid 1px var(--primary-color) !important',
    color: 'var(--primary-color) !important',
    borderRadius: '3px',
    margin: '0',
    padding: '0.95rem 2rem !important',
    transition: 'all ease 600ms',
    '&:hover, &:active': {
      backgroundColor: 'var(--primary-color) !important',
      color: '#fff !important',
    },
  },
}))
let cssRoot = document.querySelector(':root')

const Profile = () => {
  const classes = useStyles()
  const getToken = useToken()
  const { loading, user } = useAuth0()
  const [dashboardColor, setDashboardColor] = useState('#eff1f5')
  const [primaryColor, setPrimaryColor] = useState('#373A8A')
  const [secondaryColor, setSecondaryColor] = useState('#faa82c')
  const [base64, setBase64] = useState(null)
  const [type, setType] = useState(null)
  const [imgData, setImgData] = useState(null)
  const [initProject, setInitProject] = useState(false)
  const [isSuperUser, setIsSuperUser] = useState(false)

  useEffect(() => {
    if (!initProject) {
      setInitProject(true)
      getColors()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const actualColors = (dashboard, primary, secondary) => {
    cssRoot.style.setProperty('--dashboard-color', `${dashboard}`)
    cssRoot.style.setProperty('--primary-color', `${secondary}`)
    cssRoot.style.setProperty('--secondary-color', `${primary}`)
    setDashboardColor(dashboard)
    setPrimaryColor(primary)
    setSecondaryColor(secondary)
  }

  const onChangePicture = (e) => {
    setType(e.target.files[0].type.split('/')[1])
    if (e.target.files[0]) {
      const reader = new FileReader()

      reader.addEventListener('load', () => {
        setBase64(reader.result.split('base64,')[1])
        setImgData(reader.result)
      })

      reader.readAsDataURL(e.target.files[0])
    }
  }

  const updateLogo = async () => {
    if (!base64 || !type) {
      signMessage.error(`No se ha seleccionado algún logo`)
      return
    }
    let body = {
      logoExtension: type,
      dashboardLogo: base64,
    }

    const token = await getToken({
      audience: 'veridocid',
    })

    const updateLogoDashboard = await updateCustomColors(token, body)

    if (updateLogoDashboard && updateLogoDashboard?.result !== null) {
      signMessage.success(`Para aplicar los cambios se recargara la pagina`)

      setTimeout(() => {
        window.location.reload()
      }, 3000)
    } else {
      signMessage.error(
        `No se pudo actualizar su logo. Por favor, inténtalo nuevamente`
      )
    }
  }

  const getColors = async () => {
    const token = await getToken({
      audience: 'veridocid',
    })

    const customColors = await getCustomColors(token)
    const decoded = jwt.decode(token)
    const regex = new RegExp('roles')

    for (const property in decoded) {
      if (regex.test(property)) {
        if (decoded[property].includes('SuperUser')) setIsSuperUser(true)
        break
      }
    }

    if (customColors && customColors?.result === null) {
      actualColors('#eff1f5', '#faa82c', '#373A8A')
    } else {
      actualColors(
        customColors.result.dashboardColor,
        customColors?.result?.primary_color ?? '#faa82c',
        customColors?.result?.secondary_color ?? '#373A8A'
      )
    }
  }

  const updateColors = async () => {
    let body = {
      primary_color: primaryColor,
      secondary_color: secondaryColor,
      dashboardColor,
    }

    const token = await getToken({
      audience: 'veridocid',
    })

    const customColors = await updateCustomColors(token, body)

    if (customColors && customColors !== null) {
      actualColors(
        customColors?.result?.dashboardColor,
        customColors?.result?.primary_color,
        customColors?.result?.secondary_color
      )
      signMessage.success(`Se ha guardado la configuracion con exito`)
    } else {
      signMessage.error(
        `No se pudo actualizar su información. Por favor, intentalo nuevamente`
      )
    }
  }

  if (loading || !user) {
    return <Loading />
  }

  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md={2}>
          <img
            src={user.picture}
            alt="Profile"
            className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
          />
        </Col>
        <Col md>
          <h2>{user.name}</h2>
          <p className="lead text-muted">{user.email}</p>
        </Col>
      </Row>
      {isSuperUser ? (
        <div>
          <div>
            <div>
              <div
                style={{
                  background: 'var(--primary-color)',
                  borderRadius: '6px 6px 0 0',
                  color: 'white',
                  width: '100%',
                  margin: '10px 0px',
                  height: '50px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div>
                  <h5
                    style={{
                      fontSize: '14px',
                      paddingLeft: '15px',
                      color: '#fff',
                      letterSpacing: '0.5px',
                    }}
                  >
                    Selecciona el logo
                  </h5>
                </div>
              </div>

              <div
                style={{
                  border: 'none',
                  borderLeft: 'solid 1px rgb(204, 204, 204)',
                  borderRight: 'solid 1px rgb(204, 204, 204)',
                  borderBottom: 'solid 1px rgb(204, 204, 204)',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '-11px 0px 30px',
                  padding: '30px 0px',
                  overflow: 'auto',
                  flexDirection: 'column',
                  backgroundColor: 'rgb(249, 249, 249)',
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <input
                    type="file"
                    id="profilePic"
                    onChange={onChangePicture}
                    accept=".png"
                  />

                  <img
                    className="playerProfilePic_home_tile"
                    src={imgData}
                    style={{ maxWidth: '120px' }}
                    alt=""
                  />
                </div>
                <div style={{ marginTop: '40px', textAlign: 'center' }}>
                  <Button
                    className={classes.buttonFilter}
                    variant="outlined"
                    onClick={() => {
                      updateLogo()
                    }}
                  >
                    Actualizar
                  </Button>
                </div>
              </div>
            </div>

            <div>
              <div
                style={{
                  background: 'var(--primary-color)',
                  borderRadius: '6px 6px 0 0',
                  color: 'white',
                  width: '100%',
                  margin: '10px 0px',
                  height: '50px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div>
                  <h5
                    style={{
                      fontSize: '14px',
                      paddingLeft: '15px',
                      color: '#fff',
                      letterSpacing: '0.5px',
                    }}
                  >
                    Selecciona los colores
                  </h5>
                </div>
              </div>
              <div
                style={{
                  border: 'none',
                  borderLeft: 'solid 1px rgb(204, 204, 204)',
                  borderRight: 'solid 1px rgb(204, 204, 204)',
                  borderBottom: 'solid 1px rgb(204, 204, 204)',
                  width: '100%',
                  margin: '-11px 0px 20px',
                  padding: '30px 0px',
                  overflow: 'auto',
                  textAlign: 'center',
                  backgroundColor: 'rgb(249, 249, 249)',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-around',
                    margin: '-11px 0px 20px',
                    padding: '30px 0px',
                    overflow: 'auto',
                  }}
                >
                  <div>
                    <p>Dashboard (header, footer)</p>
                    <BlockPicker
                      colors={[
                        '#FF0000',
                        '#00FF00',
                        '#0000FF',
                        '#C0C0C0',
                        '#000000',
                        '#800000',
                        '#008080',
                        '#000080',
                        '#ffA500',
                        '#800080',
                      ]}
                      color={dashboardColor}
                      onChangeComplete={(e) => {
                        setDashboardColor(e.hex)
                      }}
                    />
                  </div>
                  <div>
                    <p>Primario</p>
                    <BlockPicker
                      colors={[
                        '#FF0000',
                        '#00FF00',
                        '#0000FF',
                        '#C0C0C0',
                        '#000000',
                        '#800000',
                        '#008080',
                        '#000080',
                        '#ffA500',
                        '#800080',
                      ]}
                      color={primaryColor}
                      onChangeComplete={(e) => {
                        setPrimaryColor(e.hex)
                      }}
                    />
                  </div>
                  <div>
                    <p>Secundario</p>
                    <BlockPicker
                      colors={[
                        '#FF0000',
                        '#00FF00',
                        '#0000FF',
                        '#C0C0C0',
                        '#000000',
                        '#800000',
                        '#008080',
                        '#000080',
                        '#ffA500',
                        '#800080',
                      ]}
                      color={secondaryColor}
                      onChangeComplete={(e) => {
                        setSecondaryColor(e.hex)
                      }}
                    />
                  </div>
                </div>
                <div>
                  <Button
                    className={classes.buttonFilter}
                    variant="outlined"
                    onClick={() => updateColors()}
                  >
                    Actualizar
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {/* <Row>
        <Highlight>{JSON.stringify(user, null, 2)}</Highlight>
      </Row> */}
      {/* <Row>
        <Highlight>{JSON.stringify(token, null, 2).replace('"', '')}</Highlight>
      </Row>
      <Row>
        <Button onClick={_getToken}>token</Button>
      </Row> */}
    </Container>
  )
}

export default Profile
