import React from 'react'
import { Table } from 'semantic-ui-react'

const CardListaNominalWhitinfo = ({ dataListaNominal }) => (
  <>
    <Table attached="top" color={dataListaNominal ? 'green' : 'black'}>
      <Table.Body>
        {dataListaNominal?.mensaje ? (
          <Table.Row>
            <Table.Cell textAlign="center">
              {'La credencial ' + dataListaNominal.mensaje}
            </Table.Cell>
          </Table.Row>
        ) : null}
      </Table.Body>
    </Table>

    <Table attached>
      <Table.Body>
        {dataListaNominal?.vigencia ? (
          <Table.Row>
            <Table.Cell width={6}>
              <strong>Vigencia:</strong>
            </Table.Cell>
            <Table.Cell>{dataListaNominal.vigencia}</Table.Cell>
          </Table.Row>
        ) : null}

        {dataListaNominal?.claveElector ? (
          <Table.Row>
            <Table.Cell>
              <strong>Clave de Elector:</strong>
            </Table.Cell>
            <Table.Cell>{dataListaNominal.claveElector}</Table.Cell>
          </Table.Row>
        ) : null}

        {dataListaNominal?.anioRegistro ? (
          <Table.Row>
            <Table.Cell>
              <strong>Año de Registro:</strong>
            </Table.Cell>
            <Table.Cell>{dataListaNominal.anioRegistro}</Table.Cell>
          </Table.Row>
        ) : null}

        {dataListaNominal?.anioEmision ? (
          <Table.Row>
            <Table.Cell>
              <strong>Año de Emision:</strong>
            </Table.Cell>
            <Table.Cell>{dataListaNominal.anioEmision}</Table.Cell>
          </Table.Row>
        ) : null}

        {dataListaNominal?.cic ? (
          <Table.Row>
            <Table.Cell>
              <strong>Número CIC:</strong>
            </Table.Cell>
            <Table.Cell>{dataListaNominal.cic}</Table.Cell>
          </Table.Row>
        ) : null}

        {dataListaNominal?.ocr ? (
          <Table.Row>
            <Table.Cell>
              <strong>Número OCR:</strong>
            </Table.Cell>
            <Table.Cell>{dataListaNominal.ocr}</Table.Cell>
          </Table.Row>
        ) : null}

        {dataListaNominal?.estatus ? (
          <Table.Row>
            <Table.Cell>
              <strong>Estatus de la consulta:</strong>
            </Table.Cell>
            <Table.Cell>{dataListaNominal.estatus}</Table.Cell>
          </Table.Row>
        ) : null}

        {dataListaNominal?.numeroEmision ? (
          <Table.Row>
            <Table.Cell>
              <strong>Número de Emisión:</strong>
            </Table.Cell>
            <Table.Cell>{dataListaNominal.numeroEmision}</Table.Cell>
          </Table.Row>
        ) : null}

        {dataListaNominal?.informacionAdicional ? (
          <Table.Row>
            <Table.Cell>
              <strong>Información Adicional:</strong>
            </Table.Cell>
            <Table.Cell>{dataListaNominal.informacionAdicional}</Table.Cell>
          </Table.Row>
        ) : null}
      </Table.Body>
    </Table>
  </>
)

export default CardListaNominalWhitinfo
