import { API_DASHBOARD_URL } from '../const'

const getImage = async ({
  key,
  image,
  dataVersion,
  getToken,
  setAnverso,
  setReverso,
  setFacial,
  setFacialEnrollImg,
  setFront,
  setFrontIR,
  setFrontUV,
  setBack,
  setBackIR,
  setBackUV,
  setPortrait,
  setFrontOriginal,
  setBackOriginal,
}) => {
  try {
    const token = await getToken({
      audience: 'veridocid-dashboard',
    })

    const response = await fetch(
      `${API_DASHBOARD_URL}/files?filePath=${image}&dataVersion=${dataVersion}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )

    if (response.ok) {
      const b64 = await response.text()

      switch (key) {
        case 'anverso':
          setAnverso(b64 === 'null' ? false : `data:image/png;base64,${b64}`)
          break
        case 'reverso':
          setReverso(b64 === 'null' ? false : `data:image/png;base64,${b64}`)
          break
        case 'facial':
          setFacial(b64 === 'null' ? false : `data:image/png;base64,${b64}`)
          setFacialEnrollImg(b64)
          break
        case 'front':
          setFront(b64 === 'null' ? false : `data:image/png;base64,${b64}`)
          break
        case 'frontInfrared':
          setFrontIR(b64 === 'null' ? false : `data:image/png;base64,${b64}`)
          break
        case 'frontUltraviolet':
          setFrontUV(b64 === 'null' ? false : `data:image/png;base64,${b64}`)
          break
        case 'back':
          setBack(b64 === 'null' ? false : `data:image/png;base64,${b64}`)
          break
        case 'backInfrared':
          setBackIR(b64 === 'null' ? false : `data:image/png;base64,${b64}`)
          break
        case 'backUltraviolet':
          setBackUV(b64 === 'null' ? false : `data:image/png;base64,${b64}`)
          break
        case 'portrait':
          setPortrait(b64 === 'null' ? false : `data:image/png;base64,${b64}`)
          break
        case 'frontOriginal':
          setFrontOriginal({ data: b64 })
          break
        case 'backOriginal':
          setBackOriginal({ data: b64 })
          break
        default:
          break
      }
    } else {
      const error = await response.json()
      console.error(error)
    }
  } catch (error) {
    console.error(error)
  }
}

export default getImage
