import React from 'react'
import { getDate } from '../../../utils/getDate'

export const LegalRepresentativeContract = ({ data, t }) => (
  <div className="content_status_sign content_data_panel">
    <h4
      className="title_panel_custom"
      style={{
        paddingBottom: '0.4rem',
        marginBottom: '0',
      }}
    >
      {t('legalRepresentative')}
    </h4>
    <div className="description_panel_custom">
      <p style={{ margin: '0' }}>
        {data?.signsLegalRepresentative?.user?.email || '-'}
      </p>
      {/* Fecha de firma */}
      {data?.updatedAt &&
      data?.participants.length !== 0 &&
      data.status === 'signed' &&
      data?.participants.filter((signer) => signer.status !== 'signed')
        .length === 0 ? (
        <p
          style={{
            margin: '0.2rem 0',
            color: 'gray',
          }}
        >
          {getDate(data?.updatedAt) || '-'}
        </p>
      ) : (
        <p
          style={{
            margin: '0.2rem 0',
            color: 'gray',
          }}
        >
          Pendiente
        </p>
      )}
      {/* Mesa de Revisión */}
      {data?.reviewTable && data?.dateReviewTable ? (
        <>
          <h4
            className="title_panel_custom"
            style={{
              paddingBottom: '0.4rem',
              marginBottom: '0',
            }}
          >
            {t('reviewTable')}
          </h4>
          <p style={{ margin: '0' }}>
            {data?.reviewTable === 'approved'
              ? `${t('approvedStatus')}`
              : `${t('rejectedStatus')}` || '-'}
          </p>
          <p
            style={{
              margin: '0.2rem 0',
              color: 'gray',
            }}
          >
            {getDate(data?.dateReviewTable) || '-'}
          </p>
        </>
      ) : null}
    </div>
  </div>
)
