import { API_URL } from '../../../const/index'
import '../../../styles/ClientAllDataShow.css'
import { useTranslation } from 'react-i18next'
import useToken from '../../../utils/useToken'
import { Button } from 'semantic-ui-react'
import React, { useState } from 'react'
import download from 'downloadjs'

const ButtonPdfCreditReport = ({ folioConsulta }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()
  const getToken = useToken()

  const generateCreditPDF = async (folioConsulta) => {
    setIsLoading(true)
    try {
      const token = await getToken({
        audience: 'veridocid',
      })

      const response = await fetch(
        `${API_URL}/credit/v2/credit-report/${folioConsulta}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )

      if (response.ok) {
        setIsLoading(false)
        const data = await response.json()
        if (data) {
          download(
            `data:application/pdf;base64,${data?.report}`,
            `${folioConsulta}.pdf`,
            'application/pdf'
          )
        }
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  return (
    <Button
      onClick={() => generateCreditPDF(folioConsulta)}
      floated="right"
      loading={isLoading}
      style={{
        background: 'var(--primary-color)',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      className="btns_actions_with_background"
    >
      <span className="details_btnPDF">{t('pdfReportCredit')}</span>
    </Button>
  )
}

export default ButtonPdfCreditReport
