import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'

export const MainContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

export const TemplateNameInput = styled('input')({
  border: '1px solid #d9d9d9',
  color: 'grey',
  paddingLeft: '10px',
  marginBottom: '15px',
  outline: 'none',
  padding: '0.4rem 0.5rem',
  '&:hover, &:active, &:focus': {
    border: '1px solid var(--secondary-color)',
  },
})

export const MesaMasiva = styled(Grid)({
  width: '100%',
  display: 'flex',
  color: 'var(--secondary-color)',
})

export const MesaMasivaItems = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
})

export const MesaMasivaItemsLeft = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginRight: '10px',
})
