import React, { Fragment, useState } from 'react'
import { Button, Form, Label, Transition, Message } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

import '../ConsultationServices.css'
import CardList69WithInfo from './CardList69Withinfo'
import useToken from '../../../utils/useToken'
import { API_URL } from '../../../const/index'
import { useAuth0 } from '../../../react-auth0-spa'

const CardList69 = ({
  id,
  uuid,
  rfcList69,
  lis69Status,
  dataList69Found,
  setIsList69Verified,
  handleChangeInputRFCList69,
  onVerifyList69,
}) => {
  const [dataList69, setDataList69] = useState(null)
  const [verificando, setVerificando] = useState(false)
  //Control de errores
  const [inputError, setInputError] = useState(false)
  const [inputMsgErr, setInputMsgErr] = useState('')
  // API
  const [visibleMsgApi, setVisibleMsgApi] = useState(false)
  const [contentMsgApi, setContentMsgApi] = useState('')

  //Configurations
  const { t } = useTranslation()
  const { user } = useAuth0()
  const getToken = useToken()

  const callToVerifyRFC = async () => {
    setVerificando(true)
    try {
      const token = await getToken({
        audience: 'veridocid',
      })

      const response = await fetch(`${API_URL}/list69`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
          rfc: rfcList69,
          uuid,
          name: user.name,
          email: user.email,
        }),
      })

      const reponseJSON = await response.json()

      if (response.ok) {
        onVerifyList69(reponseJSON)
        setDataList69(reponseJSON)
        setIsList69Verified(true)
        setVerificando(false)
        setVisibleMsgApi(false)
      } else {
        setVerificando(false)
        setVisibleMsgApi(true)
        setIsList69Verified(false)
        setContentMsgApi(reponseJSON?.message)
      }
    } catch (error) {
      setVerificando(false)
      setVisibleMsgApi(true)
      setContentMsgApi(
        'Hemos tenido un problema al consultar la lista del 69 y 69B, intentelo más tarde'
      )
    }
  }

  // Call API to verify a RFC
  const handleClick = () => {
    if (rfcList69 !== '') {
      setInputError(false)
      callToVerifyRFC()
    } else {
      setInputError(true)
      setInputMsgErr(`${t('messageErrorEmptyInputRfc')}`)
      setTimeout(() => {
        setInputError(false)
      }, 3000)
    }
  }

  // Handle close message error
  const changeStateVisibleMsgErrorApi = () => {
    setVisibleMsgApi(false)
    setContentMsgApi('')
  }

  return (
    <Fragment>
      <div
        className="ui container"
        style={{ marginLeft: '0', marginRight: '0' }}
      >
        {lis69Status ? (
          <CardList69WithInfo dataList69={dataList69Found || dataList69} />
        ) : (
          <>
            <Form>
              <Form.Field>
                <p>{t('rfc')}</p>

                <div className="ui fluid input">
                  <input
                    type="text"
                    value={rfcList69}
                    maxLength={13}
                    placeholder="RFC"
                    onChange={handleChangeInputRFCList69}
                    style={{ textTransform: 'uppercase' }}
                  />
                </div>

                <Transition
                  animation="drop"
                  duration={500}
                  visible={inputError}
                >
                  <Label
                    pointing
                    color="red"
                    as="span"
                    style={{ lineHeight: '15px' }}
                  >
                    {`${inputMsgErr}`}
                  </Label>
                </Transition>
              </Form.Field>
            </Form>
            <br />
            {visibleMsgApi ? (
              <Message
                error
                onDismiss={changeStateVisibleMsgErrorApi}
                header={'Error lista 69 y 69B'}
                content={contentMsgApi}
              />
            ) : null}
            <div className="ButtonsCard">
              <Button
                onClick={handleClick}
                disabled={verificando}
                loading={verificando}
              >
                {t('send')}
              </Button>
            </div>
          </>
        )}
      </div>
    </Fragment>
  )
}

export default CardList69
